import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ValueWithMoneyUnit from "../../../../../app/ui/unit/ValueWithMoneyUnit";

const RyokinBlock = ({ ryokin }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="料金について" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>入会費</th>
              <td>
                <ValueWithMoneyUnit value={ryokin.nyukaihi} />
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>会費</th>
              <td>{ryokin.kaihi}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>予約手数料</th>
              <td>
                <ValueWithMoneyUnit value={ryokin.yoyakutesuryo} />
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>迎車料</th>
              <td>
                <ValueWithMoneyUnit value={ryokin.geisharyo} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

RyokinBlock.propTypes = {
  ryokin: PropTypes.object.isRequired,
};

export default RyokinBlock;
