import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import { Card } from "@material-ui/core";
import Boundary from "../../../../app/ui/layout/Boundary";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import {
  activateJobOffer,
  deactivateJobOffer,
  activatePlus,
  deactivatePlus,
  requestFeatures,
} from "../../../../state/feature/actions";
import Mount from "../../../../app/ui/layout/Mount";
import RaisedSecondaryButton from "../../../../app/ui/button/RaisedSecondaryButton";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";

class HojinFeaturePage extends React.Component {
  constructor(props) {
    super(props);

    this.initializeFields(props);

    this.state = {
      activateDialogIsOpen: false,
      deactivateDialogIsOpen: false,
      activatePlusDialogIsOpen: false,
      deactivatePlusDialogIsOpen: false,
    };

    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleActivateJobOfferButtonClick = this.handleActivateJobOfferButtonClick.bind(
      this
    );
    this.handleActivateDialogCloseButtonClick = this.handleActivateDialogCloseButtonClick.bind(
      this
    );
    this.handleActivateDialogSubmitButtonClick = this.handleActivateDialogSubmitButtonClick.bind(
      this
    );
    this.handleDeactivateJobOfferButtonClick = this.handleDeactivateJobOfferButtonClick.bind(
      this
    );
    this.handleDeactivateDialogCloseButtonClick = this.handleDeactivateDialogCloseButtonClick.bind(
      this
    );
    this.handleDeactivateDialogSubmitButtonClick = this.handleDeactivateDialogSubmitButtonClick.bind(
      this
    );
    this.handleActivatePlusButtonClick = this.handleActivatePlusButtonClick.bind(
      this
    );
    this.handleActivatePlusDialogCloseButtonClick = this.handleActivatePlusDialogCloseButtonClick.bind(
      this
    );
    this.handleActivatePlusDialogSubmitButtonClick = this.handleActivatePlusDialogSubmitButtonClick.bind(
      this
    );
    this.handleDeactivatePlusButtonClick = this.handleDeactivatePlusButtonClick.bind(
      this
    );
    this.handleDeactivatePlusDialogCloseButtonClick = this.handleDeactivatePlusDialogCloseButtonClick.bind(
      this
    );
    this.handleDeactivatePlusDialogSubmitButtonClick = this.handleDeactivatePlusDialogSubmitButtonClick.bind(
      this
    );
  }

  initializeFields(props) {
    const { params } = props.match;
    this.id = params.id;
  }

  componentDidMount() {
    this.props.getFeatures(this.id);
  }

  handleBackButtonClick() {
    const { hojinList, gotoListPage } = this.props;
    gotoListPage(hojinList.search);
  }

  handleActivateJobOfferButtonClick() {
    this.setState({ activateDialogIsOpen: true });
  }

  handleActivateDialogCloseButtonClick() {
    this.setState({ activateDialogIsOpen: false });
  }

  handleActivateDialogSubmitButtonClick() {
    this.setState({ activateDialogIsOpen: false });
    this.props.activateJobOffer(this.id);
  }

  handleDeactivateJobOfferButtonClick() {
    this.setState({ deactivateDialogIsOpen: true });
  }

  handleDeactivateDialogCloseButtonClick() {
    this.setState({ deactivateDialogIsOpen: false });
  }

  handleDeactivateDialogSubmitButtonClick() {
    this.setState({ deactivateDialogIsOpen: false });
    this.props.deactivateJobOffer(this.id);
  }

  handleActivatePlusButtonClick() {
    this.setState({ activatePlusDialogIsOpen: true });
  }

  handleActivatePlusDialogCloseButtonClick() {
    this.setState({ activatePlusDialogIsOpen: false });
  }

  handleActivatePlusDialogSubmitButtonClick() {
    this.setState({ activatePlusDialogIsOpen: false });
    this.props.activatePlus(this.id);
  }

  handleDeactivatePlusButtonClick() {
    this.setState({ deactivatePlusDialogIsOpen: true });
  }

  handleDeactivatePlusDialogCloseButtonClick() {
    this.setState({ deactivatePlusDialogIsOpen: false });
  }

  handleDeactivatePlusDialogSubmitButtonClick() {
    this.setState({ deactivatePlusDialogIsOpen: false });
    this.props.deactivatePlus(this.id);
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          {this.renderHead()}
          {this.renderNavigation()}
          {this.renderForm()}

          {this.renderActivateDialog()}
          {this.renderDeactivateDialog()}
          {this.renderActivatePlusDialog()}
          {this.renderDeactivatePlusDialog()}
        </SideMargin>
      </Boundary>
    );
  }

  renderHead() {
    return <BackOfficeHead>オプションサービス</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderForm() {
    const { features, featuresIsLoading } = this.props.feature;
    const isJobOfferActive = features.jobOffer;
    const isPlusActive = features.plus;

    return (
      <Card>
        <SideMargin top bottom>
          <Mount if={false}>
            <FormGrouping title="求人">
              <p>求人情報を掲載することができるようになります。</p>
              <Mount if={featuresIsLoading}>
                <CenteredCircularProgress />
              </Mount>
              <Mount if={!featuresIsLoading}>
                <Mount if={isJobOfferActive}>
                  <RaisedSecondaryButton
                    onClick={this.handleDeactivateJobOfferButtonClick}
                  >
                    無効にする
                  </RaisedSecondaryButton>
                </Mount>
                <Mount if={!isJobOfferActive}>
                  <RaisedPrimaryButton
                    onClick={this.handleActivateJobOfferButtonClick}
                  >
                    有効にする
                  </RaisedPrimaryButton>
                </Mount>
              </Mount>
            </FormGrouping>
            <hr />
          </Mount>
          <FormGrouping title="ミルモネットプラス">
            <p>ミルモネットプラスを利用することができるようになります。</p>
            <Mount if={featuresIsLoading}>
              <CenteredCircularProgress />
            </Mount>
            <Mount if={!featuresIsLoading}>
              <Mount if={isPlusActive}>
                <RaisedSecondaryButton
                  onClick={this.handleDeactivatePlusButtonClick}
                >
                  無効にする
                </RaisedSecondaryButton>
              </Mount>
              <Mount if={!isPlusActive}>
                <RaisedPrimaryButton
                  onClick={this.handleActivatePlusButtonClick}
                >
                  有効にする
                </RaisedPrimaryButton>
              </Mount>
            </Mount>
          </FormGrouping>
        </SideMargin>
      </Card>
    );
  }

  renderActivateDialog() {
    return (
      <Dialog
        onClose={this.handleActivateDialogCloseButtonClick}
        open={this.state.activateDialogIsOpen}
      >
        <DialogTitle>求人機能を有効化</DialogTitle>
        <DialogContent>
          <DialogContentText>
            求人機能を有効化します。よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton
            onClick={this.handleActivateDialogCloseButtonClick}
          >
            キャンセル
          </FlatPrimaryButton>
          <RaisedPrimaryButton
            onClick={this.handleActivateDialogSubmitButtonClick}
          >
            有効化する
          </RaisedPrimaryButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderDeactivateDialog() {
    return (
      <Dialog
        onClose={this.handleDeactivateDialogCloseButtonClick}
        open={this.state.deactivateDialogIsOpen}
      >
        <DialogTitle>求人機能を無効化</DialogTitle>
        <DialogContent>
          <DialogContentText>
            求人機能を無効化します。よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton
            onClick={this.handleDeactivateDialogCloseButtonClick}
          >
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handleDeactivateDialogSubmitButtonClick}
          >
            無効化する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderActivatePlusDialog() {
    return (
      <Dialog
        onClose={this.handleActivatePlusDialogCloseButtonClick}
        open={this.state.activatePlusDialogIsOpen}
      >
        <DialogTitle>ミルモネットプラス機能を有効化</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ミルモネットプラス機能を有効化します。よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton
            onClick={this.handleActivatePlusDialogCloseButtonClick}
          >
            キャンセル
          </FlatPrimaryButton>
          <RaisedPrimaryButton
            onClick={this.handleActivatePlusDialogSubmitButtonClick}
          >
            有効化する
          </RaisedPrimaryButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderDeactivatePlusDialog() {
    return (
      <Dialog
        onClose={this.handleDeactivatePlusDialogCloseButtonClick}
        open={this.state.deactivatePlusDialogIsOpen}
      >
        <DialogTitle>ミルモネットプラス機能を無効化</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ミルモネットプラス機能を無効化します。よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton
            onClick={this.handleDeactivatePlusDialogCloseButtonClick}
          >
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handleDeactivatePlusDialogSubmitButtonClick}
          >
            無効化する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }
}

HojinFeaturePage.propTypes = {
  getFeatures: PropTypes.func.isRequired,
  activateJobOffer: PropTypes.func.isRequired,
  deactivateJobOffer: PropTypes.func.isRequired,
  activatePlus: PropTypes.func.isRequired,
  deactivatePlus: PropTypes.func.isRequired,
  gotoListPage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  feature: PropTypes.object.isRequired,
  hojinList: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HojinFeaturePage);

function mapStateToProps(state) {
  return {
    feature: state.feature,
    hojinList: state.hojinList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getFeatures: (id) => dispatch(requestFeatures(id)),
    activateJobOffer: (id) => {
      const callback = () => dispatch(requestFeatures(id));
      dispatch(activateJobOffer(id, callback));
    },
    deactivateJobOffer: (id) => {
      const callback = () => dispatch(requestFeatures(id));
      dispatch(deactivateJobOffer(id, callback));
    },
    activatePlus: (id) => {
      const callback = () => dispatch(requestFeatures(id));
      dispatch(activatePlus(id, callback));
    },
    deactivatePlus: (id) => {
      const callback = () => dispatch(requestFeatures(id));
      dispatch(deactivatePlus(id, callback));
    },
    gotoListPage: (search = "") =>
      dispatch(
        push({
          pathname: Pathname.backoffice_hojin,
          search,
        })
      ),
  };
}
