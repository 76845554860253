import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import * as actions from "../../../../state/service-search/actions";
import { searchConditionFunnelTypeValuesOfMenus } from "../SearchConditionFunnelTypeConfiguration";
import CategorizedOptions from "../../../../app/util/CategorizedMasterData";
import Parametername from "../../../../app/naming/Parametername";
import SearchConditionPageCity from "./SearchConditionPageCity";
import ViewType from "../ViewType";
import { conditionConverter } from "../../../../state/service-search/query-string";
import { enterKeyHandler } from "../../../../app/ui/form";

class SearchConditionFunnel extends React.Component {
  constructor(props) {
    super(props);
    this.handleServiceShubetsuMenuClick = this.handleServiceShubetsuMenuClick.bind(
      this
    );
    this.handleShowCityAreaDialog = this.handleShowCityAreaDialog.bind(this);
    this.handleCityAreaDialogBackgroundClick = this.handleCityAreaDialogBackgroundClick.bind(
      this
    );
    this.handleCityAreaDialogCancelButtonClick = this.handleCityAreaDialogCancelButtonClick.bind(
      this
    );
    this.handleCityAreaDialogOnChange = this.handleCityAreaDialogOnChange.bind(
      this
    );
    this.handleCityAreaDialogOnSearch = this.handleCityAreaDialogOnSearch.bind(
      this
    );
    this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
  }

  state = {
    cityDialogIsOpen: false,
  };

  // 初回の描画完了後
  componentDidMount() {
    // this.fetchSelectedBrowseAreaLabel();
  }

  handleServiceShubetsuMenuClick(e) {
    const { checked, value: code } = e.target;
    const { actions, serviceShubetsuMenu } = this.props;
    actions.changeConditionServiceShubetsuMenu(
      code,
      checked,
      serviceShubetsuMenu
    );
  }

  // 市区町村ダイアログの表示
  handleShowCityAreaDialog(e) {
    const { actions } = this.props;
    e.stopPropagation();
    // 地域一覧取得
    actions.fetchBrowseArea();
    // 所属地域の初期チェック
    this.defaultChecked();
    this.setState({
      cityDialogIsOpen: true,
    });
  }

  // バックグラウンドクリック
  handleCityAreaDialogBackgroundClick() {
    this.setState({ cityDialogIsOpen: false });
  }

  // キャンセルボタン
  handleCityAreaDialogCancelButtonClick() {
    this.setState({ cityDialogIsOpen: false });
  }

  // 変更を反映して画面を閉じる
  handleCityAreaDialogOnChange() {
    // 閲覧検索地域名取得
    this.fetchSelectedBrowseAreaLabel();
    // 情報変更して画面を閉じる
    this.setState({ cityDialogIsOpen: false });
  }

  // 変更して検索ボタン
  handleCityAreaDialogOnSearch() {
    // 閲覧検索地域名取得
    this.fetchSelectedBrowseAreaLabel();

    // 検索処理
    const { actions, serviceSearch } = this.props;
    actions.changeCondition(Parametername.view, ViewType.cardView);
    actions.clearList();
    const search = conditionConverter(serviceSearch.condition).toSearch({
      [Parametername.view]: ViewType.cardView.name,
    });
    actions.gotoSearchPage(search);

    // 情報変更して画面を閉じる
    this.setState({ cityDialogIsOpen: false });
  }

  // 閲覧検索地域名取得
  fetchSelectedBrowseAreaLabel() {
    const { actions, serviceSearch } = this.props;
    const { conditionBuffer } = serviceSearch;

    actions.fetchSelectedBrowseAreaLabel(
      conditionBuffer[Parametername.cityArea]
    );
  }

  handleSearchButtonClick(view) {
    const { actions, serviceSearch } = this.props;
    actions.changeCondition(Parametername.view, view);
    actions.clearList();
    const search = conditionConverter(serviceSearch.condition).toSearch({
      [Parametername.view]: view.name,
    });
    actions.gotoSearchPage(search);
  }

  // 所属地域の初期チェック
  defaultChecked() {
    const { changeCondition: onChange } = this.props.actions;
    const { user } = this.props.authentication;
    const { conditionBuffer } = this.props.serviceSearch;
    // 保存状態を再設定
    onChange(Parametername.cityArea, conditionBuffer[Parametername.cityArea]);

    // 全て未選択でログイン情報の地域がある場合
    if (
      user.browseAreaCityCode &&
      Object.keys(conditionBuffer[Parametername.cityArea]).length < 1
    ) {
      onChange(`${Parametername.cityArea}.${user.browseAreaCityCode}`, true);
    }
  }

  render() {
    const {
      serviceShubetsuCategoryCode,
      serviceShubetsuMenuCodes,
    } = this.props.serviceSearch.condition;
    const types = searchConditionFunnelTypeValuesOfMenus(
      serviceShubetsuCategoryCode,
      serviceShubetsuMenuCodes,
      true
    );
    return (
      <div>
        <div>
          {/* 今後表示となる可能性があるため、一旦一律非表示 */}
          {/* {this.renderArea()} */}
          <div className="c-blue-heading">
            <div className="c-blue-heading__text">絞り込み条件の選択</div>
          </div>
          <div className="c-blue-frame-sharp u-margin-b32">
            {this.renderKeyword()}
            {this.renderServiceShubetsu()}
            {types.map((funnel) => (
              <div key={funnel.name}>
                {funnel.hide_title ? null : (
                  <div className="c-small-head">{funnel.label}</div>
                )}
                {funnel.parameters.map((param) => (
                  <Fragment key={param.name}>
                    {param.label ? (
                      <div className="c-small-subhead">{param.label}</div>
                    ) : null}
                    {param.type == "checkbox"
                      ? this.renderCheckbox(param)
                      : param.type == "radio"
                      ? this.renderRadio(param)
                      : param.type == "select"
                      ? this.renderSelect(param)
                      : param.type == "select_enclose"
                      ? this.renderSelectEnclose(param)
                      : null}
                  </Fragment>
                ))}
              </div>
            ))}
          </div>
        </div>
        {this.renderCityAreaDialog()}
      </div>
    );
  }

  // 地域の選択
  renderArea() {
    const { selectedBrowseAreaLabel } = this.props.serviceSearch;
    if (selectedBrowseAreaLabel == null) return;
    return (
      <div>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">地域の選択</div>
        </div>
        <div className="c-blue-frame-sharp u-margin-b32">
          <table>
            <tbody>
              <tr>
                <td style={{ whiteSpace: "nowrap" }}>
                  {selectedBrowseAreaLabel.prefLabel} ＞
                </td>
                <td style={{ lineHeight: "1.5em" }}>
                  {/* 要素が一つならこれを設定「whiteSpace:"nowrap"」 */}
                  {selectedBrowseAreaLabel.cityLabelList.join("/ ")}
                </td>
                {/* <td style={{lineHeight: "1.5em"}}>
                                    町名用
                                 </td> */}
              </tr>
            </tbody>
          </table>

          <div
            style={{ width: "140px", textAlign: "right" }}
            className="c-simplebutton-b c-simplebutton-b--search"
          >
            <a onClick={this.handleShowCityAreaDialog}>地域の変更</a>
          </div>
        </div>
      </div>
    );
  }

  renderKeyword() {
    const { actions, serviceSearch } = this.props;
    const { condition } = serviceSearch;
    const handleChange = (e) =>
      actions.changeCondition(e.target.name, e.target.value);
    return (
      <Fragment>
        <div className="c-small-head">キーワード</div>
        <div className="c-form-text">
          <input
            type="text"
            name={Parametername.keyword}
            value={condition.getByName(Parametername.keyword)}
            onChange={handleChange}
            placeholder="千代田区 囲碁"
            onKeyDown={enterKeyHandler(this.handleSearchButtonClick)}
          />
        </div>
      </Fragment>
    );
  }

  renderServiceShubetsu() {
    const category = new CategorizedOptions(this.props.serviceShubetsuMenu)
      .getCategories()
      .find(
        (category) => category.code == this.props.serviceShubetsuCategoryCode
      );
    if (!category) return null;
    if (category.options.length < 2) return null;
    return (
      <Fragment>
        <div className="c-small-head">サービス種別（必須）</div>
        <div className="c-form-checkbox">
          {category.options.map((option) => (
            <label
              key={`serviceShubetsuMenuCode:${option.code}`}
              className="c-form-checkbox__item"
            >
              <input
                type="checkbox"
                value={option.code}
                checked={this.props.serviceShubetsuMenuCodes.includes(
                  option.code
                )}
                onChange={this.handleServiceShubetsuMenuClick}
              />
              <div className="c-form-checkbox__item-text">{option.label}</div>
            </label>
          ))}
        </div>
      </Fragment>
    );
  }

  renderCheckbox(param) {
    const { actions, serviceSearch } = this.props;
    const { condition } = serviceSearch;
    const handleChange = (e) =>
      actions.changeCondition(e.target.name, e.target.checked);
    return (
      <div className="c-form-checkbox">
        {param.options.map((option) => (
          <label
            key={`${param.name}.${option.code}`}
            className="c-form-checkbox__item"
          >
            <input
              type="checkbox"
              name={`${param.name}.${option.code}`}
              checked={
                condition.getByName(`${param.name}.${option.code}`)
                  ? true
                  : false
              }
              onChange={handleChange}
            />
            <div className="c-form-checkbox__item-text">{option.label}</div>
          </label>
        ))}
      </div>
    );
  }

  renderRadio(param) {
    const { actions, serviceSearch } = this.props;
    const { condition } = serviceSearch;
    const handleChange = (e) =>
      actions.changeCondition(e.target.name, e.target.value);
    return (
      <div className="c-form-radio">
        {param.options.map((option) => (
          <label
            key={`${param.name}.${option.code}`}
            className="c-form-radio__item"
          >
            <input
              type="radio"
              name={param.name}
              value={option.code}
              checked={condition.getByName(param.name) == option.code}
              onChange={handleChange}
            />
            <div className="c-form-radio__item-text">{option.label}</div>
          </label>
        ))}
      </div>
    );
  }

  renderSelect(param) {
    const { actions, serviceSearch } = this.props;
    const { condition } = serviceSearch;
    const handleChange = (e) =>
      actions.changeCondition(e.target.name, e.target.value);
    return (
      <div className="c-form-select">
        <div className="c-form-select__item">
          <select
            name={param.name}
            value={condition.getByName(param.name)}
            onChange={handleChange}
          >
            {param.options.map((option) => (
              <option key={`${param.name}.${option.code}`} value={option.code}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
      </div>
    );
  }

  renderSelectEnclose(param) {
    const { actions, serviceSearch } = this.props;
    const { condition } = serviceSearch;
    const handleChange = (e) =>
      actions.changeCondition(e.target.name, e.target.value);
    return (
      <div className="c-form-select">
        {param.list.map((list, index) => {
          if (list.type == "select") {
            return (
              <div key={index}>
                <div className="c-form-select__item">
                  <select
                    name={list.name}
                    value={condition.getByName(list.name)}
                    onChange={handleChange}
                  >
                    {list.options.map((option) => (
                      <option
                        key={`${list.name}.${option.code}`}
                        value={option.code}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                {list.label && (
                  <div className="c-form-select__item2">{list.label}</div>
                )}
              </div>
            );
          } else if (list.type == "block") {
            return (
              <div key={index}>
                {list.mark && (
                  <div className="c-form-select__block">{list.mark}</div>
                )}
              </div>
            );
          }
        })}
      </div>
    );
  }

  // 市区町村ダイアログ
  renderCityAreaDialog() {
    const { serviceSearch, actions, location } = this.props;
    const { changeCondition: onChange, savaConditionBuffer: onSave } = actions;
    const { cityDialogIsOpen: open } = this.state;
    const props = {
      serviceSearch,
      onChange,
      onSave,
      open,
      location,
      subOkText: "条件を追加",
      okText: "検索",
      handleCancel: this.handleCityAreaDialogBackgroundClick,
      handleClose: this.handleCityAreaDialogCancelButtonClick,
      handlSubOk: this.handleCityAreaDialogOnChange,
      handleOk: this.handleCityAreaDialogOnSearch,
    };
    return <SearchConditionPageCity {...props} />;
  }
}

SearchConditionFunnel.propTypes = {
  authentication: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  location: PropTypes.object.isRequired,
  serviceSearch: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string,
  serviceShubetsuMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
  serviceShubetsuMenuCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchConditionFunnel);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoSearchPage: (search) =>
        dispatch(
          push({
            pathname: Pathname.webfront_search,
            search,
          })
        ),
    },
  };
}
