import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const GaibuRenkeiTable = ({ contents }) => {
  const {
    blocks: { shortstayGaibuRenkei: gaibuRenkei },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-section">
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>協力連携医療機関</th>
                <td>{gaibuRenkei.kyoryokuRenkeiIryokikan}</td>
              </tr>
              <tr>
                <th>協力歯科医療機関</th>
                <td>{gaibuRenkei.kyoryokuShikaIryokikan}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.shortstayGaibuRenkei
        )}
      </div>
    </Fragment>
  );
};

GaibuRenkeiTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default GaibuRenkeiTable;
