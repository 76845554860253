import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const SogeiTable = ({ contents }) => {
  const sogei = contents.blocks.shortstaySogei;

  return (
    <Fragment>
      <div className="p-office-body-title">サービス提供地域</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>サービス提供地域</th>
              <td>{sogei.serviceTeikyochiiki}</td>
            </tr>
            <tr>
              <th>備考</th>
              <td>{sogei.serviceTeikyoChiikiBiko}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-title">送迎</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>送迎サービスの有無</th>
              <td>{sogei.sogeiServiceUmu}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>車両台数</th>
              <td>{sogei.sharyodaisu}</td>
            </tr>
            <tr>
              <th>送迎時居宅内介護</th>
              <td>{sogei.sogeijikyotakunaikaigo}</td>
            </tr>
            <tr>
              <th>送迎車ロゴ</th>
              <td>{sogei.sogeishaLogo}</td>
            </tr>
            <tr>
              <th>送迎時間個別対応</th>
              <td>{sogei.sogeijikankobetsutaio}</td>
            </tr>
            <tr>
              <th>備考</th>
              <td>{sogei.biko}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.shortstaySogei
      )}
    </Fragment>
  );
};

SogeiTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default SogeiTable;
