import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import FixedTable from "../../../../app/ui/table/FixedTable";
import SmallPaddingTableCell from "../../../../app/ui/table/SmallPaddingTableCell";
import * as actions from "../../../../state/jigyosho-form/actions";

class OpenDataJoinConfirmationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.reloadListPage = this.reloadListPage.bind(this);
  }

  handleCloseButtonClick() {
    this.props.onClose();
  }

  handleAddButtonClick() {
    const { actions, groupId, target } = this.props;
    actions.submitToJoin(target.id, groupId, this.reloadListPage);
  }

  reloadListPage() {
    const { actions, jigyoshoList } = this.props;
    actions.reloadListPage(jigyoshoList.search);
  }

  render() {
    const { onClose = () => {}, target } = this.props;
    return (
      <Dialog onClose={onClose} open={target ? true : false}>
        <Fragment>{this.renderMain()}</Fragment>
      </Dialog>
    );
  }

  renderMain() {
    const { target } = this.props;
    if (!target) return null;
    return (
      <Fragment>
        <DialogTitle>事業所追加の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            このオープンデータを管理対象の事業所として追加します。
          </DialogContentText>
          <FixedTable>
            <TableHead>
              <TableRow>
                <SmallPaddingTableCell>事業所名</SmallPaddingTableCell>
                <SmallPaddingTableCell>サービス種別</SmallPaddingTableCell>
                <SmallPaddingTableCell>市区町村</SmallPaddingTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <SmallPaddingTableCell>
                  {target.jigyoshomei}
                </SmallPaddingTableCell>
                <SmallPaddingTableCell>
                  {target.serviceShubetsu}
                </SmallPaddingTableCell>
                <SmallPaddingTableCell>
                  {target.shikuchoson}
                </SmallPaddingTableCell>
              </TableRow>
            </TableBody>
          </FixedTable>
        </DialogContent>
        <DialogActions>
          <FlatSecondaryButton onClick={this.handleCloseButtonClick}>
            キャンセル
          </FlatSecondaryButton>
          <RaisedPrimaryButton onClick={this.handleAddButtonClick}>
            追加する
          </RaisedPrimaryButton>
        </DialogActions>
      </Fragment>
    );
  }
}

OpenDataJoinConfirmationDialog.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  groupId: PropTypes.number,
  jigyoshoList: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  target: PropTypes.object,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenDataJoinConfirmationDialog);

function mapStateToProps(state) {
  return {
    jigyoshoList: state.jigyoshoList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      reloadListPage: (search = "") => {
        const searchParams = new URLSearchParams(search);
        searchParams.set("timestamp", new Date().getTime());
        search = searchParams.toString();
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho,
            search,
          })
        );
      },
    },
  };
}
