import React from "react";
import PropTypes from "prop-types";
import PrintTable from "../../../../app/ui/print/PrintTable";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const WatchTable = (props) => {
  const { service } = props;
  const { hokengaiService, watch } = service.blocks;

  return (
    <PrintTable {...props}>
      <PrintTableNode width="105px">
        {hokengaiService.jigyoshomei}
        <div className="c-table-frame__updated-at">
          ({service.userUpdatedAt})
        </div>
      </PrintTableNode>
      <PrintTableNode width="150px">{service.jusho}</PrintTableNode>
      <PrintTableNode width="112px">
        {hokengaiService.denwabango}
      </PrintTableNode>
      <PrintTableNode width="75px" center>
        {hokengaiService.teikyubi.join("・")}
      </PrintTableNode>
      <PrintTableNode width="120px" center>
        {hokengaiService.eigyojikan}
      </PrintTableNode>
      <PrintTableNode width="145px" center>
        {serviceTeikyochiiki(hokengaiService)}
      </PrintTableNode>
      <PrintTableNode width="155px">
        {watch.teikyoService.map((it) => (
          <div key={it}>・{it}</div>
        ))}
      </PrintTableNode>
      <PrintTableNode width="140px">
        {watch.menus.map((it) => (
          <div key={it.serviceMei}>
            {it.serviceMei}/{it.ryokin}
          </div>
        ))}
        <div>{watch.menusEtc ? "など" : ""}</div>
      </PrintTableNode>
    </PrintTable>
  );
};

function serviceTeikyochiiki(hokengaiService) {
  const LABEL_LIMIT = 65;
  let serviceTeikyochiiki = hokengaiService.serviceTeikyochiiki
    .split("\n")
    .join(" ");
  serviceTeikyochiiki =
    serviceTeikyochiiki && serviceTeikyochiiki.length > LABEL_LIMIT
      ? serviceTeikyochiiki.substr(0, LABEL_LIMIT) + "..."
      : serviceTeikyochiiki;

  return serviceTeikyochiiki;
}

WatchTable.propTypes = {
  onServiceButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default WatchTable;
