import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import For from "../../../../../app/ui/layout/For";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Mount from "../../../../../app/ui/layout/Mount";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

/**
 * 訪問系サービススタッフフォーム.
 */
class HomonkeiServiceStaffForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderStaff()}
        {this.renderShokuinbetsu()}
        {this.renderShikakushoyusha()}
        {this.renderSonota()}
      </Fragment>
    );
  }

  renderStaff() {
    const { serviceShubetsuCode } = this.props;
    return (
      <FormGrouping title="スタッフ">
        {this.renderTokucho()}
        {this.renderSeibetsu()}
        {this.renderNenreibetsu()}
        <HorizontalLabelLayout labelText="保有資格" recommended>
          {this.renderText({
            name: "kanrishahoyushikaku",
            label: "管理者保有資格",
            hintText: "例）看護師（排泄・褥瘡ケア専門看護師）",
          })}
          <Mount
            if={[
              ServiceShubetsuType.訪問介護,
              ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
            ]
              .map((it) => it.code)
              .includes(serviceShubetsuCode)}
            then={() =>
              this.renderText({
                name: "serviceTeikyosekininshahoyushikaku",
                label: "サービス提供責任者保有資格",
              })
            }
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderShokuinbetsu() {
    const { serviceShubetsuCode } = this.props;
    return (
      <FormGrouping title="職員別人数">
        <Mount
          if={[
            ServiceShubetsuType.訪問入浴介護,
            ServiceShubetsuType.訪問看護,
            ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
          ]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() =>
            this.renderJokinHijokinFull({
              namePrefix: "kangoshokuin",
              label: "看護職員",
              required: true,
            })
          }
        />
        <Mount
          if={[
            ServiceShubetsuType.訪問看護,
            ServiceShubetsuType.訪問リハビリテーション,
          ]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() =>
            this.renderJokinHijokinFull({
              namePrefix: "rehabilitationShokuin",
              label: "リハビリ職員",
              required: true,
            })
          }
        />
        <Mount
          if={[
            ServiceShubetsuType.訪問介護,
            ServiceShubetsuType.訪問入浴介護,
            ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
          ]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() =>
            this.renderJokinHijokinFull({
              namePrefix: "homonkaigoin",
              label: "訪問介護員",
              recommended: true,
            })
          }
        />
        {this.renderKeiken5nenWariai()}
      </FormGrouping>
    );
  }

  renderShikakushoyusha() {
    const { serviceShubetsuCode } = this.props;
    return (
      <FormGrouping title="資格保有者数">
        <Mount
          if={[
            ServiceShubetsuType.訪問介護,
            ServiceShubetsuType.訪問入浴介護,
            ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
          ]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() =>
            this.renderJokinHijokinMin({
              namePrefix: "kaigofukushishi",
              label: "介護福祉士",
            })
          }
        />
        <Mount
          if={[ServiceShubetsuType.定期巡回_随時対応型訪問介護看護]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => (
            <Fragment>
              {this.renderJokinHijokinMin({
                namePrefix: "shakaifukushishi",
                label: "社会福祉士",
              })}
              {this.renderJokinHijokinMin({
                namePrefix: "kaigoshienSemmonin",
                label: "介護支援専門員",
              })}
            </Fragment>
          )}
        />
        <Mount
          if={[
            ServiceShubetsuType.訪問介護,
            ServiceShubetsuType.訪問入浴介護,
            ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
          ]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => (
            <Fragment>
              {this.renderJokinHijokinMin({
                namePrefix: "kaigoshokuinjitsumushakenshu",
                label: "介護職員実務者研修",
              })}
              {this.renderJokinHijokinMin({
                namePrefix: "kaigoshokuinshoninshakenshu",
                label: "介護職員初任者研修",
              })}
            </Fragment>
          )}
        />
        <Mount
          if={[ServiceShubetsuType.定期巡回_随時対応型訪問介護看護]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() =>
            this.renderJokinHijokinMin({ namePrefix: "ishi", label: "医師" })
          }
        />
        <Mount
          if={[
            ServiceShubetsuType.訪問看護,
            ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
          ]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() =>
            this.renderJokinHijokinMin({
              namePrefix: "hokenshi",
              label: "保健師",
            })
          }
        />
        <Mount
          if={[
            ServiceShubetsuType.訪問入浴介護,
            ServiceShubetsuType.訪問看護,
            ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
          ]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => (
            <Fragment>
              {this.renderJokinHijokinMin({
                namePrefix: "kangoshi",
                label: "看護師",
              })}
              {this.renderJokinHijokinMin({
                namePrefix: "junkangoshi",
                label: "准看護師",
              })}
              {this.renderShikakuUmu({
                namePrefix: "semmonNinteikangoshi",
                label: "専門・認定看護師",
              })}
            </Fragment>
          )}
        />
        <Mount
          if={[
            ServiceShubetsuType.訪問看護,
            ServiceShubetsuType.訪問リハビリテーション,
          ]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => (
            <Fragment>
              {this.renderJokinHijokinMin({
                namePrefix: "rigakuryohoshi",
                label: "理学療法士",
              })}
              {this.renderJokinHijokinMin({
                namePrefix: "sagyoryohoshi",
                label: "作業療法士",
              })}
              {this.renderJokinHijokinMin({
                namePrefix: "gengochokakushi",
                label: "言語聴覚士",
              })}
              {this.renderShikakuUmu({
                namePrefix: "semmonNinteiryohoshi",
                label: "専門・認定療法士",
              })}
            </Fragment>
          )}
        />
        <Mount
          if={[
            ServiceShubetsuType.訪問介護,
            ServiceShubetsuType.訪問入浴介護,
            ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
          ]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => (
            <Fragment>{this.renderKaigoProfessionalCareerDani()}</Fragment>
          )}
        />
      </FormGrouping>
    );
  }

  renderSonota() {
    return (
      <FormGrouping title="その他">{this.renderSonotaFields()}</FormGrouping>
    );
  }

  renderTokucho() {
    const { bindingProps } = this.props;
    return (
      <HorizontalLabelLayout labelText="特徴">
        <Textarea name="tokucho" {...bindingProps} />
      </HorizontalLabelLayout>
    );
  }

  renderHeadCount({ name, label }) {
    const { bindingProps } = this.props;
    return (
      <RatioBlock divide={5} key={name}>
        <SpaceOut block>
          <Mount if={label}>
            <StaticLabel>{label}</StaticLabel>
          </Mount>
          <UnitLayout unitText="人">
            <InputTypeNumber name={name} {...bindingProps} />
          </UnitLayout>
        </SpaceOut>
      </RatioBlock>
    );
  }

  renderHeadCountConversion({ name, label }) {
    const { bindingProps } = this.props;
    return (
      <RatioBlock divide={5} key={name}>
        <SpaceOut block>
          <Mount if={label}>
            <StaticLabel>{label}</StaticLabel>
          </Mount>
          <UnitLayout unitText="人">
            <InputTypeText name={name} hintText="0.0" {...bindingProps} />
          </UnitLayout>
        </SpaceOut>
      </RatioBlock>
    );
  }

  renderPercentage({ name, label }) {
    const { bindingProps } = this.props;
    return (
      <RatioBlock divide={5} key={name}>
        <SpaceOut block>
          <Mount if={label}>
            <StaticLabel>{label}</StaticLabel>
          </Mount>
          <UnitLayout unitText="％">
            <InputTypeNumber name={name} {...bindingProps} />
          </UnitLayout>
        </SpaceOut>
      </RatioBlock>
    );
  }

  renderText({ name, label, hintText = "" }) {
    const { bindingProps } = this.props;
    return (
      <SpaceOut block>
        <Mount if={label}>
          <StaticLabel>{label}</StaticLabel>
        </Mount>
        <InputTypeText name={name} hintText={hintText} {...bindingProps} />
      </SpaceOut>
    );
  }

  renderSeibetsu() {
    return (
      <HorizontalLabelLayout labelText="性別従業員数" required>
        <div>
          <For
            in={[
              { name: "seibetsuDansei", label: "男性従業員数" },
              { name: "seibetsuJosei", label: "女性従業員数" },
            ]}
            each={(it) => this.renderHeadCount(it)}
          />
        </div>
      </HorizontalLabelLayout>
    );
  }

  renderNenreibetsu() {
    return (
      <HorizontalLabelLayout labelText="年齢別従業員数" recommended>
        <div>
          <For
            in={[
              { name: "nenreibetsu20dai", label: "20代従業員" },
              { name: "nenreibetsu30dai", label: "30代従業員" },
              { name: "nenreibetsu40dai", label: "40代従業員" },
              { name: "nenreibetsu50dai", label: "50代従業員" },
              { name: "nenreibetsu60dai", label: "60代以上従業員" },
            ]}
            each={(it) => this.renderHeadCount(it)}
          />
        </div>
      </HorizontalLabelLayout>
    );
  }

  renderJokinHijokinFull({ namePrefix, label, required, recommended }) {
    return (
      <div>
        <HorizontalLabelLayout labelText={label}></HorizontalLabelLayout>
        <HorizontalLabelLayout required={required} recommended={recommended}>
          <StaticLabel>常勤・非常勤別</StaticLabel>
          <div>
            <For
              in={[
                { name: `${namePrefix}Jokin`, label: "常勤" },
                { name: `${namePrefix}Hijokin`, label: "非常勤" },
              ]}
              each={(it) => this.renderHeadCount(it)}
            />
            {this.renderHeadCountConversion({
              name: `${namePrefix}Jokinkansan`,
              label: "常勤換算",
            })}
          </div>
          <StaticLabel>経験年数別</StaticLabel>
          <div>
            <For
              in={[
                { name: `${namePrefix}Keiken1miman`, label: "1年未満" },
                { name: `${namePrefix}Keiken1ijo3miman`, label: "〜3年未満" },
                { name: `${namePrefix}Keiken3ijo5miman`, label: "〜5年未満" },
                { name: `${namePrefix}Keiken5ijo10miman`, label: "〜10年未満" },
                { name: `${namePrefix}Keiken10ijo`, label: "10年以上" },
              ]}
              each={(it) => this.renderHeadCount(it)}
            />
          </div>
        </HorizontalLabelLayout>
      </div>
    );
  }

  renderKeiken5nenWariai() {
    return (
      <HorizontalLabelLayout
        labelText="経験年数5年以上の従業員の割合"
        recommended
      >
        {this.renderPercentage({ name: "keiken5nenWariai" })}
      </HorizontalLabelLayout>
    );
  }

  renderJokinHijokinMin({ namePrefix, label }) {
    return (
      <HorizontalLabelLayout labelText={label} recommended>
        <div>
          <For
            in={[
              { name: `${namePrefix}Jokin`, label: "常勤" },
              { name: `${namePrefix}Hijokin`, label: "非常勤" },
            ]}
            each={(it) => this.renderHeadCount(it)}
          />
        </div>
      </HorizontalLabelLayout>
    );
  }

  renderShikakuUmu({ namePrefix, label }) {
    const { bindingProps } = this.props;
    return (
      <HorizontalLabelLayout labelText={label}>
        {this.renderHeadCount({ name: `${namePrefix}Ninzu`, label: "人数" })}
        <StaticLabel>資格種類</StaticLabel>
        <InputTypeText name={`${namePrefix}Shikaku`} {...bindingProps} />
      </HorizontalLabelLayout>
    );
  }

  renderKaigoProfessionalCareerDani() {
    return (
      <Fragment>
        <HorizontalLabelLayout labelText="介護プロフェッショナルキャリア段位">
          {this.renderHeadCount({ name: "kaigoProfessionalCareerDani" })}
        </HorizontalLabelLayout>
      </Fragment>
    );
  }

  renderSonotaFields() {
    const { bindingProps, masterData } = this.props;
    return (
      <Fragment>
        <HorizontalLabelLayout labelText="その他">
          <OptionsCheckbox
            name="sonotaTsuika"
            options={masterData.homonkeiService_staffSonota}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="その他（自由記述）">
          <Textarea name="sonota" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </Fragment>
    );
  }
}

HomonkeiServiceStaffForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceStaffForm
);
