import React, { Fragment } from "react";
import PropTypes from "prop-types";

const StaffTable = ({ staff, afterTable }) => {
  return (
    <Fragment>
      <div className="c-blue-heading">
        <div className="c-blue-heading__text">スタッフ</div>
      </div>
      <div className="p-office-body-sep">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">職員数</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">
                    生活相談員
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {staff.summary.seikatsusodanin}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">看護職員</div>
                  <div className="p-office-body-table2__td-val">
                    {staff.summary.kangoshokuin}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">介護職員</div>
                  <div className="p-office-body-table2__td-val">
                    {staff.summary.kaigoshokuin}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    機能訓練指導員
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {staff.summary.kinokunrenshidoin}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    その他職員
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {staff.summary.sonotaShokuinGokei}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    職員1人あたりご利用者数
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {staff.kangoKaigoshokuinWariai}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-office-body-sep__right">
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">有資格者数</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">
                    理学療法士
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {staff.summary.rigakuryohoshi}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    作業療法士
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {staff.summary.sagyoryohoshi}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    言語聴覚士
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {staff.summary.gengochokakushi}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    柔道整復師
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {staff.summary.judoseifukushi}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    あん摩マッサージ指圧師
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {staff.summary.ammaMassageShiatsushi}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          {afterTable}
        </div>
      </div>
    </Fragment>
  );
};

StaffTable.propTypes = {
  staff: PropTypes.object.isRequired,
  afterTable: PropTypes.node,
};

export default StaffTable;
