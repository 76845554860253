import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import * as jigyoshoFormActions from "../../../../state/jigyosho-form/actions";
import * as serviceContentActions from "../../../../state/service-content/actions";
import {
  jigyoshoAttributeTypeValueOf,
  jigyoshoAttributeLabelOf,
} from "../../../../app/domain/jigyosho/JigyoshoAttributeType";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";
import { jigyoshoAttributeFormTypeValueOf } from "./form/JigyoshoAttributeFormType";
import { Card } from "@material-ui/core";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import Parametername from "../../../../app/naming/Parametername";

class JigyoshoAttributePage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.gotoPreviousPageOrDefault = this.gotoPreviousPageOrDefault.bind(this);
  }

  initializeFields(props) {
    const { params } = props.match;
    this.id = params.id;
    this.type = params.type;

    const { search } = props.location;
    const searchParams = new URLSearchParams(search);
    this.serviceShubetsuCode = searchParams.get(
      Parametername.serviceShubetsuCode
    );
    this.serviceShubetsuCategoryCode = searchParams.get(
      Parametername.serviceShubetsuCategoryCode
    );
    this.prevPathname = searchParams.get(Parametername.pathname);
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.requestAttribute(this.id, this.type, this.serviceShubetsuCode);
  }

  handleBackButtonClick() {
    this.gotoPreviousPageOrDefault();
  }

  handleSaveButtonClick() {
    const { actions, jigyoshoForm } = this.props;
    const { form } = jigyoshoForm;
    // フォーム値「住所のみ変更」が有効な場合、住所変更APIを使用
    var type =
      form.getByName("jushoOnly") || false
        ? JigyoshoAttributeType.jushoJigyoshoJoho.name
        : (type =
            form.getByName("jushoHokengaiOnly") || false
              ? JigyoshoAttributeType.jushoHokengaiJigyoshoJoho.name
              : this.type);
    actions.submitToEditAttribute(this.id, type, form, () =>
      this.gotoPreviousPageOrDefault()
    );
  }

  gotoPreviousPageOrDefault() {
    const { actions } = this.props;
    if (this.prevPathname) {
      actions.gotoPage(this.prevPathname);
    } else {
      actions.gotoStatusPage(this.id);
    }
  }

  createBindingProps() {
    const { form } = this.props.jigyoshoForm;
    const { changeForm: onChange } = this.props.actions;
    return { form, onChange };
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          {this.renderHead()}
          {this.renderNavigation()}
          {this.renderForm()}
        </SideMargin>
      </Boundary>
    );
  }

  renderHead() {
    const jigyoshoAttributeType = jigyoshoAttributeTypeValueOf(this.type);
    const jigyoshoAttributeLabel = jigyoshoAttributeLabelOf(
      jigyoshoAttributeType,
      this.serviceShubetsuCode
    );
    return <BackOfficeHead>{jigyoshoAttributeLabel}の入力</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderForm() {
    const { jigyoshoForm } = this.props;
    if (jigyoshoForm.formIsLoading) return <CenteredCircularProgress />;
    if (!jigyoshoForm.form.typeIs(this.type)) return null;

    const type = jigyoshoAttributeFormTypeValueOf(this.type);
    const FormComponent = type.component;
    const bindingProps = this.createBindingProps();
    return (
      <Card>
        <SideMargin top bottom>
          <FormComponent
            serviceShubetsuCategoryCode={this.serviceShubetsuCategoryCode}
            serviceShubetsuCode={this.serviceShubetsuCode}
            bindingProps={bindingProps}
          />
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton
                disabled={jigyoshoForm.formIsSubmitting}
                onClick={this.handleSaveButtonClick}
              >
                保存する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }
}

JigyoshoAttributePage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  jigyoshoForm: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  statusPathname: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JigyoshoAttributePage);

function mapStateToProps(state) {
  return {
    jigyoshoForm: state.jigyoshoForm,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    actions: {
      ...bindActionCreators(jigyoshoFormActions, dispatch),
      gotoStatusPage: (id) =>
        dispatch(push(props.statusPathname.replace(/:id/, id))),
      gotoPage: (pathname) => {
        dispatch(serviceContentActions.reset());
        dispatch(push(pathname));
      },
    },
  };
}
