import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import WebFrontHeader from "./WebFrontHeader";
import WebFrontFooter from "./WebFrontFooter";

class WebFrontNavlessLayout extends React.Component {
  render() {
    return (
      <div className="webfront c-header-page">
        <WebFrontHeader />
        <div className="l-content">
          {this.props.children}
          <WebFrontFooter />
        </div>
      </div>
    );
  }
}

WebFrontNavlessLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebFrontNavlessLayout);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
