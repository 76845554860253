import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";

const ServiceBlock = ({ service }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="サービス内容" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>提供サービス</th>
              <td>
                {service.teikyoService &&
                  service.teikyoService.map((it, index) => (
                    <div key={index}>・{it}</div>
                  ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

ServiceBlock.propTypes = {
  service: PropTypes.object.isRequired,
};

export default ServiceBlock;
