const ActionType = {
  REQUEST: "JIGYOSHO_FLIER_REQUEST",
  REQUEST_FINISHED: "JIGYOSHO_FLIER_REQUEST_FINISHED",
  CHANGE_SORT: "JIGYOSHO_FLIER_CHANGE_SORT",
  CHANGE_FORM: "JIGYOSHO_FLIER_CHANGE_FORM",
  REMOVE: "JIGYOSHO_FLIER_REMOVE",
  SUBMIT: "JIGYOSHO_FLIER_SBMIT",
  SUBMIT_FINISHED: "JIGYOSHO_FLIER_SBMIT_FINISHED",
  SUBMIT_FAILED: "JIGYOSHO_FLIER_SBMIT_FAILED",
};

export default ActionType;
