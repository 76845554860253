import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../state/service-search/actions";
import { push } from "react-router-redux";
import Pathname from "../../../app/naming/Pathname";
import {
  serviceShubetsuCategoryTypeValueOfHokennai,
  serviceShubetsuCategoryTypeValueOfHokengai,
  serviceShubetsuCategoryTypeValueOfKyotakukaigoshienjigyosho,
} from "../../../app/domain/jigyosho/ServiceShubetsuCategoryType";
import { foldersConverter } from "../../../state/service-search/query-string";
import { excludeMimamoriSuishinJigyo } from "../../../app/domain/jigyosho/ServiceShubetsuType";

class SharedFolderWidget extends React.Component {
  constructor(props) {
    super(props);
    this.handleFolderClick = this.handleFolderClick.bind(this);
  }

  handleFolderClick(code) {
    const { actions, serviceShubetsuMenu } = this.props;
    actions.gotoSharedFolderPage(
      serviceShubetsuMenu
        .filter((menu) => menu.category.code == code)
        .map((it) => it.code),
      this.props.condition
    );
  }

  countOf(code) {
    const { folders } = this.props;
    return folders
      .filter((it) => it.serviceShubetsuCategoryCode == code)
      .reduce((count, it) => count + it.count, 0);
  }

  render() {
    return (
      <Fragment>
        <div className="p-folder">
          <div className="p-folder-title">
            <img src="/images/ttl_1.png" />
          </div>
          <div className="p-folder-body">
            <ul>
              {serviceShubetsuCategoryTypeValueOfHokennai().map((it) =>
                this.renderBook(it)
              )}
              {serviceShubetsuCategoryTypeValueOfKyotakukaigoshienjigyosho().map(
                (it) => this.renderBook(it)
              )}
            </ul>
          </div>
        </div>
        <div className="p-folder p-folder-footerbg">
          <div className="p-folder-title">
            <img src="/images/ttl_2.png" />
          </div>
          <div className="p-folder-body">
            <ul>{this.serviceShubetsuCategoryOfHokengai()}</ul>
          </div>
        </div>
      </Fragment>
    );
  }

  serviceShubetsuCategoryOfHokengai() {
    const { user } = this.props.authentication;
    const serviceShubetsuCategory = user.canHandleMimamoriSuishinJigyo()
      ? serviceShubetsuCategoryTypeValueOfHokengai()
      : serviceShubetsuCategoryTypeValueOfHokengai().filter((it) =>
          excludeMimamoriSuishinJigyo(it.code)
        );
    return serviceShubetsuCategory
      .filter((it) => it.activated)
      .map((it) => this.renderBook(it));
  }

  renderBook({ activated, code, label, imgSrc }) {
    const count = this.countOf(code);
    return (
      <li
        key={`category:${code}`}
        onClick={() => activated && this.handleFolderClick(code)}
        className={activated ? null : "not-published"}
      >
        {count ? <i className="badge">{count}</i> : null}
        <a>
          <div className="p-folder-body-title">{label}</div>
          <div className="p-folder-body-img">
            <img src={activated ? imgSrc.active : imgSrc.inactive} />
          </div>
        </a>
      </li>
    );
  }
}

SharedFolderWidget.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  condition: PropTypes.object.isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
  serviceShubetsuMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
  authentication: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(SharedFolderWidget);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoSharedFolderPage: (folders, condition) => {
        const search = foldersConverter(folders, condition).toSearch();
        dispatch(
          push({
            pathname: Pathname.webfront_folder,
            search,
          })
        );
      },
    },
  };
}
