import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import {
  MASTER_DATA_BUNDLE_FOR_HOKENGAI,
  withMasterData,
} from "../../../../../state/master-data";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Mount from "../../../../../app/ui/layout/Mount";
import Textarea from "../../../../../app/ui/form/Textarea";

class MimamoriSuishinJigyoDetailForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        {this.renderTeikyoChiikiTaishosha()}
        {this.renderServiceNaiyo()}
      </Fragment>
    );
  }

  renderTeikyoChiikiTaishosha() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="提供地域・対象者">
        <HorizontalLabelLayout labelText="見守り提供地域" recommended>
          <InputTypeText name="mimamoriTeikyoChiiki" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="見守り対象者" recommended>
          <InputTypeText name="mimamoriTaishosha" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderServiceNaiyo() {
    const { bindingProps, masterData } = this.props;
    const teikyoService = bindingProps.form.getByName("teikyoService");
    return (
      <FormGrouping title="見守り内容">
        <HorizontalLabelLayout labelText="見守り内容" required>
          <OptionsCheckbox
            name="teikyoService"
            options={masterData.mimamoriTeikyoService}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <Mount
          if={
            teikyoService[
              masterData.mimamoriTeikyoService.find(
                (it) => it.label === "その他"
              ).code
            ]
          }
        >
          <HorizontalLabelLayout labelText="その他" required>
            <InputTypeText name="sonotaMimamoriNaiyo" {...bindingProps} />
          </HorizontalLabelLayout>
        </Mount>
        <HorizontalLabelLayout labelText="見守り内容詳細" recommended>
          <Textarea
            name="mimamoriNaiyoDetail"
            characterLimit={400}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

MimamoriSuishinJigyoDetailForm.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOKENGAI)(
  connect(mapStateToProps, mapDispatchToProps)(MimamoriSuishinJigyoDetailForm)
);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
