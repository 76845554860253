import React from "react";
import { Route, Switch } from "react-router";
import Pathname from "../../../app/naming/Pathname";
import AgentCollectionPage from "./list/AgentCollectionPage";
import AgentCollectionItemStatusPage from "./status/AgentCollectionItemStatusPage";
import JigyoshoPhotoCollectivePage from "../jigyosho/photo/JigyoshoPhotoCollectivePage";
import JigyoshoFlierCollectivePage from "../jigyosho/flier/JigyoshoFlierCollectivePage";
import JigyoshoAttributePage from "../jigyosho/attribute/JigyoshoAttributePage";
import AgentCollectionAddPage from "./add/AgentCollectionAddPage";

const AgentCollectionRouter = () => {
  return (
    <Switch>
      <Route
        path={Pathname.backoffice_agent_collection_id_status}
        component={AgentCollectionItemStatusPage}
      />
      <Route
        path={Pathname.backoffice_agent_collection_id_photo}
        component={(props) => (
          <JigyoshoPhotoCollectivePage
            statusPathname={Pathname.backoffice_agent_collection_id_status}
            {...props}
          />
        )}
      />
      <Route
        path={Pathname.backoffice_agent_collection_id_flier}
        component={(props) => (
          <JigyoshoFlierCollectivePage
            statusPathname={Pathname.backoffice_agent_collection_id_status}
            {...props}
          />
        )}
      />
      <Route
        path={Pathname.backoffice_agent_collection_id_attributes_type}
        component={(props) => (
          <JigyoshoAttributePage
            statusPathname={Pathname.backoffice_agent_collection_id_status}
            {...props}
          />
        )}
      />
      <Route
        path={Pathname.backoffice_agent_collection_add}
        component={AgentCollectionAddPage}
      />
      <Route
        path={Pathname.backoffice_agent_collection}
        component={AgentCollectionPage}
      />
    </Switch>
  );
};

export default AgentCollectionRouter;
