import ActionType from "./reducer/ActionType";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import ApiPath from "../../app/fundamental/request/ApiPath";
import { defaultResponseHandler } from "../../app/fundamental/request";

export function clearMaintenance() {
  return {
    type: ActionType.CLEAR_MAINTENANCE,
  };
}

export function setMaintenance(message) {
  return {
    type: ActionType.SET_MAINTENANCE,
    payload: {
      message: message,
    },
  };
}

export function checkMaintenanceStatus(onEndOfMaintenance) {
  return (dispatch) => {
    const onSuccess = () => {
      clearMaintenance();
      onEndOfMaintenance();
    };
    ApiRequest.get(ApiPath.api_maintenance).request(
      defaultResponseHandler(dispatch, onSuccess)
    );
  };
}
