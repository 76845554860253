import ActionType from "./reducer/ActionType";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import ApiPath from "../../app/fundamental/request/ApiPath";
import {
  defaultUnauthorizedHander,
  defaultResponseHandler,
} from "../../app/fundamental/request";
import Parametername from "../../app/naming/Parametername";

export function request() {
  return (dispatch) => {
    dispatch({
      type: ActionType.REQUEST,
    });
    requestSharedFolderContents(dispatch);
  };
}

// serviceSubId:business_menu_indexのサービス枝番
export function share(id, serviceSubId) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SHARE,
    });
    requestToShare(dispatch, id, serviceSubId);
  };
}

// serviceSubId:business_menu_indexのサービス枝番
export function unshare(id, serviceSubId) {
  return (dispatch) => {
    dispatch({
      type: ActionType.UNSHARE,
    });
    requestToUnshare(dispatch, id, serviceSubId);
  };
}

// 共有フォルダ 取得
function requestSharedFolderContents(dispatch) {
  const url = ApiPath.api_folder_services;
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        services: json.services,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

// 共有フォルダ 追加
function requestToShare(dispatch, id, serviceSubId) {
  const searchParams = new URLSearchParams();
  if (serviceSubId) searchParams.set(Parametername.serviceSubId, serviceSubId);
  const url = `${ApiPath.api_folder_services_id.replace(
    /:id/,
    id
  )}?${searchParams.toString()}`;
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.SHARE_FINISHED,
      payload: {
        service: json,
      },
    });
  };
  ApiRequest.post(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

// 共有フォルダ 削除
function requestToUnshare(dispatch, id, serviceSubId) {
  const searchParams = new URLSearchParams();
  if (serviceSubId) searchParams.set(Parametername.serviceSubId, serviceSubId);
  const url = `${ApiPath.api_folder_services_id.replace(
    /:id/,
    id
  )}?${searchParams.toString()}`;
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.UNSHARE_FINISHED,
      payload: {
        service: json,
      },
    });
  };
  ApiRequest.delete(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}
