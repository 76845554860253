import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import ApiPath from "../../../app/fundamental/request/ApiPath";
import ApiRequest from "../../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../../app/fundamental/request";
import { formDataOf } from "../../../app/fundamental/data/FormData";

/* action type */
const ACTION_TYPE_PREFIX = "COMMENT_EDITFORM";

const ActionType = {
  FETCH: `${ACTION_TYPE_PREFIX}_FETCH`,
  FETCH_FINISHED: `${ACTION_TYPE_PREFIX}_FETCH_FINISHED`,
  CHANGE_FORM: `${ACTION_TYPE_PREFIX}_CHANGE_FORM`,
  SUBMIT: `${ACTION_TYPE_PREFIX}_SUBMIT`,
  SUBMIT_FINISHED: `${ACTION_TYPE_PREFIX}_SUBMIT_FINISHED`,
  SUBMIT_FAILED: `${ACTION_TYPE_PREFIX}_SUBMIT_FAILED`,
  REMOVE: `${ACTION_TYPE_PREFIX}_REMOVE`,
  REMOVE_FINISHED: `${ACTION_TYPE_PREFIX}_REMOVE_FINISHED`,
  REMOVE_FAILED: `${ACTION_TYPE_PREFIX}_REMOVE_FAILED`,
};

/* reducer */
const initialState = {
  form: formDataOf({}),
  isLoading: false,
  isSubmitting: false,
};

const reducers = reducersMapOf({
  [ActionType.FETCH]: function (state) {
    return {
      ...state,
      isLoading: true,
    };
  },
  [ActionType.FETCH_FINISHED]: function (state, action) {
    const { form } = action.payload;
    return {
      ...state,
      form: formDataOf(form),
      isLoading: false,
    };
  },
  [ActionType.CHANGE_FORM]: function (state, action) {
    const { name, value } = action.payload;
    return {
      ...state,
      form: state.form.setByName(name, value),
    };
  },
  [ActionType.SUBMIT]: function (state) {
    return {
      ...state,
      isSubmitting: true,
    };
  },
  [ActionType.SUBMIT_FINISHED]: function (state) {
    return {
      ...state,
      isSubmitting: false,
    };
  },
  [ActionType.SUBMIT_FAILED]: function (state) {
    return {
      ...state,
      isSubmitting: false,
    };
  },
  [ActionType.REMOVE]: function (state) {
    return {
      ...state,
      isSubmitting: true,
    };
  },
  [ActionType.REMOVE_FINISHED]: function (state) {
    return {
      ...state,
      isSubmitting: false,
    };
  },
  [ActionType.REMOVE_FAILED]: function (state) {
    return {
      ...state,
      isSubmitting: false,
    };
  },
});

export default reducers.createReducer(initialState);

/* action creater */
export function fetch(id, callback = () => {}) {
  return (dispatch) => {
    dispatch({
      type: ActionType.FETCH,
      payload: { id },
    });

    const url = ApiPath.api_comments_id_form.replace(/:id/, id);
    const onSuccess = (json) => {
      dispatch({
        type: ActionType.FETCH_FINISHED,
        payload: {
          form: json,
        },
      });
      setTimeout(callback);
    };
    ApiRequest.get(url).request(
      defaultResponseHandler(dispatch, onSuccess),
      defaultUnauthorizedHander(dispatch)
    );
  };
}

export function changeForm(name, value) {
  return {
    type: ActionType.CHANGE_FORM,
    payload: { name, value },
  };
}

export function submit(id, form, callback) {
  const url = ApiPath.api_comments_id_form.replace(/:id/, id);
  const message = "コメントを修正しました。";
  return (dispatch) => {
    const onSuccess = (form) => {
      dispatch({
        type: ActionType.SUBMIT_FINISHED,
      });
      setTimeout(() => callback(form));
    };
    const onFailure = () => dispatch({ type: ActionType.SUBMIT_FAILED });
    ApiRequest.post(url, form).request(
      withCompletionMessage(
        dispatch,
        message
      )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
      defaultUnauthorizedHander(dispatch)
    );
  };
}

export function remove(id, callback) {
  const url = ApiPath.api_comments_id.replace(/:id/, id);
  const message = "コメントを削除しました。";
  return (dispatch) => {
    const onSuccess = (form) => {
      dispatch({
        type: ActionType.REMOVE_FINISHED,
      });
      setTimeout(() => callback(form));
    };
    const onFailure = () => dispatch({ type: ActionType.REMOVE_FAILED });
    ApiRequest.delete(url).request(
      withCompletionMessage(
        dispatch,
        message
      )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
      defaultUnauthorizedHander(dispatch)
    );
  };
}
