import PropTypes from "prop-types";

const ValueWithHeadcountUnit = ({ value }) => {
  if (value == null) return "";
  return `${value}人`;
};

ValueWithHeadcountUnit.propTypes = {
  value: PropTypes.number,
};

export default ValueWithHeadcountUnit;
