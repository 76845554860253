import React, { Fragment } from "react";
import PropTypes from "prop-types";
import EigyoJikanTable from "../content/homonkeiService/EigyoJikanTable";
import ServiceTeikyoJikanTable from "../content/homonkeiService/ServiceTeikyoJikanTable";
import ServiceTeikyoChiikiHohoShudan from "../content/homonkeiService/ServiceTeikyoChiikiHohoShudan";
import ServiceNaiyo from "../content/homonkeiService/ServiceNaiyo";
import StaffTable from "../content/homonkeiService/StaffTable";
import Ryokin from "../content/homonkeiService/Ryokin";
import TaiokanoShojoTable from "../content/homonkeiService/TaiokanoShojoTable";
import BihinTable from "../content/homonkeiService/BihinTable";
import GaibuRenkeiTable from "../content/homonkeiService/GaibuRenkeiTable";
import RiyoshaTable from "../content/homonkeiService/RiyoshaTable";
import JigyoshoJohoTable from "../content/homonkeiService/JigyoshoJohoTable";

class HomonkeiServiceDetail extends React.Component {
  render() {
    return (
      <div>
        <div className="p-office-body">
          {this.renderEigyoJoho()}

          {this.renderServiceNaiyo()}

          {this.renderTaiokanoShojo()}

          {this.renderRiyosha()}

          {this.renderStaff()}

          {this.renderRyokin()}

          {this.renderBihin()}

          {this.renderGiaburenkei()}

          {this.renderJigyoshoJoho()}
        </div>
      </div>
    );
  }

  renderEigyoJoho() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">営業情報</div>
        </div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-title">営業時間</div>
            <EigyoJikanTable contents={this.props.contents} detail={true} />

            <div className="p-office-body-title">
              サービス提供地域・訪問手段
            </div>
            <ServiceTeikyoChiikiHohoShudan
              contents={this.props.contents}
              detail={true}
            />
          </div>

          <div className="p-office-body-sep__right">
            <div className="p-office-body-title">サービス提供時間</div>
            <ServiceTeikyoJikanTable
              contents={this.props.contents}
              detail={true}
            />
          </div>
        </div>
      </Fragment>
    );
  }

  renderServiceNaiyo() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">サービス内容</div>
        </div>
        <ServiceNaiyo contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderTaiokanoShojo() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">
            受入れ / 対応可能な症状・医療行為
          </div>
        </div>
        <TaiokanoShojoTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderRiyosha() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">ご利用者</div>
        </div>
        <RiyoshaTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderStaff() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">スタッフ</div>
        </div>
        <StaffTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderRyokin() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">料金</div>
        </div>
        <Ryokin contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderBihin() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">備品</div>
        </div>
        <BihinTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderGiaburenkei() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">外部連携</div>
        </div>
        <GaibuRenkeiTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderJigyoshoJoho() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">事業所情報</div>
        </div>
        <JigyoshoJohoTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }
}

HomonkeiServiceDetail.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default HomonkeiServiceDetail;
