import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";

function reset() {
  return {
    type: ActionType.RESET,
  };
}

export function requestBlankForm(id) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getBlankForm(dispatch, id);
  };
}

export function changeForm(name, value) {
  return {
    type: ActionType.CHANGE_FORM,
    payload: {
      name,
      value,
    },
  };
}

export function submitToReject(id, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToReject(dispatch, id, form, callback);
  };
}

function getBlankForm(dispatch, id) {
  const url = ApiPath.api_job_offer_id_reject.replace(/:id/, id);
  getForm(dispatch, url, "blank");
}

function getForm(dispatch, url, type) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        form: json,
        type,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function postToReject(dispatch, id, form, callback) {
  const url = ApiPath.api_job_offer_id_reject.replace(/:id/, id);
  post(dispatch, url, form, callback, "求人情報の掲載を非承認しました。");
}

function post(dispatch, url, form, callback = () => {}, message) {
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(callback);
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}
