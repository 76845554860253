import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";

class ServiceGyoseiJoseiHojo extends React.Component {
  render() {
    const { bindingProps } = this.props;
    return (
      <Fragment>
        <FormGrouping title="行政の助成制度や補助について">
          <HorizontalLabelLayout labelText="補助券の利用可否 " recommended>
            <InputTypeCheckbox
              name="ticketFlg"
              labelText="可"
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="備考">
            <InputTypeText name="ticketComment" {...bindingProps} />
          </HorizontalLabelLayout>
        </FormGrouping>
        {this.props.children}
      </Fragment>
    );
  }
}

ServiceGyoseiJoseiHojo.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default ServiceGyoseiJoseiHojo;
