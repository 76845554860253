import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Thumbnail from "../../search/Thumbnail";
import Mount from "../../../../app/ui/layout/Mount";
import ServiceShubetsuType, {
  serviceShubetsuTypeValueOf,
} from "../../../../app/domain/jigyosho/ServiceShubetsuType";
import TruncateText from "../../../../app/ui/text/TruncateText";
import { servicePrintTagsOf } from "../ServicePrintExtraTags";
import CommonUneiJohoBlock from "./hokennaiService/CommonUneiJohoBlock";
import RiyoshaBlock from "./tsushokeiService/RiyoshaBlock";
import ShokuinBlock from "./tsushokeiService/ShokuinBlock";
import CareBlock from "./tsushokeiService/CareBlock";
import RecreationBlock from "./tsushokeiService/RecreationBlock";
import RehabilitaionBlock from "./tsushokeiService/RehabilitationBlock";
import TeikyoJikanBlock from "./tsushokeiService/TeikyoJikanBlock";
import KaigoHosyuKasanBlock from "./tsushokeiService/KaigoHoshuKasanBlock";
import ValueWithHeadcountUnit from "../../../../app/ui/unit/ValueWithHeadcountUnit";
import ValueWithBusubetsuServiceTeikyojikanUnit from "../../../../../src/app/ui/unit/ValueWithBusubetsuServiceTeikyojikanUnit";
import ValueWithYubinMarkUnit from "../../../../app/ui/unit/ValueWithYubinMarkUnit";

class TsushokeiServicePrint extends React.Component {
  render() {
    const { contents } = this.props;
    return (
      <Fragment>
        {this.renderHead(contents)}
        <div className="print-body__block print-body__block--columns">
          <div>{this.renderPhoto(contents)}</div>
        </div>
        <div className="print-body__block print-body__block--two-columns">
          <div>
            {this.renderRiyoshaJoho(contents)}
            {this.renderShokuinTaisei(contents)}
          </div>
          <div>
            {this.renderCareJoho(contents)}
            {this.renderRecreation(contents)}
            {this.renderRehabilitation(contents)}
          </div>
        </div>
        <div className="print-body__block print-body__block--responsive-two-columns">
          <div>{this.renderServiceTeikyoJikan(contents)}</div>
          <div>{this.renderKaigohoshuKasan(contents)}</div>
        </div>
        <hr />
        {this.renderUneiJoho(contents)}
      </Fragment>
    );
  }

  renderHead(contents) {
    return (
      <div className="print-body__head">
        {this.renderHeadLeft(contents)}
        {this.renderHeadRight(contents)}
        <img src="/images/print_bg_light_yellow.png" />
      </div>
    );
  }

  renderHeadLeft(contents) {
    const { serviceShubetsuCode } = contents;
    const LABEL_LIMIT = 178;
    const serviceShubetsuType = serviceShubetsuTypeValueOf(serviceShubetsuCode);
    const tags = servicePrintTagsOf(this.props.contents);
    const { kihonjoho } = contents;
    return (
      <div>
        <div className="service-shubetsu">
          {tags.map((tag) => (
            <div key={tag}>
              {tag}
              <img
                style={{ borderRadius: "0 0 4pt 4pt" }}
                src="/images/print_bg_light_blue.png"
              />
            </div>
          ))}
        </div>
        <div className="eigyo-joho">
          <ul>
            {kihonjoho.yobotaio ? (
              <li className="open">
                予防
                <img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                予防
                <img src="/images/print_bg_light_gray.png" />
              </li>
            )}
          </ul>
          <ul>
            {kihonjoho.doyobieigyo ? (
              <li className="open">
                土<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                土<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {kihonjoho.nichiyobieigyo ? (
              <li className="open">
                日<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                日<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {kihonjoho.shukujitsueigyo ? (
              <li className="open">
                祝<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                祝<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
          </ul>
          <ul>
            {kihonjoho.sochotaio ? (
              <li className="open">
                早朝
                <img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                早朝
                <img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {kihonjoho.yakantaio ? (
              <li className="open">
                夜間
                <img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                夜間
                <img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            <Mount
              if={
                serviceShubetsuType !==
                ServiceShubetsuType.通所リハビリテーション
              }
            >
              {kihonjoho.shukuhakutaio ? (
                <li className="open">
                  宿泊
                  <img src="/images/print_bg_yellow.png" />
                </li>
              ) : (
                <li>
                  宿泊
                  <img src="/images/print_bg_light_gray.png" />
                </li>
              )}
            </Mount>
          </ul>
        </div>

        <div className="jigyoshomei">{kihonjoho.jigyoshomei}</div>
        <div className="rinen">
          <TruncateText maxLength={LABEL_LIMIT}>
            {kihonjoho.tokuchorinen}
          </TruncateText>
        </div>
      </div>
    );
  }

  renderHeadRight(contents) {
    const { kihonjoho } = contents;
    const LABEL_LIMIT = 46;
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>サ</span>
                  <span>ー</span>
                  <span>ビ</span>
                  <span>ス</span>
                </span>
                <span>
                  <span>提</span>
                  <span>供</span>
                  <span>時</span>
                  <span>間</span>
                </span>
              </th>
              <td colSpan="3" className="">
                <ValueWithBusubetsuServiceTeikyojikanUnit
                  value={kihonjoho.busubetsuServiceTeikyojikan}
                />
              </td>
            </tr>
            <tr>
              <th className="paddingTel">
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>T</span>
                  <span>E</span>
                  <span>L</span>
                </span>
              </th>
              <td className="fontTel">{kihonjoho.kokairenrakusaki.tel}</td>
              <Mount if={kihonjoho.kokairenrakusaki.fax}>
                <th className="paddingTel">
                  <img className="bgWhite" src="/images/print_bg_white.png" />
                  <span>
                    <span>F</span>
                    <span>A</span>
                    <span>X</span>
                  </span>
                </th>
                <td className="paddingTel">{kihonjoho.kokairenrakusaki.fax}</td>
              </Mount>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>住</span>
                  <span>所</span>
                </span>
              </th>
              <td colSpan="3">
                <div>
                  <ValueWithYubinMarkUnit
                    value={kihonjoho.kokaijusho.yubimbango}
                  />
                </div>
                <div>{kihonjoho.kokaijusho.jusho}</div>
              </td>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>サ</span>
                  <span>ー</span>
                  <span>ビ</span>
                  <span>ス</span>
                </span>
                <span>
                  <span>提</span>
                  <span>供</span>
                  <span>エ</span>
                  <span>リ</span>
                  <span>ア</span>
                </span>
              </th>
              <td colSpan="3">
                <TruncateText maxLength={LABEL_LIMIT}>
                  {kihonjoho.serviceTeikyoArea}
                </TruncateText>
              </td>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>利</span>
                  <span>用</span>
                  <span>定</span>
                  <span>員</span>
                </span>
              </th>
              <td colSpan="3">
                <ValueWithHeadcountUnit value={kihonjoho.teiin} />
              </td>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>定</span>
                  <span>休</span>
                  <span>日</span>
                </span>
              </th>
              <td colSpan="3">{kihonjoho.teikyubi}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderPhoto(contents) {
    const photo1 = contents.photos[0] || { imageUrl: "", description: "" };
    const photo2 = contents.photos[1] || { imageUrl: "", description: "" };
    const photo3 = contents.photos[2] || { imageUrl: "", description: "" };
    return (
      <div>
        <div className="print-body__thumbnail">
          <Thumbnail src={photo1.imageUrl} description={photo1.description} />
        </div>
        <div className="print-body__thumbnail">
          <Thumbnail src={photo2.imageUrl} description={photo2.description} />
        </div>
        <div className="print-body__thumbnail">
          <Thumbnail src={photo3.imageUrl} description={photo3.description} />
        </div>
      </div>
    );
  }

  renderRiyoshaJoho(contents) {
    const { riyoshajoho: riyosha } = contents;
    return <RiyoshaBlock riyosha={riyosha} />;
  }

  renderShokuinTaisei(contents) {
    const { shokuintaisei: shokuin } = contents;
    return <ShokuinBlock shokuin={shokuin} />;
  }

  renderCareJoho(contents) {
    const { careJoho: care } = contents;
    return <CareBlock care={care} />;
  }

  renderRecreation(contents) {
    const { recreation: recreation } = contents;
    return <RecreationBlock recreation={recreation} />;
  }

  renderRehabilitation(contents) {
    const { rehabilitation: rehabilitation } = contents;
    return <RehabilitaionBlock rehabilitation={rehabilitation} />;
  }

  renderServiceTeikyoJikan(contents) {
    const { serviceTeikyojikan, serviceShubetsuCode } = contents;
    const serviceShubetsuType = serviceShubetsuTypeValueOf(serviceShubetsuCode);
    return (
      <TeikyoJikanBlock
        serviceTeikyojikan={serviceTeikyojikan}
        serviceShubetsuType={serviceShubetsuType}
      />
    );
  }

  renderKaigohoshuKasan(contents) {
    const { kaigohoshukasan, serviceShubetsuCode } = contents;
    const serviceShubetsuType = serviceShubetsuTypeValueOf(serviceShubetsuCode);
    return (
      <KaigoHosyuKasanBlock
        kaigohoshukasan={kaigohoshukasan}
        serviceShubetsuType={serviceShubetsuType}
      />
    );
  }

  // 運営情報
  renderUneiJoho(contents) {
    const { uneijoho, serviceShubetsuCategoryCode } = contents;
    return (
      <CommonUneiJohoBlock
        uneiJoho={uneijoho}
        serviceShubetsuCategoryCode={serviceShubetsuCategoryCode}
      />
    );
  }
}

TsushokeiServicePrint.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default TsushokeiServicePrint;
