import ServiceShubetsuType, {
  serviceShubetsuTypeValueOf,
} from "../../../app/domain/jigyosho/ServiceShubetsuType";
import ServiceShubetsuCategoryType, {
  serviceShubetsuCategoryTypeValueOf,
} from "../../../app/domain/jigyosho/ServiceShubetsuCategoryType";

export function servicePrintTagsOf(contents) {
  return [contents.kihonjoho.serviceShubetsu]
    .reduce((tags, tag) => (tags.includes(tag) ? tags : tags.concat([tag])), [])
    .concat(servicePrintExtraTags(contents))
    .map(servicePrintTagMapper);
}

function servicePrintExtraTags(contents) {
  const tags = [];
  tags.push(yakantaiogata(contents));
  tags.push(serviceTaikei(contents));
  tags.push(tokkabunya(contents));
  tags.push(mimamorigyoshu(contents));
  tags.push(katsudoKayoinoba(contents));
  return flat(tags).filter((it) => it);
}

function flat(tags) {
  return tags.reduce((list, el) => {
    if (Object.prototype.toString.call(el) === "[object Array]")
      return list.concat(el);
    return list.concat([el]);
  }, []);
}

function tokkabunya(contents) {
  const targets = [
    ServiceShubetsuCategoryType.通所系サービス,
    ServiceShubetsuCategoryType.訪問系サービス,
  ];
  if (!contentsIsInServiceShubetsuCategory(contents, targets)) return null;
  const { kihonjoho } = contents;
  return kihonjoho.tokkabunya &&
    !["なし", "標準"].includes(kihonjoho.tokkabunya)
    ? kihonjoho.tokkabunya
    : null;
}

function yakantaiogata(contents) {
  const targets = [ServiceShubetsuType.訪問介護];
  if (!contentsIsInServiceShubetsu(contents, targets)) return null;
  return contents.kihonjoho.yakantaiogataService ? "夜間対応型" : null;
}

function serviceTaikei(contents) {
  const targets = [ServiceShubetsuType.定期巡回_随時対応型訪問介護看護];
  if (!contentsIsInServiceShubetsu(contents, targets)) return null;
  return contents.kihonjoho.serviceTaikei
    ? contents.kihonjoho.serviceTaikei
    : null;
}

function mimamorigyoshu(contents) {
  const targets = [ServiceShubetsuCategoryType.見守り推進事業];
  if (!contentsIsInServiceShubetsuCategory(contents, targets)) return null;
  const { blocks } = contents;
  return blocks.mimamoriSuishinJigyo.gyoshu;
}

function katsudoKayoinoba(contents) {
  const targets = [ServiceShubetsuCategoryType.活動_通いの場];
  if (!contentsIsInServiceShubetsuCategory(contents, targets)) return null;
  const { blocks } = contents;
  if (blocks.hokengaiServiceKatsudoKayoinoba == null) return null;
  return blocks.hokengaiServiceKatsudoKayoinoba.businessMenu;
}

function servicePrintTagMapper(el) {
  if (el === "訪問介護（夜間対応型含む）") return "訪問介護";
  return el;
}

function contentsIsInServiceShubetsu(contents, types) {
  const { serviceShubetsuCode } = contents;
  if (!serviceShubetsuCode) return false;
  const type = serviceShubetsuTypeValueOf(serviceShubetsuCode);
  return types.find((it) => it === type);
}

function contentsIsInServiceShubetsuCategory(contents, types) {
  const { serviceShubetsuCategoryCode } = contents;
  if (!serviceShubetsuCategoryCode) return false;
  const type = serviceShubetsuCategoryTypeValueOf(serviceShubetsuCategoryCode);
  return types.find((it) => it === type);
}
