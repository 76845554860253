import React, { Fragment } from "react";
import PropTypes from "prop-types";

const ShokujiTable = ({ shokuji, afterTable }) => {
  return (
    <Fragment>
      <div className="c-blue-heading">
        <div className="c-blue-heading__text">食事</div>
      </div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>食事提供時間</th>
              <td>{shokuji.shokujiTeikyo.join("、")}</td>
            </tr>
            <tr>
              <th>調理場所</th>
              <td>{shokuji.choribasho}</td>
            </tr>
            <tr>
              <th>備考（料金）</th>
              <td>{shokuji.biko}</td>
            </tr>
          </tbody>
        </table>

        {afterTable}
      </div>
    </Fragment>
  );
};

ShokujiTable.propTypes = {
  shokuji: PropTypes.object.isRequired,
  afterTable: PropTypes.node,
};

export default ShokujiTable;
