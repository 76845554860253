import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import ApiPath from "../../../app/fundamental/request/ApiPath";
import ApiRequest from "../../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../../app/fundamental/request";
import { formDataOf } from "../../../app/fundamental/data/FormData";

/* action type */
const ACTION_TYPE_PREFIX = "TOOLS";

const ActionType = {
  CHANGE_FORM: `${ACTION_TYPE_PREFIX}_CHANGE_FORM`,
  SUBMIT: `${ACTION_TYPE_PREFIX}_SUBMIT`,
  SUBMIT_FINISHED: `${ACTION_TYPE_PREFIX}_SUBMIT_FINISHED`,
  SUBMIT_FAILED: `${ACTION_TYPE_PREFIX}_SUBMIT_FAILED`,
};

/* reducer */
const initialState = {
  form: formDataOf({ idRange: "" }),
  isSubmitting: false,
};

const reducers = reducersMapOf({
  [ActionType.CHANGE_FORM]: function (state, action) {
    const { name, value } = action.payload;
    return {
      ...state,
      form: state.form.setByName(name, value),
    };
  },
  [ActionType.SUBMIT]: function (state) {
    return {
      ...state,
      isSubmitting: true,
    };
  },
  [ActionType.SUBMIT_FINISHED]: function (state) {
    return {
      ...state,
      isSubmitting: false,
    };
  },
  [ActionType.SUBMIT_FAILED]: function (state) {
    return {
      ...state,
      isSubmitting: false,
    };
  },
});

export default reducers.createReducer(initialState);

/* action creater */
export function changeForm(name, value) {
  return {
    type: ActionType.CHANGE_FORM,
    payload: { name, value },
  };
}

export function submit(form, callback) {
  const url = ApiPath.api_tools_ndxr;
  const message = `インデックスを更新しました。(idRange=${form.idRange})`;
  return (dispatch) => {
    const onSuccess = (/* ret */) => {
      dispatch({
        type: ActionType.SUBMIT_FINISHED,
      });
      setTimeout(() => callback(form));
    };
    const onFailure = () => dispatch({ type: ActionType.SUBMIT_FAILED });
    dispatch({ type: ActionType.SUBMIT });
    ApiRequest.post(url, form).request(
      withCompletionMessage(
        dispatch,
        message
      )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
      defaultUnauthorizedHander(dispatch)
    );
  };
}
