import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Textarea from "../../../../../app/ui/form/Textarea";

class HomeProvideOtherMenuForm extends React.Component {
  render() {
    return <Fragment>{this.renderMenu()}</Fragment>;
  }

  renderMenu() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="メニュー・活動内容">
        <HorizontalLabelLayout labelText="メニュー・活動名" recommended>
          <InputTypeText name="serviceName" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="メニュー・活動内容詳細" recommended>
          <Textarea name="menuComment" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HomeProvideOtherMenuForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default HomeProvideOtherMenuForm;
