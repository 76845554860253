import React from "react";
import PropTypes from "prop-types";
import PrintTable from "../../../../app/ui/print/PrintTable";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const BarberTable = (props) => {
  const { service } = props;
  const { hokengaiService, barber } = service.blocks;

  return (
    <PrintTable {...props}>
      <PrintTableNode width="105px">
        {hokengaiService.jigyoshomei}
        <div className="c-table-frame__updated-at">
          ({service.userUpdatedAt})
        </div>
      </PrintTableNode>
      <PrintTableNode width="150px">{service.jusho}</PrintTableNode>
      <PrintTableNode width="112px">
        {hokengaiService.denwabango}
      </PrintTableNode>
      <PrintTableNode width="75px" center>
        {hokengaiService.teikyubi.join("・")}
      </PrintTableNode>
      <PrintTableNode width="120px" center>
        {hokengaiService.eigyojikan}
      </PrintTableNode>
      <PrintTableNode width="125px" center>
        {serviceTeikyochiiki(hokengaiService)}
      </PrintTableNode>
      <PrintTableNode width="120px">
        {barber.teikyoService.map((it) => (
          <div key={it}>・{it}</div>
        ))}
      </PrintTableNode>
      <PrintTableNode width="130px">
        {barber.menus.map((it) => (
          <div key={it.serviceMei}>
            ・{it.serviceMei}
            <div style={{ textAlign: "right" }}>({it.ryokin})</div>
          </div>
        ))}
        <div>{barber.menusEtc ? "　など" : ""}</div>
      </PrintTableNode>
      <PrintTableNode width="100px">
        {barber.taiokanoNaShojo.map((it) => (
          <div key={it}>・{it}</div>
        ))}
      </PrintTableNode>
    </PrintTable>
  );
};

function serviceTeikyochiiki(hokengaiService) {
  const LABEL_LIMIT = 98;
  let serviceTeikyochiiki = hokengaiService.serviceTeikyochiiki
    .split("\n")
    .join(" ");
  serviceTeikyochiiki =
    serviceTeikyochiiki && serviceTeikyochiiki.length > LABEL_LIMIT
      ? serviceTeikyochiiki.substr(0, LABEL_LIMIT) + "..."
      : serviceTeikyochiiki;

  return serviceTeikyochiiki;
}

BarberTable.propTypes = {
  onServiceButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default BarberTable;
