import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputTypeTextWithClearButton from "../../../../app/ui/form/InputTypeTextWithClearButton";
import { enterKeyHandler } from "../../../../app/ui/form";
import { push } from "react-router-redux";
import * as actions from "../../../../state/job-offer-jigyosho-list/actions";
import GridRow from "../../../../app/ui/grid/GridRow";
import GridCell from "../../../../app/ui/grid/GridCell";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import StandardSelect from "../../../../app/ui/form/StandardSelect";
import { conditionConverter } from "../../../../state/jigyosho-list/query-string";
import { packInHokengaiService } from "../../jigyosho/add/JigyoshoAddPage";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";

class JobOfferJigyoshoListCondition extends React.Component {
  constructor(props) {
    super(props);
    this.handleEnterKeyDown = this.handleEnterKeyDown.bind(this);
    this.handleShozaichiClearButtonClick = this.handleShozaichiClearButtonClick.bind(
      this
    );
    this.handleKeywordClearButtonClick = this.handleKeywordClearButtonClick.bind(
      this
    );
  }

  handleEnterKeyDown() {
    this.props.onConditionChangeDone(this.props.condition);
  }

  handleShozaichiClearButtonClick() {
    const callback = () =>
      this.props.onConditionChangeDone(this.props.condition);
    this.props.onConditionChange("shozaichi", "", callback);
  }

  handleKeywordClearButtonClick() {
    const callback = () =>
      this.props.onConditionChangeDone(this.props.condition);
    this.props.onConditionChange("keyword", "", callback);
  }

  render() {
    const bindingProps = this.createBindingProps();
    const serviceShubetsuSelectBindingProps = this.createServiceShubetsuSelectBindingProps(
      bindingProps
    );
    const statusCodeSelectBindingProps = this.createStatusCodeSelectBindingProps(
      bindingProps
    );
    return (
      <div>
        <GridRow>
          <GridCell>
            <SpaceOut block>
              <InputTypeTextWithClearButton
                labelText="所在地"
                name="shozaichi"
                onKeyDown={enterKeyHandler(this.handleEnterKeyDown)}
                onClearButtonClick={this.handleShozaichiClearButtonClick}
                {...bindingProps}
              />
            </SpaceOut>
          </GridCell>
          <GridCell>
            <SpaceOut block>
              <StandardSelect
                labelText="サービス種別"
                name="serviceShubetsuCode"
                {...serviceShubetsuSelectBindingProps}
              />
            </SpaceOut>
          </GridCell>
          <GridCell>
            <SpaceOut block>
              <InputTypeTextWithClearButton
                labelText="法人名・事業所名・管理者名"
                name="keyword"
                onKeyDown={enterKeyHandler(this.handleEnterKeyDown)}
                onClearButtonClick={this.handleKeywordClearButtonClick}
                {...bindingProps}
              />
            </SpaceOut>
          </GridCell>
          <GridCell>
            <SpaceOut block>
              <StandardSelect
                labelText="ステータス"
                name="statusCode"
                {...statusCodeSelectBindingProps}
              />
            </SpaceOut>
          </GridCell>
          <GridCell className="search-area" grow={0.1}>
            <RaisedPrimaryButton onClick={this.handleEnterKeyDown}>
              検索
            </RaisedPrimaryButton>
          </GridCell>
        </GridRow>
      </div>
    );
  }

  createBindingProps() {
    return {
      form: this.props.condition,
      onChange: this.props.onConditionChange,
    };
  }

  createServiceShubetsuSelectBindingProps(bindingProps) {
    const { serviceShubetsuOptions: options } = this.props;
    return {
      ...bindingProps,
      blank: true,
      categorize: true,
      options: options.map(packInHokengaiService),
      onChange: (name, value) => {
        const callback = () =>
          this.props.onConditionChangeDone(this.props.condition);
        bindingProps.onChange(name, value, callback);
      },
    };
  }

  createStatusCodeSelectBindingProps(bindingProps) {
    const { statusCodeOptions: options } = this.props;
    return {
      ...bindingProps,
      blank: true,
      options: options,
      onChange: (name, value) => {
        const callback = () =>
          this.props.onConditionChangeDone(this.props.condition);
        bindingProps.onChange(name, value, callback);
      },
    };
  }
}

JobOfferJigyoshoListCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  serviceShubetsuOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  statusCodeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.object.isRequired,
  // on-event handlers
  onConditionChange: PropTypes.func.isRequired,
  onConditionChangeDone: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobOfferJigyoshoListCondition);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onConditionChange: (name, value, callback) => {
      dispatch(actions.changeCondition(name, value, callback));
    },
    onConditionChangeDone: (condition) => {
      const search = conditionConverter(condition).toSearch();
      dispatch(push({ search }));
    },
  };
}
