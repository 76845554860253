import React, { Fragment } from "react";
import PropTypes from "prop-types";
import PhotosBlock from "./general/PhotosBlock";
import {
  goToJigyoshoAttributeFormButton,
  gotoPhotoFormButton,
} from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";
import JigyoshoTable from "../content/shortstay/JigyoshoTable";
import EigyoJikanTable from "../content/shortstay/EigyoJikanTable";
import RehabilitationTable from "../content/shortstay/RehabilitationTable";
import RecreationTable from "../content/shortstay/RecreationTable";
import ShokujiTable from "../content/shortstay/ShokujiTable";
import NyuyokuTable from "../content/shortstay/NyuyokuTable";
import KyoshitsuTable from "../content/shortstay/KyoshitsuTable";
import StaffTable from "../content/shortstay/StaffTable";
import RyokinkasanTable from "../content/shortstay/RyokinkasanTable";
import MoshikomiTable from "../content/shortstay/MoshikomiTable";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";
import RyokinMeyasuTableSet from "../content/shortstay/RyokinMeyasuTableSet";

class ShortstayAbstract extends React.Component {
  render() {
    return (
      <div>
        <div className="p-office-body">
          {this.renderHead()}
          {this.renderCatchPhrase()}

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">{this.renderPhotos()}</div>
            <div className="p-office-body-sep__right">
              {this.renderRinenTokucho()}
              {this.renderEigyojikan()}
            </div>
          </div>

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">
              {this.renderRehabilitation()}
            </div>
            <div className="p-office-body-sep__right">
              {this.renderRecreation()}
            </div>
          </div>

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">
              {this.renderShokuji()}
            </div>
            <div className="p-office-body-sep__right">
              {this.renderNyuyoku()}
            </div>
          </div>

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">
              {this.renderKyoshitsu()}
            </div>

            <div className="p-office-body-sep__right">
              {this.renderMoshikomi()}
            </div>
          </div>

          {this.renderStaff()}
          {this.renderRyokin()}
          {this.renderJigyoshoJoho()}
        </div>
      </div>
    );
  }

  renderHead() {
    const { contents } = this.props;
    const {
      blocks: {
        shortstay: shortstay,
        shortstayJigyosho: jigyosho,
        shortstayEigyoJikan: eigyoJikan,
        shortstayKyoshitsu: kyoshitsu,
        shortstaySogei: sogei,
      },
    } = contents;

    return (
      <div className="c-dl u-border-bottom-b u-margin-b32">
        <dl className="u-margin-b8">
          <dt>住所</dt>
          <dd>{jigyosho.jusho}</dd>
          <dt>サービス提供対象</dt>
          <dd>{jigyosho.serviceTeikyoTaisho.join("、")}</dd>
          <dt>窓口営業日</dt>
          <dd>{eigyoJikan.eigyobi.join("、")}</dd>
          <dt>居室タイプ</dt>
          <dd>{kyoshitsu.kyoshitsuTypeList.join("、")}</dd>
          <dt>サービス提供地域</dt>
          <dd>{sogei.serviceTeikyochiiki}</dd>
          <dt>特徴</dt>
          <dd>{shortstay.tokucho.join("、")}</dd>
          <dt>定員</dt>
          <dd>{jigyosho.teiin}</dd>
          <dt>特記事項</dt>
          <dd>{jigyosho.tokkijiko}</dd>
        </dl>
        <div className="p-office-body-partition__right-text">
          最新更新日：{contents.userUpdatedAt}
          <br />
          印刷回数：{contents.printCount}
          <br />
        </div>
      </div>
    );
  }

  renderCatchPhrase() {
    const { contents } = this.props;
    const jigyoshoJoho = contents.blocks.shortstayJigyosho;

    return (
      <div className="p-office-body-catch">{jigyoshoJoho.catchPhrase}</div>
    );
  }

  renderPhotos() {
    const { contents } = this.props;
    const photos = contents.blocks.photos;

    return (
      <Fragment>
        <PhotosBlock photos={photos.list} />

        {gotoPhotoFormButton(this.props.contents)}
      </Fragment>
    );
  }

  renderRinenTokucho() {
    const { contents } = this.props;
    const jigyoshoJoho = contents.blocks.shortstayJigyosho;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">理念・特徴</div>
        </div>

        <div className="p-office-body-description">
          {jigyoshoJoho.tokuchoRinen}

          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.shortstayjigyosho
          )}
        </div>
      </Fragment>
    );
  }

  renderEigyojikan() {
    const { contents } = this.props;
    const sogei = contents.blocks.shortstaySogei;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">営業情報</div>
        </div>
        <div className="p-office-body-title">窓口対応時間</div>
        <EigyoJikanTable contents={this.props.contents} />
        <div className="p-office-body-title">サービス提供地域</div>
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>サービス提供地域</th>
                <td>{sogei.serviceTeikyochiiki}</td>
              </tr>
              <tr>
                <th>備考</th>
                <td>{sogei.serviceTeikyoChiikiBiko}</td>
              </tr>
            </tbody>
          </table>

          {goToJigyoshoAttributeFormButton(
            contents,
            JigyoshoAttributeType.shortstaySogei
          )}
        </div>
      </Fragment>
    );
  }

  renderRehabilitation() {
    const { serviceShubetsuCode } = this.props.contents;
    const title =
      ServiceShubetsuType.短期入所生活介護.code == serviceShubetsuCode
        ? "機能訓練"
        : "リハビリテーション";
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">{title}</div>
        </div>
        <RehabilitationTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderRecreation() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">レクリエーション・行事</div>
        </div>
        <RecreationTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderShokuji() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">食事</div>
        </div>
        <ShokujiTable contents={this.props.contents} detail={false} />
      </Fragment>
    );
  }

  renderNyuyoku() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">入浴</div>
        </div>
        <NyuyokuTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderKyoshitsu() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">居室</div>
        </div>
        <KyoshitsuTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderMoshikomi() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">お申込みについて</div>
        </div>
        <MoshikomiTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderStaff() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">スタッフ</div>
        </div>
        <StaffTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderRyokin() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">料金</div>
        </div>
        <RyokinkasanTable contents={this.props.contents} />
        <RyokinMeyasuTableSet contents={this.props.contents} />
        <div className="p-office-body-sep" />
      </Fragment>
    );
  }

  renderJigyoshoJoho() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">事業所情報</div>
        </div>
        <JigyoshoTable contents={this.props.contents} />
      </Fragment>
    );
  }
}

ShortstayAbstract.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default ShortstayAbstract;
