import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";

class ServiceYoyaku extends React.Component {
  render() {
    const { bindingProps } = this.props;
    const yoyakuItems = [
      { code: "reserveTelFlg", label: "TEL", orderNo: 1 },
      { code: "reserveFaxFlg", label: "FAX", orderNo: 2 },
      { code: "reserveEmailFlg", label: "Email", orderNo: 3 },
      { code: "reserveWebFlg", label: "Webフォーム", orderNo: 4 },
      { code: "reserveLineFlg", label: "LINE", orderNo: 5 },
      { code: "reservePostmailFlg", label: "郵便", orderNo: 6 },
    ];
    const shiharaiItems = [
      { code: "cashFlg", label: "現金", orderNo: 1 },
      { code: "transferFlg", label: "振込", orderNo: 2 },
      { code: "directDebitFlg", label: "口座振替", orderNo: 3 },
      { code: "creditCardFlg", label: "クレジット", orderNo: 4 },
      { code: "debitCardFlg", label: "Jデビット", orderNo: 5 },
      { code: "convenienceStoreFlg", label: "コンビニ決済", orderNo: 6 },
    ];
    return (
      <Fragment>
        <FormGrouping title="予約方法・お支払いについて">
          <HorizontalLabelLayout labelText="事前予約（連絡）" recommended>
            <InputTypeCheckbox
              name="servicePreRegistrationFlg"
              labelText="必要"
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="予約方法" recommended>
            <OptionsCheckbox
              name="yoyakuHoho"
              options={yoyakuItems}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="お支払い方法" recommended>
            <OptionsCheckbox
              name="shiharaiHoho"
              options={shiharaiItems}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          {this.props.children}
        </FormGrouping>
      </Fragment>
    );
  }
}

ServiceYoyaku.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default ServiceYoyaku;
