import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import Textarea from "../../../../../app/ui/form/Textarea";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import Mount from "../../../../../app/ui/layout/Mount";

class ServiceRyokin extends React.Component {
  render() {
    const { addOns = {}, bindingProps } = this.props;
    return (
      <Fragment>
        <FormGrouping title="料金について">
          <Mount
            if={addOns.costFree}
            then={() => (
              <HorizontalLabelLayout labelText="無料">
                <InputTypeCheckbox
                  name="costFree"
                  labelText="はい"
                  {...bindingProps}
                />
              </HorizontalLabelLayout>
            )}
          />
          <HorizontalLabelLayout
            labelText="１回あたりの費用（税込み）"
            recommended
          >
            <ShortInputWidth>
              <UnitLayout unitText="円">
                <InputTypeNumber name="costEachTimeFee" {...bindingProps} />
              </UnitLayout>
            </ShortInputWidth>
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="初期費用（税込み）" recommended>
            <ShortInputWidth>
              <UnitLayout unitText="円">
                <InputTypeNumber name="costInitialFee" {...bindingProps} />
              </UnitLayout>
            </ShortInputWidth>
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="年会費（税込み）" recommended>
            <ShortInputWidth>
              <UnitLayout unitText="円">
                <InputTypeNumber name="costAnnualFee" {...bindingProps} />
              </UnitLayout>
            </ShortInputWidth>
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="月会費（税込み）" recommended>
            <ShortInputWidth>
              <UnitLayout unitText="円">
                <InputTypeNumber name="costMonthlyFee" {...bindingProps} />
              </UnitLayout>
            </ShortInputWidth>
          </HorizontalLabelLayout>
          <HorizontalLabelLayout
            labelText="キャンセル時の扱いについて"
            recommended
          >
            <InputTypeText name="costCancel" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="交通費について" recommended>
            <Textarea name="costTraffic" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="備考">
            <Textarea name="costComment" {...bindingProps} />
          </HorizontalLabelLayout>
          {this.props.children}
        </FormGrouping>
      </Fragment>
    );
  }
}

ServiceRyokin.propTypes = {
  addOns: PropTypes.object,
  bindingProps: PropTypes.object.isRequired,
  children: PropTypes.node,
};

export default ServiceRyokin;
