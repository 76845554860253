import React, { Fragment } from "react";
import PropTypes from "prop-types";

const Thumbnail = (props) => {
  const src = props.src || "/images/noimage.svg";
  const description = props.description;

  return (
    <Fragment>
      <img src={src} />
      {(() => {
        if (description) {
          return <p className="caption">{description}</p>;
        }
      })()}
    </Fragment>
  );
};

Thumbnail.propTypes = {
  src: PropTypes.string,
  description: PropTypes.string,
};

export default Thumbnail;
