import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceNaiyoKaigo from "./ServiceNaiyoKaigo";
import ServiceNaiyoKango from "./ServiceNaiyoKango";
import ServiceNaiyoNyuyoku from "./ServiceNaiyoNyuyoku";
import ServiceNaiyoRehabilitation from "./ServiceNaiyoRehabilitation";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import ServiceNaiyoHokengaiService from "./ServiceNaiyoHokengaiService";

const ServiceNaiyo = (props) => {
  const { contents } = props;

  const serviceNaiyoComponentDeinition = {
    [ServiceShubetsuType.訪問介護.code]: [
      { title: "介護サービス", component: ServiceNaiyoKaigo },
      { title: "保険外サービス", component: ServiceNaiyoHokengaiService },
    ],
    [ServiceShubetsuType.訪問看護.code]: [
      {
        title: "看護サービス",
        component: ServiceNaiyoKango,
        extraProps: { showRehabiliSemmonshokuniyoruHomon: true },
      },
      { title: "保険外サービス", component: ServiceNaiyoHokengaiService },
    ],
    [ServiceShubetsuType.訪問入浴介護.code]: [
      { title: null, component: ServiceNaiyoNyuyoku },
    ],
    [ServiceShubetsuType.訪問リハビリテーション.code]: [
      { title: "リハビリテーション", component: ServiceNaiyoRehabilitation },
      { title: "保険外サービス", component: ServiceNaiyoHokengaiService },
    ],
    [ServiceShubetsuType.定期巡回_随時対応型訪問介護看護.code]: [
      { title: "介護サービス", component: ServiceNaiyoKaigo },
      { title: "看護サービス", component: ServiceNaiyoKango },
    ],
  };

  return (
    <Fragment>
      {serviceNaiyoComponentDeinition[contents.serviceShubetsuCode].map(
        (item, i) => {
          const ServiceNaiyoComponent = item.component;
          const extraProps = item.extraProps || {};
          return (
            <Fragment key={i}>
              <Mount if={item.title}>
                <div className="p-office-body-title">{item.title}</div>
              </Mount>
              <ServiceNaiyoComponent {...props} {...extraProps} />
            </Fragment>
          );
        }
      )}
    </Fragment>
  );
};

ServiceNaiyo.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default ServiceNaiyo;
