import React from "react";
import PropTypes from "prop-types";

const StaffSummaryKaigo = ({ contents }) => {
  const { homonkeiServiceStaff: staff } = contents.blocks;

  return (
    <div className="p-office-body-sep">
      <div className="p-office-body-sep__left">
        <div className="p-office-body-table2">
          <div className="p-office-body-table2__th">職員数</div>
          <div className="p-office-body-table2__td">
            <ul>
              <li>
                <div className="p-office-body-table2__td-name">訪問介護員</div>
                <div className="p-office-body-table2__td-val">
                  {staff.summary.homonkaigoin}
                </div>
              </li>
              <li>
                <div className="p-office-body-table2__td-name">
                  経験年数5年以上の従業員
                </div>
                <div className="p-office-body-table2__td-val">
                  {staff.keiken5nenWariai}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>管理者保有資格</th>
                <td>{staff.kanrishaHoyushikaku}</td>
              </tr>
              <tr>
                <th>
                  サービス提供責任者
                  <br />
                  保有資格
                </th>
                <td>{staff.serviceTeikyosekininshaHoyushikaku}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="p-office-body-sep__right">
        <div className="p-office-body-table2">
          <div className="p-office-body-table2__th">有資格者数</div>
          <div className="p-office-body-table2__td">
            <ul>
              <li>
                <div className="p-office-body-table2__td-name">介護福祉士</div>
                <div className="p-office-body-table2__td-val">
                  {staff.summary.kaigofukushishi}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

StaffSummaryKaigo.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default StaffSummaryKaigo;
