import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { changeForm, submit } from "../../state/tools/jigyosho-csv";
import { Fragment } from "react";
import InputTypeText from "../../app/ui/form/InputTypeText";
import InputTypePassword from "../../app/ui/form/InputTypePassword";

class JigyoshoCsv extends React.Component {
  handleSubmit = () => {
    const {
      jigyoshoCsv: { form },
      onFormSubmit,
    } = this.props;
    onFormSubmit(form);
  };

  bindingProps() {
    const {
      jigyoshoCsv: { form },
      onFormChange: onChange,
    } = this.props;
    return { form, onChange };
  }

  render() {
    return (
      <Fragment>
        <div>
          <h2>事業所CSVダウンロード</h2>
        </div>
        <div>
          <table>
            <tbody>
              <tr>
                <th>法人名</th>
                <td>
                  <InputTypeText name="hojinmei" {...this.bindingProps()} />
                  <br />
                  ※法人名はカンマ(,)区切りで複数条件の指定ができます。
                </td>
              </tr>
              <tr>
                <th>法人のグループID</th>
                <td>
                  <InputTypeText name="hojinGroupId" {...this.bindingProps()} />
                  <br />
                  （推奨）該当法人のグループIDも入力いただいた方がより正確に出力されます。
                </td>
              </tr>
              <tr>
                <th>ダウンロードキー</th>
                <td>
                  <InputTypePassword
                    name="downloadKey"
                    autoComplete="new-password"
                    {...this.bindingProps()}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="2">
                  <br />
                </td>
              </tr>
              <tr>
                <th></th>
                <td>
                  <button onClick={this.handleSubmit}>ダウンロード</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Fragment>
    );
  }
}

JigyoshoCsv.propTypes = {
  jigyoshoCsv: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(JigyoshoCsv);

function mapStateToProps(state) {
  return {
    jigyoshoCsv: state.toolsJigyoshoCsv,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onFormChange: (name, value) => dispatch(changeForm(name, value)),
    onFormSubmit: (form, callback) => dispatch(submit(form, callback)),
  };
}
