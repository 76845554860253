import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import {
  MASTER_DATA_BUNDLE_FOR_HOKENGAI,
  withMasterData,
} from "../../../../../state/master-data";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import Textarea from "../../../../../app/ui/form/Textarea";
import StandardSelect from "../../../../../app/ui/form/StandardSelect";
import FormGroupingDeleteButton from "../../../../../app/ui/button/FormGroupingDeleteButton";
import FlatPrimaryButton from "../../../../../app/ui/button/FlatPrimaryButton";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";

class TaxiMenuForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleTaxiMenuItemAdded = this.handleTaxiMenuItemAdded.bind(this);
    this.handleTaxiMenuItemRemoved = this.handleTaxiMenuItemRemoved.bind(this);
  }

  handleTaxiMenuItemAdded() {
    this.appendTaxiMenuItemForms({
      taxiMenuKindCode: "",
      name: "",
      detail: "",
      fee: "",
      unit: "",
      extra: "",
    });
  }

  handleTaxiMenuItemRemoved(index) {
    this.removeTaxiMenuItemForms(index);
  }

  getTaxiMenuItemForms() {
    const { bindingProps } = this.props;
    return [].concat(bindingProps.form.getByName("taxiMenuItemForms"));
  }

  appendTaxiMenuItemForms(form) {
    const { bindingProps } = this.props;
    const org = this.getTaxiMenuItemForms();
    bindingProps.onChange("taxiMenuItemForms", org.concat([form]));
  }

  removeTaxiMenuItemForms(index) {
    const { bindingProps } = this.props;
    const org = this.getTaxiMenuItemForms();
    bindingProps.onChange(
      "taxiMenuItemForms",
      org.filter((_, i) => i != index)
    );
  }

  render() {
    return (
      <Fragment>
        {this.getTaxiMenuItemForms().map((taxiMenu, index) => {
          return this.renderMenu(taxiMenu, index);
        })}
        {this.renderItemAdd()}
      </Fragment>
    );
  }

  renderItemAdd() {
    return (
      <FlatPrimaryButton onClick={this.handleTaxiMenuItemAdded}>
        項目を追加する
      </FlatPrimaryButton>
    );
  }

  renderMenu(taxiMenu, index) {
    const { bindingProps, masterData } = this.props;
    const name = `taxiMenuItemForms.` + index;
    return (
      <FormGrouping
        key={index}
        title={
          <Fragment>
            メニュー{index + 1}
            <FormGroupingDeleteButton
              onClick={() => this.handleTaxiMenuItemRemoved(index)}
            />
          </Fragment>
        }
      >
        <HorizontalLabelLayout labelText="区分" required>
          <ShortInputWidth>
            <StandardSelect
              name={`${name}.taxiMenuKindCode`}
              blank
              options={masterData.taxiMenuKind}
              {...bindingProps}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="車種・プラン" required>
          <InputTypeText name={`${name}.name`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="メニュー詳細">
          <Textarea name={`${name}.detail`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="初乗り料金（税込み）" required>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name={`${name}.fee`}
                hintText="0"
                {...bindingProps}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="初乗りの単位" required>
          <ShortInputWidth>
            <UnitLayout>
              <InputTypeText
                name={`${name}.unit`}
                hintText="例）7.5km"
                {...bindingProps}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="加算料金（税込み）・単位" required>
          <InputTypeText
            name={`${name}.extra`}
            hintText="例）以降272m毎に90円加算"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

TaxiMenuForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOKENGAI)(TaxiMenuForm);
