import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../../state/information/actions";
import Pathname from "../../../app/naming/Pathname";
import Boundary from "../../../app/ui/layout/Boundary";
import SideMargin from "../../../app/ui/layout/SideMargin";
import { Paper } from "@material-ui/core";
import FlatPrimaryButton from "../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import BackOfficeHead from "../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../app/ui/structure/BackOfficeNavigation";
import CenteredCircularProgress from "../../../app/ui/progress/CenteredCircularProgress";
import For from "../../../app/ui/layout/For";

class InformationMessageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    // 「戻る・キャンセル」クリックイベント
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  // 初期化
  initializeFields(props) {
    const { params } = props.match;
    this.id = +params.id;
  }

  componentDidMount() {
    const { actions } = this.props;
    // 詳細データ取得
    actions.requestDetail(this.id);
  }

  // 「戻る・キャンセル」クリックイベント
  handleBackButtonClick() {
    const { actions } = this.props;
    actions.gotoback();
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          <BackOfficeHead>お知らせ</BackOfficeHead>
          {this.renderNavigation()}
          {this.renderInformationDetail()}
        </SideMargin>
      </Boundary>
    );
  }

  renderInformationDetail() {
    const { informationForm } = this.props;
    const { details, detailsIsLoading } = informationForm;
    if (detailsIsLoading) return <CenteredCircularProgress />;
    if (!details || details.id !== this.id) return null;
    return (
      <Paper style={styles.paper}>
        <div>
          {" "}
          {details.registDate} {this.renderNewFor(details)}
        </div>
        <div style={styles.title}>{details.title}</div>
        <div
          style={styles.detail}
          dangerouslySetInnerHTML={{ __html: details.detail }}
        />
        <For
          in={details.items}
          each={(it, index) => (
            <div key={index} style={styles.imgBlock}>
              <img src={it.imageUrl} style={styles.img} />
            </div>
          )}
        />
      </Paper>
    );
  }

  // NEW
  renderNewFor(it) {
    if (!it.newFlag) return null;
    return <span style={styles.new}>NEW</span>;
  }

  // 戻る
  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }
}

InformationMessageDetail.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  informationForm: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationMessageDetail);

function mapStateToProps(state) {
  return {
    informationForm: state.informationForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoback: () =>
        dispatch(
          push({
            pathname: Pathname.backoffice_information,
          })
        ),
    },
  };
}

const styles = {
  paper: {
    padding: "15px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "large",
    padding: "10px 0px",
    lineHeight: "1.7em",
    borderBottom: "1px solid #dddddd",
  },
  detail: {
    padding: "20px 15px 15px 15px",
    lineHeight: "1.7em",
    whiteSpace: "pre-line",
  },
  new: {
    color: "red",
  },
  imgBlock: {
    padding: "10px 0px",
    textAlign: "center",
  },
  img: {
    maxWidth: "95%",
    width: 800,
  },
};
