import React from "react";
import PropTypes from "prop-types";
import PrintTable from "../../../../app/ui/print/PrintTable";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const TsushokeiServiceTable = (props) => {
  const { service } = props;
  const hokennaiService = service.blocks.hokennaiService;
  const tsushokeiService = service.blocks.tsushokeiService;

  return (
    <PrintTable {...props}>
      <PrintTableNode width="105px">
        {hokennaiService.jigyoshomei}
        <div className="c-table-frame__updated-at">
          ({service.userUpdatedAt})
        </div>
      </PrintTableNode>
      <PrintTableNode width="90px">
        {hokennaiService.serviceShubetsu}
        <div>
          {tsushokeiService.tokkabunya
            ? `（${tsushokeiService.tokkabunya}）`
            : ""}
        </div>
      </PrintTableNode>
      <PrintTableNode width="150px">{service.jusho}</PrintTableNode>
      <PrintTableNode width="112px">{hokennaiService.tel}</PrintTableNode>
      <PrintTableNode width="15px" center>
        {tsushokeiService.teikyoService.filter((it) => it === "予防給付相当")
          .length > 0 ||
        tsushokeiService.serviceTaishosha.filter((it) => it === "要支援")
          .length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode minWidth="15px" center>
        {hokennaiService.eigyobi.filter((it) => it === "土曜").length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode minWidth="15px" center>
        {hokennaiService.eigyobi.filter((it) => it === "日曜").length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode minWidth="15px" center>
        {hokennaiService.eigyobi.filter((it) => it === "祝日").length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode width="15px" center>
        {tsushokeiService.jikangaitaio
          .filter((it) => it[0] === "早朝")
          .map((it) => it[1])}
      </PrintTableNode>
      <PrintTableNode width="15px" center>
        {tsushokeiService.jikangaitaio
          .filter((it) => it[0] === "夜間")
          .map((it) => it[1])}
      </PrintTableNode>
      <PrintTableNode width="15px" center>
        {tsushokeiService.jikangaitaio
          .filter((it) => it[0] === "宿泊")
          .map((it) => it[1])}
      </PrintTableNode>
      <PrintTableNode width="95px" center>
        {tsushokeiService.chushokuteikyo}
      </PrintTableNode>
      <PrintTableNode width="75px" center>
        <div>{tsushokeiService.rehabilitation}</div>
        <div>
          {tsushokeiService.kaigohoshukasan.length > 0
            ? `(${tsushokeiService.kaigohoshukasan})`
            : ""}
        </div>
      </PrintTableNode>
      <PrintTableNode width="65px" center>
        {tsushokeiService.shokuinatarinoriyosha}
      </PrintTableNode>
      <PrintTableNode width="33px" center>
        {tsushokeiService.teiin}
      </PrintTableNode>
      <PrintTableNode width="145px">
        {tsushokeiService.tokucho.map((it) => (
          <div key={it}>・{it}</div>
        ))}
      </PrintTableNode>
    </PrintTable>
  );
};

TsushokeiServiceTable.propTypes = {
  onServiceButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default TsushokeiServiceTable;
