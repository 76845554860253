import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ExternalLinkedUrl from "../../../../../app/ui/link/ExternalLinkedUrl";

const ToiawaseTable = ({ toiawase, afterTable }) => {
  return (
    <Fragment>
      <div className="c-blue-heading">
        <div className="c-blue-heading__text">問い合わせ・予約</div>
      </div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>電話番号</th>
              <td>{toiawase.denwabango}</td>
            </tr>
            <tr>
              <th>FAX</th>
              <td>{toiawase.fax}</td>
            </tr>
            <tr>
              <th>Webフォーム</th>
              <td>
                <ExternalLinkedUrl url={toiawase.webForm} />
              </td>
            </tr>
            <tr>
              <th>E-mail</th>
              <td>{toiawase.email}</td>
            </tr>
          </tbody>
        </table>

        {afterTable}
      </div>
    </Fragment>
  );
};

ToiawaseTable.propTypes = {
  toiawase: PropTypes.object.isRequired,
  afterTable: PropTypes.node,
};

export default ToiawaseTable;
