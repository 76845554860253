import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../../state/information/actions";
import Parametername from "../../../app/naming/Parametername";
import Pathname from "../../../app/naming/Pathname";
import WebFrontNavlessLayout from "../WebFrontNavlessLayout";
import CenteredCircularProgress from "../../../app/ui/progress/CenteredCircularProgress";
import For from "../../../app/ui/layout/For";
import ScrollTopButton from "../../../app/ui/button/ScrollTopButton";

class InformationMessageList extends React.Component {
  constructor(props) {
    super(props);
    // 初期化
    this.initializeFields(props);
    // 「戻る・キャンセル」クリックイベント
    this.handleBackLinkClick = this.handleBackLinkClick.bind(this);
    // 「タイトル」クリックイベント
    this.handleInformationMessageDetailClick = this.handleInformationMessageDetailClick.bind(
      this
    );
  }

  // 初期化
  initializeFields(props) {
    const { search } = props.location;
    const searchParams = new URLSearchParams(search);
    this.pathname =
      searchParams.get(Parametername.pathname) || Pathname.webfront;
  }

  componentDidMount() {
    const { actions } = this.props;
    // お知らせ一覧の取得
    actions.requestList();
  }

  // 「戻る・キャンセル」クリックイベント
  handleBackLinkClick() {
    const { actions } = this.props;
    actions.gotoback(this.pathname);
  }

  // 「タイトル」クリックイベント
  handleInformationMessageDetailClick(id) {
    const { actions } = this.props;
    actions.gotoInformationMessageDetailPage(id);
  }

  render() {
    return (
      <WebFrontNavlessLayout>
        <div style={styles.container}>
          {this.renderNavigation()}
          <div style={styles.title}>お知らせ一覧</div>
          {this.renderInformationList()}
          {this.renderNavigation()}
          <ScrollTopButton />
        </div>
      </WebFrontNavlessLayout>
    );
  }

  renderInformationList() {
    const { informationForm } = this.props;
    const { list, listIsLoading } = informationForm;
    if (listIsLoading) return <CenteredCircularProgress />;
    if (!list.length) return null;
    return (
      <div style={styles.blockDiv}>
        <For
          in={list}
          each={(it) => (
            <ul key={it.id} className="c-information__a" style={styles.ul}>
              <li style={styles.cell_date}>
                {it.registDate} {this.renderNewFor(it)}
              </li>
              <li style={styles.cell_message}>
                <a
                  onClick={() =>
                    this.handleInformationMessageDetailClick(it.id)
                  }
                >
                  {it.title}
                </a>
              </li>
            </ul>
          )}
        />
      </div>
    );
  }

  renderNewFor(it) {
    if (!it.newFlag) return null;
    return <span style={styles.new}>NEW</span>;
  }

  renderNavigation() {
    return (
      <div className="navigation" style={{ marginBottom: 30 }}>
        <ul>
          <li>
            <a onClick={this.handleBackLinkClick}>前のページに戻る</a>
          </li>
        </ul>
      </div>
    );
  }
}

InformationMessageList.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  informationForm: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InformationMessageList)
);

function mapStateToProps(state) {
  return {
    informationForm: state.informationForm,
  };
}

function mapDispatchToProps(dispatch) {
  const pathname = window.location.href.substr(
    window.location.protocol.length + "//".length + window.location.host.length
  );
  const searchParams = new URLSearchParams();
  searchParams.set(Parametername.pathname, pathname);
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoback: (pathname) => dispatch(push(pathname)),
      gotoInformationMessageDetailPage: (id) =>
        dispatch(
          push({
            pathname: Pathname.webfront_information_id.replace(/:id/, id),
            search: searchParams.toString(),
          })
        ),
    },
  };
}

const styles = {
  container: {
    position: "relative",
    padding: 16,
  },
  title: {
    fontSize: "medium",
    fontWeight: "bold",
    marginBottom: 20,
  },
  blockDiv: {
    borderBottom: "1px solid #dddddd",
    marginBottom: "2.5em",
  },
  ul: {
    width: "100%",
    borderTop: "1px solid #dddddd",
  },
  cell_date: {
    marginTop: 13,
    marginBottom: 5,
  },
  cell_message: {
    marginBottom: 10,
    lineHeight: "1.2em",
  },
  new: {
    color: "red",
  },
};
