import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import AccountAddComponent from "../../account/add/AccountAddComponent";
import RoleType from "../../../../app/domain/account/RoleType";

class JigyoshoKanriAccountPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  initializeFields(props) {
    const { search } = props.location;
    const searchParams = new URLSearchParams(search);
    this.groupId = +searchParams.get("groupId");
  }

  handleBackButtonClick() {
    const { actions, jigyoshoList } = this.props;
    actions.gotoListPage(jigyoshoList.search);
  }

  handleSaveButtonClick() {
    const { actions, jigyoshoList } = this.props;
    actions.reloadListPage(jigyoshoList.search);
  }

  render() {
    const pageProps = {
      groupId: this.groupId,
      onGoBack: this.handleBackButtonClick,
      onGoAhead: this.handleSaveButtonClick,
      role: RoleType.ROLE_JIGYOSHO_ADMIN.name,
    };
    return <AccountAddComponent {...pageProps} />;
  }
}

JigyoshoKanriAccountPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  jigyoshoList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JigyoshoKanriAccountPage);

function mapStateToProps(state) {
  return {
    jigyoshoList: state.jigyoshoList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gotoListPage: (search = "") =>
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho,
            search,
          })
        ),
      reloadListPage: (search = "") => {
        const searchParams = new URLSearchParams(search);
        searchParams.set("timestamp", new Date().getTime());
        search = searchParams.toString();
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho,
            search,
          })
        );
      },
    },
  };
}
