import React from "react";
import PropTypes from "prop-types";
import SpaceOut from "../layout/SpaceOut";
import HelpIcon from "@material-ui/icons/HelpOutline";

const BackOfficeHelpItem = (props) => {
  const Icon = props.icon || HelpIcon;
  return (
    <div>
      <SpaceOut>
        <Icon style={styles.icon} />
      </SpaceOut>
      {props.text}
      {props.children || null}
      {props.action || null}
    </div>
  );
};

const styles = {
  icon: {
    height: 20,
    paddingBottom: 3,
    paddingRight: 2,
    verticalAlign: "middle",
  },
};

BackOfficeHelpItem.propTypes = {
  icon: PropTypes.node,
  text: PropTypes.node.isRequired,
  children: PropTypes.node,
  action: PropTypes.node,
};

export default BackOfficeHelpItem;
