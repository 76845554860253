import React from "react";
import PropTypes from "prop-types";
import Table from "./Table";
import Map from "./Map";
import { DEFAULT_ZOOM } from "../../../../app/fundamental/map/bounds";
import { connect } from "react-redux";
import ArrowLeftIcon from "@material-ui/icons/ArrowLeft";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";
import Button from "@material-ui/core/Button/Button";

class SearchResultMapViewComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeId: null,
      showList: true,
    };

    this.handleClick = this.handleClick.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
    this.handleGoogleMapMounted = this.handleGoogleMapMounted.bind(this);
    this.serviceIsActive = this.serviceIsActive.bind(this);
    this.toggleShowList = this.toggleShowList.bind(this);
  }

  handleClick = (id) => {
    this.setState({ activeId: id });
  };

  handleCloseButtonClick = () => {
    this.setState({ activeId: null });
  };

  handleGoogleMapMounted = (googleMap) => {
    this.googleMap = googleMap;
  };

  serviceIsActive = (id) => {
    return this.state.activeId === id;
  };

  toggleShowList() {
    this.setState({
      showList: !this.state.showList,
    });
  }

  render() {
    return (
      <div className={`c-map-frame ${this.state.showList ? "" : "close"}`}>
        {this.renderList()}
        {this.renderListToggle()}
        {this.renderMap()}
      </div>
    );
  }

  renderList() {
    const { services, serviceIsSelected, onSelect } = this.props;

    return (
      <div className="c-map-frame__table">
        <div className="c-table-frame">
          {services.map((service, i) => {
            return (
              <Table
                key={i}
                service={service}
                selected={serviceIsSelected(service.id)}
                active={this.serviceIsActive(service.id)}
                onClick={this.handleClick}
                onServiceButtonClick={this.props.onServiceButtonClick}
                onSelect={onSelect}
              />
            );
          })}
        </div>
      </div>
    );
  }

  renderListToggle() {
    const Icon = this.state.showList ? ArrowLeftIcon : ArrowRightIcon;
    const buttonTitle = `リストを${
      this.state.showList ? "折りたたむ" : "開く"
    }`;
    const buttonStyle = {
      padding: 0,
      minWidth: 0,
    };
    return (
      <div className="c-table-frame__list-toggle">
        <Button
          variant="outlined"
          color="primary"
          onClick={this.toggleShowList}
          title={buttonTitle}
          size="small"
          style={buttonStyle}
        >
          <Icon />
        </Button>
      </div>
    );
  }

  renderMap() {
    const { services } = this.props;
    const defaultMapCenterAndZoom = this.defaultMapCenterAndZoom();

    return (
      <Map
        {...defaultMapCenterAndZoom}
        services={services}
        serviceIsActive={this.serviceIsActive}
        serviceIsSelected={this.props.serviceIsSelected}
        onClick={this.handleClick}
        onServiceButtonClick={this.props.onServiceButtonClick}
        onCloseButtonClick={this.handleCloseButtonClick}
        onSelect={this.props.onSelect}
        onGoogleMapMounted={this.handleGoogleMapMounted}
        onBoundsChanged={this.props.onBoundsChanged}
        containerElement={<div className="c-map-frame__map" />}
        mapElement={<div className="c-gmap" />}
      />
    );
  }

  defaultMapCenterAndZoom() {
    const { siteIsSharedFolder, authentication } = this.props;

    if (siteIsSharedFolder) return null;
    if (!authentication.user.latitude || !authentication.user.longitude)
      return null;
    return {
      center: {
        lat: authentication.user.latitude,
        lng: authentication.user.longitude,
      },
      zoom: DEFAULT_ZOOM,
    };
  }
}

SearchResultMapViewComponent.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPrintButtonClick: PropTypes.func.isRequired,
  onServiceButtonClick: PropTypes.func.isRequired,
  onShareButtonClick: PropTypes.func.isRequired,
  serviceIsShared: PropTypes.func.isRequired,
  serviceIsSelected: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onBoundsChanged: PropTypes.func,
  siteIsSharedFolder: PropTypes.bool,
  authentication: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchResultMapViewComponent);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    serviceSearch: state.serviceSearch, // 使ってないけど必要。これがないとマップのチェックボックスにチェックを入れても反映されない
  };
}

function mapDispatchToProps() {
  return {};
}
