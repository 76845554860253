import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const BihinTable = ({ contents }) => {
  const {
    blocks: { homonkeiServiceBihin: bihin },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>備品</th>
              <td>{bihin.bihin.join("、")}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.homonkeiServiceBihin
      )}
    </Fragment>
  );
};

BihinTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default BihinTable;
