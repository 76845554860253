import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../state/open-data-list/actions";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { Paper, TableBody, TableHead, TableRow } from "@material-ui/core";
import SmallPaddingTableCell from "../../../../app/ui/table/SmallPaddingTableCell";
import Right from "../../../../app/ui/layout/Right";
import AddIcon from "@material-ui/icons/Add";
import Boundary from "../../../../app/ui/layout/Boundary";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import OpenDataJoinCondition from "./OpenDataJoinCondition";
import OpenDataJoinPaginator from "./OpenDataJoinPaginator";
import FixedTable from "../../../../app/ui/table/FixedTable";
import { withInputFeedback } from "../../../../app/ui/form";
import Margin from "../../../../app/ui/layout/Margin";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import OpenDataInformation from "./OpenDataInformation";
import OpenDataJoinConfirmationDialog from "./OpenDataJoinConfirmationDialog";

class OpenDataJoinPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
  }

  initializeFields(props) {
    const { location } = props;
    const searchParams = new URLSearchParams(location.search);
    const groupId = searchParams.get("groupId");
    if (groupId != null) this.groupId = +groupId; // parseInt
  }

  state = {
    target: undefined,
  };

  componentDidMount() {
    const { actions } = this.props;
    actions.reset();
  }

  handleBackButtonClick() {
    const { actions, jigyoshoList } = this.props;
    actions.gotoListPage(jigyoshoList.search);
  }

  handleAddButtonClick(e, id) {
    e.stopPropagation();
    const { openDataList } = this.props;
    const target = openDataList.list.find((data) => data.id === id);
    this.setState({ target });
  }

  render() {
    return (
      <Fragment>
        <Boundary>
          <SideMargin>
            {this.renderHead()}
            {this.renderNavigation()}
            {this.renderCondition()}
            {this.renderSearchListTable()}
          </SideMargin>
        </Boundary>
        {this.renderConfirmationDialog()}
      </Fragment>
    );
  }

  renderHead() {
    return <BackOfficeHead>事業所追加（オープンデータ検索）</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderCondition() {
    const { openDataList } = this.props;
    const conditionProps = {
      condition: openDataList.condition,
      listIsLoading: openDataList.listIsLoading,
    };
    return (
      <Margin bottom>
        <OpenDataJoinCondition {...conditionProps} />
      </Margin>
    );
  }

  renderSearchListTable() {
    const { openDataList } = this.props;
    const { list, listIsLoading } = openDataList;
    if (listIsLoading) return <CenteredCircularProgress />;
    if (list.length === 0) return this.renderLeadToAddPage();
    return (
      <Fragment>
        {this.renderTable()}
        {this.renderPaginator()}
      </Fragment>
    );
  }

  renderLeadToAddPage() {
    const { location } = this.props;
    return <OpenDataInformation search={location.search} />;
  }

  renderTable() {
    const { openDataList } = this.props;
    const { list } = openDataList;
    return (
      <Paper>
        <FixedTable>
          <TableHead>
            <TableRow>
              <SmallPaddingTableCell>事業所名</SmallPaddingTableCell>
              <SmallPaddingTableCell>サービス種別</SmallPaddingTableCell>
              <SmallPaddingTableCell>市区町村</SmallPaddingTableCell>
              <SmallPaddingTableCell></SmallPaddingTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((jigyosho) => {
              return (
                <TableRow key={jigyosho.id}>
                  <SmallPaddingTableCell>
                    {jigyosho.jigyoshomei}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    {jigyosho.serviceShubetsu}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    {jigyosho.shikuchoson}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    <Right>
                      <FlatPrimaryButton
                        onClick={(e) =>
                          this.handleAddButtonClick(e, jigyosho.id)
                        }
                      >
                        <AddIcon />
                        この事業所を追加する
                      </FlatPrimaryButton>
                    </Right>
                  </SmallPaddingTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </FixedTable>
      </Paper>
    );
  }

  renderPaginator() {
    const { openDataList } = this.props;
    const paginatorProps = {
      prevSearch: openDataList.prevSearch,
      nextSearch: openDataList.nextSearch,
      pagination: openDataList.pagination,
    };
    return <OpenDataJoinPaginator {...paginatorProps} />;
  }

  renderConfirmationDialog() {
    const { target } = this.state;
    const dialogProps = {
      groupId: this.groupId,
      onClose: () => this.setState({ target: undefined }),
      target,
    };
    return <OpenDataJoinConfirmationDialog {...dialogProps} />;
  }
}

OpenDataJoinPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  jigyoshoList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  openDataList: PropTypes.object.isRequired,
};

export default withInputFeedback(
  connect(mapStateToProps, mapDispatchToProps)(OpenDataJoinPage)
);

function mapStateToProps(state) {
  return {
    jigyoshoList: state.jigyoshoList,
    openDataList: state.openDataList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoListPage: (search = "") =>
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho,
            search,
          })
        ),
    },
  };
}
