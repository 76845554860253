import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";

const ServiceBlock = ({ service }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="サービス内容" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>提供サービス</th>
              <td>{service.teikyoService}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>オプション</th>
              <td>
                {service.serviceOption &&
                  service.serviceOption
                    .split("/")
                    .map((it, index) => <div key={index}>・{it}</div>)}
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>スタッフ保有資格</th>
              <td>
                {service.hoyushikaku &&
                  service.hoyushikaku
                    .split("、")
                    .map((it, index) => <div key={index}>・{it}</div>)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

ServiceBlock.propTypes = {
  service: PropTypes.object.isRequired,
};

export default ServiceBlock;
