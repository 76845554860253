import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../../state/news/actions";
import Pathname from "../../../app/naming/Pathname";
import Boundary from "../../../app/ui/layout/Boundary";
import SideMargin from "../../../app/ui/layout/SideMargin";
import FlatPrimaryButton from "../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FormGrouping from "../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../app/ui/form/InputTypeText";
import { Paper } from "@material-ui/core";
import Right from "../../../app/ui/layout/Right";
import SpaceOut from "../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../app/ui/button/RaisedPrimaryButton";
import FlatSecondaryButton from "../../../app/ui/button/FlatSecondaryButton";
import BackOfficeHead from "../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../app/ui/structure/BackOfficeNavigation";
import CenteredCircularProgress from "../../../app/ui/progress/CenteredCircularProgress";
import StaticLabel from "../../../app/ui/form/StaticLabel";

class PickupMessageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    // 「戻る・キャンセル」クリックイベント
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    // 「保存」クリックイベント
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  initializeFields(props) {
    const { params } = props.match;
    this.id = +params.id;
  }

  componentDidMount() {
    const { actions } = this.props;
    // 詳細データ取得
    actions.requestPickupForm(this.id);
  }

  // 「戻る・キャンセル」クリックイベント
  handleBackButtonClick() {
    const { actions } = this.props;
    actions.gotoback();
  }

  // 保存クリックイベント
  handleSaveButtonClick() {
    const { actions, newsForm } = this.props;
    actions.pickupSubmit(newsForm.form, this.handleBackButtonClick);
  }

  createBindingProps() {
    const { actions, newsForm } = this.props;
    const { form } = newsForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  }

  render() {
    const { newsForm } = this.props;
    if (newsForm.formIsLoading) return <CenteredCircularProgress />;
    if (!newsForm.form.typeIs("blank") && !newsForm.form.typeIs("edit"))
      return null;
    const bindingProps = this.createBindingProps();
    const text = newsForm.form.typeIs("blank")
      ? {
          section: "ピックアップニュース追加",
          subsection: "新規のピックアップニュース",
        }
      : {
          section: "ピックアップニュース編集",
          subsection: "既存のピックアップニュース",
        };
    return (
      <Boundary>
        <SideMargin>
          <BackOfficeHead>{text.section}</BackOfficeHead>
          {this.renderNavigation()}
          <Paper style={{ padding: "1px 15px 15px 15px" }}>
            <Fragment>
              <FormGrouping title={text.subsection}>
                <HorizontalLabelLayout labelText="カテゴリ" required>
                  <InputTypeText name="category" {...bindingProps} />
                </HorizontalLabelLayout>
                <HorizontalLabelLayout labelText="タイトル" required>
                  <InputTypeText name="title" {...bindingProps} />
                  <StaticLabel>
                    ※「&lt;em&gt;強調したい文字&lt;/em&gt;」で太字にできます。
                  </StaticLabel>
                </HorizontalLabelLayout>
                <HorizontalLabelLayout labelText="URL" required>
                  <InputTypeText
                    name="url"
                    {...bindingProps}
                    hintText="https://"
                  />
                </HorizontalLabelLayout>
              </FormGrouping>
            </Fragment>
            <Right>
              <SpaceOut>
                <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                  キャンセル
                </FlatSecondaryButton>
              </SpaceOut>
              <SpaceOut>
                <RaisedPrimaryButton onClick={this.handleSaveButtonClick}>
                  保存する
                </RaisedPrimaryButton>
              </SpaceOut>
            </Right>
          </Paper>
        </SideMargin>
      </Boundary>
    );
  }
  // 戻る
  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }
}

PickupMessageDetail.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  newsForm: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(PickupMessageDetail)
);

function mapStateToProps(state) {
  return {
    newsForm: state.newsForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoback: () =>
        dispatch(
          push({
            pathname: Pathname.backoffice_news,
          })
        ),
    },
  };
}
