import React from "react";
import PropTypes from "prop-types";
import { Icon } from "@material-ui/core";

const IconLeadingLayout = (props) => {
  const iconProps = {
    color: props.color,
  };
  return (
    <div>
      <div style={{ ...styles(props).icon }}>
        {<Icon {...iconProps}>{props.icon}</Icon>}
      </div>
      <div style={styles(props).input}>{props.children}</div>
    </div>
  );
};

const styles = () => ({
  icon: {
    display: "inline-block",
    paddingRight: 8,
    paddingTop: 18,
  },
  input: {
    display: "inline-block",
    boxSizing: "border-box",
    verticalAlign: "top",
  },
});

IconLeadingLayout.propTypes = {
  icon: PropTypes.node.isRequired,
  color: PropTypes.string,
  children: PropTypes.node,
};

export default IconLeadingLayout;
