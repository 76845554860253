const ActionType = {
  CLEAR_LIST: "SERVICE_SEARCH_CLEAR_LIST",
  MAKE_CONDITION: "SERVICE_SEARCH_MAKE_CONDITION",
  SELECT: "SERVICE_SEARCH_SELECT",
  CHANGE_CONDITION_SERVICE_SHUBETSU_CATEGORY:
    "SERVICE_SEARCH_CHANGE_CONDITION_SERVICE_SHUBETSU_CATEGORY",
  CHANGE_CONDITION_SERVICE_SHUBETSU_MENU:
    "SERVICE_SEARCH_CHANGE_CONDITION_SERVICE_SHUBETSU_MENU",
  CHANGE_CONDITION_FOLDERS: "SERVICE_SEARCH_CHANGE_CONDITION_FOLDERS",
  REQUEST: "SERVICE_SEARCH_REQUEST",
  REQUEST_FINISHED: "SERVICE_SEARCH_REQUEST_FINISHED",
  CHANGE_CONDITION: "SERVICE_SEARCH_CHANGE_CONDITION",
  SET_SEARCH: "SERVICE_SEARCH_SET_SEARCH",
  SAVE_CONDITION_BUFFER: "SERVICE_SEARCH_SAVE_CONDITION_BUFFER",
  FETCH_BROWSE_AREA_CITIES: "SERVICE_SEARCH_FETCH_BROWSE_AREA_CITIES",
  FETCH_BROWSE_AREA_CITIES_FINISHED:
    "SERVICE_SEARCH_FETCH_BROWSE_AREA_CITIES_FINISHED",
  FETCH_BROWSE_AREA_TOWNS: "SERVICE_SEARCH_FETCH_BROWSE_AREA_TOWNS",
  FETCH_BROWSE_AREA_TOWNS_FINISHED:
    "SERVICE_SEARCH_FETCH_BROWSE_AREA_TOWNS_FINISHED",
  FETCH_BROWSE_AREA_LABEL_FINISHED:
    "SERVICE_SEARCH_FETCH_BROWSE_AREA_LABEL_FINISHED",
  FETCH_DETAIL_ITEM: "SERVICE_SEARCH_FETCH_DETAIL_ITEM",
  FETCH_DETAIL_ITEM_FINISHED: "SERVICE_SEARCH_FETCH_DETAIL_ITEM_FINISHED",
};

export default ActionType;
