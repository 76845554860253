import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import { formDataOf } from "../../../app/fundamental/data/FormData";
import { userOf } from "../../../app/fundamental/user/User";

const initialState = {
  loginForm: formDataOf({
    username: "",
    password: "",
  }),
  userIsUnauthenticated: false,
  loginIsProcessing: false,
  loginWasAccepted: false,
  loginWasDenied: false,
  user: undefined,
  userIsAuthenticated: false,
  userWasLoaded: false,
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.RESET_LOGIN_FORM]: function (state) {
    return {
      ...initialState,
      user: state.user,
      userIsAuthenticated: state.user,
    };
  },
  [ActionType.CHANGE_LOGIN_FORM]: function (state, action) {
    return {
      ...state,
      loginForm: state.loginForm.setByName(
        action.payload.name,
        action.payload.value
      ),
    };
  },
  [ActionType.LOGIN]: function (state) {
    return {
      ...state,
      loginIsProcessing: true,
      loginWasAccepted: false,
      loginWasDenied: false,
      userIsAuthenticated: false,
    };
  },
  [ActionType.LOGIN_WAS_ACCEPTED]: function (state) {
    return {
      ...state,
      loginWasAccepted: true,
      loginWasDenied: false,
    };
  },
  [ActionType.LOGIN_WAS_DENIED]: function (state) {
    return {
      ...state,
      loginWasAccepted: false,
      loginWasDenied: true,
      loginIsProcessing: false,
    };
  },
  [ActionType.LOAD_USER_FINISHED]: function (state, action) {
    return {
      ...state,
      user: userOf(action.payload.user),
      userIsAuthenticated: true,
      userWasLoaded: true,
    };
  },
  [ActionType.LOAD_USER_FAILED]: function (state) {
    return {
      ...state,
      userIsUnauthenticated: true,
    };
  },
  [ActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.AGREE_FINISHED]: function (state, action) {
    return {
      ...state,
      user: userOf({
        ...state.user,
        lastAgreedAt: action.payload.agreedAt,
      }),
    };
  },
});

export default reducers.createReducer(initialState);
