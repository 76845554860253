import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../app/naming/Pathname";
import AccountListComponent from "../../backoffice/account/list/AccountListComponent";
import RoleType from "../../../app/domain/account/RoleType";
import FlatPrimaryButton from "../../../app/ui/button/FlatPrimaryButton";
import AddIcon from "@material-ui/icons/Add";
import BackOfficeNavigation from "../../../app/ui/structure/BackOfficeNavigation";

class SystemOperatorUserListPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields();
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handlePasswordButtonClick = this.handlePasswordButtonClick.bind(this);
  }

  initializeFields() {
    this.groupId = 1;
  }

  handleAddButtonClick() {
    const { actions } = this.props;
    actions.gotoAddPage(this.groupId);
  }

  handleEditButtonClick(id) {
    const { actions } = this.props;
    actions.gotoEditPage(id);
  }

  handlePasswordButtonClick(id) {
    const { actions } = this.props;
    actions.gotoPasswordPage(id);
  }

  render() {
    const pageProps = {
      groupId: this.groupId,
      onEditButtonClick: this.handleEditButtonClick,
      onPasswordButtonClick: this.handlePasswordButtonClick,
      onGoBack: null,
      opsNavigation: this.renderOpsNavigation(),
      role: RoleType.ROLE_SYS_OPERATOR.name,
    };
    return <AccountListComponent {...pageProps} />;
  }

  renderOpsNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleAddButtonClick}>
          <AddIcon />
          {RoleType.ROLE_SYS_OPERATOR.label}を追加する
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }
}

SystemOperatorUserListPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  accountList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemOperatorUserListPage);

function mapStateToProps(state) {
  return {
    accountList: state.accountList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gotoListPage: () => {
        const searchParams = new URLSearchParams();
        searchParams.set("groupId", 1);
        const search = searchParams.toString();
        dispatch(push({ pathname: Pathname.tools_sysop }), search);
      },
      gotoAddPage: (groupId) => {
        const searchParams = new URLSearchParams();
        searchParams.set("groupId", groupId);
        const search = searchParams.toString();
        dispatch(
          push({
            pathname: Pathname.tools_sysop_add,
            search,
          })
        );
      },
      gotoEditPage: (id) => {
        dispatch(
          push({
            pathname: Pathname.tools_sysop_id_edit.replace(/:id/, id),
          })
        );
      },
      gotoPasswordPage: (id) => {
        dispatch(
          push({
            pathname: Pathname.tools_sysop_id_password.replace(/:id/, id),
          })
        );
      },
    },
  };
}
