import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import {
  OPTIONS_TAIO_JOKYO,
  OPTIONS_TAIO_JOKYO_WITH_IRYO,
} from "../../../../../app/ui/form";
import {
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
  withMasterData,
} from "../../../../../state/master-data";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import CategorizedOptions from "../../../../../app/util/CategorizedMasterData";
import DummyError from "../../../../../app/ui/form/DummyError";

class TaiokanoNaShojoForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderForm()}
        {this.renderError()}
      </Fragment>
    );
  }

  renderForm() {
    const { bindingProps, masterData } = this.props;
    const categories = new CategorizedOptions(
      masterData.taiokanoNaShojo
    ).getCategories();
    return categories.map((category) => (
      <FormGrouping
        key={`category:${category.code}`}
        title={category.label}
        recommended
      >
        {category.options.map((option) => (
          <HorizontalLabelLayout
            key={`option:${option.code}`}
            long
            labelText={option.label}
          >
            <OptionsRadio
              name={`taioJokyo.${option.code}`}
              options={
                category.code == "002"
                  ? OPTIONS_TAIO_JOKYO_WITH_IRYO
                  : OPTIONS_TAIO_JOKYO
              }
              split={6}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
        ))}
      </FormGrouping>
    ));
  }

  renderError() {
    return (
      <HorizontalLabelLayout long>
        <DummyError name="taioJokyo" />
      </HorizontalLabelLayout>
    );
  }
}

TaiokanoNaShojoForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(TaiokanoNaShojoForm);
