import ServiceShubetsuType, {
  serviceShubetsuTypeValueOf,
} from "../../../app/domain/jigyosho/ServiceShubetsuType";
import ServiceShubetsuCategoryType, {
  serviceShubetsuCategoryTypeValueOf,
} from "../../../app/domain/jigyosho/ServiceShubetsuCategoryType";

export function servicePageTagsOf(contents, serviceSubId) {
  return [contents.serviceShubetsuCategory, contents.serviceShubetsu]
    .reduce((tags, tag) => (tags.includes(tag) ? tags : tags.concat([tag])), [])
    .concat(servicePageExtraTags(contents, serviceSubId))
    .map(servicePageTagMapper);
}

function servicePageExtraTags(contents, serviceSubId) {
  const tags = [];
  tags.push(yakantaiogata(contents));
  tags.push(tokkabunya(contents));
  tags.push(mimamorigyoshu(contents));
  tags.push(katsudoKayoinobaCard(contents));
  tags.push(katsudoKayoinobaService(contents, serviceSubId));
  return flat(tags).filter((it) => it);
}

function flat(tags) {
  return tags.reduce((list, el) => {
    if (Object.prototype.toString.call(el) === "[object Array]")
      return list.concat(el);
    return list.concat([el]);
  }, []);
}

function tokkabunya(contents) {
  const targets = [
    ServiceShubetsuCategoryType.通所系サービス,
    ServiceShubetsuCategoryType.訪問系サービス,
  ];
  if (!contentsIsInServiceShubetsuCategory(contents, targets)) return null;
  const { blocks } = contents;
  const block = blocks.tsushokeiService || blocks.homonkeiService;
  return block.tokkabunya && !["なし", "標準"].includes(block.tokkabunya)
    ? block.tokkabunya
    : null;
}

function yakantaiogata(contents) {
  const targets = [ServiceShubetsuType.訪問介護];
  if (!contentsIsInServiceShubetsu(contents, targets)) return null;
  const { blocks } = contents;
  return blocks.homonkeiService.yakantaiogataService ? "夜間対応型" : null;
}

function mimamorigyoshu(contents) {
  const targets = [ServiceShubetsuCategoryType.見守り推進事業];
  if (!contentsIsInServiceShubetsuCategory(contents, targets)) return null;
  const { blocks } = contents;
  return blocks.mimamoriSuishinJigyo.gyoshu;
}

// 通いの場_カード_活動内容分類タグ追加
function katsudoKayoinobaCard(contents) {
  const targets = [ServiceShubetsuCategoryType.活動_通いの場];
  if (!contentsIsInServiceShubetsuCategory(contents, targets)) return null;
  const { blocks } = contents;
  if (blocks.hokengaiServiceKatsudoKayoinoba == null) return null;
  return blocks.hokengaiServiceKatsudoKayoinoba.businessMenu;
}

// 通いの場_詳細_活動内容分類タグ追加
function katsudoKayoinobaService(contents, serviceSubId) {
  const targets = [ServiceShubetsuCategoryType.活動_通いの場];
  if (!contentsIsInServiceShubetsuCategory(contents, targets)) return null;
  const { blocks } = contents;
  if (blocks.katsudoKayoinobaMenu == null) return null;
  const targetSubId = serviceSubId ? serviceSubId : 1;
  const mainItem = blocks.katsudoKayoinobaMenu.menus.find(
    (it) => it.subId == targetSubId
  );
  if (!mainItem) return null;
  return mainItem.businessMenu;
}

function servicePageTagMapper(el) {
  if (el === "訪問介護（夜間対応型含む）") return "訪問介護";
  return el;
}

function contentsIsInServiceShubetsu(contents, types) {
  const { serviceShubetsuCode } = contents;
  if (!serviceShubetsuCode) return false;
  const type = serviceShubetsuTypeValueOf(serviceShubetsuCode);
  return types.find((it) => it === type);
}

function contentsIsInServiceShubetsuCategory(contents, types) {
  const { serviceShubetsuCategoryCode } = contents;
  if (!serviceShubetsuCategoryCode) return false;
  const type = serviceShubetsuCategoryTypeValueOf(serviceShubetsuCategoryCode);
  return types.find((it) => it === type);
}
