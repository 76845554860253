import React from "react";
import PropTypes from "prop-types";

const YokaigodohiBarChart = (props) => {
  const isNullHiritsu =
    props.riyosha.yoshien12Hiritsu == null &&
    props.riyosha.yokaigo12Hiritsu == null &&
    props.riyosha.yokaigo345Hiritsu == null;
  const hidden_yoshien12 =
    !isNullHiritsu && props.riyosha.yoshien12Hiritsu < 10;
  const hidden_yokaigo12 =
    !isNullHiritsu && props.riyosha.yokaigo12Hiritsu < 15;
  const hidden_yokaigo345 =
    !isNullHiritsu && props.riyosha.yokaigo345Hiritsu < 19;
  return (
    <div>
      <div style={styles(props).heading_text}>要介護度比</div>
      <div style={styles(props).container}>
        <div style={styles(props, isNullHiritsu).yoshien12}>
          <img
            style={styles(props).img}
            src="/images/print_bg_light_orange.png"
          />
          <div hidden={hidden_yoshien12} style={styles(props).text}>
            要支援
            <br />
            {isNullHiritsu
              ? "ー"
              : valRound(props.riyosha.yoshien12Hiritsu) + "%"}
          </div>
        </div>
        <div style={styles(props, isNullHiritsu).yokaigo12}>
          <img style={styles(props).img} src="/images/print_bg_orange.png" />
          <div hidden={hidden_yokaigo12} style={styles(props).text}>
            要介護①②
            <br />
            {isNullHiritsu
              ? "ー"
              : valRound(props.riyosha.yokaigo12Hiritsu) + "%"}
          </div>
        </div>
        <div style={styles(props, isNullHiritsu).yokaigo345}>
          <img
            style={styles(props).img}
            src="/images/print_bg_dark_orange.png"
          />
          <div hidden={hidden_yokaigo345} style={styles(props).text}>
            要介護③④⑤
            <br />
            {isNullHiritsu
              ? "ー"
              : valRound(props.riyosha.yokaigo345Hiritsu) + "%"}
          </div>
        </div>
      </div>
      <div style={styles(props).hidden_div}>
        <span hidden={!hidden_yoshien12} style={styles(props).hidden_text}>
          <img
            style={styles(props).hidden_img}
            src="/images/print_bg_light_orange.png"
          />
          要支援 {valRound(props.riyosha.yoshien12Hiritsu)}%
        </span>
        <span hidden={!hidden_yokaigo12} style={styles(props).hidden_text}>
          <img
            style={styles(props).hidden_img}
            src="/images/print_bg_orange.png"
          />
          要介護①② {valRound(props.riyosha.yokaigo12Hiritsu)}%
        </span>
        <span hidden={!hidden_yokaigo345} style={styles(props).hidden_text}>
          <img
            style={styles(props).hidden_img}
            src="/images/print_bg_dark_orange.png"
          />
          要介護③④⑤ {valRound(props.riyosha.yokaigo345Hiritsu)}%
        </span>
      </div>
    </div>
  );
};

function valRound(value) {
  return Math.round(Math.round(value * 10) / 10);
}

const styles = (props, isNullHiritsu) => ({
  heading_text: {
    fontWeight: "bold",
    margin: "5pt 0",
    paddingLeft: "5pt",
  },
  container: {
    fontSize: "10px",
    width: "100%",
    height: "37px",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    marginBottom: "5pt",
  },
  yoshien12: {
    background: "#fcd9a6",
    width: `${isNullHiritsu ? 33.3 : props.riyosha.yoshien12Hiritsu}%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  yokaigo12: {
    background: "#ffc470",
    width: `${isNullHiritsu ? 33.3 : props.riyosha.yokaigo12Hiritsu}%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  yokaigo345: {
    background: "#ffab35",
    width: `${isNullHiritsu ? 33.3 : props.riyosha.yokaigo345Hiritsu}%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  text: {
    position: "absolute",
    top: "3pt",
    left: "0",
    width: "100%",
    textAlign: "center",
  },
  hidden_div: {
    marginLeft: "5pt",
    marginBottom: "5pt",
  },
  hidden_text: {
    marginRight: "10pt",
  },
  img: {
    width: "100%",
    height: "40px",
  },
  hidden_img: {
    width: "7pt",
    height: "7pt",
    marginRight: "3pt",
  },
});

YokaigodohiBarChart.propTypes = {
  riyosha: PropTypes.object.isRequired,
};

export default YokaigodohiBarChart;
