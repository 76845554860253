import React from "react";
import { Dialog, DialogContent, LinearProgress } from "@material-ui/core";

const LinearProgressDialog = (props) => {
  return (
    <Dialog {...props}>
      <DialogContent style={styles.content}>
        <LinearProgress />
      </DialogContent>
    </Dialog>
  );
};

const styles = {
  content: {
    width: "300px",
  },
};

export default LinearProgressDialog;
