import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ThWithBg from "../general/ThWithBg";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

const CareBlock = ({ careJoho, serviceShubetsuType }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="ケア情報" />
      <div className="p-office-body-table">
        <div className="p-office-body-title">食事</div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="食費" />
              <ThWithBg text="嚥下食" />
              <ThWithBg text="制限食" />
              <ThWithBg text="調理場所" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{careJoho.shokuhi}</td>
              <td>{careJoho.engeshokuteikyo}</td>
              <td>{careJoho.seigenshokuteikyo}</td>
              <td>{careJoho.choribasho}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table">
        <div className="p-office-body-title">排泄</div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="車椅子" />
              <ThWithBg text="同性介助" />
              <ThWithBg text="カテーテル管理" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{careJoho.kurumaisutaio}</td>
              <td>{careJoho.haisetsudoseikaijotaio}</td>
              <td>{careJoho.catheterCare}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table">
        <div className="p-office-body-title">入浴</div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="入浴頻度" />
              <ThWithBg text="機械浴" />
              <ThWithBg text="浴槽タイプ" />
              <ThWithBg text="同性介助" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{careJoho.nyuyokuhindo}</td>
              <td>{careJoho.kikaiyoku}</td>
              <td>{careJoho.yokusoType}</td>
              <td>{careJoho.nyuyokudoseikaijotaio}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table">
        <div className="p-office-body-title">
          {serviceShubetsuType === ServiceShubetsuType.短期入所生活介護
            ? "機能訓練"
            : "リハ"}
        </div>
        <table>
          <thead>
            <tr className="th-right">
              <ThWithBg text="個別訓練" />
              <ThWithBg text="集団訓練" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{careJoho.kobetsukunren}</td>
              <td>{careJoho.shudankunren}</td>
            </tr>
          </tbody>
        </table>
        <div className="p-office-body-title title-left">送迎</div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="有無" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{careJoho.sogeiService}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

CareBlock.propTypes = {
  careJoho: PropTypes.object.isRequired,
  serviceShubetsuType: PropTypes.object.isRequired,
};

export default CareBlock;
