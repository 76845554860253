import PropTypes from "prop-types";

const ValueWithPercentageUnit = ({ value }) => {
  if (value == null) return "";
  return `${value}％`;
};

ValueWithPercentageUnit.propTypes = {
  value: PropTypes.number,
};

export default ValueWithPercentageUnit;
