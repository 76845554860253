import React from "react";
import PropTypes from "prop-types";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import { connect } from "react-redux";
import {
  changeForm,
  requestEditForm,
  submitToEdit,
} from "../../../../state/job-offer-form/actions";
import {
  MASTER_DATA_BUNDLE_FOR_JOB_OFFER,
  withMasterData,
} from "../../../../state/master-data";
import JobOfferForm from "../form/JobOfferForm";
import { requestDetail } from "../../../../state/job-offer/actions";
import JobOfferEditAlertDialog from "../ui/JobOfferEditAlertDialog";

class JobOfferEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.id = this.props.match.params.id;

    this.state = {
      editAlertDialogIsOpen: false,
    };

    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSubmitButtonClick = this.handleSubmitButtonClick.bind(this);
    this.handleEditAlertDialogCloseButtonClick = this.handleEditAlertDialogCloseButtonClick.bind(
      this
    );
    this.handleEditAlertDialogSubmitButtonClick = this.handleEditAlertDialogSubmitButtonClick.bind(
      this
    );
  }

  componentDidMount() {
    this.props.loadForm(this.id);
    this.props.loadData(this.id);
  }

  handleBackButtonClick() {
    this.props.gotoJobOfferDetailPage(this.id);
  }

  handleSubmitButtonClick() {
    const { detail } = this.props.jobOffer;
    if (detail.approvalRequested || detail.approved) {
      this.setState({ editAlertDialogIsOpen: true });
    } else {
      this.handleEditAlertDialogSubmitButtonClick();
    }
  }

  handleEditAlertDialogCloseButtonClick() {
    this.setState({ editAlertDialogIsOpen: false });
  }

  handleEditAlertDialogSubmitButtonClick() {
    this.setState({ editAlertDialogIsOpen: false });
    const { submitForm, jobOfferForm, gotoJobOfferDetailPage } = this.props;
    const callback = () => gotoJobOfferDetailPage(this.id);
    submitForm(this.id, jobOfferForm.form, callback);
  }

  render() {
    const { jobOfferForm } = this.props;

    const formProps = {
      type: "edit",
      masterData: this.props.masterData,
      jobOfferForm,
      onChange: this.props.changeForm,
      onCancel: this.handleBackButtonClick,
      onSubmit: this.handleSubmitButtonClick,
    };

    return (
      <Boundary>
        <SideMargin>
          <BackOfficeHead>求人情報の編集</BackOfficeHead>

          <BackOfficeNavigation>
            <FlatPrimaryButton onClick={this.handleBackButtonClick}>
              <BackIcon /> 戻る
            </FlatPrimaryButton>
          </BackOfficeNavigation>

          <JobOfferForm {...formProps} />

          <JobOfferEditAlertDialog
            open={this.state.editAlertDialogIsOpen}
            onClose={this.handleEditAlertDialogCloseButtonClick}
            onSubmit={this.handleEditAlertDialogSubmitButtonClick}
          />
        </SideMargin>
      </Boundary>
    );
  }
}

JobOfferEditPage.propTypes = {
  match: PropTypes.object.isRequired,
  jobOfferForm: PropTypes.object.isRequired,
  jobOffer: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  loadForm: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  gotoJobOfferListPage: PropTypes.func.isRequired,
  gotoJobOfferDetailPage: PropTypes.func.isRequired,
  changeForm: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    jobOfferForm: state.jobOfferForm,
    jobOffer: state.jobOffer,
    masterData: state.masterData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadForm: (id) => {
      dispatch(requestEditForm(id));
    },
    loadData: (id) => {
      dispatch(requestDetail(id));
    },
    gotoJobOfferListPage: () => {
      dispatch(push(Pathname.backoffice_job_offer));
    },
    gotoJobOfferDetailPage: (id) => {
      dispatch(push(Pathname.backoffice_job_offer_id.replace(/:id/, id)));
    },
    changeForm: (name, value) => {
      dispatch(changeForm(name, value));
    },
    submitForm: (id, form, callback) => {
      dispatch(submitToEdit(id, form, callback));
    },
  };
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_JOB_OFFER)(
  connect(mapStateToProps, mapDispatchToProps)(JobOfferEditPage)
);
