import ActionType from "./reducer/ActionType";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import ApiPath from "../../app/fundamental/request/ApiPath";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
} from "../../app/fundamental/request";

export function select(id) {
  return {
    type: ActionType.SELECT,
    payload: {
      id,
    },
  };
}

export function changeCondition(name, value, callback = () => {}) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_CONDITION,
      payload: {
        name,
        value,
      },
    });
    setTimeout(callback);
  };
}

export function requestBySearch(search, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REQUEST,
    });
    requestAccountList(dispatch, search, callback);
  };
}

export function requestByGroupIdAndRole(groupId, role, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REQUEST,
    });
    const search = setSeachParamsByGroupIdAndRole(groupId, role);
    requestAccountList(dispatch, search, callback);
  };
}

export function requestBySysop(groupId, role, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REQUEST,
    });
    const search = setSeachParamsByGroupIdAndRole(groupId, role);
    requestSystemOperatorList(dispatch, search, callback);
  };
}

function setSeachParamsByGroupIdAndRole(groupId, role) {
  const searchParams = new URLSearchParams();
  searchParams.set("groupId", groupId);
  if (role) searchParams.set("role", role);
  const search = searchParams.toString();
  return search;
}

function requestAccountList(dispatch, search, callback = () => {}) {
  const url = `${ApiPath.api_accounts}?${search}`;
  requestAccountListProcess(dispatch, url, search, callback);
}

function requestSystemOperatorList(dispatch, search, callback = () => {}) {
  const url = `${ApiPath.api_tools_sysop}?${search}`;
  requestAccountListProcess(dispatch, url, search, callback);
}

function requestAccountListProcess(dispatch, url, search, callback = () => {}) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        group: json.group,
        list: json.list,
      },
    });
    setTimeout(() => callback(json.search));
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}
