import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import { enterKeyHandler } from "../../../../../app/ui/form";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import {
  MASTER_DATA_BUNDLE_FOR_HOKENGAI,
  withMasterData,
} from "../../../../../state/master-data";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import TimePeriod from "../../../../../app/ui/form/TimePeriod";
import DummyLabel from "../../../../../app/ui/form/DummyLabel";
import { IconButton } from "@material-ui/core";
import FindIcon from "@material-ui/icons/FindReplace";
import { zipcodeToAddress } from "../../../../../state/zipcode-search/actions";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import Right from "../../../../../app/ui/layout/Right";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import Textarea from "../../../../../app/ui/form/Textarea";
import Mount from "../../../../../app/ui/layout/Mount";

class MimamoriSuishinJigyoForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleEnterKeyDownOnYubimbango = this.handleEnterKeyDownOnYubimbango.bind(
      this
    );
  }

  handleEnterKeyDownOnYubimbango() {
    const { actions, bindingProps } = this.props;
    const { form, onChange: changeForm } = bindingProps;
    const zipcode = form.getByName("zipCode");
    actions.zipcodeToAddress(zipcode, (address) => {
      changeForm("prefName", address.pref);
      changeForm("cityName", address.address);
    });
  }

  isJushoOnly() {
    const { bindingProps } = this.props;
    return bindingProps.form.jushoHokengaiOnly || false;
  }

  render() {
    return (
      <Fragment>
        {this.renderTempo()}
        <Mount if={!this.isJushoOnly()}>
          {this.renderToiawase()}
          {this.renderEigyojikan()}
          {this.renderTantoChiikiHokatsuShienCenter()}
          {this.renderOther()}
        </Mount>
      </Fragment>
    );
  }

  renderTempo() {
    const { bindingProps, masterData } = this.props;
    const { user } = this.props.authentication;
    return (
      <FormGrouping title="店舗・事業所・団体について">
        <Mount if={!this.isJushoOnly()}>
          <HorizontalLabelLayout labelText="業種" required>
            <OptionsRadio
              name="gyoshuCode"
              options={masterData.mimamoriGyoshuCode}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="店舗・事業所・団体名" required>
            <InputTypeText name="name" {...bindingProps} />
          </HorizontalLabelLayout>
        </Mount>
        <Mount if={user.canEditJushoOnly()}>
          <HorizontalLabelLayout labelText="住所のみ変更">
            <InputTypeCheckbox name="jushoHokengaiOnly" {...bindingProps} />
          </HorizontalLabelLayout>
        </Mount>
        <HorizontalLabelLayout labelText="〒 郵便番号" required>
          <GridRow>
            <GridCell>
              <InputTypeText
                name="zipCode"
                hintText="000-0000"
                {...bindingProps}
                onKeyDown={enterKeyHandler(this.handleEnterKeyDownOnYubimbango)}
              />
            </GridCell>
            <GridCell grow={2}>
              <IconButton onClick={this.handleEnterKeyDownOnYubimbango}>
                <FindIcon />
              </IconButton>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="都道府県" required>
          <DummyLabel name="prefName" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="市区町村" required>
          <DummyLabel name="cityName" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="番地" required>
          <ShortInputWidth>
            <InputTypeText name="address" hintText="0番地" {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="建物以下">
          <InputTypeText name="building" {...bindingProps} />
        </HorizontalLabelLayout>
        <Mount if={!this.isJushoOnly()}>
          <HorizontalLabelLayout labelText="非公開設定">
            <InputTypeCheckbox
              name="jushoHikokai"
              labelText="住所を公開しない"
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="ホームページURL">
            <InputTypeText
              name="homepageUrl"
              hintText="http://"
              {...bindingProps}
            />
          </HorizontalLabelLayout>
        </Mount>
      </FormGrouping>
    );
  }

  renderToiawase() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="問い合わせ">
        <HorizontalLabelLayout labelText="☎ 電話番号" required>
          <GridRow>
            <GridCell>
              <InputTypeText
                name="tel"
                hintText="00-0000-0000"
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <div>
                <RatioBlock divide={12}>
                  <Right>（ </Right>
                </RatioBlock>
                <RatioBlock grow={6} divide={12}>
                  <InputTypeText
                    name="telToiawasesaki"
                    hintText="例）ミルモネット問い合わせ係"
                    {...bindingProps}
                  />
                </RatioBlock>
                <RatioBlock divide={12}>）</RatioBlock>
              </div>
            </GridCell>
          </GridRow>
          <StaticLabel>
            ※
            お問合せ先が店舗・事業所・団体と異なる場合お問合せ先をご記入ください
            <br />
          </StaticLabel>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="FAX" recommended>
          <ShortInputWidth>
            <InputTypeText
              name="fax"
              hintText="00-0000-0000"
              {...bindingProps}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="Webフォーム">
          <InputTypeText
            name="contactUrl"
            hintText="http://"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderEigyojikan() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="営業情報">
        <HorizontalLabelLayout labelText="運営法人名">
          <InputTypeText name="corporationName" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="管理者名">
          <InputTypeText name="kanrishamei" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="営業日" recommended>
          <OptionsCheckbox
            name="eigyobi"
            split={6}
            options={masterData.eigyobiOptions}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="営業時間" recommended>
          <ShortInputWidth>
            <TimePeriod
              name="businessAt"
              separator="."
              {...bindingProps}
              disabled={bindingProps.form.allDayFlg}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="24時間対応">
          <InputTypeCheckbox
            name="allDayFlg"
            labelText="はい"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="年中無休">
          <InputTypeCheckbox
            name="allYearFlg"
            labelText="はい"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="定休日" recommended>
          <InputTypeText name="teikyubi" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderTantoChiikiHokatsuShienCenter() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="担当地域包括支援センター">
        <HorizontalLabelLayout
          labelText={
            <span>
              地域包括支援
              <br />
              センター名
            </span>
          }
          required
        >
          <InputTypeText
            name="tantoChiikiHokatsuShienCenter"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderOther() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="その他">
        <HorizontalLabelLayout labelText="特記事項">
          <Textarea name="tokkijiko" characterLimit={400} {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
  j;
}

MimamoriSuishinJigyoForm.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
  authentication: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOKENGAI)(
  connect(mapStateToProps, mapDispatchToProps)(MimamoriSuishinJigyoForm)
);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      zipcodeToAddress: (zipcode, callback) =>
        dispatch(zipcodeToAddress(zipcode, callback)),
    },
  };
}
