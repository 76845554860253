import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SharedFolderWidget from "./SharedFolderWidget";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_SERVICE,
} from "../../../state/master-data";

class WebFrontDashboard extends React.Component {
  folders() {
    const { services } = this.props.sharedFolder;
    if (!services) return [];
    return services
      .map((service) => ({
        serviceShubetsuCategoryCode: service.serviceShubetsuCategoryCode,
        serviceShubetsuCode: service.serviceShubetsuCode,
        count: 1,
      }))
      .reduce(
        (list, shubetsu) =>
          list.find(
            (s) => s.serviceShubetsuCode == shubetsu.serviceShubetsuCode
          )
            ? (function () {
                list.find(
                  (s) => s.serviceShubetsuCode == shubetsu.serviceShubetsuCode
                ).count++;
                return list;
              })()
            : list.concat([shubetsu]),
        []
      );
  }

  render() {
    return (
      <div className="c-content-frame">
        <SharedFolderWidget
          condition={this.props.serviceSearch.condition}
          folders={this.folders()}
          serviceShubetsuMenu={this.props.masterData.serviceShubetsuMenu}
        />
        {/*
                <NewsWidget />
                */}
      </div>
    );
  }
}

WebFrontDashboard.propTypes = {
  masterData: PropTypes.object.isRequired,
  sharedFolder: PropTypes.object.isRequired,
  serviceSearch: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SERVICE)(
  connect(mapStateToProps, mapDispatchToProps)(WebFrontDashboard)
);

function mapStateToProps(state) {
  return {
    masterData: state.masterData,
    sharedFolder: state.sharedFolder,
    serviceSearch: state.serviceSearch,
  };
}

function mapDispatchToProps() {
  return {};
}
