import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import { OPTIONS_ALLOWABILITY_FUZZY } from "../../../../../app/ui/form";
import Textarea from "../../../../../app/ui/form/Textarea";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import {
  MASTER_DATA_BUNDLE_FOR_SHORTSTAY,
  withMasterData,
} from "../../../../../state/master-data";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import HorizontalSubHeadLabelLayout from "../../../../../app/ui/form/HorizontalSubHeadLabelLayout";

class ShortstayNyuyokuForm extends React.Component {
  render() {
    return <Fragment>{this.renderNyuyoku()}</Fragment>;
  }

  renderNyuyoku() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="入浴">
        <HorizontalLabelLayout labelText="特徴">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout
          labelText={
            <span>
              入浴頻度
              <br />
              （要介助者の場合）
            </span>
          }
          required
        >
          <OptionsRadio
            name="nyuyokuhindoCode"
            split={6}
            options={masterData.shortstay_Nyuyokuhindo}
            {...bindingProps}
          />
        </HorizontalLabelLayout>

        <HorizontalLabelLayout
          labelText={
            <span>
              一回あたりの入浴時間
              <br />
              （要介助者の場合）
            </span>
          }
        >
          <ShortInputWidth>
            <HorizontalSubHeadLabelLayout>
              <UnitLayout unitText="分">
                <InputTypeNumber name="nyuyokuJikan" {...bindingProps} />
              </UnitLayout>
            </HorizontalSubHeadLabelLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>

        <HorizontalLabelLayout labelText="浴室の数" required>
          <ShortInputWidth>
            <HorizontalSubHeadLabelLayout>
              <UnitLayout unitText="箇所">
                <InputTypeNumber name="yokushitsunokazu" {...bindingProps} />
              </UnitLayout>
            </HorizontalSubHeadLabelLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>

        <HorizontalLabelLayout labelText="浴槽タイプ" required>
          <ShortInputWidth>
            <HorizontalSubHeadLabelLayout labelText="個浴">
              <UnitLayout unitText="箇所">
                <InputTypeNumber name="koyokunokazu" {...bindingProps} />
              </UnitLayout>
            </HorizontalSubHeadLabelLayout>
          </ShortInputWidth>
          <ShortInputWidth>
            <HorizontalSubHeadLabelLayout labelText="大浴槽">
              <UnitLayout unitText="箇所">
                <InputTypeNumber name="daiyokusonokazu" {...bindingProps} />
              </UnitLayout>
            </HorizontalSubHeadLabelLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>

        <HorizontalLabelLayout labelText="機械浴" required>
          <GridRow>
            <GridCell>
              <HorizontalSubHeadLabelLayout labelText="特殊浴槽">
                <UnitLayout unitText="箇所">
                  <InputTypeNumber
                    name="tokushuyokusonokazu"
                    {...bindingProps}
                  />
                </UnitLayout>
              </HorizontalSubHeadLabelLayout>
            </GridCell>
            <GridCell grow={2}>
              <GridRow>
                <GridCell>
                  <InputTypeCheckbox
                    name="shininyuyoku"
                    labelText="寝位入浴"
                    {...bindingProps}
                  />
                </GridCell>
                <GridCell>
                  <InputTypeCheckbox
                    name="zainyuyoku"
                    labelText="座位入浴"
                    {...bindingProps}
                  />
                </GridCell>
                <GridCell grow={2}></GridCell>
              </GridRow>
            </GridCell>
          </GridRow>
          <ShortInputWidth>
            <HorizontalSubHeadLabelLayout labelText="リフト入浴">
              <UnitLayout unitText="箇所">
                <InputTypeNumber name="liftyokunokazu" {...bindingProps} />
              </UnitLayout>
            </HorizontalSubHeadLabelLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>

        <HorizontalLabelLayout labelText="同性介助" required>
          <OptionsRadio
            name="doseikaijo"
            split={6}
            options={OPTIONS_ALLOWABILITY_FUZZY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="その他">
          <OptionsCheckbox
            name="nyuyokuSonota"
            options={masterData.shortstay_NyuyokuSonota}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="その他設備等">
          <Textarea name="sonotasetsubito" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

ShortstayNyuyokuForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SHORTSTAY)(
  ShortstayNyuyokuForm
);
