import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../app/ui/layout/Mount";
import { goToJigyoshoAttributeFormButton } from "../GoToEditFormButton";
import ExternalLinkedUrl from "../../../../app/ui/link/ExternalLinkedUrl";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";

class TsushokeiServiceDetail extends React.Component {
  render() {
    return (
      <div>
        <div className="p-office-body">
          {this.renderEigyoJoho()}

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">
              {this.renderRehabilitation()}
            </div>

            <div className="p-office-body-sep__right">
              {this.renderRecreation()}
            </div>
          </div>

          <div className="p-office-body-sep2">
            <div className="p-office-body-sep__left">
              {this.renderShokuji()}
            </div>

            <div className="p-office-body-sep__right">
              {this.renderNyuyoku()}

              {this.renderHaisetsu()}
            </div>
          </div>

          <div className="p-office-body-section">
            {this.renderTaiokanonaShojo()}
          </div>

          {this.renderRiyosha()}

          {this.renderStaff()}

          {this.renderRyokin()}

          {this.renderTatemonoSetsubiBihin()}

          {this.renderGaibuRenkei()}

          {this.renderSonotaService()}

          {this.renderJigyoshoJoho()}
        </div>
      </div>
    );
  }

  renderEigyoJoho() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">営業情報</div>
        </div>

        <div className="p-office-body-sep">
          <div className="p-office-body-sep__left">
            {this.renderEigyoJikan()}

            {this.renderSogei()}
          </div>

          <div className="p-office-body-sep__right">
            {this.renderTeikyoJikan()}
          </div>
        </div>
      </Fragment>
    );
  }

  renderEigyoJikan() {
    const { contents } = this.props;
    const eigyoJikan = contents.blocks.tsushokeiServiceJigyoshoEigyoJikan;

    return (
      <Fragment>
        <div className="p-office-body-title">営業時間</div>

        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>平日</th>
                <td>{eigyoJikan.heijitsu.join(" 〜 ")}</td>
              </tr>
              <tr>
                <th>土曜</th>
                <td>{eigyoJikan.doyo.join(" 〜 ")}</td>
              </tr>
              <tr>
                <th>日曜</th>
                <td>{eigyoJikan.nichiyo.join(" 〜 ")}</td>
              </tr>
              <tr>
                <th>祝日</th>
                <td>{eigyoJikan.shukujitsu.join(" 〜 ")}</td>
              </tr>
              <tr>
                <th>定休日</th>
                <td>{eigyoJikan.teikyubi}</td>
              </tr>
              <tr>
                <th>早朝対応</th>
                <td>{eigyoJikan.sochoTaio}</td>
              </tr>
              <tr>
                <th>夜間対応</th>
                <td>{eigyoJikan.yakanTaio}</td>
              </tr>
              <tr>
                <th>宿泊対応</th>
                <td>{eigyoJikan.shukuhakuTaio}</td>
              </tr>
              <tr>
                <th>備考</th>
                <td>{eigyoJikan.biko}</td>
              </tr>
            </tbody>
          </table>

          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.tsushokeiServiceJigyoshoEigyoJikan
          )}
        </div>
      </Fragment>
    );
  }

  renderTeikyoJikan() {
    const { contents } = this.props;
    const teikyoJikan = contents.blocks.tsushokeiServiceJigyoshoTeikyoJikan;

    return (
      <Fragment>
        <div className="p-office-body-title">サービス提供時間</div>

        <div className="p-office-body-table-servicetime">
          <table>
            <thead>
              <tr>
                <th colSpan="2">時間区分</th>
                <th>提供時間帯</th>
              </tr>
            </thead>
            <tbody>
              {teikyoJikan.categories.map((category, i) => {
                const kubuns =
                  category.kubuns.length > 0
                    ? category.kubuns
                    : [{ label: "", jikantai: [] }];
                return kubuns.map((kubun, j) => {
                  return (
                    <tr key={`${i},${j}`}>
                      <Mount if={j === 0}>
                        <th rowSpan={kubuns.length}>{category.label}</th>
                      </Mount>
                      <td>{kubun.label}</td>
                      <td>{kubun.jikantai.join("\n")}</td>
                    </tr>
                  );
                });
              })}
              <tr>
                <th>備考</th>
                <td colSpan="2">{teikyoJikan.biko}</td>
              </tr>
            </tbody>
          </table>

          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.serviceTeikyoJikan
          )}
        </div>
      </Fragment>
    );
  }

  renderSogei() {
    const { contents } = this.props;
    const sogei = contents.blocks.tsushokeiServiceJigyoshoSogei;

    return (
      <Fragment>
        <div className="p-office-body-title">送迎</div>

        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>サービス提供地域</th>
                <td>{sogei.serviceTeikyochiiki}</td>
              </tr>
              <tr>
                <th>サービス提供地域備考</th>
                <td>{sogei.serviceTeikyoChiikiBiko}</td>
              </tr>
              <tr>
                <th>車両台数</th>
                <td>{sogei.sharyodaisu}</td>
              </tr>
              <tr>
                <th>送迎時居宅内介護</th>
                <td>{sogei.sogeijikyotakunaikaigo}</td>
              </tr>
              <tr>
                <th>送迎車ロゴ</th>
                <td>{sogei.sogeishaLogo}</td>
              </tr>
              <tr>
                <th>送迎時間個別対応</th>
                <td>{sogei.sogeijikankobetsutaio}</td>
              </tr>
              <tr>
                <th>備考</th>
                <td>{sogei.biko}</td>
              </tr>
            </tbody>
          </table>

          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.sogei
          )}
        </div>
      </Fragment>
    );
  }

  renderRehabilitation() {
    const { contents } = this.props;
    const rehabilitation =
      contents.blocks.tsushokeiServiceJigyoshoRehabilitation;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">リハビリ・機能訓練</div>
        </div>
        <div className="p-office-body-title">個別機能訓練(個別対応)</div>
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>個別対応</th>
                <td>{rehabilitation.kobetsutaio}</td>
              </tr>
              <tr>
                <th>担当制</th>
                <td>{rehabilitation.kobetsutaioTantousei}</td>
              </tr>
              <tr>
                <th>内容</th>
                <td>
                  {rehabilitation.rehabilitationKobetsuKinokunrennaiyo.join(
                    "、"
                  )}
                </td>
              </tr>
              <tr>
                <th>時間(分)</th>
                <td>{rehabilitation.kobetsutaioJikan}</td>
              </tr>
              <tr>
                <th>療法</th>
                <td>{rehabilitation.ryoho.join("、")}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-office-body-title">個別機能訓練(集団対応)</div>
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>集団対応</th>
                <td>{rehabilitation.shudantaio}</td>
              </tr>
              <tr>
                <th>内容</th>
                <td>
                  {rehabilitation.rehabilitationShudanKinokunrennaiyo.join(
                    "、"
                  )}
                </td>
              </tr>
              <tr>
                <th>人数</th>
                <td>{rehabilitation.shudantaioNinzu}</td>
              </tr>
              <tr>
                <th>時間(分)</th>
                <td>{rehabilitation.shudantaioJikan}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-office-body-title">環境・設備</div>
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>リハビリ機材</th>
                <td className="val">
                  <ul>
                    {rehabilitation.rehabiliKizai.map((item, i) => {
                      return (
                        <li key={i}>
                          <div key={i}>
                            <div className="c-small-subtitle">
                              ▼{item.label}
                            </div>
                            <div>{item.list.join("、")}</div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </td>
              </tr>
              <tr>
                <th>訓練場所</th>
                <td>{rehabilitation.rehabilitationKunrembasho.join("、")}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-office-body-title">介護報酬加算</div>
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>介護報酬加算</th>
                <td>{rehabilitation.kaigohoshukasan.join("、")}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-office-body-title">備考</div>
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>備考</th>
                <td>{rehabilitation.biko}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-office-body-characteristic">特 徴</div>

        <div className="p-office-body-description p-office-body-description--p">
          {rehabilitation.tokucho}
        </div>

        {goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.rehabilitation
        )}
      </Fragment>
    );
  }

  renderRecreation() {
    const { contents } = this.props;
    const recreation = contents.blocks.tsushokeiServiceJigyoshoRecreation;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">レクリエーション・行事</div>
        </div>

        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>個別レク</th>
                <td>{recreation.kobetsuReku}</td>
              </tr>
              <tr>
                <th>集団レク</th>
                <td>{recreation.shudanReku}</td>
              </tr>
              <tr>
                <th>外出レク</th>
                <td>{recreation.gaishutsuReku}</td>
              </tr>
              <tr>
                <th>アピールしたいレク</th>
                <td>{recreation.appealReku.join("、")}</td>
              </tr>
              <tr>
                <th>外部講師によるレク</th>
                <td>{recreation.gaibukoshiniyoruReku.join("、")}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-office-body-characteristic">特 徴</div>

        <div className="p-office-body-description p-office-body-description--p">
          {recreation.tokucho}
        </div>

        {goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.recreation
        )}
      </Fragment>
    );
  }

  renderShokuji() {
    const { contents } = this.props;
    const shokuji = contents.blocks.tsushokeiServiceJigyoshoShokuji;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">食事</div>
        </div>

        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>朝食</th>
                <td>{shokuji.choshoku}</td>
              </tr>
              <tr>
                <th>昼食</th>
                <td>{shokuji.chushoku}</td>
              </tr>
              <tr>
                <th>おやつ</th>
                <td>{shokuji.oyatsu}</td>
              </tr>
              <tr>
                <th>夕食</th>
                <td>{shokuji.yushoku}</td>
              </tr>
              <tr>
                <th>備考（料金）</th>
                <td>{shokuji.biko}</td>
              </tr>
              <tr>
                <th>嚥下食対応</th>
                <td>{shokuji.engeshokutaio}</td>
              </tr>
              <tr>
                <th>制限食対応</th>
                <td>{shokuji.seigenshokutaio}</td>
              </tr>
              <tr>
                <th>栄養士</th>
                <td>{shokuji.eiyoshi}</td>
              </tr>
              <tr>
                <th>調理場所</th>
                <td>{shokuji.choribasho}</td>
              </tr>
              <tr>
                <th>その他</th>
                <td>{shokuji.sonota.join("、")}</td>
              </tr>
              <tr>
                <th>備考（オプション）</th>
                <td>{shokuji.optionBiko}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-office-body-characteristic">特 徴</div>

        <div className="p-office-body-description p-office-body-description--p">
          {shokuji.tokucho}
        </div>

        {goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.shokuji
        )}
      </Fragment>
    );
  }

  renderNyuyoku() {
    const { contents } = this.props;
    const nyuyoku = contents.blocks.tsushokeiServiceJigyoshoNyuyoku;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">入浴</div>
        </div>

        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>浴室の数</th>
                <td>{nyuyoku.yokushitsunokazu}</td>
              </tr>
              <tr>
                <th>浴槽タイプ</th>
                <td>
                  {nyuyoku.yokusoType
                    .map((item) => {
                      return `${item.label}${item.kazu}`;
                    })
                    .join("、")}
                </td>
              </tr>
              <tr>
                <th>機械浴</th>
                <td>
                  {nyuyoku.kikaiyoku
                    .map((item) => {
                      return `${item.label}${item.kazu}`;
                    })
                    .join("、")}
                </td>
              </tr>
              <tr>
                <th>その他</th>
                <td>{nyuyoku.sonota.join("、")}</td>
              </tr>
              <tr>
                <th>同性介助</th>
                <td>{nyuyoku.doseikaijo}</td>
              </tr>
              <tr>
                <th>その他設備等</th>
                <td>{nyuyoku.sonotasetsubi}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-office-body-characteristic">特 徴</div>

        <div className="p-office-body-description p-office-body-description--p">
          {nyuyoku.tokucho}
        </div>

        {goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.nyuyoku
        )}
      </Fragment>
    );
  }

  renderHaisetsu() {
    const { contents } = this.props;
    const haisetsu = contents.blocks.tsushokeiServiceJigyoshoHaisetsu;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">排泄</div>
        </div>

        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>車椅子対応</th>
                <td>{haisetsu.kurumaisutaio}</td>
              </tr>
              <tr>
                <th>同性介助</th>
                <td>{haisetsu.doseikaijo}</td>
              </tr>
              <tr>
                <th>その他</th>
                <td>{haisetsu.sonota.join("、")}</td>
              </tr>
              <tr>
                <th>備考</th>
                <td>{haisetsu.biko}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="p-office-body-characteristic">特 徴</div>

        <div className="p-office-body-description p-office-body-description--p">
          {haisetsu.tokucho}
        </div>

        {goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.haisetsu
        )}
      </Fragment>
    );
  }

  renderTaiokanonaShojo() {
    const { contents } = this.props;
    const taiokanonaShojo =
      contents.blocks.tsushokeiServiceJigyoshoTaiokanoNaShojo;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">
            受入れ / 対応可能な症状・医療行為
          </div>
        </div>

        <div className="p-office-body-table">
          <table>
            <tbody>
              {taiokanonaShojo.list.map((item, i) => {
                return (
                  <tr key={i}>
                    <th>
                      {(() => {
                        if (item.label == "受入れ / 対応可能な医療行為") {
                          return "受入れ / 対応可能な 医療行為";
                        } else {
                          return item.label;
                        }
                      })()}
                    </th>
                    <td>
                      {(() => {
                        if (item.taioka.length > 0) {
                          return (
                            <div className="p-office-body-table__doublecircle">
                              {item.taioka.join("、")}
                            </div>
                          );
                        }
                      })()}
                      {(() => {
                        if (item.ka.length > 0) {
                          return (
                            <div className="p-office-body-table__circle">
                              {item.ka.join("、")}
                            </div>
                          );
                        }
                      })()}
                      {(() => {
                        if (item.yousoudan.length > 0) {
                          return (
                            <div className="p-office-body-table__triangle">
                              {item.yousoudan.join("、")}
                            </div>
                          );
                        }
                      })()}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="p-office-body-note">
          <span className="p-office-body-legend__doublecircle">
            対応可能&nbsp;
          </span>
          <span className="p-office-body-legend__circle">受入れ可能&nbsp;</span>
          <span className="p-office-body-legend__triangle">要相談&nbsp;</span>
        </div>
        {goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.taiokanoNaShojo
        )}
      </Fragment>
    );
  }

  renderRiyosha() {
    const { contents } = this.props;
    const riyosha = contents.blocks.tsushokeiServiceJigyoshoRiyosha;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">ご利用者</div>
        </div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>男女別ご利用者数</th>
                    <td className="val">
                      <ul>
                        <li>
                          <div className="val-name">男性</div>
                          <div className="val-val">{riyosha.danseiNinzu}</div>
                        </li>
                        <li>
                          <div className="val-name">女性</div>
                          <div className="val-val">{riyosha.joseiNinzu}</div>
                        </li>
                        <li>
                          <div className="val-name">合計</div>
                          <div className="val-val">{riyosha.gokeiNinzu}</div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>年齢別ご利用者数</th>
                    <td className="val">
                      <ul>
                        <li>
                          <div className="val-name">65歳未満</div>
                          <div className="val-val">
                            {riyosha.nenreibetsu65miman}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">65歳〜75歳未満</div>
                          <div className="val-val">
                            {riyosha.nenreibetsu65ijo75miman}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">75歳〜85歳未満</div>
                          <div className="val-val">
                            {riyosha.nenreibetsu75ijo85miman}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">85歳〜95歳未満</div>
                          <div className="val-val">
                            {riyosha.nenreibetsu85ijo95miman}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">95歳以上</div>
                          <div className="val-val">
                            {riyosha.nenreibetsu95ijo}
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="p-office-body-sep__right">
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>要介護度別ご利用者数</th>
                    <td className="val">
                      <ul>
                        <li>
                          <div className="val-name">要支援1</div>
                          <div className="val-val">{riyosha.yoshien1}</div>
                        </li>
                        <li>
                          <div className="val-name">要支援2</div>
                          <div className="val-val">{riyosha.yoshien2}</div>
                        </li>
                        <li>
                          <div className="val-name">要介護1</div>
                          <div className="val-val">{riyosha.yokaigo1}</div>
                        </li>
                        <li>
                          <div className="val-name">要介護2</div>
                          <div className="val-val">{riyosha.yokaigo2}</div>
                        </li>
                        <li>
                          <div className="val-name">要介護3</div>
                          <div className="val-val">{riyosha.yokaigo3}</div>
                        </li>
                        <li>
                          <div className="val-name">要介護4</div>
                          <div className="val-val">{riyosha.yokaigo4}</div>
                        </li>
                        <li>
                          <div className="val-name">要介護5</div>
                          <div className="val-val">{riyosha.yokaigo5}</div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <th>歩行自立者の割合</th>
                    <td>{riyosha.hokojiritsushaNoWariai}</td>
                  </tr>
                  <tr>
                    <th>その他</th>
                    <td>{riyosha.sonota.join("、")}</td>
                  </tr>
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.riyosha
              )}
            </div>
          </div>
        </div>

        <div className="p-office-body-characteristic">特 徴</div>

        <div className="p-office-body-description p-office-body-description--p">
          {riyosha.tokucho}
        </div>
      </Fragment>
    );
  }

  renderStaff() {
    const { contents } = this.props;
    const staff = contents.blocks.tsushokeiServiceJigyoshoStaff;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">スタッフ</div>
        </div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>男女別従業員数</th>
                    <td className="val">
                      <ul>
                        <li>
                          <div className="val-name">男性</div>
                          <div className="val-val">{staff.danseiNinzu}</div>
                        </li>
                        <li>
                          <div className="val-name">女性</div>
                          <div className="val-val">{staff.joseiNinzu}</div>
                        </li>
                        <li>
                          <div className="val-name">合計</div>
                          <div className="val-val">{staff.gokeiNinzu}</div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="p-office-body-sep__right">
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>年齢別従業員数</th>
                    <td className="val">
                      <ul>
                        <li>
                          <div className="val-name">20代</div>
                          <div className="val-val">
                            {staff.nenreibetsu20DaiNinzu}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">30代</div>
                          <div className="val-val">
                            {staff.nenreibetsu30DaiNinzu}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">40代</div>
                          <div className="val-val">
                            {staff.nenreibetsu40DaiNinzu}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">50代</div>
                          <div className="val-val">
                            {staff.nenreibetsu50DaiNinzu}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">60代以上</div>
                          <div className="val-val">
                            {staff.nenreibetsu60DaiNinzu}
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-title">生活相談員</div>
            {this.renderStaffTable(staff.seikatsusodanin)}
          </div>

          <div className="p-office-body-sep__right">
            <div className="p-office-body-title">看護職員</div>
            {this.renderStaffTable(staff.kangoshokuin)}
          </div>
        </div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-title">介護職員</div>
            {this.renderStaffTable(staff.kaigoshokuin)}
          </div>
          <div className="p-office-body-sep__right">
            <div className="p-office-body-title">機能訓練指導員</div>
            {this.renderStaffTable(staff.kinokunrenshidoin)}
          </div>
        </div>

        <div className="p-office-body-title">資格保有者数</div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__center">
            <div className="p-office-body-table">
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>歯科衛生士</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(staff.shikaeiseishi)}
                  </span>
                </div>
                <div className="thead border-left">
                  <span>看護師及び准看護師</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(
                      staff.kangoshiOyobiJunkangoshi
                    )}
                  </span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>管理栄養士</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(staff.kanrieiyoshi)}
                  </span>
                </div>
                <div className="thead border-left">
                  <span>理学療法士</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(staff.rigakuryohoshi)}
                  </span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>介護福祉士</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(staff.kaigofukushishi)}
                  </span>
                </div>
                <div className="thead border-left">
                  <span>作業療法士</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(staff.sagyoryohoshi)}
                  </span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>社会福祉士</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(staff.shakaifukushishi)}
                  </span>
                </div>
                <div className="thead border-left">
                  <span>言語聴覚士</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(staff.gengochokakushi)}
                  </span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>実務者研修</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(staff.jitsumushakenshu)}
                  </span>
                </div>
                <div className="thead border-left">
                  <span>柔道整復士</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(staff.judoseifukushi)}
                  </span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>介護職員初任者研修</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(
                      staff.kaigoshokuinshoninshakenshu
                    )}
                  </span>
                </div>
                <div className="thead border-left">
                  <span>認定療法士</span>
                </div>
                <div className="tdata">
                  <span>{staff.ninteiryohoshi}</span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>あん摩マッサージ指圧師</span>
                </div>
                <div className="tdata">
                  <span>
                    {this.renderStaffJokinHijokin(staff.ammaMassageShiatsushi)}
                  </span>
                </div>
                <div className="thead">
                  <span>マッサージ資格保有者</span>
                </div>
                <div className="tdata">
                  <span>{staff.massage}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="p-office-body-sep">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>経験年数5年以上の従業員の割合</th>
                    <td>{staff.keikenNensu5NenijoWariai}</td>
                  </tr>
                  <tr>
                    <th>看護・介護職員1人あたりの利用者の割合</th>
                    <td>{staff.kangoKaigoshokuinWariai}</td>
                  </tr>
                  <tr>
                    <th>その他</th>
                    <td>{staff.sonota.join("、")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <div className="p-office-body-sep__right">
            <div className="p-office-body-table2">
              <div className="p-office-body-table2__th">認知症介護</div>
              <div className="p-office-body-table2__td">
                <ul>
                  <li>
                    <div>基礎研修</div>
                    <div>{staff.ninchishokaigoKisokenshu}</div>
                  </li>
                  <li>
                    <div>実践者研修</div>
                    <div>{staff.ninchishokaigoJissenshakenshu}</div>
                  </li>
                  <li>
                    <div>実践リーダー研修</div>
                    <div>{staff.ninchishokaigoJissenLeaderKenshu}</div>
                  </li>
                  <li>
                    <div>指導者養成研修</div>
                    <div>{staff.ninchishokaigoShidoshayoseikenshu}</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="p-office-body-characteristic">特 徴</div>
            <div className="p-office-body-description p-office-body-description--p">
              {staff.tokucho}
            </div>

            {goToJigyoshoAttributeFormButton(
              this.props.contents,
              JigyoshoAttributeType.staff
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  renderStaffTable(shokushu) {
    return (
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>常勤・非常勤別</th>
              <td>{this.renderStaffJokinHijokin(shokushu.jokinHijokin)}</td>
            </tr>
            <tr>
              <th>常勤換算</th>
              <td>{shokushu.jokinkansan}</td>
            </tr>
            <tr>
              <th>経験年数別従業員数</th>
              <td className="val">
                <ul>
                  <li>
                    <div className="val-name">1年未満</div>
                    <div className="val-val">{shokushu.keiken1miman}</div>
                  </li>
                  <li>
                    <div className="val-name">～3年未満</div>
                    <div className="val-val">{shokushu.keiken1ijo3miman}</div>
                  </li>
                  <li>
                    <div className="val-name">～5年未満</div>
                    <div className="val-val">{shokushu.keiken3ijo5miman}</div>
                  </li>
                  <li>
                    <div className="val-name">～10年未満</div>
                    <div className="val-val">{shokushu.keiken5ijo10miman}</div>
                  </li>
                  <li>
                    <div className="val-name">～10年以上</div>
                    <div className="val-val">{shokushu.keiken10ijo}</div>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderStaffJokinHijokin(jokinHijokin) {
    return [
      jokinHijokin.jokin ? "常勤" + jokinHijokin.jokin : null,
      jokinHijokin.hijokin ? "非常勤" + jokinHijokin.hijokin : null,
      jokinHijokin.gokei ? "合計" + jokinHijokin.gokei : null,
    ]
      .filter((item) => {
        return item !== null;
      })
      .join("、");
  }

  renderRyokin() {
    const { contents } = this.props;
    const ryokinKasan = contents.blocks.tsushokeiServiceJigyoshoRyokinKasanJoho;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">料金</div>
        </div>

        {[ServiceShubetsuType.療養通所介護]
          .map((it) => it.code)
          .includes(contents.serviceShubetsuCode) ? (
          <Fragment>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>介護報酬加算</th>
                    <td>
                      {ryokinKasan.kaigohoshukasan
                        .flatMap((it) => it.list)
                        .join("、")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="p-office-body-title">介護報酬加算</div>

            <div className="p-office-body-table">
              <table>
                <tbody>
                  {ryokinKasan.kaigohoshukasan.map((item, i) => {
                    return (
                      <tr key={i}>
                        <th>{item.label}</th>
                        <td>{item.list.join("、")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Fragment>
        )}

        <div className="p-office-body-title">利用料金の目安</div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            {this.renderRyokinnomeyasuByYokaigodo(1, ryokinKasan.yokaigodo1)}
          </div>

          <div className="p-office-body-sep__right">
            {this.renderRyokinnomeyasuByYokaigodo(2, ryokinKasan.yokaigodo2)}
          </div>
        </div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            {this.renderRyokinnomeyasuByYokaigodo(3, ryokinKasan.yokaigodo3)}
          </div>
          <div className="p-office-body-sep__right">
            {this.renderRyokinnomeyasuByYokaigodo(4, ryokinKasan.yokaigodo4)}
          </div>
        </div>

        <div className="p-office-body-sep">
          <div className="p-office-body-sep__left">
            {this.renderRyokinnomeyasuByYokaigodo(5, ryokinKasan.yokaigodo5)}
          </div>
          <div className="p-office-body-sep__right">
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>都度発生する料金</th>
                    <td>{ryokinKasan.tsudoHasseisuruRyokin.join("、")}</td>
                  </tr>
                  <tr>
                    <th>利用者負担軽減制度</th>
                    <td>{ryokinKasan.riyoshafutanKeigenSeido}</td>
                  </tr>
                  <tr>
                    <th>支払い方法</th>
                    <td>{ryokinKasan.shiharaiHoho.join("、")}</td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td>{ryokinKasan.biko}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="p-office-body-note">
              ※自己負担額が1割の場合の目安料金です
            </div>

            {goToJigyoshoAttributeFormButton(
              this.props.contents,
              JigyoshoAttributeType.ryokinKasanJoho
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  renderRyokinnomeyasuByYokaigodo(yokaigodo, ryokin) {
    return (
      <div className="p-office-body-table2">
        <div className="p-office-body-table2__th">要介護度{yokaigodo}</div>
        <div className="p-office-body-table2__td">
          <ul>
            <li>
              <div className="p-office-body-table2__td-name">自己負担額</div>
              <div className="p-office-body-table2__td-val">
                {ryokin.jikofutangaku}
              </div>
            </li>
            <li>
              <div className="p-office-body-table2__td-name">昼食代</div>
              <div className="p-office-body-table2__td-val">
                {ryokin.chushokudai}
              </div>
            </li>
            <li>
              <div className="p-office-body-table2__td-name">おやつ代</div>
              <div className="p-office-body-table2__td-val">
                {ryokin.oyatsudai}
              </div>
            </li>
            <li className="u-fontweight-b">
              <div className="p-office-body-table2__td-name">合計金額</div>
              <div className="p-office-body-table2__td-val">
                {ryokin.gokeikingaku}
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  renderTatemonoSetsubiBihin() {
    const { contents } = this.props;
    const tatemonoSetsubiBihin =
      contents.blocks.tsushokeiServiceJigyoshoTatemonoSetsubiBihin;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">建物・設備・備品</div>
        </div>

        <div className="p-office-body-section">
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>事業所設置階</th>
                  <td>{tatemonoSetsubiBihin.jigyoshoSetchikai}</td>
                </tr>
                <tr>
                  <th>建物面積</th>
                  <td>{tatemonoSetsubiBihin.tatemonoMenseki.join("、")}</td>
                </tr>
                <tr>
                  <th>構造</th>
                  <td>{tatemonoSetsubiBihin.kozo}</td>
                </tr>
                <tr>
                  <th>エレベーター</th>
                  <td>{tatemonoSetsubiBihin.elevator}</td>
                </tr>
                <tr>
                  <th>デザイン</th>
                  <td>{tatemonoSetsubiBihin.design}</td>
                </tr>
                <tr>
                  <th>建物改築区分</th>
                  <td>{tatemonoSetsubiBihin.tatemonokaichikukubun}</td>
                </tr>
                <tr>
                  <th>ナースコール</th>
                  <td>{tatemonoSetsubiBihin.nurseCall.join("、")}</td>
                </tr>
                <tr>
                  <th>設備</th>
                  <td>{tatemonoSetsubiBihin.setsubi.join("、")}</td>
                </tr>
                <tr>
                  <th>備品</th>
                  <td>{tatemonoSetsubiBihin.bihin.join("、")}</td>
                </tr>
                <tr>
                  <th>備考</th>
                  <td>{tatemonoSetsubiBihin.biko}</td>
                </tr>
              </tbody>
            </table>

            {goToJigyoshoAttributeFormButton(
              this.props.contents,
              JigyoshoAttributeType.tatemonoSetsubiBihin
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  renderGaibuRenkei() {
    const { contents } = this.props;
    const gaibuRenkei = contents.blocks.tsushokeiServiceJigyoshoGaibuRenkei;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">外部連携</div>
        </div>

        <div className="p-office-body-section">
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>協力連携医療機関</th>
                  <td>{gaibuRenkei.kyoryokuRenkeiIryokikan}</td>
                </tr>
              </tbody>
            </table>

            {goToJigyoshoAttributeFormButton(
              this.props.contents,
              JigyoshoAttributeType.gaibuRenkei
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  renderSonotaService() {
    const { contents } = this.props;
    const sonota = contents.blocks.tsushokeiServiceJigyoshoTushokeiSonota;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">その他サービス</div>
        </div>

        <div className="p-office-body-section">
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>その他サービス</th>
                  <td>{sonota.sonotaService.join("、")}</td>
                </tr>
                <tr>
                  <th>備考</th>
                  <td>{sonota.biko}</td>
                </tr>
              </tbody>
            </table>

            {goToJigyoshoAttributeFormButton(
              this.props.contents,
              JigyoshoAttributeType.tsushokeiServiceJigyoshoSonota
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  renderJigyoshoJoho() {
    const { contents } = this.props;
    const hokennaiService =
      contents.blocks.tsushokeiServiceJigyoshoHokennaiJigyoshoJoho;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">事業所情報</div>
        </div>

        <div className="p-office-body-table u-margin-b32">
          <table>
            <tbody>
              <tr>
                <th>事業所名</th>
                <td>{hokennaiService.jigyoshomei}</td>
              </tr>
              <tr>
                <th>サービス種別</th>
                <td>{contents.serviceShubetsu}</td>
              </tr>
              <tr>
                <th>TEL</th>
                <td>{hokennaiService.tel}</td>
              </tr>
              <tr>
                <th>FAX</th>
                <td>{hokennaiService.fax}</td>
              </tr>
              <tr>
                <th>事業所番号</th>
                <td>{hokennaiService.jigyoshobango}</td>
              </tr>
              <tr>
                <th>事業開始日</th>
                <td>{hokennaiService.jigyokaishibi}</td>
              </tr>
              <tr>
                <th>郵便番号</th>
                <td>{hokennaiService.yubimbango}</td>
              </tr>
              <tr>
                <th>住所</th>
                <td>{hokennaiService.jusho}</td>
              </tr>
              <tr>
                <th>交通手段</th>
                <td>{hokennaiService.kotsushudan}</td>
              </tr>
              <tr>
                <th>運営法人</th>
                <td>{hokennaiService.uneihojin}</td>
              </tr>
              <tr>
                <th>管理者名</th>
                <td>{hokennaiService.kanrishamei}</td>
              </tr>
              <tr>
                <th>ホームページ</th>
                <td>
                  <ExternalLinkedUrl url={hokennaiService.hpAddress} />
                </td>
              </tr>
              <tr>
                <th>提供サービス</th>
                <td>{hokennaiService.teikyoServiceShubetsu.join("、")}</td>
              </tr>
              <tr>
                <th>併設サービス</th>
                <td>{hokennaiService.heisetsuService.join("、")}</td>
              </tr>
              <tr>
                <th>生活保護受入機関指定</th>
                <td>{hokennaiService.seikatsuhogoUkeire}</td>
              </tr>
              <tr>
                <th>キャッチフレーズ</th>
                <td>{hokennaiService.catchPhrase}</td>
              </tr>
              <tr>
                <th>特徴・理念</th>
                <td>{hokennaiService.tokuchoRinen}</td>
              </tr>
            </tbody>
          </table>

          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.tsushokeiServiceJigyoshoJoho
          )}
        </div>
      </Fragment>
    );
  }
}

TsushokeiServiceDetail.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default TsushokeiServiceDetail;
