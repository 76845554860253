import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";

const GyoseiBlock = ({ gyosei }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="行政の助成制度や補助について" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>
                <div>使用できる</div>
                <div>助成制度</div>
              </th>
              <td>{gyosei.hojokenriyo}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>備考</th>
              <td>{gyosei.biko}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

GyoseiBlock.propTypes = {
  gyosei: PropTypes.object.isRequired,
};

export default GyoseiBlock;
