import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ThWithBg from "../general/ThWithBg";
import HeaderTextBlue from "../general/HeaderTextBlue";

const KyoshitsuBlock = ({ kyoshitsujoho }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="居室情報" />

      <div>
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <ThWithBg text="居室数" colSpan={3} />
                <ThWithBg text="室内設備" />
              </tr>
              <tr className="td_kyoshitsu">
                <td>
                  <img src="/images/print_bg_right_blue.png" />
                  <span>個室</span>
                </td>
                <td>
                  <img src="/images/print_bg_right_blue.png" />
                  <span>2人</span>
                </td>
                <td>
                  <img src="/images/print_bg_right_blue.png" />
                  <span>3人～</span>
                </td>
                <td
                  rowSpan={4}
                  style={{ textAlign: "left", width: "65%", lineHeight: "1.5" }}
                >
                  {kyoshitsujoho.shitsunaisetsubi}
                </td>
              </tr>
              <tr className="td_kyoshitsuvalue">
                <td>{kyoshitsujoho.koshitsu}</td>
                <td>{kyoshitsujoho.futaribeya}</td>
                <td>{kyoshitsujoho.sanninbeyaijo}</td>
              </tr>
              <tr>
                <ThWithBg text="居室タイプ" colSpan={3} />
              </tr>
              <tr>
                <td colSpan={3} style={{ textAlign: "left" }}>
                  {kyoshitsujoho.kyoshitsuType.map((it) => (
                    <div key={it}>・{it}</div>
                  ))}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

KyoshitsuBlock.propTypes = {
  kyoshitsujoho: PropTypes.object.isRequired,
};

export default KyoshitsuBlock;
