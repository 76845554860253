import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import CategorizedOptions from "../../../../../app/util/CategorizedMasterData";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import {
  OPTIONS_TAIO_JOKYO,
  OPTIONS_TAIO_JOKYO_WITH_IRYO,
} from "../../../../../app/ui/form";
import DummyError from "../../../../../app/ui/form/DummyError";

/**
 * 訪問系サービス対応可能な症状フォーム.
 */
class HomonkeiServiceTaiokanoShojoForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        {this.renderHomonkeiServiceTaiokanoShojo()}
        {this.renderError()}
      </Fragment>
    );
  }

  renderHomonkeiServiceTaiokanoShojo() {
    const { bindingProps, masterData } = this.props;
    const categories = new CategorizedOptions(
      masterData.homonkeiService_taiokanoShojo
    ).getCategories();
    return categories.map((category) => (
      <FormGrouping
        key={`category:${category.code}`}
        title={category.label}
        recommended
      >
        {category.options.map((option) => (
          <HorizontalLabelLayout
            key={`option:${option.code}`}
            long
            labelText={option.label}
          >
            <OptionsRadio
              name={`shojo.${option.code}`}
              options={
                category.code == "002"
                  ? OPTIONS_TAIO_JOKYO_WITH_IRYO
                  : OPTIONS_TAIO_JOKYO
              }
              split={6}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
        ))}
      </FormGrouping>
    ));
  }

  renderError() {
    return (
      <HorizontalLabelLayout long>
        <DummyError name="shojo" />
      </HorizontalLabelLayout>
    );
  }
}

HomonkeiServiceTaiokanoShojoForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceTaiokanoShojoForm
);
