import fetch from "isomorphic-fetch";
import ApiPath from "./ApiPath";
import JwtTokenRepository from "./JwtTokenRepository";
import StatusCode from "./StatusCode";
import JwtToken from "./JwtToken";

class AuthenticationRequest {
  constructor(method, headers, body) {
    this.method = method;
    this.headers = headers;
    this.body = body;
  }

  request(responseHandler, unauthorizedHandler) {
    const url = ApiPath.api_authentication_login;
    const options = {
      method: this.method,
      headers: this.headers,
      credentials: "omit",
    };
    if (this.body) options.body = this.body;
    fetch(url, options)
      .then((response) => {
        const statusCode = new StatusCode(response.status);
        if (statusCode.isOK()) {
          this.storeToken(response.headers);
          responseHandler.onSuccess();
        } else {
          unauthorizedHandler();
          this.removeToken();
        }

        if (statusCode.isServiceUnavailable()) {
          response.json().then((json) => responseHandler.onMaintenance(json));
        } else if (statusCode.isServerError()) {
          responseHandler.onError();
        }
      })
      .catch(this.errorHandler);
  }

  storeToken(headers) {
    const token = JwtToken.ofHeaders(headers);
    new JwtTokenRepository().store(token);
  }

  removeToken() {
    new JwtTokenRepository().remove();
  }

  errorHandler(e) {
    console.error(e);
  }

  static post(body = {}) {
    const headers = { "Content-Type": "application/json" };
    return new AuthenticationRequest("post", headers, JSON.stringify(body));
  }
}

export default AuthenticationRequest;
