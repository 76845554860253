import React, { Fragment } from "react";
import PropTypes from "prop-types";

const NyuyokuTable = ({ nyuyoku, afterTable }) => {
  return (
    <Fragment>
      <div className="c-blue-heading">
        <div className="c-blue-heading__text">入浴</div>
      </div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>浴室の数</th>
              <td>{nyuyoku.yokushitsunokazu}</td>
            </tr>
            <tr>
              <th>浴槽タイプ</th>
              <td>{nyuyoku.yokusoType.map((item) => item.label).join("、")}</td>
            </tr>
            <tr>
              <th>同性介助</th>
              <td>{nyuyoku.doseikaijo}</td>
            </tr>
            <tr>
              <th>その他設備等</th>
              <td>{nyuyoku.sonotasetsubi}</td>
            </tr>
          </tbody>
        </table>

        {afterTable}
      </div>
    </Fragment>
  );
};

NyuyokuTable.propTypes = {
  nyuyoku: PropTypes.object.isRequired,
  afterTable: PropTypes.node,
};

export default NyuyokuTable;
