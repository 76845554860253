import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";

const CancelBlock = ({ cancel }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="キャンセルの取り扱い" />
      <div className="print-body-text_area">{cancel.cancelToriatsukai}</div>
    </Fragment>
  );
};

CancelBlock.propTypes = {
  cancel: PropTypes.object.isRequired,
};

export default CancelBlock;
