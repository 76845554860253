const ActionType = {
  RESET: "SERVICE_CONTENT_RESET",
  REQUEST: "SERVICE_CONTENT_REQUEST",
  REQUEST_FINISHED: "SERVICE_CONTENT_REQUEST_FINISHED",
  APPEND_COMMENT_TO_VISIBLE_SURFACE:
    "SERVICE_CONTENT_APPEND_COMMENT_TO_VISIBLE_SURFACE",
  MODIFY_COMMENT_ON_VISIBLE_SURFACE:
    "SERVICE_CONTENT_MODIFY_COMMENT_ON_VISIBLE_SURFACE",
  REMOVE_COMMENT_FROM_VISIBLE_SURFACE:
    "SERVICE_CONTENT_REMOVE_COMMENT_FROM_VISIBLE_SURFACE",
};

export default ActionType;
