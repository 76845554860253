import React, { Fragment } from "react";
import PropTypes from "prop-types";
import PhotosBlock from "./general/PhotosBlock";
import {
  goToJigyoshoAttributeFormButton,
  gotoPhotoFormButton,
} from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";
import EigyoJikanTable from "../content/kyotakukaigoshienjigyosho/EigyoJikanTable";
import ServiceTable from "../content/kyotakukaigoshienjigyosho/ServiceTable";
import StaffSummaryTable from "../content/kyotakukaigoshienjigyosho/StaffSummaryTable";
import RyokinkasanTable from "../content/kyotakukaigoshienjigyosho/RyokinkasanTable";
import JigyoshoJohoTable from "../content/kyotakukaigoshienjigyosho/JigyoshoJohoTable";
import { formatDenwaUketsukeJikan } from "../../search/view-card/KyotakukaigoshienjigyoshoCard";

class KyotakukaigoshienjigyoshoAbstract extends React.Component {
  render() {
    return (
      <div>
        <div className="p-office-body">
          {this.renderHead()}

          {this.renderCatchPhrase()}

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">{this.renderPhotos()}</div>
            <div className="p-office-body-sep__right">
              {this.renderRinenTokucho()}
            </div>
          </div>

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">
              {this.renderEigyojikan()}
            </div>
            <div className="p-office-body-sep__right">
              {this.renderServiceTeikyoChiikiHohoShudan()}
            </div>
          </div>

          {this.renderStaff()}

          {this.renderRyokin()}

          {this.renderJigyoshoJoho()}
        </div>
      </div>
    );
  }

  renderHead() {
    const { contents } = this.props;
    const {
      kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho,
      kyotakukaigoshienjigyosho,
    } = contents.blocks;

    return (
      <div className="c-dl u-border-bottom-b u-margin-b32">
        <dl className="u-margin-b8">
          <dt>住所</dt>
          <dd>{kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.jusho}</dd>
          <dt>サービス提供対象</dt>
          <dd>{kyotakukaigoshienjigyosho.serviceTeikyoTaisho.join("、")}</dd>
          <dt>営業日</dt>
          <dd>
            {kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.eigyobi.join("、")}
          </dd>
          <dt>電話受付時間</dt>
          <dd>
            {formatDenwaUketsukeJikan(
              kyotakukaigoshienjigyosho.telUketsuke,
              kyotakukaigoshienjigyosho.renrakutaiokanoJikantai
            )}
          </dd>
          <dt>サービス提供地域</dt>
          <dd>{kyotakukaigoshienjigyosho.serviceTeikyoChiiki}</dd>
          <dt>特徴</dt>
          <dd>{kyotakukaigoshienjigyosho.tokucho.join("、")}</dd>
          <dt>特記事項</dt>
          <dd>{kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.tokkijiko}</dd>
        </dl>

        <div className="p-office-body-partition__right-text">
          最新更新日：{contents.userUpdatedAt}
          <br />
          印刷回数：{contents.printCount}
          <br />
        </div>
      </div>
    );
  }

  renderCatchPhrase() {
    const {
      kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho,
    } = this.props.contents.blocks;

    return (
      <div className="p-office-body-catch">
        {kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.catchPhrase}
      </div>
    );
  }

  renderPhotos() {
    const { contents } = this.props;
    const photos = contents.blocks.photos;

    return (
      <Fragment>
        <PhotosBlock photos={photos.list} />

        {gotoPhotoFormButton(this.props.contents)}
      </Fragment>
    );
  }

  renderRinenTokucho() {
    const {
      kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho,
    } = this.props.contents.blocks;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">理念・特徴</div>
        </div>

        <div className="p-office-body-description">
          {kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.tokuchoRinen}
          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.jigyoshoJoho
          )}
        </div>
      </Fragment>
    );
  }

  renderEigyojikan() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">営業時間</div>
        </div>
        <EigyoJikanTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderServiceTeikyoChiikiHohoShudan() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">訪問・電話対応</div>
        </div>
        <ServiceTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderStaff() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">スタッフ</div>
        </div>
        <StaffSummaryTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderRyokin() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">料金加算</div>
        </div>
        <RyokinkasanTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderJigyoshoJoho() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">事業所情報</div>
        </div>
        <JigyoshoJohoTable contents={this.props.contents} />
      </Fragment>
    );
  }
}

KyotakukaigoshienjigyoshoAbstract.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default KyotakukaigoshienjigyoshoAbstract;
