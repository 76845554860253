import { StyleSheet } from "aphrodite";
import ColorCatalog from "../../../../app/ui/color/ColorCatalog";

const styles = StyleSheet.create({
  button: {
    backgroundColor: ColorCatalog.white,
    borderRadius: 2,
    boxShadow: `1px 1px 2px ${ColorCatalog.shadow}`,
    display: "table",
    height: 60,
    margin: "5px auto",
    transition: "box-shadow 300ms 0s ease",
    width: "calc(100% - 10px)",

    ":hover": {
      boxShadow: `3px 3px 6px ${ColorCatalog.shadow}`,
      cursor: "pointer",
    },
  },

  signal: {
    fontSize: "medium",
  },
  green: {
    color: ColorCatalog.input_status_green,
  },
  red: {
    color: ColorCatalog.input_status_red,
  },
  yellow: {
    color: ColorCatalog.input_status_yellow,
  },

  inner: {
    display: "table-row",
    textAlign: "center",
    verticalAlign: "middle",
  },

  label: {
    fontSize: "small",
    padding: "0 10px",
    display: "table-cell",
    textAlign: "center",
    verticalAlign: "middle",
  },
});

export default styles;
