import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../state/information/actions";
import Pathname from "../../app/naming/Pathname";
import For from "../../app/ui/layout/For";
import Mount from "../../app/ui/layout/Mount";

class WebFrontInformation extends React.Component {
  constructor(props) {
    super(props);
    // 「タイトル」クリックイベント
    this.handleInformationMessageDetailClick = this.handleInformationMessageDetailClick.bind(
      this
    );
    // 「さらに表示」クリックイベント
    this.handleInformationMessageListClick = this.handleInformationMessageListClick.bind(
      this
    );
  }

  componentDidMount() {
    const { actions } = this.props;
    // お知らせ一覧の取得
    actions.requestList();
  }

  // 「タイトル」クリックイベント
  handleInformationMessageDetailClick(id) {
    const { actions } = this.props;
    actions.gotoInformationMessageDetailPage(id);
  }

  // 「さらに表示」クリックイベント
  handleInformationMessageListClick() {
    const { actions } = this.props;
    actions.gotoInformationMessageListPage();
  }

  render() {
    const { informationForm } = this.props;
    const { list, listIsLoading } = informationForm;
    if (listIsLoading) return null;
    if (!list.length) return null;
    const latestList = list.filter((_, index) => index < 1);
    const more = list.length > 1;
    return (
      <Fragment>
        <div className="c-information-balloon">
          <div style={styles.container}>
            <div style={styles.title}>お知らせ</div>
            <For
              in={latestList}
              each={(it) => (
                <ul key={it.id} className="c-information__a" style={styles.ul}>
                  <li style={styles.cell_date}>
                    {it.registDate} {this.renderNewFor(it)}
                  </li>
                  <li style={styles.cell_message}>
                    <a
                      onClick={() =>
                        this.handleInformationMessageDetailClick(it.id)
                      }
                    >
                      {it.title}
                    </a>
                  </li>
                </ul>
              )}
            />
            <Mount if={more}>
              <div className="c-information__a" style={styles.detail}>
                <a onClick={this.handleInformationMessageListClick}>
                  さらに表示する
                </a>
              </div>
            </Mount>
          </div>
        </div>
      </Fragment>
    );
  }

  renderNewFor(it) {
    if (!it.newFlag) return null;
    return <span style={styles.new}>NEW</span>;
  }
}

WebFrontInformation.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  informationForm: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebFrontInformation);

function mapStateToProps(state) {
  return {
    informationForm: state.informationForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoInformationMessageListPage: () =>
        dispatch(
          push({
            pathname: Pathname.webfront_information,
          })
        ),
      gotoInformationMessageDetailPage: (id) =>
        dispatch(
          push({
            pathname: Pathname.webfront_information_id.replace(/:id/, id),
          })
        ),
    },
  };
}

const styles = {
  container: {
    width: "100%",
    position: "relative",
    padding: 10,
  },
  title: {
    fontSize: "medium",
    fontWeight: "bold",
  },
  ul: {
    paddingLeft: 45,
    paddingRight: 40,
  },
  cell_date: {
    marginTop: 10,
  },
  cell_message: {
    lineHeight: "1.2em",
  },
  detail: {
    width: "100%",
    textAlign: "right",
    fontSize: 14,
    marginTop: 5,
  },
  new: {
    color: "red",
  },
};
