import React, { Fragment } from "react";
import PropTypes from "prop-types";

const EigyoJikanTable = ({ eigyoJikan, afterTable }) => {
  return (
    <Fragment>
      <div className="c-blue-heading">
        <div className="c-blue-heading__text">営業時間</div>
      </div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>平日</th>
              <td>{eigyoJikan.heijitsu.join(" 〜 ")}</td>
            </tr>
            <tr>
              <th>土曜</th>
              <td>{eigyoJikan.doyo.join(" 〜 ")}</td>
            </tr>
            <tr>
              <th>日曜</th>
              <td>{eigyoJikan.nichiyo.join(" 〜 ")}</td>
            </tr>
            <tr>
              <th>祝日</th>
              <td>{eigyoJikan.shukujitsu.join(" 〜 ")}</td>
            </tr>
            <tr>
              <th>定休日</th>
              <td>{eigyoJikan.teikyubi}</td>
            </tr>
            <tr>
              <th>備考</th>
              <td>{eigyoJikan.biko}</td>
            </tr>
          </tbody>
        </table>

        {afterTable}
      </div>
    </Fragment>
  );
};

EigyoJikanTable.propTypes = {
  eigyoJikan: PropTypes.object.isRequired,
  afterTable: PropTypes.node,
};

export default EigyoJikanTable;
