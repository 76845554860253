export function stringIsNullOrEmpty(a) {
  if (a === undefined) return true;
  if (a === null) return true;
  if (a === "") return true;
  return false;
}

export function deepCopy(obj, thisIsArray = false) {
  const ret = thisIsArray ? [] : {};
  Object.keys(obj).forEach((key) => {
    if (isObject(obj[key])) {
      ret[key] = deepCopy(obj[key]);
    } else if (isArray(obj[key])) {
      ret[key] = deepCopy(obj[key], true);
    } else {
      ret[key] = obj[key];
    }
  });
  return ret;
}

export function isObject(obj) {
  const type = Object.prototype.toString.call(obj);
  if (type === "[object Object]") return true;
  return false;
}

export function isArray(obj) {
  const type = Object.prototype.toString.call(obj);
  if (type === "[object Array]") return true;
  return false;
}

export function isFunction(obj) {
  const type = Object.prototype.toString.call(obj);
  if (type === "[object Function]") return true;
  return false;
}
