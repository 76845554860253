import { StyleSheet } from "aphrodite";
import ColorCatalog from "../../../../app/ui/color/ColorCatalog";

const styles = StyleSheet.create({
  condition: {
    display: "inline-block",
    width: 300,
  },

  selectableRow: {
    cursor: "pointer",
    ":hover": {
      backgroundColor: ColorCatalog.info,
    },
  },

  selectedRow: {
    backgroundColor: ColorCatalog.primary,
  },

  selectedCell: {
    color: ColorCatalog.primary_contrast,
  },

  jigyoshosuCell: {
    width: 50,
  },

  shortcutCell: {
    width: 40,
  },
});

export default styles;
