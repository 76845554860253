import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import ServiceShubetsuType from "../../../app/domain/jigyosho/ServiceShubetsuType";

const initialState = {
  services: [],
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.REQUEST_FINISHED]: function (state, action) {
    return {
      ...state,
      services: action.payload.services,
    };
  },
  [ActionType.SHARE_FINISHED]: function (state, action) {
    return {
      ...state,
      services: state.services.concat([action.payload.service]),
    };
  },
  [ActionType.UNSHARE_FINISHED]: function (state, action) {
    return {
      ...state,
      services: state.services.filter(
        (s) =>
          (![ServiceShubetsuType.活動_通いの場]
            .map((it) => it.code)
            .includes(s.serviceShubetsuCode) &&
            !(s.id == action.payload.service.id)) ||
          ([ServiceShubetsuType.活動_通いの場]
            .map((it) => it.code)
            .includes(s.serviceShubetsuCode) &&
            !(
              s.id == action.payload.service.id &&
              s.serviceSubId == action.payload.service.serviceSubId
            ))
      ),
    };
  },
});

export default reducers.createReducer(initialState);
