import React from "react";
import PropTypes from "prop-types";
import PrintTable from "../../../../app/ui/print/PrintTable";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const HomonkeiServiceTable = (props) => {
  const { service } = props;
  const { hokennaiService, homonkeiService } = service.blocks;

  return (
    <PrintTable {...props}>
      <PrintTableNode width="105px">
        {hokennaiService.jigyoshomei}
        <div className="c-table-frame__updated-at">
          ({service.userUpdatedAt})
        </div>
      </PrintTableNode>
      <PrintTableNode width="100px">
        {serviceShubetsu(hokennaiService, homonkeiService)}
      </PrintTableNode>
      <PrintTableNode width="150px">{service.jusho}</PrintTableNode>
      <PrintTableNode width="112px">{hokennaiService.tel}</PrintTableNode>
      <PrintTableNode minWidth="15px" center>
        {hokennaiService.eigyobi.filter((it) => it === "土曜").length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode minWidth="15px" center>
        {hokennaiService.eigyobi.filter((it) => it === "日曜").length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode minWidth="15px" center>
        {hokennaiService.eigyobi.filter((it) => it === "祝日").length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode width="15px" center>
        {homonkeiService.jikangaitaio
          .filter((it) => it[0] === "早朝")
          .map((it) => it[1])}
      </PrintTableNode>
      <PrintTableNode width="15px" center>
        {homonkeiService.jikangaitaio
          .filter((it) => it[0] === "夜間")
          .map((it) => it[1])}
      </PrintTableNode>
      <PrintTableNode width="155px" center>
        {serviceTeikyochiiki(homonkeiService)}
      </PrintTableNode>
      <PrintTableNode width="180px">
        {homonkeiService.taiokanoNaShojo.map((it) => (
          <div key={it}>・{it}</div>
        ))}
        <div>{homonkeiService.taiokanoNaShojoEtc ? "　など" : ""}</div>
      </PrintTableNode>
      <PrintTableNode width="135px">
        {homonkeiService.tokucho.map((it) => (
          <div key={it}>・{it}</div>
        ))}
      </PrintTableNode>
    </PrintTable>
  );
};

function serviceShubetsu(hokennaiService, homonkeiService) {
  let serviceShubetsu = `${hokennaiService.serviceShubetsu}`;
  if (homonkeiService.yakantaiogataService) {
    serviceShubetsu = `${serviceShubetsu}\n夜間対応型訪問介護`;
  }

  return serviceShubetsu;
}

function serviceTeikyochiiki(homonkeiService) {
  const LABEL_LIMIT = 65;
  let serviceTeikyochiiki = homonkeiService.serviceTeikyochiiki
    .split("\n")
    .join(" ");
  serviceTeikyochiiki =
    serviceTeikyochiiki && serviceTeikyochiiki.length > LABEL_LIMIT
      ? serviceTeikyochiiki.substr(0, LABEL_LIMIT) + "..."
      : serviceTeikyochiiki;

  return serviceTeikyochiiki;
}

HomonkeiServiceTable.propTypes = {
  onServiceButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default HomonkeiServiceTable;
