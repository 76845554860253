import React from "react";
import PropTypes from "prop-types";
import CommonHeadContents from "./hokengaiService/CommonHeadContents";
import HokengaiServiceTable from "./hokengaiService/HokengaiServiceTable";
import YoyakuShiharaiTable from "./hokengaiService/YoyakuShiharaiTable";
import MainContents from "./katsudoKayoinoba/MainContents";
import { goToJigyoshoAttributeFormButton } from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";

class KatsudoKayoinobaAbstract extends React.Component {
  render() {
    const { contents, serviceSubId } = this.props;
    return (
      <div className="p-office-body">
        <CommonHeadContents contents={contents} />

        <MainContents contents={contents} />

        <div className="p-office-body-sep">
          <div className="p-office-body-sep__left">
            <YoyakuShiharaiTable
              yoyakuShiharai={contents.blocks.hokengaiYoyakuShiharai}
              afterTable={goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.businessProvideOther
              )}
              serviceShubetsuCode={contents.serviceShubetsuCode}
              serviceSubId={serviceSubId}
            />
          </div>
        </div>

        <HokengaiServiceTable
          hokengaiService={contents.blocks.hokengaiService}
          afterTable={goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.business
          )}
          serviceShubetsuCode={contents.serviceShubetsuCode}
        />
      </div>
    );
  }
}

KatsudoKayoinobaAbstract.propTypes = {
  contents: PropTypes.object.isRequired,
  serviceSubId: PropTypes.string.isRequired,
};

export default KatsudoKayoinobaAbstract;
