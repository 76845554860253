import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import ExternalLink from "../../../../../app/ui/link/ExternalLink";

const ServiceNaiyoHokengaiService = ({ contents }) => {
  const {
    homonkeiServiceServiceNaiyoHokengaiService: hokengaiService,
  } = contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>サービス内容</th>
              <td className="c-information__a">
                {hokengaiService.services.map((service, i) => {
                  const separator = i === 0 ? "" : "、";
                  return (
                    <Fragment key={i}>
                      {separator}
                      <ExternalLink url={service.url}>
                        {service.label}
                      </ExternalLink>
                    </Fragment>
                  );
                })}
              </td>
            </tr>
            <tr>
              <th>備考</th>
              <td>{hokengaiService.biko}</td>
            </tr>
          </tbody>
        </table>
      </div>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.homonkeiServiceHokengaiService
      )}
    </Fragment>
  );
};

ServiceNaiyoHokengaiService.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default ServiceNaiyoHokengaiService;
