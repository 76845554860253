import React from "react";
import PropTypes from "prop-types";
import Mount from "../../../app/ui/layout/Mount";

class WebFrontDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.handleSubOkButtonClick = this.handleSubOkButtonClick.bind(this);
    this.handleOkButtonClick = this.handleOkButtonClick.bind(this);
  }

  handleBackgroundClick() {
    const { onClose = () => {} } = this.props;
    onClose();
  }

  handleDialogClick(e) {
    e.stopPropagation();
  }

  handleCancelButtonClick() {
    const { onCancel = () => {} } = this.props;
    onCancel();
  }

  handleSubOkButtonClick() {
    const { onSubOk = () => {} } = this.props;
    onSubOk();
  }

  handleOkButtonClick() {
    const { onOk = () => {} } = this.props;
    onOk();
  }

  render() {
    const { open } = this.props;
    if (!open) return null;
    const {
      title,
      bodyText,
      cancelText,
      subOkText,
      okText,
      headCss,
    } = this.props;
    return (
      <div className="l-modal" onClick={this.handleBackgroundClick}>
        <div
          className={this.modalMiddleClassName()}
          onClick={this.handleDialogClick}
        >
          <div className="c-content-frame">
            <div className={`c-modal-head ${headCss ? headCss : ""}`}>
              {title}
            </div>
            <div className="c-modal-text">{bodyText}</div>
            <div className="c-modal-bt-frame">
              <div className="c-button-modal-cansel">
                <a onClick={this.handleCancelButtonClick}>
                  {cancelText ? cancelText : "キャンセル"}
                </a>
              </div>
              <Mount if={subOkText}>
                <div className="c-button-modal-ok">
                  <a onClick={this.handleSubOkButtonClick}>{subOkText}</a>
                </div>
              </Mount>
              <div className="c-button-modal-ok">
                <a onClick={this.handleOkButtonClick}>
                  {okText ? okText : "OK"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  modalMiddleClassName() {
    const { wide, wait } = this.props;
    return ["l-modal-middle"]
      .concat(wide ? ["l-modal-middle--wide"] : [])
      .concat(wait ? ["hide"] : [])
      .filter((it) => it)
      .join(" ");
  }
}

WebFrontDialog.propTypes = {
  bodyText: PropTypes.node,
  cancelText: PropTypes.string,
  subOkText: PropTypes.string,
  okText: PropTypes.string,
  headCss: PropTypes.string,
  onCancel: PropTypes.func,
  onClose: PropTypes.func,
  onSubOk: PropTypes.func,
  onOk: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  wide: PropTypes.bool,
  wait: PropTypes.bool,
};

export default WebFrontDialog;
