import React from "react";
import PropTypes from "prop-types";
import { mergeStyleToProps } from "../../fundamental/react/props";

const GridCell = (props) => {
  const { grow: flex = 1 } = props;
  return <div {...mergeStyleToProps({ flex }, props)}></div>;
};

GridCell.propTypes = {
  grow: PropTypes.number,
};

export default GridCell;
