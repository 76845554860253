import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import AccountPasswordComponent from "../../account/password/AccountPasswordComponent";

class HojinPasswordPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  initializeFields(props) {
    const { params } = props.match;
    this.id = +params.id;
  }

  handleBackButtonClick() {
    const { actions, hojinList } = this.props;
    actions.gotoListPage(hojinList.search);
  }

  render() {
    return (
      <AccountPasswordComponent
        id={this.id}
        onGoBack={this.handleBackButtonClick}
      />
    );
  }
}

HojinPasswordPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  hojinList: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HojinPasswordPage);

function mapStateToProps(state) {
  return {
    hojinList: state.hojinList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gotoListPage: (search = "") =>
        dispatch(
          push({
            pathname: Pathname.backoffice_hojin,
            search,
          })
        ),
    },
  };
}
