import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import {
  OPTIONS_AVAILABILITY,
  OPTIONS_PRACTICABILITY,
  OPTIONS_TAIO_JOKYO_POSITIVE_DEFAULT,
} from "../../../../../app/ui/form";
import For from "../../../../../app/ui/layout/For";

/**
 * 訪問系サービス介護フォーム.
 */
class HomonkeiServiceKaigoForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        {this.renderTokucho()}
        {this.renderShintaiKaigo()}
        {this.renderSeikatsuEnjo()}
        {this.renderTsuinJokokaijo()}
        {this.renderSonota()}
        {this.renderBiko()}
      </Fragment>
    );
  }

  renderTokucho() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="サービスの特徴・強み">
        <HorizontalLabelLayout labelText="">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderShintaiKaigo() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="身体介護">
        <HorizontalLabelLayout labelText="20分未満の身体介助の実施" recommended>
          <OptionsRadio
            name="shintaikaijo"
            split={6}
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <For
          in={masterData.homonkeiService_shintaiKaigo}
          each={(option) => (
            <HorizontalLabelLayout
              key={`shintaikaigo.${option.code}`}
              labelText={option.label}
              recommended
            >
              <OptionsRadio
                name={`shintaikaigo.${option.code}`}
                split={6}
                options={OPTIONS_TAIO_JOKYO_POSITIVE_DEFAULT}
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          )}
        />
      </FormGrouping>
    );
  }

  renderSeikatsuEnjo() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="生活援助">
        <For
          in={masterData.homonkeiService_seikatsuEnjo}
          each={(option) => (
            <HorizontalLabelLayout
              key={`seikatsuenjo.${option.code}`}
              labelText={option.label}
              recommended
            >
              <OptionsRadio
                name={`seikatsuenjo.${option.code}`}
                split={6}
                options={OPTIONS_TAIO_JOKYO_POSITIVE_DEFAULT}
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          )}
        />
      </FormGrouping>
    );
  }

  renderTsuinJokokaijo() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="通院等乗降介助">
        {[
          {
            name: "tsuinJokokaijo",
            label: "",
            options: OPTIONS_PRACTICABILITY,
          },
        ].map((item) => (
          <HorizontalLabelLayout
            key={item.name}
            labelText={item.label}
            recommended
          >
            <OptionsRadio
              name={item.name}
              split={6}
              options={item.options}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
        ))}
      </FormGrouping>
    );
  }

  renderSonota() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="その他">
        {[
          {
            name: "kinkyujiHomonkaigo",
            label: "緊急時訪問介護",
            options: OPTIONS_AVAILABILITY,
          },
          {
            name: "tokuteiiryokoi",
            label: "介護職による特定医療行為の実施",
            options: OPTIONS_AVAILABILITY,
          },
        ].map((item) => (
          <HorizontalLabelLayout
            key={item.name}
            labelText={item.label}
            recommended
          >
            <OptionsRadio
              name={item.name}
              split={6}
              options={item.options}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
        ))}
      </FormGrouping>
    );
  }

  renderBiko() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="備考">
        <HorizontalLabelLayout labelText="">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HomonkeiServiceKaigoForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceKaigoForm
);
