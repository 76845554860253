import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CommentRemoveButton from "./CommentRemoveButton";
import Mount from "../../../../app/ui/layout/Mount";
import CommentEditForm from "./CommentEditForm";
import CommentDisplay from "./CommentDisplay";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";

class CommentComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleModifyButtonClick = this.handleModifyButtonClick.bind(this);
    this.handleModifyCancelButtonClick = this.handleModifyCancelButtonClick.bind(
      this
    );
    this.handleFormSubmitSuccess = this.handleFormSubmitSuccess.bind(this);
  }

  handleModifyButtonClick(id) {
    this.props.onEditButtonClick(id);
    this.setState({ isEditting: true });
  }

  handleModifyCancelButtonClick() {
    this.setState({ isEditting: false });
  }

  handleFormSubmitSuccess(dummyComment) {
    this.handleModifyCancelButtonClick();
    this.props.onFormSubmitSuccess(dummyComment);
  }

  state = {
    isEditting: false,
  };

  render() {
    const { authentication, comment, editTargetId } = this.props;
    const { isEditting } = this.state;
    const thisIsEditting = isEditting && editTargetId === comment.id;
    const canModify = comment.canModify;
    const canRemove = authentication.user.canRemoveComment();
    return (
      <div className="u-margin-b32">
        <Mount if={thisIsEditting} then={() => this.renderForm(comment)} />
        {this.renderComment(comment)}
        <Mount if={canModify || canRemove}>
          {this.renderOperations(comment, canModify, canModify || canRemove)}
        </Mount>
      </div>
    );
  }

  renderForm(comment) {
    const props = {
      comment,
      onCancel: this.handleModifyCancelButtonClick,
      onFormSubmitSuccess: this.handleFormSubmitSuccess,
    };
    return <CommentEditForm {...props} />;
  }

  renderComment(comment) {
    const props = { comment };
    return <CommentDisplay {...props} />;
  }

  renderOperations({ id }, canModify, canRemove) {
    const { onRemoveSuccess } = this.props;
    const removeButtonProps = {
      id,
      onSuccess: onRemoveSuccess,
    };
    return (
      <div>
        <Mount if={canModify}>
          <SpaceOut>
            <FlatPrimaryButton onClick={() => this.handleModifyButtonClick(id)}>
              修正する
            </FlatPrimaryButton>
          </SpaceOut>
        </Mount>
        <Mount if={canRemove}>
          <SpaceOut>
            <CommentRemoveButton {...removeButtonProps} />
          </SpaceOut>
        </Mount>
      </div>
    );
  }
}

CommentComponent.propTypes = {
  authentication: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
  editTargetId: PropTypes.number,
  // on-event handler
  onEditButtonClick: PropTypes.func.isRequired,
  onFormSubmitSuccess: PropTypes.func.isRequired,
  onRemoveSuccess: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, null)(CommentComponent);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}
