import { StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
  container: {
    position: "relative",
    width: "calc(100% - 34px)",
    ":after": {
      position: "absolute",
      top: 22,
      right: -20,
      content: "'，'",
    },
  },
});

export default styles;
