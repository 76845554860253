import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../state/service-search/actions";
import SearchConditionMenu from "./SearchConditionMenu";
import SearchResult from "./SearchResult";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_SERVICE,
} from "../../../state/master-data";
import Parametername from "../../../app/naming/Parametername";
import ViewType from "./ViewType";

class SearchPage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { actions, location, masterData } = this.props;
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get(Parametername.view) === ViewType.mapView.name) {
      // マップビューの場合、マップが表示された時点でonBoundChangeイベントが発火しそれをもとにリクエストが実行されるため、ここではスルーする。
      // そのかわり、storeの状態を適切にセットしておく必要がある。
      actions.changeCondition(Parametername.view, ViewType.mapView);
      actions.setSearch(searchParams.toString());
    } else {
      actions.requestBySearch(
        searchParams.toString(),
        masterData.serviceShubetsuMenu
      );
    }
  }

  render() {
    return (
      <div className="c-content-frame">
        <div className="l-folder-sep">
          {this.renderSide()}
          <div className="l-folder-sep__search">
            {this.renderMain()}
            {this.renderReprintComment()}
          </div>
        </div>
      </div>
    );
  }

  renderSide() {
    const { masterData, serviceSearch } = this.props;
    const props = {
      serviceSearch: serviceSearch,
      serviceShubetsuMenu: masterData.serviceShubetsuMenu,
    };
    return <SearchConditionMenu {...props} />;
  }

  renderMain() {
    return <SearchResult />;
  }

  renderReprintComment() {
    return (
      <div className="p-office-reprint-comment" style={{ margin: 0 }}>
        ※
        一部の情報は「介護サービス情報公表システム」より転載しています。情報は事業者により随時更新されます。
      </div>
    );
  }
}

SearchPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  location: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceSearch: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SERVICE)(
  connect(mapStateToProps, mapDispatchToProps)(SearchPage)
);

function mapStateToProps(state) {
  return {
    masterData: state.masterData,
    serviceSearch: state.serviceSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
