import React from "react";
import PropTypes from "prop-types";
import { serviceShubetsuCategoryHeadViewTypeValueOf } from "../ServiceShubetsuCategoryHeadViewType";

const MapPrintCards = ({
  services,
  serviceShubetsuCategoryCode,
  isLastOfLine,
}) => {
  const MapPrintCardView = serviceShubetsuCategoryHeadViewTypeValueOf(
    serviceShubetsuCategoryCode
  ).mapPrintCard;

  return (
    <ul className="map-print-items">
      {services.map((service, index) => {
        const lastClass =
          isLastOfLine && isLastOfLine(index)
            ? "map-print-items__item--last"
            : "";
        return (
          <li key={service.id} className={`map-print-items__item ${lastClass}`}>
            <MapPrintCardView service={service} />
          </li>
        );
      })}
    </ul>
  );
};

MapPrintCards.propTypes = {
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  isLastOfLine: PropTypes.func,
};

export default MapPrintCards;
