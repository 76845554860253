import React from "react";
import PropTypes from "prop-types";

const PrintTableNode = (props) => {
  const styles = {
    width: props.width,
    minWidth: props.minWidth ? props.minWidth : props.width,
    height: props.height,
  };
  return (
    <div
      className={`${
        props.flexible
          ? "c-table-frame__flex-link-flexible"
          : "c-table-frame__flex-link-item"
      } ${props.center ? "u-textalign-center" : ""}`}
      style={styles}
    >
      {props.children}
    </div>
  );
};

PrintTableNode.propTypes = {
  children: PropTypes.node,
  center: PropTypes.bool,
  width: PropTypes.string,
  minWidth: PropTypes.string,
  height: PropTypes.string,
  flexible: PropTypes.bool,
};

export default PrintTableNode;
