import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./actions";
import CenteredCircularProgress from "../../app/ui/progress/CenteredCircularProgress";

export function withMasterData(names = []) {
  return (OriginalComponent) =>
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(
      class MasterDataRequiredComponent extends React.Component {
        static propTypes = {
          actions: PropTypes.objectOf(PropTypes.func).isRequired,
          masterData: PropTypes.object.isRequired,
        };

        componentDidMount() {
          const { actions, masterData } = this.props;
          names
            .filter((name) => !masterData[name])
            .forEach((name) => {
              actions.requestData(name);
            });
        }

        render() {
          const { masterData } = this.props;
          if (names.length !== names.filter((name) => masterData[name]).length)
            return <CenteredCircularProgress />;
          return <OriginalComponent {...this.props} />;
        }
      }
    );
  function mapStateToProps(state) {
    return {
      masterData: state.masterData,
    };
  }
  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(actions, dispatch),
    };
  }
}

export function serviceShubetsuFilter(
  serviceShubetsuCategoryCode,
  serviceShubetsuCode
) {
  return (masterData) => {
    return (
      masterData.serviceShubetsuCategoryRelations.includes(
        serviceShubetsuCategoryCode
      ) || masterData.serviceShubetsuRelations.includes(serviceShubetsuCode)
    );
  };
}

export const MASTER_DATA_BUNDLE_FOR_SERVICE = ["serviceShubetsuMenu"];

export const MASTER_DATA_BUNDLE_FOR_JIGYOSHO = [
  "serviceShubetsu",
  "servicePhotoCategory",
];

export const MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO = [
  "choriBashoShubetsu",
  "haisetsuSonota",
  "kaigohoshukasan",
  "kibo",
  "kobetsuKinokunrennaiyo",
  "kunrembasho",
  "nurseCall",
  "nyuyokuSonota",
  "recreationHindo",
  "recreationShubetsu",
  "rehabiliKizai",
  "riyosyaSonota",
  "ryoho",
  "serviceShubetsu",
  "serviceTeikyoJikanKubun",
  "shiharaiHoho",
  "shokujiSonota",
  "shudanKinokunrennaiyo",
  "sogeishaLogo",
  "staffSonota",
  "taiogata",
  "taiokanoNaShojo",
  "tatemonoBihin",
  "tatemonoDesign",
  "tatemonoKaichikuKubun",
  "tatemonoKozo",
  "tatemonoSetsubi",
  "teikyoService",
  "serviceTaishosha",
  "tokkabunya",
  "tsudoHasseisuruRyokinLabel",
  "tsushokeiService_teikyobusu",
  "tsushokeiService_heisetsuService",
];

export const MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE = [
  "homonkeiService_serviceRenkei",
  "homonkeiService_tokkaBunya",
  "homonkeiService_teikyoService",
  "homonkeiService_serviceTaishosha",
  "homonkeiService_jisshiJigyosha",
  "homonkeiService_heisetsuService",
  "homonkeiService_homonShudan",
  "homonkeiService_shintaiKaigo",
  "homonkeiService_seikatsuEnjo",
  "homonkeiService_hokengaiUnit",
  "homonkeiService_hokengaiService",
  "homonkeiService_kango",
  "homonkeiService_nyuyoku",
  "homonkeiService_yokuso",
  "homonkeiService_nyuyokuSonota",
  "homonkeiService_rehabilitationKunren",
  "homonkeiService_taiokanoShojoCategory",
  "homonkeiService_taiokanoShojo",
  "homonkeiService_staffSonota",
  "homonkeiService_bihin",
  "homonkeiService_kaigohoshukasan",
  "homonkeiService_tsudoryokin",
  "homonkeiService_shiharai",
];

export const MASTER_DATA_BUNDLE_FOR_SHORTSTAY = [
  "shortstay_TeikyoService",
  "shortstay_ServiceTaishosha",
  "shortstay_JigyoshoKeitai",
  "shortstay_Jisshijigyosha",
  "shortstay_HeisetsuService",
  "shortstay_SogeishaLogo",
  "shortstay_KobetsuKinokunrennaiyo",
  "shortstay_Ryoho",
  "shortstay_ShudanKinokunrennaiyo",
  "shortstay_RehabiliKizai",
  "shortstay_Kunrembasho",
  "shortstay_RecreationHindo",
  "shortstay_RecreationShubetsu",
  "shortstay_Shokujichoribasho",
  "shortstay_ShokujiSonota",
  "shortstay_Nyuyokuhindo",
  "shortstay_NyuyokuSonota",
  "shortstay_HaisetsuSonota",
  "shortstay_KyoshitsuType",
  "shortstay_KyoshitsuYoshiki",
  "shortstay_KyoshitsyuJyokyoCategory",
  "shortstay_ShitsunaiSetsubi",
  "shortstay_TaiokanoShojo",
  "shortstay_Kaigohoshukasan",
  "shortstay_TsudoHasseisuruRyokin",
  "shortstay_ShiharaiHoho",
  "shortstay_MoshikomiShorui",
  "shortstay_TatemonoKozo",
  "shortstay_TatemonoDesign",
  "shortstay_NurseCall",
  "shortstay_TatemonoSetsubi",
  "shortstay_TatemonoBihin",
  "shortstay_StaffSonota",
  "shortstay_SonotaService",
  "shortstay_RiyoshaSonota",
];

export const MASTER_DATA_BUNDLE_FOR_KYOTAKUKAIGOSHIENJIGYOSHO = [
  "kyotakukaigoshienjigyosho_kaigohoshukasan",
  "kyotakukaigoshienjigyosho_heisetsuService",
];

export const MASTER_DATA_BUNDLE_FOR_HOKENGAI = [
  "deliveryMenuCategory",
  "deliveryMenuKind",
  "deliveryMenuTemperature",
  "taxiKind",
  "taxiMenuKind",
  "taxiOptionKind",
  "mimamoriGyoshuCode",
  "mimamoriTeikyoService",
  "dantaishubetsuCode",
  "applyCode",
  "businessProvideCategoryCode",
  "businessProvideCode",
  "heldFrequencyCode",
  "careLevelCode",
  "eigyobiOptions",
  "otherMenuItemEigyobi",
];

export const MASTER_DATA_BUNDLE_FOR_JOB_OFFER = [
  "jobOffer_jobType",
  "jobOffer_jobContract",
  "jobOffer_commuteOption",
  "jobOffer_applicationQualificationTag",
  "jobOffer_treatment",
];

export const MASTER_DATA_BUNDLE_FOR_COMMENT = ["comment_disclosureRange"];
