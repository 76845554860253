import React from "react";
import PropTypes from "prop-types";

const BackOfficeHead = (props) => {
  return <h1 style={styles.head}>{props.children}</h1>;
};

const styles = {
  head: {
    fontSize: "large",
    marginTop: 20,
  },
};

BackOfficeHead.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BackOfficeHead;
