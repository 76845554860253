import React from "react";
import PropTypes from "prop-types";
import ServiceShubetsuCategoryType from "../../../../app/domain/jigyosho/ServiceShubetsuCategoryType";
import TruncateText from "../../../../app/ui/text/TruncateText";

const HokengaiMapPrintCard = ({ service }) => {
  const hokengaiService = service.blocks.hokengaiService;

  return (
    <div className="map-print-items-item">
      <div className="map-print-items-item__header">
        <div className="map-print-items-item__number">{service.number}</div>
        <div className="map-print-items-item__jigyoshomei">
          {service.jigyoshomei}
        </div>
      </div>
      <dl className="map-print-items-item__attributes">
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">住所</dt>
          <dd className="map-print-items-item__attributes-item-value">
            {service.jusho}
          </dd>
        </div>
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">TEL</dt>
          <dd className="map-print-items-item__attributes-item-value">
            {hokengaiService.denwabango}
          </dd>
        </div>
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">
            {isKayoinoba(service.serviceShubetsuCategoryCode)
              ? "営業・開催日"
              : "営業日"}
          </dt>
          <dd className="map-print-items-item__attributes-item-value">
            {hokengaiService.eigyobi.join("、")}
          </dd>
        </div>

        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">
            サービス提供地域
          </dt>
          <dd className="map-print-items-item__attributes-item-value">
            <TruncateText maxLength={50}>
              {hokengaiService.serviceTeikyochiiki}
            </TruncateText>
          </dd>
        </div>
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">
            キャッチフレーズ
          </dt>
          <dd className="map-print-items-item__attributes-item-value">
            {hokengaiService.catchPhrase}
          </dd>
        </div>
      </dl>
    </div>
  );

  function isKayoinoba(serviceShubetsuCategoryCode) {
    return (
      serviceShubetsuCategoryCode ===
      ServiceShubetsuCategoryType.活動_通いの場.code
    );
  }
};

HokengaiMapPrintCard.propTypes = {
  service: PropTypes.object.isRequired,
};

export default HokengaiMapPrintCard;
