import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ServiceKaishi from "./ServiceKaishi";
import ServiceYoyaku from "./ServiceYoyaku";
import ServiceRyokin from "./ServiceRyokin";
import ServiceGyoseiJoseiHojo from "./ServiceGyoseiJoseiHojo";

class HomeProvideOtherForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderServiceKaishi()}
        {this.renderYoyaku()}
        {this.renderGyoseiJoseiHojo()}
        {this.renderRyokin()}
      </Fragment>
    );
  }

  renderServiceKaishi() {
    const { bindingProps } = this.props;
    return <ServiceKaishi bindingProps={bindingProps} />;
  }

  renderYoyaku() {
    const { bindingProps } = this.props;
    return <ServiceYoyaku bindingProps={bindingProps} />;
  }

  renderGyoseiJoseiHojo() {
    const { bindingProps } = this.props;
    return <ServiceGyoseiJoseiHojo bindingProps={bindingProps} />;
  }

  renderRyokin() {
    const { bindingProps } = this.props;
    return (
      <ServiceRyokin bindingProps={bindingProps} addOns={{ costFree: true }} />
    );
  }
}

HomeProvideOtherForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeProvideOtherForm);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
