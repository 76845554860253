import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

const KaigoHosyuKasanBlock = ({ kaigohoshukasan, serviceShubetsuType }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="介護報酬加算" />
      <div className="p-office-body-table-dotted">
        <Mount
          if={serviceShubetsuType === ServiceShubetsuType.短期入所生活介護}
          then={() => (
            <KaigoHosyuKasanBlock_seikatsukaigo
              kaigohoshukasan={kaigohoshukasan}
            />
          )}
        />
        <Mount
          if={
            serviceShubetsuType === ServiceShubetsuType.短期入所療養介護医療 ||
            serviceShubetsuType === ServiceShubetsuType.短期入所療養介護老健
          }
          then={() => (
            <KaigoHosyuKasanBlock_ryoyokaigoiryo
              kaigohoshukasan={kaigohoshukasan}
            />
          )}
        />
      </div>
    </Fragment>
  );
};

// 短期入所生活介護
const KaigoHosyuKasanBlock_seikatsukaigo = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <table>
      <tbody>
        <tr>
          <th>生活機能向上連携加算</th>
          <td>{kaigohoshu.seikatsukinokojorenkeiKasan}</td>
          <th>療養食加算</th>
          <td>{kaigohoshu.ryoyoshokuKasan}</td>
          <th>看護体制加算</th>
          <td>{kaigohoshu.kangotaiseikasan}</td>
        </tr>
        <tr>
          <th>機能訓練体制加算</th>
          <td>{kaigohoshu.kinokunrentaiseiKasan}</td>
          <th>認知症専門ケア加算</th>
          <td>{kaigohoshu.ninchishosemmonCareKasan}</td>
          <th>医療連携強化加算</th>
          <td>{kaigohoshu.iryorenkeikyokaKasan}</td>
        </tr>
        <tr>
          <th>個別機能訓練加算</th>
          <td>{kaigohoshu.kobetsukinokunrenKasan}</td>
          <th>サービス提供体制強化加算</th>
          <td>{kaigohoshu.serviceTeikyotaiseikyokaKasan}</td>
          <th>在宅中重度受入加算</th>
          <td>{kaigohoshu.zaitakuchujudokeireKasan}</td>
        </tr>
        <tr>
          <th>
            <div>認知症行動</div>
            <div>・心理症状緊急対応加算</div>
          </th>
          <td>{kaigohoshu.ninchishokodoShinrishojokinkyutaioKasan}</td>
          <th>介護職員処遇改善加算</th>
          <td>{kaigohoshu.kaigoshokuinshogukaizenkasan}</td>
          <th>夜勤職員配置加算</th>
          <td>{kaigohoshu.yakinshokuinhaichikasan}</td>
        </tr>
        <tr>
          <th>若年性認知症利用者受入加算</th>
          <td>{kaigohoshu.jakunenseininchishoriyoshaukeireKasan}</td>
          <th></th>
          <td>{""}</td>
          <th>緊急短期入所受入加算</th>
          <td>{kaigohoshu.kinkyutankinyushokeireKasan}</td>
        </tr>
      </tbody>
    </table>
  );
};

// 短期入所療養介護医療(医療・老健)
const KaigoHosyuKasanBlock_ryoyokaigoiryo = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <table>
      <tbody>
        <tr>
          <th>サービス提供体制強化加算</th>
          <td>{kaigohoshu.serviceTeikyotaiseikyokaKasan}</td>
          <th>療養食加算</th>
          <td>{kaigohoshu.ryoyoshokuKasan}</td>
          <th>
            <div>個別リハビリテーション</div>
            <div>実施加算</div>
          </th>
          <td>{kaigohoshu.kobetsuRehabilitationJissiKasan}</td>
        </tr>
        <tr>
          <th>介護職員処遇改善加算</th>
          <td>{kaigohoshu.kaigoshokuinshogukaizenkasan}</td>
          <th>認知症専門ケア加算</th>
          <td>{kaigohoshu.ninchishosemmonCareKasan}</td>
          <th>
            <div>在宅復帰・在宅療養支援</div>
            <div>機能加算</div>
          </th>
          <td>{kaigohoshu.zaitakufukkizaitakuryoyoshienkinoKasan}</td>
        </tr>
        <tr>
          <th>夜勤職員配置加算</th>
          <td>{kaigohoshu.yakinshokuinhaichikasan}</td>
          <th>若年性認知症利用者受入加算</th>
          <td>{kaigohoshu.jakunenseininchishoriyoshaukeireKasan}</td>
          <th>重度療養管理加算</th>
          <td>{kaigohoshu.judoryoyokanrikasan}</td>
        </tr>
        <tr></tr>
        <tr>
          <th>療養体制維持特別加算</th>
          <td>{kaigohoshu.ryoyotaiseiijitokubetsuKasan}</td>
          <th>
            <div>認知症行動</div>
            <div>・心理症状緊急対応加算</div>
          </th>
          <td>{kaigohoshu.ninchishokodoShinrishojokinkyutaioKasan}</td>
          <th>緊急短期入所受入加算</th>
          <td>{kaigohoshu.kinkyutankinyushokeireKasan}</td>
        </tr>
      </tbody>
    </table>
  );
};

KaigoHosyuKasanBlock.propTypes = {
  kaigohoshukasan: PropTypes.object.isRequired,
  serviceShubetsuType: PropTypes.object.isRequired,
};

export default KaigoHosyuKasanBlock;
