import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../../state/information/actions";
import Pathname from "../../../app/naming/Pathname";
import Boundary from "../../../app/ui/layout/Boundary";
import SideMargin from "../../../app/ui/layout/SideMargin";
import FlatPrimaryButton from "../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FormGrouping from "../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../app/ui/form/InputTypeText";
import Textarea from "../../../app/ui/form/Textarea";
import { Paper, TableBody, TableHead, TableRow } from "@material-ui/core";
import Right from "../../../app/ui/layout/Right";
import SpaceOut from "../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../app/ui/button/RaisedPrimaryButton";
import FlatSecondaryButton from "../../../app/ui/button/FlatSecondaryButton";
import BackOfficeHead from "../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../app/ui/structure/BackOfficeNavigation";
import CenteredCircularProgress from "../../../app/ui/progress/CenteredCircularProgress";
import Margin from "../../../app/ui/layout/Margin";
import BackOfficeHelp from "../../../app/ui/structure/BackOfficeHelp";
import BackOfficeHelpItem from "../../../app/ui/structure/BackOfficeHelpItem";
import Dropzone from "../../../app/ui/dropzone/Dropzone";
import FixedTable from "../../../app/ui/table/FixedTable";
import SmallPaddingTableCell from "../../../app/ui/table/SmallPaddingTableCell";
import LinearProgressDialog from "../../../app/ui/progress/LinearProgressDialog";
import { IconButton } from "../../../../node_modules/@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForever";

class NotificationMessageInputDetail extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    // 「戻る・キャンセル」クリックイベント
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    // 「保存」クリックイベント
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    // ドロップ
    this.handleDrop = this.handleDrop.bind(this);
    // アップロードファイル削除
    this.handleInformationItemRemove = this.handleInformationItemRemove.bind(
      this
    );
  }

  state = {
    filesAreUploading: false,
    draggedId: undefined,
    draggedIndex: undefined,
  };

  initializeFields(props) {
    const { params } = props.match;
    this.id = +params.id;
  }

  componentDidMount() {
    const { actions } = this.props;
    // 詳細データ取得
    actions.requestForm(this.id);
  }

  // 「戻る・キャンセル」クリックイベント
  handleBackButtonClick() {
    const { actions } = this.props;
    actions.gotoback();
  }

  // 保存クリックイベント
  handleSaveButtonClick() {
    const { actions, informationForm } = this.props;
    actions.submit(informationForm.form, this.handleBackButtonClick);
  }

  // ドロップイベント
  handleDrop(files) {
    if (!files || !files.length) return;
    // 新しいアイテムFormを追加
    const newBlankItem = { uploadFile: null };
    this.appendPromotionItem(newBlankItem);
    const { actions } = this.props;
    const { changeForm: onChange } = actions;
    this.setState({ filesAreUploading: true });
    actions.uploadFiles([files[0]], (data) => {
      this.setState({ filesAreUploading: false });
      const file = data.contents[0];
      onChange(
        `items.${this.getInformationItems().length - 1}.uploadFile`,
        file
      );
    });
  }

  // アップロードファイル削除
  handleInformationItemRemove(index) {
    this.removeInformationItem(index);
  }

  // ドラッグ操作開始時イベント
  handleDragStart(id) {
    this.setState({
      draggedId: id,
    });
  }

  // ドラッグ操作終了時イベント
  handleDragEnd() {
    this.setState({
      draggedId: undefined,
      draggedIndex: undefined,
    });
  }

  // マウスカーソルが、ドラッグしながら最初に要素上に移動した際のイベント
  handleDragEnter(index) {
    if (!this.state.draggedId) return;
    this.setState({
      draggedIndex: index,
    });
  }

  // ドロップ時のイベント
  handleDragDrop(index) {
    if (!this.state.draggedId) return;
    const { actions } = this.props;
    actions.changeSort("items", this.state.draggedId, index);
  }

  createBindingProps() {
    const { actions, informationForm } = this.props;
    const { form } = informationForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  }

  getInformationItems() {
    const { informationForm } = this.props;
    return [].concat(informationForm.form.getByName("items"));
  }

  appendPromotionItem(form) {
    const { actions } = this.props;
    const { changeForm: onChange } = actions;
    const org = this.getInformationItems();
    onChange("items", org.concat([form]));
  }

  removeInformationItem(index) {
    const { actions } = this.props;
    const { changeForm: onChange } = actions;
    const org = this.getInformationItems();
    onChange(
      "items",
      org.filter((_, i) => i != index)
    );
  }

  render() {
    const { informationForm } = this.props;
    if (informationForm.formIsLoading) return <CenteredCircularProgress />;
    if (
      !informationForm.form.typeIs("blank") &&
      !informationForm.form.typeIs("edit")
    )
      return null;
    const bindingProps = this.createBindingProps();
    const text = informationForm.form.typeIs("blank")
      ? { section: "お知らせ追加", subsection: "新規のお知らせ" }
      : { section: "お知らせ編集", subsection: "既存のお知らせ" };
    return (
      <Boundary>
        <SideMargin>
          <BackOfficeHead>{text.section}</BackOfficeHead>
          {this.renderNavigation()}
          <Paper style={{ padding: "1px 15px 15px 15px" }}>
            <Fragment>
              <FormGrouping title={text.subsection}>
                <HorizontalLabelLayout labelText="見出し" required>
                  <InputTypeText name="title" {...bindingProps} />
                </HorizontalLabelLayout>
                <HorizontalLabelLayout labelText="本文" required>
                  <Textarea
                    characterLimit={1000}
                    name="detail"
                    {...bindingProps}
                  />
                </HorizontalLabelLayout>
              </FormGrouping>
            </Fragment>
            {this.renderDropzone()}
            <BackOfficeHelp>
              <BackOfficeHelpItem text="画像はドラッグ＆ドロップで順番を並び替えることができます。" />
            </BackOfficeHelp>
            {this.renderPhotos()}
            <Right>
              <SpaceOut>
                <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                  キャンセル
                </FlatSecondaryButton>
              </SpaceOut>
              <SpaceOut>
                <RaisedPrimaryButton onClick={this.handleSaveButtonClick}>
                  保存する
                </RaisedPrimaryButton>
              </SpaceOut>
            </Right>
          </Paper>
        </SideMargin>
        <LinearProgressDialog open={this.state.filesAreUploading} />
      </Boundary>
    );
  }

  // 戻る
  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  // ドロップエリア
  renderDropzone() {
    return (
      <Margin top>
        <Dropzone onDrop={this.handleDrop} />
      </Margin>
    );
  }

  // 画像エリア
  renderPhotos() {
    return (
      <Margin top bottom>
        <Paper>
          <FixedTable>
            <TableHead>
              <TableRow>
                <SmallPaddingTableCell>添付画像</SmallPaddingTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <SmallPaddingTableCell>
                  <div style={styles.itemContainer}>
                    {this.getInformationItems().map((item, i) => {
                      return this.renderInformationItem(i);
                    })}
                  </div>
                </SmallPaddingTableCell>
              </TableRow>
            </TableBody>
          </FixedTable>
        </Paper>
      </Margin>
    );
  }

  renderInformationItem(index) {
    const { informationForm } = this.props;
    const item_form_name = `items.${index}`;
    const { draggedIndex } = this.state;
    return (
      <div key={index} style={styles.itemBlock}>
        {(function (onImage) {
          const image_name = `${item_form_name}.uploadFile`;
          const file = informationForm.form.getByName(image_name);
          return file && onImage(file);
        })((file) => (
          <div
            style={draggedIndex == index ? styles.draggedIndex : {}}
            onDragStart={() => this.handleDragStart(file.id)}
            onDragEnd={() => this.handleDragEnd(index)}
            onDragEnter={() => this.handleDragEnter(index)}
            onDrop={() => this.handleDragDrop(index)}
            draggable={true}
          >
            <img src={file.filePath} style={styles.img} />
            <IconButton
              onClick={() => this.handleInformationItemRemove(index)}
              style={styles.deleteIcon}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        ))}
      </div>
    );
  }
}

NotificationMessageInputDetail.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  informationForm: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NotificationMessageInputDetail)
);

function mapStateToProps(state) {
  return {
    informationForm: state.informationForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoback: () =>
        dispatch(
          push({
            pathname: Pathname.backoffice_notification,
          })
        ),
    },
  };
}

const styles = {
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  itemBlock: {
    position: "relative",
    width: "32%",
    padding: "10px 5px",
  },

  img: {
    maxWidth: "85%",
    paddingTop: 15,
  },
  deleteIcon: {
    position: "absolute",
    top: 0,
    right: 5,
  },
  draggedIndex: {
    borderLeft: "4px solid",
  },
};
