import React from "react";
import { Card } from "@material-ui/core";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { bindActionCreators } from "redux";
import { formDataOf } from "../../../../app/fundamental/data/FormData";
import Pathname from "../../../../app/naming/Pathname";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import DummyLabel from "../../../../app/ui/form/DummyLabel";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import Boundary from "../../../../app/ui/layout/Boundary";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import InputTypeText from "../../../../app/ui/form/InputTypeText";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import * as actions from "../../../../state/jigyosho-change-hojin/actions";

class JigyoshoChangeHojinPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.reloadListPage = this.reloadListPage.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.requestEditForm(this.id);
  }

  initializeFields(props) {
    const { params } = props.match;
    this.id = params.id;
  }

  handleBackButtonClick() {
    const { actions, jigyoshoList } = this.props;
    actions.gotoListPage(jigyoshoList.search);
  }

  handleSaveButtonClick() {
    const { actions, jigyoshoChangeHojinForm } = this.props;
    const fieldName = "afterHojinGroupId";
    const postData = formDataOf({
      [fieldName]: jigyoshoChangeHojinForm.form.getByName(fieldName),
    });
    actions.submitToChangeHojin(this.id, postData, this.reloadListPage);
  }

  reloadListPage() {
    const { actions, jigyoshoList } = this.props;
    actions.reloadListPage(jigyoshoList.search);
  }

  createBindingProps() {
    const { actions, jigyoshoChangeHojinForm } = this.props;
    const { form } = jigyoshoChangeHojinForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          {this.renderHead()}
          {this.renderNavigation()}
          {this.renderForm()}
        </SideMargin>
      </Boundary>
    );
  }

  renderHead() {
    return <BackOfficeHead>法人情報付け替え</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderForm() {
    const {
      form,
      formIsLoading,
      formIsSubmitting,
    } = this.props.jigyoshoChangeHojinForm;
    if (formIsLoading) return <CenteredCircularProgress />;
    if (!form.typeIs("edit")) return null;
    const bindingProps = this.createBindingProps();
    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title="現在の事業所情報">
            <HorizontalLabelLayout labelText="事業所名">
              <DummyLabel name="jigyoshomei" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="法人名">
              <DummyLabel name="currentHojimmei" {...bindingProps} />
            </HorizontalLabelLayout>
          </FormGrouping>
          <FormGrouping title="新しい法人情報">
            <HorizontalLabelLayout labelText="グループコード" required>
              <InputTypeText name="afterHojinGroupId" {...bindingProps} />
            </HorizontalLabelLayout>
          </FormGrouping>
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton
                disabled={formIsSubmitting}
                onClick={this.handleSaveButtonClick}
              >
                保存する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }
}

JigyoshoChangeHojinPage.propTypes = {
  jigyoshoChangeHojinForm: PropTypes.object.isRequired,
  jigyoshoList: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JigyoshoChangeHojinPage);

function mapStateToProps(state) {
  return {
    jigyoshoList: state.jigyoshoList,
    jigyoshoChangeHojinForm: state.jigyoshoChangeHojinForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoListPage: (search = "") =>
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho,
            search,
          })
        ),
      reloadListPage: (search = "") => {
        const searchParams = new URLSearchParams(search);
        searchParams.set("timestamp", new Date().getTime());
        search = searchParams.toString();
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho,
            search,
          })
        );
      },
    },
  };
}
