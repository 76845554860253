import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";

const initialState = {
  features: {},
  featuresIsLoading: false,
  featureIsActivating: false,
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.RESET]: function () {
    return initialState;
  },
  [ActionType.REQUEST_FEATURES]: function (state) {
    return {
      ...state,
      featuresIsLoading: true,
    };
  },
  [ActionType.REQUEST_FEATURES_FINISHED]: function (state, action) {
    return {
      ...state,
      featuresIsLoading: false,
      features: action.payload,
    };
  },
  [ActionType.EDIT_FEATURE_ACTIVATION_SUBMIT]: function (state) {
    return {
      ...state,
      featureIsActivating: true,
    };
  },
  [ActionType.EDIT_FEATURE_ACTIVATION_FINISHED]: function (state) {
    return {
      ...state,
      featureIsActivating: false,
    };
  },
  [ActionType.EDIT_FEATURE_ACTIVATION_FAILED]: function (state) {
    return {
      ...state,
      featureIsActivating: false,
    };
  },
  [ActionType.EDIT_FEATURE_PLUS_ACTIVATION_SUBMIT]: function (state) {
    return {
      ...state,
      featureIsActivating: true,
    };
  },
  [ActionType.EDIT_FEATURE_PLUS_ACTIVATION_FINISHED]: function (state) {
    return {
      ...state,
      featureIsActivating: false,
    };
  },
  [ActionType.EDIT_FEATURE_PLUS_ACTIVATION_FAILED]: function (state) {
    return {
      ...state,
      featureIsActivating: false,
    };
  },
});

export default reducers.createReducer(initialState);
