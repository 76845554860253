const ActionType = {
  RESET: "KANRI_JIGYOSHO_CHANGE_HOJIN_RESET",
  REQUEST: "KANRI_JIGYOSHO_CHANGE_HOJIN_REQUEST",
  REQUEST_FINISHED: "KANRI_JIGYOSHO_CHANGE_HOJIN_REQUEST_FINISHED",
  CHANGE_FORM: "KANRI_JIGYOSHO_CHANGE_HOJIN_CHANGE_FORM",
  SUBMIT: "KANRI_JIGYOSHO_CHANGE_HOJIN_SBMIT",
  SUBMIT_FINISHED: "KANRI_JIGYOSHO_CHANGE_HOJIN_SBMIT_FINISHED",
  SUBMIT_FAILED: "KANRI_JIGYOSHO_CHANGE_HOJIN_SBMIT_FAILED",
};

export default ActionType;
