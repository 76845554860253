import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import RatioBlock from "../layout/RatioBlock";
import FlatPrimaryButton from "../button/FlatPrimaryButton";
import CategorizedOptions from "../../util/CategorizedMasterData";
import ColorCatalog from "../color/ColorCatalog";

class OptionsSelectDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleOpenButtonClick = this.handleOpenButtonClick.bind(this);
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
    this.handleOpenButtonClick = this.handleOpenButtonClick.bind(this);
  }

  state = {
    open: false,
  };

  handleOpenButtonClick() {
    this.setState({ open: true });
  }

  handleCloseButtonClick() {
    this.setState({ open: false });
  }

  handleOptionButtonClick(code) {
    const { onSelect, options } = this.props;
    this.setState({ open: false });
    onSelect(options.find((option) => option.code == code));
  }

  render() {
    const { categorize, options, title } = this.props;
    return (
      <Fragment>
        <FlatPrimaryButton onClick={this.handleOpenButtonClick}>
          項目を追加する
        </FlatPrimaryButton>
        <Dialog onClose={this.handleCloseButtonClick} open={this.state.open}>
          <DialogTitle>{title}</DialogTitle>
          <DialogContent>
            {categorize ? this.renderCategories() : this.renderOptions(options)}
          </DialogContent>
        </Dialog>
      </Fragment>
    );
  }

  renderCategories() {
    const { options } = this.props;
    const categories = new CategorizedOptions(options).getCategories();
    return categories.map((category) => (
      <Fragment key={`category:${category.code}`}>
        <div style={styles.category}>▼ {category.label}</div>
        {this.renderOptions(category.options)}
      </Fragment>
    ));
  }

  renderOptions(options) {
    const { split = 3 } = this.props;
    return options.map((option) => (
      <RatioBlock key={`option:${option.code}`} divide={split}>
        <FlatPrimaryButton
          style={styles.button}
          onClick={() => this.handleOptionButtonClick(option.code)}
        >
          {option.label}
        </FlatPrimaryButton>
      </RatioBlock>
    ));
  }
}

const styles = {
  button: {
    width: "100%",
  },
  category: {
    color: ColorCatalog.gray,
    fontSize: "small",
    marginBottom: 6,
    marginTop: 6,
  },
};

OptionsSelectDialog.propTypes = {
  categorize: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  split: PropTypes.number,
  title: PropTypes.string.isRequired,
};

export default OptionsSelectDialog;
