import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";

class CommentDisplay extends React.Component {
  render() {
    const { comment } = this.props;
    return this.renderDisplay(comment);
  }

  renderDisplay({ disclosureRangeType, postedAt, body, posterGroupName }) {
    const disclosureRangeField = ["disclosure-range"]
      .concat(this.canShowDisclosureRange() ? [] : ["hide"])
      .join(" ");
    return (
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <td>
                <div className="poster-group">{posterGroupName}からの投稿</div>
                <div className="posted-at">{postedAt}</div>
                {this.renderBody(body)}
                <div className={disclosureRangeField}>
                  【公開範囲：{disclosureRangeType}】
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderBody(body) {
    return body
      .replace(/\n\n+/g, "\n\n")
      .split("\n\n")
      .map((paragraph, pi) => {
        const paragraphContents = paragraph.split("\n").map((sentence, si) => (
          <Fragment key={si}>
            {sentence}
            <br />
          </Fragment>
        ));
        return <p key={pi}>{paragraphContents}</p>;
      });
  }

  canShowDisclosureRange() {
    const { user } = this.props.authentication;
    const serviceShubetsuCodes = [
      ServiceShubetsuType.地域包括支援センター.code,
      ServiceShubetsuType.社会福祉協議会.code,
    ];
    return serviceShubetsuCodes.includes(user.group.serviceShubetsuCode);
  }
}

CommentDisplay.propTypes = {
  authentication: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, null)(CommentDisplay);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}
