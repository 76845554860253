import React, { Fragment } from "react";
import PropTypes from "prop-types";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";

const ServiceTeikyoChiikiHohoShudan = ({ contents }) => {
  const {
    homonkeiServiceServiceTeikyoChiikiHouhouShudan: serviceTeikyo,
  } = contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>サービス提供地域</th>
              <td>{serviceTeikyo.serviceTeikyoChiiki}</td>
            </tr>
            <tr>
              <th>サービス提供地域備考</th>
              <td>{serviceTeikyo.serviceTeikyoChiikiBiko}</td>
            </tr>
            <tr>
              <th>訪問手段</th>
              <td>{serviceTeikyo.homonShudan.join("、")}</td>
            </tr>
            <tr>
              <th>サービス提供地域外の交通費</th>
              <td>{serviceTeikyo.chiikigaiKotsuhi}</td>
            </tr>
            <tr>
              <th>備考</th>
              <td>{serviceTeikyo.biko}</td>
            </tr>
          </tbody>
        </table>

        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.homonkeiServiceServiceTeikyo
        )}
      </div>
    </Fragment>
  );
};

ServiceTeikyoChiikiHohoShudan.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default ServiceTeikyoChiikiHohoShudan;
