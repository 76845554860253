import React from "react";
import PropTypes from "prop-types";

const BackOfficeNavigation = (props) => {
  return <div style={styles.operation}>{props.children}</div>;
};

const styles = {
  operation: {},
};

BackOfficeNavigation.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BackOfficeNavigation;
