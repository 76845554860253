import React, { Fragment } from "react";
import PropTypes from "prop-types";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import {
  Paper,
  TableBody,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import { css } from "aphrodite";
import styles from "./JigyoshoListComponent.css";
import SmallPaddingTableCell from "../../../../app/ui/table/SmallPaddingTableCell";
import Right from "../../../../app/ui/layout/Right";
import { IconButton } from "@material-ui/core";
import LockIcon from "@material-ui/icons/Lock";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Boundary from "../../../../app/ui/layout/Boundary";
import JigyoshoListOperationDialog from "./JigyoshoListOperationDialog";
import JigyoshoListCondition, {
  JigyoshoListConditionType,
} from "./JigyoshoListCondition";
import JigyoshoListPaginator from "./JigyoshoListPaginator";
import FixedTable from "../../../../app/ui/table/FixedTable";
import { withInputFeedback } from "../../../../app/ui/form";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import Mount from "../../../../app/ui/layout/Mount";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import RaisedSecondaryButton from "../../../../app/ui/button/RaisedSecondaryButton";

export const JigyoshoListComponentType = {
  管理対象: {
    conditionType: JigyoshoListConditionType.管理対象,
    jigyoshomeiWith: {
      toPut: (user) => user.hasBackOfficeHojinMenu(),
      label: "法人名",
      textOf: (jigyosho) => jigyosho.hojimmei,
    },
  },
  代行入力: {
    conditionType: JigyoshoListConditionType.代行入力,
    jigyoshomeiWith: {
      toPut: () => true,
      label: "登録事業所",
      textOf: (jigyosho) => jigyosho.agent,
    },
  },
};

class JigyoshoListComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleItemSelect = this.handleItemSelect.bind(this);
    this.handleItemUnselect = this.handleItemUnselect.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleRemoveButtonClick = this.handleRemoveButtonClick.bind(this);
    this.handleRemoveDialogCloseButtonClick = this.handleRemoveDialogCloseButtonClick.bind(
      this
    );
    this.handleRemoveDialogRevemoButtonClick = this.handleRemoveDialogRevemoButtonClick.bind(
      this
    );
  }

  state = {
    removeDialogIsOpen: false,
    removeTargetId: null,
    isDeleteButton: false,
  };

  componentDidMount() {
    this.props.onDidMount(this.props.jigyoshoList.search);
  }

  handleItemSelect(e, id) {
    e.stopPropagation();
    this.props.onItemSelect(id);
  }

  handleItemUnselect(e) {
    e.stopPropagation();
    this.props.onItemUnselect();
  }

  handleEditButtonClick(e, id) {
    e.stopPropagation();
    this.props.onEditButtonClick(id);
  }

  handleRemoveButtonClick(e, id) {
    e.stopPropagation();
    this.setState({
      removeDialogIsOpen: true,
      removeTargetId: id,
      isDeleteButton: false,
    });
  }

  handleRemoveDialogCloseButtonClick(e) {
    e.stopPropagation();
    this.setState({
      removeDialogIsOpen: false,
      removeTargetId: null,
    });
  }

  handleRemoveDialogRevemoButtonClick(e) {
    e.stopPropagation();
    this.setState({ isDeleteButton: true });
    this.props.onRemoveButtonClick(this.state.removeTargetId);
  }

  listIsLoading() {
    return this.props.jigyoshoList.listIsLoading;
  }

  componentType() {
    const { componentType = JigyoshoListComponentType.管理対象 } = this.props;
    return componentType;
  }

  userCanRemoveJigyosho(jigyosho) {
    const { user } = this.props.authentication;
    const same_group_id = jigyosho.groupId === user.group.id;
    return (
      this.props.onRemoveButtonClick &&
      (user.canRemoveJigyosho() || same_group_id)
    );
  }

  render() {
    const {
      informationRenderer: renderInformation = () => null,
      navigationRenderer: renderNavigation = () => null,
    } = this.props;
    return (
      <Fragment>
        <Boundary>
          <SideMargin>
            {renderInformation()}
            {this.renderHead()}
            {renderNavigation()}
            {this.renderCondition()}
            {this.renderList()}
          </SideMargin>
        </Boundary>
        {this.renderOperationDialog()}
      </Fragment>
    );
  }

  renderHead() {
    return <BackOfficeHead>事業所一覧</BackOfficeHead>;
  }

  renderCondition() {
    if (this.props.omitCondition) return null;
    const { condition, serviceShubetsuOptions } = this.props.jigyoshoList;
    const { user } = this.props.authentication;
    const props = {
      condition,
      serviceShubetsuOptions,
      user,
    };
    return (
      <JigyoshoListCondition
        componentType={this.componentType().conditionType}
        {...props}
      />
    );
  }

  renderList() {
    if (this.listIsLoading()) return <CenteredCircularProgress />;
    return (
      <Fragment>
        {this.renderTable()}
        {this.renderPaginator()}
        {this.renderRemoveDialog()}
      </Fragment>
    );
  }

  renderTable() {
    const { authentication, jigyoshoList } = this.props;
    const { user } = authentication;
    const { list, selectedId } = jigyoshoList;
    const jigyoshomeiWithLabel = this.componentType().jigyoshomeiWith.toPut(
      user
    )
      ? `（${this.componentType().jigyoshomeiWith.label}）`
      : null;
    const jigyoshoIsSelected = (jigyosho) => jigyosho.id === selectedId;
    const conditionTypeAgent =
      JigyoshoListConditionType.代行入力 == this.componentType().conditionType
        ? true
        : false;
    return (
      <Paper>
        <FixedTable>
          <TableHead>
            <TableRow>
              <SmallPaddingTableCell>
                事業所名
                <br />
                {jigyoshomeiWithLabel}
              </SmallPaddingTableCell>
              <Mount
                if={conditionTypeAgent}
                then={() => (
                  <SmallPaddingTableCell>
                    最新更新日時
                    <br />
                    （最新更新事業所）
                  </SmallPaddingTableCell>
                )}
              />
              <SmallPaddingTableCell>サービス種別</SmallPaddingTableCell>
              <SmallPaddingTableCell>市区町村</SmallPaddingTableCell>
              <Mount
                if={!conditionTypeAgent}
                then={() => (
                  <SmallPaddingTableCell>管理者名</SmallPaddingTableCell>
                )}
              />
              <SmallPaddingTableCell
                className={css(styles.shortcutCell)}
              ></SmallPaddingTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((jigyosho) => {
              const rowClass = jigyoshoIsSelected(jigyosho)
                ? css(styles.selectableRow, styles.selectedRow)
                : css(styles.selectableRow);
              const cellClass = jigyoshoIsSelected(jigyosho)
                ? css(styles.selectedCell)
                : "";
              const jigyoshomeiWithText = this.componentType().jigyoshomeiWith.toPut(
                user
              )
                ? this.componentType().jigyoshomeiWith.textOf(jigyosho)
                  ? `（${this.componentType().jigyoshomeiWith.textOf(
                      jigyosho
                    )}）`
                  : "（なし）"
                : null;
              const lastUpdateAgentNameText = jigyosho.lastUpdateAgentName
                ? `（${jigyosho.lastUpdateAgentName}）`
                : "";
              return (
                <TableRow
                  className={rowClass}
                  key={jigyosho.id}
                  onClick={(e) => this.handleItemSelect(e, jigyosho.id)}
                >
                  <SmallPaddingTableCell className={cellClass}>
                    {jigyosho.jigyoshomei}
                    <br />
                    {jigyoshomeiWithText}
                  </SmallPaddingTableCell>
                  <Mount
                    if={conditionTypeAgent}
                    then={() => (
                      <SmallPaddingTableCell className={cellClass}>
                        {jigyosho.lastUpdateAgentDate}
                        <br />
                        {lastUpdateAgentNameText}
                      </SmallPaddingTableCell>
                    )}
                  />
                  <SmallPaddingTableCell className={cellClass}>
                    {jigyosho.serviceShubetsu}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell className={cellClass}>
                    {jigyosho.shikuchoson}
                  </SmallPaddingTableCell>
                  <Mount
                    if={!conditionTypeAgent}
                    then={() => (
                      <SmallPaddingTableCell className={cellClass}>
                        {jigyosho.kanrishamei}
                      </SmallPaddingTableCell>
                    )}
                  />
                  <SmallPaddingTableCell>
                    <Right>
                      <Mount if={conditionTypeAgent && jigyosho.privateFlg}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <LockIcon
                            style={{ color: "lightcoral", width: "16px" }}
                          />
                          <span
                            style={{
                              fontWeight: "bold",
                              color: "lightcoral",
                              fontSize: "0.78rem",
                            }}
                          >
                            非公開設定中
                          </span>
                        </div>
                      </Mount>
                      <Mount
                        if={
                          jigyosho.searchableInFrontend ||
                          user.hasBackOfficeHojinMenu()
                        }
                      >
                        <IconButton
                          onClick={(e) =>
                            this.handleEditButtonClick(e, jigyosho.id)
                          }
                        >
                          <EditIcon />
                        </IconButton>
                      </Mount>
                      <Mount
                        if={this.userCanRemoveJigyosho(jigyosho)}
                        then={() => (
                          <IconButton
                            onClick={(e) =>
                              this.handleRemoveButtonClick(e, jigyosho.id)
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      />
                    </Right>
                  </SmallPaddingTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </FixedTable>
      </Paper>
    );
  }

  renderPaginator() {
    const { jigyoshoList } = this.props;
    const paginatorProps = {
      prevSearch: jigyoshoList.prevSearch,
      nextSearch: jigyoshoList.nextSearch,
      pagination: jigyoshoList.pagination,
    };
    return <JigyoshoListPaginator {...paginatorProps} />;
  }

  renderOperationDialog() {
    const { authentication, jigyoshoList } = this.props;
    const props = {
      // state
      search: jigyoshoList.search,
      target: jigyoshoList.list.find(
        (jigyosho) => jigyosho.id === jigyoshoList.selectedId
      ),
      user: authentication.user,
      // action
      onClose: this.handleItemUnselect,
      onEditButtonClick: this.props.onEditButtonClick,
    };
    return <JigyoshoListOperationDialog {...props} />;
  }

  renderRemoveDialog() {
    return (
      <Dialog
        onClose={this.handleRemoveDialogCloseButtonClick}
        open={this.state.removeDialogIsOpen}
      >
        <DialogTitle>代行入力した事業所削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            代行入力した事業所データを削除します、よろしいですか？
          </DialogContentText>
          <DialogContentText>
            事業所データを削除した場合、新規に登録し直すまでこの事業所を検索することができなくなります。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton onClick={this.handleRemoveDialogCloseButtonClick}>
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handleRemoveDialogRevemoButtonClick}
            disabled={this.state.isDeleteButton}
          >
            削除する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }
}

JigyoshoListComponent.propTypes = {
  authentication: PropTypes.object.isRequired,
  componentType: PropTypes.object,
  history: PropTypes.object.isRequired,
  jigyoshoList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  omitCondition: PropTypes.bool,
  // on-event handlers
  onAddButtonClick: PropTypes.func.isRequired,
  onDidMount: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onRemoveButtonClick: PropTypes.func,
  onItemSelect: PropTypes.func.isRequired,
  onItemUnselect: PropTypes.func.isRequired,
  // renderers
  informationRenderer: PropTypes.func.isRequired,
  navigationRenderer: PropTypes.func.isRequired,
};

export default withInputFeedback(JigyoshoListComponent);
