import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import Pathname from "../../app/naming/Pathname";
import Parametername from "../../app/naming/Parametername";

export function withAuthentication(OriginalComponent) {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class AuthenticationRequiredComponent extends React.Component {
      static propTypes = {
        authentication: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
      };

      render() {
        const { authentication } = this.props;
        if (!authentication.userIsAuthenticated) return this.redirect();
        return <OriginalComponent {...this.props} />;
      }

      redirect() {
        const { location } = this.props;
        const searchParams = new URLSearchParams();
        searchParams.set(
          Parametername.from,
          `${location.pathname}${location.search}`
        );
        const to = {
          pathname: Pathname.login,
          search: searchParams.toString(),
        };
        return <Redirect to={to} />;
      }
    }
  );
  function mapStateToProps(state) {
    return {
      authentication: state.authentication,
    };
  }
  function mapDispatchToProps() {
    return {};
  }
}

export function staticPage(OriginalComponent) {
  return class StaticPageComponent extends React.Component {
    static propTypes = {
      location: PropTypes.object,
    };
    componentDidMount() {
      const { hash } = this.props.location;
      if (!hash) window.scrollTo(0, 0);
    }
    render() {
      return <OriginalComponent {...this.props} />;
    }
  };
}
