import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";

const ServiceBlock = ({ service }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="サービス内容" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>配達可能な食事</th>
              <td>{service.haitatsukano}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>容器の種類</th>
              <td>{service.yoki}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>対応可能サービス</th>
              <td>
                {service.taioService &&
                  service.taioService
                    .split("/")
                    .map((it, index) => <div key={index}>・{it}</div>)}
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>
                <div>商品の</div>
                <div>配送・受け取り</div>
              </th>
              <td>{service.haisouketori}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

ServiceBlock.propTypes = {
  service: PropTypes.object.isRequired,
};

export default ServiceBlock;
