import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";

const initialState = {
  group: {},
  list: [],
  selectedId: undefined,
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.SELECT]: function (state, action) {
    return {
      ...state,
      selectedId:
        action.payload.id === state.selectedId ? undefined : action.payload.id,
    };
  },
  [ActionType.CHANGE_CONDITION]: function (state, action) {
    return {
      ...state,
      condition: state.condition.setByName(
        action.payload.name,
        action.payload.value
      ),
    };
  },
  [ActionType.REQUEST_FINISHED]: function (state, action) {
    return {
      ...state,
      group: action.payload.group,
      list: action.payload.list,
      selectedId: undefined,
    };
  },
});

export default reducers.createReducer(initialState);
