const ActionType = {
  RESET: "NEWS_RESET",
  REQUEST_LIST: "NEWS_LIST_REQUEST",
  REQUEST_LIST_FINISHED: "NEWS_LIST_REQUEST_FINISHED",
  REQUEST_DETAILS: "NEWS_REQUEST_DETAILS",
  REQUEST_DETAILS_FINISHED: "NEWS_REQUEST_DETAILS_FINISHED",
  REQUEST_FORM: "NEWS_REQUEST_FORM",
  REQUEST_FORM_FINISHED: "NEWS_REQUEST_FORM_FINISHED",
  CHANGE_SORT: "NEWS_CHANGE_SORT",
  CHANGE_FORM: "NEWS_CHANGE_FORM",
  REMOVE: "NEWS_REMOVE",
  SUBMIT: "NEWS_SBMIT",
  SUBMIT_FINISHED: "NEWS_SBMIT_FINISHED",
  SUBMIT_FAILED: "NEWS_SBMIT_FAILED",
  PICKUP_REQUEST_LIST: "PICKUP_LIST_REQUEST",
  PICKUP_REQUEST_LIST_FINISHED: "PICKUP_LIST_REQUEST_FINISHED",
  PICKUP_REQUEST_FORM: "PICKUP_REQUEST_FORM",
  PICKUP_REQUEST_FORM_FINISHED: "PICKUP_REQUEST_FORM_FINISHED",
  PICKUP_SUBMIT_FINISHED: "PICKUP_SBMIT_FINISHED",
  PICKUP_SUBMIT_FAILED: "PICKUP_SBMIT_FAILED",
  PICKUP_REMOVE: "PICKUP_REMOVE",
  PICKUP_SUBMIT: "PICKUP_SBMIT",
};

export default ActionType;
