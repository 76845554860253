import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import {
  OPTIONS_AVAILABILITY,
  OPTIONS_AVAILABILITY_WITH_NEGOTIABLE,
} from "../../../../../app/ui/form";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import GridRow from "../../../../../app/ui/grid/GridRow";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import GridCell from "../../../../../app/ui/grid/GridCell";
import {
  MASTER_DATA_BUNDLE_FOR_SHORTSTAY,
  withMasterData,
} from "../../../../../state/master-data";

class ShortstayShokujiForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        {this.renderTokucho()}
        {this.renderTeikyoumuRyokin()}
        {this.renderOption()}
      </Fragment>
    );
  }

  renderTokucho() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="特徴">
        <HorizontalLabelLayout labelText="特徴">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderTeikyoumuRyokin() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="提供有無/料金">
        <HorizontalLabelLayout labelText="朝食" required>
          <GridRow>
            <GridCell>
              <SpaceOut block>
                <OptionsRadio
                  name="choshokuShokujiTeikyoType"
                  options={OPTIONS_AVAILABILITY_WITH_NEGOTIABLE}
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
            <GridCell>
              <SpaceOut block>
                <ShortInputWidth>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="choshokuKakaku"
                      {...bindingProps}
                      disabled={!this.isPositive("choshokuShokujiTeikyoType")}
                    />
                  </UnitLayout>
                </ShortInputWidth>
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="昼食" required>
          <GridRow>
            <GridCell>
              <SpaceOut block>
                <OptionsRadio
                  name="chushokuShokujiTeikyoType"
                  options={OPTIONS_AVAILABILITY_WITH_NEGOTIABLE}
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
            <GridCell>
              <SpaceOut block>
                <ShortInputWidth>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="chushokuKakaku"
                      {...bindingProps}
                      disabled={!this.isPositive("chushokuShokujiTeikyoType")}
                    />
                  </UnitLayout>
                </ShortInputWidth>
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="おやつ" required>
          <GridRow>
            <GridCell>
              <SpaceOut block>
                <OptionsRadio
                  name="oyatsuShokujiTeikyoType"
                  options={OPTIONS_AVAILABILITY_WITH_NEGOTIABLE}
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
            <GridCell>
              <SpaceOut block>
                <ShortInputWidth>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="oyatsuKakaku"
                      {...bindingProps}
                      disabled={!this.isPositive("oyatsuShokujiTeikyoType")}
                    />
                  </UnitLayout>
                </ShortInputWidth>
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="夕食" required>
          <GridRow>
            <GridCell>
              <SpaceOut block>
                <OptionsRadio
                  name="yushokuShokujiTeikyoType"
                  options={OPTIONS_AVAILABILITY_WITH_NEGOTIABLE}
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
            <GridCell>
              <SpaceOut block>
                <ShortInputWidth>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="yushokuKakaku"
                      {...bindingProps}
                      disabled={!this.isPositive("yushokuShokujiTeikyoType")}
                    />
                  </UnitLayout>
                </ShortInputWidth>
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderOption() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="オプション">
        <HorizontalLabelLayout labelText="嚥下食対応" required>
          <GridRow>
            <GridCell>
              <SpaceOut block>
                <OptionsRadio
                  name="engeshokutaioShokujiTeikyoType"
                  options={OPTIONS_AVAILABILITY_WITH_NEGOTIABLE}
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
            <GridCell>
              <SpaceOut block>
                <ShortInputWidth>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="engeshokutaioKakaku"
                      {...bindingProps}
                      disabled={
                        !this.isPositive("engeshokutaioShokujiTeikyoType")
                      }
                    />
                  </UnitLayout>
                </ShortInputWidth>
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="制限食対応" required>
          <GridRow>
            <GridCell>
              <SpaceOut block>
                <OptionsRadio
                  name="seigenshokutaioShokujiTeikyoType"
                  options={OPTIONS_AVAILABILITY_WITH_NEGOTIABLE}
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
            <GridCell>
              <SpaceOut block>
                <ShortInputWidth>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="seigenshokutaioKakaku"
                      {...bindingProps}
                      disabled={
                        !this.isPositive("seigenshokutaioShokujiTeikyoType")
                      }
                    />
                  </UnitLayout>
                </ShortInputWidth>
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="栄養士" required>
          <GridRow>
            <GridCell>
              <SpaceOut block>
                <OptionsRadio
                  name="eiyoshi"
                  split={6}
                  options={OPTIONS_AVAILABILITY}
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="調理場所" required>
          <GridRow>
            <GridCell>
              <SpaceOut block>
                <OptionsRadio
                  name="choribashoCode"
                  split={6}
                  options={masterData.shortstay_Shokujichoribasho}
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="その他">
          <OptionsCheckbox
            name="shokujiSonota"
            options={masterData.shortstay_ShokujiSonota}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="optionBiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  isPositive(radioName) {
    const { bindingProps } = this.props;
    if (bindingProps.form.getByName(radioName) === "POSITIVE") {
      return true;
    }
    return false;
  }
}

ShortstayShokujiForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SHORTSTAY)(
  ShortstayShokujiForm
);
