import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import DropzoneLib from "react-dropzone";
import * as fileUploadActions from "../../../../../state/file-upload/actions";
import * as jigyoshoFormActions from "../../../../../state/jigyosho-form/actions";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import { IconButton } from "../../../../../../node_modules/@material-ui/core";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_SHORTSTAY,
} from "../../../../../state/master-data";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Textarea from "../../../../../app/ui/form/Textarea";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import { OPTIONS_AVAILABILITY } from "../../../../../app/ui/form";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import FlatPrimaryButton from "../../../../../app/ui/button/FlatPrimaryButton";
import DeleteIcon from "@material-ui/icons/DeleteForever";

class ShortstayMoshikomiForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleHitsuyoshoruiAdded = this.handleHitsuyoshoruiAdded.bind(this);
    this.handleUpload = this.handleUpload.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  // 項目を追加する。
  handleHitsuyoshoruiAdded() {
    this.appendHitsuyoshorui({
      shubetsuCode: "999",
      checkUmu: true,
      sonota: "",
      uploadFileForm: null,
    });
  }

  // アップロードファイル削除
  handleRemove(name) {
    const { bindingProps } = this.props;
    bindingProps.onChange(name, undefined);
  }

  // Form取得
  getHitsuyoshorui() {
    const { bindingProps } = this.props;
    return [].concat(bindingProps.form.getByName("hitsuyoshorui"));
  }

  // Form追加
  appendHitsuyoshorui(form) {
    const { bindingProps } = this.props;
    const org = this.getHitsuyoshorui();
    bindingProps.onChange("hitsuyoshorui", org.concat([form]));
  }

  // アップロード
  handleUpload(name, files) {
    const { actions, bindingProps } = this.props;
    actions.uploadFiles([files[0]], (data) => {
      const file = data.contents[0];
      bindingProps.onChange(name, file);
    });
  }

  render() {
    const { bindingProps } = this.props;
    return (
      <Fragment>
        <FormGrouping title="申込み情報">
          <HorizontalLabelLayout labelText="申込み期間" recommended>
            <ShortInputWidth>
              <InputTypeText
                name="kikan"
                hintText="例）前月1日から"
                {...bindingProps}
              />
            </ShortInputWidth>
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="緊急時の受け入れ" required>
            <OptionsRadio
              name="kinkyujiUkeire"
              split={6}
              options={OPTIONS_AVAILABILITY}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          {this.renderPromotion()}
          <HorizontalLabelLayout labelText="備考">
            <Textarea name="biko" {...bindingProps} />
          </HorizontalLabelLayout>
        </FormGrouping>
      </Fragment>
    );
  }

  renderPromotion() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping>
        <HorizontalLabelLayout labelText="必要書類" recommended>
          {this.getHitsuyoshorui().length > 0 ? (
            <GridRow>
              <GridCell grow={2}>
                <StaticLabel>必要な書類にチェック</StaticLabel>
              </GridCell>
              <GridCell grow={4}>
                <StaticLabel>テンプレートファイル</StaticLabel>
              </GridCell>
            </GridRow>
          ) : null}
          {this.getHitsuyoshorui().map((shorui, index) => {
            const masterMoshikomiShorui = masterData.shortstay_MoshikomiShorui.find(
              (it) => it.code == shorui.shubetsuCode
            );
            const name = `hitsuyoshorui.${index}`;
            return (
              <GridRow key={index}>
                {/* その他自由入力である場合 */}
                {masterMoshikomiShorui.freeText ? (
                  <GridCell grow={2}>
                    <div style={styles.shoruiCheckbox}>
                      <InputTypeCheckbox
                        name={`${name}.checkUmu`}
                        {...bindingProps}
                      />
                    </div>
                    <div style={styles.shoruiText}>
                      <InputTypeText
                        hintText="その他（自由入力）"
                        name={`${name}.sonota`}
                        {...bindingProps}
                      />
                    </div>
                  </GridCell>
                ) : (
                  <GridCell grow={2}>
                    <InputTypeCheckbox
                      name={`${name}.checkUmu`}
                      labelText={masterMoshikomiShorui.label}
                      {...bindingProps}
                    />
                  </GridCell>
                )}

                {/* アップロードファイルが存在する場合 */}
                {shorui.uploadFileForm != null ? (
                  <GridCell grow={4}>
                    <div style={styles.shoruiFileName}>
                      <StaticLabel style={{ paddingLeft: 10 }}>
                        {shorui.uploadFileForm.originalFilename}
                      </StaticLabel>
                    </div>
                    <div style={styles.shoruiDelete}>
                      <IconButton
                        onClick={() =>
                          this.handleRemove(`${name}.uploadFileForm`)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </div>
                  </GridCell>
                ) : (
                  (function (onImage) {
                    const image_name = `${name}.uploadFileForm`;
                    const file = bindingProps.form.getByName(image_name);
                    return onImage(image_name, file);
                  })((name) => (
                    <GridCell grow={4} style={{ paddingTop: 10 }}>
                      <FlatPrimaryButton disabled={!shorui.checkUmu}>
                        <DropzoneLib
                          style={styles.dropzone}
                          onDrop={(files) => this.handleUpload(name, files)}
                        >
                          アップロード
                        </DropzoneLib>
                      </FlatPrimaryButton>
                    </GridCell>
                  ))
                )}
              </GridRow>
            );
          })}
          <GridCell>
            <FlatPrimaryButton onClick={this.handleHitsuyoshoruiAdded}>
              必要書類を追加する
            </FlatPrimaryButton>
          </GridCell>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

const styles = {
  shoruiCheckbox: {
    width: "10%",
    float: "left",
  },
  shoruiText: {
    width: "90%",
    float: "left",
  },
  shoruiFileName: {
    width: "75%",
    float: "left",
    wordBreak: "break-all",
  },
  shoruiDelete: {
    float: "left",
    paddingTop: 10,
  },
  dropzone: {
    // 空で上書き
  },
};

ShortstayMoshikomiForm.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SHORTSTAY)(
  connect(mapStateToProps, mapDispatchToProps)(ShortstayMoshikomiForm)
);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(jigyoshoFormActions, dispatch),
      uploadFiles: (files, callback) =>
        dispatch(fileUploadActions.uploadFiles(files, callback)),
    },
  };
}
