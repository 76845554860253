import React from "react";
import { mergeStyleToProps } from "../../fundamental/react/props";

const GridRow = (props) => {
  return <div {...mergeStyleToProps(styles.gridRow, props)}></div>;
};

const styles = {
  gridRow: {
    display: "flex",
  },
};

export default GridRow;
