import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import TruncateText from "../../../../../app/ui/text/TruncateText";

const RecreationBlock = ({ recreation }) => {
  const LABEL_LIMIT = 98;
  return (
    <Fragment>
      <HeaderTextBlue text="レクリエーション" />
      <div className="print-body-text_area">
        <TruncateText maxLength={LABEL_LIMIT}>
          {recreation.recreations}
        </TruncateText>
      </div>
    </Fragment>
  );
};

RecreationBlock.propTypes = {
  recreation: PropTypes.object.isRequired,
};

export default RecreationBlock;
