import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";

function reset() {
  return {
    type: ActionType.RESET,
  };
}

export function request(jigyoshoId) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getPhotos(dispatch, jigyoshoId);
  };
}

export function changeForm(name, value, callback = () => {}) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_FORM,
      payload: { name, value },
    });
    setTimeout(callback);
  };
}

export function submitToEditPhoto(jigyoshoId, photoId, photo) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToEditPhoto(dispatch, jigyoshoId, photoId, photo);
  };
}

function getPhotos(dispatch, jigyoshoId) {
  const url = ApiPath.api_job_offer_jigyosho_id_photos.replace(
    /:jigyoshoId/,
    jigyoshoId
  );
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: json,
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function postToEditPhoto(dispatch, jigyoshoId, photoId, form) {
  const url = ApiPath.api_job_offer_jigyosho_id_photos_id
    .replace(/:jigyoshoId/, jigyoshoId)
    .replace(/:photoId/, photoId);
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      "変更しました"
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}
