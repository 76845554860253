import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";

const initialState = {
  dataIsLoading: false,
  id: undefined,
  jigyoshomei: undefined,
  attributes: [],
  flier: undefined,
  photo: undefined,
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.REQUEST]: function (state) {
    return {
      ...state,
      dataIsLoading: true,
    };
  },
  [ActionType.REQUEST_FINISHED]: function (state, action) {
    return {
      ...state,
      dataIsLoading: false,
      id: action.payload.id,
      serviceShubetsuCategoryCode: action.payload.serviceShubetsuCategoryCode,
      serviceShubetsuCode: action.payload.serviceShubetsuCode,
      jigyoshomei: action.payload.jigyoshomei,
      attributes: action.payload.attributes,
      flier: action.payload.flier,
      photo: action.payload.photo,
      editAgents: action.payload.editAgents,
      privateFlg: action.payload.privateFlg,
    };
  },
});

export default reducers.createReducer(initialState);
