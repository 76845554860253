import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";

class MimamoriSuishinJigyoDetail extends React.Component {
  render() {
    return (
      <div>
        <div className="p-office-body">{this.renderMimamoriNaiyo()}</div>
      </div>
    );
  }

  renderMimamoriNaiyo() {
    const { contents } = this.props;
    const { mimamoriSuishinJigyo } = this.props.contents.blocks;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">詳細</div>
        </div>

        <div className="p-office-body-table u-margin-b32">
          <table>
            <tbody>
              <tr>
                <th>見守り対象者</th>
                <td>{mimamoriSuishinJigyo.mimamoriTaishosha}</td>
              </tr>
              <tr>
                <th>見守り提供地域</th>
                <td>{mimamoriSuishinJigyo.mimamoriTeikyoChiiki}</td>
              </tr>
              <tr>
                <th>見守り内容</th>
                <td>
                  {mimamoriSuishinJigyo.teikyoService.map((it) => (
                    <div key={it}>{it}</div>
                  ))}
                </td>
              </tr>
              <tr>
                <th>見守り内容詳細</th>
                <td>{mimamoriSuishinJigyo.mimamoriNaiyoDetail}</td>
              </tr>
              <tr>
                <th>担当地域包括支援センター</th>
                <td>{mimamoriSuishinJigyo.tantoChiikiHokatsuShienCenter}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.mimamoriSuishinJigyoDetail
        )}
      </Fragment>
    );
  }
}

MimamoriSuishinJigyoDetail.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default MimamoriSuishinJigyoDetail;
