import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const EigyoJikanTable = ({ contents, detail }) => {
  const {
    blocks: { homonkeiServiceEigyoJikan: eigyoJikan },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>平日</th>
              <td>{eigyoJikan.heijitsu.join(" 〜 ")}</td>
            </tr>
            <tr>
              <th>土曜</th>
              <td>{eigyoJikan.doyo.join(" 〜 ")}</td>
            </tr>
            <tr>
              <th>日曜</th>
              <td>{eigyoJikan.nichiyo.join(" 〜 ")}</td>
            </tr>
            <tr>
              <th>祝日</th>
              <td>{eigyoJikan.shukujitsu.join(" 〜 ")}</td>
            </tr>
            <Mount if={detail}>
              <tr>
                <th>早朝対応</th>
                <td>{eigyoJikan.sochoTaio}</td>
              </tr>
              <tr>
                <th>夜間対応</th>
                <td>{eigyoJikan.yakanTaio}</td>
              </tr>
            </Mount>
            <tr>
              <th>定休日</th>
              <td>{eigyoJikan.teikyubi}</td>
            </tr>
            <tr>
              <th>備考</th>
              <td>{eigyoJikan.biko}</td>
            </tr>
          </tbody>
        </table>

        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.homonkeiServiceEigyoJikan
        )}
      </div>
    </Fragment>
  );
};

EigyoJikanTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default EigyoJikanTable;
