import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";

/**
 * 訪問系サービス外部連携フォーム.
 */
class HomonkeiServiceGaibuRenkeiForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Fragment>{this.renderHomonkeiServiceGaibuRenkei()}</Fragment>;
  }

  renderHomonkeiServiceGaibuRenkei() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="外部連携">
        <HorizontalLabelLayout labelText="医療機関数">
          <ShortInputWidth>
            <UnitLayout unitText="機関">
              <InputTypeNumber name="iryokikansu" {...bindingProps} />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="医療機関名" recommended>
          <Textarea name="iryokikanmei" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="指示書を受けている医師の人数">
          <ShortInputWidth>
            <UnitLayout unitText="人">
              <InputTypeNumber name="shijishoIshi" {...bindingProps} />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HomonkeiServiceGaibuRenkeiForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceGaibuRenkeiForm
);
