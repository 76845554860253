import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { Card } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Logo from "../../app/ui/logo/Logo";
import styles from "./BackOfficeLayout.css";
import { css } from "aphrodite";
import FlatPrimaryButton from "../../app/ui/button/FlatPrimaryButton";
import BackOfficeNavigation from "./BackOfficeNavigation";
import Pathname from "../../app/naming/Pathname";
import SpaceOut from "../../app/ui/layout/SpaceOut";
import Parametername from "../../app/naming/Parametername";
import { Banner } from "../tools/Banner";

class BackOfficeLayout extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogoClick = this.handleLogoClick.bind(this);
    this.handleGuideLinkClick = this.handleGuideLinkClick.bind(this);
    this.handleWebFrontButtonClick = this.handleWebFrontButtonClick.bind(this);
    this.handleBackOfficeButtonClick = this.handleBackOfficeButtonClick.bind(
      this
    );
    this.handleLogouButtonClick = this.handleLogouButtonClick.bind(this);
    this.handleAgreementPageLinkClick = this.handleAgreementPageLinkClick.bind(
      this
    );
    this.handlePrivacyPolicyPageLinkClick = this.handlePrivacyPolicyPageLinkClick.bind(
      this
    );
    this.handleNotificationMessageInputListClick = this.handleNotificationMessageInputListClick.bind(
      this
    );
    this.hasBackOfficeNotificationMenu = this.hasBackOfficeNotificationMenu.bind(
      this
    );

    this.handleWebFrontButtonMouseOver = this.handleWebFrontButtonMouseOver.bind(
      this
    );
    this.handleWebFrontButtonMouseOut = this.handleWebFrontButtonMouseOut.bind(
      this
    );
    this.handleInquiryLinkClick = this.handleInquiryLinkClick.bind(this);
    this.handleMilmoPlanLinkClick = this.handleMilmoPlanLinkClick.bind(this);
  }

  state = {
    isMouseOver: false,
  };

  handleLogoClick() {
    const { actions } = this.props;
    actions.gotoBackOfficePage();
  }

  handleGuideLinkClick() {
    const { actions } = this.props;
    actions.gotoGuidePage();
  }

  handleWebFrontButtonClick() {
    const { actions } = this.props;
    actions.gotoWebFrontSearchPage();
  }

  handleBackOfficeButtonClick() {
    const { actions } = this.props;
    actions.gotoBackOfficePage();
  }

  handleLogouButtonClick() {
    const { actions } = this.props;
    actions.gotoLogoutPage();
  }

  handleAgreementPageLinkClick() {
    const { actions } = this.props;
    actions.gotoAgreementPage();
  }

  handlePrivacyPolicyPageLinkClick() {
    const { actions } = this.props;
    actions.gotoPrivacyPolicyPage();
  }

  handleNotificationMessageInputListClick() {
    const { actions } = this.props;
    actions.gotoNotificationMessageInputListPage();
  }

  hasBackOfficeNotificationMenu() {
    const { user } = this.props.authentication;
    return user.hasBackOfficeNotificationMenu();
  }

  handleWebFrontButtonMouseOver() {
    this.setState({
      isMouseOver: true,
    });
  }
  handleWebFrontButtonMouseOut() {
    this.setState({
      isMouseOver: false,
    });
  }
  handleInquiryLinkClick() {
    const { actions } = this.props;
    actions.gotoInquiryPage();
  }
  handleMilmoPlanLinkClick() {
    const { actions } = this.props;
    actions.gotoMilmoPlanPage();
  }

  render() {
    // 使い方表示中の場合（pathnameが二つ以上追加されないように）
    const guideBackoffice =
      window.location.pathname == Pathname.guide_backoffice;
    return (
      <div className="backoffice">
        <div className={css(styles.headerAndNavigation)}>
          <Card className={css(styles.header)}>
            <div className={css(styles.header__container)}>
              <div className={css(styles.header__container__left)}>
                <div className={css(styles.logo)}>
                  <a
                    className={css(styles.logoAnchor)}
                    onClick={this.handleLogoClick}
                  >
                    <Logo />
                  </a>
                  <div className="c-toggle">
                    <Button
                      onClick={this.handleWebFrontButtonClick}
                      onMouseOver={this.handleWebFrontButtonMouseOver}
                      onMouseOut={this.handleWebFrontButtonMouseOut}
                      className={`c-toggleButton-off ${
                        this.state.isMouseOver ? "c-toggleButton-off-hover" : ""
                      }`}
                    >
                      閲覧ページ
                    </Button>
                    <Button
                      onClick={this.handleBackOfficeButtonClick}
                      className="c-toggleButton-on"
                    >
                      管理ページ
                    </Button>
                  </div>
                </div>
              </div>
              <div className={css(styles.header__container__right)}>
                <SpaceOut>
                  <FlatPrimaryButton
                    onClick={() =>
                      !guideBackoffice && this.handleGuideLinkClick()
                    }
                  >
                    使い方
                  </FlatPrimaryButton>
                </SpaceOut>
                {this.hasBackOfficeNotificationMenu() ? (
                  <SpaceOut>
                    <FlatPrimaryButton
                      onClick={this.handleNotificationMessageInputListClick}
                    >
                      お知らせ管理
                    </FlatPrimaryButton>
                  </SpaceOut>
                ) : null}
                <SpaceOut>
                  <FlatPrimaryButton onClick={this.handleLogouButtonClick}>
                    ログアウト
                  </FlatPrimaryButton>
                </SpaceOut>
              </div>
            </div>
          </Card>
          <BackOfficeNavigation />
        </div>
        <Banner />

        {this.props.children}
        <div className={css(styles.footer)}>
          <div>
            <span className={css(styles.footer__left__item)}>
              <a onClick={this.handleAgreementPageLinkClick}>利用規約</a>
            </span>
            <span className={css(styles.footer__left__item)}>
              <a onClick={this.handlePrivacyPolicyPageLinkClick}>
                プライバシーポリシー
              </a>
            </span>
            <span className={css(styles.footer__left__item)}>
              <a onClick={this.handleInquiryLinkClick}>お問い合わせ</a>
            </span>
          </div>
          <div>
            <span className={css(styles.footer__milmo__item)}>
              <a onClick={this.handleMilmoPlanLinkClick}>ミルモぷらん</a>
            </span>
          </div>
          <div className={css(styles.footer__center)}>
            <span className={css(styles.footer__center__coworker)}>
              協働開発：地方独立行政法人東京都健康長寿医療センター研究所&emsp;
            </span>
            <span className={css(styles.footer__center__copyrights)}>
              &copy; Welmo, Inc. All rights reserved.
            </span>
          </div>
        </div>
      </div>
    );
  }
}

BackOfficeLayout.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  children: PropTypes.node.isRequired,
  authentication: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(BackOfficeLayout);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  const pathname = window.location.href.substr(
    window.location.protocol.length + "//".length + window.location.host.length
  );
  const searchParams = new URLSearchParams();
  searchParams.set(Parametername.pathname, pathname);
  return {
    actions: {
      gotoBackOfficePage: () => dispatch(push(Pathname.backoffice)),
      gotoGuidePage: () =>
        dispatch(
          push({
            pathname: Pathname.guide_backoffice,
            search: searchParams.toString(),
          })
        ),
      gotoWebFrontSearchPage: () =>
        dispatch(push(Pathname.webfront_search_condition)),
      gotoLogoutPage: () => dispatch(push(Pathname.logout)),
      gotoAgreementPage: () =>
        dispatch(
          push({
            pathname: Pathname.agreement,
            search: searchParams.toString(),
          })
        ),
      gotoPrivacyPolicyPage: () => {
        window.open("https://welmo.co.jp/privacy");
      },
      gotoNotificationMessageInputListPage: () =>
        dispatch(
          push({
            pathname: Pathname.backoffice_notification,
          })
        ),
      gotoInquiryPage: () => {
        window.open("https://forms.gle/rySadKi6MVfRvTUn8");
      },
      gotoMilmoPlanPage: () => {
        window.open("https://milmoplan.welmo.co.jp");
      },
    },
  };
}
