import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import TruncateText from "../../../../../app/ui/text/TruncateText";

const RiyokaishiBlock = ({ riyokaishi }) => {
  const LABEL_LIMIT = 140;
  return (
    <Fragment>
      <HeaderTextBlue text="ご利用開始について" />
      <div className="print-body-text_area">
        <TruncateText maxLength={LABEL_LIMIT}>
          {riyokaishi.riyokaishiFlow}
        </TruncateText>
      </div>
    </Fragment>
  );
};

RiyokaishiBlock.propTypes = {
  riyokaishi: PropTypes.object.isRequired,
};

export default RiyokaishiBlock;
