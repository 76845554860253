const ServiceShubetsuCategoryType = {
  // 保険内サービス
  通所系サービス: {
    activated: true,
    code: "002",
    label: "通所系サービス",
    shortLabel: "通所系",
    imgSrc: {
      active: "/images/service_daycare_blue.svg",
      inactive: "/images/service_daycare_gray.svg",
    },
  },
  訪問系サービス: {
    activated: true,
    code: "001",
    label: "訪問系サービス",
    shortLabel: "訪問系",
    imgSrc: {
      active: "/images/service_visit_blue.svg",
      inactive: "/images/service_visit_gray.svg",
    },
  },
  短期入所系サービス: {
    activated: true,
    code: "003",
    label: "短期入所系サービス",
    shortLabel: "短期入所系",
    imgSrc: {
      active: "/images/service_short_blue.svg",
      inactive: "/images/service_short_gray.svg",
    },
  },

  // 保険外サービス
  活動_通いの場: {
    activated: true,
    code: "550",
    label: "活動・通いの場",
    shortLabel: "活動・通い",
    imgSrc: {
      active: "/images/service_activity_blue.svg",
      inactive: "/images/service_activity_gray.svg",
    },
  },
  移送_介護タクシー: {
    activated: true,
    code: "510",
    label: "移送・介護タクシー",
    shortLabel: "介護タクシー",
    imgSrc: {
      active: "/images/service_taxi_blue.svg",
      inactive: "/images/service_taxi_gray.svg",
    },
  },
  生活支援_自費ヘルプ: {
    activated: true,
    code: "520",
    label: "生活支援(自費ヘルプ)",
    shortLabel: "生活支援",
    imgSrc: {
      active: "/images/service_support_blue.svg",
      inactive: "/images/service_support_gray.svg",
    },
  },
  配食サービス: {
    activated: true,
    code: "530",
    label: "配食サービス",
    shortLabel: "配食",
    imgSrc: {
      active: "/images/service_delivery_blue.svg",
      inactive: "/images/service_delivery_gray.svg",
    },
  },
  訪問理美容: {
    activated: true,
    code: "540",
    label: "訪問理美容",
    shortLabel: "訪問理美容",
    imgSrc: {
      active: "/images/service_barber_blue.svg",
      inactive: "/images/service_barber_gray.svg",
    },
  },
  遺品整理_生前整理: {
    activated: true,
    code: "541",
    label: "遺品整理・生前整理",
    shortLabel: "遺品整理",
    imgSrc: {
      active: "/images/service_memento_blue.svg",
      inactive: "/images/service_memento_gray.svg",
    },
  },
  葬儀_相続: {
    activated: true,
    code: "580",
    label: "葬儀・相続",
    shortLabel: "葬儀",
    imgSrc: {
      active: "/images/service_funeral_blue.svg",
      inactive: "/images/service_funeral_gray.svg",
    },
  },
  見守り_緊急通報: {
    activated: true,
    code: "542",
    label: "見守り・緊急通報",
    shortLabel: "見守り",
    imgSrc: {
      active: "/images/service_watch_blue.svg",
      inactive: "/images/service_watch_gray.svg",
    },
  },
  その他便利サービス: {
    activated: true,
    code: "560",
    label: "その他便利サービス",
    shortLabel: "その他",
    imgSrc: {
      active: "/images/service_others_blue.svg",
      inactive: "/images/service_others_gray.svg",
    },
  },
  見守り推進事業: {
    activated: true,
    code: "570",
    label: "見守り推進事業",
    shortLabel: "見守り事業",
    imgSrc: {
      active: "/images/service_mimamori_blue.svg",
      inactive: "/images/service_mimamori_gray.svg",
    },
  },

  // 居宅介護支援
  居宅介護支援: {
    activated: true,
    code: "910",
    label: "居宅介護支援",
    shortLabel: "居宅介護支援",
    imgSrc: {
      active: "/images/service_kyotaku_blue.svg",
      inactive: "/images/service_kyotaku_gray.svg",
    },
  },

  // 支援組織・推進団体
  支援組織_推進団体: {
    activated: false,
    code: "900",
    label: "支援組織・推進団体",
    shortLabel: "支援組織・推進団体",
    imgSrc: {
      active: "/images/service_others_blue.svg",
      inactive: "/images/service_others_gray.svg",
    },
  },
};

export function serviceShubetsuCategoryTypeValueOf(code) {
  return Object.keys(ServiceShubetsuCategoryType)
    .map((key) => ServiceShubetsuCategoryType[key])
    .find((type) => type.code == code);
}

export function serviceShubetsuCategoryTypeValueOfHokennai() {
  return [
    ServiceShubetsuCategoryType.通所系サービス,
    ServiceShubetsuCategoryType.訪問系サービス,
    ServiceShubetsuCategoryType.短期入所系サービス,
  ];
}

// 閲覧ページトップ（保険外サービス）のサービスの順番はここの順番で表示
export function serviceShubetsuCategoryTypeValueOfHokengai() {
  return [
    ServiceShubetsuCategoryType.活動_通いの場,
    ServiceShubetsuCategoryType.移送_介護タクシー,
    ServiceShubetsuCategoryType.生活支援_自費ヘルプ,
    ServiceShubetsuCategoryType.配食サービス,
    ServiceShubetsuCategoryType.訪問理美容,
    ServiceShubetsuCategoryType.遺品整理_生前整理,
    ServiceShubetsuCategoryType.葬儀_相続,
    ServiceShubetsuCategoryType.見守り_緊急通報,
    ServiceShubetsuCategoryType.その他便利サービス,
    ServiceShubetsuCategoryType.見守り推進事業,
  ];
}

export function serviceShubetsuCategoryTypeValueOfKyotakukaigoshienjigyosho() {
  return [ServiceShubetsuCategoryType.居宅介護支援];
}

export default ServiceShubetsuCategoryType;
