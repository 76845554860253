import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const MoshikomiTable = ({ contents, detail, print }) => {
  const {
    blocks: { shortstayMoshikomi: moshikomi },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>お申込み期間</th>
              <td>{moshikomi.kikan}</td>
            </tr>
            <tr>
              <th>緊急時の受け入れ</th>
              <td>{moshikomi.kinkyujiUkeire}</td>
            </tr>
            <Mount if={detail}>
              <tr>
                <th>備考</th>
                <td>{moshikomi.biko}</td>
              </tr>
            </Mount>
            <tr>
              <th>申し込み必要書類</th>
              <td className="val">
                <ul>
                  {moshikomi.hitsuyoshoruiList.map((item, i) => {
                    return (
                      <li key={i}>
                        <div style={{ width: 240 }}>{item.hitsuyoshorui}</div>
                        <div>
                          <Mount if={!print && item.filePath}>
                            <a
                              onClick={() =>
                                (window.location.href = item.filePath)
                              }
                            >
                              ダウンロード
                            </a>
                          </Mount>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.shortstayMoshikomi
      )}
    </Fragment>
  );
};

MoshikomiTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default MoshikomiTable;
