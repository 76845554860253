const ActionType = {
  RESET: "KANRI_JOB_OFFER_FORM_RESET",
  REQUEST: "KANRI_JOB_OFFER_FORM_REQUEST",
  REQUEST_FINISHED: "KANRI_JOB_OFFER_FORM_REQUEST_FINISHED",
  CHANGE_FORM: "KANRI_JOB_OFFER_FORM_CHANGE_FORM",
  SUBMIT: "KANRI_JOB_OFFER_FORM_SBMIT",
  SUBMIT_FINISHED: "KANRI_JOB_OFFER_FORM_SBMIT_FINISHED",
  SUBMIT_FAILED: "KANRI_JOB_OFFER_FORM_SBMIT_FAILED",
};

export default ActionType;
