import PropTypes from "prop-types";

const ValueWithMoneyUnit = ({ value }) => {
  if (value == null) return "";
  if (value === 0) return "無料";
  return `${value}円`;
};

ValueWithMoneyUnit.propTypes = {
  value: PropTypes.number,
};

export default ValueWithMoneyUnit;
