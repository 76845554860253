import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";

const TantoHokatsuBlock = ({ tantoHokatsu }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="担当地域包括センター" />
      <div className="print-body-text_area print-body-font_bold">
        {tantoHokatsu.chiikihokatsushienCenterMei}
      </div>
    </Fragment>
  );
};

TantoHokatsuBlock.propTypes = {
  tantoHokatsu: PropTypes.object.isRequired,
};

export default TantoHokatsuBlock;
