import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Center from "../layout/Center";
import StaticLabel from "./StaticLabel";
import InputTypeTime from "./InputTypeTime";

class TimePeriod extends React.Component {
  helperText() {
    return this.props.inputFeedback.errors.getByName(name);
  }

  render() {
    const {
      disabled,
      form,
      inputFeedback,
      name,
      onChange,
      separator = "_",
    } = this.props;
    const inputTypeTimeProps = {
      disabled,
      form,
      inputFeedback,
      onChange,
    };
    return (
      <div>
        <div>
          <div style={styles.input}>
            <InputTypeTime
              name={`${name}${separator}start`}
              {...inputTypeTimeProps}
            />
          </div>
          <div style={styles.honya}>
            <Center>
              <StaticLabel>〜</StaticLabel>
            </Center>
          </div>
          <div style={styles.input}>
            <InputTypeTime
              name={`${name}${separator}end`}
              {...inputTypeTimeProps}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  input: {
    display: "inline-block",
    width: "calc(50% - 15px)",
  },
  honya: {
    display: "inline-block",
    width: 30,
  },
};

TimePeriod.propTypes = {
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  inputFeedback: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  separator: PropTypes.string,
};

export default connect(mapStateToProps, mapDispatchToProps)(TimePeriod);

function mapStateToProps(state) {
  return {
    inputFeedback: state.inputFeedback,
  };
}

function mapDispatchToProps() {
  return {};
}
