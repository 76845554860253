import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../state/authentication/actions";

class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmitButtonClick = this.handleSubmitButtonClick.bind(this);
  }

  handleSubmitButtonClick() {
    const { actions, form } = this.props;
    actions.login(form);
  }

  render() {
    return (
      <div>
        {this.renderInput()}
        {this.renderButton()}
      </div>
    );
  }

  renderInput() {
    const { actions, form } = this.props;
    return (
      <div className="p-login-item-frame">
        <div className="p-login-item">
          <div className="p-login-item__title">ユーザー名</div>
          <div className="p-login-item__body">
            <input
              type="text"
              placeholder="あなたのユーザー名を入力して下さい"
              name="username"
              value={form.getByName("username")}
              onChange={(e) =>
                actions.changeLoginForm(e.target.name, e.target.value)
              }
            />
          </div>
        </div>
        <div className="p-login-item">
          <div className="p-login-item__title">パスワード</div>
          <div className="p-login-item__body">
            <input
              type="password"
              placeholder="あなたのパスワードを入力して下さい"
              name="password"
              value={form.getByName("password")}
              onChange={(e) =>
                actions.changeLoginForm(e.target.name, e.target.value)
              }
            />
          </div>
        </div>
      </div>
    );
  }

  renderButton() {
    const { form, loginIsProcessing } = this.props;
    const buttonIsDisabled = form.isNotEnough() || loginIsProcessing;
    return (
      <div className="p-login-button">
        <button
          disabled={buttonIsDisabled}
          onClick={this.handleSubmitButtonClick}
        >
          ログイン
        </button>
      </div>
    );
  }
}

LoginForm.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  form: PropTypes.object.isRequired,
  loginIsProcessing: PropTypes.bool.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
