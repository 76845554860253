import React from "react";
import PropTypes from "prop-types";
import { Card, IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../app/ui/form/Textarea";
import InputTypeText from "../../../../app/ui/form/InputTypeText";
import StandardSelect from "../../../../app/ui/form/StandardSelect";
import OptionsCheckbox from "../../../../app/ui/form/OptionsCheckbox";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import InputTypeDate from "../../../../app/ui/form/InputTypeDate";
import GridCell from "../../../../app/ui/grid/GridCell";
import GridRow from "../../../../app/ui/grid/GridRow";
import Dropzone from "../../../../app/ui/dropzone/Dropzone";
import { uploadFiles } from "../../../../state/file-upload/actions";
import connect from "react-redux/es/connect/connect";

const JobOfferForm = (props) => {
  const {
    type,
    masterData,
    jobOfferForm,
    onChange,
    onCancel,
    onSubmit,
    uploadFiles,
  } = props;

  if (jobOfferForm.formIsLoading) return <CenteredCircularProgress />;
  if (!jobOfferForm.form.typeIs(type)) return null;

  const bindingProps = {
    form: jobOfferForm.form,
    onChange: onChange,
  };
  const disabled = jobOfferForm.form.getByName("jobTypeCode") != "099";
  return (
    <Card>
      <SideMargin top bottom>
        <FormGrouping title="求人情報">
          <HorizontalLabelLayout labelText="職種" required>
            <StandardSelect
              name="jobTypeCode"
              blank
              options={masterData.jobOffer_jobType}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="その他(職種)">
             <InputTypeText name="positionName" disabled={disabled} {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="職種の説明" required>
            <Textarea name="description" {...bindingProps} characterLimit={100} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="雇用形態" required>
            <StandardSelect
              name="jobContractCode"
              blank
              options={masterData.jobOffer_jobContract}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="仕事内容" required>
            <Textarea name="jobDescription" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="応募要件" required>
            <Textarea name="applicationQualification" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="応募要件タグ" required>
            <OptionsCheckbox
              name="applicationQualificationTags"
              options={masterData.jobOffer_applicationQualificationTag}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="歓迎要件" required>
            <Textarea name="preferredRequirements" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="給与" required>
            <Textarea name="salary" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="勤務時間" required>
            <Textarea name="workingHours" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="休日・休暇" required>
            <Textarea name="holiday" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="通勤手段" required>
            <OptionsCheckbox
              name="commuteOptions"
              options={masterData.jobOffer_commuteOption}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="福利厚生" required>
            <OptionsCheckbox
              name="treatments"
              options={masterData.jobOffer_treatment}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="待遇・福利厚生 その他" required>
            <Textarea name="benefits" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="勤務地">
            <Textarea name="workLocation" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="募集背景">
            <Textarea name="recruitmentBackground" {...bindingProps} />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="選考プロセス">
            <Textarea name="selectionProcess" {...bindingProps} />
          </HorizontalLabelLayout>
        </FormGrouping>

        <FormGrouping title="アピールポイント">
          <GridRow>
            <GridCell grow={3}>
              <SpaceOut block>
                <HorizontalLabelLayout labelText="タイトル" required>
                  <InputTypeText name="promotionTitle" {...bindingProps} />
                </HorizontalLabelLayout>
                <HorizontalLabelLayout labelText="本文">
                  <Textarea name="promotionDetail" {...bindingProps} />
                </HorizontalLabelLayout>
              </SpaceOut>
            </GridCell>
            <GridCell>
               <SpaceOut block>
                  {renderUploadImage("uploadFileForm")}
               </SpaceOut>
            </GridCell>
          </GridRow>
        </FormGrouping>

        <FormGrouping title="公開設定">
          <HorizontalLabelLayout labelText="掲載期間" required>
            <InputTypeDate
              name="publishStart"
              style={{ width: "150px" }}
              {...bindingProps}
            />
            <span style={{ padding: "0 20px" }}>〜</span>
            <InputTypeDate
              name="publishEnd"
              style={{ width: "150px" }}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
        </FormGrouping>

        <Right>
          <SpaceOut>
            <FlatSecondaryButton onClick={onCancel}>
              キャンセル
            </FlatSecondaryButton>
          </SpaceOut>
          <SpaceOut>
            <RaisedPrimaryButton
              disabled={jobOfferForm.formIsSubmitting}
              onClick={onSubmit}
            >
              下書き保存する
            </RaisedPrimaryButton>
          </SpaceOut>
        </Right>
      </SideMargin>
    </Card>
  );

  function renderUploadImage(name) {
      const file = jobOfferForm.form.getByName(name);
      if (file) {
        return (
          <div style={{ position: "relative" }}>
            <img
              src={file.filePath}
              alt={file.originalFilename}
              title={file.originalFilename}
              style={{ width: "100%" }}
            />
            <IconButton
              onClick={() => onChange(name, null)}
              style={{ position: "absolute", top: 5, right: 5 }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        );
      } else {
        return <Dropzone onDrop={(files) => handleDrop(name, files)} />;
      }
    };

  function handleDrop(name, files) {
      uploadFiles([files[0]], (data) => {
        const file = data.contents[0];
        onChange(name, file);
      });
  }
};

JobOfferForm.propTypes = {
  type: PropTypes.string.isRequired,
  masterData: PropTypes.object.isRequired,
  jobOfferForm: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFiles: (files, callback) => dispatch(uploadFiles(files, callback)),
  };
};

export default connect(null, mapDispatchToProps)(JobOfferForm);
