import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../../../state/jigyosho-list/actions";
import * as jigyoshoFormActions from "../../../../state/jigyosho-form/actions";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import { withInputFeedback } from "../../../../app/ui/form";
import SearchComparator from "../../../../app/util/SearchComparator";
import JigyoshoListComponent, {
  JigyoshoListComponentType,
} from "../../jigyosho/list/JigyoshoListComponent";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import AddIcon from "@material-ui/icons/Add";
import BackOfficeHelp from "../../../../app/ui/structure/BackOfficeHelp";
import BackOfficeHelpItem from "../../../../app/ui/structure/BackOfficeHelpItem";
import Mount from "../../../../app/ui/layout/Mount";

class AgentCollectionPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.renderInformation = this.renderInformation.bind(this);
    this.renderNavigation = this.renderNavigation.bind(this);
  }

  render() {
    const props = {
      authentication: this.props.authentication,
      history: this.props.history,
      jigyoshoList: this.props.jigyoshoList,
      location: this.props.location,
      // on-event handlers
      onAddButtonClick: this.props.onAddButtonClick,
      onDidMount: this.props.onDidMount,
      onEditButtonClick: this.props.onEditButtonClick,
      onRemoveButtonClick: this.props.onRemoveButtonClick,
      onItemSelect: this.props.onItemSelect,
      onItemUnselect: this.props.onItemUnselect,
      // renderers
      informationRenderer: this.renderInformation,
      navigationRenderer: this.renderNavigation,
    };
    return (
      <JigyoshoListComponent
        componentType={JigyoshoListComponentType.代行入力}
        {...props}
      />
    );
  }

  handleAddButtonClick() {
    this.props.onAddButtonClick();
  }

  renderInformation() {
    return (
      <BackOfficeHelp>
        <BackOfficeHelpItem
          text={
            <span>
              代行入力している事業所の一覧を表示しています。
              <br />
              &emsp;&emsp;※同地域内の代行入力情報すべてを編集する事が可能です。
            </span>
          }
        />
      </BackOfficeHelp>
    );
  }

  renderNavigation() {
    const { user } = this.props.authentication;
    return (
      <BackOfficeNavigation>
        <Mount if={user.canCollectJigyosho()}>
          <FlatPrimaryButton onClick={this.handleAddButtonClick}>
            <AddIcon />
            事業所を追加する
          </FlatPrimaryButton>
        </Mount>
      </BackOfficeNavigation>
    );
  }
}

AgentCollectionPage.propTypes = {
  authentication: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  jigyoshoList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // on-event handlers
  onAddButtonClick: PropTypes.func.isRequired,
  onDidMount: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onRemoveButtonClick: PropTypes.func.isRequired,
  onItemSelect: PropTypes.func.isRequired,
  onItemUnselect: PropTypes.func.isRequired,
};

export default withInputFeedback(
  connect(mapStateToProps, mapDispatchToProps)(AgentCollectionPage)
);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    jigyoshoList: state.jigyoshoList,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    onDidMount: (search) => {
      const toUpdate = new SearchComparator(
        props.location.search,
        search
      ).different();
      if (toUpdate)
        dispatch(
          actions.requestAgentCollectionBySearch(
            new URLSearchParams(props.location.search).toString()
          )
        );
      dispatch(actions.select(undefined));
    },
    onItemSelect: (id) => {
      dispatch(
        push(Pathname.backoffice_agent_collection_id_status.replace(/:id/, id))
      );
    },
    onItemUnselect: () => {},
    onAddButtonClick: () => {
      dispatch(
        push({
          pathname: Pathname.backoffice_agent_collection_add,
          search: props.location.search,
        })
      );
    },
    onEditButtonClick: (id) => {
      dispatch(
        push(Pathname.backoffice_agent_collection_id_status.replace(/:id/, id))
      );
    },
    onRemoveButtonClick: (id) => {
      const searchParams = new URLSearchParams(props.location.search);
      searchParams.set("timestamp", new Date().getTime());
      dispatch(
        jigyoshoFormActions.submitToRemoveOpenData(id, () =>
          dispatch(push({ search: searchParams.toString() }))
        )
      );
    },
  };
}
