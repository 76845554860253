import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ColorCatalog from "../color/ColorCatalog";

class DummyError extends React.Component {
  helperText() {
    const { name } = this.props;
    return this.props.inputFeedback.errors.getByName(name);
  }

  render() {
    const helperText = this.helperText();
    if (!helperText) return null;
    return <div style={styles.error}>{helperText}</div>;
  }
}

const styles = {
  error: {
    borderTop: `2px solid ${ColorCatalog.error}`,
    color: ColorCatalog.error,
    fontSize: "0.75rem",
    paddingTop: 4,
  },
};

DummyError.propTypes = {
  inputFeedback: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DummyError);

function mapStateToProps(state) {
  return {
    inputFeedback: state.inputFeedback,
  };
}

function mapDispatchToProps() {
  return {};
}
