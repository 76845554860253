import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../app/naming/Pathname";
import Parametername from "../../app/naming/Parametername";

class WebFrontFooter extends React.Component {
  constructor(props) {
    super(props);
    this.handleAgreementLinkClick = this.handleAgreementLinkClick.bind(this);
    this.handlePrivacyPolicyLinkClick = this.handlePrivacyPolicyLinkClick.bind(
      this
    );
    this.handleInquiryLinkClick = this.handleInquiryLinkClick.bind(this);
    this.handleMilmoPlanLinkClick = this.handleMilmoPlanLinkClick.bind(this);
  }

  handleAgreementLinkClick() {
    this.props.actions.gotoAgreementPage();
  }

  handlePrivacyPolicyLinkClick() {
    this.props.actions.gotoPrivacyPolicyPage();
  }

  handleInquiryLinkClick() {
    this.props.actions.gotoInquiryPage();
  }
  handleMilmoPlanLinkClick() {
    this.props.actions.gotoMilmoPlanPage();
  }

  render() {
    return (
      <div className="c-footer">
        <div className="c-footer-item">
          {this.props.preview ? null : (
            <Fragment>
              <a onClick={this.handleAgreementLinkClick}>利用規約</a>
              <a onClick={this.handlePrivacyPolicyLinkClick}>
                プライバシーポリシー
              </a>
              <a onClick={this.handleInquiryLinkClick}>お問い合わせ</a>
            </Fragment>
          )}
        </div>
        <div className="c-footer-item">
          {this.props.preview ? null : (
            <Fragment>
              <a onClick={this.handleMilmoPlanLinkClick}>ミルモぷらん</a>
            </Fragment>
          )}
        </div>
        <div className="c-footer-item">
          <span className="coworker">
            協働開発：地方独立行政法人東京都健康長寿医療センター研究所
          </span>
          <span className="copyrights">© Welmo, Inc. All rights reserved.</span>
        </div>
      </div>
    );
  }
}

WebFrontFooter.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  preview: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebFrontFooter);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  const pathname = window.location.href.substr(
    window.location.protocol.length + "//".length + window.location.host.length
  );
  const searchParams = new URLSearchParams();
  searchParams.set(Parametername.pathname, pathname);
  return {
    actions: {
      gotoAgreementPage: () =>
        dispatch(
          push({
            pathname: Pathname.agreement,
            search: searchParams.toString(),
          })
        ),
      gotoPrivacyPolicyPage: () => {
        window.open("https://welmo.co.jp/privacy");
      },
      gotoInquiryPage: () => {
        window.open("https://forms.gle/rySadKi6MVfRvTUn8");
      },
      gotoMilmoPlanPage: () => {
        window.open("https://milmoplan.welmo.co.jp");
      },
    },
  };
}
