import React from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const StaffSummaryTable = ({ contents }) => {
  const { kyotakukaigoshienjigyoshoStaff: staff } = contents.blocks;

  return (
    <div className="p-office-body-sep">
      <div className="p-office-body-sep__left">
        <div className="p-office-body-table2">
          <div className="p-office-body-table2__th">職員数</div>
          <div className="p-office-body-table2__td">
            <ul>
              <li>
                <div className="p-office-body-table2__td-name">
                  介護支援専門員
                </div>
                <div className="p-office-body-table2__td-val">
                  {staff.kaigoshienSemmonin}
                </div>
              </li>
              <li>
                <div className="p-office-body-table2__td-name">
                  経験年数5年以上のケアマネジャー
                </div>
                <div className="p-office-body-table2__td-val">
                  {staff.keiken5nenWariai}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="p-office-body-sep__right">
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>管理者保有資格</th>
                <td>{staff.kanrishahoyushikaku}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.kyotakukaigoshienjigyoshoStaff
        )}
      </div>
    </div>
  );
};

StaffSummaryTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default StaffSummaryTable;
