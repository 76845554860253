import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ValueWithCarUnit from "../../../../../app/ui/unit/ValueWithCarUnit";
import ValueWithHeadcountUnit from "../../../../../app/ui/unit/ValueWithHeadcountUnit";

const ServiceBlock = ({ service }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="サービス内容" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>サービス種類</th>
              <td>{service.taxiService}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>提供サービス</th>
              <td>{service.teikyoService}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>保有台数</th>
              <td>
                <ValueWithCarUnit value={service.hoyudaisu} />
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>
                <div>同乗者定員</div>
                <div>（上限）</div>
              </th>
              <td>
                <ValueWithHeadcountUnit value={service.dojoshateiinjogen} />
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>備考</th>
              <td>{service.serviceBiko}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

ServiceBlock.propTypes = {
  service: PropTypes.object.isRequired,
};

export default ServiceBlock;
