import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import Mount from "../../../../../app/ui/layout/Mount";
import ExternalLinkedUrl from "../../../../../app/ui/link/ExternalLinkedUrl";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

const JigyoshoJohoTable = ({ contents, detail, print }) => {
  const {
    serviceShubetsuCode,
    serviceShubetsu,
    blocks: { homonkeiServiceJigyoshoHokennaiJigyoshoJoho: jigyoshoJoho },
  } = contents;
  return (
    <Fragment>
      <div className="p-office-body-table u-margin-b32">
        <table>
          <tbody>
            <Mount if={!print}>
              <tr>
                <th>事業所名</th>
                <td>{jigyoshoJoho.jigyoshomei}</td>
              </tr>
              <tr>
                <th>サービス種別</th>
                <td>{serviceShubetsu}</td>
              </tr>
            </Mount>
            <tr>
              <th>TEL</th>
              <td>{jigyoshoJoho.tel}</td>
            </tr>
            <tr>
              <th>FAX</th>
              <td>{jigyoshoJoho.fax}</td>
            </tr>
            <tr>
              <th>事業所番号</th>
              <td>{jigyoshoJoho.jigyoshobango}</td>
            </tr>
            <tr>
              <th>事業開始日</th>
              <td>{jigyoshoJoho.jigyokaishibi}</td>
            </tr>
            <tr>
              <th>住所</th>
              <td>
                〒{jigyoshoJoho.yubimbango} {jigyoshoJoho.jusho}
              </td>
            </tr>
            <tr>
              <th>運営法人</th>
              <td>{jigyoshoJoho.uneihojin}</td>
            </tr>
            <tr>
              <th>管理者名</th>
              <td>{jigyoshoJoho.kanrishamei}</td>
            </tr>
            <Mount if={detail}>
              <tr>
                <th>ホームページ</th>
                <td>
                  <ExternalLinkedUrl url={jigyoshoJoho.hpAddress} />
                </td>
              </tr>
              <tr>
                <th>提供サービス</th>
                <td>{jigyoshoJoho.teikyoServiceShubetsu.join("、")}</td>
              </tr>
              <Mount
                if={[
                  ServiceShubetsuType.訪問看護,
                  ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
                ]
                  .map((it) => it.code)
                  .includes(serviceShubetsuCode)}
              >
                <tr>
                  <th>実施事業者</th>
                  <td>{jigyoshoJoho.jisshijigyosha.join("、")}</td>
                </tr>
              </Mount>
              <tr>
                <th>併設サービス</th>
                <td>{jigyoshoJoho.heisetsuService.join("、")}</td>
              </tr>
              <tr>
                <th>生活保護受入機関指定</th>
                <td>{jigyoshoJoho.seikatsuhogoUkeire}</td>
              </tr>
              <Mount
                if={[ServiceShubetsuType.定期巡回_随時対応型訪問介護看護]
                  .map((it) => it.code)
                  .includes(serviceShubetsuCode)}
              >
                <tr>
                  <th>一体・連携</th>
                  <td>{jigyoshoJoho.ittaiRenkei}</td>
                </tr>
                <tr>
                  <th>連携している事業所</th>
                  <td>{jigyoshoJoho.renkeiJigyosho}</td>
                </tr>
              </Mount>
              <tr>
                <th>キャッチフレーズ</th>
                <td>{jigyoshoJoho.catchPhrase}</td>
              </tr>
              <tr>
                <th>特徴・理念</th>
                <td>{jigyoshoJoho.tokuchoRinen}</td>
              </tr>
            </Mount>
          </tbody>
        </table>
      </div>

      <Mount
        if={jigyoshoJoho.yakan}
        then={() => {
          return (
            <div className="p-office-body-table u-margin-b32">
              <div className="p-office-body-title">夜間対応型</div>
              <table>
                <tbody>
                  <tr>
                    <th>TEL</th>
                    <td>{jigyoshoJoho.yakan.tel}</td>
                  </tr>
                  <tr>
                    <th>事業所番号</th>
                    <td>{jigyoshoJoho.yakan.jigyoshobango}</td>
                  </tr>
                  <tr>
                    <th>オペレーションセンターの設置</th>
                    <td>{jigyoshoJoho.yakan.operationCenter}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        }}
      />

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.homonkeiServiceJigyosho
      )}
    </Fragment>
  );
};

JigyoshoJohoTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default JigyoshoJohoTable;
