import React from "react";
import PropTypes from "prop-types";
import WebFrontNavlessLayout from "../../page/webfront/WebFrontNavlessLayout";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { HashLink } from "react-router-hash-link"; // スムーススクロール
import smoothscroll from "smoothscroll-polyfill"; // スムーススクロールpolyfill
import Parametername from "../naming/Parametername";
import Pathname from "../naming/Pathname";

class GuidePage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackLinkClick = this.handleBackLinkClick.bind(this);
  }

  componentWillMount() {
    smoothscroll.polyfill();
  }

  scrollWithOffset = (el) => {
    const offset = 100;
    const elementPosition = el.offsetTop - offset;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  initializeFields(props) {
    const { search } = props.location;
    const searchParams = new URLSearchParams(search);
    this.pathname =
      searchParams.get(Parametername.pathname) || Pathname.webfront;
  }

  // 前のページに戻る
  handleBackLinkClick() {
    const { actions } = this.props;
    actions.gotoback(this.pathname);
  }

  render() {
    return (
      <WebFrontNavlessLayout>
        <div className="o-guide-flame">
          <div className="o-guide-side">
            <ul>
              <li>
                <a onClick={this.handleBackLinkClick}>前のページに戻る</a>
              </li>
              <li>
                <HashLink
                  to="#login"
                  scroll={(el) => this.scrollWithOffset(el)}
                >
                  ログインする
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="#search"
                  scroll={(el) => this.scrollWithOffset(el)}
                >
                  保険内・保険外情報を探す
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="#search-on-map"
                  scroll={(el) => this.scrollWithOffset(el)}
                >
                  保険内・保険外情報をマップで探す
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="#region"
                  scroll={(el) => this.scrollWithOffset(el)}
                >
                  地域の選択を行う
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="#detail"
                  scroll={(el) => this.scrollWithOffset(el)}
                >
                  保険内・保険外情報の詳細を見る
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="#print"
                  scroll={(el) => this.scrollWithOffset(el)}
                >
                  保険内・保険外情報を印刷する
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="#comment"
                  scroll={(el) => this.scrollWithOffset(el)}
                >
                  保険内・保険外情報にコメントを記入する（専門職のみ）
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="#dashboard_to_folder"
                  scroll={(el) => this.scrollWithOffset(el)}
                >
                  共有フォルダとは？
                </HashLink>
              </li>
              <li>
                <HashLink to="#add" scroll={(el) => this.scrollWithOffset(el)}>
                  保険内・保険外情報を共有フォルダに追加する
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="#shubetsu"
                  scroll={(el) => this.scrollWithOffset(el)}
                >
                  共有フォルダに登録した情報をサービス種別毎に見る
                </HashLink>
              </li>
              <li>
                <HashLink to="#map" scroll={(el) => this.scrollWithOffset(el)}>
                  共有フォルダに登録した情報をマップで見る
                </HashLink>
              </li>
              <li>
                <HashLink
                  to="#logout"
                  scroll={(el) => this.scrollWithOffset(el)}
                >
                  ログアウトする
                </HashLink>
              </li>
            </ul>
          </div>
          <div className="o-guide-main">
            <div className="o-guide-title">ミルモネット使い方ガイド</div>
            <div className="o-guide-box" id="login">
              <div className="o-guide-box__title">ログインする</div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/login.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      ユーザー名とパスワードを入力し、「ログイン」ボタンをクリックしてログインします。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/kiyaku.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      初めてログインした時のみ、利用規約同意確認ページが表示されます。
                    </li>
                    <li>
                      利用規約をお読みになった上で、ページ下部にある「同意する」ボタンをクリックして次へ進みます。
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="o-guide-box" id="search">
              <div className="o-guide-box__title">保険内・保険外情報を探す</div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/search2.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      検索したいサービスを選択し「この条件で検索」ボタンをクリックします。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/search3.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      さらに詳しい条件で絞り込みたい場合は、画面を下にスクロールし、絞り込む条件を設定します。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/card.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>検索した結果が表示されます。</li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/list.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      「リストで見る」をクリックすると、リスト形式で情報を見ることができます。
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="o-guide-box" id="search-on-map">
              <div className="o-guide-box__title">
                保険内・保険外情報をマップで探す
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/search-on-map-2.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      検索したいサービスを選択し「この条件でマップ検索」ボタンをクリックします。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/search-on-map-3.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      検索した情報をマップ上に配置して見ることができます。
                    </li>
                    <li>
                      「◀」アイコンをクリックすると、マップの左側に表示されているリストが非表示になり、マップの表示領域を拡大することができます。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/search-on-map-4.png" />
                </div>
              </div>
            </div>

            <div className="o-guide-box" id="region">
              <div className="o-guide-box__title">
                地域の選択を行う（市区町村・町名）
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/card_to_region.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      検索結果画面左上の「地域の選択」部分に現在表示されている地域が表示されています。
                      <br />
                      その他地域の情報を閲覧したい場合は「市区町村の変更」をクリックします。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/region.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      市区町村が選択できるダイアログが表示されます。閲覧したい地域を選択し「再検索」をクリックすると選択した地域の情報が表示されます。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/card_to_region2.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      町名単位で、さらに限定した地域に絞り込んで検索を行いたい場合は「町名の変更」をクリックします。
                      <br />
                      ※市区町村が単一選択の場合、町名の選択が可能です。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/region2.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      町名が選択できるダイアログが表示されます。閲覧したい地域を選択し「再検索」をクリックすると選択した地域の情報が表示されます。
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="o-guide-box" id="detail">
              <div className="o-guide-box__title">
                保険内・保険外情報の詳細を見る
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/card_to_shousai.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      各情報の名称、もしくは「詳細を見る」をクリックすると、詳細情報を見ることができます。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/shousai.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>詳細情報が表示されます。</li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/shousai2.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      画面上部のアイコンを押すと、表示内容の切り替えを行うことができます。
                      <br />
                      「詳細」はより具体的なサービス内容を、「コメント」は利用経験のある専門職からのコメントを見る事ができます。
                      <br />
                      ※コメントの公開範囲は、専門職とコメントされた事業所のみです。
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="o-guide-box" id="print">
              <div className="o-guide-box__title">
                保険内・保険外情報を印刷する
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/shousai_to_print.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      情報の検索結果のカード、もしくは情報の詳細にある「印刷」のアイコンをクリックして印刷プレビューを表示させます。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/print.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      印刷プレビューが表示されたら、右上にある「このページを印刷する」をクリックして印刷します。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/print2.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      複数の情報を選択し、「印刷」のアイコンをクリックすると、「リスト」または「マップ」の形式で印刷をすることができます。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/print3.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      「リストで印刷」「マップで印刷」を選択し、印刷プレビューが表示されたら、右上にある「このページを印刷する」をクリックして印刷します。
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="o-guide-box" id="comment">
              <div className="o-guide-box__title">
                保険内・保険外情報にコメントを記入する（専門職のみ）
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/shousai_to_comment.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      「情報の詳細」画面上部の「コメント」アイコンをクリックし、左側の「コメントを投稿する」をクリックすると、コメント記入画面が表示されます。
                    </li>
                  </ol>
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__text">
                  <b>コメントとは？</b>
                  専門職(ケアマネジャーや地域包括支援センター職員等)の皆さまが、各サービスに対するコメントを記入・閲覧できるようになりました。
                  <br />
                  利用経験のある専門職のコメントを、事業所選択時の参考にすることが可能になります。
                  <br />
                  ※コメントの公開範囲は、専門職とコメントされた事業所のみです。
                </div>
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/comment.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      コメントを記入し、「確認する」アイコンをクリックすると内容を確認する事が可能です。内容を確認後、「投稿する」アイコンをクリックし、コメントを投稿します。　※コメント記入者名は「事業所名」が表示されます。
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="o-guide-box" id="dashboard_to_folder">
              <div className="o-guide-box__title">共有フォルダとは？</div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/dashboard_to_folder.png" />
                </div>
                <div className="o-guide-box__text">
                  <p>
                    共有フォルダには、各情報(保険内、保険外)に表示される「共有フォルダに追加」ボタンをクリックした情報が追加されます。
                  </p>
                  <p>
                    共有フォルダに追加された情報は、同じ職場でミルモネットを利用されている他の職員の方と共有することができます。また、利用頻度の高い情報を登録しておくことで、再度検索を行わなくても、共有フォルダから直接閲覧することができるようになります。
                  </p>
                </div>
              </div>
            </div>

            <div className="o-guide-box" id="add">
              <div className="o-guide-box__title">
                保険内・保険外情報を共有フォルダに追加する
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/shousai_to_folder.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      情報の検索結果のカード、もしくは詳細情報にある「共有フォルダ」のアイコンをクリックして、共有フォルダへ追加します。
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="o-guide-box" id="shubetsu">
              <div className="o-guide-box__title">
                共有フォルダに登録した情報をサービス種別毎に見る
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/dashboard_to_folder.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>「共有フォルダ」をクリックします。</li>
                    <li>
                      共有フォルダに登録した情報を、各サービス種別ごとに見ることができます。
                    </li>
                    <li>
                      ご覧になりたいサービスのアイコンをクリックして、登録したサービスをご覧ください。
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="o-guide-box" id="map">
              <div className="o-guide-box__title">
                共有フォルダに登録した情報をマップで見る
              </div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/map.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>
                      共有フォルダに登録した情報は、「マップで見る」をクリックすると、各情報をマップ上に配置して見ることができます。
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <div className="o-guide-box" id="logout">
              <div className="o-guide-box__title">ログアウトする</div>
              <div className="o-guide-section">
                <div className="o-guide-box__img">
                  <img src="/images/guide/logout.png" />
                </div>
                <div className="o-guide-box__text">
                  <ol>
                    <li>「ログアウト」をクリックして、ログアウトします。</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
      </WebFrontNavlessLayout>
    );
  }
}

GuidePage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  location: PropTypes.object,
};

export default withRouter(
  connect(
    () => ({}),
    (dispatch) => ({
      actions: {
        gotoback: (pathname) => dispatch(push(pathname)),
      },
    })
  )(GuidePage)
);
