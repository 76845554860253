import React, { Fragment } from "react";
import PropTypes from "prop-types";
import RegistrationDataEmpty from "./RegistrationDataEmpty";
import { gotoFlierFormButton } from "./GoToEditFormButton";
import { fileTypeValueOfFilename } from "../../../app/domain/file/FileType";
import FileIcon from "@material-ui/icons/InsertDriveFile";

class ServiceContentTypeFlier extends React.Component {
  constructor(props) {
    super(props);
    this.handleDownloadButton = this.handleDownloadButton.bind(this);
  }

  ie11() {
    return document.documentMode && navigator.msSaveOrOpenBlob;
  }

  handleDownloadButton(e, filename) {
    // IE11以外はブラウザの機能でダウンロード
    if (!this.ie11()) return true;

    e.preventDefault();
    const a = e.target;
    const filePath = a.href;
    const xhr = new XMLHttpRequest();
    xhr.open("GET", filePath);
    xhr.responseType = "blob";
    xhr.send();
    xhr.onload = () => navigator.msSaveOrOpenBlob(xhr.response, filename);
  }

  render() {
    return (
      <div>
        <div className="p-office-body">
          {this.renderMain()}

          {gotoFlierFormButton(this.props.contents)}
        </div>
      </div>
    );
  }

  renderMain() {
    const { fliers } = this.props.contents.blocks;
    if (fliers.list.length < 1) return <RegistrationDataEmpty />;
    const list = fliers.list.sort((a, b) => a.orderNo - b.orderNo);
    return list.map((item) => this.renderItem(item));
  }

  renderItem(item) {
    const fileType = fileTypeValueOfFilename(item.originalFilename);
    const modern = !this.ie11();
    const viewable = fileType.viewable && (modern || fileType.image);
    return (
      <div key={item.orderNo} className="p-office-body__pdf-block">
        {viewable
          ? this.renderViewer(item, fileType)
          : this.renderFile(item, fileType)}
        <hr />
      </div>
    );
  }

  renderViewer(item, fileType) {
    return (
      <Fragment>
        <div className="p-office-body-dl-bt">
          <div className="c-simplebutton-b">
            <a
              href={item.filePath}
              download={item.originalFilename}
              onClick={(e) =>
                this.handleDownloadButton(e, item.originalFilename)
              }
            >
              ダウンロードする
            </a>
          </div>
        </div>
        {fileType.image ? this.renderImageArea(item) : this.renderPdfArea(item)}
        <div className="p-office-body-dl-bt">
          <div className="c-simplebutton-b">
            <a
              href={item.filePath}
              download={item.originalFilename}
              onClick={(e) =>
                this.handleDownloadButton(e, item.originalFilename)
              }
            >
              ダウンロードする
            </a>
          </div>
        </div>
      </Fragment>
    );
  }

  renderImageArea(item) {
    return (
      <div className="p-office-body-image-area">
        <img src={item.filePath} alt={item.originalFilename} />
      </div>
    );
  }

  renderPdfArea(item) {
    return (
      <div className="p-office-body-pdf-area">
        <iframe
          style={{ width: "100%", height: "100%" }}
          src={`${item.filePath}#page=1&pagemode=none`}
        ></iframe>
      </div>
    );
  }

  renderFile(item, fileType) {
    const className = `p-office-body-file-icon p-office-body-file-icon--${fileType.icon}`;
    return (
      <Fragment>
        <div className={className}>
          <FileIcon />
        </div>
        <p>
          {fileType.label}ファイルがあります。
          <br />【{item.originalFilename}】
        </p>
        <div className="p-office-body-dl-bt">
          <div className="c-simplebutton-b">
            <a
              href={item.filePath}
              download={item.originalFilename}
              onClick={(e) =>
                this.handleDownloadButton(e, item.originalFilename)
              }
            >
              ダウンロードする
            </a>
          </div>
        </div>
      </Fragment>
    );
  }
}

ServiceContentTypeFlier.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default ServiceContentTypeFlier;
