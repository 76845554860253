import React from "react";

const RegistrationDataEmpty = () => {
  return (
    <div className="p-office-body__registration-data-empty">
      <img src="/images/chara_down.png" />
      <p>
        登録がありません。
        <br />
      </p>
    </div>
  );
};

export default RegistrationDataEmpty;
