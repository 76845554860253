import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_COMMENT,
} from "../../../../state/master-data";
import { fetch, changeForm, submit } from "../../../../state/comment/editForm";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import Mount from "../../../../app/ui/layout/Mount";
import WebFrontDialog from "../../../../app/ui/dialog/WebFrontDialog";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";
import DisclosureRangeType from "./DisclosureRangeType";

class CommentEditForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    this.fetchEditForm();
  }

  handleCancel() {
    this.props.onCancel();
  }

  handleFormSubmit() {
    const { comment, commentEditForm, onFormSubmit } = this.props;
    const { id } = comment;
    const { form } = commentEditForm;
    const callback = (form) => {
      this.modifyCommentOnVisibleSurface(form);
    };
    onFormSubmit(id, form, callback);
  }

  fetchEditForm() {
    this.props.onLoad();
  }

  modifyCommentOnVisibleSurface(form) {
    const { body, disclosureRangeCode } = form;
    const { comment, masterData } = this.props;
    const dummyComment = {
      ...comment,
      body,
      disclosureRangeType: masterData.comment_disclosureRange.find(
        (it) => it.code === disclosureRangeCode
      ).label,
    };
    this.props.onFormSubmitSuccess(dummyComment);
  }

  render() {
    return this.renderForm();
  }

  renderForm() {
    const props = {
      open: true,
      wide: true,
      wait: this.formIsLoading(),
      cancelText: "キャンセル",
      okText: "修正する",
      onCancel: this.handleCancel,
      onClose: this.handleCancel,
      onOk: this.handleFormSubmit,
      title: "投稿済みのコメント",
      bodyText: (
        <div className="form-layout">
          <div className="form-layout__input">
            {this.renderRangeForm()}
            {this.renderBodyForm()}
          </div>
        </div>
      ),
    };
    return <WebFrontDialog {...props} />;
  }

  renderRangeForm() {
    const name = "disclosureRangeCode";
    const bindingPorps = this.bindingProps();
    const disclosureRange = this.selectableDisclosureRange();
    const disclosureRangeSelect = disclosureRange.length > 1 ? null : "hide";
    const disclosureRangeCodes = disclosureRange.map((x) => x.code);
    const helperText = this.props.inputFeedback.errors.getByName(name);
    return (
      <div className={disclosureRangeSelect}>
        <div className="c-small-head">公開範囲</div>
        <div className="c-sort-select">
          <select
            value={bindingPorps.form.getByName(name)}
            onChange={(e) => bindingPorps.onChange(name, e.target.value)}
          >
            {disclosureRange.map((option) => (
              <option key={option.code} value={option.code}>
                {option.label}
              </option>
            ))}
          </select>
        </div>
        <Mount if={helperText}>
          <div className="c-error-feedback">{helperText}</div>
        </Mount>
        <div className="comment-disclosure-range-description">
          <dl>
            {disclosureRangeCodes.includes(
              DisclosureRangeType.地域包括支援センター職員のみに公開.code
            )
              ? [
                  <dt
                    key={
                      DisclosureRangeType.地域包括支援センター職員のみに公開
                        .code
                    }
                  >
                    地域包括支援センター職員のみに公開
                  </dt>,
                  <dd
                    key={
                      DisclosureRangeType.地域包括支援センター職員のみに公開
                        .code
                    }
                  >
                    同じ地域（市区町村）の地域包括支援センター職員のみが、閲覧可能です。
                  </dd>,
                ]
              : null}
            {disclosureRangeCodes.includes(
              DisclosureRangeType
                .地域包括支援センター職員_社会福祉協議会職員のみに公開.code
            )
              ? [
                  <dt
                    key={
                      DisclosureRangeType
                        .地域包括支援センター職員_社会福祉協議会職員のみに公開
                        .code
                    }
                  >
                    地域包括支援センター職員、社会福祉協議会職員のみに公開
                  </dt>,
                  <dd
                    key={
                      DisclosureRangeType
                        .地域包括支援センター職員_社会福祉協議会職員のみに公開
                        .code
                    }
                  >
                    同じ地域（市区町村）の地域包括支援センター職員と社会福祉協議会職員が閲覧可能です。
                    <br />
                    ※居宅介護支援事業所職員、その他サービス種別事業所は閲覧できません
                  </dd>,
                ]
              : null}
            {disclosureRangeCodes.includes(
              DisclosureRangeType.専門職のみに公開.code
            )
              ? [
                  <dt key={DisclosureRangeType.専門職のみに公開.code}>
                    専門職のみに公開
                  </dt>,
                  <dd key={DisclosureRangeType.専門職のみに公開.code}>
                    専門職とコメントされた事業所が、閲覧可能です。
                  </dd>,
                ]
              : null}
          </dl>
        </div>
      </div>
    );
  }

  renderBodyForm() {
    const name = "body";
    const bindingPorps = this.bindingProps();
    const helperText = this.props.inputFeedback.errors.getByName(name);
    const placeholder =
      "例）\n" +
      "\n" +
      "とっても活気があるデイサービスです。\n" +
      "外出レクの頻度が高く、お出かけ好きな利用者さんがいつも楽しみにしています。\n" +
      "スタッフの皆様もとても丁寧に対応してくださり、明るい雰囲気です。";
    return (
      <div>
        <div className="c-small-head">本文</div>
        <div className="c-form-text">
          <textarea
            placeholder={placeholder}
            value={bindingPorps.form.getByName(name)}
            onChange={(e) => bindingPorps.onChange(name, e.target.value)}
          ></textarea>
        </div>
        <Mount if={helperText}>
          <div className="c-error-feedback">{helperText}</div>
        </Mount>
      </div>
    );
  }

  renderActions(disabled = false) {
    return (
      <div className="form-layout__actions">
        <SpaceOut>
          <FlatSecondaryButton disabled={disabled} onClick={this.handleCancel}>
            キャンセル
          </FlatSecondaryButton>
        </SpaceOut>
        <SpaceOut>
          <RaisedPrimaryButton
            disabled={disabled}
            onClick={this.handleFormSubmit}
          >
            修正する
          </RaisedPrimaryButton>
        </SpaceOut>
      </div>
    );
  }

  formIsLoading() {
    return this.props.commentEditForm.isLoading;
  }

  bindingProps() {
    const { commentEditForm, onFormChange: onChange } = this.props;
    const { form } = commentEditForm;
    return { form, onChange };
  }

  selectableDisclosureRange() {
    const { authentication, masterData } = this.props;
    const hiddenCode = [];
    if (
      authentication.user.group.serviceShubetsuCode ===
      ServiceShubetsuType.社会福祉協議会.code
    ) {
      hiddenCode.push(
        DisclosureRangeType.地域包括支援センター職員のみに公開.code
      );
    }

    if (
      authentication.user.group.serviceShubetsuCode ===
      ServiceShubetsuType.居宅介護支援.code
    ) {
      hiddenCode.push(
        DisclosureRangeType.地域包括支援センター職員のみに公開.code
      );
      hiddenCode.push(
        DisclosureRangeType
          .地域包括支援センター職員_社会福祉協議会職員のみに公開.code
      );
    }
    return masterData.comment_disclosureRange.filter(
      (it) => !hiddenCode.includes(it.code)
    );
  }
}

CommentEditForm.propTypes = {
  authentication: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  comment: PropTypes.object.isRequired,
  commentEditForm: PropTypes.object.isRequired,
  inputFeedback: PropTypes.object.isRequired,
  // on-event handler
  onLoad: PropTypes.func.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onFormSubmitSuccess: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_COMMENT)(
  connect(mapStateToProps, mapDispatchToProps)(CommentEditForm)
);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    commentEditForm: state.commentEditForm,
    inputFeedback: state.inputFeedback,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    onLoad: () => dispatch(fetch(props.comment.id)),
    onFormChange: (name, value) => dispatch(changeForm(name, value)),
    onFormSubmit: (id, form, callback) => dispatch(submit(id, form, callback)),
  };
}
