import React from "react";
import PropTypes from "prop-types";

const ValueWithBusubetsuServiceTeikyojikanUnit = ({ value }) => {
  if (value == null) return "";
  // 3〜5部のときは改行して表示
  if (value.match(/5部/)) {
    const array = value.split("、");
    return (
      <div>
        <div>
          {array[0]}、{array[1]}、
        </div>
        <div>
          {array[2]}、{array[3]}、
        </div>
        <div>{array[4]}</div>
      </div>
    );
  } else if (value.match(/4部/)) {
    const array = value.split("、");
    return (
      <div>
        <div>
          {array[0]}、{array[1]}、
        </div>
        <div>
          {array[2]}、{array[3]}
        </div>
      </div>
    );
  } else if (value.match(/3部/)) {
    const array = value.split("、");
    return (
      <div>
        <div>
          {array[0]}、{array[1]}、
        </div>
        <div>{array[2]}</div>
      </div>
    );
  }
  return value;
};

ValueWithBusubetsuServiceTeikyojikanUnit.propTypes = {
  value: PropTypes.string,
};

export default ValueWithBusubetsuServiceTeikyojikanUnit;
