import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import TruncateText from "../../../../../app/ui/text/TruncateText";

const RyokinBlock = ({ ryokin }) => {
  const LABEL_LIMIT = 80;
  return (
    <Fragment>
      <HeaderTextBlue text="料金について" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>費用</th>
              <td>{ryokin.hiyo}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>会費</th>
              <td>{ryokin.kaihi}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>交通費</th>
              <td>
                <TruncateText maxLength={LABEL_LIMIT}>
                  {ryokin.kotsuhi}
                </TruncateText>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

RyokinBlock.propTypes = {
  ryokin: PropTypes.object.isRequired,
};

export default RyokinBlock;
