import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as actions from "../../../../state/jigyosho-flier-collective/actions";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FileIcon from "@material-ui/icons/Note";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Dropzone from "../../../../app/ui/dropzone/Dropzone";
import Margin from "../../../../app/ui/layout/Margin";
import {
  IconButton,
  Paper,
  TableBody,
  TableHead,
  TableRow,
} from "@material-ui/core";
import FixedTable from "../../../../app/ui/table/FixedTable";
import SmallPaddingTableCell from "../../../../app/ui/table/SmallPaddingTableCell";
import LinearProgressDialog from "../../../../app/ui/progress/LinearProgressDialog";
import Parametername from "../../../../app/naming/Parametername";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import { Card } from "@material-ui/core";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";

class JigyoshoFlierCollectivePage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleDrop = this.handleDrop.bind(this);
    this.handleRemoveButtonClick = this.handleRemoveButtonClick.bind(this);
  }

  state = {
    filesAreUploading: false,
    draggedId: undefined,
    draggedIndex: undefined,
    removeDialogIsOpen: false,
    removeTargetId: null,
    isDeleteButton: false,
  };

  initializeFields(props) {
    const { params } = props.match;
    const { search } = props.location;
    const searchParams = new URLSearchParams(search);
    this.id = params.id;
    this.prevPathname = searchParams.get(Parametername.pathname);
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.request(this.id);
  }

  handleBackButtonClick() {
    const { actions } = this.props;
    if (this.prevPathname) {
      actions.gotoPage(this.prevPathname);
    } else {
      actions.gotoStatusPage(this.id);
    }
  }

  handleDrop(files) {
    if (!files || files.length === 0) return;
    const { actions } = this.props;

    this.setState({ filesAreUploading: true });
    actions.uploadFiles([files[0]], () => {
      this.setState({ filesAreUploading: false });
    });
  }

  handleRemoveButtonClick(e, id) {
    e.stopPropagation();
    const { actions } = this.props;
    actions.removeFlier(this.id, id);
  }

  handleDragStart(e, index, id) {
    this.setState({
      draggedId: id,
    });
  }

  handleDragEnd() {
    this.setState({
      draggedId: undefined,
      draggedIndex: undefined,
    });
  }

  handleDragEnter(e, index) {
    if (!this.state.draggedId) return;
    this.setState({
      draggedIndex: index,
    });
  }

  handleDragDrop(e, index) {
    if (!this.state.draggedId) return;
    const { actions } = this.props;
    actions.changeSort(this.state.draggedId, index, () => {});
  }

  handleSaveButtonClick() {
    const { actions, jigyoshoFlierCollective } = this.props;
    actions.submitToEditFlierCollective(
      this.id,
      jigyoshoFlierCollective.form,
      this.handleBackButtonClick
    );
  }

  render() {
    return (
      <Fragment>
        <Boundary>
          <SideMargin>
            {this.renderHead()}
            {this.renderNavigation()}
            {this.renderDropzone()}
            {this.renderFliers()}
            {this.renderFormButton()}
          </SideMargin>
        </Boundary>
        <LinearProgressDialog open={this.state.filesAreUploading} />
      </Fragment>
    );
  }

  renderHead() {
    return <BackOfficeHead>チラシ（PDF）の登録</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderDropzone() {
    return (
      <Margin top>
        <Dropzone onDrop={this.handleDrop} errorMessage />
      </Margin>
    );
  }

  renderFliers() {
    const { list } = this.props.jigyoshoFlierCollective;
    const { draggedIndex } = this.state;
    return (
      <Margin top>
        <Paper>
          <FixedTable>
            <TableHead>
              <TableRow>
                <SmallPaddingTableCell>ファイル名</SmallPaddingTableCell>
                <SmallPaddingTableCell style={styles.fileTypeCell}>
                  ファイルタイプ
                </SmallPaddingTableCell>
                <SmallPaddingTableCell
                  style={styles.deleteCell}
                ></SmallPaddingTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((flier, index) => (
                <TableRow
                  key={flier.id}
                  style={draggedIndex == index ? styles.draggedIndex : {}}
                  onDragStart={(e) => this.handleDragStart(e, index, flier.id)}
                  onDragEnd={(e) => this.handleDragEnd(e, index)}
                  onDragEnter={(e) => this.handleDragEnter(e, index)}
                  onDrop={(e) => this.handleDragDrop(e, index)}
                  draggable={true}
                >
                  <SmallPaddingTableCell>
                    {flier.originalFilename}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    <a href={flier.filePath} target="flier">
                      <FileIcon style={styles.icon} />
                      {flier.contentType}
                    </a>
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell style={styles.deleteCell}>
                    <IconButton
                      color="secondary"
                      onClick={(e) => this.handleRemoveButtonClick(e, flier.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </SmallPaddingTableCell>
                </TableRow>
              ))}
            </TableBody>
          </FixedTable>
        </Paper>
      </Margin>
    );
  }

  renderFormButton() {
    const { listIsSubmitting } = this.props.jigyoshoFlierCollective;
    return (
      <Card>
        <SideMargin top bottom>
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton
                disabled={listIsSubmitting}
                onClick={this.handleSaveButtonClick}
              >
                保存する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }
}

const styles = {
  draggedIndex: {
    borderLeft: "4px solid",
  },
  fileTypeCell: {
    width: 200,
  },
  deleteCell: {
    width: 40,
    textAlign: "center",
  },
  icon: {
    margin: "0 5px",
    verticalAlign: "middle",
  },
};

JigyoshoFlierCollectivePage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  jigyoshoFlierCollective: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  statusPathname: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JigyoshoFlierCollectivePage);

function mapStateToProps(state) {
  return {
    jigyoshoFlierCollective: state.jigyoshoFlierCollective,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoStatusPage: (id) =>
        dispatch(push(props.statusPathname.replace(/:id/, id))),
      gotoPage: (pathname) => dispatch(push(pathname)),
    },
  };
}
