import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Thumbnail from "../../search/Thumbnail";
import Mount from "../../../../app/ui/layout/Mount";
import TruncateText from "../../../../app/ui/text/TruncateText";
import YoyakuShiharaiBlock from "./hokengaiService/YoyakuShiharaiBlock";
import RiyokaishiBlock from "./hokengaiService/RiyokaishiBlock";
import CommonUneiJohoBlock from "./hokengaiService/CommonUneiJohoBlock";
import MenuBlock from "./katsudoKayoinoba/MenuBlock";
import RyokinBlock from "./katsudoKayoinoba/RyokinBlock";
import GyoseiBlock from "./katsudoKayoinoba/GyoseiBlock";
import OtherMenuBlock from "./katsudoKayoinoba/OtherMenuBlock";

class KatsudoKayoinobaPrint extends React.Component {
  render() {
    const { contents } = this.props;
    // メイン表示アイテム
    const targetSubId = this.props.serviceSubId ? this.props.serviceSubId : 1;
    const mainItem = contents.menuPlan.item.find(
      (it) => it.subId == targetSubId
    );
    // その他表示アイテム
    const otherItems = contents.menuPlan.item.filter(
      (it) => it.subId != targetSubId
    );

    return (
      <Fragment>
        {this.renderHead(contents, mainItem)}
        <div className="print-body__block print-body__block--two-columns print-body__block--hokengai-font">
          <div>{this.renderPhoto(contents)}</div>
          <div>{this.renderMenu(mainItem)}</div>
        </div>
        <div className="print-body__block print-body__block--two-columns print-body__block--hokengai-font">
          <div>{this.renderRyokin(mainItem)}</div>
          <div>{this.renderYoyakuShiharai(contents)}</div>
        </div>
        <div className="print-body__block print-body__block--two-columns print-body__block--hokengai-font">
          <div>{this.renderRiyokaishi(contents)}</div>
          <div>{this.renderGyosei(contents)}</div>
        </div>
        <div className="print-body__block print-body__block--hokengai-font">
          <div>{this.renderOtherMenu(otherItems)}</div>
        </div>
        <hr />
        {this.renderUneiJoho(contents)}
      </Fragment>
    );
  }

  renderHead(contents, mainItem) {
    return (
      <div className="print-body__head">
        {this.renderHeadLeft(contents, mainItem)}
        {this.renderHeadRight(contents, mainItem)}
        <img src="/images/print_bg_light_yellow.png" />
      </div>
    );
  }

  renderHeadLeft(contents, mainItem) {
    const { kihonjoho: kihonjoho } = contents;
    const LABEL_LIMIT = 178;
    const tags = [];
    tags.push(kihonjoho.serviceShubetsu);
    if (mainItem.katsudonaiyobunrui) {
      tags.push(mainItem.katsudonaiyobunrui);
    }
    return (
      <div>
        <div className="service-shubetsu">
          {tags.map((tag) => (
            <div key={tag}>
              {tag}
              <img
                style={{ borderRadius: "0 0 4pt 4pt" }}
                src="/images/print_bg_light_blue.png"
              />
            </div>
          ))}
        </div>
        <div className="eigyo-joho">
          <ul>
            {mainItem.eigyojoho.yobibetsueigyobi.getsuyobieigyoSign ? (
              <li className="open">
                月<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                月<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {mainItem.eigyojoho.yobibetsueigyobi.kayobieigyoSign ? (
              <li className="open">
                火<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                火<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {mainItem.eigyojoho.yobibetsueigyobi.suiyobieigyoSign ? (
              <li className="open">
                水<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                水<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {mainItem.eigyojoho.yobibetsueigyobi.mokuyobieigyoSign ? (
              <li className="open">
                木<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                木<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {mainItem.eigyojoho.yobibetsueigyobi.kinyobieigyoSign ? (
              <li className="open">
                金<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                金<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {mainItem.eigyojoho.yobibetsueigyobi.doyobieigyoSign ? (
              <li className="open">
                土<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                土<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {mainItem.eigyojoho.yobibetsueigyobi.nichiyobieigyoSign ? (
              <li className="open">
                日<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                日<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {mainItem.eigyojoho.yobibetsueigyobi.shukujitsueigyoSign ? (
              <li className="open">
                祝<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                祝<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
          </ul>
        </div>

        <div className="jigyoshomei">{kihonjoho.jigyoshomei}</div>
        <div className="tokuchoMessageTitle">
          {kihonjoho.tokuchoMessageTitle
            ? `・${kihonjoho.tokuchoMessageTitle}`
            : ""}
        </div>
        <div className="tokuchoMessageDetails">
          <TruncateText maxLength={LABEL_LIMIT}>
            {kihonjoho.tokuchoMessageDetails}
          </TruncateText>
        </div>
      </div>
    );
  }

  renderHeadRight(contents, mainItem) {
    const { kihonjoho: kihonjoho } = contents;
    const LABEL_LIMIT = 46;
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>営</span>
                  <span>業</span>
                  <span>・</span>
                  <span>開</span>
                  <span>催</span>
                </span>
                <span>
                  <span>時</span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span>間</span>
                </span>
              </th>
              <td colSpan="3">{mainItem.eigyojoho.eigyokaisaijikan}</td>
            </tr>
            <tr>
              <th className="paddingTel">
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>T</span>
                  <span>E</span>
                  <span>L</span>
                </span>
              </th>
              <td className="fontTel">{mainItem.kokairenrakusaki.tel}</td>
              <Mount if={mainItem.kokairenrakusaki.fax}>
                <th className="paddingTel">
                  <img className="bgWhite" src="/images/print_bg_white.png" />
                  <span>
                    <span>F</span>
                    <span>A</span>
                    <span>X</span>
                  </span>
                </th>
                <td className="paddingTel">{mainItem.kokairenrakusaki.fax}</td>
              </Mount>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>サ</span>
                  <span>ー</span>
                  <span>ビ</span>
                  <span>ス</span>
                </span>
                <span>
                  <span>提</span>
                  <span>供</span>
                  <span>エ</span>
                  <span>リ</span>
                  <span>ア</span>
                </span>
              </th>
              <td colSpan="3">
                <TruncateText maxLength={LABEL_LIMIT}>
                  {kihonjoho.serviceTeikyoArea}
                </TruncateText>
              </td>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>ご</span>
                  <span>利</span>
                  <span>用</span>
                </span>
                <span>
                  <span>対</span>
                  <span>象</span>
                  <span>者</span>
                </span>
              </th>
              <td colSpan="3">
                <TruncateText maxLength={LABEL_LIMIT}>
                  {kihonjoho.riyotaishosha}
                </TruncateText>
              </td>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>開</span>
                  <span>催</span>
                  <span>日</span>
                </span>
                <span>
                  <span>備</span>
                  <span></span>
                  <span>考</span>
                </span>
              </th>
              <td colSpan="3">{mainItem.eigyojoho.teikyubiComment}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderPhoto(contents) {
    const photo1 = contents.photos[0] || { imageUrl: "", description: "" };

    return (
      <div style={{ marginTop: "10pt" }}>
        <div className="print-body__thumbnail_single">
          <Thumbnail src={photo1.imageUrl} description={photo1.description} />
        </div>
      </div>
    );
  }

  renderMenu(mainItem) {
    return <MenuBlock mainItem={mainItem} />;
  }

  renderRyokin(mainItem) {
    const { ryokin: ryokin } = mainItem;

    return <RyokinBlock ryokin={ryokin} />;
  }

  renderYoyakuShiharai(contents) {
    const { yoyakushiharai: yoyakushiharai } = contents;

    return <YoyakuShiharaiBlock yoyakuShiharai={yoyakushiharai} />;
  }

  renderRiyokaishi(contents) {
    const { riyokaishi: riyokaishi } = contents;

    return <RiyokaishiBlock riyokaishi={riyokaishi} />;
  }

  renderGyosei(contents) {
    const { gyoseijoseiseido: gyoseijoseiseido } = contents;

    return <GyoseiBlock gyosei={gyoseijoseiseido} />;
  }

  renderOtherMenu(otherItems) {
    if (otherItems.length === 0) return;
    return <OtherMenuBlock otherItems={otherItems} />;
  }

  renderUneiJoho(contents) {
    const { uneijoho: uneijoho } = contents;
    const { serviceShubetsuCode: serviceShubetsuCode } = contents;

    return (
      <CommonUneiJohoBlock
        uneiJoho={uneijoho}
        serviceShubetsuCode={serviceShubetsuCode}
      />
    );
  }
}

KatsudoKayoinobaPrint.propTypes = {
  serviceSubId: PropTypes.string,
  contents: PropTypes.object.isRequired,
};

export default KatsudoKayoinobaPrint;
