import React from "react";
import PropTypes from "prop-types";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import { Card, IconButton } from "@material-ui/core";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import connect from "react-redux/es/connect/connect";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../../app/ui/form/InputTypeText";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import Dropzone from "../../../../app/ui/dropzone/Dropzone";
import { uploadFiles } from "../../../../state/file-upload/actions";

class JobOfferJigyoshoForm extends React.Component {
  constructor(props) {
    super(props);

    this.handlePromotionItemAddButtonClick = this.handlePromotionItemAddButtonClick.bind(
      this
    );
    this.handlePromotionItemRemoveButtonClick = this.handlePromotionItemRemoveButtonClick.bind(
      this
    );
    this.handleDrop = this.handleDrop.bind(this);
  }

  handlePromotionItemAddButtonClick() {
    const { promotions } = this.props.jobOfferJigyoshoForm.form;
    const emptyPromotion = { title: "", detail: "", uploadFileForm: null };
    this.props.onChangeForm("promotions", promotions.concat([emptyPromotion]));
  }

  handlePromotionItemRemoveButtonClick(index) {
    const { promotions } = this.props.jobOfferJigyoshoForm.form;
    this.props.onChangeForm(
      "promotions",
      promotions.filter((_, i) => i !== index)
    );
  }

  handleDrop(name, files) {
    this.props.uploadFiles([files[0]], (data) => {
      const file = data.contents[0];
      this.props.onChangeForm(name, file);
    });
  }

  render() {
    const { jobOfferJigyoshoForm, onCancel, onSubmit } = this.props;
    if (jobOfferJigyoshoForm.formIsLoading) return <CenteredCircularProgress />;
    if (!jobOfferJigyoshoForm.form.typeIs("edit")) return null;

    const bindingProps = {
      form: this.props.jobOfferJigyoshoForm.form,
      onChange: this.props.onChangeForm,
    };

    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title="事業所情報">
            <HorizontalLabelLayout labelText="キャッチフレーズ" required>
              <InputTypeText name="catchPhrase" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="アイキャッチ画像" required>
              {this.renderUploadImage("uploadFileForm")}
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="最寄り駅" required>
              <InputTypeText name="station" {...bindingProps} />
            </HorizontalLabelLayout>
          </FormGrouping>

          <FormGrouping title="採用ご担当者様">
            <HorizontalLabelLayout labelText="お名前" required>
              <InputTypeText name="recruiterName" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="電話番号" required>
              <InputTypeText name="recruiterTel" {...bindingProps} />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="メールアドレス" required>
              <InputTypeText name="recruiterEmail" {...bindingProps} />
            </HorizontalLabelLayout>
          </FormGrouping>

          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={onCancel}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton
                disabled={jobOfferJigyoshoForm.formIsSubmitting}
                onClick={onSubmit}
              >
                保存する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }

  renderUploadImage(name) {
    const file = this.props.jobOfferJigyoshoForm.form.getByName(name);
    if (file) {
      return (
        <div style={{ position: "relative" }}>
          <img
            src={file.filePath}
            alt={file.originalFilename}
            title={file.originalFilename}
            style={{ width: "100%" }}
          />
          <IconButton
            onClick={() => this.props.onChangeForm(name, null)}
            style={{ position: "absolute", top: 5, right: 5 }}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      );
    } else {
      return <Dropzone onDrop={(files) => this.handleDrop(name, files)} />;
    }
  }
}

JobOfferJigyoshoForm.propTypes = {
  jobOfferJigyoshoForm: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onChangeForm: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    jobOfferJigyoshoForm: state.jobOfferJigyoshoForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    uploadFiles: (files, callback) => dispatch(uploadFiles(files, callback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobOfferJigyoshoForm);
