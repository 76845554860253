import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

class MainContents extends React.Component {
  render() {
    const { contents, print = false } = this.props;
    return (
      <Fragment>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">サービスについて</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>配達が出来る食事</th>
                    <td>
                      {contents.blocks.haishokuService.haishokuDekiruShokuji.join(
                        "、"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>容器の種類</th>
                    <td>
                      {contents.blocks.haishokuService.youkinoShurui.join("、")}
                    </td>
                  </tr>
                  <tr>
                    <th>対応してくれるサービス</th>
                    <td>
                      {contents.blocks.haishokuService.taioShitekureruService.join(
                        "、"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td>{contents.blocks.haishokuService.biko}</td>
                  </tr>
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.delivery
              )}
            </div>
          </div>

          <div className="p-office-body-sep__right">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">ご利用開始について</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>ご利用開始までの流れ</th>
                    <td>
                      {
                        contents.blocks.hokengaiServiceKaishi
                          .goriyokaishimadenoNagare
                      }
                    </td>
                  </tr>
                  {print ? null : (
                    <Fragment>
                      <tr>
                        <th>ご利用可能対象者</th>
                        <td>
                          {
                            contents.blocks.hokengaiServiceKaishi
                              .serviceRiyoKanoTaishosha
                          }
                        </td>
                      </tr>
                      <tr>
                        <th>提供・対象地域</th>
                        <td>
                          {
                            contents.blocks.hokengaiServiceKaishi
                              .serviceTeikyochiiki
                          }
                        </td>
                      </tr>
                    </Fragment>
                  )}
                  <tr>
                    <th>商品の配送・受け取りについて</th>
                    <td>
                      {contents.blocks.haishokuService.shohinnoHaisoUketori.join(
                        "、"
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.delivery
              )}
            </div>
          </div>
        </div>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">料金について</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>キャンセル時の扱いについて</th>
                    <td>
                      {contents.blocks.hokengaiRyokin.cancelJinoatsukainitsuite}
                    </td>
                  </tr>
                  <tr>
                    <th>注文に関する条件</th>
                    <td>
                      {
                        contents.blocks.haishokuServiceRyokin
                          .chumonnikansuruJoken
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>送料（税込み）</th>
                    <td>{contents.blocks.haishokuServiceRyokin.soryo}</td>
                  </tr>
                  <tr>
                    <th>送料について備考</th>
                    <td>{contents.blocks.haishokuServiceRyokin.soryoBiko}</td>
                  </tr>
                  <tr>
                    <th>費用に関する補足</th>
                    <td>
                      {contents.blocks.hokengaiRyokin.hiyonikansuruHosoku}
                    </td>
                  </tr>
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.delivery
              )}
            </div>
          </div>

          <div className="p-office-body-sep__right">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">
                行政の助成制度や補助について
              </div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>助成制度や補助券の利用可否</th>
                    <td>
                      {
                        contents.blocks.hokengaiGyoseiJoseiHojo
                          .joseiseidoHojokennoRiyoKahi
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td>{contents.blocks.hokengaiGyoseiJoseiHojo.biko}</td>
                  </tr>
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.delivery
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

MainContents.propTypes = {
  contents: PropTypes.object.isRequired,
  print: PropTypes.bool,
};

export default MainContents;
