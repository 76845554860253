import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import {
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
  withMasterData,
} from "../../../../../state/master-data";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import Right from "../../../../../app/ui/layout/Right";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

class TsushokeiServiceJigyoshoAkiJoho extends React.Component {
  render() {
    return <Fragment>{this.renderBasic()}</Fragment>;
  }

  renderBasic() {
    const { bindingProps, serviceShubetsuCode } = this.props;
    return (
      <FormGrouping title="空き情報" required>
        <div>
          <RatioBlock grow={4} divide={18}></RatioBlock>
          <RatioBlock grow={14} divide={18}>
            <StaticLabel>
              ※ 空き情報を更新すると、<b>更新日</b>がサイト上に表示されます。
              <br />※ 曜日ごとの空き人数をご記入ください
            </StaticLabel>
          </RatioBlock>
          <RatioBlock grow={3} divide={18}></RatioBlock>
          <RatioBlock divide={18}></RatioBlock>
          <RatioBlock divide={9}>
            <SpaceOut block>
              <StaticLabel>月曜</StaticLabel>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={9}>
            <SpaceOut block>
              <StaticLabel>火曜</StaticLabel>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={9}>
            <SpaceOut block>
              <StaticLabel>水曜</StaticLabel>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={9}>
            <SpaceOut block>
              <StaticLabel>木曜</StaticLabel>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={9}>
            <SpaceOut block>
              <StaticLabel>金曜</StaticLabel>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={9}>
            <SpaceOut block>
              <StaticLabel>土曜</StaticLabel>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={9}>
            <SpaceOut block>
              <StaticLabel>日曜</StaticLabel>
            </SpaceOut>
          </RatioBlock>
        </div>
        {([ServiceShubetsuType.通所リハビリテーション]
          .map((it) => it.code)
          .includes(serviceShubetsuCode)
          ? [1, 2, 3, 4, 5]
          : [1, 2, 3]
        ).map((part, index) => (
          <div key={part}>
            <RatioBlock grow={3} divide={18}>
              <SpaceOut block>
                <StaticLabel>
                  <Right>{part}部　空き人数</Right>
                </StaticLabel>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={18}></RatioBlock>
            <RatioBlock divide={9}>
              <SpaceOut block>
                <InputTypeNumber
                  name={`sections.${index}.getsuyo`}
                  {...bindingProps}
                />
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={9}>
              <SpaceOut block>
                <InputTypeNumber
                  name={`sections.${index}.kayo`}
                  {...bindingProps}
                />
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={9}>
              <SpaceOut block>
                <InputTypeNumber
                  name={`sections.${index}.suiyo`}
                  {...bindingProps}
                />
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={9}>
              <SpaceOut block>
                <InputTypeNumber
                  name={`sections.${index}.mokuyo`}
                  {...bindingProps}
                />
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={9}>
              <SpaceOut block>
                <InputTypeNumber
                  name={`sections.${index}.kinyo`}
                  {...bindingProps}
                />
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={9}>
              <SpaceOut block>
                <InputTypeNumber
                  name={`sections.${index}.doyo`}
                  {...bindingProps}
                />
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={9}>
              <SpaceOut block>
                <InputTypeNumber
                  name={`sections.${index}.nichiyo`}
                  {...bindingProps}
                />
              </SpaceOut>
            </RatioBlock>
          </div>
        ))}
      </FormGrouping>
    );
  }
}

TsushokeiServiceJigyoshoAkiJoho.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(TsushokeiServiceJigyoshoAkiJoho);
