import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../state/service-content/actions";
import ServiceContentTab from "./ServiceContentTab";
import ServiceContentType, {
  serviceContentTypeValueOfOrDefault,
} from "./ServiceContentType";
import ServiceContentTypeAbstract from "./ServiceContentTypeAbstract";
import ServiceContentTypeDetail from "./ServiceContentTypeDetail";
import ServiceContentTypePhoto from "./ServiceContentTypePhoto";
import ServiceContentTypeFlier from "./ServiceContentTypeFlier";
import ServiceContentTypeComment from "./ServiceContentTypeComment";
import { share, unshare } from "../../../state/shared-folder/actions";
import Mount from "../../../app/ui/layout/Mount";
import { servicePageTagsOf } from "./ServicePageExtraTags";
import ServicePageReprintComment from "./ServicePageReprintComment";
import Parametername from "../../../app/naming/Parametername";
import ServiceShubetsuType from "../../../app/domain/jigyosho/ServiceShubetsuType";

class ServicePage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleShareButtonClick = this.handleShareButtonClick.bind(this);
    this.handlePrintButtonClick = this.handlePrintButtonClick.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  initializeFields(props) {
    const { location } = this.props;
    const { params } = props.match;
    const searchParams = new URLSearchParams(location.search);
    this.serviceSubId = searchParams.get(Parametername.serviceSubId);
    this.id = +params.id;
    this.serviceContentType = serviceContentTypeValueOfOrDefault(params.type);

    if (this.props.preview == true) {
      sessionStorage.removeItem("list_url");
    }
    const list_url = sessionStorage.getItem("list_url");
    const state = this.props.location.state;
    this.url = state != null ? state.url : list_url != null ? list_url : "";
    sessionStorage.setItem("list_url", this.url);
  }

  handleShareButtonClick() {
    const { actions } = this.props;
    return this.serviceIsShared()
      ? actions.unshare(this.id, this.serviceSubId)
      : actions.share(this.id, this.serviceSubId);
  }

  handlePrintButtonClick() {
    this.props.actions.openPrintPage(this.id, this.serviceSubId);
  }

  handleBackButtonClick() {
    this.props.history.push(this.url);
  }

  serviceIsShared() {
    const contents = this.contents();
    if (!contents) return false;
    const { services } = this.props.sharedFolder;

    // 活動通いの場以外の場合、IDが一致する場合
    // 活動通いの場の場合、IDとsubIDが一致する場合：true
    return services.find(
      (s) =>
        (![ServiceShubetsuType.活動_通いの場]
          .map((it) => it.code)
          .includes(s.serviceShubetsuCode) &&
          s.id == contents.id) ||
        ([ServiceShubetsuType.活動_通いの場]
          .map((it) => it.code)
          .includes(s.serviceShubetsuCode) &&
          "" + s.id == this.id &&
          s.serviceSubId == this.serviceSubId)
    )
      ? true
      : false;
  }

  componentDidMount() {
    const { actions, preview, type: servicePageType } = this.props;
    if (this.contentsIsEmpty())
      actions.request(this.id, this.serviceSubId, this.serviceContentType, {
        preview,
        servicePageType,
      });
  }

  contentsIsEmpty() {
    const { serviceContent, preview } = this.props;
    const contents = serviceContent.contents[this.serviceContentType.name];
    return contents && contents.id == this.id && contents.preview == preview
      ? false
      : true;
  }

  contents() {
    const { serviceContent } = this.props;
    if (serviceContent.contentsIsLoading) return null;
    return this.serviceContentType.selector(serviceContent.contents);
  }

  render() {
    return (
      <div className="c-content-frame c-content-frame--office">
        <div className="p-office-head">
          <a
            className="p-office-head-back-button-b"
            onClick={this.handleBackButtonClick}
          >
            一覧に戻る
          </a>
          <div className="p-office-head-sep">
            {this.renderHead()}
            <div className="c-balloon__right">
              <Mount if={this.props.preview !== true}>
                <div
                  className={`c-button-r ${
                    this.serviceIsShared() ? "c-button-r--off" : ""
                  } c-button-r--shared-folder`}
                >
                  <a onClick={this.handleShareButtonClick}>
                    {this.serviceIsShared()
                      ? "共有フォルダから削除"
                      : "共有フォルダに追加"}
                  </a>
                </div>
              </Mount>
              <div className="c-button-b">
                <a onClick={this.handlePrintButtonClick}>印刷</a>
              </div>
              {/*
                            <div className="c-button-g">
                                <a>マイリストに追加</a>
                            </div>
                            */}
            </div>
          </div>
          {this.renderServiceContentTab()}
        </div>
        {this.renderContent()}
        {this.renderReprintComment()}
      </div>
    );
  }

  renderHead() {
    let contents = this.contents();
    if (!contents)
      contents = {
        jigyoshomei: "...",
        serviceShubetsuCategory: "...",
        serviceShubetsu: "...",
      };
    const tags = servicePageTagsOf(contents, this.serviceSubId);
    return (
      <div className="c-card-frame__item-head-main">
        <div className="c-card-frame__item-tag">
          {tags.map((tag) => (
            <div key={`tag:${tag}`} className="c-card-frame__item-tag-item">
              {tag}
            </div>
          ))}
        </div>
        <div className="c-card-frame__item-head-main-title">
          {contents.jigyoshomei}
        </div>
      </div>
    );
  }

  renderServiceContentTab() {
    const props = {
      contents: this.props.serviceContent.contents[
        this.serviceContentType.name
      ],
      id: this.id,
      serviceSubId: this.serviceSubId,
      serviceContentType: this.serviceContentType,
      servicePageType: this.props.type,
      url: this.url,
      history: this.props.history,
    };
    return <ServiceContentTab {...props} />;
  }

  renderContent() {
    const contents = this.contents();
    if (!contents) return null;
    return this.serviceContentType.selector({
      [ServiceContentType.abstract.name]: (
        <ServiceContentTypeAbstract
          contents={contents}
          serviceSubId={this.serviceSubId}
        />
      ),
      [ServiceContentType.detail.name]: (
        <ServiceContentTypeDetail
          contents={contents}
          serviceSubId={this.serviceSubId}
        />
      ),
      [ServiceContentType.photo.name]: (
        <ServiceContentTypePhoto contents={contents} />
      ),
      [ServiceContentType.flier.name]: (
        <ServiceContentTypeFlier contents={contents} />
      ),
      [ServiceContentType.comment.name]: (
        <ServiceContentTypeComment contents={contents} />
      ),
    });
  }

  renderReprintComment() {
    const tabRequires = this.serviceContentType.selector({
      [ServiceContentType.abstract.name]: true,
      [ServiceContentType.detail.name]: true,
    });
    if (!tabRequires) return null;
    const contents = this.contents();
    if (!contents) return null;
    return <ServicePageReprintComment contents={contents} />;
  }
}

ServicePage.propTypes = {
  location: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  serviceContent: PropTypes.object.isRequired,
  sharedFolder: PropTypes.object.isRequired,
  preview: PropTypes.bool,
  type: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePage);

function mapStateToProps(state) {
  return {
    serviceContent: state.serviceContent,
    sharedFolder: state.sharedFolder,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      share: (id, serviceSubId) => dispatch(share(id, serviceSubId)),
      unshare: (id, serviceSubId) => dispatch(unshare(id, serviceSubId)),
      openPrintPage: (id, serviceSubId) => {
        const searchParams = new URLSearchParams();
        if (serviceSubId)
          searchParams.set(Parametername.serviceSubId, serviceSubId);
        window.location.href = `${props.type.printPathname.replace(
          /:id/,
          id
        )}?${searchParams.toString()}`;
      },
    },
  };
}
