import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import {
  MASTER_DATA_BUNDLE_FOR_HOKENGAI,
  withMasterData,
} from "../../../../../state/master-data";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import Textarea from "../../../../../app/ui/form/Textarea";
import StandardSelect from "../../../../../app/ui/form/StandardSelect";
import FormGroupingDeleteButton from "../../../../../app/ui/button/FormGroupingDeleteButton";
import FlatPrimaryButton from "../../../../../app/ui/button/FlatPrimaryButton";

class TaxiOptionForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleTaxiOptionItemAdded = this.handleTaxiOptionItemAdded.bind(this);
    this.handleTaxiOptionItemRemoved = this.handleTaxiOptionItemRemoved.bind(
      this
    );
  }

  handleTaxiOptionItemAdded() {
    this.appendTaxiOptionItemForms({
      taxiOptionKindCode: "",
      name: "",
      detail: "",
      fee: "",
      unit: "",
    });
  }

  handleTaxiOptionItemRemoved(index) {
    this.removeTaxiOptionItemForms(index);
  }

  getTaxiOptionItemForms() {
    const { bindingProps } = this.props;
    return [].concat(bindingProps.form.getByName("taxiOptionItemForms"));
  }

  appendTaxiOptionItemForms(form) {
    const { bindingProps } = this.props;
    const org = this.getTaxiOptionItemForms();
    bindingProps.onChange("taxiOptionItemForms", org.concat([form]));
  }

  removeTaxiOptionItemForms(index) {
    const { bindingProps } = this.props;
    const org = this.getTaxiOptionItemForms();
    bindingProps.onChange(
      "taxiOptionItemForms",
      org.filter((_, i) => i != index)
    );
  }

  render() {
    return (
      <Fragment>
        {this.getTaxiOptionItemForms().map((taxiOption, index) => {
          return this.renderOption(taxiOption, index);
        })}
        {this.renderItemAdd()}
      </Fragment>
    );
  }

  renderItemAdd() {
    return (
      <FlatPrimaryButton onClick={this.handleTaxiOptionItemAdded}>
        項目を追加する
      </FlatPrimaryButton>
    );
  }

  renderOption(taxiOption, index) {
    const { bindingProps, masterData } = this.props;
    const name = `taxiOptionItemForms.` + index;
    return (
      <FormGrouping
        key={index}
        title={
          <Fragment>
            オプション{index + 1}
            <FormGroupingDeleteButton
              onClick={() => this.handleTaxiOptionItemRemoved(index)}
            />
          </Fragment>
        }
      >
        <HorizontalLabelLayout labelText="区分" required>
          <ShortInputWidth>
            <StandardSelect
              name={`${name}.taxiOptionKindCode`}
              blank
              options={masterData.taxiOptionKind}
              {...bindingProps}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="オプション名" required>
          <InputTypeText
            name={`${name}.name`}
            hintText="例）基本介助料"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="オプション詳細">
          <Textarea name={`${name}.detail`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="料金（税込み）" required>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name={`${name}.fee`}
                hintText="0"
                {...bindingProps}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="単位" required>
          <ShortInputWidth>
            <UnitLayout>
              <InputTypeText
                name={`${name}.unit`}
                hintText="例）１回１名"
                {...bindingProps}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

TaxiOptionForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOKENGAI)(TaxiOptionForm);
