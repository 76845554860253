import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import TruncateText from "../../../../../app/ui/text/TruncateText";

const CancelBlock = ({ cancelRyokin }) => {
  const LABEL_LIMIT = 80;
  return (
    <Fragment>
      <HeaderTextBlue text="キャンセル料" />
      <div className="print-body-text_area">
        <TruncateText maxLength={LABEL_LIMIT}>
          {cancelRyokin.cancelRyokin}
        </TruncateText>
      </div>
    </Fragment>
  );
};

CancelBlock.propTypes = {
  cancelRyokin: PropTypes.object.isRequired,
};

export default CancelBlock;
