import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const RyokinkasanTable = ({ contents }) => {
  const {
    blocks: { kyotakukaigoshienjigyoshoRyokinkasan: ryokinkasan },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>介護報酬加算</th>
              <td>{ryokinkasan.kaigohoshukasan.join("、")}</td>
            </tr>
          </tbody>
        </table>

        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.kyotakukaigoshienjigyoshoRyokinkasan
        )}
      </div>
    </Fragment>
  );
};

RyokinkasanTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default RyokinkasanTable;
