import React from "react";
import PropTypes from "prop-types";
import PrintTable from "../../../../app/ui/print/PrintTable";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const ShortstayTable = (props) => {
  const { service } = props;
  const { hokennaiService, shortstay } = service.blocks;

  return (
    <PrintTable {...props}>
      <PrintTableNode width="100px">
        {hokennaiService.jigyoshomei}
        <div className="c-table-frame__updated-at">
          ({service.userUpdatedAt})
        </div>
      </PrintTableNode>
      <PrintTableNode width="90px">
        {hokennaiService.serviceShubetsu}
      </PrintTableNode>
      <PrintTableNode width="150px">{service.jusho}</PrintTableNode>
      <PrintTableNode width="112px">{hokennaiService.tel}</PrintTableNode>
      <PrintTableNode width="60px" center>
        {shortstay.rehabilitation}
      </PrintTableNode>
      <PrintTableNode width="15px" center>
        {shortstay.sogei}
      </PrintTableNode>
      <PrintTableNode width="160px">
        {shortstay.kyoshitsuType.map((it) => (
          <div key={it}>・{it}</div>
        ))}
      </PrintTableNode>
      <PrintTableNode width="188px">
        {shortstay.taiokanoShojo.map((it) => (
          <div key={it}>・{it}</div>
        ))}
        <div>{shortstay.taiokanoShojoEtc ? "　など" : ""}</div>
      </PrintTableNode>
      <PrintTableNode width="35px" center>
        {shortstay.teiin}
      </PrintTableNode>
      <PrintTableNode width="120px">
        {shortstay.tokucho.map((it) => (
          <div key={it}>・{it}</div>
        ))}
      </PrintTableNode>
    </PrintTable>
  );
};

ShortstayTable.propTypes = {
  onServiceButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ShortstayTable;
