import React from "react";
import PropTypes from "prop-types";
import { renderStaffJokinHijokin } from "./StaffTable";

const StaffShokushuTable = ({ shokushu }) => {
  return (
    <div className="p-office-body-table">
      <table>
        <tbody>
          <tr>
            <th>常勤・非常勤別</th>
            <td>{renderStaffJokinHijokin(shokushu.jokinHijokin)}</td>
          </tr>
          <tr>
            <th>常勤換算</th>
            <td>{shokushu.jokinkansan}</td>
          </tr>
          <tr>
            <th>経験年数別従業員数</th>
            <td className="val">
              <ul>
                <li>
                  <div className="val-name">1年未満</div>
                  <div className="val-val">{shokushu.keiken1miman}</div>
                </li>
                <li>
                  <div className="val-name">～3年未満</div>
                  <div className="val-val">{shokushu.keiken1ijo3miman}</div>
                </li>
                <li>
                  <div className="val-name">～5年未満</div>
                  <div className="val-val">{shokushu.keiken3ijo5miman}</div>
                </li>
                <li>
                  <div className="val-name">～10年未満</div>
                  <div className="val-val">{shokushu.keiken5ijo10miman}</div>
                </li>
                <li>
                  <div className="val-name">～10年以上</div>
                  <div className="val-val">{shokushu.keiken10ijo}</div>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

StaffShokushuTable.propTypes = {
  shokushu: PropTypes.object.isRequired,
};

export default StaffShokushuTable;
