import React from "react";
import PropTypes from "prop-types";
import { servicePageTagsOf } from "../../service/ServicePageExtraTags";
import Mount from "../../../../app/ui/layout/Mount";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";

const CardHead = (props) => {
  const {
    onSelect: select,
    onServiceButtonClick: handleServiceButtonClick,
    selected,
    service,
  } = props;
  const contents = service
    ? service
    : {
        jigyoshomei: "...",
        serviceShubetsuCategory: "...",
        serviceShubetsu: "...",
      };
  const tags = servicePageTagsOf(contents);
  const id = [ServiceShubetsuType.活動_通いの場]
    .map((it) => it.code)
    .includes(service.serviceShubetsuCode)
    ? service.serviceId
    : service.id;
  const { catchPhrase } = service.blocks.hokennaiService || {};
  return (
    <div className="c-card-frame__item-head">
      <div className="c-card-frame__item-head-radio">
        <label className="c-check">
          <input
            type="checkbox"
            checked={selected}
            onChange={() => select(contents.id)}
          />
          <span className="c-check__box"></span>
        </label>
      </div>
      <div className="c-card-frame__item-head-main">
        <div className="c-card-frame__item-tag">
          {tags.map((tag) => (
            <div key={`tag:${tag}`} className="c-card-frame__item-tag-item">
              {tag}
            </div>
          ))}
        </div>
        <div className="c-card-frame__item-head-main-title">
          <a onClick={() => handleServiceButtonClick(id, service.serviceSubId)}>
            {contents.jigyoshomei}
          </a>
        </div>
        <Mount if={catchPhrase}>
          <div className="c-card-frame__item-head-main-catch-phrase">
            {catchPhrase}
          </div>
        </Mount>
      </div>
      <div className="c-card-frame__item-head-date">
        最新更新日：{contents.userUpdatedAt}
        <br />
        印刷回数：{contents.printCount}
        <br />
        <Mount if={contents.canShowComment}>
          コメント数：{contents.commentCount}
          <br />
        </Mount>
      </div>
    </div>
  );
};

CardHead.propTypes = {
  onSelect: PropTypes.func.isRequired,
  onServiceButtonClick: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  service: PropTypes.object,
};

export default CardHead;
