import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";
import Parametername from "../../app/naming/Parametername";
import { openMessage } from "../../state/notification-message/actions";
import { raiseErrors, reset } from "../../state/input-feedback/actions";
import { setMaintenance } from "../../state/maintenance/actions";
import Pathname from "../../app/naming/Pathname";
import { push } from "react-router-redux";

export function request(id) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REQUEST,
    });
    getPhotos(dispatch, id);
  };
}

export function uploadFiles(files, callback) {
  return (dispatch) => {
    if (files.find((it) => it.name.match(/\.(jpg|jpeg|png|gif|bmp)$/i))) {
  　  const form = new FormData();
  　  files.forEach((file) => form.append(Parametername.files, file));
  　  postToUploadFiles(dispatch, form, callback);
    } else {
      dispatch(
        openMessage("写真以外はアップロードできません")
      );
      setTimeout(callback);
    }
  }
}

export function changeSort(id, index, callback = () => {}) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_SORT,
      payload: {
        id,
        index,
      },
    });
    setTimeout(callback);
  };
}

export function changeForm(name, value, callback = () => {}) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_FORM,
      payload: { name, value },
    });
    setTimeout(callback);
  };
}

export function submitToEditPhotoCollective(id, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToEditPhotoCollective(dispatch, id, form, callback);
  };
}

export function removePhoto(serviceId, fileId) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMOVE,
      payload: {
        fileId,
      },
    });
  };
}

function getPhotos(dispatch, id) {
  const url = ApiPath.api_jigyosho_id_photos_collective.replace(/:id/, id);
  get(dispatch, url);
}

function get(dispatch, url) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        list: json,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function postToUploadFiles(dispatch, form, callback) {
  const url = ApiPath.api_io_files;
  postFile(dispatch, url, form, callback, "写真をアップロードしました。");
}

function postFile(dispatch, url, form, callback = () => {}, message) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.UPLOAD,
      payload: {
        file: json,
      },
    });
    setTimeout(() => callback(json));
  };
  const onFailure = () => {};
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}

function postToEditPhotoCollective(dispatch, id, form, callback) {
  const url = ApiPath.api_jigyosho_id_photos_collective.replace(/:id/, id);
  post(dispatch, url, form, callback, null, "写真を更新しました。");
}

function post(
  dispatch,
  url,
  form,
  callbackOnSuccess,
  callbackOnFailure,
  message
) {
  const noop = () => {};
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(callbackOnSuccess || noop);
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
    setTimeout(callbackOnFailure || noop);
  };
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(photoResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}

function photoResponseHandler(dispatch, onSuccess, onFailure = () => {}) {
  return {
    // for HTTP status code 200
    onSuccess: (json) => {
      dispatch(reset());
      onSuccess(json);
    },
    // for HTTP status code 4xx
    onFailure: (json) => {
      const msg = Object.keys(json).find((it) => it.match(/categoryCode$/g))
        ? "カテゴリ未選択の写真があります。カテゴリを選択してください。"
        : "入力に誤りがあります。ご確認ください。";
      dispatch(openMessage(msg));
      dispatch(raiseErrors(json));
      onFailure(json);
    },
    // for HTTP status code 503
    onMaintenance: (json) => {
      dispatch(setMaintenance(json.message));
      dispatch(push(Pathname.maintenance));
    },
    // for HTTP status code 5xx excludes 503
    onError: () => {
      dispatch(
        openMessage(
          "システムにエラーがありました。恐れ入りますがシステム管理者までお問い合わせください。"
        )
      );
    },
  };
}
