import React from "react";
import PropTypes from "prop-types";
import PrintTable from "../../../../app/ui/print/PrintTable";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const KatsudoKayoinobaTable = (props) => {
  const { service } = props;
  const { hokengaiServiceKatsudoKayoinoba: hokengaiService } = service.blocks;

  return (
    <PrintTable {...props}>
      <PrintTableNode width="105px">
        {hokengaiService.jigyoshomei}
        <div className="c-table-frame__updated-at">
          ({service.userUpdatedAt})
        </div>
      </PrintTableNode>
      <PrintTableNode width="150px">{service.jusho}</PrintTableNode>
      <PrintTableNode width="112px">
        {hokengaiService.denwabango}
      </PrintTableNode>
      <PrintTableNode width="145px">
        {hokengaiService.businessMenu}
      </PrintTableNode>
      <PrintTableNode width="90px" center>
        {hokengaiService.eigyobi.join("・")}
      </PrintTableNode>
      <PrintTableNode width="120px" center>
        {hokengaiService.eigyojikan}
      </PrintTableNode>
      <PrintTableNode width="125px" center>
        {serviceTeikyochiiki(hokengaiService)}
      </PrintTableNode>
      <PrintTableNode width="100px" center>
        {hokengaiService.ryokin.map(itemizeMapper)}
      </PrintTableNode>
    </PrintTable>
  );
};

function serviceTeikyochiiki(hokengaiService) {
  const LABEL_LIMIT = 49;
  let serviceTeikyochiiki = hokengaiService.serviceTeikyochiiki
    .split("\n")
    .join(" ");
  serviceTeikyochiiki =
    serviceTeikyochiiki && serviceTeikyochiiki.length > LABEL_LIMIT
      ? serviceTeikyochiiki.substr(0, LABEL_LIMIT) + "..."
      : serviceTeikyochiiki;

  return serviceTeikyochiiki;
}

function itemizeMapper(term, index) {
  return (
    <div key={index}>{term[0] != "" ? `${term[1]}/${term[0]}` : term[1]}</div>
  );
}

KatsudoKayoinobaTable.propTypes = {
  onServiceButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default KatsudoKayoinobaTable;
