import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const ServiceTeikyoJikanTable = ({ contents }) => {
  const {
    blocks: { homonkeiServiceTeikyoJikan: eigyoJikan },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>平日</th>
              <td>{eigyoJikan.heijitsu.join(" 〜 ")}</td>
            </tr>
            <tr>
              <th>土曜</th>
              <td>{eigyoJikan.doyo.join(" 〜 ")}</td>
            </tr>
            <tr>
              <th>日曜</th>
              <td>{eigyoJikan.nichiyo.join(" 〜 ")}</td>
            </tr>
            <tr>
              <th>祝日</th>
              <td>{eigyoJikan.shukujitsu.join(" 〜 ")}</td>
            </tr>
          </tbody>
        </table>

        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.homonkeiServiceEigyoJikan
        )}
      </div>
    </Fragment>
  );
};

ServiceTeikyoJikanTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default ServiceTeikyoJikanTable;
