import React from "react";
import PropTypes from "prop-types";
import styles from "./JigyoshoStatusButton.css";
import { css } from "aphrodite";
import { removePropertyFromProps } from "../../../../app/fundamental/react/props";

class JigyoshoStatusButton extends React.Component {
  containerProps() {
    let props = { ...this.props };
    props = removePropertyFromProps("children", props);
    props = removePropertyFromProps("status", props);
    return props;
  }

  signalClassName() {
    let csss = [css(styles.signal)];
    if (this.props.status === "green") csss = csss.concat([css(styles.green)]);
    if (this.props.status === "red") csss = csss.concat([css(styles.red)]);
    if (this.props.status === "yellow")
      csss = csss.concat([css(styles.yellow)]);
    return csss.join(" ");
  }

  render() {
    return (
      <div className={css(styles.button)} {...this.containerProps()}>
        <div className={css(styles.inner)}>{this.renderLabel()}</div>
      </div>
    );
  }

  renderLabel() {
    return (
      <div className={css(styles.label)}>
        <span className={this.signalClassName()}>●</span>
        <br />
        {this.props.children}
      </div>
    );
  }
}

JigyoshoStatusButton.propTypes = {
  children: PropTypes.node,
  status: PropTypes.string.isRequired,
};

export default JigyoshoStatusButton;
