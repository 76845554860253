import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";

/**
 * 訪問系サービス備品フォーム.
 */
class HomonkeiServiceBihinForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Fragment>{this.renderHomonkeiServiceBihin()}</Fragment>;
  }

  renderHomonkeiServiceBihin() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="備品">
        <HorizontalLabelLayout labelText="">
          <OptionsCheckbox
            name="items"
            options={masterData.homonkeiService_bihin}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HomonkeiServiceBihinForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceBihinForm
);
