import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  requestJigyoshoListBySearch,
  select,
} from "../../../../state/job-offer-jigyosho-list/actions";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import { withInputFeedback } from "../../../../app/ui/form";
import SearchComparator from "../../../../app/util/SearchComparator";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import JigyoshoListPaginator from "../../jigyosho/list/JigyoshoListPaginator";
import FixedTable from "../../../../app/ui/table/FixedTable";
import SmallPaddingTableCell from "../../../../app/ui/table/SmallPaddingTableCell";
import { Paper, TableBody, TableHead, TableRow } from "@material-ui/core";
import Right from "../../../../app/ui/layout/Right";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AddIcon from "@material-ui/icons/Add";
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import JigyoshoListCondition from "./JobOfferListCondition";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import Mount from "../../../../app/ui/layout/Mount";
import JobOfferStatusChip from "../ui/JobOfferStatusChip";
import Parametername from "../../../../app/naming/Parametername";

class JobOfferListPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleJobOfferClick = this.handleJobOfferClick.bind(this);
    this.handleJobOfferAddButtonClick = this.handleJobOfferAddButtonClick.bind(
      this
    );
    this.handleJigyoshoEditButtonClick = this.handleJigyoshoEditButtonClick.bind(
      this
    );
    this.handleJigyoshoPhotoEditButtonClick = this.handleJigyoshoPhotoEditButtonClick.bind(
      this
    );
    this.handleJobOfferDetailButtonClick = this.handleJobOfferDetailButtonClick.bind(
      this
    );
  }

  componentDidMount() {
    this.props.loadJigyosho(this.props.jobOfferJigyoshoList.search);
  }

  handleJobOfferClick(id) {
    this.props.gotoJobOfferDetailPage(id);
  }

  handleJobOfferAddButtonClick(jigyoshoId) {
    this.props.gotoJobOfferAddPage(jigyoshoId);
  }

  handleJigyoshoEditButtonClick(event, jigyoshoId) {
    event.stopPropagation();
    this.props.gotoJobOfferJigyoshoEditPage(jigyoshoId);
  }

  handleJigyoshoPhotoEditButtonClick(event, jigyoshoId) {
    event.stopPropagation();
    this.props.gotoJobOfferJigyoshoPhotoEditPage(jigyoshoId);
  }

  handleJobOfferDetailButtonClick(event, id) {
    event.stopPropagation();
    this.props.gotoJobOfferDetailPage(id);
  }

  render() {
    return (
      <Fragment>
        <Boundary>
          <SideMargin>
            {this.renderHead()}
            {this.renderCondition()}
            {this.renderList()}
          </SideMargin>
        </Boundary>
      </Fragment>
    );
  }

  renderHead() {
    return <BackOfficeHead>求人一覧</BackOfficeHead>;
  }

  renderCondition() {
    const { user } = this.props.authentication;
    if (!user.canAddJigyosho()) return null;

    const {
      condition,
      serviceShubetsuOptions,
      statusCodeOptions,
    } = this.props.jobOfferJigyoshoList;
    const props = {
      condition,
      serviceShubetsuOptions,
      statusCodeOptions,
      user,
    };
    return <JigyoshoListCondition {...props} />;
  }

  renderList() {
    if (this.props.jobOfferJigyoshoList.listIsLoading)
      return <CenteredCircularProgress />;
    return (
      <Fragment>
        {this.renderTable()}
        {this.renderPaginator()}
      </Fragment>
    );
  }

  renderTable() {
    const { user } = this.props.authentication;
    const { list } = this.props.jobOfferJigyoshoList;

    return (
      <Paper>
        <FixedTable>
          <TableHead>
            <TableRow>
              <SmallPaddingTableCell>
                事業所名
                <br />
                {user.hasBackOfficeHojinMenu() ? "（法人名）" : ""}
              </SmallPaddingTableCell>
              <SmallPaddingTableCell style={{ width: "130px" }}>
                職種
              </SmallPaddingTableCell>
              <SmallPaddingTableCell style={{ width: "130px" }}>
                雇用形態
              </SmallPaddingTableCell>
              <SmallPaddingTableCell style={{ width: "100px" }}>
                ステータス
              </SmallPaddingTableCell>
              <SmallPaddingTableCell style={{ width: "100px" }}>
                掲載期間
              </SmallPaddingTableCell>
              <SmallPaddingTableCell style={{ width: "48px" }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((jigyosho) => this.renderJigyoshoRow(jigyosho))}
          </TableBody>
        </FixedTable>
      </Paper>
    );
  }

  renderJigyoshoRow(jigyosho) {
    if (jigyosho.jobOffers.length === 0) {
      return (
        <Fragment key={jigyosho.id}>
          <TableRow>
            {this.renderJigyoshoInfoCell(jigyosho)}
            {this.renderAddJobOfferCell(jigyosho)}
          </TableRow>
        </Fragment>
      );
    } else {
      return (
        <Fragment key={jigyosho.id}>
          {jigyosho.jobOffers.map((jobOffer, index) => (
            <TableRow key={jobOffer.id}>
              <Mount if={index === 0}>
                {this.renderJigyoshoInfoCell(jigyosho)}
              </Mount>
              <SmallPaddingTableCell>{jobOffer.jobType}</SmallPaddingTableCell>
              <SmallPaddingTableCell>
                {jobOffer.jobContract}
              </SmallPaddingTableCell>
              <SmallPaddingTableCell>
                <JobOfferStatusChip status={jobOffer.status} />
              </SmallPaddingTableCell>
              <SmallPaddingTableCell>
                <Mount if={jobOffer.publishStart || jobOffer.publishEnd}>
                  {jobOffer.publishStart} 〜<br />
                  {jobOffer.publishEnd}
                </Mount>
              </SmallPaddingTableCell>
              <SmallPaddingTableCell>
                <Right>
                  <IconButton
                    onClick={(e) =>
                      this.handleJobOfferDetailButtonClick(e, jobOffer.id)
                    }
                  >
                    <RightIcon />
                  </IconButton>
                </Right>
              </SmallPaddingTableCell>
            </TableRow>
          ))}

          <TableRow>{this.renderAddJobOfferCell(jigyosho)}</TableRow>
        </Fragment>
      );
    }
  }

  renderJigyoshoInfoCell(jigyosho) {
    const { user } = this.props.authentication;
    const rowSpan = jigyosho.jobOffers.length + 1;

    return (
      <Fragment>
        <SmallPaddingTableCell rowSpan={rowSpan}>
          {jigyosho.jigyoshomei}
          <br />
          {user.hasBackOfficeHojinMenu() ? `（${jigyosho.hojimmei}）` : ""}
          <br />
          <FlatPrimaryButton
            onClick={(event) =>
              this.handleJigyoshoEditButtonClick(event, jigyosho.id)
            }
          >
            共通情報を編集
          </FlatPrimaryButton>
          <FlatPrimaryButton
            onClick={(event) =>
              this.handleJigyoshoPhotoEditButtonClick(event, jigyosho.id)
            }
          >
            写真の設定
          </FlatPrimaryButton>
        </SmallPaddingTableCell>
      </Fragment>
    );
  }

  renderAddJobOfferCell(jigyosho) {
    return (
      <SmallPaddingTableCell colSpan={5}>
        <FlatPrimaryButton
          onClick={() => this.handleJobOfferAddButtonClick(jigyosho.id)}
        >
          <AddIcon /> 求人情報を追加
        </FlatPrimaryButton>
      </SmallPaddingTableCell>
    );
  }

  renderPaginator() {
    const { jobOfferJigyoshoList } = this.props;
    const paginatorProps = {
      prevSearch: jobOfferJigyoshoList.prevSearch,
      nextSearch: jobOfferJigyoshoList.nextSearch,
      pagination: jobOfferJigyoshoList.pagination,
    };
    return <JigyoshoListPaginator {...paginatorProps} />;
  }
}

JobOfferListPage.propTypes = {
  authentication: PropTypes.object.isRequired,
  jobOfferJigyoshoList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  loadJigyosho: PropTypes.func.isRequired,
  gotoJobOfferDetailPage: PropTypes.func.isRequired,
  gotoJobOfferAddPage: PropTypes.func.isRequired,
  gotoJobOfferJigyoshoEditPage: PropTypes.func.isRequired,
  gotoJobOfferJigyoshoPhotoEditPage: PropTypes.func.isRequired,
};

export default withInputFeedback(
  connect(mapStateToProps, mapDispatchToProps)(JobOfferListPage)
);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    jobOfferJigyoshoList: state.jobOfferJigyoshoList,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    loadJigyosho: (search) => {
      const toUpdate = new SearchComparator(
        props.location.search,
        search
      ).different();
      if (toUpdate)
        dispatch(
          requestJigyoshoListBySearch(
            new URLSearchParams(props.location.search).toString()
          )
        );
      dispatch(select(null));
    },
    gotoJobOfferDetailPage: (id) => {
      dispatch(push(Pathname.backoffice_job_offer_id.replace(/:id/, id)));
    },
    gotoJobOfferAddPage: (jigyoshoId) => {
      dispatch(
        push(
          Pathname.backoffice_job_offer_jigyosho_id_add.replace(
            /:jigyoshoId/,
            jigyoshoId
          )
        )
      );
    },
    gotoJobOfferJigyoshoEditPage: (jigyoshoId) => {
      const pathname = window.location.href.substr(
        window.location.protocol.length +
          "//".length +
          window.location.host.length
      );
      const searchParams = new URLSearchParams();
      searchParams.set(Parametername.pathname, pathname);
      dispatch(
        push({
          pathname: Pathname.backoffice_job_offer_jigyosho_id.replace(
            /:jigyoshoId/,
            jigyoshoId
          ),
          search: searchParams.toString(),
        })
      );
    },
    gotoJobOfferJigyoshoPhotoEditPage: (jigyoshoId) => {
      const pathname = window.location.href.substr(
        window.location.protocol.length +
          "//".length +
          window.location.host.length
      );
      const searchParams = new URLSearchParams();
      searchParams.set(Parametername.pathname, pathname);
      dispatch(
        push({
          pathname: Pathname.backoffice_job_offer_jigyosho_id_photos.replace(
            /:jigyoshoId/,
            jigyoshoId
          ),
          search: searchParams.toString(),
        })
      );
    },
  };
}
