function ReducersMap() {}

ReducersMap.prototype.createReducer = function (initialState) {
  return (state = initialState, action) => {
    return this.reduce(state, action);
  };
};

ReducersMap.prototype.reduce = function (state, action) {
  if (!this.hasOwnProperty(action.type)) return state;
  return this[action.type](state, action);
};

export function reducersMapOf(target) {
  Object.setPrototypeOf(target, ReducersMap.prototype);
  return target;
}
