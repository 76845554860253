import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../state/service-content/actions";
import ServiceContentType from "./ServiceContentType";
import ServiceContentTypePrint from "./ServiceContentTypePrint";
import Sheet from "../search/print/Sheet";
import ServicePageReprintComment from "./ServicePageReprintComment";
import Parametername from "../../../app/naming/Parametername";

class ServicePrintPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
  }

  initializeFields(props) {
    const { location } = this.props;
    const { params } = props.match;
    const searchParams = new URLSearchParams(location.search);
    this.serviceSubId = searchParams.get(Parametername.serviceSubId);
    this.id = +params.id;
    this.serviceContentType = ServiceContentType.abstract;
  }

  componentDidMount() {
    const { location, actions, preview } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const serviceSubId = searchParams.get(Parametername.serviceSubId);
    actions.request(this.id, serviceSubId, this.serviceContentType, {
      preview,
      print: true,
    });
  }

  contents() {
    const { serviceContent } = this.props;
    if (serviceContent.contentsIsLoading) return null;
    return this.serviceContentType.selector(serviceContent.contents);
  }

  render() {
    const contents = this.contents();
    if (!contents) return null;
    return (
      <Sheet>
        <ServiceContentTypePrint
          serviceSubId={this.serviceSubId}
          contents={contents}
        />
        <ServicePageReprintComment contents={contents} />
      </Sheet>
    );
  }
}

ServicePrintPage.propTypes = {
  location: PropTypes.object.isRequired,
  actions: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  serviceContent: PropTypes.object.isRequired,
  preview: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(ServicePrintPage);

function mapStateToProps(state) {
  return {
    serviceContent: state.serviceContent,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
