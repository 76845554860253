import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import RyokinMeyasuTable from "./RyokinMeyasuTable";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const RyokinMeyasuTableSet = ({ contents }) => {
  const {
    blocks: { shortstayRyokinMeyasu: ryokinMeyasu },
  } = contents;
  const alone = ryokinMeyasu.kyoshitsuTypeList.length < 2;
  const available = ryokinMeyasu.kyoshitsuTypeList.length > 0;
  return (
    <Fragment>
      <Mount
        if={alone}
        then={() =>
          ryokinMeyasu.kyoshitsuTypeList.map((item, i) => {
            return (
              <div key={i}>
                <RyokinMeyasuTable contents={item} alone />
              </div>
            );
          })
        }
      />
      <Mount
        if={!alone}
        then={() => (
          <div className="p-office-body-flex-wrap">
            {ryokinMeyasu.kyoshitsuTypeList.map((item, i) => {
              return (
                <div className="p-office-body-sep__left" key={i}>
                  <RyokinMeyasuTable contents={item} />
                </div>
              );
            })}
          </div>
        )}
      />

      <Mount if={available}>
        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.shortstayRyokinmeyasu
        )}
      </Mount>
    </Fragment>
  );
};

RyokinMeyasuTableSet.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default RyokinMeyasuTableSet;
