import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../../state/information/actions";
import Parametername from "../../../app/naming/Parametername";
import Pathname from "../../../app/naming/Pathname";
import { Paper } from "@material-ui/core";
import WebFrontNavlessLayout from "../WebFrontNavlessLayout";
import CenteredCircularProgress from "../../../app/ui/progress/CenteredCircularProgress";
import For from "../../../app/ui/layout/For";

class InformationMessageDetail extends React.Component {
  constructor(props) {
    super(props);
    // 初期化
    this.initializeFields(props);
    // 「戻る・キャンセル」クリックイベント
    this.handleBackLinkClick = this.handleBackLinkClick.bind(this);
  }

  // 初期化
  initializeFields(props) {
    const { search } = props.location;
    const searchParams = new URLSearchParams(search);
    this.pathname =
      searchParams.get(Parametername.pathname) || Pathname.webfront;
    const { params } = props.match;
    this.id = +params.id;
  }

  componentDidMount() {
    const { actions } = this.props;
    // お知らせ詳細取得
    actions.requestDetail(this.id);
  }

  // 「戻る・キャンセル」クリックイベント
  handleBackLinkClick() {
    const { actions } = this.props;
    actions.gotoback(this.pathname);
  }

  render() {
    return (
      <WebFrontNavlessLayout>
        <div style={styles.container}>
          {this.renderNavigation()}
          {this.renderDetail()}
          {this.renderNavigation()}
        </div>
      </WebFrontNavlessLayout>
    );
  }

  renderDetail() {
    const { informationForm } = this.props;
    const { details, detailsIsLoading } = informationForm;
    if (detailsIsLoading) return <CenteredCircularProgress />;
    if (!details || details.id !== this.id) return null;
    return (
      <Paper className="c-blue-frame-sharp u-margin-b32" style={styles.paper}>
        <div>
          <div>
            {" "}
            {details.registDate} {this.renderNewFor(details)}
          </div>
          <div style={styles.title}>{details.title}</div>
          <div
            style={styles.detail}
            dangerouslySetInnerHTML={{ __html: details.detail }}
          />
          <For
            in={details.items}
            each={(it, index) => (
              <div key={index} style={styles.imgBlock}>
                <img style={styles.img} src={it.imageUrl} />
              </div>
            )}
          />
        </div>
      </Paper>
    );
  }

  // NEW
  renderNewFor(it) {
    if (!it.newFlag) return null;
    return <span style={styles.new}>NEW</span>;
  }

  // 前のページに戻る
  renderNavigation() {
    return (
      <div className="navigation" style={{ marginBottom: 30 }}>
        <ul>
          <li>
            <a onClick={this.handleBackLinkClick}>前のページに戻る</a>
          </li>
        </ul>
      </div>
    );
  }
}

InformationMessageDetail.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  informationForm: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(InformationMessageDetail)
);

function mapStateToProps(state) {
  return {
    informationForm: state.informationForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoback: (pathname) => dispatch(push(pathname)),
    },
  };
}

const styles = {
  container: {
    position: "relative",
    padding: 16,
  },
  paper: {
    padding: "15px",
    boxShadow: "0px 0px",
    borderRadius: "8px",
  },
  title: {
    fontWeight: "bold",
    fontSize: "large",
    padding: "10px 0px",
    lineHeight: "1.7em",
    borderBottom: "1px solid #dddddd",
  },
  detail: {
    padding: "20px 15px 15px 15px",
    lineHeight: "1.7em",
    whiteSpace: "pre-line",
  },
  new: {
    color: "red",
  },
  imgBlock: {
    padding: "10px 0px",
    textAlign: "center",
  },
  img: {
    maxWidth: "95%",
    width: 800,
  },
};
