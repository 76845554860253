import React, { Fragment } from "react";
import PropTypes from "prop-types";

const Mount = (props) => {
  if (props.if && props.then) return <Fragment>{props.then()}</Fragment>;
  if (props.if) return <Fragment>{props.children}</Fragment>;
  if (props.else) return <Fragment>{props.else()}</Fragment>;
  return null;
};

Mount.propTypes = {
  children: PropTypes.node,
  if: PropTypes.any,
  then: PropTypes.func,
  else: PropTypes.func,
};

export default Mount;
