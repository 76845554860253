import React from "react";
import PropTypes from "prop-types";

const ThWithBg = ({ text, colSpan = 1 }) => {
  return (
    <th colSpan={colSpan}>
      <img src="/images/print_bg_light_blue.png" />
      <span>{text}</span>
    </th>
  );
};

ThWithBg.propTypes = {
  text: PropTypes.node.isRequired,
  colSpan: PropTypes.number,
};

export default ThWithBg;
