const ViewType = {
  tableView: {
    name: "t",
    description: "リストで見る",
    selector: (options) => options.table,
  },
  cardView: {
    name: "c",
    description: "カードで見る",
    selector: (options) => options.card,
  },
  mapView: {
    name: "m",
    description: "マップで見る",
    selector: (options) => options.map,
  },
};

export function viewTypeValueOf(name) {
  return Object.keys(ViewType)
    .map((key) => ViewType[key])
    .find((type) => type.name === name);
}

export function viewTypeValueOfOrDefault(name) {
  const type = viewTypeValueOf(name);
  return type ? type : ViewType.cardView;
}

export default ViewType;
