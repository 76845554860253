import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import TruncateText from "../../../../../app/ui/text/TruncateText";

const RehabilitaionBlock = ({ rehabilitation }) => {
  const LABEL_LIMIT = 130;
  return (
    <Fragment>
      <HeaderTextBlue text="リハビリ機材" />
      <div className="print-body-text_area">
        <TruncateText maxLength={LABEL_LIMIT}>
          {rehabilitation.rehabilitationKizaiList}
        </TruncateText>
      </div>
    </Fragment>
  );
};

RehabilitaionBlock.propTypes = {
  rehabilitation: PropTypes.object.isRequired,
};

export default RehabilitaionBlock;
