import React from "react";
import { mergeStyleToProps } from "../../fundamental/react/props";
import ColorCatalog from "../color/ColorCatalog";

const BackOfficeHelp = (props) => {
  return <div {...mergeStyleToProps(styles.info, props)}></div>;
};

const styles = {
  info: {
    backgroundColor: ColorCatalog.info,
    color: ColorCatalog.info_contrast,
    marginTop: 15,
    padding: 15,
  },
};

export default BackOfficeHelp;
