import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";

class ShortstayGaibuRenkeiForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Fragment>{this.renderShortstayGaibuRenkei()}</Fragment>;
  }

  renderShortstayGaibuRenkei() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="外部連携">
        <HorizontalLabelLayout labelText="協力連携医療機関" recommended>
          <Textarea name="kyoryokuRenkeiIryokikan" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="協力連携歯科医療機関">
          <Textarea name="kyoryokuRenkeiShikaIryokikan" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

ShortstayGaibuRenkeiForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default ShortstayGaibuRenkeiForm;
