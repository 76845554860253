import React from "react";
import PropTypes from "prop-types";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import {
  requestDetail,
  submitToApprovalRequest,
  submitToClose,
  submitToRemove,
  submitToApproved,
} from "../../../../state/job-offer/actions";
import { connect } from "react-redux";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import GridRow from "../../../../app/ui/grid/GridRow";
import GridCell from "../../../../app/ui/grid/GridCell";
import Right from "../../../../app/ui/layout/Right";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import Margin from "../../../../app/ui/layout/Margin";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import RaisedSecondaryButton from "../../../../app/ui/button/RaisedSecondaryButton";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import JobOfferBlock from "./JobOfferBlock";
import JobOfferJigyoshoBlock from "./JobOfferJigyoshoBlock";
import JobOfferHistoriesBlock from "./JobOfferHistoriesBlock";
import Mount from "../../../../app/ui/layout/Mount";

class JobOfferDetailPage extends React.Component {
  constructor(props) {
    super(props);

    this.id = props.match.params.id;

    this.state = {
      removeDialogIsOpen: false,
      approvalRequestDialogIsOpen: false,
      closeDialogIsOpen: false,
      approvedDialogIsOpen: false,
      isDeleteButton: false,
    };

    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleRemoveButtonClick = this.handleRemoveButtonClick.bind(this);
    this.handleRemoveDialogCloseButtonClick = this.handleRemoveDialogCloseButtonClick.bind(
      this
    );
    this.handleRemoveDialogSubmitButtonClick = this.handleRemoveDialogSubmitButtonClick.bind(
      this
    );
    this.handleApprovalRequestButtonClick = this.handleApprovalRequestButtonClick.bind(
      this
    );
    this.handleApprovalRequestDialogCloseButtonClick = this.handleApprovalRequestDialogCloseButtonClick.bind(
      this
    );
    this.handleApprovalRequestDialogSubmitButtonClick = this.handleApprovalRequestDialogSubmitButtonClick.bind(
      this
    );
    this.handleCloseButtonClick = this.handleCloseButtonClick.bind(this);
    this.handleCloseDialogCloseButtonClick = this.handleCloseDialogCloseButtonClick.bind(
      this
    );
    this.handleCloseDialogSubmitButtonClick = this.handleCloseDialogSubmitButtonClick.bind(
      this
    );
    this.handleApprovedButtonClick = this.handleApprovedButtonClick.bind(this);
    this.handleApprovedDialogCloseButtonClick = this.handleApprovedDialogCloseButtonClick.bind(
      this
    );
    this.handleApprovedDialogSubmitButtonClick = this.handleApprovedDialogSubmitButtonClick.bind(
      this
    );
    this.handleRejectButtonClick = this.handleRejectButtonClick.bind(this);
  }

  componentDidMount() {
    this.props.loadData(this.id);
  }

  handleBackButtonClick() {
    this.props.gotoJobOfferListPage(this.props.jobOfferJigyoshoList.search);
  }

  handleRemoveButtonClick() {
    this.setState({ removeDialogIsOpen: true, isDeleteButton: false });
  }

  handleRemoveDialogCloseButtonClick() {
    this.setState({ removeDialogIsOpen: false });
  }

  handleRemoveDialogSubmitButtonClick() {
    this.setState({ removeDialogIsOpen: false, isDeleteButton: true });
    const callback = () => {
      this.handleRemoveDialogCloseButtonClick(
        this.props.jobOfferJigyoshoList.search
      );
      this.props.gotoJobOfferListPage();
    };
    const callbackOnFailure = () => {
      this.props.loadData(this.id);
    };
    this.props.submitToRemove(this.id, callback, callbackOnFailure);
  }

  handleApprovalRequestButtonClick() {
    this.setState({ approvalRequestDialogIsOpen: true });
  }

  handleApprovalRequestDialogCloseButtonClick() {
    this.setState({ approvalRequestDialogIsOpen: false });
  }

  handleApprovalRequestDialogSubmitButtonClick() {
    this.setState({ approvalRequestDialogIsOpen: false });
    const callback = () => {
      this.props.loadData(this.id);
    };
    const callbackOnFailure = () => {
      this.props.loadData(this.id);
    };
    this.props.submitToApprovalRequest(this.id, callback, callbackOnFailure);
  }

  handleCloseButtonClick() {
    this.setState({ closeDialogIsOpen: true });
  }

  handleCloseDialogCloseButtonClick() {
    this.setState({ closeDialogIsOpen: false });
  }

  handleCloseDialogSubmitButtonClick() {
    this.setState({ closeDialogIsOpen: false });
    const callback = () => {
      this.props.loadData(this.id);
    };
    const callbackOnFailure = () => {
      this.props.loadData(this.id);
    };
    this.props.submitToClose(this.id, callback, callbackOnFailure);
  }

  handleApprovedButtonClick() {
    this.setState({ approvedDialogIsOpen: true });
  }

  handleApprovedDialogCloseButtonClick() {
    this.setState({ approvedDialogIsOpen: false });
  }

  handleApprovedDialogSubmitButtonClick() {
    this.setState({ approvedDialogIsOpen: false });
    const callback = () => {
      this.props.loadData(this.id);
    };
    const callbackOnFailure = () => {
      this.props.loadData(this.id);
    };
    this.props.submitToApproved(this.id, callback, callbackOnFailure);
  }

  handleRejectButtonClick() {
    this.props.gotoJobOfferRejectPage(this.id);
  }

  render() {
    const { jobOffer } = this.props;
    const { detail } = jobOffer;
    if (!detail || jobOffer.isLoading) return <CenteredCircularProgress />;

    return (
      <Boundary>
        <SideMargin>
          <BackOfficeHead>求人情報の詳細</BackOfficeHead>

          {this.renderNavigation()}

          <JobOfferBlock />

          <Margin top bottom>
            <JobOfferJigyoshoBlock />
          </Margin>

          <Margin top bottom>
            <JobOfferHistoriesBlock />
          </Margin>

          {this.renderRemoveDialog()}
          {this.renderApprovalRequestDialog()}
          {this.renderCloseDialog()}
          {this.renderApprovedDialog()}
        </SideMargin>
      </Boundary>
    );
  }

  renderNavigation() {
    const { detail } = this.props.jobOffer;
    const { user } = this.props.authentication;

    const isApprovalRequest =
      detail.status == "申請中" && user.canManageJobOffer();

    return (
      <Margin bottom>
        <BackOfficeNavigation>
          <GridRow>
            <GridCell>
              <FlatPrimaryButton onClick={this.handleBackButtonClick}>
                <BackIcon /> 戻る
              </FlatPrimaryButton>
            </GridCell>
            <GridCell>
              <Right>
                <Mount if={detail.deletable}>
                  <SpaceOut>
                    <FlatSecondaryButton onClick={this.handleRemoveButtonClick}>
                      削除する
                    </FlatSecondaryButton>
                  </SpaceOut>
                </Mount>
                <Mount if={detail.approvalRequestable}>
                  <SpaceOut>
                    <RaisedPrimaryButton
                      onClick={this.handleApprovalRequestButtonClick}
                    >
                      この内容で申請する
                    </RaisedPrimaryButton>
                  </SpaceOut>
                </Mount>
                <Mount if={detail.published}>
                  <SpaceOut>
                    <FlatSecondaryButton onClick={this.handleCloseButtonClick}>
                      掲載を終了する
                    </FlatSecondaryButton>
                  </SpaceOut>
                </Mount>
                <Mount if={isApprovalRequest}>
                  <SpaceOut>
                    <RaisedPrimaryButton
                      onClick={this.handleApprovedButtonClick}
                    >
                      承認
                    </RaisedPrimaryButton>
                  </SpaceOut>
                  <SpaceOut>
                    <RaisedSecondaryButton
                      onClick={this.handleRejectButtonClick}
                    >
                      非承認
                    </RaisedSecondaryButton>
                  </SpaceOut>
                </Mount>
              </Right>
            </GridCell>
          </GridRow>
        </BackOfficeNavigation>
      </Margin>
    );
  }

  renderRemoveDialog() {
    return (
      <Dialog
        onClose={this.handleRemoveDialogCloseButtonClick}
        open={this.state.removeDialogIsOpen}
      >
        <DialogTitle>求人情報削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            求人情報を削除します、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton onClick={this.handleRemoveDialogCloseButtonClick}>
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handleRemoveDialogSubmitButtonClick}
            disabled={this.state.isDeleteButton}
          >
            削除する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderApprovalRequestDialog() {
    return (
      <Dialog
        onClose={this.handleApprovalRequestDialogCloseButtonClick}
        open={this.state.approvalRequestDialogIsOpen}
      >
        <DialogTitle>求人情報の申請</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この内容で掲載を申請します、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton
            onClick={this.handleApprovalRequestDialogCloseButtonClick}
          >
            キャンセル
          </FlatPrimaryButton>
          <RaisedPrimaryButton
            onClick={this.handleApprovalRequestDialogSubmitButtonClick}
          >
            申請する
          </RaisedPrimaryButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderCloseDialog() {
    return (
      <Dialog
        onClose={this.handleCloseDialogCloseButtonClick}
        open={this.state.closeDialogIsOpen}
      >
        <DialogTitle>求人掲載の停止</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この求人の掲載を終了します。よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton onClick={this.handleCloseDialogCloseButtonClick}>
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handleCloseDialogSubmitButtonClick}
          >
            終了する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }

  renderApprovedDialog() {
    return (
      <Dialog
        onClose={this.handleApprovedDialogCloseButtonClick}
        open={this.state.approvedDialogIsOpen}
      >
        <DialogTitle>求人情報の承認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            この内容で掲載を承認します、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton
            onClick={this.handleApprovedDialogCloseButtonClick}
          >
            キャンセル
          </FlatPrimaryButton>
          <RaisedPrimaryButton
            onClick={this.handleApprovedDialogSubmitButtonClick}
          >
            承認する
          </RaisedPrimaryButton>
        </DialogActions>
      </Dialog>
    );
  }
}

JobOfferDetailPage.propTypes = {
  match: PropTypes.object.isRequired,
  jobOffer: PropTypes.object.isRequired,
  jobOfferJigyoshoList: PropTypes.object.isRequired,
  loadData: PropTypes.func.isRequired,
  gotoJobOfferListPage: PropTypes.func.isRequired,
  submitToRemove: PropTypes.func.isRequired,
  submitToApprovalRequest: PropTypes.func.isRequired,
  submitToClose: PropTypes.func.isRequired,
  submitToApproved: PropTypes.func.isRequired,
  gotoJobOfferRejectPage: PropTypes.func.isRequired,
  authentication: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    jobOffer: state.jobOffer,
    jobOfferJigyoshoList: state.jobOfferJigyoshoList,
    authentication: state.authentication,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadData: (id) => {
      dispatch(requestDetail(id));
    },
    gotoJobOfferListPage: (search) => {
      const searchParams = new URLSearchParams(search);
      searchParams.set("timestamp", new Date().getTime().toString());
      search = searchParams.toString();
      dispatch(push({ pathname: Pathname.backoffice_job_offer, search }));
    },
    submitToRemove: (id, callback, callbackOnFailure) => {
      dispatch(submitToRemove(id, callback, callbackOnFailure));
    },
    submitToApprovalRequest: (id, callback, callbackOnFailure) => {
      dispatch(submitToApprovalRequest(id, callback, callbackOnFailure));
    },
    submitToClose: (id, callback, callbackOnFailure) => {
      dispatch(submitToClose(id, callback, callbackOnFailure));
    },
    submitToApproved: (id, callback, callbackOnFailure) => {
      dispatch(submitToApproved(id, callback, callbackOnFailure));
    },
    gotoJobOfferRejectPage: (id) => {
      dispatch(
        push(Pathname.backoffice_job_offer_id_reject.replace(/:id/, id))
      );
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobOfferDetailPage);
