import React, { Fragment } from "react";
import PropTypes from "prop-types";

const RecreationTable = ({ recreation, afterTable }) => {
  return (
    <Fragment>
      <div className="c-blue-heading">
        <div className="c-blue-heading__text">レクリエーション・行事</div>
      </div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>アピールしたいレク</th>
              <td>{recreation.appealReku.join("、")}</td>
            </tr>
            <tr>
              <th>外部講師によるレク</th>
              <td>{recreation.gaibukoshiniyoruReku.join("、")}</td>
            </tr>
          </tbody>
        </table>

        {afterTable}
      </div>
    </Fragment>
  );
};

RecreationTable.propTypes = {
  recreation: PropTypes.object.isRequired,
  afterTable: PropTypes.node,
};

export default RecreationTable;
