import { StyleSheet } from "aphrodite";
import ColorCatalog from "../../app/ui/color/ColorCatalog";

const styles = StyleSheet.create({
  headerAndNavigation: {
    backgroundColor: ColorCatalog.white,
  },

  header: {
    height: 64,
  },

  header__container: {
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-between",
    margin: "0 auto",
    maxWidth: 1024,
    paddingRight: 10,
  },

  header__container__left: {},

  header__container__right: {
    lineHeight: "64px",
  },

  logo: {
    margin: "20px auto auto 20px",
    width: 200,
    textAlign: "center",
    letterSpacing: 3,
    position: "relative",
  },

  logoAnchor: {
    textDecoration: "none",
    ":hover": {
      opacity: 0.8,
    },
  },

  footer: {
    boxSizing: "border-box",
    display: "block",
    fontSize: "small",
    justifyContent: "space-between",
    margin: "20px auto 0",
    maxWidth: 1024,
    padding: "0 20px 20px",
  },

  footer__left__item: {
    color: "#23b8ce",
    ":not(:first-child):before": {
      content: '"　"',
    },
  },
  footer__milmo__item: {
    fontSize: 11,
    ":not(:first-child):before": {
      content: '" | "',
    },
  },
  footer__center: {
    marginTop: 20,
    textAlign: "center",
  },

  footer__center__copyrights: {
    marginTop: 6,
    display: "inline",
  },

  footer__center__coworker: {
    display: "inline",
    fontSize: 11,
  },
});

export default styles;
