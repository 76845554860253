import React, { Fragment } from "react";
import PropTypes from "prop-types";

const HorizontalLabelLayout = (props) => {
  return (
    <div style={styles(props).container}>
      <div
        style={{
          ...styles(props).label,
          ...(props.withFloatingText ? styles(props).withFloatingText : {}),
        }}
      >
        {props.required ? <span className="mark--required"></span> : null}
        {props.recommended ? <span className="mark--recommended"></span> : null}
        {props.labelText ? <Fragment>{props.labelText}:</Fragment> : null}
      </div>
      <div style={styles(props).input}>{props.children}</div>
    </div>
  );
};

const styles = (props) => ({
  container: {
    width: "100%",
    marginBottom: 10,
  },
  label: {
    boxSizing: "border-box",
    display: "inline-block",
    fontSize: "small",
    fontWeight: "bold",
    paddingBottom: 10,
    paddingRight: 20,
    paddingTop: 22,
    textAlign: "right",
    verticalAlign: "top",
    width: props.long ? 240 : 200,
  },
  withFloatingText: {
    paddingTop: 38,
  },
  input: {
    display: "inline-block",
    boxSizing: "border-box",
    verticalAlign: "top",
    width: props.long ? "calc(100% - 240px)" : "calc(100% - 200px)",
  },
});

HorizontalLabelLayout.propTypes = {
  children: PropTypes.node,
  long: PropTypes.bool,
  labelText: PropTypes.node,
  required: PropTypes.bool,
  recommended: PropTypes.bool,
  withFloatingText: PropTypes.bool,
};

export default HorizontalLabelLayout;
