import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const SonotaServiceTable = ({ contents }) => {
  const {
    blocks: { shortstaySonotaService: sonotaService },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-section">
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>その他サービス</th>
                <td>{sonotaService.sonotaService.join("、")}</td>
              </tr>
              <tr>
                <th>備考</th>
                <td>{sonotaService.biko}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.shortstaySonotaService
        )}
      </div>
    </Fragment>
  );
};

SonotaServiceTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default SonotaServiceTable;
