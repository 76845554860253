import React from "react";
import PropTypes from "prop-types";
import { GoogleMap, Marker, InfoWindow } from "react-google-maps";
import Mount from "../../../../app/ui/layout/Mount";
import { withGoogleMap } from "../../../../state/google-map";
import MapInfoWindow from "./MapInfoWindow";
import {
  fitBounds,
  initialCenterAndZoom,
  MIN_ZOOM,
} from "../../../../app/fundamental/map/bounds";

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.handleMapMounted = this.handleMapMounted.bind(this);
    this.handleMarkerMouseOver = this.handleMarkerMouseOver.bind(this);
    this.handleMarkerMouseOut = this.handleMarkerMouseOut.bind(this);
    this.handleBoundsChanged = this.handleBoundsChanged.bind(this);

    this.state = {
      initialMapPosition: { center: null, zoom: null },
      mouseOverId: null,
    };
  }

  componentDidMount() {
    this.setInitialMapBounds();
  }

  handleMapMounted(googleMap) {
    this.googleMap = googleMap;
    if (this.props.onGoogleMapMounted) {
      this.props.onGoogleMapMounted(googleMap);
    }
  }

  handleMarkerMouseOver(id) {
    this.setState({ mouseOverId: id });
  }

  handleBoundsChanged() {
    if (this.props.onBoundsChanged) {
      this.props.onBoundsChanged(this.googleMap.getBounds());
    }
  }

  handleMarkerMouseOut() {
    this.setState({ mouseOverId: null });
  }

  setInitialMapBounds() {
    const { center, zoom } = this.props;

    const centerAndZoom = initialCenterAndZoom(
      this.props.services,
      center,
      zoom
    );
    if (centerAndZoom) {
      this.setState({
        initialMapPosition: centerAndZoom,
      });
    } else {
      fitBounds(this.props.services, this.googleMap);
    }
  }

  render() {
    const {
      services,
      onClick,
      onCloseButtonClick,
      onServiceButtonClick,
      serviceIsSelected,
      serviceIsActive,
      onSelect,
    } = this.props;
    const { initialMapPosition } = this.state;

    const googleMapOptions = {
      clickableIcons: false,
      fullscreenControl: false,
      mapTypeControl: false,
      scaleControl: true,
      minZoom: MIN_ZOOM,
    };

    return (
      <GoogleMap
        center={initialMapPosition.center}
        zoom={initialMapPosition.zoom}
        options={googleMapOptions}
        onBoundsChanged={this.handleBoundsChanged}
        ref={this.handleMapMounted}
      >
        {services.map((service, i) => {
          const infoWindowOptions = {
            disableAutoPan: false,
            maxWidth: 320,
          };

          return (
            <Mount if={service.latitude && service.longitude} key={i}>
              <Marker
                key={i}
                position={{ lat: +service.latitude, lng: +service.longitude }}
                onClick={() => onClick(service.id)}
                onMouseOver={() => this.handleMarkerMouseOver(service.id)}
                onMouseOut={() => this.handleMarkerMouseOut(service.id)}
              >
                <Mount if={serviceIsActive(service.id)}>
                  <InfoWindow
                    onCloseClick={onCloseButtonClick}
                    options={infoWindowOptions}
                  >
                    <MapInfoWindow
                      service={service}
                      selected={serviceIsSelected(service.id)}
                      onServiceButtonClick={onServiceButtonClick}
                      onSelect={onSelect}
                    />
                  </InfoWindow>
                </Mount>
                <Mount
                  if={
                    !serviceIsActive(service.id) &&
                    this.state.mouseOverId === service.id
                  }
                >
                  <InfoWindow options={{ disableAutoPan: true }}>
                    <div>{service.jigyoshomei}</div>
                  </InfoWindow>
                </Mount>
              </Marker>
            </Mount>
          );
        })}
      </GoogleMap>
    );
  }
}

Map.propTypes = {
  center: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  }),
  zoom: PropTypes.number,
  services: PropTypes.arrayOf(PropTypes.object).isRequired,
  serviceIsSelected: PropTypes.func.isRequired,
  serviceIsActive: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  onCloseButtonClick: PropTypes.func.isRequired,
  onServiceButtonClick: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onGoogleMapMounted: PropTypes.func,
  onBoundsChanged: PropTypes.func,
};

export default withGoogleMap(Map);
