import React, { Fragment } from "react";
import PropTypes from "prop-types";
import PhotosBlock from "./general/PhotosBlock";
import StaffSummaryTable from "../content/homonkeiService/StaffSummaryTable";
import {
  goToJigyoshoAttributeFormButton,
  gotoPhotoFormButton,
} from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";
import EigyoJikanTable from "../content/homonkeiService/EigyoJikanTable";
import ServiceTeikyoChiikiHohoShudan from "../content/homonkeiService/ServiceTeikyoChiikiHohoShudan";
import ServiceNaiyo from "../content/homonkeiService/ServiceNaiyo";
import Ryokin from "../content/homonkeiService/Ryokin";
import JigyoshoJohoTable from "../content/homonkeiService/JigyoshoJohoTable";

class HomonkeiServiceAbstract extends React.Component {
  render() {
    return (
      <div>
        <div className="p-office-body">
          {this.renderHead()}

          {this.renderCatchPhrase()}

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">{this.renderPhotos()}</div>
            <div className="p-office-body-sep__right">
              {this.renderRinenTokucho()}
            </div>
          </div>

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">
              {this.renderEigyojikan()}
            </div>
            <div className="p-office-body-sep__right">
              {this.renderServiceTeikyoChiikiHohoShudan()}
            </div>
          </div>

          {this.renderServiceNaiyo()}

          {this.renderStaff()}

          {this.renderRyokin()}

          {this.renderJigyoshoJoho()}
        </div>
      </div>
    );
  }

  renderHead() {
    const { contents } = this.props;
    const {
      homonkeiService,
      homonkeiServiceJigyoshoHokennaiJigyoshoJoho: hokennaiService,
      homonkeiServiceEigyoJikan: eigyoJikan,
    } = contents.blocks;

    return (
      <div className="c-dl u-border-bottom-b u-margin-b32">
        <dl className="u-margin-b8">
          <dt>住所</dt>
          <dd>{hokennaiService.jusho}</dd>
          <dt>サービス提供対象</dt>
          <dd>{hokennaiService.serviceTeikyoTaisho.join("、")}</dd>
          <dt>営業日</dt>
          <dd>{eigyoJikan.eigyobi.join("、")}</dd>
          <dt>時間外対応</dt>
          <dd>
            {eigyoJikan.jikangaitaio.map((tj) => tj.join(" ")).join("、")}
          </dd>
          <dt>サービス提供地域</dt>
          <dd>{homonkeiService.serviceTeikyochiiki}</dd>
          <dt>特徴</dt>
          <dd>{homonkeiService.tokucho.join("、")}</dd>
          <dt>特記事項</dt>
          <dd>{hokennaiService.tokkijiko}</dd>
        </dl>

        <div className="p-office-body-partition__right-text">
          最新更新日：{contents.userUpdatedAt}
          <br />
          印刷回数：{contents.printCount}
          <br />
        </div>
      </div>
    );
  }

  renderCatchPhrase() {
    const {
      homonkeiServiceJigyoshoHokennaiJigyoshoJoho: hokennaiJigyoshoJoho,
    } = this.props.contents.blocks;

    return (
      <div className="p-office-body-catch">
        {hokennaiJigyoshoJoho.catchPhrase}
      </div>
    );
  }

  renderPhotos() {
    const { contents } = this.props;
    const photos = contents.blocks.photos;

    return (
      <Fragment>
        <PhotosBlock photos={photos.list} />

        {gotoPhotoFormButton(this.props.contents)}
      </Fragment>
    );
  }

  renderRinenTokucho() {
    const {
      homonkeiServiceJigyoshoHokennaiJigyoshoJoho: hokennaiJigyoshoJoho,
    } = this.props.contents.blocks;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">理念・特徴</div>
        </div>

        <div className="p-office-body-description">
          {hokennaiJigyoshoJoho.tokuchoRinen}
          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.homonkeiServiceJigyosho
          )}
        </div>
      </Fragment>
    );
  }

  renderEigyojikan() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">営業時間</div>
        </div>
        <EigyoJikanTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderServiceTeikyoChiikiHohoShudan() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">サービス提供地域・訪問手段</div>
        </div>
        <ServiceTeikyoChiikiHohoShudan contents={this.props.contents} />
      </Fragment>
    );
  }

  renderServiceNaiyo() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">サービス内容</div>
        </div>
        <ServiceNaiyo contents={this.props.contents} />
      </Fragment>
    );
  }

  renderStaff() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">スタッフ</div>
        </div>
        <StaffSummaryTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderRyokin() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">料金</div>
        </div>
        <Ryokin contents={this.props.contents} />
      </Fragment>
    );
  }

  renderJigyoshoJoho() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">事業所情報</div>
        </div>
        <JigyoshoJohoTable contents={this.props.contents} />
      </Fragment>
    );
  }
}

HomonkeiServiceAbstract.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default HomonkeiServiceAbstract;
