import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ThWithBg from "../general/ThWithBg";

const CareBlock = ({ care }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="ケア情報" />
      <div className="p-office-body-table">
        <div className="p-office-body-title">食事</div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="昼食" />
              <ThWithBg text="おやつ" />
              <ThWithBg text="嚥下食" />
              <ThWithBg text="制限食" />
              <ThWithBg text="栄養士" />
              <ThWithBg text="調理場所" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.chushoku}</td>
              <td>{care.oyatsu}</td>
              <td>{care.engeshokuteikyo}</td>
              <td>{care.seigenshokuteikyo}</td>
              <td>{care.eiyoshizaihi}</td>
              <td>{care.choribasho}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table">
        <div className="p-office-body-title">排泄</div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="車椅子" />
              <ThWithBg text="同性介助" />
              <ThWithBg text="カテーテル管理" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.kurumaisutaio}</td>
              <td>{care.haisetsudoseikaijotaio}</td>
              <td>{care.catheterCare}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table">
        <div className="p-office-body-title">入浴</div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="機械浴" />
              <ThWithBg text="浴槽タイプ" />
              <ThWithBg text="同性介助" />
              <ThWithBg text="浴室の数" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.kikaiyoku}</td>
              <td>{care.yokusoType}</td>
              <td>{care.nyuyokudoseikaijotaio}</td>
              <td>{care.yokushitsukazu}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table">
        <div className="p-office-body-title">医療</div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="導尿" />
              <ThWithBg text="褥瘡" />
              <ThWithBg text="胃ろう" />
              <ThWithBg text="痰吸引" />
              <ThWithBg text="インスリン" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.donyo}</td>
              <td>{care.jokuso}</td>
              <td>{care.iro}</td>
              <td>{care.tankyuin}</td>
              <td>{care.insulinChusha}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

CareBlock.propTypes = {
  care: PropTypes.object.isRequired,
};

export default CareBlock;
