import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as actions from "../../state/authentication/actions";
import Pathname from "../naming/Pathname";

class LogoutPage extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.logout();
  }

  render() {
    return this.redirect();
  }

  redirect() {
    return <Redirect to={Pathname.login} />;
  }
}

LogoutPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(LogoutPage);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
