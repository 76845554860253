import React from "react";
import PropTypes from "prop-types";
import { serviceShubetsuCategoryContentViewTypeOf } from "./ServiceShubetsuCategoryContentViewType";

class ServiceContentTypePrint extends React.Component {
  render() {
    const { serviceShubetsuCategoryCode } = this.props.contents;
    const viewType = serviceShubetsuCategoryContentViewTypeOf(
      serviceShubetsuCategoryCode
    );
    const Component = viewType.print;
    return (
      <Component
        serviceSubId={this.props.serviceSubId}
        contents={this.props.contents}
      />
    );
  }
}

ServiceContentTypePrint.propTypes = {
  serviceSubId: PropTypes.string,
  contents: PropTypes.object.isRequired,
};

export default ServiceContentTypePrint;
