const HokenTekiyoType = {
  保険内: { isHokennai: () => true, isHokengai: () => false },
  保険外: { isHokennai: () => false, isHokengai: () => true },
};

export function hokenTekiyoTypeValueOf(name) {
  return HokenTekiyoType[name];
}

export default HokenTekiyoType;
