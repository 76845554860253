import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";

const IconButtonTrailingLayout = (props) => {
  const { color, disabled, onClick } = props;
  const iconButtonProps = {
    color,
    disabled,
    onClick,
  };
  return (
    <div>
      <div style={styles(props).input}>{props.children}</div>
      <div style={{ ...styles(props).icon }}>
        {
          <IconButton {...iconButtonProps} onClick={props.onClick}>
            {props.icon}
          </IconButton>
        }
      </div>
    </div>
  );
};

const styles = () => ({
  icon: {
    display: "inline-block",
    paddingTop: 8,
  },
  input: {
    display: "inline-block",
    boxSizing: "border-box",
    verticalAlign: "top",
  },
});

IconButtonTrailingLayout.propTypes = {
  icon: PropTypes.node.isRequired,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
};

export default IconButtonTrailingLayout;
