import fetch from "isomorphic-fetch";
import JwtTokenRepository from "./JwtTokenRepository";
import AuthorizationRefreshRequest from "./AuthorizationRefreshRequest";
import StatusCode from "./StatusCode";

class AuthorizationRequiredRequestFile {
  constructor(url, method, headers, body) {
    this.url = url;
    this.method = method;
    this.headers = headers;
    this.body = body;
  }

  request(responseHandler, unauthorizedHandler, tokenRefreshed = false) {
    const token = new JwtTokenRepository().restore();
    if (!token) {
      unauthorizedHandler();
      return;
    }

    const options = {
      method: this.method,
      headers: {
        ...this.headers,
        ...token.toHeaderObject(),
      },
      credentials: "omit",
    };
    if (this.body) options.body = this.body;
    fetch(this.url, options)
      .then((response) => {
        const statusCode = new StatusCode(response.status);
        if (statusCode.isOK()) {
          var fileName = "";
          const disposition = response.headers.get("Content-Disposition");
          if (disposition) {
            let reg = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = reg.exec(disposition);
            if (matches != null && matches[1]) {
              fileName = decodeURIComponent(matches[1].replace(/['"]/g, ""));
            }
          }
          response.blob().then((blob) => {
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            if (fileName.length > 0) {
              link.download = fileName;
            }
            link.click();
          });
        } else if (statusCode.isServiceUnavailable()) {
          response.json().then((json) => responseHandler.onMaintenance(json));
        } else if (statusCode.isBadRequest()) {
          response.json().then((json) => responseHandler.onFailure(json));
        } else if (statusCode.isUnauthorized()) {
          if (tokenRefreshed) {
            unauthorizedHandler();
          } else {
            new AuthorizationRefreshRequest()
              .setErrorHandler(this.errorHandler)
              .request(() => {
                this.request(responseHandler, unauthorizedHandler, true);
              }, unauthorizedHandler);
          }
        } else if (statusCode.isServerError()) {
          responseHandler.onError();
        }
      })
      .catch(this.errorHandler);
  }

  errorHandler(e) {
    console.error(e);
  }

  setErrorHandler(handler) {
    this.errorHandler = handler;
    return this;
  }

  static get(url) {
    const headers = {};
    const body = null;
    return new AuthorizationRequiredRequestFile(url, "get", headers, body);
  }

  static post(url, body = {}) {
    const bodyIsFormData =
      Object.prototype.toString.call(body) === "[object FormData]";
    const headers = bodyIsFormData
      ? {}
      : { "Content-Type": "application/json" };
    const realBody = bodyIsFormData ? body : JSON.stringify(body);
    return new AuthorizationRequiredRequestFile(url, "post", headers, realBody);
  }

  static delete(url) {
    const headers = { "X-HTTP-Method-Override": "DELETE" };
    const body = null;
    return new AuthorizationRequiredRequestFile(url, "post", headers, body);
  }
}

export default AuthorizationRequiredRequestFile;
