import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import { formDataOf } from "../../../app/fundamental/data/FormData";

const initialState = {
  form: formDataOf({}),
  formIsLoading: false,
  formIsSubmitting: false,
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.RESET]: function () {
    return initialState;
  },
  [ActionType.REQUEST]: function (state) {
    return {
      ...state,
      formIsLoading: true,
    };
  },
  [ActionType.REQUEST_FINISHED]: function (state, action) {
    return {
      ...state,
      formIsLoading: false,
      form: formDataOf(action.payload.form).setType(action.payload.type),
    };
  },
  [ActionType.CHANGE_FORM]: function (state, action) {
    return {
      ...state,
      form: state.form.setByName(action.payload.name, action.payload.value),
    };
  },
  [ActionType.SUBMIT]: function (state) {
    return {
      ...state,
      formIsSubmitting: true,
    };
  },
  [ActionType.SUBMIT_FINISHED]: function (state) {
    return {
      ...state,
      formIsSubmitting: false,
    };
  },
  [ActionType.SUBMIT_FAILED]: function (state) {
    return {
      ...state,
      formIsSubmitting: false,
    };
  },
});

export default reducers.createReducer(initialState);
