import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ThWithBg from "../general/ThWithBg";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

const KaigoHosyuKasanBlock = ({ kaigohoshukasan, serviceShubetsuType }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="介護報酬加算" />
      <div className="p-office-body-table-dotted">
        <Mount
          if={serviceShubetsuType === ServiceShubetsuType.通所介護}
          then={() => (
            <KaigoHosyuKasanBlock_tsushokaigo
              kaigohoshukasan={kaigohoshukasan}
            />
          )}
        />
        <Mount
          if={serviceShubetsuType === ServiceShubetsuType.認知症対応型通所介護}
          then={() => (
            <KaigoHosyuKasanBlock_ninchishotaio
              kaigohoshukasan={kaigohoshukasan}
            />
          )}
        />
        <Mount
          if={
            serviceShubetsuType === ServiceShubetsuType.通所リハビリテーション
          }
          then={() => (
            <KaigoHosyuKasanBlock_rehabilitation
              kaigohoshukasan={kaigohoshukasan}
            />
          )}
        />
        <Mount
          if={serviceShubetsuType === ServiceShubetsuType.療養通所介護}
          then={() => (
            <KaigoHosyuKasanBlock_ryoyo kaigohoshukasan={kaigohoshukasan} />
          )}
        />
        <Mount
          if={serviceShubetsuType === ServiceShubetsuType.地域密着型通所介護}
          then={() => (
            <KaigoHosyuKasanBlock_chiikimitchaku
              kaigohoshukasan={kaigohoshukasan}
            />
          )}
        />
      </div>
    </Fragment>
  );
};

// 通所介護
const KaigoHosyuKasanBlock_tsushokaigo = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <table>
      <thead>
        <tr>
          <ThWithBg text="要介護＋予防" colSpan={2} />
          <ThWithBg text="要介護のみ" colSpan={2} />
          <ThWithBg text="予防のみ" colSpan={2} />
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>サービス提供体制強化加算</th>
          <td>{kaigohoshu.serviceTeikyotaiseikyokakasan}</td>
          <th>個別機能訓練加算</th>
          <td>{kaigohoshu.kobetsukinokunrenkasan}</td>
          <th>生活機能向上グループ活動加算</th>
          <td>{kaigohoshu.seikatsukinokojoGroupKatsudokasan}</td>
        </tr>
        <tr>
          <th>介護職員処遇改善加算</th>
          <td>{kaigohoshu.kaigoshokuinshogukaizenkasan}</td>
          <th>入浴介助加算</th>
          <td>{kaigohoshu.nyuyokukaijokasan}</td>
          <th>運動器機能向上体制加算</th>
          <td>{kaigohoshu.undokinokojotaiseikasan}</td>
        </tr>
        <tr>
          <th>栄養改善加算</th>
          <td>{kaigohoshu.eiyokaizentaiseikasan}</td>
          {/* 正しくは「中重度者ケア体制加算」だが、レイアウトの都合上 名称変更する */}
          <th>中重度ケア体制加算</th>
          <td>{kaigohoshu.chujudoshaCareTaiseikasan}</td>
          <th>選択的サービス複数実施加算</th>
          <td>{kaigohoshu.sentakutekiServiceFukusujisikasan}</td>
        </tr>
        <tr>
          <th>口腔機能向上加算</th>
          <td>{kaigohoshu.kokukinokojotaiseikasan}</td>
          <th>認知症加算</th>
          <td>{kaigohoshu.ninchishokasan}</td>
          <th>事業所評価加算</th>
          <td>{kaigohoshu.jigyoshohyokakasan}</td>
        </tr>
      </tbody>
    </table>
  );
};

// 認知症対応型通所介護
const KaigoHosyuKasanBlock_ninchishotaio = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <table>
      <thead>
        <tr>
          <ThWithBg text="要介護＋予防" colSpan={4} />
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>サービス提供体制強化加算</th>
          <td>{kaigohoshu.serviceTeikyotaiseikyokakasan}</td>
          <th>口腔機能向上加算</th>
          <td>{kaigohoshu.kokukinokojotaiseikasan}</td>
        </tr>
        <tr>
          <th>介護職員処遇改善加算</th>
          <td>{kaigohoshu.kaigoshokuinshogukaizenkasan}</td>
          <th>個別機能訓練加算</th>
          <td>{kaigohoshu.kobetsukinokunrenkasan}</td>
        </tr>
        <tr>
          <th>栄養改善加算</th>
          <td>{kaigohoshu.eiyokaizentaiseikasan}</td>
          <th>生活機能向上連携加算</th>
          <td>{kaigohoshu.seikatsukinokojorenkeikasan}</td>
        </tr>
      </tbody>
    </table>
  );
};

// 通所リハビリテーション
const KaigoHosyuKasanBlock_rehabilitation = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <table>
      <thead>
        <tr>
          <ThWithBg text="要介護＋予防" colSpan={2} />
          <ThWithBg text="要介護のみ" colSpan={2} />
          <ThWithBg text="予防のみ" colSpan={2} />
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>サービス提供体制強化加算</th>
          <td>{kaigohoshu.serviceTeikyotaiseikyokakasan}</td>
          <th>入浴介助加算</th>
          <td>{kaigohoshu.nyuyokukaijokasan}</td>
          <th>運動器機能向上体制加算</th>
          <td>{kaigohoshu.undokinokojotaiseikasan}</td>
        </tr>
        <tr>
          <th>介護職員処遇改善加算</th>
          <td>{kaigohoshu.kaigoshokuinshogukaizenkasan}</td>
          <th>中重度者ケア体制加算</th>
          <td>{kaigohoshu.chujudoshaCareTaiseikasan}</td>
          <th>選択的サービス複数実施加算</th>
          <td>{kaigohoshu.sentakutekiServiceFukusujisikasan}</td>
        </tr>
        <tr>
          <th></th>
          <td></td>
          <th>移行支援加算</th>
          <td>{kaigohoshu.ikoshien}</td>
          <th>事業所評価加算</th>
          <td>{kaigohoshu.jigyoshohyokakasan}</td>
        </tr>
        <tr>
          <th></th>
          <td></td>
          <th>リハビリ提供体制加算</th>
          <td>{kaigohoshu.rehabilitationTeikyotaisei}</td>
          <th></th>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

// 療養通所介護
const KaigoHosyuKasanBlock_ryoyo = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <table>
      <tbody>
        <tr>
          <th>サービス提供体制強化加算</th>
          <td>{kaigohoshu.serviceTeikyotaiseikyokakasan}</td>
          <th>個別機能訓練加算</th>
          <td>{kaigohoshu.kobetsukinokunrenkasan}</td>
        </tr>
        <tr>
          <th>栄養改善加算</th>
          <td>{kaigohoshu.eiyokaizentaiseikasan}</td>
          <th>中重度者ケア体制加算</th>
          <td>{kaigohoshu.chujudoshaCareTaiseikasan}</td>
        </tr>
        <tr>
          <th>口腔機能向上加算</th>
          <td>{kaigohoshu.kokukinokojotaiseikasan}</td>
          <th>認知症加算</th>
          <td>{kaigohoshu.ninchishokasan}</td>
        </tr>
      </tbody>
    </table>
  );
};

// 地域密着型通所介護
const KaigoHosyuKasanBlock_chiikimitchaku = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <table>
      <tbody>
        <tr>
          <th>サービス提供体制強化加算</th>
          <td>{kaigohoshu.serviceTeikyotaiseikyokakasan}</td>
          <th>個別機能訓練加算</th>
          <td>{kaigohoshu.kobetsukinokunrenkasan}</td>
        </tr>
        <tr>
          <th>栄養改善加算</th>
          <td>{kaigohoshu.eiyokaizentaiseikasan}</td>
          <th>中重度者ケア体制加算</th>
          <td>{kaigohoshu.chujudoshaCareTaiseikasan}</td>
        </tr>
        <tr>
          <th>口腔機能向上加算</th>
          <td>{kaigohoshu.kokukinokojotaiseikasan}</td>
          <th>認知症加算</th>
          <td>{kaigohoshu.ninchishokasan}</td>
        </tr>
      </tbody>
    </table>
  );
};

KaigoHosyuKasanBlock.propTypes = {
  kaigohoshukasan: PropTypes.object.isRequired,
  serviceShubetsuType: PropTypes.object.isRequired,
};

export default KaigoHosyuKasanBlock;
