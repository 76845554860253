import React from "react";
import PropTypes from "prop-types";
import Thumbnail from "../Thumbnail";
import CardHead from "./CardHead";
import CardFoot from "./CardFoot";
import CardFrameItem from "./CardFrameItem";
import Mount from "../../../../app/ui/layout/Mount";

const ShortstayCard = (props) => {
  const { service, selected, onSelect, shared } = props;
  const { hokennaiService, shortstay } = service.blocks;
  return (
    <div
      className={`c-card-frame__item ${
        selected ? "c-card-frame__item--y" : ""
      }`}
    >
      <CardHead
        onSelect={onSelect}
        onServiceButtonClick={props.onServiceButtonClick}
        selected={selected}
        service={service}
      />
      <div className="c-card-frame__item-body">
        <div className="c-card-frame__item-body-left">
          <div className="c-card-frame-picture">
            <a onClick={() => props.onServiceButtonClick(service.id)}>
              <Thumbnail src={service.thumbnail} />
            </a>
          </div>
        </div>
        <div className="c-card-frame__item-body-right">
          <div className="c-dl">
            <dl>
              <CardFrameItem title="TEL">{hokennaiService.tel}</CardFrameItem>
              <CardFrameItem title="住所">{service.jusho}</CardFrameItem>
              <CardFrameItem title="サービス提供地域">
                {shortstay.serviceTeikyochiiki}
              </CardFrameItem>
              <CardFrameItem title="提供サービス">
                {shortstay.teikyoService.join("、")}
              </CardFrameItem>
              <CardFrameItem title="居室タイプ">
                {shortstay.kyoshitsuType.join("、")}
              </CardFrameItem>
              <CardFrameItem
                title="受入れ / 対応可能な　　症状・病気等"
                isEmpty={shortstay.taiokanoShojo.length === 0}
              >
                {shortstay.taiokanoShojo.join("、")}
                <Mount if={shortstay.taiokanoShojoEtc}> など</Mount>
              </CardFrameItem>
              <CardFrameItem title="特徴">
                {shortstay.tokucho.join("、")}
              </CardFrameItem>
              <CardFrameItem title="特記事項">
                {hokennaiService.tokkijiko}
              </CardFrameItem>
            </dl>
          </div>
          <CardFoot
            onPrintButtonClick={props.onPrintButtonClick}
            onServiceButtonClick={props.onServiceButtonClick}
            onShareButtonClick={props.onShareButtonClick}
            service={service}
            shared={shared}
          />
        </div>
      </div>
    </div>
  );
};

ShortstayCard.propTypes = {
  onPrintButtonClick: PropTypes.func.isRequired,
  onServiceButtonClick: PropTypes.func.isRequired,
  onShareButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  shared: PropTypes.bool.isRequired,
};

export default ShortstayCard;
