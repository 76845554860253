const ActionType = {
  REQUEST: "JIGYOSHO_COLLECTIVE_PHOTO_REQUEST",
  REQUEST_FINISHED: "JIGYOSHO_PHOTO_COLLECTIVE_REQUEST_FINISHED",
  CHANGE_SORT: "JIGYOSHO_PHOTO_COLLECTIVE_CHANGE_SORT",
  CHANGE_FORM: "JIGYOSHO_PHOTO_COLLECTIVE_CHANGE_FORM",
  UPLOAD: "JIGYOSHO_PHOTO_COLLECTIVE_UPLOAD",
  REMOVE: "JIGYOSHO_PHOTO_COLLECTIVE_REMOVE",
  SUBMIT: "JIGYOSHO_PHOTO_COLLECTIVE_SBMIT",
  SUBMIT_FINISHED: "JIGYOSHO_PHOTO_COLLECTIVE_SBMIT_FINISHED",
  SUBMIT_FAILED: "JIGYOSHO_PHOTO_COLLECTIVE_SBMIT_FAILED",
};

export default ActionType;
