import { StyleSheet } from "aphrodite";
import ColorCatalog from "../../app/ui/color/ColorCatalog";

const styles = StyleSheet.create({
  navigationContainer: {
    display: "flex",
  },

  navigation: {
    flexGrow: 1,
    padding: 15,
  },

  active: {
    color: ColorCatalog.primary,
  },

  inactive: {
    color: ColorCatalog.gray,
  },
});

export default styles;
