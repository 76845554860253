import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const TatemonoSetsubiBihinTable = ({ contents }) => {
  const {
    blocks: { shortstayTatemonoSetsubiBihin: tatemonoSetsubiBihin },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-section">
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>事業所設置階</th>
                <td>{tatemonoSetsubiBihin.jigyoshoSetchikai}</td>
              </tr>
              <tr>
                <th>建物面積</th>
                <td>{tatemonoSetsubiBihin.tatemonoMenseki.join("、")}</td>
              </tr>
              <tr>
                <th>構造</th>
                <td>{tatemonoSetsubiBihin.kozo}</td>
              </tr>
              <tr>
                <th>エレベーター</th>
                <td>{tatemonoSetsubiBihin.elevator}</td>
              </tr>
              <tr>
                <th>デザイン</th>
                <td>{tatemonoSetsubiBihin.design}</td>
              </tr>
              <tr>
                <th>ナースコール</th>
                <td>{tatemonoSetsubiBihin.nurseCall.join("、")}</td>
              </tr>
              <tr>
                <th>設備</th>
                <td>{tatemonoSetsubiBihin.setsubi.join("、")}</td>
              </tr>
              <tr>
                <th>備品</th>
                <td>{tatemonoSetsubiBihin.bihin.join("、")}</td>
              </tr>
              <tr>
                <th>備考</th>
                <td>{tatemonoSetsubiBihin.biko}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.shortstayTatemonoSetsubiBihin
        )}
      </div>
    </Fragment>
  );
};

TatemonoSetsubiBihinTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default TatemonoSetsubiBihinTable;
