import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import * as actions from "../../../state/service-search/actions";
import { push } from "react-router-redux";
import Pathname from "../../../app/naming/Pathname";
import CategorizedOptions from "../../../app/util/CategorizedMasterData";
import { conditionConverter } from "../../../state/service-search/query-string";
import SearchConditionMenuFunnel from "./SearchConditionMenuFunnel";
import { serviceShubetsuCategoryTypeValueOf } from "../../../app/domain/jigyosho/ServiceShubetsuCategoryType";
import Parametername from "../../../app/naming/Parametername";
import ViewType from "./ViewType";
import SearchConditionPageCity from "./condition/SearchConditionPageCity";
import SearchConditionPageTown from "./condition/SearchConditionPageTown";
import { enterKeyHandler } from "../../../app/ui/form";

class SearchConditionMenu extends React.Component {
  constructor(props) {
    super(props);
    this.handleServiceShubetsuMenuClick = this.handleServiceShubetsuMenuClick.bind(
      this
    );
    this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
    this.handleMoreSearchButtonClick = this.handleMoreSearchButtonClick.bind(
      this
    );

    this.handleShowCityAreaDialog = this.handleShowCityAreaDialog.bind(this);
    this.handleShowTownAreaDialog = this.handleShowTownAreaDialog.bind(this);
    this.handleAreaDialogCancelClick = this.handleAreaDialogCancelClick.bind(
      this
    );
    this.handleAreaDialogOnSearch = this.handleAreaDialogOnSearch.bind(this);
  }

  state = {
    cityDialogIsOpen: false,
    townDialogIsOpen: false,
  };

  // 初回の描画完了後
  componentDidMount() {
    this.fetchSelectedBrowseAreaLabel();
    this.presetAreas();
  }

  handleServiceShubetsuMenuClick(e) {
    const { checked, value: code } = e.target;
    const { actions, serviceShubetsuMenu } = this.props;
    actions.changeConditionServiceShubetsuMenu(
      code,
      checked,
      serviceShubetsuMenu
    );
  }

  handleSearchButtonClick() {
    const { actions, serviceSearch } = this.props;
    const { condition } = serviceSearch;
    actions.reSearch(condition);
    actions.clearList();
  }

  handleMoreSearchButtonClick() {
    const { actions, serviceSearch } = this.props;
    const { condition } = serviceSearch;
    const newCondition = {
      ...condition,
      maxLat: "",
      minLat: "",
      minLng: "",
      maxLng: "",
    };
    actions.gotoSearchConditionPage(newCondition);
  }

  // 市区町村ダイアログの表示
  handleShowCityAreaDialog(event) {
    const { actions } = this.props;
    event.stopPropagation();
    // 地域一覧取得
    actions.fetchBrowseAreaCities();
    // 所属市区町村の初期チェック
    this.defaultCityChecked();
    this.setState({
      cityDialogIsOpen: true,
    });
  }

  // 町名ダイアログの表示
  handleShowTownAreaDialog(event) {
    const { actions } = this.props;
    const { condition } = this.props.serviceSearch;
    event.stopPropagation();

    // 地域一覧取得
    var cityAreas = {};
    Object.keys(condition[Parametername.cityArea])
      .filter((key) => condition[Parametername.cityArea][key])
      .forEach((key) => (cityAreas[key] = true));
    actions.fetchBrowseAreaTowns(cityAreas);

    this.setState({
      townDialogIsOpen: true,
    });
  }

  // キャンセルボタン・バックグラウンドクリック
  handleAreaDialogCancelClick() {
    this.setState({ cityDialogIsOpen: false, townDialogIsOpen: false });
  }

  // 変更して検索ボタン
  handleAreaDialogOnSearch() {
    const { actions, serviceSearch } = this.props;
    const { condition } = serviceSearch;
    // 再検索
    actions.reSearch(condition);
    actions.clearList();
    // 情報変更して画面を閉じる
    this.setState({ cityDialogIsOpen: false, townDialogIsOpen: false });
  }

  // 閲覧検索地域名取得
  fetchSelectedBrowseAreaLabel() {
    const { actions, location } = this.props;
    const { search } = location;
    const searchParams = new URLSearchParams(search);
    var cityAreas = {},
      townAreas = {};
    searchParams
      .getAll(Parametername.cityArea)
      .forEach((val) => (cityAreas[val] = true));
    searchParams
      .getAll(Parametername.townArea)
      .forEach((val) => (townAreas[val] = true));
    // クエリストリングより閲覧検索地域名取得
    actions.fetchSelectedBrowseAreaLabel(cityAreas, townAreas);
  }

  // クエリストリングよりConditionバッファー作成
  presetAreas() {
    const { actions, location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    var cityAreas = {},
      townAreas = {};
    searchParams
      .getAll(Parametername.cityArea)
      .forEach((val) => (cityAreas[val] = true));
    searchParams
      .getAll(Parametername.townArea)
      .forEach((val) => (townAreas[val] = true));
    // クエリストリングより選択市区町村を保存
    actions.savaConditionBuffer(Parametername.cityArea, cityAreas);
    // クエリストリングより選択町名を保存
    actions.savaConditionBuffer(Parametername.townArea, townAreas);
  }

  // 所属市区町村の初期チェック
  defaultCityChecked() {
    const { changeCondition: onChange } = this.props.actions;
    const { user } = this.props.authentication;
    const { conditionBuffer } = this.props.serviceSearch;
    // 全て未選択でログイン情報の地域がある場合
    if (
      user.browseAreaCityCode &&
      Object.keys(conditionBuffer[Parametername.cityArea]).length < 1
    ) {
      onChange(`${Parametername.cityArea}.${user.browseAreaCityCode}`, true);
    }
  }

  getCityLabelList(cityLabelList) {
    const LABEL_LIMIT = 10; // 地域の表示上限
    return cityLabelList.length > LABEL_LIMIT
      ? cityLabelList.filter((val, index) => index < LABEL_LIMIT).join("/") +
          "..."
      : cityLabelList.join("/");
  }

  getTownLabelList(townLabelList, townDisabled) {
    const LABEL_LIMIT = 10; // 地域の表示上限
    var townLabel = "";
    if (townDisabled)
      townLabel = "※市区町村が単一選択の場合、町名の選択が可能です。";
    else if (townLabelList.length === 0) townLabel = "未選択";
    else if (townLabelList.length > LABEL_LIMIT)
      townLabel =
        townLabelList.filter((val, index) => index < LABEL_LIMIT).join("/") +
        "...";
    else townLabel = townLabelList.join("/");
    return townLabel;
  }

  getTownLabelStyle(townDisabled) {
    return townDisabled
      ? " " + "c-search-nav__body-subtext__browseAreaLabel_disabled"
      : " " + "c-search-nav__body-subtext__browseAreaLabel";
  }

  render() {
    const { condition } = this.props.serviceSearch;
    this.serviceShubetsuCategoryCode = condition.serviceShubetsuCategoryCode
      ? condition.serviceShubetsuCategoryCode
      : this.serviceShubetsuCategoryCode;

    return (
      <div>
        <div>
          {this.renderSelectedServiceShubetsuCategory()}
          {this.renderSelectedArea()}

          <div className="c-search-nav">
            <div className="c-search-nav__title">絞り込み条件の選択</div>
            <div className="c-search-nav__body">
              {this.renderKeyword()}
              {this.renderServiceShubetsuSelection()}
              <br />
              {this.renderSearchButton()}

              <SearchConditionMenuFunnel
                condition={this.props.serviceSearch.condition}
                onChange={this.props.actions.changeCondition}
              />

              {/*
                            <div className="c-nav-form-title">提供メニュー</div>
                            <div className="c-form-checkbox2">
                                <label className="c-form-checkbox__item2">
                                    <input type="checkbox" checked/>
                                    <div className="c-form-checkbox__item-text">こだわらない</div>
                                </label>
                            </div>

                            <div className="c-nav-form-title">提供地域</div>
                            <div className="c-nav-form-area">
                                東京都 世田谷区<br/>
                                八幡山 1丁目
                            </div>
                            <div className="c-simplebutton-b c-simplebutton-b--search">
                                <a href="">
                                    提供地域の変更
                                </a>
                            </div>

                            <div className="c-nav-form-title">徒歩時間</div>
                            <div className="c-nav-form-space">
                                <div className="c-form-select">
                                    <div className="c-form-select__item">
                                        <select name="">
                                            <option>指定なし</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            */}

              <br />
              {this.renderSearchButton()}

              <div className="c-nav-form-last">
                <a onClick={this.handleMoreSearchButtonClick}>
                  <div className="c-nav-form-last-text1">さらに詳細な</div>
                  <div className="c-nav-form-last-text2">
                    絞り込み条件の選択
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>

        {this.renderCityAreaDialog()}
        {this.renderTownAreaDialog()}
      </div>
    );
  }

  renderSelectedServiceShubetsuCategory() {
    const categoryType = serviceShubetsuCategoryTypeValueOf(
      this.serviceShubetsuCategoryCode
    );
    if (!categoryType) return null;
    return (
      <div className="c-search-nav">
        <div className="c-search-nav__title">サービス種別の選択</div>
        <div className="c-search-nav__body">
          <div className="c-search-nav__body-selected">
            <div className="c-search-nav__body-selected-img">
              <img src={categoryType.imgSrc.active} />
            </div>
            <div className="c-search-nav__body-selected-text">選択中</div>
          </div>
          <div className="c-search-nav__body-subtext">{categoryType.label}</div>
          <div className="c-simplebutton-b c-simplebutton-b--search">
            <a onClick={this.handleMoreSearchButtonClick}>種別の変更</a>
          </div>
        </div>
      </div>
    );
  }

  renderSelectedArea() {
    const { user } = this.props.authentication;
    const {
      condition,
      conditionBuffer,
      selectedBrowseAreaLabel,
    } = this.props.serviceSearch;
    if (
      condition.view.name == ViewType.mapView.name ||
      !selectedBrowseAreaLabel ||
      !user.postcode
    )
      return;
    const disabled = condition.serviceShubetsuMenuCodes.length === 0;
    const townDisabled =
      Object.keys(conditionBuffer[Parametername.cityArea]).length > 1;
    const cityLabelList = this.getCityLabelList(
      selectedBrowseAreaLabel.cityLabelList
    );
    const townLabelList = this.getTownLabelList(
      selectedBrowseAreaLabel.townLabelList,
      townDisabled
    );
    const townLabelStyle = this.getTownLabelStyle(townDisabled);

    return (
      <div className="c-search-nav">
        <div className="c-search-nav__title">地域の選択</div>
        <div className="c-search-nav__body">
          <div className="c-search-nav__body-selected">
            {selectedBrowseAreaLabel.prefLabel}
          </div>
          <div className="c-search-nav__body-subtext c-search-nav__body-subtext__browseAreaLabel">
            {cityLabelList}
          </div>
          <div className="c-simplebutton-b c-simplebutton-b--search">
            <a
              onClick={(event) =>
                !disabled && this.handleShowCityAreaDialog(event)
              }
              disabled={disabled}
            >
              市区町村の変更
            </a>
          </div>
          <div className={`c-search-nav__body-subtext` + townLabelStyle}>
            {townLabelList}
          </div>
          <div className="c-simplebutton-b c-simplebutton-b--search">
            <a
              onClick={(event) =>
                !disabled &&
                !townDisabled &&
                this.handleShowTownAreaDialog(event)
              }
              disabled={disabled || townDisabled}
            >
              町名の変更
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderKeyword() {
    const condition = this.props.serviceSearch.condition;
    const onChange = this.props.actions.changeCondition;
    const handleChange = (e) => onChange(e.target.name, e.target.value);
    return (
      <Fragment>
        <div className="c-nav-form-title">キーワード</div>
        <div className="c-form-text2">
          <input
            type="text"
            name={Parametername.keyword}
            value={condition.getByName(Parametername.keyword)}
            onChange={handleChange}
            placeholder="千代田区 囲碁"
            onKeyDown={enterKeyHandler(this.handleSearchButtonClick)}
          />
        </div>
      </Fragment>
    );
  }

  renderServiceShubetsuSelection() {
    const { serviceSearch, serviceShubetsuMenu } = this.props;
    const { condition } = serviceSearch;
    const category = new CategorizedOptions(serviceShubetsuMenu)
      .getCategories()
      .find((category) => category.code === this.serviceShubetsuCategoryCode);
    if (!category) return null;
    if (category.options.length < 2) return null;
    return (
      <Fragment>
        <div className="c-nav-form-title">サービス種別（必須）</div>
        {category.options.map((menu) => (
          <div key={menu.code} className="c-form-checkbox2">
            <label className="c-form-checkbox__item2">
              <input
                type="checkbox"
                checked={condition.serviceShubetsuMenuCodes.includes(menu.code)}
                onChange={this.handleServiceShubetsuMenuClick}
                value={menu.code}
              />
              <div className="c-form-checkbox__item-text">{menu.label}</div>
            </label>
          </div>
        ))}
      </Fragment>
    );
  }

  renderSearchButton() {
    const { condition } = this.props.serviceSearch;
    const disabled = condition.serviceShubetsuMenuCodes.length === 0;
    return (
      <div className="c-simplebutton-b c-simplebutton-b--search">
        <a
          onClick={() => !disabled && this.handleSearchButtonClick()}
          disabled={disabled}
        >
          再検索
        </a>
      </div>
    );
  }

  // 市区町村ダイアログ
  renderCityAreaDialog() {
    const { actions, serviceSearch, location } = this.props;
    const { changeCondition: onChange, savaConditionBuffer: onSave } = actions;
    const { cityDialogIsOpen: open } = this.state;
    const props = {
      serviceSearch,
      location,
      onChange,
      onSave,
      open,
      okText: "再検索",
      handleCancel: this.handleAreaDialogCancelClick,
      handleClose: this.handleAreaDialogCancelClick,
      handleOk: this.handleAreaDialogOnSearch,
    };
    return <SearchConditionPageCity {...props} />;
  }

  // 市区町村ダイアログ
  renderTownAreaDialog() {
    const { actions, serviceSearch, location } = this.props;
    const { changeCondition: onChange, savaConditionBuffer: onSave } = actions;
    const { townDialogIsOpen: open } = this.state;
    const props = {
      serviceSearch,
      location,
      onChange,
      onSave,
      open,
      okText: "再検索",
      handleCancel: this.handleAreaDialogCancelClick,
      handleClose: this.handleAreaDialogCancelClick,
      handleOk: this.handleAreaDialogOnSearch,
    };
    return <SearchConditionPageTown {...props} />;
  }
}

SearchConditionMenu.propTypes = {
  authentication: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  serviceSearch: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  serviceShubetsuMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SearchConditionMenu)
);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      reSearch: (condition) => {
        const search = conditionConverter(condition).toSearch();
        dispatch(
          push({
            search,
          })
        );
      },
      gotoSearchConditionPage: (condition) => {
        const search = conditionConverter(condition).toSearch();
        dispatch(
          push({
            pathname: Pathname.webfront_search_condition,
            search,
          })
        );
      },
    },
  };
}
