import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./actions";
import CenteredCircularProgress from "../../app/ui/progress/CenteredCircularProgress";
import {
  withScriptjs,
  withGoogleMap as withReactGoogleMap,
} from "react-google-maps";

const GOOGLE_MAPS_API_JS_URL = "https://maps.googleapis.com/maps/api/js";

export function withGoogleMap(OriginalComponent) {
  const WrappedOriginalComponent = withScriptjs(
    withReactGoogleMap(OriginalComponent)
  );

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class GoogleMapRequiredComponent extends React.Component {
      static propTypes = {
        actions: PropTypes.objectOf(PropTypes.func).isRequired,
        googleMap: PropTypes.object.isRequired,
        containerElement: PropTypes.node.isRequired,
        mapElement: PropTypes.node.isRequired,
      };

      componentDidMount() {
        const { actions, googleMap } = this.props;
        if (googleMap.apiKey) return;
        actions.requestApiKey();
      }

      render() {
        const { googleMap, ...restProps } = this.props;
        if (!googleMap.apiKey) return <CenteredCircularProgress />;
        const originalComponentProps = {
          googleMapURL: `${GOOGLE_MAPS_API_JS_URL}?key=${googleMap.apiKey}`,
          loadingElement: <CenteredCircularProgress />,
          ...restProps,
        };
        return <WrappedOriginalComponent {...originalComponentProps} />;
      }
    }
  );

  function mapStateToProps(state) {
    return {
      googleMap: state.googleMap,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      actions: bindActionCreators(actions, dispatch),
    };
  }
}
