import React from "react";
import PropTypes from "prop-types";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import { connect } from "react-redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import RaisedSecondaryButton from "../../../../app/ui/button/RaisedSecondaryButton";
import Mount from "../../../../app/ui/layout/Mount";

const JobOfferEditAlertDialog = ({ jobOffer, open, onClose, onSubmit }) => {
  if (!jobOffer.detail) return null;

  const { detail } = jobOffer;

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>求人情報編集の確認</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Mount if={detail.approvalRequested}>
            求人情報を編集すると公開申請が取り下げられます。よろしいですか？
          </Mount>
          <Mount if={detail.published || detail.approved}>
            求人情報を編集すると再度内容が承認されるまで掲載が取り下げられます。よろしいですか？
          </Mount>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <FlatPrimaryButton onClick={onClose}>キャンセル</FlatPrimaryButton>
        <RaisedSecondaryButton onClick={onSubmit}>
          編集する
        </RaisedSecondaryButton>
      </DialogActions>
    </Dialog>
  );
};

JobOfferEditAlertDialog.propTypes = {
  jobOffer: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    jobOffer: state.jobOffer,
  };
};

export default connect(mapStateToProps, null)(JobOfferEditAlertDialog);
