import React from "react";
import { mergeStyleToProps } from "../../fundamental/react/props";

const ScreenMask = (props) => {
  return <div {...mergeStyleToProps(styles.mask, props)}></div>;
};

const styles = {
  mask: {
    backgroundColor: "rgba(0,0,0,0.2)",
    height: "100%",
    left: 0,
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 1000,
  },
};

export default ScreenMask;
