import React, { Fragment } from "react";
import PropTypes from "prop-types";
import PhotosBlock from "./general/PhotosBlock";
import {
  goToJigyoshoAttributeFormButton,
  gotoPhotoFormButton,
} from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";

class MimamoriSuishinJigyoAbstract extends React.Component {
  render() {
    return (
      <div>
        <div className="p-office-body">
          {this.renderHead()}

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">{this.renderPhotos()}</div>
            <div className="p-office-body-sep__right">{this.renderGaiyo()}</div>
          </div>

          {this.renderJigyoshoJoho()}
        </div>
      </div>
    );
  }

  renderHead() {
    const { contents } = this.props;
    const hokengaiService = contents.blocks.hokengaiService;
    const mimamoriSuishinJigyo = contents.blocks.mimamoriSuishinJigyo;
    return (
      <div className="c-dl u-border-bottom-b u-margin-b32">
        <dl className="u-margin-b8">
          <dt>営業時間</dt>
          <dd>{hokengaiService.eigyojikan}</dd>
          <dt>営業日</dt>
          <dd>{hokengaiService.eigyobi.join("、")}</dd>
          <dt>定休日</dt>
          <dd>{mimamoriSuishinJigyo.teikyubi}</dd>
          <dt>住所</dt>
          <dd>{hokengaiService.jusho}</dd>
          <dt>電話番号</dt>
          <dd>{hokengaiService.denwabango}</dd>
          <dt>担当地域包括支援センター</dt>
          <dd>{mimamoriSuishinJigyo.tantoChiikiHokatsuShienCenter}</dd>
          <dt>特記事項</dt>
          <dd>{mimamoriSuishinJigyo.tokkijiko}</dd>
        </dl>

        <div className="p-office-body-partition__right-text">
          最新更新日：{contents.userUpdatedAt}
          <br />
          印刷回数：{contents.printCount}
          <br />
        </div>
      </div>
    );
  }

  renderPhotos() {
    const { contents } = this.props;
    const photos = contents.blocks.photos;

    return (
      <Fragment>
        <PhotosBlock photos={photos.list} />

        {gotoPhotoFormButton(this.props.contents)}
      </Fragment>
    );
  }

  renderGaiyo() {
    const { contents } = this.props;
    const mimamoriSuishinJigyo = contents.blocks.mimamoriSuishinJigyo;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">見守り概要</div>
        </div>
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>見守り概要</th>
                <td>
                  {mimamoriSuishinJigyo.teikyoService.map((it) => (
                    <div key={it}>{it}</div>
                  ))}
                </td>
              </tr>
              <tr>
                <th>見守り対象者</th>
                <td>{mimamoriSuishinJigyo.mimamoriTaishosha}</td>
              </tr>
              <tr>
                <th>見守り提供地域</th>
                <td>{mimamoriSuishinJigyo.mimamoriTeikyoChiiki}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.mimamoriSuishinJigyoDetail
        )}
      </Fragment>
    );
  }

  renderJigyoshoJoho() {
    const { contents } = this.props;
    const hokengaiService = contents.blocks.hokengaiService;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">事業所情報</div>
        </div>
        <div className="p-office-body-table u-margin-b32">
          <table>
            <tbody>
              <tr>
                <th>郵便番号</th>
                <td>{hokengaiService.yubinbango}</td>
              </tr>
              <tr>
                <th>住所</th>
                <td>{hokengaiService.jusho}</td>
              </tr>
              <tr>
                <th>ホームページURL</th>
                <td>{hokengaiService.homepageUrl}</td>
              </tr>
              <tr>
                <th>運営法人名</th>
                <td>{hokengaiService.uneihojin}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.mimamoriSuishinJigyo
        )}
      </Fragment>
    );
  }
}

MimamoriSuishinJigyoAbstract.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default MimamoriSuishinJigyoAbstract;
