import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import TruncateText from "../../../../../app/ui/text/TruncateText";

const ServiceBlock = ({ service }) => {
  const LABEL_LIMIT = 80;
  return (
    <Fragment>
      <HeaderTextBlue text="サービス内容" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>提供サービス</th>
              <td>{service.teikyoService}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height40 tbody-td_textAlign_left">
              <th>対応可能サービス</th>
              <td>{service.taioService}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height40 tbody-td_textAlign_left">
              <th>備考</th>
              <td>
                <TruncateText maxLength={LABEL_LIMIT}>
                  {service.serviceBiko}
                </TruncateText>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

ServiceBlock.propTypes = {
  service: PropTypes.object.isRequired,
};

export default ServiceBlock;
