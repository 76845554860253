import React from "react";
import PropTypes from "prop-types";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import { connect } from "react-redux";
import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";

const JobOfferHistoriesBlock = (props) => {
  const { detail } = props.jobOffer;

  return (
    <Card>
      <SideMargin top bottom>
        <FormGrouping title="履歴">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ width: "130px" }}>日時</TableCell>
                <TableCell style={{ width: "130px" }}>内容</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {detail.actionHistories.map((history, index) => (
                <TableRow key={index}>
                  <TableCell>{history.createdAt}</TableCell>
                  <TableCell>{history.action}</TableCell>
                  <TableCell className="pre-line">{history.detail}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </FormGrouping>
      </SideMargin>
    </Card>
  );
};

JobOfferHistoriesBlock.propTypes = {
  jobOffer: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    jobOffer: state.jobOffer,
  };
};

export default connect(mapStateToProps, null)(JobOfferHistoriesBlock);
