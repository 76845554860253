import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import ServiceKaishi from "./ServiceKaishi";
import ServiceYoyaku from "./ServiceYoyaku";
import ServiceRyokin from "./ServiceRyokin";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import ServiceGyoseiJoseiHojo from "./ServiceGyoseiJoseiHojo";

class HouseworkForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderService()}
        {this.renderServiceKaishi()}
        {this.renderYoyaku()}
        {this.renderGyoseiJoseiHojo()}
        {this.renderRyokin()}
      </Fragment>
    );
  }

  renderService() {
    const { bindingProps } = this.props;
    const teikyoServices = [
      { code: "houseworkFlg", label: "日常家事代行" },
      { code: "cleaningFlg", label: "掃除代行" },
      { code: "cookingFlg", label: "料理代行" },
      { code: "physicalHelpFlg", label: "身体介護" },
      { code: "talkingFlg", label: "高齢者向けサービス" },
      { code: "familyFlg", label: "介護家族向けサービス" },
      { code: "sitterFlg", label: "シッターサービス" },
      { code: "bugFlg", label: "害虫駆除" },
      { code: "trashHouseFlg", label: "ゴミ屋敷の掃除" },
      { code: "fanFlg", label: "エアコン・換気扇クリーニング" },
      { code: "furnitureFlg", label: "家具の移動" },
      { code: "weedFlg", label: "雑草取り・庭の剪伐" },
    ];
    const taioServices = [
      { code: "withInsuranceFlg", label: "介護保険サービスとの併用" },
      { code: "oneTimeFlg", label: "1回からの利用" },
      { code: "shortUse30Flg", label: "短時間利用（30分～）" },
      { code: "shortUse120Flg", label: "長時間利用（2時間～）" },
      { code: "morningFlg", label: "早朝対応" },
      { code: "nightFlg", label: "夜間対応" },
      { code: "stayFlg", label: "宿泊対応" },
      { code: "manFlg", label: "男性スタッフ" },
      { code: "responsibleStaffFlg", label: "同一スタッフ指名" },
    ];
    return (
      <FormGrouping title="サービスについて">
        <HorizontalLabelLayout labelText="提供サービス" required>
          <OptionsCheckbox
            name="teikyoService"
            options={teikyoServices}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="対応してくれるサービス" required>
          <OptionsCheckbox
            name="taioService"
            options={taioServices}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="serviceBiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderServiceKaishi() {
    const { bindingProps } = this.props;
    return <ServiceKaishi bindingProps={bindingProps} />;
  }

  renderYoyaku() {
    const { bindingProps } = this.props;
    return <ServiceYoyaku bindingProps={bindingProps} />;
  }

  renderGyoseiJoseiHojo() {
    const { bindingProps } = this.props;
    return <ServiceGyoseiJoseiHojo bindingProps={bindingProps} />;
  }

  renderRyokin() {
    const { bindingProps } = this.props;
    return <ServiceRyokin bindingProps={bindingProps} />;
  }
}

HouseworkForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HouseworkForm);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
