import fetch from "isomorphic-fetch";
import ApiPath from "./ApiPath";
import JwtTokenRepository from "./JwtTokenRepository";
import StatusCode from "./StatusCode";
import JwtToken from "./JwtToken";

class AuthorizationRefreshRequest {
  request(onSuccess = () => {}, onFailure = () => {}) {
    const token = new JwtTokenRepository().restore();
    if (!token) return onFailure();
    const url = ApiPath.api_authentication_refresh;
    const options = {
      method: "post",
      headers: token.toHeaderObject(),
      credentials: "omit",
    };
    fetch(url, options)
      .then((response) => {
        const { status, headers } = response;
        const statusCode = new StatusCode(status);
        if (statusCode.isOK()) {
          this.storeToken(headers);
          onSuccess();
        } else {
          onFailure();
        }
      })
      .catch(this.errorHandler);
  }

  storeToken(headers) {
    const token = JwtToken.ofHeaders(headers);
    new JwtTokenRepository().store(token);
  }

  errorHandler(e) {
    console.error(e);
  }

  setErrorHandler(handler) {
    this.errorHandler = handler;
    return this;
  }
}

export default AuthorizationRefreshRequest;
