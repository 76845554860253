import React from "react";
import PropTypes from "prop-types";
import ServiceShubetsuType from "../../../app/domain/jigyosho/ServiceShubetsuType";

const PrintTable = (props) => {
  const { selected, onSelect, service, children } = props;
  const id = [ServiceShubetsuType.活動_通いの場]
    .map((it) => it.code)
    .includes(service.serviceShubetsuCode)
    ? service.serviceId
    : service.id;
  return (
    <div className="c-table-frame__flex">
      <div className="c-table-frame__flex-check">
        <label className="c-check">
          <input
            type="checkbox"
            checked={selected}
            onChange={() => onSelect(service.id)}
          />
          <span className="c-check__box"></span>
        </label>
      </div>
      <div
        className={`c-table-frame__flex-link ${selected ? "u-bg-yellow4" : ""}`}
      >
        <a onClick={() => props.onServiceButtonClick(id, service.serviceSubId)}>
          {children}
        </a>
      </div>
    </div>
  );
};

PrintTable.propTypes = {
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onServiceButtonClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export default PrintTable;
