import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const ServiceTable = ({ contents }) => {
  const {
    blocks: { kyotakukaigoshienjigyoshoService: service },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>サービス提供地域</th>
              <td>{service.serviceTeikyoChiiki}</td>
            </tr>
            <tr>
              <th>訪問対応可能日</th>
              <td>{service.homontaiokanobi.join("、")}</td>
            </tr>
            <tr>
              <th>電話受付時間</th>
              <td>{service.telUketsuke.join(" 〜 ")}</td>
            </tr>
            <tr>
              <th>緊急時の電話対応</th>
              <td>{service.kinkyujinodenwataio}</td>
            </tr>
            <tr>
              <th>緊急時の連絡先</th>
              <td>{service.kinkyujinorenrakusaki}</td>
            </tr>
          </tbody>
        </table>

        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.kyotakukaigoshienjigyoshoService
        )}
      </div>
    </Fragment>
  );
};

ServiceTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default ServiceTable;
