import fetch from "isomorphic-fetch";
import StatusCode from "./StatusCode";
import { responseHandlersTypeOf } from "./ResponseHandlersType";

class PureApiRequest {
  constructor(url, method, headers = {}) {
    this.url = url;
    this.method = method;
    this.headers = headers;
  }

  request(
    responseHandler = (json) => {
      console.log(json);
    }
  ) {
    this.makeErrorHandler(responseHandler);
    console.log(`${this.method} ${this.url}`);
    const options = {
      method: this.method,
      headers: this.headers,
      credentials: "omit",
    };
    fetch(this.url, options)
      .then(
        (() => {
          const type = responseHandlersTypeOf(responseHandler);
          const normalizedResponseHandler = type.normalize(responseHandler);
          return this.responseHandler(
            normalizedResponseHandler.onSuccess,
            normalizedResponseHandler.onFailure,
            normalizedResponseHandler.onError
          );
        })()
      )
      .catch(this.errorHandler);
  }

  responseHandler(onSuccess, onFailure, onError) {
    return (response) => {
      const statusCode = new StatusCode(response.status);
      if (statusCode.isNotFound()) return onFailure({});
      return response
        .json()
        .then(
          (function () {
            const statusCode = new StatusCode(response.status);
            if (statusCode.isOK()) return onSuccess;
            if (statusCode.isBadRequest()) return onFailure;
            return onError;
          })()
        )
        .catch(this.errorHandler);
    };
  }

  makeErrorHandler(responseHandler) {
    this.errorHandler = responseHandlersTypeOf(responseHandler).normalize(
      responseHandler
    ).onError;
  }

  static get(url) {
    return new PureApiRequest(url, "get");
  }
}

export default PureApiRequest;
