import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../../state/information/actions";
import Pathname from "../../../app/naming/Pathname";
import Boundary from "../../../app/ui/layout/Boundary";
import SideMargin from "../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../app/ui/structure/BackOfficeHead";
import Parametername from "../../../app/naming/Parametername";
import CenteredCircularProgress from "../../../app/ui/progress/CenteredCircularProgress";
import For from "../../../app/ui/layout/For";

class InformationMessageList extends React.Component {
  constructor(props) {
    super(props);
    // 初期化
    this.initializeFields(props);
    // 「タイトル」クリックイベント
    this.handleInformationMessageDetailClick = this.handleInformationMessageDetailClick.bind(
      this
    );
  }

  // 初期化
  initializeFields(props) {
    const { search } = props.location;
    const searchParams = new URLSearchParams(search);
    this.pathname =
      searchParams.get(Parametername.pathname) || Pathname.webfront;
  }

  componentDidMount() {
    const { actions } = this.props;
    // お知らせ一覧の取得
    actions.requestList();
  }

  // 「タイトル」クリックイベント
  handleInformationMessageDetailClick(id) {
    const { actions } = this.props;
    actions.gotoInformationMessageDetailPage(id);
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          <BackOfficeHead>お知らせ一覧</BackOfficeHead>
          {this.renderInformationList()}
        </SideMargin>
      </Boundary>
    );
  }

  renderInformationList() {
    const { informationForm } = this.props;
    const { list, listIsLoading } = informationForm;
    if (listIsLoading) return <CenteredCircularProgress />;
    if (!list.length) return this.renderMessageDataEmpty();
    return (
      <div style={styles.div}>
        <For
          in={list}
          each={(it) => (
            <div key={it.id} style={styles.cell}>
              {it.registDate} {this.renderNewFor(it)} <br />
              <a
                onClick={() => this.handleInformationMessageDetailClick(it.id)}
              >
                {it.title}
              </a>
            </div>
          )}
        />
      </div>
    );
  }

  renderMessageDataEmpty() {
    return (
      <div
        style={{ textAlign: "center" }}
        className="p-office-body__registration-data-empty"
      >
        <p>
          お知らせはありません。
          <br />
        </p>
      </div>
    );
  }

  renderNewFor(it) {
    if (!it.newFlag) return null;
    return <span style={styles.new}>NEW</span>;
  }
}

InformationMessageList.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  informationForm: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InformationMessageList);

function mapStateToProps(state) {
  return {
    informationForm: state.informationForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoInformationMessageDetailPage: (id) =>
        dispatch(
          push({
            pathname: Pathname.backoffice_information_id.replace(/:id/, id),
          })
        ),
    },
  };
}

const styles = {
  div: {
    width: "100%",
    marginTop: 26,
    borderTop: "1px solid #dddddd",
  },

  cell: {
    paddingTop: 10,
    paddingBottom: 5,
    borderBottom: "1px solid #dddddd",
  },
  new: {
    color: "red",
  },
};
