import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import ExternalLinkedUrl from "../../../../../app/ui/link/ExternalLinkedUrl";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

const HokengaiServiceTable = ({
  hokengaiService,
  afterTable,
  serviceShubetsuCode,
}) => {
  const kayoinobaFlg = [ServiceShubetsuType.活動_通いの場]
    .map((it) => it.code)
    .includes(serviceShubetsuCode);
  return (
    <Fragment>
      <div className="c-blue-heading">
        <div className="c-blue-heading__text">
          {kayoinobaFlg ? "事業所・団体情報" : "事業所情報"}
        </div>
      </div>
      <div className="p-office-body-table u-margin-b32">
        <table>
          <tbody>
            <tr>
              <th>郵便番号</th>
              <td>{hokengaiService.yubinbango}</td>
            </tr>
            <tr>
              <th>{kayoinobaFlg ? "代表者住所" : "住所"}</th>
              <td>{hokengaiService.jusho}</td>
            </tr>
            <Mount if={kayoinobaFlg}>
              <tr>
                <th>代表者電話番号</th>
                <td>{hokengaiService.denwabango}</td>
              </tr>
              <tr>
                <th>代表者FAX</th>
                <td>{hokengaiService.fax}</td>
              </tr>
            </Mount>
            <tr>
              <th>ホームページ</th>
              <td>
                <ExternalLinkedUrl url={hokengaiService.homepageUrl} />
              </td>
            </tr>
            <tr>
              <th>運営法人名</th>
              <td>{hokengaiService.uneihojin}</td>
            </tr>
            <Mount if={kayoinobaFlg}>
              <tr>
                <th>団体種別</th>
                <td>{hokengaiService.dantaishubetsu}</td>
              </tr>
            </Mount>
          </tbody>
        </table>

        {afterTable}
      </div>
    </Fragment>
  );
};

HokengaiServiceTable.propTypes = {
  hokengaiService: PropTypes.object.isRequired,
  afterTable: PropTypes.node,
  serviceShubetsuCode: PropTypes.string,
};

export default HokengaiServiceTable;
