import PropTypes from "prop-types";

const ValueWithYubinMarkUnit = ({ value }) => {
  if (value == null) return "";
  return `〒${value}`;
};

ValueWithYubinMarkUnit.propTypes = {
  value: PropTypes.string,
};

export default ValueWithYubinMarkUnit;
