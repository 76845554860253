import React, { Fragment } from "react";
import PropTypes from "prop-types";
import PhotosBlock from "./general/PhotosBlock";
import EigyoJikanTable from "./tsushokeiService/EigyoJikanTable";
import RehabilitationTable from "./tsushokeiService/RehabilitationTable";
import RecreationTable from "./tsushokeiService/RecreationTable";
import ShokujiTable from "./tsushokeiService/ShokujiTable";
import NyuyokuTable from "./tsushokeiService/NyuyokuTable";
import StaffTable from "./tsushokeiService/StaffTable";
import HokennaiServiceTable from "./hokennaiService/HokennaiServiceTable";
import {
  goToJigyoshoAttributeFormButton,
  gotoPhotoFormButton,
} from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";

class TsushokeiServiceAbstract extends React.Component {
  render() {
    return (
      <div>
        <div className="p-office-body">
          {this.renderHead()}

          {this.renderCatchPhrase()}

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">{this.renderPhotos()}</div>
            <div className="p-office-body-sep__right">
              {this.renderRinenTokucho()}

              {this.renderEigyojikan()}
            </div>
          </div>

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">
              {this.renderRehabilitation()}
            </div>
            <div className="p-office-body-sep__right">
              {this.renderRecreation()}
            </div>
          </div>

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">
              {this.renderShokuji()}
            </div>
            <div className="p-office-body-sep__right">
              {this.renderNyuyoku()}
            </div>
          </div>

          {this.renderStaff()}

          {this.renderRyokin()}

          {this.renderJigyoshoJoho()}
        </div>
      </div>
    );
  }

  renderHead() {
    const { contents } = this.props;
    const tsushokeiService = contents.blocks.tsushokeiService;
    const hokennaiService =
      contents.blocks.tsushokeiServiceJigyoshoHokennaiJigyoshoJoho;
    const eigyoJikan = contents.blocks.tsushokeiServiceJigyoshoEigyoJikan;
    const sogei = contents.blocks.tsushokeiServiceJigyoshoSogei;

    // 通所リハなら時間外対応の宿泊を非表示
    if (
      [ServiceShubetsuType.通所リハビリテーション]
        .map((it) => it.code)
        .includes(contents.serviceShubetsuCode) &&
      eigyoJikan.jikangaitaio.length >= 3
    ) {
      eigyoJikan.jikangaitaio.pop();
    }

    return (
      <div className="c-dl u-border-bottom-b u-margin-b32">
        <dl className="u-margin-b8">
          <dt>住所</dt>
          <dd>{hokennaiService.jusho}</dd>
          <dt>サービス提供対象</dt>
          <dd>{hokennaiService.serviceTeikyoTaisho.join("、")}</dd>
          <dt>営業日</dt>
          <dd>{eigyoJikan.eigyobi.join("、")}</dd>
          <dt>時間外対応</dt>
          <dd>
            {eigyoJikan.jikangaitaio.map((tj) => tj.join(" ")).join("、")}
          </dd>
          <dt>サービス提供地域</dt>
          <dd>{sogei.serviceTeikyochiiki}</dd>
          <dt>特徴</dt>
          <dd>{tsushokeiService.tokucho.join("、")}</dd>
          <dt>定員</dt>
          <dd>{hokennaiService.teiin}</dd>
          <dt>提供部数</dt>
          <dd>
            {eigyoJikan.sectionJikanTai.length === 0 ? (
              <Fragment>{eigyoJikan.teikyobusu}</Fragment>
            ) : (
              <Fragment>
                {eigyoJikan.teikyobusu}（{eigyoJikan.sectionJikanTai.join("、")}
                ）
              </Fragment>
            )}
          </dd>
          <dt>特記事項</dt>
          <dd>{hokennaiService.tokkijiko}</dd>
        </dl>

        <div className="p-office-body-partition__right-text">
          最新更新日：{contents.userUpdatedAt}
          <br />
          印刷回数：{contents.printCount}
          <br />
        </div>
      </div>
    );
  }

  renderAkiJokyo() {
    const { contents } = this.props;
    const tsushokeiService = contents.blocks.tsushokeiService;

    return (
      <>
        <table>
          <caption>空き状況</caption>
          <thead>
            <tr>
              {tsushokeiService.akijokyo.map((youbi, i) => {
                return <th key={i}>{youbi[0]}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            <tr>
              {tsushokeiService.akijokyo.map((youbi, i) => {
                return <td key={i}>{youbi[1]}</td>;
              })}
            </tr>
          </tbody>
        </table>
        <div className="p-office-body-note">
          {tsushokeiService.akijokyoUserUpdatedAt}時点
        </div>
      </>
    );
  }

  renderCatchPhrase() {
    const { contents } = this.props;
    const hokennaiJigyoshoJoho =
      contents.blocks.tsushokeiServiceJigyoshoHokennaiJigyoshoJoho;

    return (
      <div className="p-office-body-catch">
        {hokennaiJigyoshoJoho.catchPhrase}
      </div>
    );
  }

  renderPhotos() {
    const { contents } = this.props;
    const photos = contents.blocks.photos;

    return (
      <Fragment>
        <PhotosBlock photos={photos.list} />

        {gotoPhotoFormButton(this.props.contents)}
      </Fragment>
    );
  }

  renderRinenTokucho() {
    const { contents } = this.props;
    const hokennaiJigyoshoJoho =
      contents.blocks.tsushokeiServiceJigyoshoHokennaiJigyoshoJoho;

    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">理念・特徴</div>
        </div>

        <div className="p-office-body-description">
          {hokennaiJigyoshoJoho.tokuchoRinen}
          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.tsushokeiServiceJigyoshoJoho
          )}
        </div>
      </Fragment>
    );
  }

  renderEigyojikan() {
    const { contents } = this.props;
    const eigyoJikan = contents.blocks.tsushokeiServiceJigyoshoEigyoJikan;
    const teikyoJikan = contents.blocks.tsushokeiServiceJigyoshoTeikyoJikan;
    const sogei = contents.blocks.tsushokeiServiceJigyoshoSogei;

    return (
      <Fragment>
        <EigyoJikanTable
          eigyoJikan={eigyoJikan}
          afterTable={goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.tsushokeiServiceJigyoshoEigyoJikan
          )}
        />

        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>サービス提供時間</th>
                <td>{teikyoJikan.services.join("、")}</td>
              </tr>
              <tr>
                <th>サービス提供地域</th>
                <td>{sogei.serviceTeikyochiiki}</td>
              </tr>
              <tr>
                <th>サービス提供地域備考</th>
                <td>{sogei.serviceTeikyoChiikiBiko}</td>
              </tr>
            </tbody>
          </table>
          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.serviceTeikyoJikan
          )}
        </div>

        <div className="p-office-body-calendar">
          {this.renderAkiJokyo()}
          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.tsushokeiServiceJigyoshoAkiJoho
          )}
        </div>
      </Fragment>
    );
  }

  renderRehabilitation() {
    const {
      tsushokeiServiceJigyoshoRehabilitation: rehabilitation,
    } = this.props.contents.blocks;
    return (
      <RehabilitationTable
        rehabilitation={rehabilitation}
        afterTable={goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.rehabilitation
        )}
      />
    );
  }

  renderRecreation() {
    const {
      tsushokeiServiceJigyoshoRecreation: recreation,
    } = this.props.contents.blocks;
    return (
      <RecreationTable
        recreation={recreation}
        afterTable={goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.recreation
        )}
      />
    );
  }

  renderShokuji() {
    const {
      tsushokeiServiceJigyoshoShokuji: shokuji,
    } = this.props.contents.blocks;
    return (
      <ShokujiTable
        shokuji={shokuji}
        afterTable={goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.shokuji
        )}
      />
    );
  }

  renderNyuyoku() {
    const {
      tsushokeiServiceJigyoshoNyuyoku: nyuyoku,
    } = this.props.contents.blocks;
    return (
      <NyuyokuTable
        nyuyoku={nyuyoku}
        afterTable={goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.nyuyoku
        )}
      />
    );
  }

  renderStaff() {
    const { tsushokeiServiceJigyoshoStaff: staff } = this.props.contents.blocks;
    return (
      <StaffTable
        staff={staff}
        afterTable={goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.staff
        )}
      />
    );
  }

  renderRyokin() {
    const { contents } = this.props;
    const ryokinKasanJoho =
      contents.blocks.tsushokeiServiceJigyoshoRyokinKasanJoho;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">料金</div>
        </div>

        {[ServiceShubetsuType.療養通所介護]
          .map((it) => it.code)
          .includes(contents.serviceShubetsuCode) ? (
          <Fragment>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>介護報酬加算</th>
                    <td>
                      {ryokinKasanJoho.kaigohoshukasan
                        .flatMap((it) => it.list)
                        .join("、")}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </Fragment>
        ) : (
          <Fragment>
            <div className="p-office-body-title">介護報酬加算</div>

            <div className="p-office-body-table">
              <table>
                <tbody>
                  {ryokinKasanJoho.kaigohoshukasan.map((item, i) => {
                    return (
                      <tr key={i}>
                        <th>{item.label}</th>
                        <td>{item.list.join("、")}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Fragment>
        )}

        <div className="p-office-body-title">利用料金の目安</div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-table2">
              {this.renderRyokinYokaigodo(1)}
            </div>
          </div>
          <div className="p-office-body-sep__right">
            <div className="p-office-body-table2">
              {this.renderRyokinYokaigodo(2)}
            </div>
          </div>
        </div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-table2">
              {this.renderRyokinYokaigodo(3)}
            </div>
          </div>
          <div className="p-office-body-sep__right">
            <div className="p-office-body-table2">
              {this.renderRyokinYokaigodo(4)}
            </div>
          </div>
        </div>

        <div className="p-office-body-sep">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-table2">
              {this.renderRyokinYokaigodo(5)}
            </div>
          </div>
          <div className="p-office-body-sep__right">
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>都度発生する料金</th>
                    <td>{ryokinKasanJoho.tsudoHasseisuruRyokin.join("、")}</td>
                  </tr>
                  <tr>
                    <th>利用者負担軽減制度</th>
                    <td>{ryokinKasanJoho.riyoshafutanKeigenSeido}</td>
                  </tr>
                  <tr>
                    <th>支払い方法</th>
                    <td>{ryokinKasanJoho.shiharaiHoho.join("、")}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="p-office-body-note">
              ※自己負担額が1割の場合の目安料金です
            </div>
            {goToJigyoshoAttributeFormButton(
              this.props.contents,
              JigyoshoAttributeType.ryokinKasanJoho
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  renderRyokinYokaigodo(n) {
    const { contents } = this.props;
    const ryokinKasanJoho =
      contents.blocks.tsushokeiServiceJigyoshoRyokinKasanJoho;
    const info = ryokinKasanJoho[`yokaigodo${n}`];

    return (
      <Fragment>
        <div className="p-office-body-table2__th">要介護度{n}</div>
        <div className="p-office-body-table2__td">
          <ul>
            <li>
              <div className="p-office-body-table2__td-name">自己負担額</div>
              <div className="p-office-body-table2__td-val">
                {info.jikofutangaku}
              </div>
            </li>
            <li>
              <div className="p-office-body-table2__td-name">昼食代</div>
              <div className="p-office-body-table2__td-val">
                {info.chushokudai}
              </div>
            </li>
            <li>
              <div className="p-office-body-table2__td-name">おやつ代</div>
              <div className="p-office-body-table2__td-val">
                {info.oyatsudai}
              </div>
            </li>
            <li className="u-fontweight-b">
              <div className="p-office-body-table2__td-name">合計金額</div>
              <div className="p-office-body-table2__td-val">
                {info.gokeikingaku}
              </div>
            </li>
          </ul>
        </div>
      </Fragment>
    );
  }

  renderJigyoshoJoho() {
    const {
      tsushokeiServiceJigyoshoHokennaiJigyoshoJoho: hokennaiService,
    } = this.props.contents.blocks;
    return (
      <HokennaiServiceTable
        hokennaiService={hokennaiService}
        afterTable={goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.tsushokeiServiceJigyoshoJoho
        )}
      />
    );
  }
}

TsushokeiServiceAbstract.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default TsushokeiServiceAbstract;
