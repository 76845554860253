import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import Mount from "../../../../../app/ui/layout/Mount";

const NyuyokuTable = ({ contents, detail }) => {
  const {
    blocks: { shortstayNyuyoku: nyuyoku },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>入浴頻度</th>
              <td>{nyuyoku.nyuyokuhindo}</td>
            </tr>
            <tr>
              <th>一回あたりの入浴時間</th>
              <td>{nyuyoku.nyuyokuJikan}</td>
            </tr>
            <tr>
              <th>浴室の数</th>
              <td>{nyuyoku.yokushitsunokazu}</td>
            </tr>
            <tr>
              <th>浴槽タイプ</th>
              <Mount if={!detail}>
                <td>{nyuyoku.yokusoType}</td>
              </Mount>
              <Mount if={detail}>
                <td>{nyuyoku.yokusoTypeDetail}</td>
              </Mount>
            </tr>
            <Mount if={detail}>
              <tr>
                <th>機械浴</th>
                <td>{nyuyoku.kikaiyoku}</td>
              </tr>
              <tr>
                <th>その他</th>
                <td>{nyuyoku.sonota}</td>
              </tr>
            </Mount>
            <tr>
              <th>同性介助</th>
              <td>{nyuyoku.doseikaijo}</td>
            </tr>
            <tr>
              <th>その他設備等</th>
              <td>{nyuyoku.sonotasetsubito}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Mount if={detail}>
        <div className="p-office-body-characteristic">特 徴</div>
        <div className="p-office-body-description p-office-body-description--p">
          {nyuyoku.tokucho}
        </div>
      </Mount>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.shortstayNyuyoku
      )}
    </Fragment>
  );
};

NyuyokuTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default NyuyokuTable;
