import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import ServiceContentType from "../ServiceContentType";
import Pathname from "../../../../app/naming/Pathname";
import Parametername from "../../../../app/naming/Parametername";
import { goToJigyoshoAttributeFormButton } from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";
import Mount from "../../../../app/ui/layout/Mount";

class KatsudoKayoinobaDetail extends React.Component {
  constructor(props) {
    super(props);
    this.handleServiceMeiButtonClick = this.handleServiceMeiButtonClick.bind(
      this
    );
  }

  handleServiceMeiButtonClick(id, subId) {
    this.props.onServiceMeiButtonClick(id, subId);
  }

  render() {
    const { katsudoKayoinobaMenu } = this.props.contents.blocks;
    // メイン表示アイテム
    const targetSubId = this.props.serviceSubId ? this.props.serviceSubId : "1";
    const mainItem = katsudoKayoinobaMenu.menus.find(
      (it) => it.subId == targetSubId
    );
    // その他表示アイテム
    const otherItems = katsudoKayoinobaMenu.menus.filter(
      (it) => it.subId !== targetSubId
    );
    return (
      <div className="p-office-body">
        {this.renderMenu(mainItem)}
        {this.renderBashoEigyojikan(mainItem)}
        {this.renderRyokinSankasha(mainItem)}
        {this.renderToiawase(mainItem)}
        {this.renderOthers(otherItems, this.props.contents.id)}
      </div>
    );
  }

  renderMenu(mainItem) {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">メニュー・活動内容</div>
        </div>
        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>メニュー・活動名</th>
                <td>{mainItem ? mainItem.serviceMei : null}</td>
              </tr>
              <tr>
                <th>メニュー・活動内容詳細</th>
                <td>{mainItem ? mainItem.menuServices : null}</td>
              </tr>
              <tr>
                <th>活動内容分類</th>
                <td>{mainItem ? mainItem.businessMenu : null}</td>
              </tr>
              <tr>
                <th>活動内容種類</th>
                <td>
                  {mainItem ? mainItem.businessMenuDetail.join("、") : null}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.businessProvideOtherMenu
        )}
      </Fragment>
    );
  }

  renderBashoEigyojikan(mainItem) {
    return (
      <Fragment>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">活動場所</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>住所</th>
                    <td>{mainItem ? mainItem.jusho : null}</td>
                  </tr>
                  <tr>
                    <th>会場備考</th>
                    <td>{mainItem ? mainItem.kaijoBiko : null}</td>
                  </tr>
                  <tr>
                    <th>会場までの行き方</th>
                    <td>{mainItem ? mainItem.kaijomadenoikikata : null}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {goToJigyoshoAttributeFormButton(
              this.props.contents,
              JigyoshoAttributeType.businessProvideOtherMenu
            )}
          </div>
          <div className="p-office-body-sep__right">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">営業時間</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>開催時間</th>
                    <td>{mainItem ? mainItem.eigyojikan : null}</td>
                  </tr>
                  <tr>
                    <th>開催日</th>
                    <td>{mainItem ? mainItem.eigyobi.join("、") : null}</td>
                  </tr>
                  <tr>
                    <th>開催日備考</th>
                    <td>{mainItem ? mainItem.eigyobiBiko : null}</td>
                  </tr>
                  <tr>
                    <th>開催頻度</th>
                    <td>{mainItem ? mainItem.eigyoHindo : null}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {goToJigyoshoAttributeFormButton(
              this.props.contents,
              JigyoshoAttributeType.businessProvideOtherMenu
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  renderRyokinSankasha(mainItem) {
    return (
      <Fragment>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">料金について</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>
                      一回当たりの費用
                      <br />
                      （税込み）
                    </th>
                    <td style={{ width: "106px" }}>
                      {mainItem ? mainItem.ikkaiatarinohiyo : null}
                    </td>
                    <th>年会費（税込み）</th>
                    <td>{mainItem ? mainItem.nenkaihi : null}</td>
                  </tr>
                  <tr>
                    <th>入会金・登録費</th>
                    <td style={{ width: "106px" }}>
                      {mainItem ? mainItem.nyukaihiTorokuhi : null}
                    </td>
                    <th>月会費（税込み）</th>
                    <td>{mainItem ? mainItem.tsukikaihi : null}</td>
                  </tr>
                  <tr>
                    <th>キャンセル時の扱いについて</th>
                    <td colSpan={3}>
                      {mainItem ? mainItem.cancelJinoatsukainitsuite : null}
                    </td>
                  </tr>
                  <tr>
                    <th>交通費について</th>
                    <td colSpan={3}>
                      {mainItem ? mainItem.kotsuhinitsuite : null}
                    </td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td colSpan={3}>{mainItem ? mainItem.ryokinBiko : null}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {goToJigyoshoAttributeFormButton(
              this.props.contents,
              JigyoshoAttributeType.businessProvideOtherMenu
            )}
          </div>
          <div className="p-office-body-sep__right">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">参加者</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>
                      1回あたりの参加者
                      <br />
                      人数
                    </th>
                    <td colSpan={3}>
                      {mainItem ? mainItem.participant : null}
                    </td>
                  </tr>
                  <tr>
                    <th
                      rowSpan={2}
                      style={{ borderRight: "1px dotted #23b8ce" }}
                    >
                      年齢別
                    </th>
                    <td className="header">65歳以上75歳未満</td>
                    <td>
                      男性：{mainItem ? mainItem.from65ToUnder75Male : null}
                    </td>
                    <td>
                      女性：{mainItem ? mainItem.from65ToUnder75Female : null}
                    </td>
                  </tr>
                  <tr>
                    <td className="header">75歳以上</td>
                    <td>男性：{mainItem ? mainItem.over75Male : null}</td>
                    <td>女性：{mainItem ? mainItem.over75Female : null}</td>
                  </tr>
                  <tr>
                    <th>
                      最も介護度の高い
                      <br />
                      参加者
                    </th>
                    <td colSpan={3}>{mainItem ? mainItem.careLevel : null}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            {goToJigyoshoAttributeFormButton(
              this.props.contents,
              JigyoshoAttributeType.businessProvideOtherMenu
            )}
          </div>
        </div>
      </Fragment>
    );
  }

  renderToiawase(mainItem) {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">問い合わせ</div>
        </div>
        <div className="p-office-body-table u-margin-b32">
          <table>
            <tbody>
              <tr>
                <th>問い合わせ方法</th>
                <td colSpan={3}>{mainItem ? mainItem.apply : null}</td>
              </tr>
              <tr>
                <th>担当者</th>
                <td colSpan={3}>{mainItem ? mainItem.tantoshaName : null}</td>
              </tr>
              <tr>
                <th>電話番号</th>
                <td style={{ width: "400px" }}>
                  {mainItem ? mainItem.tel : null}
                </td>
                <th>FAX</th>
                <td>{mainItem ? mainItem.fax : null}</td>
              </tr>
              <tr>
                <th>E-mail</th>
                <td colSpan={3}>{mainItem ? mainItem.email : null}</td>
              </tr>
            </tbody>
          </table>
        </div>
        {goToJigyoshoAttributeFormButton(
          this.props.contents,
          JigyoshoAttributeType.businessProvideOtherMenu
        )}
      </Fragment>
    );
  }

  renderOthers(otherItems, serviceId) {
    if (otherItems == null) return null;
    return otherItems.map((item, index) => (
      <Fragment key={index}>
        <Mount if={index === 0}>
          <div className="u-border-top-b u-padding-t32 u-margin-t20">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">その他活動内容</div>
            </div>
          </div>
        </Mount>
        <div className="p-office-body-table u-border-bottom-b u-padding-b32 u-margin-b32">
          <table>
            <tbody>
              <tr>
                <th>メニュー・活動名</th>
                <td className="c-information__a">
                  <a
                    onClick={() =>
                      this.handleServiceMeiButtonClick(serviceId, item.subId)
                    }
                  >
                    {item.serviceMei}
                  </a>
                </td>
              </tr>
              <tr>
                <th>メニュー・活動内容詳細</th>
                <td>{item.menuServices}</td>
              </tr>
              <tr>
                <th>活動内容分類</th>
                <td>{item.businessMenu}</td>
              </tr>
              <tr>
                <th>活動内容種類</th>
                <td>{item.businessMenuDetail.join("、")}</td>
              </tr>
              <tr>
                <th>活動場所住所</th>
                <td>{item.jusho}</td>
              </tr>
            </tbody>
          </table>
          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.businessProvideOtherMenu
          )}
        </div>
      </Fragment>
    ));
  }
}

KatsudoKayoinobaDetail.propTypes = {
  contents: PropTypes.object.isRequired,
  serviceSubId: PropTypes.string,
  onServiceMeiButtonClick: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(KatsudoKayoinobaDetail);

function mapDispatchToProps(dispatch, props) {
  const searchParams = new URLSearchParams();
  const pagePath =
    props.contents.preview == true
      ? Pathname.webfront_preview_jigyosho_id_type
      : Pathname.webfront_service_id_type;
  if (props.serviceSubId)
    searchParams.set(Parametername.serviceSubId, props.serviceSubId);
  return {
    onServiceMeiButtonClick: (id, subId) => {
      const searchParams = new URLSearchParams();
      if (subId) searchParams.set(Parametername.serviceSubId, subId);
      dispatch(
        push({
          pathname: pagePath
            .replace(/:id/, id)
            .replace(/:type/, ServiceContentType.detail.name),
          search: searchParams.toString(),
        })
      );
    },
  };
}
