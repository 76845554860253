import ActionType from "./reducer/ActionType";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import ApiPath from "../../app/fundamental/request/ApiPath";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
} from "../../app/fundamental/request";

export function select(id) {
  return {
    type: ActionType.SELECT,
    payload: {
      id,
    },
  };
}

export function changeCondition(name, value, callback = () => {}) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_CONDITION,
      payload: {
        name,
        value,
      },
    });
    setTimeout(callback);
  };
}

export function requestBySearch(search, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REQUEST,
      payload: {
        search,
      },
    });
    requestHojinList(dispatch, search, callback);
  };
}

function requestHojinList(dispatch, search, callback = () => {}) {
  const url = `${ApiPath.api_hojin}?${search}`;
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        list: json.list,
        pagination: json.pagination,
        search: json.search,
        prevSearch: json.prevSearch,
        nextSearch: json.nextSearch,
      },
    });
    setTimeout(() => callback(json.search));
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}
