import React from "react";

const PrintFooter = () => {
  return (
    <div className="print-foot">
      <div className="print-foot__center">
        <span className="coworker">
          協働開発：地方独立行政法人東京都健康長寿医療センター研究所&emsp;
        </span>
        <span className="copyrights">
          &copy; Welmo, Inc. All rights reserved.
        </span>
      </div>
    </div>
  );
};

export default PrintFooter;
