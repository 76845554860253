import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ThWithBg from "../general/ThWithBg";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

const TeikyoJikanBlock = ({ serviceTeikyojikan, serviceShubetsuType }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="サービス提供時間区分" />
      <div className="p-office-body-table-dotted">
        <Mount
          if={
            serviceShubetsuType === ServiceShubetsuType.通所リハビリテーション
          }
          then={() => (
            <TeikyoJikanBlock_rehabilitation
              serviceTeikyojikan={serviceTeikyojikan}
            />
          )}
        />
        <Mount
          if={
            serviceShubetsuType === ServiceShubetsuType.通所介護 ||
            serviceShubetsuType === ServiceShubetsuType.地域密着型通所介護 ||
            serviceShubetsuType === ServiceShubetsuType.認知症対応型通所介護 ||
            serviceShubetsuType === ServiceShubetsuType.療養通所介護
          }
          then={() => (
            <TeikyoJikanBlock_other serviceTeikyojikan={serviceTeikyojikan} />
          )}
        />
      </div>
    </Fragment>
  );
};

// 通所リハビリテーション
const TeikyoJikanBlock_rehabilitation = (serviceTeikyojikan) => {
  const { serviceTeikyojikan: teikyojikan } = serviceTeikyojikan;
  return (
    <table>
      <thead>
        <tr>
          <ThWithBg text="通常（時間）" colSpan={4} />
          <ThWithBg text="延長" colSpan={2} />
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>1〜2</th>
          <td>{teikyojikan.hours1to2}</td>
          <th>5〜6</th>
          <td>{teikyojikan.hours5to6}</td>
          <th>8〜10</th>
          <td>{teikyojikan.hours8to10}</td>
        </tr>
        <tr>
          <th>2〜3</th>
          <td>{teikyojikan.hours2to3}</td>
          <th>6〜7</th>
          <td>{teikyojikan.hours6to7}</td>
          <th>10〜12</th>
          <td>{teikyojikan.hours10to12}</td>
        </tr>
        <tr>
          <th>3〜4</th>
          <td>{teikyojikan.hours3to4}</td>
          <th>7〜8</th>
          <td>{teikyojikan.hours7to8}</td>
          <th>12〜14</th>
          <td>{teikyojikan.hours12to14}</td>
        </tr>
        <tr>
          <th>4〜5</th>
          <td>{teikyojikan.hours4to5}</td>
          <th></th>
          <td></td>
          <th></th>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

// 通所リハビリテーション以外
const TeikyoJikanBlock_other = (serviceTeikyojikan) => {
  const { serviceTeikyojikan: teikyojikan } = serviceTeikyojikan;
  return (
    <table>
      <thead>
        <tr>
          <ThWithBg text="通常（時間）" colSpan={4} />
          <ThWithBg text="延長" colSpan={2} />
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>2〜3</th>
          <td>{teikyojikan.hours2to3}</td>
          <th>6〜7</th>
          <td>{teikyojikan.hours6to7}</td>
          <th>9〜12</th>
          <td>{teikyojikan.hours9to12}</td>
        </tr>
        <tr>
          <th>3〜4</th>
          <td>{teikyojikan.hours3to4}</td>
          <th>7〜8</th>
          <td>{teikyojikan.hours7to8}</td>
          <th>12〜14</th>
          <td>{teikyojikan.hours12to14}</td>
        </tr>
        <tr>
          <th>4〜5</th>
          <td>{teikyojikan.hours4to5}</td>
          <th>8〜9</th>
          <td>{teikyojikan.hours8to9}</td>
          <th>宿泊</th>
          <td>{teikyojikan.shukuhaku}</td>
        </tr>
        <tr>
          <th>5〜6</th>
          <td>{teikyojikan.hours5to6}</td>
          <th></th>
          <td></td>
          <th></th>
          <td></td>
        </tr>
      </tbody>
    </table>
  );
};

TeikyoJikanBlock.propTypes = {
  serviceTeikyojikan: PropTypes.object.isRequired,
  serviceShubetsuType: PropTypes.object.isRequired,
};

export default TeikyoJikanBlock;
