import React from "react";
import PropTypes from "prop-types";
import CommonHeadContents from "./hokengaiService/CommonHeadContents";
import MainContents from "./barber/MainContents";
import YoyakuShiharaiTable from "./hokengaiService/YoyakuShiharaiTable";
import ToiawaseTable from "./hokengaiService/ToiawaseTable";
import HokengaiServiceTable from "./hokengaiService/HokengaiServiceTable";
import { goToJigyoshoAttributeFormButton } from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";

class BarberAbstract extends React.Component {
  render() {
    const { contents } = this.props;
    return (
      <div className="p-office-body">
        <CommonHeadContents contents={contents} />

        <MainContents contents={contents} />

        <div className="p-office-body-sep">
          <div className="p-office-body-sep__left">
            <YoyakuShiharaiTable
              yoyakuShiharai={contents.blocks.hokengaiYoyakuShiharai}
              afterTable={goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.barber
              )}
            />
          </div>
          <div className="p-office-body-sep__right">
            <ToiawaseTable
              toiawase={contents.blocks.hokengaiToiawase}
              afterTable={goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.business
              )}
            />
          </div>
        </div>

        <HokengaiServiceTable
          hokengaiService={contents.blocks.hokengaiService}
          afterTable={goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.business
          )}
        />
      </div>
    );
  }
}

BarberAbstract.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default BarberAbstract;
