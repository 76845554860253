import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import GridRow from "../grid/GridRow";
import GridCell from "../grid/GridCell";
import ColorCatalog from "../color/ColorCatalog";
import SplitArrayTable from "../../util/SplitArrayTable";
import CategorizedOptions from "../../util/CategorizedMasterData";

class OptionsRadio extends React.Component {
  static checked(value) {
    if (value === "true") return true;
    if (value === "yes") return true;
    if (value === "on") return true;
    if (value === "1") return true;
    if (value === true) return true;
    if (value === 1) return true;
    return false;
  }

  formControlProps() {
    const helperText = this.helperText();
    return {
      error: helperText ? true : false,
      margin: "normal",
      style: styles.formControl,
    };
  }

  helperText() {
    const { name } = this.props;
    return this.props.inputFeedback.errors.getByName(name);
  }

  radioGroupProps() {
    const { form, name, onChange: change } = this.props;
    return {
      name,
      onChange: (e) => change(name, e.target.value),
      value: String(form.getByName(name)),
    };
  }

  options() {
    const { options } = this.props;
    return this.optionsOf(options);
  }

  categorizedOptions() {
    const { options } = this.props;
    return new CategorizedOptions(options).getCategories().map((category) => ({
      label: category.label,
      options: this.optionsOf(category.options),
    }));
  }

  optionsOf(options) {
    const { name, disabled } = this.props;
    return options.map((option) => {
      const formControlLabelProps = {
        control: <Radio color="primary" />,
        disabled,
        label: option.label,
        value: option.code,
        style: styles.formControlLabel,
      };
      return (
        <FormControlLabel
          key={`${name}-${option.code}`}
          {...formControlLabelProps}
        />
      );
    });
  }

  split(options) {
    const { split = 3 } = this.props;
    return new SplitArrayTable(split).make(options).fill().getTable();
  }

  render() {
    const { categorize, labelText } = this.props;
    const helperText = this.helperText();
    return (
      <FormControl {...this.formControlProps()}>
        {labelText ? <FormLabel>{labelText}</FormLabel> : null}
        {categorize ? this.renderCategorizedOptions() : this.renderOptions()}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }

  renderOptions() {
    return this.renderOptionsOf(this.options());
  }

  renderCategorizedOptions() {
    return this.categorizedOptions().map((category, categoryIndex) => (
      <Fragment key={categoryIndex}>
        <div style={styles.category}>▼ {category.label}</div>
        {this.renderOptionsOf(category.options)}
      </Fragment>
    ));
  }

  renderOptionsOf(options) {
    return this.split(options).map((options, optionsIndex) => (
      <GridRow key={optionsIndex}>
        {options.map((option, optionIndex) => (
          <GridCell key={optionIndex}>
            <RadioGroup {...this.radioGroupProps()}>{option}</RadioGroup>
          </GridCell>
        ))}
      </GridRow>
    ));
  }
}

const styles = {
  formControl: {
    width: "100%",
  },
  formControlLabel: {
    width: "100%",
    fontSize: "small",
    marginTop: -8,
  },
  category: {
    color: ColorCatalog.gray,
    fontSize: "small",
    marginBottom: 6,
    marginTop: 6,
  },
};

OptionsRadio.propTypes = {
  categorize: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  inputFeedback: PropTypes.object.isRequired,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  split: PropTypes.number,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionsRadio);

function mapStateToProps(state) {
  return {
    inputFeedback: state.inputFeedback,
  };
}

function mapDispatchToProps() {
  return {};
}
