import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ValueWithMenuUnit from "../../../../../app/ui/unit/ValueWithMenuUnit";

const MenuPlanBlock = ({ menuplan }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="メニュー・プラン例" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>メニュー名</th>
              <td>
                {<ValueWithMenuUnit item={menuplan.item} value="title" />}
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height40 tbody-td_textAlign_left">
              <th>詳細</th>
              <td>
                {<ValueWithMenuUnit item={menuplan.item} value="details" />}
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>料金</th>
              <td>
                {<ValueWithMenuUnit item={menuplan.item} value="ryokin" />}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

MenuPlanBlock.propTypes = {
  menuplan: PropTypes.object.isRequired,
};

export default MenuPlanBlock;
