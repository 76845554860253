import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DanjohiBarChart from "../../../../../app/ui/chart/DanjohiBarChart";
import YokaigodohiBarChart from "../../../../../app/ui/chart/YokaigodohiBarChart";
import HeaderTextBlue from "../general/HeaderTextBlue";

const RiyoshaBlock = ({ riyosha }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="利用者情報" />
      <DanjohiBarChart danjohi={riyosha} />
      <YokaigodohiBarChart riyosha={riyosha} />
    </Fragment>
  );
};

RiyoshaBlock.propTypes = {
  riyosha: PropTypes.object.isRequired,
};

export default RiyoshaBlock;
