import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import {
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
  withMasterData,
} from "../../../../../state/master-data";
import { OPTIONS_ALLOWABILITY } from "../../../../../app/ui/form";
import Textarea from "../../../../../app/ui/form/Textarea";

class TsushokeiServiceJigyoshoSonotaForm extends React.Component {
  render() {
    return <Fragment>{this.renderSonotaService()}</Fragment>;
  }

  renderSonotaService() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="その他サービス">
        <HorizontalLabelLayout labelText="喫煙" required>
          <OptionsRadio
            name="kitsuen"
            split={6}
            options={OPTIONS_ALLOWABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="飲酒" required>
          <OptionsRadio
            name="inshu"
            split={6}
            options={OPTIONS_ALLOWABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="マッサージ" required>
          <OptionsRadio
            name="massage"
            split={6}
            options={OPTIONS_ALLOWABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="ペット持ち込み" required>
          <OptionsRadio
            name="petMochikomi"
            split={6}
            options={OPTIONS_ALLOWABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

TsushokeiServiceJigyoshoSonotaForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(TsushokeiServiceJigyoshoSonotaForm);
