import React, { Fragment } from "react";
import PropTypes from "prop-types";

const YoyakuShiharaiTable = ({ yoyakuShiharai, afterTable }) => {
  return (
    <Fragment>
      <div className="c-blue-heading">
        <div className="c-blue-heading__text">予約方法・お支払いについて</div>
      </div>

      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>事前予約（連絡）</th>
              <td>{yoyakuShiharai.jizentorokuYouhi}</td>
            </tr>
            <tr>
              <th>予約方法</th>
              <td>{yoyakuShiharai.yoyakuHouhou.join("、")}</td>
            </tr>
            <tr>
              <th>お支払い方法</th>
              <td>{yoyakuShiharai.shiharaiHouhou.join("、")}</td>
            </tr>
          </tbody>
        </table>

        {afterTable}
      </div>
    </Fragment>
  );
};

YoyakuShiharaiTable.propTypes = {
  yoyakuShiharai: PropTypes.object.isRequired,
  afterTable: PropTypes.node,
};

export default YoyakuShiharaiTable;
