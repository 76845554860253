import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ServiceShubetsuCategoryType from "../../../../app/domain/jigyosho/ServiceShubetsuCategoryType";
import Mount from "../../../../app/ui/layout/Mount";

const KatsudokayoinobaSonotaMapPrintCard = ({ service }) => {
  const {
    hokengaiServiceKatsudoKayoinoba,
    hokengaiServiceSonotaBenriService,
  } = service.blocks;
  const hokengaiService =
    hokengaiServiceKatsudoKayoinoba || hokengaiServiceSonotaBenriService;

  return (
    <div className="map-print-items-item">
      <div className="map-print-items-item__header">
        <div className="map-print-items-item__number">{service.number}</div>
        <div className="map-print-items-item__jigyoshomei">
          {service.jigyoshomei}
        </div>
      </div>
      <dl className="map-print-items-item__attributes">
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">住所</dt>
          <dd className="map-print-items-item__attributes-item-value">
            {service.jusho}
          </dd>
        </div>
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">TEL</dt>
          <dd className="map-print-items-item__attributes-item-value">
            {hokengaiService.denwabango}
          </dd>
        </div>
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">
            {eigyobiLabel(service.serviceShubetsuCategoryCode)}
          </dt>
          <dd className="map-print-items-item__attributes-item-value">
            {hokengaiService.eigyobi.join("、")}
          </dd>
        </div>

        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">料金</dt>
          <dd className="map-print-items-item__attributes-item-value">
            {hokengaiService.ryokin
              .map((labelAndValue) => labelAndValue.join(""))
              .join("、")}
          </dd>
        </div>
        <Mount if={hokengaiServiceKatsudoKayoinoba}>
          <div className="map-print-items-item__attributes-item">
            <dt className="map-print-items-item__attributes-item-label">
              活動内容種類
            </dt>
            <dd className="map-print-items-item__attributes-item-value">
              {hokengaiService.businessMenu}
            </dd>
          </div>
        </Mount>
        <Mount if={hokengaiServiceSonotaBenriService}>
          <div className="map-print-items-item__attributes-item">
            <dt className="map-print-items-item__attributes-item-label">
              キャッチフレーズ
            </dt>
            <dd className="map-print-items-item__attributes-item-value">
              {hokengaiService.catchPhrase}
            </dd>
          </div>
        </Mount>
      </dl>
    </div>
  );

  function eigyobiLabel(serviceShubetsuCategoryCode) {
    if (
      serviceShubetsuCategoryCode ===
      ServiceShubetsuCategoryType.活動_通いの場.code
    ) {
      return (
        <Fragment>
          営業日
          <br />
          (開催日)
        </Fragment>
      );
    } else {
      return <Fragment>営業日</Fragment>;
    }
  }
};

KatsudokayoinobaSonotaMapPrintCard.propTypes = {
  service: PropTypes.object.isRequired,
};

export default KatsudokayoinobaSonotaMapPrintCard;
