import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import StaticLabel from "./StaticLabel";
import DummyError from "./DummyError";
import { removePropertyFromProps } from "../../fundamental/react/props";

class DummyLabel extends React.Component {
  helperText() {
    const { name } = this.props;
    return this.props.inputFeedback.errors.getByName(name);
  }

  render() {
    const { form, name } = this.props;
    const value = form.getByName(name);
    return (
      <div>
        <StaticLabel {...removePropertyFromProps("inputFeedback", this.props)}>
          {value ? value : <span>&nbsp;</span>}
        </StaticLabel>
        <DummyError name={name} />
      </div>
    );
  }
}

DummyLabel.propTypes = {
  form: PropTypes.object.isRequired,
  inputFeedback: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(DummyLabel);

function mapStateToProps(state) {
  return {
    inputFeedback: state.inputFeedback,
  };
}

function mapDispatchToProps() {
  return {};
}
