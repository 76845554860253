import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Textarea from "../../../../../app/ui/form/Textarea";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import { IconButton } from "../../../../../../node_modules/@material-ui/core";
import {
  MASTER_DATA_BUNDLE_FOR_SHORTSTAY,
  withMasterData,
} from "../../../../../state/master-data";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import FlatPrimaryButton from "../../../../../app/ui/button/FlatPrimaryButton";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";

class ShortstaySonotaServiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleSonotaServiceJiyunyuryokuAdded = this.handleSonotaServiceJiyunyuryokuAdded.bind(
      this
    );
    this.handleRemove = this.handleRemove.bind(this);
  }

  // 項目を追加する。
  handleSonotaServiceJiyunyuryokuAdded() {
    this.appendSonotaServiceJiyunyuryoku({
      shubetsuCode: "999",
      sonota: "",
    });
  }

  // 項目削除
  handleRemove(index) {
    this.removeSonotaServiceJiyunyuryoku(index);
  }

  // Form取得
  getSonotaServiceJiyunyuryoku() {
    const { bindingProps } = this.props;
    return [].concat(bindingProps.form.getByName("sonotaServiceJiyunyuryoku"));
  }

  // Form追加
  appendSonotaServiceJiyunyuryoku(form) {
    const { bindingProps } = this.props;
    const org = this.getSonotaServiceJiyunyuryoku();
    bindingProps.onChange("sonotaServiceJiyunyuryoku", org.concat([form]));
  }

  // Form削除
  removeSonotaServiceJiyunyuryoku(index) {
    const { bindingProps } = this.props;
    const org = this.getSonotaServiceJiyunyuryoku();
    bindingProps.onChange(
      "sonotaServiceJiyunyuryoku",
      org.filter((_, i) => i != index)
    );
  }

  render() {
    const { bindingProps, masterData } = this.props;
    const sonotaServiceOptions = masterData.shortstay_SonotaService.filter(
      (it) => it.code != "999"
    );
    return (
      <Fragment>
        <FormGrouping title="その他サービス">
          <HorizontalLabelLayout labelText="その他サービス">
            <OptionsCheckbox
              name="sonotaShubetu"
              split={3}
              options={sonotaServiceOptions}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          {this.renderSonotaServiceJiyunyuryoku()}
          <HorizontalLabelLayout labelText="備考">
            <Textarea name="biko" {...bindingProps} />
          </HorizontalLabelLayout>
        </FormGrouping>
      </Fragment>
    );
  }

  renderSonotaServiceJiyunyuryoku() {
    const { bindingProps } = this.props;
    return (
      <HorizontalLabelLayout
        labelText={
          <span>
            その他サービス
            <br />
            （自由入力）
          </span>
        }
      >
        {this.getSonotaServiceJiyunyuryoku().map((sonota, index) => {
          const name = `sonotaServiceJiyunyuryoku.${index}`;
          return (
            <GridRow key={index}>
              <GridCell>
                <InputTypeText
                  hintText="自由入力"
                  name={`${name}.sonota`}
                  {...bindingProps}
                />
              </GridCell>
              <GridCell grow={2}>
                <IconButton onClick={() => this.handleRemove(index)}>
                  <DeleteIcon />
                </IconButton>
              </GridCell>
            </GridRow>
          );
        })}
        <GridRow>
          <GridCell style={{ paddingTop: 10 }}>
            <FlatPrimaryButton
              onClick={this.handleSonotaServiceJiyunyuryokuAdded}
            >
              項目を追加する
            </FlatPrimaryButton>
          </GridCell>
        </GridRow>
      </HorizontalLabelLayout>
    );
  }
}

ShortstaySonotaServiceForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SHORTSTAY)(
  ShortstaySonotaServiceForm
);
