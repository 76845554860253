import React, { Fragment } from "react";
import PropTypes from "prop-types";

const TruncateText = ({ maxLength, children, abbr }) => {
  let text = children;
  if (text == null) return "";

  if (text.length > maxLength) {
    text = text.substring(0, maxLength) + (abbr || "…");
  }
  return <Fragment>{text}</Fragment>;
};

TruncateText.propTypes = {
  maxLength: PropTypes.number.isRequired,
  children: PropTypes.string,
  abbr: PropTypes.string,
};

export default TruncateText;
