import React, { Fragment } from "react";
import PropTypes from "prop-types";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";

const StaffTable = ({ contents }) => {
  const { kyotakukaigoshienjigyoshoStaff: staff } = contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>男女別従業員数</th>
                  <td className="val">
                    <ul>
                      <li>
                        <div className="val-name">男性</div>
                        <div className="val-val">{staff.danseiNinzu}</div>
                      </li>
                      <li>
                        <div className="val-name">女性</div>
                        <div className="val-val">{staff.joseiNinzu}</div>
                      </li>
                      <li>
                        <div className="val-name">合計</div>
                        <div className="val-val">{staff.gokeiNinzu}</div>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="p-office-body-sep__left">
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">職員数</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">
                    介護支援専門員
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {staff.kaigoshienSemmonin}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    経験年数5年以上のケアマネジャー
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {staff.keiken5nenWariai}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>管理者保有資格</th>
                  <td>{staff.kanrishahoyushikaku}</td>
                </tr>
              </tbody>
            </table>
          </div>

          {goToJigyoshoAttributeFormButton(
            contents,
            JigyoshoAttributeType.kyotakukaigoshienjigyoshoStaff
          )}
        </div>
      </div>
    </Fragment>
  );
};

StaffTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default StaffTable;
