import ActionType from "./reducer/ActionType";

export function leadToLogin(message) {
  return {
    type: ActionType.LEAD_TO_LOGIN,
    payload: {
      message,
    },
  };
}

export function openMessage(message) {
  return {
    type: ActionType.OPEN_MESSAGE,
    payload: {
      message,
    },
  };
}

export function closeMessage() {
  return {
    type: ActionType.CLOSE_MESSAGE,
  };
}
