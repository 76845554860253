import React from "react";
import InputTypeText from "./InputTypeText";

const InputTypeTime = (props) => {
  return <InputTypeText type="time" {...inputTypeTextProps(props)} />;
};

function inputTypeTextProps(props) {
  return {
    ...props,
    hintText: "00:00",
    inputProps: { step: 1800 /* 30 min */ },
  };
}

export default InputTypeTime;
