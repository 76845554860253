import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import Mount from "../../../../../app/ui/layout/Mount";
import ExternalLinkedUrl from "../../../../../app/ui/link/ExternalLinkedUrl";

const JigyoshoJohoTable = ({ contents, detail, print }) => {
  const {
    serviceShubetsu,
    blocks: { kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho },
  } = contents;
  return (
    <Fragment>
      <div className="p-office-body-table u-margin-b32">
        <table>
          <tbody>
            <Mount if={!print}>
              <tr>
                <th>事業所名</th>
                <td>
                  {kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.jigyoshomei}
                </td>
              </tr>
              <tr>
                <th>サービス種別</th>
                <td>{serviceShubetsu}</td>
              </tr>
            </Mount>
            <tr>
              <th>TEL</th>
              <td>{kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.tel}</td>
            </tr>
            <tr>
              <th>FAX</th>
              <td>{kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.fax}</td>
            </tr>
            <tr>
              <th>事業所番号</th>
              <td>
                {kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.jigyoshobango}
              </td>
            </tr>
            <tr>
              <th>事業開始日</th>
              <td>
                {kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.jigyokaishibi}
              </td>
            </tr>
            <tr>
              <th>住所</th>
              <td>
                〒{kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.yubimbango}{" "}
                {kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.jusho}
              </td>
            </tr>
            <tr>
              <th>運営法人</th>
              <td>{kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.uneihojin}</td>
            </tr>
            <tr>
              <th>管理者名</th>
              <td>
                {kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.kanrishamei}
              </td>
            </tr>
            <Mount if={detail}>
              <tr>
                <th>ホームページ</th>
                <td>
                  <ExternalLinkedUrl
                    url={
                      kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.homepageUrl
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>併設サービス</th>
                <td>
                  {kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.heisetsuService.join(
                    "、"
                  )}
                </td>
              </tr>
              <tr>
                <th>キャッチフレーズ</th>
                <td>
                  {kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.catchPhrase}
                </td>
              </tr>
              <tr>
                <th>特徴・理念</th>
                <td>
                  {kyotakukaigoshienjigyoshoHokennaiJigyoshoJoho.tokuchoRinen}
                </td>
              </tr>
            </Mount>
          </tbody>
        </table>
      </div>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.jigyoshoJoho
      )}
    </Fragment>
  );
};

JigyoshoJohoTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default JigyoshoJohoTable;
