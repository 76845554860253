import { StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
  icon: {
    margin: "0 5px",
    verticalAlign: "middle",
  },
  editAgentsTabele: {
    marginTop: 50,
  },
  editAgentsTabeleRow: {
    height: 30,
  },
  editAgentsTabeleHeadCell: {
    fontWeight: "bold",
  },
  editAgentsTabeleBodyCell: {
    color: "rgba(0, 0, 0, 0.54)",
  },
});

export default styles;
