import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";
import Parametername from "../../app/naming/Parametername";

export function uploadFiles(files, callback) {
  return (dispatch) => {
    const form = new FormData();
    files.forEach((file) => form.append(Parametername.files, file));
    postToUploadFiles(dispatch, form, callback);
  };
}

function postToUploadFiles(dispatch, form, callback) {
  const url = ApiPath.api_io_files;
  post(dispatch, url, form, callback, "アップロードしました。");
}

function post(dispatch, url, form, callback = () => {}, message) {
  const onSuccess = (json) => {
    setTimeout(() => callback(json));
  };
  const onFailure = () => {};
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}
