import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import ServiceContentType from "../../../page/webfront/service/ServiceContentType";

const initialState = {
  id: null,
  contentsIsLoading: false,
  contents: {
    [ServiceContentType.abstract.name]: null,
    [ServiceContentType.detail.name]: null,
    [ServiceContentType.photo.name]: null,
    [ServiceContentType.flier.name]: null,
    [ServiceContentType.comment.name]: null,
  },
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.RESET]: function () {
    return initialState;
  },
  [ActionType.REQUEST]: function (state) {
    return {
      ...state,
      contentsIsLoading: true,
    };
  },
  [ActionType.REQUEST_FINISHED]: function (state, action) {
    const { id, serviceContentType, contents } = action.payload;
    return {
      ...state,
      id: id,
      contentsIsLoading: false,
      contents: {
        ...state.contents,
        [serviceContentType.name]: contents,
      },
    };
  },
  [ActionType.APPEND_COMMENT_TO_VISIBLE_SURFACE]: function (state, action) {
    const { dummyComment } = action.payload;
    const makeList = (list) => [dummyComment].concat(list);
    return updateCommentList(makeList, state);
  },
  [ActionType.MODIFY_COMMENT_ON_VISIBLE_SURFACE]: function (state, action) {
    const { dummyComment } = action.payload;
    const makeList = (list) =>
      list.map((it) => (it.id === dummyComment.id ? dummyComment : it));
    return updateCommentList(makeList, state);
  },
  [ActionType.REMOVE_COMMENT_FROM_VISIBLE_SURFACE]: function (state, action) {
    const { id } = action.payload;
    const makeList = (list) => list.filter((it) => it.id !== id);
    return updateCommentList(makeList, state);
  },
});

function updateCommentList(makeList, state) {
  const { contents } = state;
  const commentTabContents = contents[ServiceContentType.comment.name];
  const { blocks } = commentTabContents;
  const { comments } = blocks;
  const { list } = comments;
  const newList = makeList(list);
  const commentCount = newList.length;
  return {
    ...state,
    contents: {
      ...contents,
      [ServiceContentType.comment.name]: {
        ...commentTabContents,
        commentCount: commentCount,
        blocks: {
          ...blocks,
          comments: {
            ...comments,
            list: newList,
          },
        },
      },
      [ServiceContentType.abstract.name]: updateCommentCountIfExists(
        contents[ServiceContentType.abstract.name],
        commentCount
      ),
      [ServiceContentType.detail.name]: updateCommentCountIfExists(
        contents[ServiceContentType.detail.name],
        commentCount
      ),
      [ServiceContentType.photo.name]: updateCommentCountIfExists(
        contents[ServiceContentType.photo.name],
        commentCount
      ),
      [ServiceContentType.flier.name]: updateCommentCountIfExists(
        contents[ServiceContentType.flier.name],
        commentCount
      ),
    },
  };
}

function updateCommentCountIfExists(contents, commentCount) {
  if (!contents) return contents;
  return { ...contents, commentCount };
}

export default reducers.createReducer(initialState);
