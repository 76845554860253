import React from "react";
import PropTypes from "prop-types";

const DanjohiBarChart = (props) => {
  const {
    danseininzu,
    joseininzu,
    danseihiritsu,
    joseihiritsu,
  } = props.danjohi;
  const isNullHiritsu = danseihiritsu == null && joseihiritsu == null;
  const hidden_dansei = !isNullHiritsu && danseihiritsu < 10;
  const hidden_josei = !isNullHiritsu && joseihiritsu < 10;
  return (
    <div className="danjo-bar-chart">
      <div style={styles(props).heading_text}>男女比</div>
      <div style={styles(props).container}>
        <div style={styles(props, isNullHiritsu).dansei}>
          <img style={styles(props).img} src="/images/print_bg_blue.png" />
          <div hidden={hidden_dansei} style={styles(props).dansei_text}>
            男性
            <br />
            {isNullHiritsu ? "ー" : danseininzu + "人"}
          </div>
        </div>
        <div style={styles(props, isNullHiritsu).josei}>
          <img
            style={styles(props).img}
            src="/images/print_bg_light_pink.png"
          />
          <div hidden={hidden_josei} style={styles(props).josei_text}>
            女性
            <br />
            {isNullHiritsu ? "ー" : joseininzu + "人"}
          </div>
        </div>
      </div>
      <div style={styles(props).hidden_div}>
        <span hidden={!hidden_dansei} style={styles(props).hidden_text}>
          <img
            style={styles(props).hidden_img}
            src="/images/print_bg_blue.png"
          />
          男性 {danseininzu}人
        </span>
        <span hidden={!hidden_josei} style={styles(props).hidden_text}>
          <img
            style={styles(props).hidden_img}
            src="/images/print_bg_light_pink.png"
          />
          女性 {joseininzu}人
        </span>
      </div>
    </div>
  );
};

const styles = (props, isNullHiritsu) => ({
  heading_text: {
    fontWeight: "bold",
    marginTop: "3pt",
    marginBottom: "3pt",
    paddingLeft: "5pt",
  },
  text_area: {
    position: "relative",
  },
  container: {
    fontSize: "10px",
    width: "100%",
    height: "37px",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    marginBottom: "5pt",
  },
  dansei: {
    width: `${isNullHiritsu ? 50 : props.danjohi.danseihiritsu}%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  josei: {
    width: `${isNullHiritsu ? 50 : props.danjohi.joseihiritsu}%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  dansei_text: {
    position: "absolute",
    top: "3pt",
    left: "0",
    width: "100%",
    textAlign: "center",
  },
  josei_text: {
    position: "absolute",
    top: "3pt",
    left: "0",
    width: "100%",
    textAlign: "center",
  },
  hidden_div: {
    marginLeft: "5pt",
    marginBottom: "5pt",
  },
  hidden_text: {
    marginRight: "10pt",
  },
  img: {
    width: "100%",
    height: "100%",
  },
  hidden_img: {
    width: "7pt",
    height: "7pt",
    marginRight: "3pt",
  },
});

DanjohiBarChart.propTypes = {
  danjohi: PropTypes.object.isRequired,
};

export default DanjohiBarChart;
