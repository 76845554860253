class JwtToken {
  constructor(value) {
    this.value = value;
  }

  static ofHeaders(headers) {
    const token = headers.get("Authorization").replace("Bearer ", "");
    return new JwtToken(token);
  }

  toHeaderObject() {
    return {
      Authorization: `Bearer ${this.value}`,
    };
  }
}

export default JwtToken;
