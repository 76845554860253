import ActionType from "./reducer/ActionType";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import ApiPath from "../../app/fundamental/request/ApiPath";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
} from "../../app/fundamental/request";

export function reset(id) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REQUEST,
    });
    getJigyoshoStatus(dispatch, id);
  };
}

function getJigyoshoStatus(dispatch, id) {
  const url = ApiPath.api_jigyosho_id_status.replace(/:id/, id);
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        id: json.id,
        serviceShubetsuCategoryCode: json.serviceShubetsuCategoryCode,
        serviceShubetsuCode: json.serviceShubetsuCode,
        jigyoshomei: json.jigyoshomei,
        attributes: json.attributes,
        flier: json.flier,
        photo: json.photo,
        editAgents: json.editAgents,
        privateFlg: json.privateFlg,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}
