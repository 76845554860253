import React, { Fragment } from "react";
import PropTypes from "prop-types";

const RyokinNyuyoku = ({ contents }) => {
  const { homonkeiServiceRyokin: ryokin } = contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-title">介護報酬加算</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>共通</th>
              <td>{ryokin.kaigohoshukasanKyotsu.join("、")}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="p-office-body-title">利用料金の目安</div>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">要支援1〜2</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">全身浴</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.nyuyokuYoshien}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    清拭・部分浴
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.seishikiYoshien}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="p-office-body-sep__right">
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">要介護1〜5</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">全身浴</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.nyuyokuYokaigo}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    清拭・部分浴
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.seishikiYokaigo}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

RyokinNyuyoku.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default RyokinNyuyoku;
