import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";

const initialState = {};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.REQUEST_FINISHED]: function (state, action) {
    return {
      ...state,
      [action.payload.name]: action.payload.data,
    };
  },
});

export default reducers.createReducer(initialState);
