import PropTypes from "prop-types";

const ValueWithCarUnit = ({ value }) => {
  if (value == null) return "";
  return `${value}台`;
};

ValueWithCarUnit.propTypes = {
  value: PropTypes.number,
};

export default ValueWithCarUnit;
