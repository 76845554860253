import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { renderStaffJokinHijokin } from "./StaffTable";
import StaffShokushuTable from "./StaffShokushuTable";

const StaffShikakuKaigo = (props) => {
  const { homonkeiServiceStaff: staff } = props.contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-title">訪問介護員</div>
          <StaffShokushuTable shokushu={staff.homonkaigoin} />
        </div>

        <div className="p-office-body-sep__left">
          <div className="p-office-body-title">資格保有者数</div>
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>介護職員実務者研修修了者</th>
                  <td>
                    {renderStaffJokinHijokin(
                      staff.kaigoshokuinjitsumushakenshu
                    )}
                  </td>
                </tr>
                <tr>
                  <th>介護職員初任者研修</th>
                  <td>
                    {renderStaffJokinHijokin(staff.kaigoshokuinshoninshakenshu)}
                  </td>
                </tr>
                <tr>
                  <th>介護福祉士</th>
                  <td>{renderStaffJokinHijokin(staff.kaigofukushishi)}</td>
                </tr>
                <tr>
                  <th>介護プロフェッショナルキャリア段位</th>
                  <td>{staff.kaigoProfessionalCareerDani}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

StaffShikakuKaigo.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default StaffShikakuKaigo;
