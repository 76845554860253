import ActionType from "./reducer/ActionType";
import AuthenticationRequest from "../../app/fundamental/request/AuthenticationRequest";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import ApiPath from "../../app/fundamental/request/ApiPath";
import JwtTokenRepository from "../../app/fundamental/request/JwtTokenRepository";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
} from "../../app/fundamental/request";

export function resetLoginForm() {
  return {
    type: ActionType.RESET_LOGIN_FORM,
  };
}

export function changeLoginForm(name, value) {
  return {
    type: ActionType.CHANGE_LOGIN_FORM,
    payload: {
      name,
      value,
    },
  };
}

export function login(form) {
  return (dispatch) => {
    dispatch({
      type: ActionType.LOGIN,
      payload: {
        form,
      },
    });
    const normalScenario = () =>
      dispatch({
        type: ActionType.LOGIN_WAS_ACCEPTED,
      });
    const exceptionScenario = () =>
      dispatch({
        type: ActionType.LOGIN_WAS_DENIED,
      });
    AuthenticationRequest.post(form).request(
      defaultResponseHandler(dispatch, normalScenario),
      exceptionScenario
    );
  };
}

export function loadUser() {
  return (dispatch) => {
    dispatch({
      type: ActionType.LOAD_USER,
    });

    const onSuccess = (json) =>
      dispatch({
        type: ActionType.LOAD_USER_FINISHED,
        payload: {
          user: json,
        },
      });
    const onFailure = () =>
      dispatch({
        type: ActionType.LOAD_USER_FAILED,
      });
    ApiRequest.get(ApiPath.api_authentication_user).request(
      defaultResponseHandler(dispatch, onSuccess, onFailure),
      onFailure
    );
  };
}

export function logout() {
  new JwtTokenRepository().remove();
  return {
    type: ActionType.LOGOUT,
  };
}

export function agree() {
  return (dispatch) => {
    dispatch({
      type: ActionType.AGREE,
    });
    const onSuccess = (json) => {
      dispatch({
        type: ActionType.AGREE_FINISHED,
        payload: {
          agreedAt: json.agreedAt,
        },
      });
    };
    ApiRequest.post(ApiPath.api_authentication_agree).request(
      defaultResponseHandler(dispatch, onSuccess),
      defaultUnauthorizedHander(dispatch)
    );
  };
}
