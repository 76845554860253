import React from "react";
import PropTypes from "prop-types";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import connect from "react-redux/es/connect/connect";
import Pathname from "../../../../app/naming/Pathname";
import { push } from "react-router-redux";
import {
  changeForm,
  request,
  submitToEditPhoto,
} from "../../../../state/job-offer-jigyosho-photo/actions";
import Parametername from "../../../../app/naming/Parametername";
import Margin from "../../../../app/ui/layout/Margin";
import FixedTable from "../../../../app/ui/table/FixedTable";
import { Paper, TableBody, TableHead, TableRow } from "@material-ui/core";
import SmallPaddingTableCell from "../../../../app/ui/table/SmallPaddingTableCell";
import StaticLabel from "../../../../app/ui/form/StaticLabel";
import InputTypeCheckbox from "../../../../app/ui/form/InputTypeCheckbox";
import BackOfficeHelp from "../../../../app/ui/structure/BackOfficeHelp";
import BackOfficeHelpItem from "../../../../app/ui/structure/BackOfficeHelpItem";
import Card from "@material-ui/core/Card";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";

class JobOfferJigyoshoPhotoEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.jigyoshoId = props.match.params.jigyoshoId;

    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleDisplayToggleButtonClick = this.handleDisplayToggleButtonClick.bind(
      this
    );
    this.handleGotoJigyoshoStatusPageButtonClick = this.handleGotoJigyoshoStatusPageButtonClick.bind(
      this
    );
  }

  componentDidMount() {
    this.props.loadForm(this.jigyoshoId);
  }

  handleBackButtonClick() {
    const searchParams = new URLSearchParams(window.location.search);
    const pathname =
      searchParams.get(Parametername.pathname) || Pathname.backoffice_job_offer;
    this.props.goto(pathname);
  }

  handleDisplayToggleButtonClick(photoId) {
    this.props.submitForm(
      this.jigyoshoId,
      photoId,
      this.props.jobOfferJigyoshoPhoto.form[photoId]
    );
  }

  handleGotoJigyoshoStatusPageButtonClick() {
    this.props.goto(
      Pathname.backoffice_jigyosho_id_status.replace(/:id/, this.jigyoshoId)
    );
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          <BackOfficeHead>求人情報に表示する事業所写真の設定</BackOfficeHead>

          <BackOfficeNavigation>
            <FlatPrimaryButton onClick={this.handleBackButtonClick}>
              <BackIcon /> 戻る
            </FlatPrimaryButton>
          </BackOfficeNavigation>

          <BackOfficeHelp>
            <BackOfficeHelpItem text="登録している事業所の写真のうち、求人情報には表示させたくないものは非表示に設定することができます。" />
            <BackOfficeHelpItem
              text="写真の追加・変更・削除はこちら"
              action={
                <FlatPrimaryButton
                  onClick={this.handleGotoJigyoshoStatusPageButtonClick}
                >
                  事業所情報の編集
                </FlatPrimaryButton>
              }
            />
          </BackOfficeHelp>

          {this.renderPhotos()}
        </SideMargin>
      </Boundary>
    );
  }

  renderPhotos() {
    const { jobOfferJigyoshoPhoto } = this.props;
    const { form, list } = jobOfferJigyoshoPhoto;

    if (list.length === 0) return this.renderNoImage();
    return (
      <Margin top>
        <Paper>
          <FixedTable>
            <TableHead>
              <TableRow>
                <SmallPaddingTableCell
                  style={{ width: "40px", textAlign: "center" }}
                >
                  表示
                </SmallPaddingTableCell>
                <SmallPaddingTableCell>写真</SmallPaddingTableCell>
                <SmallPaddingTableCell style={{ width: "150px" }}>
                  カテゴリ
                </SmallPaddingTableCell>
                <SmallPaddingTableCell>説明</SmallPaddingTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {list.map((photo) => (
                <TableRow
                  key={photo.id}
                  style={{
                    background: form[photo.id].visible ? "none" : "#cccccc",
                  }}
                >
                  <SmallPaddingTableCell style={{ textAlign: "center" }}>
                    <InputTypeCheckbox
                      name={`${photo.id}.visible`}
                      form={form}
                      onChange={(name, value) =>
                        this.props.changeForm(name, value, () =>
                          this.handleDisplayToggleButtonClick(photo.id)
                        )
                      }
                      labelStyle={{ marginLeft: 0, marginRight: 0 }}
                    />
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    <a href={photo.filePath} target="photo">
                      <img
                        style={{ maxWidth: "90%" }}
                        src={photo.filePath}
                        alt={photo.originalFilename}
                        title={photo.originalFilename}
                      />
                    </a>
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    <StaticLabel>{photo.category}</StaticLabel>
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    <StaticLabel>{photo.description}</StaticLabel>
                  </SmallPaddingTableCell>
                </TableRow>
              ))}
            </TableBody>
          </FixedTable>
        </Paper>
      </Margin>
    );
  }

  renderNoImage() {
    return (
      <Margin top>
        <Card>
          <SideMargin top bottom>
            写真が登録されていません。
            <RaisedPrimaryButton
              onClick={this.handleGotoJigyoshoStatusPageButtonClick}
            >
              事業所情報の編集
            </RaisedPrimaryButton>
          </SideMargin>
        </Card>
      </Margin>
    );
  }
}

JobOfferJigyoshoPhotoEditPage.propTypes = {
  match: PropTypes.object.isRequired,
  jobOfferJigyoshoPhoto: PropTypes.object.isRequired,
  loadForm: PropTypes.func.isRequired,
  changeForm: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  goto: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    jobOfferJigyoshoPhoto: state.jobOfferJigyoshoPhoto,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadForm: (jigyoshoId) => {
      dispatch(request(jigyoshoId));
    },
    changeForm: (name, value, callback) => {
      dispatch(changeForm(name, value, callback));
    },
    submitForm: (jigyoshoId, photoId, form, callback) => {
      dispatch(submitToEditPhoto(jigyoshoId, photoId, form, callback));
    },
    goto: (pathname) => dispatch(push(pathname)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobOfferJigyoshoPhotoEditPage);
