const DEFAULT_THRESHOLD = 0.00005; // 約5m

/**
 * 緯度経度をもとに、事業所をグルーピングして返す。
 *
 * @param services
 * @param threshold
 * @returns {*[]}
 */
export function groupServicesByLatLng(services, threshold = DEFAULT_THRESHOLD) {
  const servicesWithLatLng = [];
  const servicesWithoutLatLng = [];
  services.forEach((s) => {
    (s.latitude !== "" && s.longitude !== ""
      ? servicesWithLatLng
      : servicesWithoutLatLng
    ).push(s);
  });

  const initialServiceGroups = servicesWithLatLng.map((s) => {
    return {
      latitude: +s.latitude,
      longitude: +s.longitude,
      items: [s],
    };
  });

  const serviceGroups = grouping(initialServiceGroups, threshold).sort(
    (s1, s2) => {
      // 基本的に経度の昇順（西から東）にソート。ただし、経度が同じ場合は緯度の降順（北から南）とする。
      const diffLng = s1.longitude - s2.longitude;
      const diffLat = s2.latitude - s1.latitude;
      return diffLng || diffLat;
    }
  );

  const serviceGroupsWithoutLatLng = servicesWithoutLatLng.map((s) => {
    return {
      latitude: +s.latitude,
      longitude: +s.longitude,
      items: [s],
    };
  });

  return [...serviceGroups, ...serviceGroupsWithoutLatLng].map(
    (serviceGroup, index) => {
      return {
        ...serviceGroup,
        number: index + 1,
      };
    }
  );
}

/**
 * 近い（距離がthreshold以内）事業所同士をまとめる。
 *
 * Note 正確に言うと、緯度と経度で1度あたりの距離は異なるが、計算を簡単にするためにそれらは同じとしている。
 *
 * @param serviceGroups
 * @param threshold
 * @returns {*}
 */
function grouping(serviceGroups, threshold) {
  const ordered = serviceGroups.slice().sort((s1, s2) => {
    return s1.latitude - s2.latitude;
  });
  for (let i = 0; i < ordered.length - 1; i++) {
    for (let j = i + 1; j < ordered.length; j++) {
      const si = ordered[i];
      const sj = ordered[j];
      if (Math.abs(si.latitude - sj.latitude) > threshold) {
        break;
      }
      if (isAlmostSamePlace(si, sj, threshold)) {
        const newServiceGroups = ordered.filter(
          (_, index) => index !== i && index !== j
        );
        newServiceGroups.push({
          latitude:
            (si.latitude * si.items.length + sj.latitude * sj.items.length) /
            (si.items.length + sj.items.length),
          longitude:
            (si.longitude * si.items.length + sj.longitude * sj.items.length) /
            (si.items.length + sj.items.length),
          items: [...si.items, ...sj.items],
        });
        return grouping(newServiceGroups, threshold);
      }
    }
  }
  return ordered;
}

function isAlmostSamePlace(s1, s2, threshold) {
  return (
    Math.sqrt(
      Math.pow(s1.latitude - s2.latitude, 2) +
        Math.pow(s1.longitude - s2.longitude, 2)
    ) <= threshold
  );
}

export function flatten(serviceGroups) {
  const list = serviceGroups.map((sg) => {
    return sg.items.map((s) => {
      return { number: sg.number, ...s };
    });
  });

  return list.reduce((acc, items) => acc.concat(items), []);
}
