import { defaultResponseHandler } from "../../app/fundamental/request";
import PureApiRequest from "../../app/fundamental/request/PureApiRequest";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import { openMessage } from "../notification-message/actions";
import { formDataOf } from "../../app/fundamental/data/FormData";
import ApiPath from "../../app/fundamental/request/ApiPath";

export function zipcodeToAddress(
  zipcode,
  callback = (json) => {
    console.log(json);
  }
) {
  zipcode = cleanZipcode(zipcode);
  return (dispatch) =>
    getZipaddress(dispatch, zipcode, (json) => {
      const ja = json.data[0].ja;
      dispatch(
        openMessage(
          `住所「${
            ja.prefecture + ja.address1 + ja.address2
          }」が見つかりました。`
        )
      );
      postToPostcode(dispatch, json.code);
      setTimeout(() =>
        callback({
          pref: ja.prefecture,
          city: ja.address1,
          town: ja.address2,
          address: ja.address1 + ja.address2, // city + town
          fullAddress: ja.prefecture + ja.address1 + ja.address2, // pref + city + town
        })
      );
    });
}

function cleanZipcode(zipcode) {
  zipcode = zipcode.replace(/[０-９]/g, (s) =>
    String.fromCharCode(s.charCodeAt(0) - 0xfee0)
  );
  zipcode = zipcode.replace(/[ー−]/g, "-");
  zipcode = zipcode.replace(/-/g, "");
  return zipcode;
}

function getZipaddress(dispatch, zipcode, onSuccess) {
  const left = zipcode.substring(0, 3);
  const right = zipcode.substring(3);
  const url = `https://arrow-payment.github.io/postal-code-api/api/v2/${left}/${right}.json`;
  PureApiRequest.get(url).request(defaultResponseHandler(dispatch, onSuccess));
}

function postToPostcode(dispatch, postcode) {
  const url = ApiPath.api_postcode;
  ApiRequest.post(url, formDataOf({ code: postcode })).request(
    defaultResponseHandler(dispatch, () => {}),
    {}
  );
}
