import React from "react";
import PrintTableHead from "../../../../app/ui/print/PrintTableHead";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const ShortstayTableHead = () => {
  return (
    <PrintTableHead height="80px">
      <PrintTableNode width="100px">
        店舗・団体名
        <div className="c-table-frame__updated-at">(最終更新日)</div>
      </PrintTableNode>
      <PrintTableNode width="90px">サービス種別</PrintTableNode>
      <PrintTableNode width="150px">住所</PrintTableNode>
      <PrintTableNode width="112px">TEL</PrintTableNode>
      <PrintTableNode width="60px">
        リハビリ
        <br />
        機能訓練
      </PrintTableNode>
      <PrintTableNode width="15px">送迎</PrintTableNode>
      <PrintTableNode width="160px">居室タイプ</PrintTableNode>
      <PrintTableNode width="188px">受入れ / 対応可能な症状等</PrintTableNode>
      <PrintTableNode width="35px">定員</PrintTableNode>
      <PrintTableNode width="120px">特徴</PrintTableNode>
    </PrintTableHead>
  );
};

export default ShortstayTableHead;
