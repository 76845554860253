import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import OperationDialog from "../../../../app/ui/dialog/OperationDialog";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import Pathname from "../../../../app/naming/Pathname";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import RaisedSecondaryButton from "../../../../app/ui/button/RaisedSecondaryButton";
import * as actions from "../../../../state/hojin-form/actions";
import BackOfficeSubHead from "../../../../app/ui/structure/BackOfficeSubHead";
import Mount from "../../../../app/ui/layout/Mount";

class HojinListOperationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleListButtonClick = this.handleListButtonClick.bind(this);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
    this.handleFeatureButtonClick = this.handleFeatureButtonClick.bind(this);
    this.handleRemoveButtonClick = this.handleRemoveButtonClick.bind(this);
    this.handleRemoveDialogCloseButtonClick = this.handleRemoveDialogCloseButtonClick.bind(
      this
    );
    this.handleRemoveDialogRevemoButtonClick = this.handleRemoveDialogRevemoButtonClick.bind(
      this
    );
    this.handleHojinAdminEditButtonClick = this.handleHojinAdminEditButtonClick.bind(
      this
    );
    this.handlePasswordButtonClick = this.handlePasswordButtonClick.bind(this);
  }

  state = {
    dialogIsOpen: false,
    isDeleteButton: false,
  };

  handleListButtonClick() {
    const { actions, target } = this.props;
    const searchParams = new URLSearchParams();
    searchParams.set("groupId", target.groupId);
    actions.gotoJigyoshoListPage(searchParams.toString());
  }

  handleEditButtonClick() {
    const { actions, target } = this.props;
    actions.gotoEditPage(target.id);
  }

  handleFeatureButtonClick() {
    const { actions, target } = this.props;
    actions.gotoFeaturePage(target.id);
  }

  handleRemoveButtonClick() {
    this.setState({ dialogIsOpen: true, isDeleteButton: false });
  }

  handleRemoveDialogCloseButtonClick() {
    this.setState({ dialogIsOpen: false });
  }

  handleRemoveDialogRevemoButtonClick() {
    const { actions, target } = this.props;
    this.setState({ isDeleteButton: true });
    actions.submitToRemove(target.id, () => {
      this.handleRemoveDialogCloseButtonClick();
      const { actions, search } = this.props;
      actions.reloadPage(search);
    });
  }

  handleHojinAdminEditButtonClick() {
    const { actions, target } = this.props;
    actions.gotoHojinAdminEditPage(target.accountId);
  }

  handlePasswordButtonClick() {
    const { actions, target } = this.props;
    actions.gotoPasswordPage(target.accountId);
  }

  render() {
    return (
      <Fragment>
        {this.renderOperationDialog()}
        {this.renderRemoveDialog()}
      </Fragment>
    );
  }

  renderOperationDialog() {
    const { onClose = () => {}, target, user } = this.props;
    const open = target ? true : false;
    const title = target ? `${target.hojimmei}` : null;
    const username = target ? `(${target.username})` : null;
    return (
      <OperationDialog onClose={onClose} open={open} title={title}>
        <SpaceOut>
          <RaisedPrimaryButton onClick={this.handleListButtonClick}>
            事業所一覧を表示する
          </RaisedPrimaryButton>
        </SpaceOut>
        <Mount if={user.canEditHojin()}>
          <SpaceOut>
            <RaisedPrimaryButton onClick={this.handleEditButtonClick}>
              法人情報を編集する
            </RaisedPrimaryButton>
          </SpaceOut>
        </Mount>
        <Mount if={user.canActivateFeature()}>
          <SpaceOut>
            <RaisedPrimaryButton onClick={this.handleFeatureButtonClick}>
              オプションサービス
            </RaisedPrimaryButton>
          </SpaceOut>
        </Mount>
        <Mount if={user.canRemoveHojin()}>
          <SpaceOut>
            <FlatSecondaryButton onClick={this.handleRemoveButtonClick}>
              削除する
            </FlatSecondaryButton>
          </SpaceOut>
        </Mount>
        <Mount
          if={user.canEditHojinAdmin || user.canChangeHojinAdminPassword()}
        >
          <BackOfficeSubHead>法人管理アカウント {username}</BackOfficeSubHead>
          <Mount if={user.canEditHojinAdmin()}>
            <SpaceOut>
              <RaisedPrimaryButton
                onClick={this.handleHojinAdminEditButtonClick}
              >
                アカウント情報を変更する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Mount>
          <Mount if={user.canChangeHojinAdminPassword()}>
            <SpaceOut>
              <RaisedPrimaryButton onClick={this.handlePasswordButtonClick}>
                パスワード変更する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Mount>
        </Mount>
      </OperationDialog>
    );
  }

  renderRemoveDialog() {
    return (
      <Dialog
        onClose={this.handleRemoveDialogCloseButtonClick}
        open={this.state.dialogIsOpen}
      >
        <DialogTitle>法人削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            法人データを削除します、よろしいですか？
          </DialogContentText>
          <DialogContentText>
            法人データを削除した場合でも、法人が管理していた事業所のデータは削除されないため、引き続き事業所を検索することができます。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton onClick={this.handleRemoveDialogCloseButtonClick}>
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handleRemoveDialogRevemoButtonClick}
            disabled={this.state.isDeleteButton}
          >
            削除する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }
}

HojinListOperationDialog.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  onClose: PropTypes.func,
  search: PropTypes.string,
  target: PropTypes.object,
  user: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HojinListOperationDialog);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gotoEditPage: (id) =>
        dispatch(push(Pathname.backoffice_hojin_id_edit.replace(/:id/, id))),
      gotoFeaturePage: (id) =>
        dispatch(
          push(Pathname.backoffice_hojin_id_features.replace(/:id/, id))
        ),
      gotoJigyoshoListPage: (search) =>
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho,
            search,
          })
        ),
      gotoHojinAdminEditPage: (accountId) =>
        dispatch(
          push(
            Pathname.backoffice_hojin_accounts_id_edit.replace(/:id/, accountId)
          )
        ),
      gotoPasswordPage: (accountId) =>
        dispatch(
          push(
            Pathname.backoffice_hojin_accounts_id_password.replace(
              /:id/,
              accountId
            )
          )
        ),
      submitToRemove: (id, callback) =>
        dispatch(actions.submitToRemove(id, callback)),
      reloadPage: (search) => {
        const searchParams = new URLSearchParams(search);
        searchParams.set("timestamp", new Date().getTime());
        search = searchParams.toString();
        dispatch(push({ search }));
      },
    },
  };
}
