import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

class MainContents extends React.Component {
  render() {
    const { contents } = this.props;
    return (
      <Fragment>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">サービスについて</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>サービスの種類</th>
                    <td>{contents.blocks.isoKaigoTaxi.serviceNoShurui}</td>
                  </tr>
                  <tr>
                    <th>提供サービス</th>
                    <td>
                      {contents.blocks.isoKaigoTaxi.teikyoService.join("、")}
                    </td>
                  </tr>
                  <tr>
                    <th>保有台数</th>
                    <td>{contents.blocks.isoKaigoTaxi.hoyuDaisu}</td>
                  </tr>
                  <tr>
                    <th>同乗者定員（上限）</th>
                    <td>{contents.blocks.isoKaigoTaxi.doujoushaTeiin}</td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td>{contents.blocks.isoKaigoTaxi.biko}</td>
                  </tr>
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.taxi
              )}
            </div>
          </div>

          <div className="p-office-body-sep__right">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">ご利用開始について</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>ご利用開始までの流れ</th>
                    <td>
                      {
                        contents.blocks.hokengaiServiceKaishi
                          .goriyokaishimadenoNagare
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>ご利用可能対象者</th>
                    <td>
                      {
                        contents.blocks.hokengaiServiceKaishi
                          .serviceRiyoKanoTaishosha
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>提供・対象地域</th>
                    <td>
                      {
                        contents.blocks.hokengaiServiceKaishi
                          .serviceTeikyochiiki
                      }
                    </td>
                  </tr>
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.taxi
              )}
            </div>
          </div>
        </div>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">料金について</div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>入会費・登録費（税込み）</th>
                    <td>{contents.blocks.hokengaiRyokin.nyukaihiTorokuhi}</td>
                  </tr>
                  <tr>
                    <th>年会費（税込み）</th>
                    <td>{contents.blocks.hokengaiRyokin.nenkaihi}</td>
                  </tr>
                  <tr>
                    <th>月会費（税込み）</th>
                    <td>{contents.blocks.hokengaiRyokin.tsukikaihi}</td>
                  </tr>
                  <tr>
                    <th>予約手数料（税込み）</th>
                    <td>{contents.blocks.isoKaigoTaxiRyokin.yoyakuTesuryo}</td>
                  </tr>
                  <tr>
                    <th>迎車料（税込み）</th>
                    <td>{contents.blocks.isoKaigoTaxiRyokin.geisharyo}</td>
                  </tr>
                  <tr>
                    <th>キャンセル時の扱いについて</th>
                    <td>
                      {contents.blocks.hokengaiRyokin.cancelJinoatsukainitsuite}
                    </td>
                  </tr>
                  <tr>
                    <th>費用に関する補足</th>
                    <td>
                      {contents.blocks.hokengaiRyokin.hiyonikansuruHosoku}
                    </td>
                  </tr>
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.taxi
              )}
            </div>
          </div>

          <div className="p-office-body-sep__right">
            <div className="c-blue-heading">
              <div className="c-blue-heading__text">
                行政の助成制度や補助について
              </div>
            </div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>使用出来る助成制度</th>
                    <td>
                      {contents.blocks.isoKaigoTaxi.shiyodekiruJoseiseido.join(
                        "、"
                      )}
                    </td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td>{contents.blocks.hokengaiGyoseiJoseiHojo.biko}</td>
                  </tr>
                </tbody>
              </table>

              {goToJigyoshoAttributeFormButton(
                this.props.contents,
                JigyoshoAttributeType.taxi
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

MainContents.propTypes = {
  contents: PropTypes.object.isRequired,
  print: PropTypes.bool,
};

export default MainContents;
