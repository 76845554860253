import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

const PrintHeader = ({ authentication }) => {
  const date = new Date();
  const today = `${date.getFullYear()}年${
    date.getMonth() + 1
  }月${date.getDate()}日`;

  return (
    <div className="print-head">
      <div className="print-head__logo">
        <img className="logo" src="/images/logo.svg" alt="MILMO net" />
      </div>
      <div className="print-head__date">印刷日：{today}</div>
      <div className="print-head__jigyoshomei">
        印刷者：{authentication.user.group.name}
      </div>
    </div>
  );
};

PrintHeader.propTypes = {
  authentication: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(PrintHeader);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}
