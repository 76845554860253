import React from "react";
import { Route, Switch } from "react-router";
import { withAuthentication, staticPage } from "./";
import Pathname from "../../app/naming/Pathname";
import LoginPage from "../login/LoginPage";
import BackOfficeRouter from "../../page/backoffice/BackOfficeRouter";
import WebFrontRouter from "../../page/webfront/WebFrontRouter";
import LogoutPage from "../login/LogoutPage";
import AgreementPage, { withAgreement } from "../login/AgreementPage";
import GuidePage from "../login/GuidePage";
import BackOfficeGuidePage from "../../page/backoffice/BackOfficeGuidePage";
import { MuiThemeProvider } from "@material-ui/core";
import theme from "../../page/theme";
import MaintenancePage from "../maintenance/MaintenancePage";
import ToolsRouter from "../../page/tools/ToolsRouter";

const HomeRouter = () => {
  return (
    <MuiThemeProvider theme={theme}>
      <Switch>
        <Route path={Pathname.login} component={staticPage(LoginPage)} />
        <Route path={Pathname.logout} component={staticPage(LogoutPage)} />
        <Route
          path={Pathname.agreement}
          component={staticPage(AgreementPage)}
        />
        <Route
          path={Pathname.maintenance}
          component={staticPage(MaintenancePage)}
        />
        <Route
          path={Pathname.tools}
          component={withAuthentication(ToolsRouter)}
        />
        <Route
          path={Pathname.backoffice}
          component={withAuthentication(withAgreement(BackOfficeRouter))}
        />
        <Route
          path={Pathname.guide}
          component={() => (
            <Switch>
              <Route
                path={Pathname.guide_backoffice}
                component={withAuthentication(
                  withAgreement(BackOfficeGuidePage)
                )}
              />
              <Route
                path={Pathname.guide}
                component={withAuthentication(withAgreement(GuidePage))}
              />
            </Switch>
          )}
        />
        <Route
          path={Pathname.__root}
          component={withAuthentication(withAgreement(WebFrontRouter))}
        />
      </Switch>
    </MuiThemeProvider>
  );
};

export default HomeRouter;
