import React from "react";
import PropTypes from "prop-types";
import StaticLabel from "./StaticLabel";
import ColorCatalog from "../color/ColorCatalog";

const UnitLayout = (props) => {
  return (
    <div style={styles.container}>
      <div style={styles.input}>{props.children}</div>
      <div style={styles.unit}>
        <StaticLabel withFloatingText={props.withFloatingText}>
          {props.unitText ? `${props.unitText}` : null}
        </StaticLabel>
      </div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
  },
  input: {
    boxSizing: "border-box",
    display: "inline-block",
    width: "calc(100% - 50px)",
  },
  unit: {
    boxSizing: "border-box",
    display: "inline-block",
    width: 50,
    paddingLeft: 5,
    color: ColorCatalog.gray,
  },
};

UnitLayout.propTypes = {
  children: PropTypes.node,
  unitText: PropTypes.string,
  withFloatingText: PropTypes.bool,
};

export default UnitLayout;
