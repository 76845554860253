import keycode from "keycode";

class Keycode {
  constructor(e) {
    this.keycode = keycode(e);
  }

  isEnter() {
    return this.keycode === "enter";
  }
}

export default Keycode;
