import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { renderNinzuShikaku, renderStaffJokinHijokin } from "./StaffTable";
import StaffShokushuTable from "./StaffShokushuTable";

const StaffShikakuKango = (props) => {
  const { homonkeiServiceStaff: staff } = props.contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-title">看護職員</div>
          <StaffShokushuTable shokushu={staff.kangoshokuin} />
        </div>

        <div className="p-office-body-sep__right">
          <div className="p-office-body-title">リハビリ職員</div>
          <StaffShokushuTable shokushu={staff.rehabilitationshokuin} />
        </div>
      </div>

      <div className="p-office-body-title">資格保有者数</div>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__center">
          <div className="p-office-body-table">
            <div className="p-office-body-table-row">
              <div className="thead">
                <span>看護師</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.kangoshi)}</span>
              </div>
              <div className="thead border-left">
                <span>理学療法士</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.rigakuryohoshi)}</span>
              </div>
            </div>
            <div className="p-office-body-table-row">
              <div className="thead">
                <span>准看護師</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.junkangoshi)}</span>
              </div>
              <div className="thead border-left">
                <span>作業療法士</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.sagyoryohoshi)}</span>
              </div>
            </div>
            <div className="p-office-body-table-row">
              <div className="thead">
                <span>専門・認定看護師</span>
              </div>
              <div className="tdata">
                <span>
                  {renderNinzuShikaku(
                    staff.semmonNinteikangoshi,
                    staff.semmonNinteikangoshiShikaku
                  )}
                </span>
              </div>
              <div className="thead border-left">
                <span>言語聴覚士</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.gengochokakushi)}</span>
              </div>
            </div>
            <div className="p-office-body-table-row">
              <div className="thead">
                <span>保健師</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.hokenshi)}</span>
              </div>
              <div className="thead border-left">
                <span>専門・認定療法士</span>
              </div>
              <div className="tdata">
                <span>
                  {renderNinzuShikaku(
                    staff.semmonNinteiryohoshi,
                    staff.semmonNinteiryohoshiShikaku
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

StaffShikakuKango.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default StaffShikakuKango;
