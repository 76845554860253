import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { changeForm, submit } from "../../state/tools/ndxr";
import { Fragment } from "react";
import InputTypeText from "../../app/ui/form/InputTypeText";

class Ndxr extends React.Component {
  handleNext = (range) => {
    const {
      ndxr: {
        form: { idRange },
      },
      onFormChange: change,
    } = this.props;
    const from = str2range(idRange).to;
    const to = from + range;
    change("idRange", `${from}-${to}`);
  };

  handleSubmit = () => {
    const {
      ndxr: { form },
      onFormSubmit,
    } = this.props;
    onFormSubmit(form);
  };

  formIsSubmitting() {
    const {
      ndxr: { isSubmitting },
    } = this.props;
    return isSubmitting;
  }

  bindingProps() {
    const {
      ndxr: { form },
      onFormChange: onChange,
    } = this.props;
    return { form, onChange };
  }

  render() {
    return (
      <Fragment>
        <button disabled={this.formIsSubmitting()} onClick={this.handleSubmit}>
          インデックスを更新する
        </button>
        <br />
        <br />
        <button
          disabled={this.formIsSubmitting()}
          onClick={() => this.handleNext(1000)}
        >
          次の1000
        </button>
        <div style={{ width: 200 }}>
          <InputTypeText
            name="idRange"
            helperText="事業所id"
            hintText="0-0"
            {...this.bindingProps()}
          />
        </div>
      </Fragment>
    );
  }
}

Ndxr.propTypes = {
  ndxr: PropTypes.object.isRequired,
  onFormChange: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Ndxr);

function mapStateToProps(state) {
  return {
    ndxr: state.toolsNdxr,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onFormChange: (name, value) => dispatch(changeForm(name, value)),
    onFormSubmit: (form) => {
      const { idRange } = form;
      const reservedRange = str2range(idRange);
      const interval = 100;
      function dispatchSubmit(form) {
        const { to } = str2range(form.idRange);
        const nextRange = {
          from: to + 1,
          to: to + interval,
        };
        if (nextRange.from > reservedRange.to) return;
        if (nextRange.to > reservedRange.to) nextRange.to = reservedRange.to;
        dispatch(submit({ idRange: range2str(nextRange) }, dispatchSubmit));
      }
      if (reservedRange.from > 0)
        return dispatchSubmit({
          idRange: range2str({ from: 0, to: reservedRange.from - 1 }),
        });
      return dispatchSubmit({ idRange: "0" });
    },
  };
}

function str2range(idRange) {
  const terms = (idRange || "").split("-");
  const x = parseInt(terms[0]) || 0;
  const y = parseInt(terms[1]) || 0;
  return {
    from: x < y ? x : y,
    to: x > y ? x : y,
  };
}

function range2str(range) {
  if (range.from === range.to) return `${range.from}`;
  return `${range.from}-${range.to}`;
}
