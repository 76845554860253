import React from "react";
import PropTypes from "prop-types";

const StaffSummaryRehabilitation = ({ contents }) => {
  const { homonkeiServiceStaff: staff } = contents.blocks;

  return (
    <div className="p-office-body-sep">
      <div className="p-office-body-sep__left">
        <div className="p-office-body-table2">
          <div className="p-office-body-table2__th">職員数</div>
          <div className="p-office-body-table2__td">
            <ul>
              <li>
                <div className="p-office-body-table2__td-name">
                  リハビリ職員
                </div>
                <div className="p-office-body-table2__td-val">
                  {staff.summary.rehabilitationshokuin}
                </div>
              </li>
              <li>
                <div className="p-office-body-table2__td-name">
                  経験年数5年以上の従業員
                </div>
                <div className="p-office-body-table2__td-val">
                  {staff.keiken5nenWariai}
                </div>
              </li>
            </ul>
          </div>
        </div>

        <div className="p-office-body-table">
          <table>
            <tbody>
              <tr>
                <th>管理者保有資格</th>
                <td>{staff.kanrishaHoyushikaku}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="p-office-body-sep__right">
        <div className="p-office-body-table2">
          <div className="p-office-body-table2__th">有資格者数</div>
          <div className="p-office-body-table2__td">
            <ul>
              <li>
                <div className="p-office-body-table2__td-name">理学療法士</div>
                <div className="p-office-body-table2__td-val">
                  {staff.summary.rigakuryohoshi}
                </div>
              </li>
              <li>
                <div className="p-office-body-table2__td-name">作業療法士</div>
                <div className="p-office-body-table2__td-val">
                  {staff.summary.sagyoryohoshi}
                </div>
              </li>
              <li>
                <div className="p-office-body-table2__td-name">言語聴覚士</div>
                <div className="p-office-body-table2__td-val">
                  {staff.summary.gengochokakushi}
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

StaffSummaryRehabilitation.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default StaffSummaryRehabilitation;
