import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
  serviceShubetsuFilter,
} from "../../../../../state/master-data";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import CategorizedOptions from "../../../../../app/util/CategorizedMasterData";
import For from "../../../../../app/ui/layout/For";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import Mount from "../../../../../app/ui/layout/Mount";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import OptionsSelectDialog from "../../../../../app/ui/form/OptionsSelectDialog";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import { OPTIONS_AVAILABILITY } from "../../../../../app/ui/form";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import Textarea from "../../../../../app/ui/form/Textarea";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import HorizontalSubHeadLabelLayout from "../../../../../app/ui/form/HorizontalSubHeadLabelLayout";

/**
 * 訪問系サービス料金加算フォーム.
 */
class HomonkeiServiceRyokinkasanForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleTsudoryokinAdded = this.handleTsudoryokinAdded.bind(this);
    this.handleTsudoryokinRemoved = this.handleTsudoryokinRemoved.bind(this);
    this.setYakantaiogataService();
    this.setIttaiRenkeiCode();
  }

  handleTsudoryokinAdded(option) {
    const duplicated = this.tsudoryokin().find((it) => it.code == option.code);
    const freeText = option.freeText;
    if (duplicated && !freeText) return;
    this.tsudoryokin(
      this.tsudoryokin().concat([
        {
          code: option.code,
          sonota: "",
          lowerLimit: 0,
          upperLimit: 0,
          biko: "",
        },
      ])
    );
  }

  handleTsudoryokinRemoved(index) {
    this.tsudoryokin(this.tsudoryokin().filter((_, i) => i != index));
  }

  tsudoryokin(tsudoryokin) {
    const { bindingProps } = this.props;
    if (!tsudoryokin) return bindingProps.form.getByName("tsudoryokin");
    bindingProps.onChange("tsudoryokin", tsudoryokin);
  }

  setYakantaiogataService() {
    const { bindingProps, serviceShubetsuCode } = this.props;
    if (
      ![ServiceShubetsuType.訪問介護]
        .map((it) => it.code)
        .includes(serviceShubetsuCode)
    ) {
      // 訪問介護以外の場合、保存時に基本情報への連携(登録更新)を行わない
      bindingProps.onChange("yakantaiogataService", null);
    }
  }

  setIttaiRenkeiCode() {
    const { bindingProps, masterData, serviceShubetsuCode } = this.props;
    if (
      ![ServiceShubetsuType.定期巡回_随時対応型訪問介護看護]
        .map((it) => it.code)
        .includes(serviceShubetsuCode)
    ) {
      return;
    }
    if (!bindingProps.form.getByName("ittaiRenkeiCode")) {
      const ittaiGata = masterData.homonkeiService_serviceRenkei[0];
      bindingProps.onChange("ittaiRenkeiCode", ittaiGata.code);
    }
  }

  render() {
    const { serviceShubetsuCode } = this.props;
    return (
      <Fragment>
        {this.renderKaigohoshukasan()}
        {this.renderComment()}
        <Mount
          if={[ServiceShubetsuType.訪問介護]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => this.render訪介()}
        />
        <Mount
          if={[ServiceShubetsuType.訪問入浴介護]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => this.render訪入()}
        />
        <Mount
          if={[ServiceShubetsuType.訪問看護]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => this.render訪看()}
        />
        <Mount
          if={[ServiceShubetsuType.訪問リハビリテーション]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => this.render訪リハ()}
        />
        <Mount
          if={[ServiceShubetsuType.定期巡回_随時対応型訪問介護看護]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => this.render定随()}
        />
        {this.renderTsudoryokin()}
        {this.renderSonota()}
      </Fragment>
    );
  }

  renderKaigohoshukasan() {
    const {
      bindingProps,
      masterData,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode,
    } = this.props;
    const kasanCategories = new CategorizedOptions(
      masterData.homonkeiService_kaigohoshukasan.filter(
        serviceShubetsuFilter(serviceShubetsuCategoryCode, serviceShubetsuCode)
      )
    ).getCategories();
    return (
      <FormGrouping title="介護報酬加算" recommended>
        <For
          in={kasanCategories}
          each={(category) => (
            <HorizontalLabelLayout
              key={`category:${category.code}`}
              labelText={category.label}
            >
              <OptionsCheckbox
                name="kaigohoshukasan"
                split={2}
                options={this.categoryOptions(category)}
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          )}
        />
      </FormGrouping>
    );
  }

  categoryOptions(category) {
    const { serviceShubetsuCode } = this.props;
    const kaigo = [ServiceShubetsuType.訪問介護]
      .map((it) => it.code)
      .includes(serviceShubetsuCode);
    const suffix = "（夜）";
    return category.options.map((option) => {
      if (!kaigo) return option;
      if (!option.yakantaiogata) return option;
      return { ...option, label: `${option.label}${suffix}` };
    });
  }

  ittaiRenkeiCode_ittaiGata() {
    const { bindingProps, masterData } = this.props;
    return [
      masterData.homonkeiService_serviceRenkei.find((it) => it.code == "001"),
    ]
      .map((it) => it.code)
      .includes(bindingProps.form.getByName("ittaiRenkeiCode"));
  }

  renderComment() {
    return (
      <HorizontalLabelLayout labelText="">
        <StaticLabel>
          ※ 自己負担額が1割の場合の目安料金をご記入ください
          <br />
          ※ 基本料金＋体制加算の料金をご記入ください
          <br />
        </StaticLabel>
      </HorizontalLabelLayout>
    );
  }

  renderRyokinYoshienYokaigo(items) {
    const { bindingProps } = this.props;
    return (
      <FormGrouping>
        <HorizontalLabelLayout>
          <GridRow>
            <GridCell>
              <StaticLabel style={{ fontWeight: "bold" }}>
                要支援１～２
              </StaticLabel>
            </GridCell>
            <GridCell>
              <StaticLabel style={{ fontWeight: "bold" }}>
                要介護１～５
              </StaticLabel>
            </GridCell>
            <GridCell grow={2}></GridCell>
          </GridRow>
        </HorizontalLabelLayout>

        <For
          in={items}
          each={(it) => (
            <HorizontalLabelLayout
              key={it.name1}
              labelText={it.label}
              required={!it.optional}
            >
              <GridRow>
                <GridCell>
                  <UnitLayout unitText="円">
                    <InputTypeNumber name={it.name1} {...bindingProps} />
                  </UnitLayout>
                </GridCell>
                <GridCell>
                  <UnitLayout unitText="円">
                    <InputTypeNumber name={it.name2} {...bindingProps} />
                  </UnitLayout>
                </GridCell>
                <GridCell grow={2}></GridCell>
              </GridRow>
            </HorizontalLabelLayout>
          )}
        />
      </FormGrouping>
    );
  }
  renderRyokin(items) {
    const { bindingProps } = this.props;
    return (
      <For
        in={items}
        each={(it) => (
          <HorizontalLabelLayout
            key={it.name}
            labelText={it.label}
            required={!it.optional}
          >
            <GridRow>
              <GridCell>
                <UnitLayout unitText="円">
                  <InputTypeNumber name={`${it.name}`} {...bindingProps} />
                </UnitLayout>
              </GridCell>
              <GridCell grow={3}></GridCell>
            </GridRow>
          </HorizontalLabelLayout>
        )}
      />
    );
  }

  renderRyokinRange(items) {
    const { bindingProps } = this.props;
    return (
      <For
        in={items}
        each={(it) => (
          <HorizontalLabelLayout
            key={it.namePrefix}
            labelText={it.label}
            required={!it.optional}
          >
            <GridRow>
              <GridCell>
                <UnitLayout unitText="円 〜">
                  <InputTypeNumber
                    name={`${it.namePrefix}LowerLimit`}
                    {...bindingProps}
                  />
                </UnitLayout>
              </GridCell>
              <GridCell>
                <UnitLayout unitText="円">
                  <InputTypeNumber
                    name={`${it.namePrefix}UpperLimit`}
                    {...bindingProps}
                  />
                </UnitLayout>
              </GridCell>
              <GridCell grow={2}></GridCell>
            </GridRow>
          </HorizontalLabelLayout>
        )}
      />
    );
  }

  renderYokaigodobetsu(items) {
    const { bindingProps } = this.props;
    return (
      <For
        in={items}
        each={(it) => (
          <HorizontalLabelLayout
            key={it.namePrefix}
            labelText={it.label}
            required={!it.optional}
          >
            <div>
              <For
                in={[1, 2, 3, 4, 5]}
                each={(level) => (
                  <RatioBlock
                    divide={5}
                    key={`${it.namePrefix}Yokaigo${level}`}
                  >
                    <SpaceOut block>
                      <UnitLayout unitText="円">
                        <InputTypeNumber
                          name={`${it.namePrefix}Yokaigo${level}`}
                          {...bindingProps}
                        />
                      </UnitLayout>
                    </SpaceOut>
                  </RatioBlock>
                )}
              />
            </div>
          </HorizontalLabelLayout>
        )}
      />
    );
  }

  // 訪問介護
  render訪介() {
    const { bindingProps } = this.props;
    const yakantaiogataService = bindingProps.form.getByName(
      "yakantaiogataService"
    );
    return (
      <Fragment>
        <FormGrouping title="料金目安">
          <HorizontalLabelLayout labelText="夜間対応型サービスの実施" required>
            <OptionsRadio
              name="yakantaiogataService"
              options={OPTIONS_AVAILABILITY}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout>
            <GridRow>
              <GridCell>
                <HorizontalSubHeadLabelLayout>
                  <StaticLabel style={{ fontWeight: "bold" }}>
                    要支援１～２
                  </StaticLabel>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell>
                <HorizontalSubHeadLabelLayout>
                  <StaticLabel style={{ fontWeight: "bold" }}>
                    要介護１～５
                  </StaticLabel>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
            </GridRow>
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="身体介護">
            <GridRow>
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      <br />
                      週１回程度
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shintaikaigoYoshienShu1kai"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      <br />
                      20分未満
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shintaikaigoYokaigoWithin20min"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
            </GridRow>

            <GridRow>
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      <br />
                      週２回程度
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shintaikaigoYoshienShu2kai"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      20分以上
                      <br />
                      30分未満
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shintaikaigoYokaigoWithin30min"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
            </GridRow>

            <GridRow>
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      週２回を
                      <br />
                      超える場合
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shintaikaigoYoshienMoreThanShu2kai"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      30分以上
                      <br />
                      1時間未満
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shintaikaigoYokaigoWithin60min"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
            </GridRow>
            <GridRow>
              <GridCell />
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      1時間30分
                      <br />
                      未満
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shintaikaigoYokaigoWithin90min"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
            </GridRow>
            <GridRow>
              <GridCell />
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      30分増す
                      <br />
                      ごとに
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shintaikaigoYokaigoBy30min"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
            </GridRow>
          </HorizontalLabelLayout>

          <HorizontalLabelLayout>
            <GridRow>
              <GridCell>
                <HorizontalSubHeadLabelLayout>
                  <StaticLabel style={{ fontWeight: "bold" }}>
                    要支援１～２
                  </StaticLabel>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell>
                <HorizontalSubHeadLabelLayout>
                  <StaticLabel style={{ fontWeight: "bold" }}>
                    要介護１～５
                  </StaticLabel>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
            </GridRow>
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="生活援助">
            <GridRow>
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      20分以上
                      <br />
                      45分未満
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="seikatsuenjoYoshienWithin45min"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      20分以上
                      <br />
                      45分未満
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="seikatsuenjoYokaigoWithin45min"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
            </GridRow>
            <GridRow>
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      <br />
                      45分以上
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="seikatsuenjoYoshienMoreThan45min"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell>
                <HorizontalSubHeadLabelLayout
                  labelText={
                    <span>
                      <br />
                      45分以上
                    </span>
                  }
                >
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="seikatsuenjoYokaigoMoreThan45min"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
            </GridRow>
          </HorizontalLabelLayout>

          <HorizontalLabelLayout>
            <GridRow>
              <GridCell>
                <HorizontalSubHeadLabelLayout>
                  <StaticLabel style={{ fontWeight: "bold" }}>
                    要介護１～５
                  </StaticLabel>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
            </GridRow>
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="通院等乗降介助">
            <GridRow>
              <GridCell>
                <HorizontalSubHeadLabelLayout>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="tsuinJokokaijoYokaigo"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
              <GridCell />
            </GridRow>
          </HorizontalLabelLayout>
        </FormGrouping>

        <Mount
          if={yakantaiogataService == "true" || yakantaiogataService == true}
        >
          <FormGrouping title="料金目安（夜間対応）">
            <HorizontalLabelLayout
              labelText={
                <span>
                  基本夜間対応型
                  <br />
                  訪問介護費
                </span>
              }
            >
              <GridRow>
                <RatioBlock divide={5}>
                  <UnitLayout unitText="円/月">
                    <InputTypeNumber
                      name="yakantaioKihonServiceYokaigo"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </RatioBlock>
              </GridRow>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="定期巡回サービス費">
              <GridRow>
                <RatioBlock divide={5}>
                  <UnitLayout unitText="円/回">
                    <InputTypeNumber
                      name="yakantaioTeikijunkaiYokaigo"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </RatioBlock>
              </GridRow>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="随時訪問サービス費（Ⅰ）">
              <GridRow>
                <RatioBlock divide={5}>
                  <UnitLayout unitText="円/回">
                    <InputTypeNumber
                      name="yakantaioZuijihomon1Yokaigo"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </RatioBlock>
              </GridRow>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="随時訪問サービス費（Ⅱ）">
              <GridRow>
                <RatioBlock divide={5}>
                  <UnitLayout unitText="円/回">
                    <InputTypeNumber
                      name="yakantaioZuijihomon2Yokaigo"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </RatioBlock>
              </GridRow>
            </HorizontalLabelLayout>
          </FormGrouping>
        </Mount>
      </Fragment>
    );
  }

  render訪入() {
    return (
      <Fragment>
        <FormGrouping title="料金目安">
          {this.renderRyokinYoshienYokaigo([
            {
              name1: "nyuyokuYoshien",
              name2: "nyuyokuYokaigo",
              label: "全身浴",
              optional: true,
            },
            {
              name1: "seishikiYoshien",
              name2: "seishikiYokaigo",
              label: "清拭・部分浴",
              optional: true,
            },
          ])}
        </FormGrouping>
      </Fragment>
    );
  }

  render訪看() {
    return (
      <Fragment>
        <FormGrouping title="料金目安">
          {this.renderRyokinYoshienYokaigo([
            {
              name1: "homonkangoI1Yoshien",
              name2: "homonkangoI1Yokaigo",
              label: "訪問看護I1",
              optional: true,
            },
            {
              name1: "homonkangoI2Yoshien",
              name2: "homonkangoI2Yokaigo",
              label: "訪問看護I2",
              optional: true,
            },
            {
              name1: "homonkangoI3Yoshien",
              name2: "homonkangoI3Yokaigo",
              label: "訪問看護I3",
              optional: true,
            },
            {
              name1: "homonkangoI4Yoshien",
              name2: "homonkangoI4Yokaigo",
              label: "訪問看護I4",
              optional: true,
            },
            {
              name1: "homonkangoI5Yoshien",
              name2: "homonkangoI5Yokaigo",
              label: "訪問看護I5",
              optional: true,
            },
            {
              name1: "homonkangoI52koeruYoshien",
              name2: "homonkangoI52koeruYokaigo",
              label: "訪問看護I5・2超",
              optional: true,
            },
          ])}
        </FormGrouping>
      </Fragment>
    );
  }

  render訪リハ() {
    return (
      <FormGrouping title="訪問リハビリテーション">
        {this.renderRyokin([
          { name: "homonRehabilitation", label: "1回（20分）", optional: true },
        ])}
      </FormGrouping>
    );
  }

  render定随() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="利用料金目安">
        <HorizontalLabelLayout labelText="一体・連携" required>
          <OptionsRadio
            name="ittaiRenkeiCode"
            options={masterData.homonkeiService_serviceRenkei}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="">
          <RatioBlock divide={5}>
            <StaticLabel>要介護１</StaticLabel>
          </RatioBlock>
          <RatioBlock divide={5}>
            <StaticLabel>要介護２</StaticLabel>
          </RatioBlock>
          <RatioBlock divide={5}>
            <StaticLabel>要介護３</StaticLabel>
          </RatioBlock>
          <RatioBlock divide={5}>
            <StaticLabel>要介護４</StaticLabel>
          </RatioBlock>
          <RatioBlock divide={5}>
            <StaticLabel>要介護５</StaticLabel>
          </RatioBlock>
        </HorizontalLabelLayout>
        {this.renderYokaigodobetsu([
          { namePrefix: "homonkaigohi", label: "訪問介護費", optional: true },
        ])}
        <Mount if={this.ittaiRenkeiCode_ittaiGata()}>
          {this.renderYokaigodobetsu([
            { namePrefix: "homonkangohi", label: "訪問看護費", optional: true },
          ])}
        </Mount>
      </FormGrouping>
    );
  }

  renderTsudoryokin() {
    const { bindingProps, masterData } = this.props;
    const TsudoryokinType = {
      キャンセル料: { code: "001" },
    };
    return (
      <FormGrouping title="都度発生する料金" recommended>
        <HorizontalLabelLayout labelText="">
          {this.tsudoryokin().length > 0 ? (
            <GridRow>
              <GridCell>
                <StaticLabel>項目</StaticLabel>
              </GridCell>
              <GridCell grow={2}>
                <StaticLabel>料金の範囲</StaticLabel>
              </GridCell>
              <GridCell>
                <StaticLabel>備考</StaticLabel>
              </GridCell>
            </GridRow>
          ) : null}
          {this.tsudoryokin().map((tsudoryokin, index) => {
            const name = `tsudoryokin.${index}`;
            const label = masterData.homonkeiService_tsudoryokin.find(
              (it) => it.code == tsudoryokin.code
            );
            return (
              <GridRow key={`${index}:${label.code}`}>
                <GridCell>
                  <SpaceOut block>
                    <Mount
                      if={label.freeText}
                      then={() => (
                        <InputTypeText
                          name={`${name}.sonota`}
                          hintText="自由入力"
                          {...bindingProps}
                        />
                      )}
                      else={() => <StaticLabel>{label.label}</StaticLabel>}
                    />
                  </SpaceOut>
                </GridCell>
                <GridCell>
                  <SpaceOut block>
                    <UnitLayout unitText="円 〜">
                      <InputTypeNumber
                        name={`${name}.lowerLimit`}
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </GridCell>
                <GridCell>
                  <SpaceOut block>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.upperLimit`}
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </GridCell>
                <GridCell>
                  <SpaceOut block>
                    <RatioBlock grow={3} divide={4}>
                      <InputTypeText
                        name={`${name}.biko`}
                        hintText="備考"
                        {...bindingProps}
                      />
                    </RatioBlock>
                    <RatioBlock grow={1} divide={4}>
                      <IconButton
                        onClick={() => this.handleTsudoryokinRemoved(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </RatioBlock>
                  </SpaceOut>
                </GridCell>
              </GridRow>
            );
          })}
          <OptionsSelectDialog
            title="都度発生する料金を追加する"
            onSelect={this.handleTsudoryokinAdded}
            options={masterData.homonkeiService_tsudoryokin.filter(
              (it) =>
                it.code != TsudoryokinType.キャンセル料.code ||
                this.tsudoryokin().filter(
                  (it) => it.code === TsudoryokinType.キャンセル料.code
                ).length === 0
            )}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderSonota() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="その他">
        <HorizontalLabelLayout labelText="利用者負担軽減制度" required>
          <OptionsRadio
            name="riyoshafutankeigenseido"
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="支払い方法">
          <OptionsCheckbox
            name="shiharai"
            options={masterData.homonkeiService_shiharai}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HomonkeiServiceRyokinkasanForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceRyokinkasanForm
);
