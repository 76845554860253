import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { backOfficeMenuTypeValues } from "../BackOfficeMenuType";

class BackOfficeDashboard extends React.Component {
  render() {
    return <Redirect to={this.defaultMenu().pathname} />;
  }

  defaultMenu() {
    const { user } = this.props.authentication;
    return backOfficeMenuTypeValues().filter((it) => it.available(user))[0];
  }
}

BackOfficeDashboard.propTypes = {
  authentication: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackOfficeDashboard);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps() {
  return {};
}
