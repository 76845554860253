import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import HorizontalSubHeadLabelLayout from "../../../../../app/ui/form/HorizontalSubHeadLabelLayout";
import {
  MASTER_DATA_BUNDLE_FOR_SHORTSTAY,
  withMasterData,
  serviceShubetsuFilter,
} from "../../../../../state/master-data";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";

class ShortstayKyoshitsuForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderKyoshitsu()}
        {this.renderKyoshitsuJokyo()}
        {this.renderShitsunaiSetsubi()}
        {this.renderSonota()}
      </Fragment>
    );
  }

  renderKyoshitsu() {
    const {
      bindingProps,
      masterData,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode,
    } = this.props;
    return (
      <FormGrouping title="居室">
        <HorizontalLabelLayout labelText="居室タイプ" required>
          <OptionsCheckbox
            name="kyoshitsuType"
            split={3}
            options={masterData.shortstay_KyoshitsuType.filter(
              serviceShubetsuFilter(
                serviceShubetsuCategoryCode,
                serviceShubetsuCode
              )
            )}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="居室様式" required>
          <OptionsCheckbox
            name="kyoshitsuYoshiki"
            split={3}
            options={masterData.shortstay_KyoshitsuYoshiki}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderKyoshitsuJokyo() {
    const {
      bindingProps,
      masterData,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode,
    } = this.props;
    const jikanKubunCategories = masterData.shortstay_KyoshitsyuJyokyoCategory.filter(
      serviceShubetsuFilter(serviceShubetsuCategoryCode, serviceShubetsuCode)
    );
    return (
      <FormGrouping title="居室の状況">
        {jikanKubunCategories.map((category) => (
          <HorizontalLabelLayout
            key={`category:${category.code}`}
            labelText={category.label}
          >
            <GridRow>
              <GridCell>
                <HorizontalSubHeadLabelLayout labelText="居室数" required>
                  <UnitLayout unitText="部屋">
                    <InputTypeNumber
                      name={`kyoshitsuJokyos.${category.code}.kazu`}
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell>
                <HorizontalSubHeadLabelLayout labelText="床面積">
                  <UnitLayout unitText="畳">
                    <InputTypeNumber
                      name={`kyoshitsuJokyos.${category.code}.menseki`}
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell />
            </GridRow>
          </HorizontalLabelLayout>
        ))}
      </FormGrouping>
    );
  }

  renderShitsunaiSetsubi() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="室内設備">
        <HorizontalLabelLayout labelText="室内設備" recommended>
          <OptionsCheckbox
            name="shitsunaiSetsubi"
            split={3}
            options={masterData.shortstay_ShitsunaiSetsubi}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="その他設備等">
          <Textarea name="shitsunaisetsubiSonota" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderSonota() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="その他">
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

ShortstayKyoshitsuForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SHORTSTAY)(
  ShortstayKyoshitsuForm
);
