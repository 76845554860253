import React from "react";
import PrintTableHead from "../../../../app/ui/print/PrintTableHead";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const HaishokuServiceTableHead = () => {
  return (
    <PrintTableHead height="80px">
      <PrintTableNode width="105px">
        店舗・団体名
        <div className="c-table-frame__updated-at">(最終更新日)</div>
      </PrintTableNode>
      <PrintTableNode width="150px">住所</PrintTableNode>
      <PrintTableNode width="112px">TEL</PrintTableNode>
      <PrintTableNode width="75px">定休日</PrintTableNode>
      <PrintTableNode width="120px">営業・開催時間</PrintTableNode>
      <PrintTableNode width="140px">提供・対象地域</PrintTableNode>
      <PrintTableNode width="141px" flexible>
        <div className="row" style={{ height: "50%" }}>
          <div className="cell">配食が出来る食事</div>
        </div>
        <div className="row" style={{ height: "50%" }}>
          <div className="cell" style={{ width: "30px" }}>
            朝食
          </div>
          <div className="cell" style={{ width: "30px" }}>
            昼食
          </div>
          <div className="cell" style={{ width: "30px" }}>
            夕食
          </div>
        </div>
      </PrintTableNode>
      <PrintTableNode width="220px">対応サービス</PrintTableNode>
    </PrintTableHead>
  );
};

export default HaishokuServiceTableHead;
