import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DanjohiBarChart from "../../../../../app/ui/chart/DanjohiBarChart";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ValueWithHeadcountUnit from "../../../../../app/ui/unit/ValueWithHeadcountUnit";
import ValueWithPercentageUnit from "../../../../../app/ui/unit/ValueWithPercentageUnit";

const StaffBlock = ({ shokuintaisei }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="職員体制" />
      <DanjohiBarChart danjohi={shokuintaisei} />

      <div className="print-body-text_area">
        <div style={{ fontWeight: "bold" }}>
          介護支援専門員数：
          <ValueWithHeadcountUnit
            value={shokuintaisei.kaigoshiensenmoninninzu}
          />
        </div>
        <div style={{ fontWeight: "bold" }}>
          経験年数5年以上の従業員の割合：
          <ValueWithPercentageUnit
            value={shokuintaisei.keikennensu5Nenijyowariai}
          />
        </div>
      </div>
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_padding6-7 tbody-th_vertical-align-middle tbody-th_width80 tbody-th_height20">
              <th>管理者保有資格</th>
              <td style={{ textAlign: "left" }}>
                {shokuintaisei.kanrishahoyushikaku}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

StaffBlock.propTypes = {
  shokuintaisei: PropTypes.object.isRequired,
};

export default StaffBlock;
