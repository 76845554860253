import React from "react";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { HashLink } from "react-router-hash-link"; // スムーススクロール
import smoothscroll from "smoothscroll-polyfill"; // スムーススクロールpolyfill
import BackOfficeLayout from "./BackOfficeLayout";
import Boundary from "../../app/ui/layout/Boundary";
import SideMargin from "../../app/ui/layout/SideMargin";
import Sticky from "react-sticky-el";
import Parametername from "../../app/naming/Parametername";
import Pathname from "../../app/naming/Pathname";
import Mount from "../../app/ui/layout/Mount";

class BackOfficeGuidePage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackLinkClick = this.handleBackLinkClick.bind(this);
  }

  componentWillMount() {
    smoothscroll.polyfill();
    this.handleJigyoshoLinkClick = this.handleJigyoshoLinkClick.bind(this);
    this.handleAgentCollectionLinkClick = this.handleAgentCollectionLinkClick.bind(
      this
    );
  }

  scrollWithOffset = (el) => {
    const elementPosition = el.offsetTop + 80;
    window.scroll({
      top: elementPosition,
      left: 0,
      behavior: "smooth",
    });
  };

  initializeFields(props) {
    const { search } = props.location;
    const searchParams = new URLSearchParams(search);
    this.pathname =
      searchParams.get(Parametername.pathname) || Pathname.webfront;
  }

  handleJigyoshoLinkClick() {
    this.props.onJigyoshoLinkClick();
  }

  handleAgentCollectionLinkClick() {
    this.props.onAgentCollectionLinkClick();
  }

  userHasBackOfficeJigyoshoMenu() {
    return this.props.authentication.user.hasBackOfficeJigyoshoMenu();
  }

  userHasBackOfficeAgentCollectionMenu() {
    return this.props.authentication.user.hasBackOfficeAgentCollectionMenu();
  }

  // 前のページに戻る
  handleBackLinkClick() {
    const { actions } = this.props;
    actions.gotoback(this.pathname);
  }

  render() {
    return (
      <BackOfficeLayout>
        <Boundary>
          <SideMargin>
            <div className="o-guide-flame">
              {this.renderMenu()}
              {this.renderContents()}
            </div>
          </SideMargin>
        </Boundary>
      </BackOfficeLayout>
    );
  }

  renderMenu() {
    return (
      <div className="o-guide-side">
        <Sticky>
          <ul>
            {this.renderNavigation()}
            {this.renderMenuItem({ hash: "#login", label: "ログインする" })}
            {this.userHasBackOfficeJigyoshoMenu()
              ? this.renderMenuItem({
                  hash: "#update",
                  label: "事業所情報を入力する",
                })
              : null}
            {this.userHasBackOfficeJigyoshoMenu()
              ? this.renderMenuItem({
                  hash: "#preview",
                  label: "事業所情報の表示を確認する",
                })
              : null}
            {this.userHasBackOfficeJigyoshoMenu()
              ? this.renderMenuItem({
                  hash: "#add-user",
                  label: "一般ユーザーを追加する",
                })
              : null}
            {this.userHasBackOfficeAgentCollectionMenu()
              ? this.renderMenuItem({
                  hash: "#agent-collection",
                  label: "代行入力する",
                })
              : null}
            {this.renderMenuItem({ hash: "#logout", label: "ログアウトする" })}
          </ul>
        </Sticky>
      </div>
    );
  }

  renderNavigation() {
    return (
      <li>
        <a onClick={this.handleBackLinkClick}>前のページに戻る</a>
      </li>
    );
  }

  renderMenuItem({ hash, label }) {
    return (
      <li>
        <HashLink to={hash} scroll={(el) => this.scrollWithOffset(el)}>
          {label}
        </HashLink>
      </li>
    );
  }

  renderContents() {
    return (
      <div className="o-guide-main">
        <div className="o-guide-title">ミルモネット使い方ガイド</div>
        {this.renderContentsLogin()}
        {this.userHasBackOfficeJigyoshoMenu()
          ? this.renderContentsUpdate()
          : null}
        {this.userHasBackOfficeJigyoshoMenu()
          ? this.renderContentsPreview()
          : null}
        {this.userHasBackOfficeJigyoshoMenu()
          ? this.renderContentsAddUser()
          : null}
        {this.userHasBackOfficeAgentCollectionMenu()
          ? this.renderContentsAgentCollection()
          : null}
        {this.renderContentsLogout()}
      </div>
    );
  }

  renderContentsLogin() {
    return (
      <div className="o-guide-box" id="login">
        <div className="o-guide-box__title">ログインする</div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/login.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                ユーザー名とパスワードを入力し、「ログイン」ボタンをクリックしてログインします。
              </li>
            </ol>
          </div>
        </div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/kiyaku.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                初めてログインした時のみ、利用規約同意確認ページが表示されます。
              </li>
              <li>
                利用規約をお読みになった上で、ページ下部にある「同意する」ボタンをクリックして次へ進みます。
              </li>
            </ol>
          </div>
        </div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/backoffice-menu.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                ページ上部のメニューから「管理ページ」をクリックして次へ進みます。
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }

  renderContentsUpdate() {
    return (
      <div className="o-guide-box" id="update">
        <div className="o-guide-box__title">事業所情報を入力する</div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/update-1.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                <a onClick={this.handleJigyoshoLinkClick}>事業所一覧ページ</a>
                を開きます。
              </li>
              <li>事業所名が表示されている行をクリックします。</li>
            </ol>
          </div>
        </div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/update-2.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                画面下部に操作を選択するためのダイアログが表示されたら、「入力状況を表示する」ボタンをクリックします。
              </li>
            </ol>
          </div>
        </div>
        <div className="o-guide-section">
          <div className="o-guide-box__text">
            <b>ショートカット</b>
            <div className="o-guide-box__img">
              <img src="/images/guide/backoffice/update-3.png" />
            </div>
            <p>
              事業所名が表示されている行の右端にあるペン印のアイコンをクリックしても、入力状況を表示することができます。
            </p>
          </div>
        </div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/update-4.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                入力したい情報の項目名をクリックして、入力フォームに進みます。
              </li>
              <li>
                情報を更新した項目は赤の
                <span style={{ color: "lightcoral" }}>●</span>印が緑の
                <span style={{ color: "lightgreen" }}>●</span>印に変わります。
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }

  renderContentsPreview() {
    return (
      <div className="o-guide-box" id="preview">
        <div className="o-guide-box__title">事業所情報の表示を確認する</div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/preview-1.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                <a onClick={this.handleJigyoshoLinkClick}>事業所一覧ページ</a>
                を開きます。
              </li>
              <li>
                上記『事業所情報を入力する』の操作と同様に、入力状況を表示します。
              </li>
              <li>
                入力状況を表示するページにて、右上の「表示を確認する」ボタンをクリックします。
              </li>
            </ol>
          </div>
        </div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/preview-2.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                事業所の個別ページで表示される形式にて事業所情報を確認することができます。
              </li>
              <li>
                各情報の項目にある「編集する」ボタンをクリックすると、その項目の入力フォームが表示されますので、そのまま入力を続けることができます。
              </li>
              <li>
                内容に問題がなければ入力は完了です。元のページに戻るにはページ上部の「情報入力ページに戻る」ボタンをクリックします。
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }

  renderContentsAddUser() {
    return (
      <div className="o-guide-box" id="add-user">
        <div className="o-guide-box__title">一般ユーザーを追加する</div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/add-user-1.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                <a onClick={this.handleJigyoshoLinkClick}>事業所一覧ページ</a>
                を開きます。
              </li>
              <li>
                事業所を選択して画面下部に操作を選択するためのダイアログが表示されたら、「一般ユーザーを管理する」ボタンをクリックします。
              </li>
            </ol>
          </div>
        </div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/add-user-2.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                「一般ユーザーを追加する」ボタンをクリックすると、アカウント作成ページに進みます。
              </li>
            </ol>
          </div>
        </div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/add-user-3.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                新しく登録したいユーザーのログインIDとパスワードをログイン情報に入力します。
              </li>
              <li>
                新しく登録したいユーザーの苗字・名前とメールアドレスをアカウント情報に入力します。
              </li>
              <li>
                「追加する」ボタンをクリックして、アカウントを作成します。
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }

  renderContentsAgentCollection() {
    return (
      <div className="o-guide-box" id="agent-collection">
        <div className="o-guide-box__title">代行入力する</div>
        <Mount if={this.userHasBackOfficeJigyoshoMenu()}>
          <div className="o-guide-section">
            <div className="o-guide-box__img">
              <img src="/images/guide/backoffice/agent-collection-1.png" />
            </div>
            <div className="o-guide-box__text">
              <ol>
                <li>
                  <a onClick={this.handleAgentCollectionLinkClick}>
                    事業所一覧ページ（代行入力）
                  </a>
                  を開きます。
                </li>
              </ol>
            </div>
          </div>
        </Mount>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/agent-collection-2.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                「事業所を追加する」ボタンをクリックすると、事業所追加ページに進みます。
              </li>
            </ol>
          </div>
        </div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/agent-collection-3.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>登録したい事業所・団体の必要な情報を入力します。</li>
              <li>
                「保存する」ボタンをクリックすると、事業所の追加が完了します。
              </li>
            </ol>
          </div>
        </div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/agent-collection-4.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                入力したい情報の項目名をクリックして、入力フォームに進みます。
              </li>
              <li>
                情報を更新した項目は赤の
                <span style={{ color: "lightcoral" }}>●</span>印が緑の
                <span style={{ color: "lightgreen" }}>●</span>印に変わります。
              </li>
            </ol>
          </div>
        </div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/agent-collection-5.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>
                事業所一覧ページ（代行入力）にて、事業所名が表示されている行の右端にあるペン印のアイコンをクリックすると、上記の情報入力ページに進むことができます。
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }

  renderContentsLogout() {
    return (
      <div className="o-guide-box" id="logout">
        <div className="o-guide-box__title">ログアウトする</div>
        <div className="o-guide-section">
          <div className="o-guide-box__img">
            <img src="/images/guide/backoffice/logout.png" />
          </div>
          <div className="o-guide-box__text">
            <ol>
              <li>「ログアウト」をクリックして、ログアウトします。</li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

BackOfficeGuidePage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func),
  location: PropTypes.object,
  authentication: PropTypes.object.isRequired,
  // on-event handlers
  onJigyoshoLinkClick: PropTypes.func.isRequired,
  onAgentCollectionLinkClick: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BackOfficeGuidePage);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onJigyoshoLinkClick: () => dispatch(push(Pathname.backoffice_jigyosho)),
    onAgentCollectionLinkClick: () =>
      dispatch(push(Pathname.backoffice_agent_collection)),
    actions: {
      gotoback: (pathname) => dispatch(push(pathname)),
    },
  };
}
