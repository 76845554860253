import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../state/account-form/actions";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import { withInputFeedback } from "../../../../app/ui/form";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import { Card } from "@material-ui/core";
import Boundary from "../../../../app/ui/layout/Boundary";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import InputTypePassword from "../../../../app/ui/form/InputTypePassword";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import ShortInputWidth from "../../../../app/ui/form/ShortInputWidth";
import RoleType, {
  roleTypeValueOf,
} from "../../../../app/domain/account/RoleType";
import InputTypeText from "../../../../app/ui/form/InputTypeText";
import DummyError from "../../../../app/ui/form/DummyError";
import GridRow from "../../../../app/ui/grid/GridRow";
import GridCell from "../../../../app/ui/grid/GridCell";
import StaticLabel from "../../../../app/ui/form/StaticLabel";
import RefreshIcon from "@material-ui/icons/Refresh";
import RaisedButton from "../../../../app/ui/button/RaisedButton";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import LockIcon from "@material-ui/icons/Lock";
import IconLeadingLayout from "../../../../app/ui/form/IconLeadingLayout";
import IconButtonTrailingLayout from "../../../../app/ui/form/IconButtonTrailingLayout";
import Center from "../../../../app/ui/layout/Center";
import Margin from "../../../../app/ui/layout/Margin";

class AccountAddComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
    this.handleCopyRandomPwButtonClick = this.handleCopyRandomPwButtonClick.bind(
      this
    );
    this.handleRefreshRandomPwButtonClick = this.handleRefreshRandomPwButtonClick.bind(
      this
    );
  }

  componentDidMount() {
    const { actions, groupId, role } = this.props;
    actions.requestBlankForm(groupId, role, this.getRandomPassword());
  }

  handleBackButtonClick() {
    const { onGoBack } = this.props;
    onGoBack();
  }

  handleSaveButtonClick() {
    const { actions, accountForm, role, onGoAhead } = this.props;

    if (RoleType.ROLE_SYS_OPERATOR.name === role) {
      actions.submitToAddBySysop(
        accountForm.form,
        onGoAhead ? () => onGoAhead() : this.handleBackButtonClick
      );
    } else {
      actions.submitToAdd(
        accountForm.form,
        onGoAhead ? () => onGoAhead() : this.handleBackButtonClick
      );
    }
  }

  createBindingProps() {
    const { actions, accountForm } = this.props;
    const { form } = accountForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  }

  handleCopyRandomPwButtonClick() {
    const { actions, accountForm } = this.props;
    const { form } = accountForm;
    const randomPw = form.getByName("randomPassword");
    actions.changeForm("password", randomPw);
    actions.changeForm("passwordConfirm", randomPw);
  }

  handleRefreshRandomPwButtonClick() {
    const { actions } = this.props;
    actions.changeForm("randomPassword", this.getRandomPassword());
  }

  getRandomPassword() {
    const reqularExpression = /^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,}$/i;
    while (true) {
      const randomPw = this.createRandomPassword();
      if (randomPw.match(reqularExpression) != null) return randomPw;
    }
  }

  createRandomPassword() {
    const word = "abcdefghijklmnopqrstuvwxyz0123456789";
    const lenPw = 8;
    var randomPw = "";
    for (var i = 0; i < lenPw; i++) {
      randomPw += word[Math.floor(Math.random() * word.length)];
    }
    return randomPw;
  }

  isAppliedRandomPassword() {
    const { accountForm } = this.props;
    const { form } = accountForm;
    return form.getByName("password") === form.getByName("randomPassword");
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          {this.renderHead()}
          {this.renderNavigation()}
          {this.renderForm()}
        </SideMargin>
      </Boundary>
    );
  }

  renderHead() {
    const accountLabel = roleTypeValueOf(this.props.role).label;
    return <BackOfficeHead>{accountLabel}追加</BackOfficeHead>;
  }

  renderNavigation() {
    return (
      <BackOfficeNavigation>
        <FlatPrimaryButton onClick={this.handleBackButtonClick}>
          <BackIcon />
          戻る
        </FlatPrimaryButton>
      </BackOfficeNavigation>
    );
  }

  renderForm() {
    const { form, formIsLoading, formIsSubmitting } = this.props.accountForm;
    if (formIsLoading) return <CenteredCircularProgress />;
    if (!form.typeIs("blank")) return null;
    const bindingProps = this.createBindingProps();
    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title="ログイン情報">
            <HorizontalLabelLayout labelText="ログインID" required>
              <ShortInputWidth>
                <InputTypeText name="username" {...bindingProps} />
              </ShortInputWidth>
              <StaticLabel>
                ※ ログインIDは半角英数字で入力してください
                <br />
              </StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="パスワード" required>
              <GridRow>
                <GridCell>
                  <InputTypePassword name="password" {...bindingProps} />
                </GridCell>
                <GridCell grow={2}>
                  <GridRow>
                    <GridCell>
                      <Center>
                        <Margin top>
                          <RaisedButton
                            size="small"
                            color="primary"
                            onClick={this.handleCopyRandomPwButtonClick}
                          >
                            <KeyboardBackspaceIcon
                              style={{ width: "20px", height: "20px" }}
                            />
                          </RaisedButton>
                        </Margin>
                      </Center>
                    </GridCell>
                    <GridCell grow={5}>
                      <IconLeadingLayout icon={<LockIcon />} color="disabled">
                        <IconButtonTrailingLayout
                          icon={<RefreshIcon />}
                          onClick={this.handleRefreshRandomPwButtonClick}
                        >
                          <InputTypeText
                            name="randomPassword"
                            disabled
                            {...bindingProps}
                            helperText={
                              this.isAppliedRandomPassword()
                                ? "適用されています。"
                                : null
                            }
                          />
                        </IconButtonTrailingLayout>
                      </IconLeadingLayout>
                    </GridCell>
                  </GridRow>
                </GridCell>
              </GridRow>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="パスワード(確認)" required>
              <ShortInputWidth>
                <InputTypePassword name="passwordConfirm" {...bindingProps} />
              </ShortInputWidth>
              <StaticLabel>
                ※
                パスワードは英字と数字をそれぞれ含め、半角英数字記号8文字以上で入力してください
                <br />
              </StaticLabel>
            </HorizontalLabelLayout>
          </FormGrouping>
          <FormGrouping title="アカウント情報">
            <HorizontalLabelLayout labelText="苗字・名前" required>
              <GridRow>
                <GridCell>
                  <SpaceOut block>
                    <InputTypeText
                      name="familyName"
                      hintText="山田"
                      {...bindingProps}
                    />
                  </SpaceOut>
                </GridCell>
                <GridCell>
                  <SpaceOut block>
                    <InputTypeText
                      name="givenName"
                      hintText="太郎"
                      {...bindingProps}
                    />
                  </SpaceOut>
                </GridCell>
                <GridCell></GridCell>
              </GridRow>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="✉ メールアドレス">
              <InputTypeText
                name="mailAddress"
                hintText="xxxx@xxxx.xxxx"
                {...bindingProps}
              />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout>
              <DummyError name="groupId" />
            </HorizontalLabelLayout>
          </FormGrouping>
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedPrimaryButton
                disabled={formIsSubmitting}
                onClick={this.handleSaveButtonClick}
              >
                追加する
              </RaisedPrimaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }
}

AccountAddComponent.propTypes = {
  accountForm: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  groupId: PropTypes.number.isRequired,
  onGoBack: PropTypes.func.isRequired,
  onGoAhead: PropTypes.func,
  role: PropTypes.string.isRequired,
};

export default withInputFeedback(
  connect(mapStateToProps, mapDispatchToProps)(AccountAddComponent)
);

function mapStateToProps(state) {
  return {
    accountForm: state.accountForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
