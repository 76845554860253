import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import {
  MASTER_DATA_BUNDLE_FOR_HOKENGAI,
  withMasterData,
} from "../../../../../state/master-data";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import StandardSelect from "../../../../../app/ui/form/StandardSelect";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import ServiceKaishi from "./ServiceKaishi";
import ServiceYoyaku from "./ServiceYoyaku";

class TaxiForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderService()}
        {this.renderServiceKaishi()}
        {this.renderYoyaku()}
        {this.renderGyoseiJoseiHojoTaxi()}
        {this.renderRyokin()}
      </Fragment>
    );
  }

  renderService() {
    const { bindingProps, masterData } = this.props;
    const services = [
      { code: "selectFlg", label: "車種の選択", orderNo: 1 },
      { code: "wheelchairFlg", label: "車椅子利用", orderNo: 2 },
      { code: "recliningFlg", label: "リクライニング車椅子利用", orderNo: 3 },
      { code: "stretcherFlg", label: "ストレッチャー利用", orderNo: 4 },
      { code: "helperFlg", label: "看護師の同乗", orderNo: 5 },
      { code: "oxygenFlg", label: "酸素吸入器", orderNo: 6 },
      { code: "maskFlg", label: "酸素マスク", orderNo: 7 },
      { code: "cannulaFlg", label: "カニューラ", orderNo: 8 },
      { code: "aspirationFlg", label: "たん吸引器", orderNo: 9 },
    ];
    return (
      <FormGrouping title="サービスについて">
        <HorizontalLabelLayout labelText="サービスの種類" required>
          <ShortInputWidth>
            <StandardSelect
              name="kindCode"
              blank
              options={masterData.taxiKind}
              {...bindingProps}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="提供サービス" recommended>
          <OptionsCheckbox
            name="teikyoService"
            options={services}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="保有台数" recommended>
          <ShortInputWidth>
            <UnitLayout unitText="台">
              <InputTypeNumber name="cars" {...bindingProps} />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="同乗者定員（上限）" recommended>
          <ShortInputWidth>
            <UnitLayout unitText="人">
              <InputTypeNumber name="ridingCapacity" {...bindingProps} />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="serviceBiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderServiceKaishi() {
    const { bindingProps } = this.props;
    return <ServiceKaishi bindingProps={bindingProps} />;
  }

  renderYoyaku() {
    const { bindingProps } = this.props;
    return <ServiceYoyaku bindingProps={bindingProps} />;
  }

  renderGyoseiJoseiHojoTaxi() {
    const { bindingProps } = this.props;
    const joseiseidos = [
      { code: "useInsuranceFlg", label: "介護保険適用", orderNo: 1 },
      { code: "ticketTaxiFlg", label: "福祉タクシー券", orderNo: 2 },
      { code: "ticketReservationFlg", label: "予約料", orderNo: 3 },
      {
        code: "ticketStretcherFlg",
        label: "ストレッチャー料免除券",
        orderNo: 4,
      },
    ];
    return (
      <FormGrouping title="行政の助成制度や補助について">
        <HorizontalLabelLayout labelText="使用できる助成制度" recommended>
          <OptionsCheckbox
            name="joseiseido"
            options={joseiseidos}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <InputTypeText name="ticketComment" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderRyokin() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="料金について">
        <HorizontalLabelLayout
          labelText="キャンセル時の扱いについて"
          recommended
        >
          <InputTypeText name="costCancel" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="入会費・登録費（税込み）" recommended>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name="costInitialFee"
                hintText="0"
                {...bindingProps}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="年会費（税込み）" recommended>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name="costAnnualFee"
                hintText="0"
                {...bindingProps}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="月会費（税込み）" recommended>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name="costMonthlyFee"
                hintText="0"
                {...bindingProps}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="予約手数料（税込み）" recommended>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name="reservationFee"
                hintText="0"
                {...bindingProps}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="迎車料（税込み）" recommended>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name="comingFee"
                hintText="0"
                {...bindingProps}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="costComment" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

TaxiForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOKENGAI)(TaxiForm);
