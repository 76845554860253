import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import { errorsDataOf } from "../../../app/fundamental/data/ErrorsData";

const initialState = {
  errors: errorsDataOf({}),
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.RESET]: function () {
    return initialState;
  },
  [ActionType.RAISE_ERRORS]: function (state, action) {
    return {
      ...state,
      errors: errorsDataOf(action.payload.errors),
    };
  },
});

export default reducers.createReducer(initialState);
