export default class CategorizedOptions {
  constructor(options) {
    const categories = options
      .map((option) => option.category)
      .reduce((categories, category) => {
        if (!categories.find((c) => c.code === category.code))
          categories.push(category);
        return categories;
      }, [])
      .sort((a, b) => a.orderNo - b.orderNo);
    this.categories = categories
      .map((category) => ({
        ...category,
        options: options
          .filter((option) => option.category.code === category.code)
          .sort((a, b) => a.orderNo - b.orderNo),
      }))
      .sort((a, b) => a.orderNo - b.orderNo);
  }

  getCategories() {
    return this.categories;
  }
}
