import React from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";

class SonotaChiikishigenDetail extends React.Component {
  render() {
    const { sonotaChiikishigenMenu } = this.props.contents.blocks;
    return (
      <div className="p-office-body">
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">活動内容</div>
        </div>

        <div className="p-office-body-table u-border-bottom-b u-padding-b32 u-margin-b32">
          <table>
            <tbody>
              <tr>
                <th>メニュー・活動名</th>
                <td>{sonotaChiikishigenMenu.serviceMei}</td>
              </tr>
              <tr>
                <th>メニュー・活動内容詳細</th>
                <td>{sonotaChiikishigenMenu.menuService}</td>
              </tr>
            </tbody>
          </table>

          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.homeProvideOtherMenu
          )}
        </div>
      </div>
    );
  }
}

SonotaChiikishigenDetail.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default SonotaChiikishigenDetail;
