import { isFunction, isArray } from "../data";

const ResponseHandlersType = {
  function: {
    match: isFunction,
    normalize: (handler) =>
      fill({
        onSuccess: handler,
        onFailure: () => {},
        onError: () => {},
        onMaintenance: () => {},
      }),
  },
  array: {
    match: isArray,
    normalize: (handler) =>
      fill({
        onSuccess: handler[0],
        onFailure: handler[1],
        onError: handler[2],
        onMaintenance: handler[3],
      }),
  },
  object: {
    match: (handler) => !isFunction(handler) && !isArray(handler),
    normalize: (handler) =>
      fill({
        onSuccess: handler.onSuccess,
        onFailure: handler.onFailure,
        onError: handler.onError,
        onMaintenance: handler.onMaintenance,
      }),
  },
};

function fill(responseHandler) {
  let { onSuccess, onFailure, onError, onMaintenance } = responseHandler;
  if (!onSuccess) onSuccess = (json) => console.log(json);
  if (!onFailure) onFailure = (json) => console.warn(json);
  if (!onError) onError = (json) => console.error(json);
  if (!onMaintenance) onMaintenance = (json) => console.warn(json);
  return { onSuccess, onFailure, onError, onMaintenance };
}

export function responseHandlersTypeOf(responseHandler) {
  return Object.keys(ResponseHandlersType)
    .map((key) => ResponseHandlersType[key])
    .find((type) => type.match(responseHandler));
}

export default ResponseHandlersType;
