import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import TimePeriod from "../../../../../app/ui/form/TimePeriod";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Textarea from "../../../../../app/ui/form/Textarea";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";

class EigyoJikanForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        {this.renderEigyoJikan()}
        {this.renderTeikyubi()}
      </Fragment>
    );
  }

  renderEigyoJikan() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="営業時間">
        <HorizontalLabelLayout labelText="平日">
          <ShortInputWidth>
            <TimePeriod name="heijitsu" {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="土曜">
          <ShortInputWidth>
            <TimePeriod name="doyo" {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="日曜">
          <ShortInputWidth>
            <TimePeriod name="nichiyo" {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="祝日">
          <ShortInputWidth>
            <TimePeriod name="shukujitsu" {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderTeikyubi() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="定休日等">
        <HorizontalLabelLayout labelText="365日対応" required>
          <InputTypeCheckbox
            name="allyearUkeire"
            labelText=""
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="定休日">
          <InputTypeText name="teikyubi" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="留意事項">
          <Textarea name="ryuijiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

EigyoJikanForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default EigyoJikanForm;
