import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  MASTER_DATA_BUNDLE_FOR_SHORTSTAY,
  withMasterData,
} from "../../../../../state/master-data";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import { OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE } from "../../../../../app/ui/form";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

class ShortstayStaffForm extends React.Component {
  serviseShibetuType_seikatukaigo(serviceShubetsuCode) {
    return [ServiceShubetsuType.短期入所生活介護]
      .map((it) => it.code)
      .includes(serviceShubetsuCode);
  }

  serviseShibetuType_seiketukaigo_Iryou(serviceShubetsuCode) {
    return [
      ServiceShubetsuType.短期入所生活介護,
      ServiceShubetsuType.短期入所療養介護医療,
    ]
      .map((it) => it.code)
      .includes(serviceShubetsuCode);
  }

  serviseShibetuType_Iryo_Roken(serviceShubetsuCode) {
    return [
      ServiceShubetsuType.短期入所療養介護医療,
      ServiceShubetsuType.短期入所療養介護老健,
    ]
      .map((it) => it.code)
      .includes(serviceShubetsuCode);
  }

  render() {
    return (
      <Fragment>
        {this.renderStaff()}
        {this.renderShokuinbetsu()}
        {this.renderShikakuhoyusya()}
        {this.renderSonota()}
      </Fragment>
    );
  }

  renderStaff() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="スタッフ">
        <HorizontalLabelLayout labelText="特徴">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="1:1個別対応" required>
          <OptionsRadio
            name="kobetsutaioType"
            split={6}
            options={OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="性別従業員数" required>
          <div>
            <RatioBlock divide={5}>
              <SpaceOut block>
                <StaticLabel>男性従業員数</StaticLabel>
                <UnitLayout unitText="人">
                  <InputTypeNumber
                    name="seibetsuDansei"
                    hintText="0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={5}>
              <SpaceOut block>
                <StaticLabel>女性従業員数</StaticLabel>
                <UnitLayout unitText="人">
                  <InputTypeNumber
                    name="seibetsuJosei"
                    hintText="0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
          </div>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="年齢別従業員数">
          <div>
            <RatioBlock divide={5}>
              <SpaceOut block>
                <StaticLabel>20代従業員</StaticLabel>
                <UnitLayout unitText="人">
                  <InputTypeNumber
                    name="nenreibetsu20dai"
                    hintText="0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={5}>
              <SpaceOut block>
                <StaticLabel>30代従業員</StaticLabel>
                <UnitLayout unitText="人">
                  <InputTypeNumber
                    name="nenreibetsu30dai"
                    hintText="0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={5}>
              <SpaceOut block>
                <StaticLabel>40代従業員</StaticLabel>
                <UnitLayout unitText="人">
                  <InputTypeNumber
                    name="nenreibetsu40dai"
                    hintText="0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={5}>
              <SpaceOut block>
                <StaticLabel>50代従業員</StaticLabel>
                <UnitLayout unitText="人">
                  <InputTypeNumber
                    name="nenreibetsu50dai"
                    hintText="0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={5}>
              <SpaceOut block>
                <StaticLabel>60代以上従業員</StaticLabel>
                <UnitLayout unitText="人">
                  <InputTypeNumber
                    name="nenreibetsu60dai"
                    hintText="0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
          </div>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="管理者保有資格">
          <SpaceOut block>
            <InputTypeText
              name="kanrishahoyushikaku"
              hintText="例）看護師（排泄・褥瘡ケア専門看護師）"
              {...bindingProps}
            />
          </SpaceOut>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderShokuinbetsu() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="職員別人数">
        {this.renderKangoshokuin()}
        {this.renderKaigoshokuin()}
        {this.renderKinokunrenshidoin()}
        {this.renderSeikatsusodanin()}
        {this.renderIshi()}
        {this.renderYakuzaishi()}
        {this.renderRehabilitation()}
        {this.renderShikaeiseishi()}
        {this.renderKanrieiyoshi()}
        {this.renderEiyoshi()}
        <HorizontalLabelLayout
          labelText="看護・介護職員1人当たりのご利用者数"
          recommended
        >
          <div>
            <RatioBlock divide={5}>
              <SpaceOut block>
                <StaticLabel></StaticLabel>
                <UnitLayout unitText="人">
                  <InputTypeText
                    name="shokuinatarinoriyosha"
                    hintText="0.0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={0}>
              <SpaceOut block>
                <StaticLabel>※定員÷看護・介護職員配置人数</StaticLabel>
              </SpaceOut>
            </RatioBlock>
          </div>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderShikakuhoyusya() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="資格保有者数">
        {this.renderKaigofukushishi()}
        {this.renderShakaifukushishi()}
        {this.renderKaigoshokuinjitsumushakenshu()}
        {this.renderKaigoshokuinshoninshakenshu()}
        {this.renderKangoshiOyobiJunkangoshi()}
        <HorizontalLabelLayout>
          <StaticLabel>資格種類</StaticLabel>
          <InputTypeText
            name="kangoshiOyobiJunkangoshiShikakushurui"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        {this.renderRigakuryohoshi()}
        {this.renderSagyoryohoshi()}
        {this.renderGengochokakushi()}
        {this.renderJudoseifukushi()}
        {this.renderAmmaMassageShiatsushi()}
        {this.renderMassage()}
        {this.renderNinteiryohoshi()}
        <HorizontalLabelLayout>
          <StaticLabel>資格種類</StaticLabel>
          <InputTypeText name="ninteiryohoshiShikakushurui" {...bindingProps} />
        </HorizontalLabelLayout>
        {this.renderKaigoProfessionalCarrierDani()}
        <HorizontalLabelLayout labelText="認知症介護">
          {this.renderNinchishokaigoKisokenshu()}
          {this.renderNinchishokaigoJissenshakenshu()}
          {this.renderNinchishokaigoJissenLeaderKenshu()}
          {this.renderNinchishokaigoShidoshayoseikenshu()}
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderSonota() {
    const { bindingProps, masterData } = this.props;
    const staffSonotaOptions = masterData.shortstay_StaffSonota.filter(
      (it) => it.code != "999"
    );
    return (
      <FormGrouping title="その他">
        <HorizontalLabelLayout labelText="その他">
          <OptionsCheckbox
            name="staffSonota"
            options={staffSonotaOptions}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderKangoshokuin() {
    const array = [
      "看護職員",
      "kangoshokuinJokin",
      "kangoshokuinHijokin",
      "kangoshokuinJokinkansan",
      "kangoshokuinKeiken1miman",
      "kangoshokuinKeiken1ijo3miman",
      "kangoshokuinKeiken3ijo5miman",
      "kangoshokuinKeiken5ijo10miman",
      "kangoshokuinKeiken10ijo",
      true,
      false,
    ];
    return this.renderShokuin(...array);
  }

  renderKaigoshokuin() {
    const array = [
      "介護職員",
      "kaigoshokuinJokin",
      "kaigoshokuinHijokin",
      "kaigoshokuinJokinkansan",
      "kaigoshokuinKeiken1miman",
      "kaigoshokuinKeiken1ijo3miman",
      "kaigoshokuinKeiken3ijo5miman",
      "kaigoshokuinKeiken5ijo10miman",
      "kaigoshokuinKeiken10ijo",
      true,
      false,
    ];
    return this.renderShokuin(...array);
  }

  renderKinokunrenshidoin() {
    const { serviceShubetsuCode } = this.props;
    const array = [
      "機能訓練指導員",
      "kinokunrenshidoinJokin",
      "kinokunrenshidoinHijokin",
      "kinokunrenshidoinJokinkansan",
      "kinokunrenshidoinKeiken1miman",
      "kinokunrenshidoinKeiken1ijo3miman",
      "kinokunrenshidoinKeiken3ijo5miman",
      "kinokunrenshidoinKeiken5ijo10miman",
      "kinokunrenshidoinKeiken10ijo",
      true,
      false,
    ];
    if (this.serviseShibetuType_seikatukaigo(serviceShubetsuCode))
      return this.renderShokuin(...array);
    return null;
  }

  renderSeikatsusodanin() {
    const { serviceShubetsuCode } = this.props;
    const array = [
      "生活相談員",
      "seikatsusodaninJokin",
      "seikatsusodaninHijokin",
      "seikatsusodaninJokinkansan",
      "seikatsusodaninKeiken1miman",
      "seikatsusodaninKeiken1ijo3miman",
      "seikatsusodaninKeiken3ijo5miman",
      "seikatsusodaninKeiken5ijo10miman",
      "seikatsusodaninKeiken10ijo",
      false,
      true,
    ];
    if (this.serviseShibetuType_seikatukaigo(serviceShubetsuCode))
      return this.renderShokuin(...array);
    return null;
  }

  renderIshi() {
    const array = [
      "医師",
      "ishiJokin",
      "ishiHijokin",
      "ishiJokinkansan",
      "ishiKeiken1miman",
      "ishiKeiken1ijo3miman",
      "ishiKeiken3ijo5miman",
      "ishiKeiken5ijo10miman",
      "ishiKeiken10ijo",
      false,
      false,
    ];
    return this.renderShokuin(...array);
  }

  renderYakuzaishi() {
    const { serviceShubetsuCode } = this.props;
    const array = [
      "薬剤師",
      "yakuzaishiJokin",
      "yakuzaishiHijokin",
      "yakuzaishiJokinkansan",
      "yakuzaishiKeiken1miman",
      "yakuzaishiKeiken1ijo3miman",
      "yakuzaishiKeiken3ijo5miman",
      "yakuzaishiKeiken5ijo10miman",
      "yakuzaishiKeiken10ijo",
      false,
      false,
    ];
    if (this.serviseShibetuType_Iryo_Roken(serviceShubetsuCode))
      return this.renderShokuin(...array);
    return null;
  }

  renderRehabilitation() {
    const { serviceShubetsuCode } = this.props;
    const array = [
      "リハビリ職員",
      "rehabilitationJokin",
      "rehabilitationHijokin",
      "rehabilitationJokinkansan",
      "rehabilitationKeiken1miman",
      "rehabilitationKeiken1ijo3miman",
      "rehabilitationKeiken3ijo5miman",
      "rehabilitationKeiken5ijo10miman",
      "rehabilitationKeiken10ijo",
      false,
      false,
    ];
    if (this.serviseShibetuType_Iryo_Roken(serviceShubetsuCode))
      return this.renderShokuin(...array);
    return null;
  }

  // 常勤・非常勤別、経験年数別用
  renderShokuin(
    labelText,
    jokin,
    hijokin,
    jokinkansan,
    keiken1miman,
    keiken1ijo3miman,
    keiken3ijo5miman,
    keiken5ijo10miman,
    keiken10ijo,
    required,
    recommended
  ) {
    const { bindingProps } = this.props;
    return (
      <div>
        <HorizontalLabelLayout labelText={labelText}></HorizontalLabelLayout>
        <HorizontalLabelLayout required={required} recommended={recommended}>
          <div>
            <RatioBlock divide={5}>
              <SpaceOut block>
                <StaticLabel>常勤・非常勤別</StaticLabel>
                <StaticLabel>常勤</StaticLabel>
                <UnitLayout unitText="人">
                  <InputTypeNumber
                    name={jokin}
                    hintText="0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={5}>
              <SpaceOut block>
                <StaticLabel>非常勤</StaticLabel>
                <UnitLayout unitText="人">
                  <InputTypeNumber
                    name={hijokin}
                    hintText="0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={5}>
              <SpaceOut block>
                <StaticLabel>常勤換算</StaticLabel>
                <UnitLayout unitText="人">
                  <InputTypeText
                    name={jokinkansan}
                    hintText="0.0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
          </div>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="">
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>経験年数別</StaticLabel>
              <StaticLabel>1年未満</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name={keiken1miman}
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>〜3年未満</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name={keiken1ijo3miman}
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>〜5年未満</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name={keiken3ijo5miman}
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>〜10年未満</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name={keiken5ijo10miman}
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>10年以上</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name={keiken10ijo}
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
        </HorizontalLabelLayout>
      </div>
    );
  }

  renderShikaeiseishi() {
    const array = [
      "歯科衛生士",
      "shikaeiseishiJokin",
      "shikaeiseishiHijokin",
      "shikaeiseishiJokinkansan",
    ];
    return this.renderStaffJokinHijokinJokinkansan(...array);
  }

  renderKanrieiyoshi() {
    const array = [
      "管理栄養士",
      "kanrieiyoshiJokin",
      "kanrieiyoshiHijokin",
      "kanrieiyoshiJokinkansan",
    ];
    return this.renderStaffJokinHijokinJokinkansan(...array);
  }

  renderEiyoshi() {
    const array = [
      "栄養士",
      "eiyoshiJokin",
      "eiyoshiHijokin",
      "eiyoshiJokinkansan",
    ];
    return this.renderStaffJokinHijokinJokinkansan(...array);
  }

  // 常勤・非常勤・常勤換算用
  renderStaffJokinHijokinJokinkansan(labelText, jokin, hijokin, jokinkansan) {
    const { bindingProps } = this.props;
    return (
      <HorizontalLabelLayout labelText={labelText}>
        <div>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>常勤</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber name={jokin} hintText="0" {...bindingProps} />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>非常勤</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name={hijokin}
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>常勤換算</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeText
                  name={jokinkansan}
                  hintText="0.0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
        </div>
      </HorizontalLabelLayout>
    );
  }

  renderKaigofukushishi() {
    const array = [
      "介護福祉士",
      "kaigofukushishiJokin",
      "kaigofukushishiHijokin",
    ];
    return this.renderStaffJokinHijokin(...array);
  }

  renderShakaifukushishi() {
    const array = [
      "社会福祉士",
      "shakaifukushishiJokin",
      "shakaifukushishiHijokin",
    ];
    return this.renderStaffJokinHijokin(...array);
  }

  renderKaigoshokuinjitsumushakenshu() {
    const array = [
      "介護職員実務者研修",
      "kaigoshokuinjitsumushakenshuJokin",
      "kaigoshokuinjitsumushakenshuHijokin",
    ];
    return this.renderStaffJokinHijokin(...array);
  }

  renderKaigoshokuinshoninshakenshu() {
    const array = [
      "介護職員初任者研修",
      "kaigoshokuinshoninshakenshuJokin",
      "kaigoshokuinshoninshakenshuHijokin",
    ];
    return this.renderStaffJokinHijokin(...array);
  }

  renderKangoshiOyobiJunkangoshi() {
    const array = [
      "看護師及び准看護師",
      "kangoshiOyobiJunkangoshiJokin",
      "kangoshiOyobiJunkangoshiHijokin",
    ];
    return this.renderStaffJokinHijokin(...array);
  }

  renderRigakuryohoshi() {
    const array = [
      "理学療法士",
      "rigakuryohoshiJokin",
      "rigakuryohoshiHijokin",
    ];
    return this.renderStaffJokinHijokin(...array);
  }

  renderSagyoryohoshi() {
    const array = ["作業療法士", "sagyoryohoshiJokin", "sagyoryohoshiHijokin"];
    return this.renderStaffJokinHijokin(...array);
  }

  renderGengochokakushi() {
    const array = [
      "言語聴覚士",
      "gengochokakushiJokin",
      "gengochokakushiHijokin",
    ];
    return this.renderStaffJokinHijokin(...array);
  }

  renderJudoseifukushi() {
    const { serviceShubetsuCode } = this.props;
    const array = [
      "柔道整復師",
      "judoseifukushiJokin",
      "judoseifukushiHijokin",
    ];
    if (this.serviseShibetuType_seikatukaigo(serviceShubetsuCode))
      return this.renderStaffJokinHijokin(...array);
    return null;
  }

  renderAmmaMassageShiatsushi() {
    const { serviceShubetsuCode } = this.props;
    const array = [
      "あん摩マッサージ指圧師",
      "ammaMassageShiatsushiJokin",
      "ammaMassageShiatsushiHijokin",
    ];
    if (this.serviseShibetuType_seikatukaigo(serviceShubetsuCode))
      return this.renderStaffJokinHijokin(...array);
    return null;
  }

  // 常勤・非常勤用
  renderStaffJokinHijokin(labelText, jokin, hijokin) {
    const { bindingProps } = this.props;
    return (
      <HorizontalLabelLayout labelText={labelText} recommended>
        <div>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>常勤</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber name={jokin} hintText="0" {...bindingProps} />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>非常勤</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name={hijokin}
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
        </div>
      </HorizontalLabelLayout>
    );
  }

  renderKaigoProfessionalCarrierDani() {
    const array = [
      "介護プロフェッショナルキャリア段位",
      "kaigoProfessionalCarrierDani",
    ];
    return this.renderStaffNinzu(...array);
  }

  renderNinteiryohoshi() {
    const array = ["認定療法士", "ninteiryohoshi"];
    return this.renderStaffNinzu(...array);
  }

  renderMassage() {
    const { serviceShubetsuCode } = this.props;
    const array = ["マッサージ資格保有者", "massage"];
    if (this.serviseShibetuType_seikatukaigo(serviceShubetsuCode))
      return this.renderStaffNinzu(...array);
    return null;
  }

  // 単一人数用
  renderStaffNinzu(labelText, ninzu) {
    const { bindingProps } = this.props;
    return (
      <HorizontalLabelLayout labelText={labelText}>
        <RatioBlock divide={5}>
          <SpaceOut block>
            <StaticLabel />
            <UnitLayout unitText="人">
              <InputTypeNumber name={ninzu} hintText="0" {...bindingProps} />
            </UnitLayout>
          </SpaceOut>
        </RatioBlock>
      </HorizontalLabelLayout>
    );
  }

  renderNinchishokaigoKisokenshu() {
    const array = ["基礎研修", "ninchishokaigoKisokenshu"];
    return this.renderStaffSubNinzu(...array);
  }

  renderNinchishokaigoJissenshakenshu() {
    const array = ["実践者研修", "ninchishokaigoJissenshakenshu"];
    return this.renderStaffSubNinzu(...array);
  }

  renderNinchishokaigoJissenLeaderKenshu() {
    const array = ["実践リーダー研修", "ninchishokaigoJissenLeaderKenshu"];
    return this.renderStaffSubNinzu(...array);
  }

  renderNinchishokaigoShidoshayoseikenshu() {
    const array = ["指導者養成研修", "ninchishokaigoShidoshayoseikenshu"];
    return this.renderStaffSubNinzu(...array);
  }

  // ラベル付き複数人数用
  renderStaffSubNinzu(labelText, ninzu) {
    const { bindingProps } = this.props;
    return (
      <RatioBlock divide={5}>
        <SpaceOut block>
          <StaticLabel>{labelText}</StaticLabel>
          <UnitLayout unitText="人">
            <InputTypeNumber name={ninzu} hintText="0" {...bindingProps} />
          </UnitLayout>
        </SpaceOut>
      </RatioBlock>
    );
  }
}

ShortstayStaffForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SHORTSTAY)(
  ShortstayStaffForm
);
