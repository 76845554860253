import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Parametername from "../../../app/naming/Parametername";
import Right from "../../../app/ui/layout/Right";
import Mount from "../../../app/ui/layout/Mount";
import FlatPrimaryButton from "../../../app/ui/button/FlatPrimaryButton";

class GoToEditFormButtonRaw extends React.Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    const { actions, contents, formType, jigyoshoAttributeType } = this.props;
    switch (formType) {
      case "photo":
        actions.gotoPhotoForm(
          contents.servicePageType.photoEditFormPathname,
          contents.id,
          contents.serviceShubetsuCode,
          contents.serviceShubetsuCategoryCode
        );
        break;
      case "flier":
        actions.gotoFlierForm(
          contents.servicePageType.flierEditFormPathname,
          contents.id,
          contents.serviceShubetsuCode,
          contents.serviceShubetsuCategoryCode
        );
        break;
      case "attribute":
        actions.gotoJigyoshoAttributeForm(
          contents.servicePageType.attributeEditFormPathname,
          contents.id,
          jigyoshoAttributeType,
          contents.serviceShubetsuCode,
          contents.serviceShubetsuCategoryCode
        );
        break;
    }
  }

  render() {
    return (
      <Mount if={this.props.contents.preview}>
        <Right>
          <FlatPrimaryButton onClick={this.handleClick}>
            編集する
          </FlatPrimaryButton>
        </Right>
      </Mount>
    );
  }
}

GoToEditFormButtonRaw.propTypes = {
  actions: PropTypes.object.isRequired,
  contents: PropTypes.object.isRequired,
  formType: PropTypes.oneOf(["photo", "flier", "attribute"]).isRequired,
  jigyoshoAttributeType: PropTypes.object,
};

const GoToEditFormButton = connect(
  null,
  mapDispatchToProps
)(GoToEditFormButtonRaw);
export default GoToEditFormButton;

function mapDispatchToProps(dispatch) {
  const gotoForm = (
    pathname,
    serviceShubetsuCode,
    serviceShubetsuCategoryCode
  ) => {
    const searchParams = new URLSearchParams();
    searchParams.set(Parametername.serviceShubetsuCode, serviceShubetsuCode);
    searchParams.set(
      Parametername.serviceShubetsuCategoryCode,
      serviceShubetsuCategoryCode
    );
    searchParams.set(Parametername.pathname, window.location.pathname);
    dispatch(
      push({
        pathname: pathname,
        search: searchParams.toString(),
      })
    );
  };

  return {
    actions: {
      gotoPhotoForm: (
        formPathname,
        id,
        serviceShubetsuCode,
        serviceShubetsuCategoryCode
      ) => {
        gotoForm(
          formPathname.replace(":id", id),
          serviceShubetsuCode,
          serviceShubetsuCategoryCode
        );
      },
      gotoFlierForm: (
        formPathname,
        id,
        serviceShubetsuCode,
        serviceShubetsuCategoryCode
      ) => {
        gotoForm(
          formPathname.replace(":id", id),
          serviceShubetsuCode,
          serviceShubetsuCategoryCode
        );
      },
      gotoJigyoshoAttributeForm: (
        formPathname,
        id,
        jigyoshoAttributeType,
        serviceShubetsuCode,
        serviceShubetsuCategoryCode
      ) => {
        gotoForm(
          formPathname
            .replace(":id", id)
            .replace(":type", jigyoshoAttributeType.name),
          serviceShubetsuCode,
          serviceShubetsuCategoryCode
        );
      },
    },
  };
}

export const gotoPhotoFormButton = (contents) => {
  return <GoToEditFormButton contents={contents} formType="photo" />;
};

export const gotoFlierFormButton = (contents) => {
  return <GoToEditFormButton contents={contents} formType="flier" />;
};

export const goToJigyoshoAttributeFormButton = (
  contents,
  jigyoshoAttributeType
) => {
  return (
    <GoToEditFormButton
      contents={contents}
      formType="attribute"
      jigyoshoAttributeType={jigyoshoAttributeType}
    />
  );
};
