const ActionType = {
  REQUEST: "JIGYOSHO_PHOTO_REQUEST",
  REQUEST_FINISHED: "JIGYOSHO_PHOTO_REQUEST_FINISHED",
  CHANGE_SORT: "JIGYOSHO_PHOTO_CHANGE_SORT",
  CHANGE_FORM: "JIGYOSHO_PHOTO_CHANGE_FORM",
  REMOVE: "JIGYOSHO_PHOTO_REMOVE",
  SUBMIT: "JIGYOSHO_PHOTO_SBMIT",
  SUBMIT_FINISHED: "JIGYOSHO_PHOTO_SBMIT_FINISHED",
  SUBMIT_FAILED: "JIGYOSHO_PHOTO_SBMIT_FAILED",
};

export default ActionType;
