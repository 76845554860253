import { stringIsNullOrEmpty } from "../../app/fundamental/data";

export function searchConverter(target = "") {
  return {
    toCondition: () => {
      const searchParams = new URLSearchParams(target);
      return {
        groupId: !stringIsNullOrEmpty(searchParams.get("groupId"))
          ? searchParams.get("groupId")
          : "",
        keyword: !stringIsNullOrEmpty(searchParams.get("keyword"))
          ? searchParams.get("keyword")
          : "",
        serviceShubetsuCode: !stringIsNullOrEmpty(
          searchParams.get("serviceShubetsuCode")
        )
          ? searchParams.get("serviceShubetsuCode")
          : "",
        shozaichi: !stringIsNullOrEmpty(searchParams.get("shozaichi"))
          ? searchParams.get("shozaichi")
          : "",
        statusCode: !stringIsNullOrEmpty(searchParams.get("statusCode"))
          ? searchParams.get("statusCode")
          : "",
      };
    },
  };
}

export function conditionConverter(condition) {
  return {
    toSearch: () => {
      const searchParams = new URLSearchParams();
      if (!stringIsNullOrEmpty(condition.groupId))
        searchParams.set("groupId", condition.groupId);
      if (!stringIsNullOrEmpty(condition.keyword))
        searchParams.set("keyword", condition.keyword);
      if (!stringIsNullOrEmpty(condition.serviceShubetsuCode))
        searchParams.set("serviceShubetsuCode", condition.serviceShubetsuCode);
      if (!stringIsNullOrEmpty(condition.shozaichi))
        searchParams.set("shozaichi", condition.shozaichi);
      if (!stringIsNullOrEmpty(condition.statusCode))
        searchParams.set("statusCode", condition.statusCode);
      return searchParams.toString();
    },
  };
}
