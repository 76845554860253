import React from "react";
import PropTypes from "prop-types";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import { connect } from "react-redux";
import {
  changeForm,
  requestBlankForm,
  submitToAdd,
} from "../../../../state/job-offer-form/actions";
import {
  MASTER_DATA_BUNDLE_FOR_JOB_OFFER,
  withMasterData,
} from "../../../../state/master-data";
import JobOfferForm from "../form/JobOfferForm";

class JobOfferAddPage extends React.Component {
  constructor(props) {
    super(props);

    this.jigyoshoId = this.props.match.params.jigyoshoId;

    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  componentDidMount() {
    this.props.loadForm(this.jigyoshoId);
  }

  handleBackButtonClick() {
    this.props.gotoJobOfferListPage();
  }

  render() {
    const { jobOfferForm } = this.props;

    const formProps = {
      type: "blank",
      masterData: this.props.masterData,
      jobOfferForm,
      onChange: this.props.changeForm,
      onCancel: () => this.props.gotoJobOfferListPage(),
      onSubmit: () => {
        const { submitForm, jobOfferForm, gotoJobOfferDetailPage } = this.props;
        const callback = (json) => gotoJobOfferDetailPage(json.id);
        submitForm(this.jigyoshoId, jobOfferForm.form, callback);
      },
    };

    return (
      <Boundary>
        <SideMargin>
          <BackOfficeHead>求人情報の新規登録</BackOfficeHead>

          <BackOfficeNavigation>
            <FlatPrimaryButton onClick={this.handleBackButtonClick}>
              <BackIcon /> 戻る
            </FlatPrimaryButton>
          </BackOfficeNavigation>

          <JobOfferForm {...formProps} />
        </SideMargin>
      </Boundary>
    );
  }
}

JobOfferAddPage.propTypes = {
  match: PropTypes.object.isRequired,
  jobOfferForm: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  loadForm: PropTypes.func.isRequired,
  gotoJobOfferListPage: PropTypes.func.isRequired,
  gotoJobOfferDetailPage: PropTypes.func.isRequired,
  changeForm: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    jobOfferForm: state.jobOfferForm,
    masterData: state.masterData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadForm: (jigyoshoId) => {
      dispatch(requestBlankForm(jigyoshoId));
    },
    gotoJobOfferListPage: () => {
      dispatch(push(Pathname.backoffice_job_offer));
    },
    gotoJobOfferDetailPage: (id) => {
      dispatch(push(Pathname.backoffice_job_offer_id.replace(/:id/, id)));
    },
    changeForm: (name, value) => {
      dispatch(changeForm(name, value));
    },
    submitForm: (jigyoshoId, form, callback) => {
      dispatch(submitToAdd(jigyoshoId, form, callback));
    },
  };
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_JOB_OFFER)(
  connect(mapStateToProps, mapDispatchToProps)(JobOfferAddPage)
);
