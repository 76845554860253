import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import { OPTIONS_AVAILABILITY } from "../../../../../app/ui/form";
import { zipcodeToAddress } from "../../../../../state/zipcode-search/actions";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
  withMasterData,
} from "../../../../../state/master-data";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import HorizontalSubHeadLabelLayout from "../../../../../app/ui/form/HorizontalSubHeadLabelLayout";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";

class SogeiForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleEnterKeyDownOnYubimbango = this.handleEnterKeyDownOnYubimbango.bind(
      this
    );
  }

  handleEnterKeyDownOnYubimbango() {
    const { actions, bindingProps } = this.props;
    const { form, onChange: changeForm } = bindingProps;
    const zipcode = form.getByName("yubimbango");
    actions.zipcodeToAddress(zipcode, (address) => {
      changeForm("todofuken", address.pref);
      changeForm("shikuchoson", address.address);
    });
  }

  render() {
    return (
      <Fragment>
        {this.renderServiceTeikyoChiiki()}
        {this.renderSogei()}
      </Fragment>
    );
  }

  renderServiceTeikyoChiiki() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="サービス提供地域">
        <HorizontalLabelLayout labelText="サービス提供地域" required>
          <Textarea name="serviceTeikyochiiki" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="serviceTeikyoChiikiBiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderSogei() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="送迎">
        <HorizontalLabelLayout labelText="車両台数">
          <ShortInputWidth>
            <HorizontalSubHeadLabelLayout labelText="一般車両">
              <UnitLayout unitText="台">
                <InputTypeNumber name="ippansharyo" {...bindingProps} />
              </UnitLayout>
            </HorizontalSubHeadLabelLayout>
          </ShortInputWidth>
          <ShortInputWidth>
            <HorizontalSubHeadLabelLayout labelText="リフト車両">
              <UnitLayout unitText="台">
                <InputTypeNumber name="liftSharyo" {...bindingProps} />
              </UnitLayout>
            </HorizontalSubHeadLabelLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="送迎時居宅内介護" recommended>
          <OptionsRadio
            name="sogeijikyotakunaikaigo"
            split={6}
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="送迎車ロゴ" recommended>
          <OptionsRadio
            name="sogeishaLogoCode"
            split={6}
            options={masterData.sogeishaLogo}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="送迎時間個別対応" recommended>
          <OptionsRadio
            name="sogeijikankobetsutaio"
            split={6}
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

SogeiForm.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(connect(mapStateToProps, mapDispatchToProps)(SogeiForm));

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      zipcodeToAddress: (zipcode, callback) =>
        dispatch(zipcodeToAddress(zipcode, callback)),
    },
  };
}
