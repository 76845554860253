import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import WebFrontDialog from "../../../../app/ui/dialog/WebFrontDialog";
import Parametername from "../../../../app/naming/Parametername";
import ScrollableAreaOnModalDialog from "./ScrollableAreaOnModalDialog";

class SearchConditionPageCity extends React.Component {
  constructor(props) {
    super(props);
    this.handleBackgroundClick = this.handleBackgroundClick.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
    this.handleSubOkButtonClick = this.handleSubOkButtonClick.bind(this);
    this.handleOkButtonClick = this.handleOkButtonClick.bind(this);
  }

  isLoading() {
    return this.props.serviceSearch.isBrowseAreaLoading;
  }

  // バックグラウンドクリック
  handleBackgroundClick() {
    const { onChange } = this.props;
    const { conditionBuffer } = this.props.serviceSearch;
    const { handleClose = () => {} } = this.props;
    // 保存状態を再設定
    onChange(Parametername.cityArea, conditionBuffer[Parametername.cityArea]);
    handleClose();
  }

  // キャンセルボタン
  handleCancelButtonClick() {
    const { onChange } = this.props;
    const { conditionBuffer } = this.props.serviceSearch;
    const { handleCancel = () => {} } = this.props;

    // 保存状態を再設定
    onChange(Parametername.cityArea, conditionBuffer[Parametername.cityArea]);
    handleCancel();
  }

  // 条件を追加する
  // 変更を反映して画面を閉じる
  handleSubOkButtonClick() {
    const { onSave } = this.props;
    const { condition } = this.props.serviceSearch;
    const { handlSubOk = () => {} } = this.props;

    // 変更内容の保存
    var cityAreas = {};
    Object.keys(condition[Parametername.cityArea])
      .filter((key) => condition[Parametername.cityArea][key])
      .forEach((key) => (cityAreas[key] = true));

    onSave(Parametername.cityArea, cityAreas, handlSubOk);
  }

  // 変更して検索ボタン
  handleOkButtonClick() {
    const { onSave } = this.props;
    const { condition } = this.props.serviceSearch;
    const { handleOk = () => {} } = this.props;

    // 変更内容の保存
    var cityAreas = {};
    Object.keys(condition[Parametername.cityArea])
      .filter((key) => condition[Parametername.cityArea][key])
      .forEach((key) => (cityAreas[key] = true));

    onSave(Parametername.cityArea, cityAreas, handleOk);
  }

  // まとまりチェックボックス
  handleBundleCheckboxClick(event, cityItemList) {
    const { onChange } = this.props;
    // まとまり配下の市区町村分ループしてチェック状態を設定
    cityItemList.forEach((cityItem) =>
      onChange(
        `${Parametername.cityArea}.${cityItem.cityCode}`,
        event.target.checked
      )
    );
  }

  // まとまりチェックボックスのチェック状態
  checkedBundleCheckbox(cityItemList) {
    const { condition } = this.props.serviceSearch;
    // チェックボックス選択リスト
    var checkedList = {};
    cityItemList.forEach(
      (cityItem, index) =>
        (checkedList[index] = condition.getByName(
          `${Parametername.cityArea}.${cityItem.cityCode}`
        )
          ? true
          : false)
    );
    // 配下全てにチェックがある場合
    if (
      cityItemList.length ==
      Object.values(checkedList).filter((val, index) => checkedList[index])
        .length
    ) {
      return true;
    } else {
      return false;
    }
  }

  readyToMountScrollableArea() {
    return (
      this.props.serviceSearch.browseAreaCities != null && !this.isLoading()
    );
  }

  render() {
    return <Fragment>{this.renderAreaDialog()}</Fragment>;
  }

  // ダイアログ表示内容
  renderAreaDialog() {
    const { open, subOkText, okText } = this.props;
    const props = {
      open,
      wide: true,
      wait: this.isLoading(),
      cancelText: "キャンセル",
      subOkText: subOkText,
      okText: okText,
      headCss: "c-modal-head__browseArea",
      onCancel: this.handleCancelButtonClick,
      onClose: this.handleBackgroundClick,
      onSubOk: this.handleSubOkButtonClick,
      onOk: this.handleOkButtonClick,
      title: "市区町村を選択してください。（複数選択できます）",
      bodyText: (
        <div className="form-layout">
          <div className="form-layout__input">{this.renderBodyForm()}</div>
        </div>
      ),
    };
    return <WebFrontDialog {...props} />;
  }

  renderBodyForm() {
    if (!this.readyToMountScrollableArea()) return null;
    const { serviceSearch } = this.props;
    const { browseAreaCities } = serviceSearch;
    return (
      <ScrollableAreaOnModalDialog className="dialog_bodyForm_scroll_browseArea">
        {browseAreaCities.map((option, index) => (
          <div key={index}>
            <div className="c-small-head c-small-head__browseArea">
              <label key={index} className="c-form-checkbox__item2">
                <input
                  type="checkbox"
                  value={option.browseCity}
                  name={`${Parametername.cityArea}.${index}`}
                  checked={this.checkedBundleCheckbox(option.cityItemList)}
                  onChange={(event) =>
                    this.handleBundleCheckboxClick(event, option.cityItemList)
                  }
                />
                <div className="c-form-checkbox__item-text">
                  {option.browseCity}
                </div>
              </label>
            </div>
            <div className="c-form-text">
              {this.renderCityAreaCheckbox(option.cityItemList)}
            </div>
          </div>
        ))}
      </ScrollableAreaOnModalDialog>
    );
  }

  // 市区町村チェックボックス
  renderCityAreaCheckbox(cityItemList) {
    const { onChange } = this.props;
    const { condition } = this.props.serviceSearch;
    const handleChange = (event) =>
      onChange(event.target.name, event.target.checked);
    return (
      <div className="c-form-checkbox2 c-form-checkbox2__cityCheckbox">
        {cityItemList.map((option, index) => (
          <label
            key={index}
            className="c-form-checkbox__item2 c-form-checkbox__item2__cityCheckbox"
          >
            <input
              type="checkbox"
              value={option.cityCode}
              name={`${Parametername.cityArea}.${option.cityCode}`}
              checked={
                condition.getByName(
                  `${Parametername.cityArea}.${option.cityCode}`
                )
                  ? true
                  : false
              }
              onChange={handleChange}
            />
            <div className="c-form-checkbox__item-text">{option.cityLabel}</div>
          </label>
        ))}
      </div>
    );
  }
}

SearchConditionPageCity.propTypes = {
  authentication: PropTypes.object.isRequired,
  serviceSearch: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  location: PropTypes.object.isRequired,
  subOkText: PropTypes.string,
  okText: PropTypes.string,
  handleCancel: PropTypes.func,
  handleClose: PropTypes.func,
  handlSubOk: PropTypes.func,
  handleOk: PropTypes.func,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchConditionPageCity);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps() {
  return {};
}
