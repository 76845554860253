const ActionType = {
  RESET_LOGIN_FORM: "AUTHENTICATION_RESET_LOGIN_FORM",
  CHANGE_LOGIN_FORM: "AUTHENTICATION_CHANGE_LOGIN_FORM",
  LOGIN: "AUTHENTICATION_LOGIN",
  LOGIN_WAS_ACCEPTED: "AUTHENTICATION_LOGIN_WAS_ACCEPTED",
  LOGIN_WAS_DENIED: "AUTHENTICATION_LOGIN_WAS_DENIED",
  LOAD_USER: "AUTHENTICATION_LOAD_USER",
  LOAD_USER_FINISHED: "AUTHENTICATION_LOAD_USER_FINISHED",
  LOAD_USER_FAILED: "AUTHENTICATION_LOAD_USER_FAILED",
  LOGOUT: "AUTHENTICATION_LOGOUT",
  AGREE: "AUTHENTICATION_AGREE",
  AGREE_FINISHED: "AUTHENTICATION_AGREE_FINISHED",
};

export default ActionType;
