import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ServiceYoyaku from "./ServiceYoyaku";
import ServiceKaishi from "./ServiceKaishi";
import ServiceGyoseiJoseiHojo from "./ServiceGyoseiJoseiHojo";

class WatchForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderTeikyoService()}
        {this.renderServiceKaishi()}
        {this.renderYoyaku()}
        {this.renderGyoseiJoseiHojo()}
      </Fragment>
    );
  }

  renderTeikyoService() {
    const { bindingProps } = this.props;
    const services = [
      { code: "monitoringFlg", label: "定期確認（訪問または電話）" },
      { code: "emergencyVisitFlg", label: "緊急時駆けつけ" },
      { code: "gpsFlg", label: "GPS" },
      { code: "sensorFlg", label: "センサー" },
      { code: "familyFlg", label: "緊急時家族へ連絡" },
    ];
    return (
      <FormGrouping title="提供サービス" required>
        <HorizontalLabelLayout>
          <OptionsCheckbox
            name="teikyoService"
            options={services}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderServiceKaishi() {
    const { bindingProps } = this.props;
    return <ServiceKaishi bindingProps={bindingProps} />;
  }

  renderYoyaku() {
    const { bindingProps } = this.props;
    return <ServiceYoyaku bindingProps={bindingProps} />;
  }

  renderGyoseiJoseiHojo() {
    const { bindingProps } = this.props;
    return <ServiceGyoseiJoseiHojo bindingProps={bindingProps} />;
  }
}

WatchForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WatchForm);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
