import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceShubetsuType, {
  serviceShubetsuTypeValueOf,
} from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import RyokinKaigo from "./RyokinKaigo";
import RyokinRehabilitation from "./RyokinRehabilitation";
import RyokinNyuyoku from "./RyokinNyuyoku";
import RyokinKango from "./RyokinKango";
import RyokinTeizui from "./RyokinTeizui";

const Ryokin = (props) => {
  const contents = props.contents;
  const {
    serviceShubetsuCode,
    blocks: { homonkeiServiceRyokin: ryokin },
  } = contents;
  const serviceShubetsuType = serviceShubetsuTypeValueOf(serviceShubetsuCode);

  return (
    <Fragment>
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問介護}
        then={() => <RyokinKaigo {...props} />}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問看護}
        then={() => <RyokinKango {...props} />}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問入浴介護}
        then={() => <RyokinNyuyoku {...props} />}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問リハビリテーション}
        then={() => <RyokinRehabilitation {...props} />}
      />
      <Mount
        if={
          serviceShubetsuType ===
          ServiceShubetsuType.定期巡回_随時対応型訪問介護看護
        }
        then={() => <RyokinTeizui {...props} />}
      />

      <div className="p-office-body-sep">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>都度発生する料金</th>
                  <td>
                    {ryokin.tsudoryokin.map((r) => tsudoryokin(r)).join("\n")}
                  </td>
                </tr>
                <tr>
                  <th>利用者負担軽減制度</th>
                  <td>{ryokin.riyoshafutankeigenseido}</td>
                </tr>
                <tr>
                  <th>支払い方法</th>
                  <td>{ryokin.shiharaiHoho.join("、")}</td>
                </tr>
                <tr>
                  <th>備考</th>
                  <td>{ryokin.biko}</td>
                </tr>
              </tbody>
            </table>
          </div>

          <div className="p-office-body-note">
            ※自己負担額が1割の場合の目安料金です
          </div>

          {goToJigyoshoAttributeFormButton(
            contents,
            JigyoshoAttributeType.homonkeiServiceRyokinkasan
          )}
        </div>
      </div>
    </Fragment>
  );
};

function tsudoryokin(item) {
  let result = `${item.label} ${item.ryokin}`;
  if (item.biko) {
    result += `（${item.biko}）`;
  }
  return result;
}

Ryokin.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default Ryokin;
