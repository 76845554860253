import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { searchConditionFunnelTypeValuesOfMenus } from "./SearchConditionFunnelTypeConfiguration";

class SearchConditionMenuFunnel extends React.Component {
  render() {
    const {
      serviceShubetsuCategoryCode,
      serviceShubetsuMenuCodes,
    } = this.props.condition;
    const types = searchConditionFunnelTypeValuesOfMenus(
      serviceShubetsuCategoryCode,
      serviceShubetsuMenuCodes
    );
    return (
      <Fragment>
        {this.props.condition.serviceShubetsuCategoryCode ? (
          <Fragment>
            {types.map((funnel) => (
              <Fragment key={funnel.name}>
                {funnel.hide_title ? null : (
                  <div className="c-nav-form-title">{funnel.label}</div>
                )}
                {funnel.parameters.map((param) => (
                  <Fragment key={param.name}>
                    {param.label ? (
                      <div className="c-nav-form-subtitle">{param.label}</div>
                    ) : null}
                    {param.type == "checkbox"
                      ? this.renderCheckbox(param)
                      : param.type == "radio"
                      ? this.renderRadio(param)
                      : param.type == "select"
                      ? this.renderSelect(param)
                      : param.type == "select_enclose"
                      ? this.renderSelectEnclose(param)
                      : null}
                  </Fragment>
                ))}
              </Fragment>
            ))}
          </Fragment>
        ) : null}
      </Fragment>
    );
  }

  renderCheckbox(param) {
    const { condition, onChange } = this.props;
    const handleChange = (e) => onChange(e.target.name, e.target.checked);
    const itemCss = param.view_small
      ? "c-form-checkbox__item3"
      : "c-form-checkbox__item2";
    return (
      <div className="c-form-checkbox2">
        {param.options.map((option) => (
          <label key={`${param.name}.${option.code}`} className={itemCss}>
            <input
              type="checkbox"
              name={`${param.name}.${option.code}`}
              checked={
                condition.getByName(`${param.name}.${option.code}`)
                  ? true
                  : false
              }
              onChange={handleChange}
            />
            <div className="c-form-checkbox__item-text">{option.label}</div>
          </label>
        ))}
      </div>
    );
  }

  renderRadio(param) {
    const { condition, onChange } = this.props;
    const handleChange = (e) => onChange(e.target.name, e.target.value);
    return (
      <div className="c-form-radio2">
        {param.options.map((option) => (
          <label
            key={`${param.name}.${option.code}`}
            className="c-form-radio__item2"
          >
            <input
              type="radio"
              name={param.name}
              value={option.code}
              checked={condition.getByName(param.name) == option.code}
              onChange={handleChange}
            />
            <div className="c-form-radio__item-text">{option.label}</div>
          </label>
        ))}
      </div>
    );
  }

  renderSelect(param) {
    const { condition, onChange } = this.props;
    const handleChange = (e) => onChange(e.target.name, e.target.value);
    return (
      <div className="c-nav-form-space">
        <div className="c-form-select">
          <div className="c-form-select__item">
            <select
              name={param.name}
              value={condition.getByName(param.name)}
              onChange={handleChange}
            >
              {param.options.map((option) => (
                <option
                  key={`${param.name}.${option.code}`}
                  value={option.code}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>
    );
  }

  renderSelectEnclose(param) {
    const { condition, onChange } = this.props;
    const handleChange = (e) => onChange(e.target.name, e.target.value);
    return (
      <div className="c-nav-form-space">
        <div className="c-form-select">
          {param.list.map((list, index) => {
            const sideView = list.hide_side ? false : true;
            if (list.type == "select") {
              const itemCss = list.view_small
                ? "c-form-select__smallitem"
                : "c-form-select__item";
              return (
                <div className={itemCss} key={index}>
                  <select
                    name={list.name}
                    value={condition.getByName(list.name)}
                    onChange={handleChange}
                  >
                    {list.options.map((option) => (
                      <option
                        key={`${list.name}.${option.code}`}
                        value={option.code}
                      >
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              );
            } else if (list.type == "block" && sideView) {
              return (
                <div key={index}>
                  {list.mark && (
                    <div className="c-form-select__block">{list.mark}</div>
                  )}
                </div>
              );
            }
          })}
        </div>
      </div>
    );
  }
}

SearchConditionMenuFunnel.propTypes = {
  condition: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SearchConditionMenuFunnel;
