import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import {
  OPTIONS_AVAILABILITY,
  OPTIONS_TAIO_JOKYO,
} from "../../../../../app/ui/form";
import For from "../../../../../app/ui/layout/For";

/**
 * 訪問系サービスリハビリテーションフォーム.
 */
class HomonkeiServiceRehabilitationForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        {this.renderTokucho()}
        {this.renderTantosei()}
        {this.renderKunren()}
        {this.renderBiko()}
      </Fragment>
    );
  }

  renderTokucho() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="サービスの特徴・強み">
        <HorizontalLabelLayout labelText="">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderTantosei() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="担当制">
        <HorizontalLabelLayout labelText="">
          <OptionsRadio
            name="tantosei"
            split={6}
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderKunren() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="訓練内容">
        <For
          in={masterData.homonkeiService_rehabilitationKunren}
          each={(kunren) => (
            <HorizontalLabelLayout labelText={kunren.label} required>
              <OptionsRadio
                name={`kunren.${kunren.code}`}
                split={6}
                options={OPTIONS_TAIO_JOKYO}
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          )}
        />
      </FormGrouping>
    );
  }

  renderBiko() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="備考">
        <HorizontalLabelLayout labelText="">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HomonkeiServiceRehabilitationForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceRehabilitationForm
);
