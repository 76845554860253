import React, { Fragment } from "react";
import PropType from "prop-types";
import { servicePageTagsOf } from "../../service/ServicePageExtraTags";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";

const MapInfoWindow = (props) => {
  const { service, selected, onServiceButtonClick, onSelect } = props;
  const id = [ServiceShubetsuType.活動_通いの場]
    .map((it) => it.code)
    .includes(service.serviceShubetsuCode)
    ? service.serviceId
    : service.id;
  const tags = servicePageTagsOf(service);

  return (
    <Fragment>
      <div className="c-card-frame__item-tag">
        {tags.map((tag, i) => {
          return (
            <div key={i} className="c-card-frame__item-tag-item">
              {tag}
            </div>
          );
        })}
      </div>
      <div className="c-map-balloon__main">
        <div className="c-map-balloon__check">
          <label className="c-check">
            <input
              type="checkbox"
              checked={selected}
              onChange={() => onSelect(service.id)}
            />
            <span className="c-check__box"></span>
          </label>
        </div>
        <div className="c-map-balloon__info">
          <div className="c-map-balloon__title">
            <div className="c-map-balloon__jigyoshomei">
              {service.jigyoshomei}
            </div>
          </div>
          <div className="c-map-balloon__body">{service.jusho}</div>
          <div className="c-map-balloon__detail">
            <a onClick={() => onServiceButtonClick(id, service.serviceSubId)}>
              詳細を見る
            </a>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

MapInfoWindow.propTypes = {
  service: PropType.object.isRequired,
  selected: PropType.bool.isRequired,
  onServiceButtonClick: PropType.func.isRequired,
  onSelect: PropType.func.isRequired,
};

export default MapInfoWindow;
