import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import TruncateText from "../../../../../app/ui/text/TruncateText";

const MenuBlock = ({ menu }) => {
  const LABEL_LIMIT = 120;
  return (
    <Fragment>
      <HeaderTextBlue text="メニュー・活動内容" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>
                <div>メニュー・</div>
                <div>活動名</div>
              </th>
              <td>{menu.title}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height40 tbody-td_textAlign_left">
              <th>内容詳細</th>
              <td>
                <TruncateText maxLength={LABEL_LIMIT}>
                  {menu.details}
                </TruncateText>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

MenuBlock.propTypes = {
  menu: PropTypes.object.isRequired,
};

export default MenuBlock;
