import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import CenteredCircularProgress from "../../app/ui/progress/CenteredCircularProgress";
import { requestBySearch } from "./actions";
import Parametername from "../../app/naming/Parametername";

export function withServiceSearchList(OriginalComponent) {
  class ComponentWithServiceSearch extends React.Component {
    constructor(props) {
      super(props);

      const searchParams = new URLSearchParams(this.props.location.search);
      this.searchParams = searchParams.toString();
      this.serviceShubetsuCategoryCode = searchParams.get(
        Parametername.serviceShubetsuCategoryCode
      );
    }

    componentDidMount() {
      this.props.requestBySearch(
        new URLSearchParams(this.props.location.search).toString()
      );
    }

    render() {
      const { serviceSearch } = this.props;
      if (serviceSearch.listIsLoading) return <CenteredCircularProgress />;
      return (
        <OriginalComponent
          serviceShubetsuCategoryCode={this.serviceShubetsuCategoryCode}
          {...this.props}
        />
      );
    }
  }

  ComponentWithServiceSearch.propTypes = {
    location: PropTypes.object.isRequired,
    serviceSearch: PropTypes.object.isRequired,
    requestBySearch: PropTypes.func.isRequired,
  };

  function mapStateToProps(state) {
    return {
      serviceSearch: state.serviceSearch,
    };
  }

  function mapDispatchToProps(dispatch) {
    return {
      requestBySearch: bindActionCreators(requestBySearch, dispatch),
    };
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(ComponentWithServiceSearch);
}
