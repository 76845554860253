import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { renderStaffJokinHijokin } from "./StaffTable";
import StaffShokushuTable from "./StaffShokushuTable";

const StaffShikakuTeizui = (props) => {
  const { homonkeiServiceStaff: staff } = props.contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-title">看護職員</div>
          <StaffShokushuTable shokushu={staff.kangoshokuin} />
        </div>

        <div className="p-office-body-sep__right">
          <div className="p-office-body-title">訪問介護員</div>
          <StaffShokushuTable shokushu={staff.homonkaigoin} />
        </div>
      </div>

      <div className="p-office-body-title">資格保有者数</div>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__center">
          <div className="p-office-body-table">
            <div className="p-office-body-table-row">
              <div className="thead">
                <span>介護職員初任者研修修了者</span>
              </div>
              <div className="tdata">
                <span>
                  {renderStaffJokinHijokin(staff.kaigoshokuinshoninshakenshu)}
                </span>
              </div>
              <div className="thead border-left">
                <span>看護師</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.kangoshi)}</span>
              </div>
            </div>
            <div className="p-office-body-table-row">
              <div className="thead">
                <span>介護職員実務者研修修了者</span>
              </div>
              <div className="tdata">
                <span>
                  {renderStaffJokinHijokin(staff.kaigoshokuinjitsumushakenshu)}
                </span>
              </div>
              <div className="thead border-left">
                <span>准看護師</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.junkangoshi)}</span>
              </div>
            </div>
            <div className="p-office-body-table-row">
              <div className="thead">
                <span>介護福祉士</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.kaigofukushishi)}</span>
              </div>
              <div className="thead border-left">
                <span>専門・認定看護師</span>
              </div>
              <div className="tdata">
                <span>{staff.semmonNinteikangoshi}</span>
              </div>
            </div>
            <div className="p-office-body-table-row">
              <div className="thead">
                <span>介護プロフェッショナルキャリア段位</span>
              </div>
              <div className="tdata">
                <span>{staff.kaigoProfessionalCareerDani}</span>
              </div>
              <div className="thead border-left">
                <span>保健師</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.hokenshi)}</span>
              </div>
            </div>
            <div className="p-office-body-table-row">
              <div className="thead">
                <span>介護支援専門員</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.kaigoshiensenmonin)}</span>
              </div>
              <div className="thead border-left">
                <span>医師</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.ishi)}</span>
              </div>
            </div>
            <div className="p-office-body-table-row">
              <div className="thead">
                <span>社会福祉士</span>
              </div>
              <div className="tdata">
                <span>{renderStaffJokinHijokin(staff.shakaifukushishi)}</span>
              </div>
              <div className="thead border-left"></div>
              <div className="tdata"></div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

StaffShikakuTeizui.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default StaffShikakuTeizui;
