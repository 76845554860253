import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import TruncateText from "../../../../../app/ui/text/TruncateText";

const OtherMenuBlock = ({ otherItems }) => {
  const LABEL_LIMIT = 80;
  const otherItem1 = otherItems[0];
  const otherItem2 = otherItems[1];
  return (
    <Fragment>
      <HeaderTextBlue text="その他活動内容" />
      <div className="p-office-body-table-flex">
        {otherItem1 ? (
          <div className="p-office-body-table-dotted p-office-body-table-dotted--two-columns">
            <table>
              <tbody>
                <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
                  <th>
                    <div>メニュー・活動名</div>
                  </th>
                  <td>{otherItem1.menuContent.title}</td>
                </tr>
                <tr className="tbody-th_bold tbody-th_width80 tbody-th_height40 tbody-td_textAlign_left">
                  <th>内容詳細</th>
                  <td>
                    <TruncateText maxLength={LABEL_LIMIT}>
                      {otherItem1.menuContent.details}
                    </TruncateText>
                  </td>
                </tr>
                <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
                  <th>活動内容種類</th>
                  <td>
                    <TruncateText maxLength={LABEL_LIMIT}>
                      {otherItem1.katsudonaiyoshuruiList.join()}
                    </TruncateText>
                  </td>
                </tr>
                <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
                  <th>営業・開催日</th>
                  <td>
                    <TruncateText maxLength={LABEL_LIMIT}>
                      {otherItem1.eigyojoho.eigyoKaisaibi}
                    </TruncateText>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
        {otherItem2 ? (
          <div className="p-office-body-table-dotted p-office-body-table-dotted--two-columns">
            <table>
              <tbody>
                <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
                  <th>
                    <div>メニュー・活動名</div>
                  </th>
                  <td>{otherItem2.menuContent.title}</td>
                </tr>
                <tr className="tbody-th_bold tbody-th_width80 tbody-th_height40 tbody-td_textAlign_left">
                  <th>内容詳細</th>
                  <td>
                    <TruncateText maxLength={LABEL_LIMIT}>
                      {otherItem2.menuContent.details}
                    </TruncateText>
                  </td>
                </tr>
                <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
                  <th>活動内容種類</th>
                  <td>
                    <TruncateText maxLength={LABEL_LIMIT}>
                      {otherItem2.katsudonaiyoshuruiList.join()}
                    </TruncateText>
                  </td>
                </tr>
                <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
                  <th>営業・開催日</th>
                  <td>
                    <TruncateText maxLength={LABEL_LIMIT}>
                      {otherItem2.eigyojoho.eigyoKaisaibi}
                    </TruncateText>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </Fragment>
  );
};

OtherMenuBlock.propTypes = {
  otherItems: PropTypes.array.isRequired,
};

export default OtherMenuBlock;
