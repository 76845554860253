import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import ServiceKaishi from "./ServiceKaishi";
import ServiceYoyaku from "./ServiceYoyaku";
import ServiceGyoseiJoseiHojo from "./ServiceGyoseiJoseiHojo";

class MementoForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderTeikyoService()}
        {this.renderOption()}
        {this.renderShikakuTou()}
        {this.renderServiceKaishi()}
        {this.renderYoyaku()}
        {this.renderGyoseiJoseiHojo()}
      </Fragment>
    );
  }

  renderTeikyoService() {
    const { bindingProps } = this.props;
    const services = [
      { code: "furnitureFlg", label: "家財整理" },
      { code: "mementoFlg", label: "遺品整理" },
      { code: "specialCleaningFlg", label: "特殊清掃" },
      { code: "houseFlg", label: "ゴミ屋敷の掃除" },
      { code: "bugFlg", label: "害虫駆除" },
      { code: "searchFlg", label: "物品・書類の捜索" },
      { code: "fireFlg", label: "魂抜き・供養・お焚き上げ" },
      { code: "reuseFlg", label: "不要品下取り" },
      { code: "applianceFlg", label: "家電ゴミ回収" },
      { code: "trashFlg", label: "粗大ごみ回収" },
      { code: "photoFlg", label: "写真の整理" },
      { code: "willFlg", label: "遺言書の作成" },
    ];
    return (
      <FormGrouping title="提供サービス" required>
        <HorizontalLabelLayout>
          <OptionsCheckbox
            name="teikyoService"
            options={services}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderOption() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="オプション" recommended>
        <HorizontalLabelLayout>
          <InputTypeCheckbox
            name="freeEstimateFlg"
            labelText="見積もり無料"
            {...bindingProps}
          />
          <InputTypeCheckbox
            name="womanFlg"
            labelText="女性スタッフによる作業"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderShikakuTou() {
    const { bindingProps } = this.props;
    const services = [
      { code: "licenseLivingFlg", label: "福祉住環境コーディネーター" },
      { code: "licenseMementoFlg", label: "遺品整理士" },
      { code: "licenseSpecialCleaningFlg", label: "事件現場特殊清掃士" },
      { code: "licenseEndingFlg", label: "終活カウンセラー" },
      { code: "licenseWelfareCouncilorFlg", label: "生前整理カウンセラー" },
      { code: "licenseWelfareAdviserFlg", label: "生前整理アドバイザー" },
      { code: "licenseWelfareTechnicianFlg", label: "生前整理作業技能士" },
      { code: "licenseOrganizedFlg", label: "整理収納カウンセラー" },
      { code: "licenseOther", label: "その他介護関連資格" },
    ];
    return (
      <FormGrouping title="資格等" recommended>
        <HorizontalLabelLayout>
          <OptionsCheckbox
            name="shikakuTou"
            options={services}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderServiceKaishi() {
    const { bindingProps } = this.props;
    return <ServiceKaishi bindingProps={bindingProps} />;
  }

  renderYoyaku() {
    const { bindingProps } = this.props;
    return <ServiceYoyaku bindingProps={bindingProps} />;
  }

  renderGyoseiJoseiHojo() {
    const { bindingProps } = this.props;
    return <ServiceGyoseiJoseiHojo bindingProps={bindingProps} />;
  }
}

MementoForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MementoForm);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
