import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import * as actions from "../../../../state/service-search/actions";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_SERVICE,
} from "../../../../state/master-data";
import SearchConditionServiceShubetsuMenu from "./SearchConditionServiceShubetsuMenu";
import { conditionConverter } from "../../../../state/service-search/query-string";
import SearchConditionFunnel from "./SearchConditionFunnel";
import ViewType from "../ViewType";
import Parametername from "../../../../app/naming/Parametername";

class SearchConditionPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
  }

  componentDidMount() {
    const { actions, location, masterData } = this.props;
    actions.makeCondition(location.search, masterData.serviceShubetsuMenu);

    this.presetCityAreas();
  }

  handleSearchButtonClick(view) {
    const { actions, serviceSearch } = this.props;
    actions.changeCondition(Parametername.view, view);
    actions.clearList();
    const search = conditionConverter(serviceSearch.condition).toSearch({
      [Parametername.view]: view.name,
    });
    actions.gotoSearchPage(search);
  }

  // クエリストリングよりConditionバッファー作成
  presetCityAreas() {
    const { actions, location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    var cityAreas = {},
      townAreas = {};
    searchParams
      .getAll(Parametername.cityArea)
      .forEach((val) => (cityAreas[val] = true));
    searchParams
      .getAll(Parametername.townArea)
      .forEach((val) => (townAreas[val] = true));
    // クエリストリングより選択市区町村を保存
    actions.savaConditionBuffer(Parametername.cityArea, cityAreas);
    // クエリストリングより選択町名を保存
    actions.savaConditionBuffer(Parametername.townArea, townAreas);
  }

  render() {
    return (
      <div className="c-content-frame">
        {this.renderServiceShubetsuMenu()}
        {this.renderSearchButton()}
        {this.renderConditionFunnel()}
      </div>
    );
  }

  renderServiceShubetsuMenu() {
    const { masterData, serviceSearch } = this.props;
    const props = {
      serviceShubetsuCategoryCode:
        serviceSearch.condition.serviceShubetsuCategoryCode,
      serviceShubetsuMenu: masterData.serviceShubetsuMenu,
      serviceShubetsuMenuCodes:
        serviceSearch.condition.serviceShubetsuMenuCodes,
      conditionBuffer: serviceSearch.conditionBuffer,
    };
    return <SearchConditionServiceShubetsuMenu {...props} />;
  }

  renderConditionFunnel() {
    const { masterData, serviceSearch, location } = this.props;
    const { condition } = serviceSearch;
    const disabled = condition.serviceShubetsuCategoryCode ? false : true;
    if (disabled) return null;
    const props = {
      serviceSearch,
      location,
      serviceShubetsuCategoryCode:
        serviceSearch.condition.serviceShubetsuCategoryCode,
      serviceShubetsuMenu: masterData.serviceShubetsuMenu,
      serviceShubetsuMenuCodes:
        serviceSearch.condition.serviceShubetsuMenuCodes,
    };
    return (
      <Fragment>
        <SearchConditionFunnel {...props} />
        {this.renderSearchButton()}
      </Fragment>
    );
  }

  renderSearchButton() {
    const { condition } = this.props.serviceSearch;
    const disabled = condition.serviceShubetsuMenuCodes.length < 1;
    return (
      <div className="c-input-button-y u-margin-b64">
        <button
          onClick={() => this.handleSearchButtonClick(ViewType.cardView)}
          disabled={disabled}
        >
          この条件で検索
        </button>
        <button
          className="search-map"
          onClick={() => this.handleSearchButtonClick(ViewType.mapView)}
          disabled={disabled}
        >
          この条件でマップ検索
        </button>
      </div>
    );
  }
}

SearchConditionPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  location: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceSearch: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SERVICE)(
  connect(mapStateToProps, mapDispatchToProps)(SearchConditionPage)
);

function mapStateToProps(state) {
  return {
    masterData: state.masterData,
    serviceSearch: state.serviceSearch,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoSearchPage: (search) =>
        dispatch(
          push({
            pathname: Pathname.webfront_search,
            search,
          })
        ),
    },
  };
}
