const ActionType = {
  RESET: "KANRI_JOB_OFFER_RESET",
  REQUEST: "KANRI_JOB_OFFER_REQUEST",
  REQUEST_FINISHED: "KANRI_JOB_OFFER_REQUEST_FINISHED",
  REMOVE_REQUEST: "KANRI_JOB_OFFER_REMOVE_REQUEST",
  REMOVE_REQUEST_FINISHED: "KANRI_JOB_OFFER_REMOVE_REQUEST_FINISHED",
  REMOVE_REQUEST_FAILED: "KANRI_JOB_OFFER_REMOVE_REQUEST_FAILED",
  APPROVAL_REQUEST_REQUEST: "KANRI_JOB_OFFER_APPROVAL_REQUEST_REQUEST",
  APPROVAL_REQUEST_REQUEST_FINISHED:
    "KANRI_JOB_OFFER_APPROVAL_REQUEST_REQUEST_FINISHED",
  APPROVAL_REQUEST_REQUEST_FAILED:
    "KANRI_JOB_OFFER_APPROVAL_REQUEST_REQUEST_FAILED",
  CLOSE_REQUEST: "KANRI_JOB_OFFER_CLOSE_REQUEST_REQUEST",
  CLOSE_REQUEST_FINISHED: "KANRI_JOB_OFFER_CLOSE_REQUEST_FINISHED",
  CLOSE_REQUEST_FAILED: "KANRI_JOB_OFFER_CLOSE_REQUEST_FAILED",
  APPROVED_REQUEST: "KANRI_JOB_OFFER_APPROVED_REQUEST_REQUEST",
  APPROVED_REQUEST_FINISHED: "KANRI_JOB_OFFER_APPROVED_REQUEST_FINISHED",
  APPROVED_REQUEST_FAILED: "KANRI_JOB_OFFER_APPROVED_REQUEST_FAILED",
};

export default ActionType;
