import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
  serviceShubetsuFilter,
} from "../../../../../state/master-data";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import TimePeriod from "../../../../../app/ui/form/TimePeriod";
import CategorizedOptions from "../../../../../app/util/CategorizedMasterData";

class ServiceTeikyoJikanForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderPeriod()}
        {this.renderSonota()}
      </Fragment>
    );
  }

  renderPeriod() {
    const {
      bindingProps,
      masterData,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode,
    } = this.props;
    const jikanKubunCategories = new CategorizedOptions(
      masterData.serviceTeikyoJikanKubun
    ).getCategories();
    return jikanKubunCategories.map((category) => (
      <FormGrouping key={`category:${category.code}`} title={category.label}>
        {category.options
          .filter(
            serviceShubetsuFilter(
              serviceShubetsuCategoryCode,
              serviceShubetsuCode
            )
          )
          .map((option) => (
            <HorizontalLabelLayout
              key={`option:${option.code}`}
              long
              labelText={option.label}
              recommended={category.code === "001" || category.code === "002"}
            >
              <GridRow>
                {[0, 1, 2].map((slot) => (
                  <GridCell key={`slot:${slot}`}>
                    {option.slot > slot ? (
                      <SpaceOut block comma>
                        <TimePeriod
                          name={`serviceTeikyoJikanTai.${option.code}.${slot}`}
                          separator="."
                          {...bindingProps}
                        />
                      </SpaceOut>
                    ) : null}
                  </GridCell>
                ))}
              </GridRow>
            </HorizontalLabelLayout>
          ))}
      </FormGrouping>
    ));
  }

  renderSonota() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="その他">
        <HorizontalLabelLayout long labelText="留意事項">
          <Textarea name="ryuijiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

ServiceTeikyoJikanForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(ServiceTeikyoJikanForm);
