import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import For from "../../../../../app/ui/layout/For";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import { OPTIONS_TAIO_JOKYO_POSITIVE_DEFAULT } from "../../../../../app/ui/form";

/**
 * 訪問系サービス入浴フォーム.
 */
class HomonkeiServiceNyuyokuForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        {this.renderTokucho()}
        {this.renderNaiyo()}
        {this.renderYokuso()}
        {this.renderSonota()}
        {this.renderBiko()}
      </Fragment>
    );
  }

  renderTokucho() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="サービスの特徴・強み">
        <HorizontalLabelLayout labelText="">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderNaiyo() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="入浴内容">
        <For
          in={masterData.homonkeiService_nyuyoku}
          each={(nyuyoku) => (
            <HorizontalLabelLayout labelText={nyuyoku.label} required>
              <OptionsRadio
                name={`naiyo.${nyuyoku.code}`}
                split={6}
                options={OPTIONS_TAIO_JOKYO_POSITIVE_DEFAULT}
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          )}
        />
      </FormGrouping>
    );
  }

  renderYokuso() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="浴槽">
        <HorizontalLabelLayout labelText="種類">
          <OptionsCheckbox
            name="yokuso"
            options={masterData.homonkeiService_yokuso}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="大きさ">
          <InputTypeText
            name="yokusoSize"
            hintText="例）50×60×60、60×70×80"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderSonota() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="その他">
        <HorizontalLabelLayout labelText="その他サービス" recommended>
          <OptionsCheckbox
            name="sonota"
            options={masterData.homonkeiService_nyuyokuSonota}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="入浴車両台数">
          <ShortInputWidth>
            <UnitLayout unitText="台">
              <InputTypeNumber name="sharyoDaisu" {...bindingProps} />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderBiko() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="備考">
        <HorizontalLabelLayout labelText="">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HomonkeiServiceNyuyokuForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceNyuyokuForm
);
