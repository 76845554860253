import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const RyokinkasanTable = ({ contents }) => {
  const {
    blocks: { shortstayRyokinkasan: ryokinkasan },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-title">介護報酬加算</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            {ryokinkasan.kaigohoshukasanList.map((item, i) => {
              return (
                <tr key={i}>
                  <th>{item.label}</th>
                  <td>{item.list.join("、")}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="p-office-body-title">利用料金の目安</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>都度発生する料金</th>
              <td>{ryokinkasan.tsudoHasseisuruRyokin.join("、")}</td>
            </tr>
            <tr>
              <th>利用者負担軽減制度</th>
              <td>{ryokinkasan.riyoshafutanKeigenSeido}</td>
            </tr>
            <tr>
              <th>支払い方法</th>
              <td>{ryokinkasan.shiharaiHoho.join("、")}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-note">
        ※自己負担額が1割の場合の目安料金です
      </div>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.shortstayRyokinkasan
      )}
    </Fragment>
  );
};

RyokinkasanTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default RyokinkasanTable;
