import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";

function reset() {
  return {
    type: ActionType.RESET,
  };
}

export function requestFeatures(id) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST_FEATURES,
    });

    const url = ApiPath.api_feature_hojin_id.replace(/:id/, id);
    const onSuccess = (json) => {
      dispatch({
        type: ActionType.REQUEST_FEATURES_FINISHED,
        payload: json,
      });
    };
    ApiRequest.get(url).request(
      defaultResponseHandler(dispatch, onSuccess),
      defaultUnauthorizedHander(dispatch)
    );
  };
}

export function activateJobOffer(id, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.EDIT_FEATURE_ACTIVATION_SUBMIT,
    });

    const url = ApiPath.api_feature_hojin_id_job_offer.replace(/:id/, id);
    const onSuccess = () => {
      dispatch({
        type: ActionType.EDIT_FEATURE_ACTIVATION_FINISHED,
      });
      setTimeout(callback);
    };
    const onFailure = () => {
      dispatch({
        type: ActionType.EDIT_FEATURE_ACTIVATION_FAILED,
      });
    };
    ApiRequest.post(url).request(
      withCompletionMessage(
        dispatch,
        "求人機能を有効にしました。"
      )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
      defaultUnauthorizedHander(dispatch)
    );
  };
}

export function deactivateJobOffer(id, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.EDIT_FEATURE_ACTIVATION_SUBMIT,
    });

    const url = ApiPath.api_feature_hojin_id_job_offer.replace(/:id/, id);
    const onSuccess = () => {
      dispatch({
        type: ActionType.EDIT_FEATURE_ACTIVATION_FINISHED,
      });
      setTimeout(callback);
    };
    const onFailure = () => {
      dispatch({
        type: ActionType.EDIT_FEATURE_ACTIVATION_FAILED,
      });
    };
    ApiRequest.delete(url).request(
      withCompletionMessage(
        dispatch,
        "求人機能を無効にしました。"
      )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
      defaultUnauthorizedHander(dispatch)
    );
  };
}

export function activatePlus(id, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.EDIT_FEATURE_PLUS_ACTIVATION_SUBMIT,
    });

    const url = ApiPath.api_feature_hojin_id_plus.replace(/:id/, id);
    const onSuccess = () => {
      dispatch({
        type: ActionType.EDIT_FEATURE_PLUS_ACTIVATION_FINISHED,
      });
      setTimeout(callback);
    };
    const onFailure = () => {
      dispatch({
        type: ActionType.EDIT_FEATURE_PLUS_ACTIVATION_FAILED,
      });
    };
    ApiRequest.post(url).request(
      withCompletionMessage(
        dispatch,
        "ミルモネットプラスを有効にしました。"
      )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
      defaultUnauthorizedHander(dispatch)
    );
  };
}

export function deactivatePlus(id, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.EDIT_FEATURE_PLUS_ACTIVATION_SUBMIT,
    });

    const url = ApiPath.api_feature_hojin_id_plus.replace(/:id/, id);
    const onSuccess = () => {
      dispatch({
        type: ActionType.EDIT_FEATURE_PLUS_ACTIVATION_FINISHED,
      });
      setTimeout(callback);
    };
    const onFailure = () => {
      dispatch({
        type: ActionType.EDIT_FEATURE_PLUS_ACTIVATION_FAILED,
      });
    };
    ApiRequest.delete(url).request(
      withCompletionMessage(
        dispatch,
        "ミルモネットプラスを無効にしました。"
      )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
      defaultUnauthorizedHander(dispatch)
    );
  };
}
