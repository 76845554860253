import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { serviceShubetsuCategoryHeadViewTypeValueOf } from "./ServiceShubetsuCategoryHeadViewType";
import ViewTypeToggle from "./ViewTypeToggle";
import SortablePaginator from "../../../app/ui/paginator/SortablePaginator";
import { serviceSearchSortOptions } from "../../../app/domain/service/ServiceSearchSortType";
import WebFrontDialog from "../../../app/ui/dialog/WebFrontDialog";
import SearchResultMapViewComponent from "./view-map/SearchResultMapViewComponent";
import Parametername from "../../../app/naming/Parametername";
import ViewType from "./ViewType";
import CenteredCircularProgress from "../../../app/ui/progress/CenteredCircularProgress";
import ServiceShubetsuType from "../../../app/domain/jigyosho/ServiceShubetsuType";

class SearchResultComponent extends React.Component {
  constructor(props) {
    super(props);
    this.handlePrintButtonClick = this.handlePrintButtonClick.bind(this);
    this.handleServiceButtonClick = this.handleServiceButtonClick.bind(this);
    this.handleServiceCheckboxClick = this.handleServiceCheckboxClick.bind(
      this
    );
    this.handleBundleCheckboxClick = this.handleBundleCheckboxClick.bind(this);
    this.handleShareButtonClick = this.handleShareButtonClick.bind(this);
    this.handleBundleShareButtonClick = this.handleBundleShareButtonClick.bind(
      this
    );
    this.handleBundlePrintButtonClick = this.handleBundlePrintButtonClick.bind(
      this
    );
    this.handlePaginatorButtonClick = this.handlePaginatorButtonClick.bind(
      this
    );
    this.handleScrollBarScroll = this.handleScrollBarScroll.bind(this);
    this.handleScrollableTableScroll = this.handleScrollableTableScroll.bind(
      this
    );
    this.handleMapBoundsChange = this.handleMapBoundsChange.bind(this);
    this.serviceIsSelected = this.serviceIsSelected.bind(this);
    this.serviceIsShared = this.serviceIsShared.bind(this);
    this.nodes = {
      scrollBar: React.createRef(),
      scrollableTable: React.createRef(),
    };
  }

  componentWillUnmount() {
    clearTimeout(this.handleMapBoundsChangeProcess);
  }

  state = {
    shareDialogOpen: false,
  };

  handlePrintButtonClick(id, serviceSubId) {
    const { actions } = this.props;
    actions.openPrintPage(id, serviceSubId);
  }

  handleServiceButtonClick(id, serviceSubId) {
    const { actions } = this.props;
    actions.gotoServicePage(id, serviceSubId, this.props.history);
  }

  handleServiceCheckboxClick(id) {
    const { actions } = this.props;
    actions.select(id);
  }

  handleBundleCheckboxClick() {
    const { actions, serviceSearch } = this.props;
    if (this.allServicesAreSelected()) {
      serviceSearch.list.forEach((it) => actions.select(it.id));
    } else {
      serviceSearch.list
        .filter((it) => !this.serviceIsSelected(it.id))
        .forEach((it) => actions.select(it.id));
    }
  }

  handleShareButtonClick(id, serviceSubId) {
    const { actions } = this.props;
    return this.serviceIsShared(id, serviceSubId)
      ? actions.unshare(id, serviceSubId)
      : actions.share(id, serviceSubId);
  }

  handleBundleShareButtonClick() {
    this.setState({ shareDialogOpen: true });
  }

  handleBundlePrintButtonClick() {
    const { actions, serviceSearch } = this.props;
    actions.openListPrintPage(
      serviceSearch.selectedIds,
      serviceSearch.condition.serviceShubetsuCategoryCode,
      serviceSearch.condition.sort,
      this.isMapView(),
      this.isCardView()
    );
  }

  handlePaginatorButtonClick(search) {
    const { actions } = this.props;
    actions.page(search);
  }

  handleScrollBarScroll() {
    this.nodes.scrollableTable.current.scrollLeft = this.nodes.scrollBar.current.scrollLeft;
  }

  handleScrollableTableScroll() {
    clearTimeout(this.tableScrollTimer);
    this.tableScrollTimer = setTimeout(() => {
      this.nodes.scrollBar.current.scrollLeft = this.nodes.scrollableTable.current.scrollLeft;
    }, 100);
  }

  handleMapBoundsChange = (bounds) => {
    clearTimeout(this.handleMapBoundsChangeProcess);
    this.handleMapBoundsChangeProcess = setTimeout(() => {
      const { actions, serviceSearch, masterData } = this.props;

      const searchParams = new URLSearchParams(serviceSearch.search);
      searchParams.set(Parametername.view, ViewType.mapView.name);
      searchParams.set(
        Parametername.minLat,
        bounds.getSouthWest().lat().toString()
      );
      searchParams.set(
        Parametername.maxLat,
        bounds.getNorthEast().lat().toString()
      );
      searchParams.set(
        Parametername.minLng,
        bounds.getSouthWest().lng().toString()
      );
      searchParams.set(
        Parametername.maxLng,
        bounds.getNorthEast().lng().toString()
      );
      searchParams.set(Parametername.page, "1");

      actions.requestBySearch(
        searchParams.toString(),
        masterData.serviceShubetsuMenu
      );
    }, 100);
  };

  serviceIsShared(id, serviceSubId) {
    const { services } = this.props.sharedFolder;
    return services.find(
      (s) =>
        (![ServiceShubetsuType.活動_通いの場]
          .map((it) => it.code)
          .includes(s.serviceShubetsuCode) &&
          s.id == id) ||
        ([ServiceShubetsuType.活動_通いの場]
          .map((it) => it.code)
          .includes(s.serviceShubetsuCode) &&
          s.id == id &&
          s.serviceSubId == serviceSubId)
    )
      ? true
      : false;
  }

  serviceIsSelected(id) {
    const { serviceSearch } = this.props;
    return serviceSearch.selectedIds.includes(id);
  }

  allServicesAreSelected() {
    const { serviceSearch } = this.props;
    if (serviceSearch.list.length < 1) return false;
    return serviceSearch.list.filter((it) => !this.serviceIsSelected(it.id))
      .length
      ? false
      : true;
  }

  serviceShubetsuCategory() {
    const { serviceSearch, masterData } = this.props;
    const { condition } = serviceSearch;
    const { serviceShubetsuMenu } = masterData;
    this.serviceShubetsuCategoryCode = condition.serviceShubetsuCategoryCode
      ? condition.serviceShubetsuCategoryCode
      : this.serviceShubetsuCategoryCode;
    return serviceShubetsuMenu
      .map((it) => it.category)
      .find((it) => it.code == this.serviceShubetsuCategoryCode);
  }

  isCardView() {
    const { condition } = this.props.serviceSearch;
    return condition.view.name === ViewType.cardView.name;
  }

  isMapView() {
    const { condition } = this.props.serviceSearch;
    return condition.view.name === ViewType.mapView.name;
  }

  isTableView() {
    const { condition } = this.props.serviceSearch;
    return condition.view.name === ViewType.tableView.name;
  }

  render() {
    const { serviceSearch, siteIsServiceSearch } = this.props;
    return (
      <div>
        {this.renderShareDialog()}
        {siteIsServiceSearch ||
        serviceSearch.list.length > 0 ||
        serviceSearch.listIsLoading
          ? this.renderMain()
          : this.renderMessageForEmpty()}
      </div>
    );
  }

  renderMain() {
    const serviceShubetsuCategory = this.serviceShubetsuCategory();
    return (
      <Fragment>
        <div className="c-middle-header">
          <div className="c-middle-header__text">
            {serviceShubetsuCategory ? serviceShubetsuCategory.label : null}
          </div>
          <div className="c-middle-header__sub">
            {this.renderViewTypeToggle()}
          </div>
        </div>
        {this.renderPaginator()}
        {this.renderList()}
        {this.renderPaginator()}
      </Fragment>
    );
  }

  renderMessageForEmpty() {
    return (
      <div className="l-folder-sep__message-for-empty">
        <p>共有フォルダに登録された情報はありません。</p>
        <p>共有フォルダに登録するとより簡単にアクセスできます。</p>
      </div>
    );
  }

  renderShareDialog() {
    const dialogProps = {
      // status
      open: this.state.shareDialogOpen,
      // text
      title: "共有フォルダに追加",
      bodyText:
        "選択した施設をまとめて共有フォルダに追加します。共有フォルダは同一事業所内のメンバーで共有することができます。",
      // button
      okText: "追加する",
      cancelText: "キャンセル",
      // function
      onCancel: () => this.setState({ shareDialogOpen: false }),
      onOk: () => {
        this.setState({ shareDialogOpen: false });
        const { actions, serviceSearch } = this.props;
        const { condition, list } = serviceSearch;
        const selectedList = list.filter((it) =>
          serviceSearch.selectedIds.includes(it.id)
        );
        [ServiceShubetsuType.活動_通いの場]
          .map((it) => it.code)
          .includes(condition.serviceShubetsuCategoryCode)
          ? selectedList.forEach((it) =>
              actions.share(it.serviceId, it.serviceSubId)
            )
          : serviceSearch.selectedIds.forEach((id) => actions.share(id));
      },
      onClose: () => this.setState({ shareDialogOpen: false }),
    };
    return <WebFrontDialog {...dialogProps} />;
  }

  renderViewTypeToggle() {
    const { serviceSearch, siteIsSharedFolder } = this.props;
    const props = {
      condition: serviceSearch.condition,
      search: serviceSearch.search,
      siteIsSharedFolder: siteIsSharedFolder,
    };
    return <ViewTypeToggle {...props} />;
  }

  renderList() {
    const { condition, list, listIsLoading } = this.props.serviceSearch;
    if (this.isMapView()) return this.renderMapList(list);
    if (listIsLoading) return this.renderLoading();
    if (list.length === 0) return this.renderNotFound();
    return condition.view.selector({
      card: (list) => this.renderCardList(list),
      table: (list) => this.renderTableList(list),
    })(list);
  }

  renderLoading() {
    return (
      <div className="c-loading-frame">
        <CenteredCircularProgress />
      </div>
    );
  }

  renderNotFound() {
    return (
      <div className="l-folder-sep__result-empty">
        <img src="/images/chara_research.png" />
        <p>
          該当するサービスが見つかりませんでした。
          <br />
          検索条件を変えて再検索してください。
          <br />
        </p>
      </div>
    );
  }

  renderTableList(services) {
    return (
      <div>
        {this.renderListUtilityTop()}

        <div className="c-table-boundary">
          <div
            className="c-table-scroll-bar"
            ref={this.nodes.scrollBar}
            onScroll={this.handleScrollBarScroll}
          >
            {this.renderTableHead()}
          </div>
          <div
            className="c-table-frame"
            ref={this.nodes.scrollableTable}
            onScroll={this.handleScrollableTableScroll}
          >
            {this.renderTableHead()}
            {services.map(this.renderServiceOnTable.bind(this))}
          </div>
        </div>

        {this.renderListUtilityBottom()}
      </div>
    );
  }

  renderTableHead() {
    const serviceShubetsuCategory = this.serviceShubetsuCategory();
    if (!serviceShubetsuCategory) return null;
    const viewType = serviceShubetsuCategoryHeadViewTypeValueOf(
      serviceShubetsuCategory.code
    );
    const TableHeadView = viewType.tableHead;
    return <TableHeadView />;
  }

  renderServiceOnTable(service) {
    const viewType = serviceShubetsuCategoryHeadViewTypeValueOf(
      service.serviceShubetsuCategoryCode
    );
    const TableView = viewType.table;
    const viewProps = {
      key: service.id,
      onServiceButtonClick: this.handleServiceButtonClick,
      service,
      selected: this.serviceIsSelected(service.id),
      onSelect: this.handleServiceCheckboxClick,
    };
    return <TableView {...viewProps} />;
  }

  renderCardList(services) {
    return (
      <div>
        {this.renderListUtilityTop()}

        <div className="c-card-frame">
          {services.map(this.renderServiceOnCard.bind(this))}
        </div>

        {this.renderListUtilityBottom()}
      </div>
    );
  }

  renderServiceOnCard(service) {
    const viewType = serviceShubetsuCategoryHeadViewTypeValueOf(
      service.serviceShubetsuCategoryCode
    );
    const CardView = viewType.card;
    const id = [ServiceShubetsuType.活動_通いの場]
      .map((it) => it.code)
      .includes(service.serviceShubetsuCode)
      ? service.serviceId
      : service.id;
    const viewProps = {
      key: service.id,
      onPrintButtonClick: this.handlePrintButtonClick,
      onServiceButtonClick: this.handleServiceButtonClick,
      onShareButtonClick: this.handleShareButtonClick,
      shared: this.serviceIsShared(id, service.serviceSubId),
      service,
      selected: this.serviceIsSelected(service.id),
      onSelect: this.handleServiceCheckboxClick,
    };
    return <CardView {...viewProps} />;
  }

  renderMapList(services) {
    const viewProps = {
      services: services,
      onPrintButtonClick: this.handlePrintButtonClick,
      onServiceButtonClick: this.handleServiceButtonClick,
      onShareButtonClick: this.handleShareButtonClick,
      serviceIsShared: this.serviceIsShared,
      serviceIsSelected: this.serviceIsSelected,
      onSelect: this.handleServiceCheckboxClick,
      siteIsSharedFolder: this.props.siteIsSharedFolder,
      onBoundsChanged: this.props.siteIsSharedFolder
        ? null
        : this.handleMapBoundsChange,
    };
    return (
      <div>
        {this.renderListUtilityTop()}

        <SearchResultMapViewComponent {...viewProps} />

        {this.renderListUtilityBottom()}
      </div>
    );
  }

  renderListUtilityTop() {
    return (
      <div className="c-balloon c-balloon--top">
        {this.renderListUtilityMain()}
      </div>
    );
  }

  renderListUtilityBottom() {
    return (
      <div className="c-balloon c-balloon--bottom">
        {this.renderListUtilityMain()}
      </div>
    );
  }

  renderListUtilityMain() {
    const { selectedIds } = this.props.serviceSearch;
    const disabled = selectedIds.length < 1;
    return (
      <Fragment>
        <div className="c-balloon__left">
          <div className="c-balloon__left-check">
            <label className="c-check">
              <input
                id="all-select-checkbox-bottom"
                type="checkbox"
                checked={this.allServicesAreSelected()}
                onChange={this.handleBundleCheckboxClick}
              />
              <span className="c-check__box"></span>
            </label>
            <label
              htmlFor="all-select-checkbox-bottom"
              className="c-balloon__left-check-txt"
            >
              すべてチェック
            </label>
          </div>
        </div>
        <div className="c-balloon__right">
          <div className="c-balloon__right-text c-balloon__right-text--check">
            チェックした施設をまとめて
          </div>
          {!this.props.siteIsSharedFolder ? (
            <div className="c-button-r">
              <a
                onClick={() => !disabled && this.handleBundleShareButtonClick()}
                disabled={disabled}
              >
                共有フォルダに追加
              </a>
            </div>
          ) : null}
          <div className="c-button-b">
            <a
              onClick={() => !disabled && this.handleBundlePrintButtonClick()}
              disabled={disabled}
            >
              印刷
            </a>
          </div>
          {/*
                    <div className="c-button-g">
                        <a>マイリストに追加</a>
                    </div>
                    */}
        </div>
      </Fragment>
    );
  }

  renderPaginator() {
    const {
      search = "",
      pagination,
      condition,
      listIsLoading,
    } = this.props.serviceSearch;
    const { siteIsSharedFolder } = this.props;
    return (
      <SortablePaginator
        search={search}
        sortOptions={serviceSearchSortOptions()}
        onPaginationKick={(search) => this.handlePaginatorButtonClick(search)}
        siteIsSharedFolder={siteIsSharedFolder}
        view={condition.view}
        {...pagination}
        loading={listIsLoading}
      />
    );
  }
}

SearchResultComponent.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  masterData: PropTypes.object.isRequired,
  serviceSearch: PropTypes.object.isRequired,
  sharedFolder: PropTypes.object.isRequired,
  siteIsSharedFolder: PropTypes.bool,
  siteIsServiceSearch: PropTypes.bool,
  history: PropTypes.object.isRequired,
};

export default withRouter(SearchResultComponent);
