const ActionType = {
  RESET: "FEATURE_RESET",
  REQUEST_FEATURES: "FEATURE_REQUEST_FEATURES",
  REQUEST_FEATURES_FINISHED: "FEATURE_REQUEST_FEATURES_FINISHED",
  EDIT_FEATURE_ACTIVATION_SUBMIT: "FEATURE_EDIT_FEATURE_ACTIVATION_SUBMIT",
  EDIT_FEATURE_ACTIVATION_FINISHED: "FEATURE_EDIT_FEATURE_ACTIVATION_FINISHED",
  EDIT_FEATURE_ACTIVATION_FAILED: "FEATURE_EDIT_FEATURE_ACTIVATION_FAILED",
  EDIT_FEATURE_PLUS_ACTIVATION_SUBMIT:
    "FEATURE_EDIT_FEATURE_PLUS_ACTIVATION_SUBMIT",
  EDIT_FEATURE_PLUS_ACTIVATION_FINISHED:
    "FEATURE_EDIT_FEATURE_PLUS_ACTIVATION_FINISHED",
  EDIT_FEATURE_PLUS_ACTIVATION_FAILED:
    "FEATURE_EDIT_FEATURE_PLUS_ACTIVATION_FAILED",
};

export default ActionType;
