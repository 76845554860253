import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Right from "../../../../app/ui/layout/Right";
import PrevNextPaginator from "../../../../app/ui/paginator/PrevNextPaginator";

class JigyoshoListPaginator extends React.Component {
  constructor(props) {
    super(props);
    this.handlePaginatorButtonClick = this.handlePaginatorButtonClick.bind(
      this
    );
  }

  handlePaginatorButtonClick(search) {
    this.props.onPaginatorButtonClick(search);
  }

  render() {
    const { prevSearch, nextSearch, pagination } = this.props;
    return (
      <Right>
        <PrevNextPaginator
          onPrevButtonClick={() => this.handlePaginatorButtonClick(prevSearch)}
          onNextButtonClick={() => this.handlePaginatorButtonClick(nextSearch)}
          {...pagination}
        />
      </Right>
    );
  }
}

JigyoshoListPaginator.propTypes = {
  // state
  prevSearch: PropTypes.string,
  nextSearch: PropTypes.string,
  pagination: PropTypes.object.isRequired,
  // action
  onPaginatorButtonClick: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JigyoshoListPaginator);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onPaginatorButtonClick: (search) => dispatch(push({ search })),
  };
}
