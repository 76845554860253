import React, { Fragment } from "react";
import PropTypes from "prop-types";
import EigyoJikanTable from "../content/kyotakukaigoshienjigyosho/EigyoJikanTable";
import ServiceTable from "../content/kyotakukaigoshienjigyosho/ServiceTable";
import StaffTable from "../content/kyotakukaigoshienjigyosho/StaffTable";
import RyokinkasanTable from "../content/kyotakukaigoshienjigyosho/RyokinkasanTable";
import JigyoshoJohoTable from "../content/kyotakukaigoshienjigyosho/JigyoshoJohoTable";

class KyotakukaigoshienjigyoshoDetail extends React.Component {
  render() {
    return (
      <div>
        <div className="p-office-body">
          {this.renderEigyoJoho()}

          {this.renderStaff()}

          {this.renderRyokinkasan()}

          {this.renderJigyoshoJoho()}
        </div>
      </div>
    );
  }

  renderEigyoJoho() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">営業情報</div>
        </div>

        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-title">営業時間</div>
            <EigyoJikanTable contents={this.props.contents} detail={true} />
          </div>

          <div className="p-office-body-sep__right">
            <div className="p-office-body-title">訪問・電話対応</div>
            <ServiceTable contents={this.props.contents} detail={true} />
          </div>
        </div>
      </Fragment>
    );
  }

  renderStaff() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">スタッフ</div>
        </div>
        <StaffTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderRyokinkasan() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">料金加算</div>
        </div>
        <RyokinkasanTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderJigyoshoJoho() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">事業所情報</div>
        </div>
        <JigyoshoJohoTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }
}

KyotakukaigoshienjigyoshoDetail.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default KyotakukaigoshienjigyoshoDetail;
