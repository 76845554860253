import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Thumbnail from "../../search/Thumbnail";
import Mount from "../../../../app/ui/layout/Mount";
import TruncateText from "../../../../app/ui/text/TruncateText";
import CommonUneiJohoBlock from "./hokennaiService/CommonUneiJohoBlock";
import StaffBlock from "./kyotakukaigoshienjigyosho/StaffBlock";
import HomonDenwaBlock from "./kyotakukaigoshienjigyosho/HomonDenwaBlock";
import KaigoHosyuKasanBlock from "./kyotakukaigoshienjigyosho/KaigoHoshuKasanBlock";
import ValueWithYubinMarkUnit from "../../../../app/ui/unit/ValueWithYubinMarkUnit";

class KyotakukaigoshienjigyoshoPrint extends React.Component {
  render() {
    const { contents } = this.props;
    return (
      <Fragment>
        {this.renderHead(contents)}
        <div className="print-body__block print-body__block--two-columns print-body__block--hokengai-font">
          <div>{this.renderPhoto(contents)}</div>
          <div>{this.renderStaff(contents)}</div>
        </div>
        <div className="print-body__block print-body__block--two-columns print-body__block--hokengai-font">
          <div>{this.renderHomonDenwa(contents)}</div>
          <div>{this.renderKaigohoshuKasan(contents)}</div>
        </div>
        <hr />
        {this.renderUneiJoho(contents)}
      </Fragment>
    );
  }

  renderHead(contents) {
    return (
      <div className="print-body__head">
        {this.renderHeadLeft(contents)}
        {this.renderHeadRight(contents)}
        <img src="/images/print_bg_light_yellow.png" />
      </div>
    );
  }

  renderHeadLeft(contents) {
    const { kihonjoho: kihonjoho } = contents;
    const tags = [];
    tags.push(kihonjoho.serviceShubetsu);

    return (
      <div>
        <div className="service-shubetsu">
          {tags.map((tag) => (
            <div key={tag}>
              {tag}
              <img
                style={{ borderRadius: "0 0 4pt 4pt" }}
                src="/images/print_bg_light_blue.png"
              />
            </div>
          ))}
        </div>
        <div className="eigyo-joho">
          <ul>
            {kihonjoho.kaigoyoboshienJoho ? (
              <li className="open">
                予防
                <img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                予防
                <img src="/images/print_bg_light_gray.png" />
              </li>
            )}
          </ul>
          <ul>
            {kihonjoho.doyobieigyo ? (
              <li className="open">
                土<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                土<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {kihonjoho.nichiyobieigyo ? (
              <li className="open">
                日<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                日<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {kihonjoho.shukujitsueigyo ? (
              <li className="open">
                祝<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                祝<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
          </ul>
        </div>

        <div className="jigyoshomei">{kihonjoho.jigyoshomei}</div>
        <div className="rinen">{kihonjoho.tokuchorinen}</div>
      </div>
    );
  }

  renderHeadRight(contents) {
    const { kihonjoho: kihonjoho } = contents;
    const LABEL_LIMIT = 46;
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>サ</span>
                  <span>ー</span>
                  <span>ビ</span>
                  <span>ス</span>
                </span>
                <span>
                  <span>提</span>
                  <span>供</span>
                  <span>時</span>
                  <span>間</span>
                </span>
              </th>
              <td colSpan="3">{kihonjoho.serviceTeikyojikan}</td>
            </tr>
            <tr>
              <th className="paddingTel">
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>T</span>
                  <span>E</span>
                  <span>L</span>
                </span>
              </th>
              <td className="fontTel">{kihonjoho.kokairenrakusaki.tel}</td>
              <Mount if={kihonjoho.kokairenrakusaki.fax}>
                <th className="paddingTel">
                  <img className="bgWhite" src="/images/print_bg_white.png" />
                  <span>
                    <span>F</span>
                    <span>A</span>
                    <span>X</span>
                  </span>
                </th>
                <td className="paddingTel">{kihonjoho.kokairenrakusaki.fax}</td>
              </Mount>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>住</span>
                  <span>所</span>
                </span>
              </th>
              <td colSpan="3">
                <div>
                  <ValueWithYubinMarkUnit
                    value={kihonjoho.kokaijusho.yubimbango}
                  />
                </div>
                <div>{kihonjoho.kokaijusho.jusho}</div>
              </td>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>サ</span>
                  <span>ー</span>
                  <span>ビ</span>
                  <span>ス</span>
                </span>
                <span>
                  <span>提</span>
                  <span>供</span>
                  <span>エ</span>
                  <span>リ</span>
                  <span>ア</span>
                </span>
              </th>
              <td colSpan="3">
                <TruncateText maxLength={LABEL_LIMIT}>
                  {kihonjoho.serviceTeikyoArea}
                </TruncateText>
              </td>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>定</span>
                  <span>休</span>
                  <span>日</span>
                </span>
              </th>
              <td colSpan="3">{kihonjoho.teikyubi}</td>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>併</span>
                  <span></span>
                  <span></span>
                  <span>設</span>
                </span>
                <span>
                  <span>サ</span>
                  <span>ー</span>
                  <span>ビ</span>
                  <span>ス</span>
                </span>
              </th>
              <td colSpan="3">
                <TruncateText maxLength={LABEL_LIMIT}>
                  {kihonjoho.heisetsuService}
                </TruncateText>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderPhoto(contents) {
    const photo1 = contents.photos[0] || { imageUrl: "", description: "" };

    return (
      <div style={{ marginTop: "10pt" }}>
        <div className="print-body__thumbnail_single">
          <Thumbnail src={photo1.imageUrl} description={photo1.description} />
        </div>
      </div>
    );
  }

  renderStaff(contents) {
    const { shokuintaisei: shokuintaisei } = contents;
    return <StaffBlock shokuintaisei={shokuintaisei} />;
  }

  renderHomonDenwa(contents) {
    const { homondenwataio: homondenwataio } = contents;

    return <HomonDenwaBlock homondenwa={homondenwataio} />;
  }

  renderKaigohoshuKasan(contents) {
    const { kaigohoshukasan: kaigohoshu } = contents;

    return <KaigoHosyuKasanBlock kaigohoshu={kaigohoshu} />;
  }

  renderUneiJoho(contents) {
    const { uneijoho: jigyoshoJoho, serviceShubetsuCategoryCode } = contents;

    return (
      <CommonUneiJohoBlock
        uneiJoho={jigyoshoJoho}
        serviceShubetsuCategoryCode={serviceShubetsuCategoryCode}
      />
    );
  }
}

KyotakukaigoshienjigyoshoPrint.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default KyotakukaigoshienjigyoshoPrint;
