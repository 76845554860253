import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { push } from "react-router-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../state/service-search/actions";
import SharedFolderMenu from "./SharedFolderMenu";
import SharedFolderContents from "./SharedFolderContents";
import {
  MASTER_DATA_BUNDLE_FOR_SERVICE,
  withMasterData,
} from "../../../state/master-data/index";
import { searchFilter } from "../../../state/service-search/query-string";
import Pathname from "../../../app/naming/Pathname";

class SharedFolderPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleServiceSearchTabClick = this.handleServiceSearchTabClick.bind(
      this
    );
  }

  componentDidMount() {
    const { actions, location, masterData } = this.props;
    actions.makeCondition(location.search, masterData.serviceShubetsuMenu);
    actions.requestBySearch(
      new URLSearchParams(location.search).toString(),
      masterData.serviceShubetsuMenu
    );
  }

  handleServiceSearchTabClick() {
    const { actions, location } = this.props;
    const search = searchFilter(location.search).forServiceSearch();
    actions.gotoSearchConditionPage(search);
  }

  folders() {
    const { services } = this.props.sharedFolder;
    if (!services) return [];
    return services
      .map((service) => ({
        serviceShubetsuCategoryCode: service.serviceShubetsuCategoryCode,
        serviceShubetsuCode: service.serviceShubetsuCode,
        count: 1,
      }))
      .reduce(
        (list, shubetsu) =>
          list.find(
            (s) => s.serviceShubetsuCode == shubetsu.serviceShubetsuCode
          )
            ? (function () {
                list.find(
                  (s) => s.serviceShubetsuCode == shubetsu.serviceShubetsuCode
                ).count++;
                return list;
              })()
            : list.concat([shubetsu]),
        []
      );
  }

  render() {
    return (
      <div className="c-content-frame">
        <div className="l-folder-sep">
          {this.renderSide()}
          <div className="l-folder-sep__folder">{this.renderMain()}</div>
        </div>
      </div>
    );
  }

  renderSide() {
    const { masterData, serviceSearch } = this.props;
    const props = {
      condition: serviceSearch.condition,
      serviceShubetsuMenu: masterData.serviceShubetsuMenu,
      folders: this.folders(),
    };
    return <SharedFolderMenu {...props} />;
  }

  renderMain() {
    const { listIsLoading } = this.props.serviceSearch;
    if (listIsLoading) return null;
    return (
      <Fragment>
        <SharedFolderContents />
        <div className="c-button-chara-y">
          <a onClick={this.handleServiceSearchTabClick}>
            この種別をすべての情報から探す
          </a>
        </div>
      </Fragment>
    );
  }
}

SharedFolderPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  location: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceSearch: PropTypes.object.isRequired,
  sharedFolder: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SERVICE)(
  connect(mapStateToProps, mapDispatchToProps)(SharedFolderPage)
);

function mapStateToProps(state) {
  return {
    masterData: state.masterData,
    serviceSearch: state.serviceSearch,
    sharedFolder: state.sharedFolder,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoSearchConditionPage: (search) =>
        dispatch(
          push({
            pathname: Pathname.webfront_search,
            search,
          })
        ),
    },
  };
}
