import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import { formDataOf } from "../../../app/fundamental/data/FormData";

const initialState = {
  list: [],
  listIsLoading: false,
  listIsSubmitting: false,
  form: undefined,
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.REQUEST]: function (state) {
    return {
      ...state,
      listIsLoading: true,
    };
  },
  [ActionType.REQUEST_FINISHED]: function (state, action) {
    const form = formDataOf({});
    action.payload.list.forEach(
      (it) =>
        (form[it.id] = {
          id: it.id,
          categoryCode: it.categoryCode,
          description: it.description,
        })
    );
    return {
      ...state,
      listIsLoading: false,
      list: action.payload.list.sort((a, b) => a.orderNo - b.orderNo),
      form,
    };
  },
  [ActionType.CHANGE_SORT]: function (state, action) {
    const item = state.list.find((it) => it.id == action.payload.id);
    const list = state.list.filter((it) => it.id != action.payload.id);
    list.splice(action.payload.index, 0, item);
    list.forEach((it, index) => (it.orderNo = index + 1));
    return {
      ...state,
      list,
    };
  },
  [ActionType.CHANGE_FORM]: function (state, action) {
    return {
      ...state,
      form: state.form.setByName(action.payload.name, action.payload.value),
    };
  },
  [ActionType.REMOVE]: function (state, action) {
    return {
      ...state,
      list: state.list.filter((it) => it.id != action.payload.fileId),
    };
  },
  [ActionType.SUBMIT]: function (state) {
    return {
      ...state,
      listIsSubmitting: true,
    };
  },
  [ActionType.SUBMIT_FINISHED]: function (state) {
    return {
      ...state,
      listIsSubmitting: false,
    };
  },
  [ActionType.SUBMIT_FAILED]: function (state) {
    return {
      ...state,
      listIsSubmitting: false,
    };
  },
});

export default reducers.createReducer(initialState);
