import React from "react";
import PropTypes from "prop-types";
import { serviceShubetsuCategoryContentViewTypeOf } from "./ServiceShubetsuCategoryContentViewType";

class ServiceContentTypeDetail extends React.Component {
  render() {
    const { serviceShubetsuCategoryCode } = this.props.contents;
    const viewType = serviceShubetsuCategoryContentViewTypeOf(
      serviceShubetsuCategoryCode
    );
    const Component = viewType.detail;
    return (
      <Component
        contents={this.props.contents}
        serviceSubId={this.props.serviceSubId}
      />
    );
  }
}

ServiceContentTypeDetail.propTypes = {
  contents: PropTypes.object.isRequired,
  serviceSubId: PropTypes.string,
};

export default ServiceContentTypeDetail;
