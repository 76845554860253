import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
  withMasterData,
} from "../../../../../state/master-data";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import StandardSelect from "../../../../../app/ui/form/StandardSelect";
import OptionsSelectDialog from "../../../../../app/ui/form/OptionsSelectDialog";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { IconButton } from "../../../../../../node_modules/@material-ui/core";

class RecreationForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleAppealingRecreationAdded = this.handleAppealingRecreationAdded.bind(
      this
    );
    this.handleAppealingRecreationRemoved = this.handleAppealingRecreationRemoved.bind(
      this
    );
    this.handleGaibukoshiniyoruRecreationAdded = this.handleGaibukoshiniyoruRecreationAdded.bind(
      this
    );
    this.handleGaibukoshiniyoruRecreationRemoved = this.handleGaibukoshiniyoruRecreationRemoved.bind(
      this
    );
  }

  handleAppealingRecreationAdded(option) {
    const duplicated = this.getAppealingRecreationSetsumeiForms().find(
      (it) => it.shubetsuCode == option.code
    );
    if (duplicated && !this.isFreeTextRecreationShubetsu(option.code)) return;
    this.appendAppealingRecreationSetsumeiForms({
      shubetsuCode: option.code,
      sonota: "",
      hindoCode: "",
    });
  }

  handleGaibukoshiniyoruRecreationAdded(option) {
    const duplicated = this.getGaibukoshiniyoruRecreationSetsumeiForms().find(
      (it) => it.shubetsuCode == option.code
    );
    if (duplicated && !this.isFreeTextRecreationShubetsu(option.code)) return;
    this.appendGaibukoshiniyoruRecreationSetsumeiForms({
      shubetsuCode: option.code,
      sonota: "",
      hindoCode: "",
    });
  }

  handleAppealingRecreationRemoved(index) {
    this.removeAppealingRecreationSetsumeiForms(index);
  }

  handleGaibukoshiniyoruRecreationRemoved(index) {
    this.removeGaibukoshiniyoruRecreationSetsumeiForms(index);
  }

  getAppealingRecreationSetsumeiForms() {
    const { bindingProps } = this.props;
    return [].concat(
      bindingProps.form.getByName("appealingRecreationSetsumeiForms")
    );
  }

  getGaibukoshiniyoruRecreationSetsumeiForms() {
    const { bindingProps } = this.props;
    return [].concat(
      bindingProps.form.getByName("gaibukoshiniyoruRecreationSetsumeiForms")
    );
  }

  appendAppealingRecreationSetsumeiForms(form) {
    const { bindingProps } = this.props;
    const org = this.getAppealingRecreationSetsumeiForms();
    bindingProps.onChange(
      "appealingRecreationSetsumeiForms",
      org.concat([form])
    );
  }

  appendGaibukoshiniyoruRecreationSetsumeiForms(form) {
    const { bindingProps } = this.props;
    const org = this.getGaibukoshiniyoruRecreationSetsumeiForms();
    bindingProps.onChange(
      "gaibukoshiniyoruRecreationSetsumeiForms",
      org.concat([form])
    );
  }

  removeAppealingRecreationSetsumeiForms(index) {
    const { bindingProps } = this.props;
    const org = this.getAppealingRecreationSetsumeiForms();
    bindingProps.onChange(
      "appealingRecreationSetsumeiForms",
      org.filter((_, i) => i != index)
    );
  }

  removeGaibukoshiniyoruRecreationSetsumeiForms(index) {
    const { bindingProps } = this.props;
    const org = this.getGaibukoshiniyoruRecreationSetsumeiForms();
    bindingProps.onChange(
      "gaibukoshiniyoruRecreationSetsumeiForms",
      org.filter((_, i) => i != index)
    );
  }

  isFreeTextRecreationShubetsu(code) {
    const { masterData } = this.props;
    return masterData.recreationShubetsu.find((it) => it.code == code).freeText;
  }

  render() {
    return (
      <Fragment>
        {this.renderRecreation()}
        {this.renderApealing()}
        {this.renderGaibukoshi()}
      </Fragment>
    );
  }

  renderRecreation() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="レクリエーション・行事">
        <HorizontalLabelLayout labelText="特徴">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="レク頻度" recommended>
          <GridRow>
            <GridCell>
              <StaticLabel>種類</StaticLabel>
            </GridCell>
            <GridCell>
              <StaticLabel>頻度</StaticLabel>
            </GridCell>
            <GridCell grow={2}></GridCell>
          </GridRow>
          <GridRow>
            <GridCell>
              <StaticLabel>個別</StaticLabel>
            </GridCell>
            <GridCell>
              <StandardSelect
                name="kobetsuRecreationHindoCode"
                blank
                options={masterData.recreationHindo}
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}></GridCell>
          </GridRow>
          <GridRow>
            <GridCell>
              <StaticLabel>集団</StaticLabel>
            </GridCell>
            <GridCell>
              <StandardSelect
                name="shudanRecreationHindoCode"
                blank
                options={masterData.recreationHindo}
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}></GridCell>
          </GridRow>
          <GridRow>
            <GridCell>
              <StaticLabel>外出</StaticLabel>
            </GridCell>
            <GridCell>
              <StandardSelect
                name="gaishutsuRecreationHindoCode"
                blank
                options={masterData.recreationHindo}
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}></GridCell>
          </GridRow>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderApealing() {
    const { bindingProps, masterData } = this.props;
    const RecreationHindoType = {
      提供なし: { code: "007" },
    };
    return (
      <FormGrouping title="アピールしたいレク" recommended>
        <HorizontalLabelLayout labelText="">
          {this.getAppealingRecreationSetsumeiForms().length > 0 ? (
            <GridRow>
              <GridCell>
                <StaticLabel>
                  <SpaceOut block>種類</SpaceOut>
                </StaticLabel>
              </GridCell>
              <GridCell>
                <StaticLabel>
                  <SpaceOut block>頻度</SpaceOut>
                </StaticLabel>
              </GridCell>
              <GridCell grow={2}></GridCell>
            </GridRow>
          ) : null}
          {this.getAppealingRecreationSetsumeiForms().map(
            (recreation, index) => {
              const name = `appealingRecreationSetsumeiForms.${index}`;
              const label = masterData.recreationShubetsu.find(
                (it) => it.code == recreation.shubetsuCode
              );
              return (
                <GridRow key={`${index}:${label.code}`}>
                  <GridCell>
                    <SpaceOut block>
                      {label.freeText ? (
                        <InputTypeText
                          name={`${name}.sonota`}
                          {...bindingProps}
                        />
                      ) : (
                        <StaticLabel>{label.label}</StaticLabel>
                      )}
                    </SpaceOut>
                  </GridCell>
                  <GridCell>
                    <RatioBlock grow={3} divide={4}>
                      <StandardSelect
                        name={`${name}.hindoCode`}
                        blank
                        options={masterData.recreationHindo.filter(
                          (it) => it.code != RecreationHindoType.提供なし.code
                        )}
                        {...bindingProps}
                      />
                    </RatioBlock>
                    <RatioBlock grow={1} divide={4}>
                      <IconButton
                        onClick={() =>
                          this.handleAppealingRecreationRemoved(index)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </RatioBlock>
                  </GridCell>
                  <GridCell grow={2}></GridCell>
                </GridRow>
              );
            }
          )}
          <OptionsSelectDialog
            title="レクリエーションを追加する"
            onSelect={this.handleAppealingRecreationAdded}
            categorize
            options={masterData.recreationShubetsu}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderGaibukoshi() {
    const { bindingProps, masterData } = this.props;
    const RecreationHindoType = {
      提供なし: { code: "007" },
    };
    return (
      <FormGrouping title="外部講師によるレク" recommended>
        <HorizontalLabelLayout labelText="">
          {this.getGaibukoshiniyoruRecreationSetsumeiForms().length > 0 ? (
            <GridRow>
              <GridCell>
                <StaticLabel>
                  <SpaceOut block>種類</SpaceOut>
                </StaticLabel>
              </GridCell>
              <GridCell>
                <StaticLabel>
                  <SpaceOut block>頻度</SpaceOut>
                </StaticLabel>
              </GridCell>
              <GridCell grow={2}></GridCell>
            </GridRow>
          ) : null}
          {this.getGaibukoshiniyoruRecreationSetsumeiForms().map(
            (recreation, index) => {
              const name = `gaibukoshiniyoruRecreationSetsumeiForms.${index}`;
              const label = masterData.recreationShubetsu.find(
                (it) => it.code == recreation.shubetsuCode
              );
              return (
                <GridRow key={`${index}:${label.code}`}>
                  <GridCell>
                    <SpaceOut block>
                      {label.freeText ? (
                        <InputTypeText
                          name={`${name}.sonota`}
                          {...bindingProps}
                        />
                      ) : (
                        <StaticLabel>{label.label}</StaticLabel>
                      )}
                    </SpaceOut>
                  </GridCell>
                  <GridCell>
                    <RatioBlock grow={3} divide={4}>
                      <StandardSelect
                        name={`${name}.hindoCode`}
                        blank
                        options={masterData.recreationHindo.filter(
                          (it) => it.code != RecreationHindoType.提供なし.code
                        )}
                        {...bindingProps}
                      />
                    </RatioBlock>
                    <RatioBlock grow={1} divide={4}>
                      <IconButton
                        onClick={() =>
                          this.handleGaibukoshiniyoruRecreationRemoved(index)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </RatioBlock>
                  </GridCell>
                  <GridCell grow={2}></GridCell>
                </GridRow>
              );
            }
          )}
          <OptionsSelectDialog
            title="レクリエーションを追加する"
            onSelect={this.handleGaibukoshiniyoruRecreationAdded}
            categorize
            options={masterData.recreationShubetsu}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

RecreationForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(RecreationForm);
