const ColorCatalog = {
  // primitive
  error: "red",
  gray: "gray",
  lightGray: "LightGray",
  white: "white",
  // nuance
  info: "ivory",
  info_contrast: "black",
  primary: "DarkTurquoise",
  primary_contrast: "white",
  shadow: "LightGray",
  // input status
  input_status_green: "LightGreen",
  input_status_red: "LightCoral",
  input_status_yellow: "Gold",
};

export default ColorCatalog;
