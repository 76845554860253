import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ThWithBg from "../general/ThWithBg";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import ValueWithHeadcountUnit from "../../../../../app/ui/unit/ValueWithHeadcountUnit";

const ShokuinBlock = ({ shokuintaisei, serviceShubetsuType }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="職員体制" />
      <div style={{ marginBottom: "2pt" }}>
        <div>
          介護職員数：
          <ValueWithHeadcountUnit
            value={shokuintaisei.kaigoshokuingokeininzu}
          />
        </div>
      </div>
      <div>
        <div className="p-office-body-table-dotted">
          <Mount
            if={serviceShubetsuType === ServiceShubetsuType.短期入所生活介護}
            then={() => (
              <ShokuinBlock_seikatsukaigo shokuintaisei={shokuintaisei} />
            )}
          />
          <Mount
            if={
              serviceShubetsuType ===
                ServiceShubetsuType.短期入所療養介護医療 ||
              serviceShubetsuType === ServiceShubetsuType.短期入所療養介護老健
            }
            then={() => (
              <ShokuinBlock_ryoyokaigoiryo shokuintaisei={shokuintaisei} />
            )}
          />
        </div>
      </div>
    </Fragment>
  );
};

// 短期入所生活介護
const ShokuinBlock_seikatsukaigo = (shokuintaisei) => {
  const { shokuintaisei: shokuin } = shokuintaisei;
  return (
    <table>
      <thead>
        <tr>
          <ThWithBg text="看護・介護系資格" />
          <ThWithBg text="常勤" />
          <ThWithBg text="非常勤" />
          <ThWithBg text="リハビリ系資格" />
          <ThWithBg text="常勤" />
          <ThWithBg text="非常勤" />
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>看護師・准看護師</th>
          <td>{shokuin.kangoshijunkangoshininzu.jokin}</td>
          <td>{shokuin.kangoshijunkangoshininzu.hijokin}</td>
          <th>理学療法士</th>
          <td>{shokuin.rigakuryohoshininzu.jokin}</td>
          <td>{shokuin.rigakuryohoshininzu.hijokin}</td>
        </tr>
        <tr>
          <th>介護福祉士</th>
          <td>{shokuin.kaigofukushishininzu.jokin}</td>
          <td>{shokuin.kaigofukushishininzu.hijokin}</td>
          <th>作業療法士</th>
          <td>{shokuin.sagyoryohoshininzu.jokin}</td>
          <td>{shokuin.sagyoryohoshininzu.hijokin}</td>
        </tr>
        <tr>
          <th>社会福祉士</th>
          <td>{shokuin.shakaifukushishininzu.jokin}</td>
          <td>{shokuin.shakaifukushishininzu.hijokin}</td>
          <th>言語聴覚士</th>
          <td>{shokuin.gengochokakushininzu.jokin}</td>
          <td>{shokuin.gengochokakushininzu.hijokin}</td>
        </tr>
        <tr>
          <th>実務者研修修了者</th>
          <td>{shokuin.jitsumushakenshuninzu.jokin}</td>
          <td>{shokuin.jitsumushakenshuninzu.hijokin}</td>
          <th>柔道整復士</th>
          <td>{shokuin.judoseifukushininzu.jokin}</td>
          <td>{shokuin.judoseifukushininzu.hijokin}</td>
        </tr>
        <tr>
          <th>初任者研修修了者</th>
          <td>{shokuin.shoninshakenshuninzu.jokin}</td>
          <td>{shokuin.shoninshakenshuninzu.hijokin}</td>
          <th>あん摩マッサージ師</th>
          <td>{shokuin.ammaMassagistNinzu.jokin}</td>
          <td>{shokuin.ammaMassagistNinzu.hijokin}</td>
        </tr>
      </tbody>
    </table>
  );
};

// 短期入所療養介護医療(医療・老健)
const ShokuinBlock_ryoyokaigoiryo = (shokuintaisei) => {
  const { shokuintaisei: shokuin } = shokuintaisei;
  return (
    <table>
      <thead>
        <tr>
          <ThWithBg text="介護系資格" />
          <ThWithBg text="常勤" />
          <ThWithBg text="非常勤" />
          <ThWithBg text="看護・リハビリ系資格" />
          <ThWithBg text="常勤" />
          <ThWithBg text="非常勤" />
        </tr>
      </thead>
      <tbody>
        <tr>
          <th>介護福祉士</th>
          <td>{shokuin.kaigofukushishininzu.jokin}</td>
          <td>{shokuin.kaigofukushishininzu.hijokin}</td>
          <th>看護師・准看護師</th>
          <td>{shokuin.kangoshijunkangoshininzu.jokin}</td>
          <td>{shokuin.kangoshijunkangoshininzu.hijokin}</td>
        </tr>
        <tr>
          <th>社会福祉士</th>
          <td>{shokuin.shakaifukushishininzu.jokin}</td>
          <td>{shokuin.shakaifukushishininzu.hijokin}</td>
          <th>理学療法士</th>
          <td>{shokuin.rigakuryohoshininzu.jokin}</td>
          <td>{shokuin.rigakuryohoshininzu.hijokin}</td>
        </tr>
        <tr>
          <th>実務者研修修了者</th>
          <td>{shokuin.jitsumushakenshuninzu.jokin}</td>
          <td>{shokuin.jitsumushakenshuninzu.hijokin}</td>
          <th>作業療法士</th>
          <td>{shokuin.sagyoryohoshininzu.jokin}</td>
          <td>{shokuin.sagyoryohoshininzu.hijokin}</td>
        </tr>
        <tr>
          <th>初任者研修修了者</th>
          <td>{shokuin.shoninshakenshuninzu.jokin}</td>
          <td>{shokuin.shoninshakenshuninzu.hijokin}</td>
          <th>言語聴覚士</th>
          <td>{shokuin.gengochokakushininzu.jokin}</td>
          <td>{shokuin.gengochokakushininzu.hijokin}</td>
        </tr>
      </tbody>
    </table>
  );
};

ShokuinBlock.propTypes = {
  shokuintaisei: PropTypes.object.isRequired,
  serviceShubetsuType: PropTypes.object.isRequired,
};

export default ShokuinBlock;
