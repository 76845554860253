import React from "react";
import PropTypes from "prop-types";
import Chip from "@material-ui/core/Chip";

const JobOfferStatusChip = ({ status }) => {
  const style = styles[status] || {};

  return <Chip label={status} style={style} />;
};

const styles = {
  下書き: {},
  申請中: {
    background: "#ffd700",
  },
  差し戻し: {
    background: "#e10050",
    color: "#ffffff",
  },
  掲載待ち: {
    background: "#00bfff",
    color: "#ffffff",
  },
  掲載中: {
    background: "#2196f3",
    color: "#ffffff",
  },
  掲載終了: {},
  "公開終了（掲載取り下げ）": {},
};

JobOfferStatusChip.propTypes = {
  status: PropTypes.string.isRequired,
};

export default JobOfferStatusChip;
