import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const HaisetsuTable = ({ contents }) => {
  const {
    blocks: { shortstayHaisetsu: haisetsu },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>トイレ設置数</th>
              <td>{haisetsu.toilet}</td>
            </tr>
            <tr>
              <th>車椅子対応</th>
              <td>{haisetsu.kurumaisutaio}</td>
            </tr>
            <tr>
              <th>同性介助</th>
              <td>{haisetsu.doseikaijo}</td>
            </tr>
            <tr>
              <th>その他</th>
              <td>{haisetsu.sonota.join("、")}</td>
            </tr>
            <tr>
              <th>備考</th>
              <td>{haisetsu.biko}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="p-office-body-characteristic">特 徴</div>
      <div className="p-office-body-description p-office-body-description--p">
        {haisetsu.tokucho}
      </div>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.shortstayHaisetsu
      )}
    </Fragment>
  );
};

HaisetsuTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default HaisetsuTable;
