import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Thumbnail from "../../../search/Thumbnail";

class PhotosBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 0,
    };
  }

  handlePhotoClick(index) {
    this.setState({
      selected: index,
    });
  }

  render() {
    const photos = this.props.photos;
    const selectedPhotoSrc =
      photos.length > this.state.selected
        ? photos[this.state.selected].imageUrl
        : null;
    const selectedPhotoDescription =
      photos.length > this.state.selected
        ? photos[this.state.selected].description
        : null;

    return (
      <Fragment>
        <div className="p-office-body-pic">
          <Thumbnail
            src={selectedPhotoSrc}
            description={selectedPhotoDescription}
          />
        </div>

        <div className="p-office-body-thumb">
          <ul>
            {this.props.photos.slice(0, 10).map((photo, i) => {
              return (
                <li
                  key={i}
                  className={this.state.selected === i ? "is-active" : ""}
                  onClick={() => this.handlePhotoClick(i)}
                >
                  <div>
                    <img src={photo.imageUrl} />
                  </div>
                </li>
              );
            })}
          </ul>
        </div>
      </Fragment>
    );
  }
}

PhotosBlock.propTypes = {
  photos: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default PhotosBlock;
