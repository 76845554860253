import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import AccountEditComponent from "../../account/edit/AccountEditComponent";

class JigyoshoKanriAccountEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  initializeFields(props) {
    const { params } = props.match;
    this.id = +params.id;
  }

  handleBackButtonClick() {
    const { actions, jigyoshoList } = this.props;
    actions.gotoListPage(jigyoshoList.search);
  }

  handleSaveButtonClick() {
    this.handleBackButtonClick();
  }

  render() {
    const pageProps = {
      id: this.id,
      onGoBack: this.handleBackButtonClick,
      onGoAhead: this.handleSaveButtonClick,
    };
    return <AccountEditComponent {...pageProps} />;
  }
}

JigyoshoKanriAccountEditPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  jigyoshoList: PropTypes.object.isRequired,
  accountForm: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JigyoshoKanriAccountEditPage);

function mapStateToProps(state) {
  return {
    jigyoshoList: state.jigyoshoList,
    accountForm: state.accountForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gotoListPage: (search = "") =>
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho,
            search,
          })
        ),
    },
  };
}
