import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import { searchConverter } from "../query-string";
import { formDataOf } from "../../../app/fundamental/data/FormData";

const initialState = {
  condition: formDataOf(searchConverter().toCondition()),
  list: [],
  listIsLoading: false,
  pagination: {
    page: 1,
    size: 10,
    totalPages: 1,
    totalElements: 0,
  },
  search: undefined,
  prevSearch: undefined,
  nextSearch: undefined,
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.RESET]: function () {
    return initialState;
  },
  [ActionType.CHANGE_CONDITION]: function (state, action) {
    return {
      ...state,
      condition: state.condition.setByName(
        action.payload.name,
        action.payload.value
      ),
    };
  },
  [ActionType.REQUEST]: function (state, action) {
    return {
      ...state,
      listIsLoading: true,
      search: action.payload.search,
    };
  },
  [ActionType.REQUEST_FINISHED]: function (state, action) {
    return {
      ...state,
      condition: formDataOf(
        searchConverter(action.payload.search).toCondition()
      ),
      list: action.payload.list,
      listIsLoading: false,
      pagination: action.payload.pagination,
      search: action.payload.search,
      prevSearch: action.payload.prevSearch,
      nextSearch: action.payload.nextSearch,
    };
  },
});

export default reducers.createReducer(initialState);
