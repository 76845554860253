import React from "react";
import PropTypes from "prop-types";

const AgeBarChart = (props) => {
  const isNullHiritsu =
    props.ageninzu.nenrei20DaiWariai == null &&
    props.ageninzu.nenrei30DaiWariai == null &&
    props.ageninzu.nenrei40DaiWariai == null &&
    props.ageninzu.nenrei50DaiWariai == null &&
    props.ageninzu.nenrei60DaiWariai == null;
  const hidden_nenrei20DaiWariai =
    !isNullHiritsu && props.ageninzu.nenrei20DaiWariai < 10;
  const hidden_nenrei30DaiWariai =
    !isNullHiritsu && props.ageninzu.nenrei30DaiWariai < 10;
  const hidden_nenrei40DaiWariai =
    !isNullHiritsu && props.ageninzu.nenrei40DaiWariai < 10;
  const hidden_nenrei50DaiWariai =
    !isNullHiritsu && props.ageninzu.nenrei50DaiWariai < 10;
  const hidden_nenrei60DaiWariai =
    !isNullHiritsu && props.ageninzu.nenrei60DaiWariai < 15;
  return (
    <div className="danjo-bar-chart">
      <div style={styles(props).heading_text}>年齢比</div>
      <div style={styles(props).container}>
        <div style={styles(props, isNullHiritsu).nenrei20DaiWariai}>
          <img
            style={styles(props).img}
            src="/images/print_bg_highlight_orange.png"
          />
          <div hidden={hidden_nenrei20DaiWariai} style={styles(props).text}>
            20代
            <br />
            {isNullHiritsu
              ? "ー"
              : valRound(props.ageninzu.nenrei20DaiWariai) + "%"}
          </div>
        </div>
        <div style={styles(props, isNullHiritsu).nenrei30DaiWariai}>
          <img
            style={styles(props).img}
            src="/images/print_bg_light_orange.png"
          />
          <div hidden={hidden_nenrei30DaiWariai} style={styles(props).text}>
            30代
            <br />
            {isNullHiritsu
              ? "ー"
              : valRound(props.ageninzu.nenrei30DaiWariai) + "%"}
          </div>
        </div>
        <div style={styles(props, isNullHiritsu).nenrei40DaiWariai}>
          <img style={styles(props).img} src="/images/print_bg_orange.png" />
          <div hidden={hidden_nenrei40DaiWariai} style={styles(props).text}>
            40代
            <br />
            {isNullHiritsu
              ? "ー"
              : valRound(props.ageninzu.nenrei40DaiWariai) + "%"}
          </div>
        </div>
        <div style={styles(props, isNullHiritsu).nenrei50DaiWariai}>
          <img
            style={styles(props).img}
            src="/images/print_bg_dark_orange.png"
          />
          <div hidden={hidden_nenrei50DaiWariai} style={styles(props).text}>
            50代
            <br />
            {isNullHiritsu
              ? "ー"
              : valRound(props.ageninzu.nenrei50DaiWariai) + "%"}
          </div>
        </div>
        <div style={styles(props, isNullHiritsu).nenrei60DaiWariai}>
          <img
            style={styles(props).img}
            src="/images/print_bg_orange_red.png"
          />
          <div hidden={hidden_nenrei60DaiWariai} style={styles(props).text}>
            60代以上
            <br />
            {isNullHiritsu
              ? "ー"
              : valRound(props.ageninzu.nenrei60DaiWariai) + "%"}
          </div>
        </div>
      </div>
      <div style={styles(props).hidden_div}>
        <span
          hidden={!hidden_nenrei20DaiWariai}
          style={styles(props).hidden_text}
        >
          <img
            style={styles(props).hidden_img}
            src="/images/print_bg_highlight_orange.png"
          />
          20代{" "}
          {isNullHiritsu ? "ー" : valRound(props.ageninzu.nenrei20DaiWariai)}%
        </span>
        <span
          hidden={!hidden_nenrei30DaiWariai}
          style={styles(props).hidden_text}
        >
          <img
            style={styles(props).hidden_img}
            src="/images/print_bg_light_orange.png"
          />
          30代{" "}
          {isNullHiritsu ? "ー" : valRound(props.ageninzu.nenrei30DaiWariai)}%
        </span>
        <span
          hidden={!hidden_nenrei40DaiWariai}
          style={styles(props).hidden_text}
        >
          <img
            style={styles(props).hidden_img}
            src="/images/print_bg_orange.png"
          />
          40代{" "}
          {isNullHiritsu ? "ー" : valRound(props.ageninzu.nenrei40DaiWariai)}%
        </span>
        <span
          hidden={!hidden_nenrei50DaiWariai}
          style={styles(props).hidden_text}
        >
          <img
            style={styles(props).hidden_img}
            src="/images/print_bg_dark_orange.png"
          />
          50代{" "}
          {isNullHiritsu ? "ー" : valRound(props.ageninzu.nenrei50DaiWariai)}%
        </span>
        <span
          hidden={!hidden_nenrei60DaiWariai}
          style={styles(props).hidden_text}
        >
          <img
            style={styles(props).hidden_img}
            src="/images/print_bg_orange_red.png"
          />
          60代以上{" "}
          {isNullHiritsu ? "ー" : valRound(props.ageninzu.nenrei60DaiWariai)}%
        </span>
      </div>
    </div>
  );
};

function valRound(value) {
  return Math.round(Math.round(value * 10) / 10);
}

const styles = (props, isNullHiritsu) => ({
  heading_text: {
    fontWeight: "bold",
    marginBottom: "5pt",
    paddingLeft: "5pt",
  },
  container: {
    fontSize: "10px",
    width: "100%",
    height: "37px",
    display: "flex",
    flexDirection: "row",
    textAlign: "center",
    marginBottom: "5pt",
  },
  nenrei20DaiWariai: {
    background: "#F0E68C",
    width: `${isNullHiritsu ? 25 : props.ageninzu.nenrei20DaiWariai}%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  nenrei30DaiWariai: {
    width: `${isNullHiritsu ? 25 : props.ageninzu.nenrei30DaiWariai}%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  nenrei40DaiWariai: {
    width: `${isNullHiritsu ? 25 : props.ageninzu.nenrei40DaiWariai}%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  nenrei50DaiWariai: {
    width: `${isNullHiritsu ? 25 : props.ageninzu.nenrei50DaiWariai}%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  nenrei60DaiWariai: {
    width: `${isNullHiritsu ? 25 : props.ageninzu.nenrei60DaiWariai}%`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
  },
  text: {
    position: "absolute",
    top: "3pt",
    left: "0",
    width: "100%",
    textAlign: "center",
  },
  hidden_div: {
    marginLeft: "5pt",
    marginBottom: "5pt",
  },
  hidden_text: {
    marginRight: "10pt",
  },
  img: {
    width: "100%",
    height: "40px",
  },
  hidden_img: {
    width: "7pt",
    height: "7pt",
    marginRight: "3pt",
  },
});

AgeBarChart.propTypes = {
  ageninzu: PropTypes.object.isRequired,
};

export default AgeBarChart;
