import React, { Fragment } from "react";
import PropTypes from "prop-types";

const RyokinRehabilitation = ({ contents }) => {
  const { homonkeiServiceRyokin: ryokin } = contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-title">介護報酬加算</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>共通</th>
              <td>{ryokin.kaigohoshukasanKyotsu.join("、")}</td>
            </tr>
            <tr>
              <th>要介護のみ</th>
              <td>{ryokin.kaigohoshukasanYokaigo.join("、")}</td>
            </tr>
            <tr>
              <th>予防のみ</th>
              <td>{ryokin.kaigohoshukasanYobo.join("、")}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="p-office-body-title">利用料金の目安</div>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">
              訪問リハビリテーション
            </div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">
                    1回（20分）
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonRehabilitation}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

RyokinRehabilitation.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default RyokinRehabilitation;
