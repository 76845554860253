import { StyleSheet } from "aphrodite";
import ColorCatalog from "../color/ColorCatalog";

const styles = StyleSheet.create({
  dropzone: {
    borderColor: ColorCatalog.lightGray,
    borderStyle: "dashed",
    borderWidth: "2px",
    boxSizing: "border-box",
    color: ColorCatalog.lightGray,
    cursor: "pointer",
    padding: "80px 10px",
    textAlign: "center",
    transition: "color 200ms 0s ease, border-color 200ms 0s ease",
    width: "100%",

    ":hover": {
      borderColor: ColorCatalog.gray,
      color: ColorCatalog.gray,
    },
  },
});

export default styles;
