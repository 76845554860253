class StatusCode {
  constructor(code) {
    this.code = Number(code);
  }

  isOK() {
    return this.code === 200;
  }

  isBadRequest() {
    return this.code === 400;
  }

  isUnauthorized() {
    return this.code === 401;
  }

  isNotFound() {
    return this.code === 404;
  }

  isServiceUnavailable() {
    return this.code === 503;
  }

  isClientError() {
    return 400 <= this.code && this.code < 500;
  }

  isServerError() {
    return 500 <= this.code && this.code < 600;
  }
}

export default StatusCode;
