import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

const StaffTable = ({ contents, detail }) => {
  const {
    serviceShubetsuCode,
    blocks: { shortstayStaff: staff },
  } = contents;

  return (
    <Fragment>
      {/* ↓↓ 概要 */}
      <Mount if={!detail}>
        <div className="p-office-body-sep">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-table2">
              <div className="p-office-body-table2__th">職員数</div>
              <div className="p-office-body-table2__td">
                <ul>
                  <li>
                    <div className="p-office-body-table2__td-name">医師</div>
                    <div className="p-office-body-table2__td-val">
                      {staff.summary.ishi}
                    </div>
                  </li>
                  <Mount
                    if={[ServiceShubetsuType.短期入所生活介護]
                      .map((it) => it.code)
                      .includes(serviceShubetsuCode)}
                  >
                    <li>
                      <div className="p-office-body-table2__td-name">
                        生活相談員
                      </div>
                      <div className="p-office-body-table2__td-val">
                        {staff.summary.seikatsusodanin}
                      </div>
                    </li>
                  </Mount>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      看護職員
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {staff.summary.kangoshokuin}
                    </div>
                  </li>
                  <Mount
                    if={[
                      ServiceShubetsuType.短期入所療養介護医療,
                      ServiceShubetsuType.短期入所療養介護老健,
                    ]
                      .map((it) => it.code)
                      .includes(serviceShubetsuCode)}
                  >
                    <li>
                      <div className="p-office-body-table2__td-name">
                        薬剤師
                      </div>
                      <div className="p-office-body-table2__td-val">
                        {staff.summary.yakuzaishi}
                      </div>
                    </li>
                  </Mount>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      介護職員
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {staff.summary.kaigoshokuin}
                    </div>
                  </li>
                  <Mount
                    if={[
                      ServiceShubetsuType.短期入所療養介護医療,
                      ServiceShubetsuType.短期入所療養介護老健,
                    ]
                      .map((it) => it.code)
                      .includes(serviceShubetsuCode)}
                  >
                    <li>
                      <div className="p-office-body-table2__td-name">
                        リハビリ職員
                      </div>
                      <div className="p-office-body-table2__td-val">
                        {staff.summary.rehabilitation}
                      </div>
                    </li>
                  </Mount>
                  <Mount
                    if={[ServiceShubetsuType.短期入所生活介護]
                      .map((it) => it.code)
                      .includes(serviceShubetsuCode)}
                  >
                    <li>
                      <div className="p-office-body-table2__td-name">
                        機能訓練指導員
                      </div>
                      <div className="p-office-body-table2__td-val">
                        {staff.summary.kinokunrenshidoin}
                      </div>
                    </li>
                  </Mount>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      その他職員
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {staff.summary.sonotaShokuinGokei}
                    </div>
                  </li>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      職員1人あたりご利用者数
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {staff.kangoKaigoshokuinWariai}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="p-office-body-sep__right">
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>管理者保有資格</th>
                    <td>{staff.kanrishahoyushikaku}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="p-office-body-table2">
              <div className="p-office-body-table2__th">有資格者数</div>
              <div className="p-office-body-table2__td">
                <ul>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      理学療法士
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {staff.summary.rigakuryohoshi}
                    </div>
                  </li>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      作業療法士
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {staff.summary.sagyoryohoshi}
                    </div>
                  </li>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      言語聴覚士
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {staff.summary.gengochokakushi}
                    </div>
                  </li>
                  <Mount
                    if={[ServiceShubetsuType.短期入所生活介護]
                      .map((it) => it.code)
                      .includes(serviceShubetsuCode)}
                  >
                    <li>
                      <div className="p-office-body-table2__td-name">
                        柔道整復師
                      </div>
                      <div className="p-office-body-table2__td-val">
                        {staff.summary.judoseifukushi}
                      </div>
                    </li>
                    <li>
                      <div className="p-office-body-table2__td-name">
                        あん摩マッサージ指圧師
                      </div>
                      <div className="p-office-body-table2__td-val">
                        {staff.summary.ammaMassageShiatsushi}
                      </div>
                    </li>
                  </Mount>
                </ul>
              </div>
            </div>

            {goToJigyoshoAttributeFormButton(
              contents,
              JigyoshoAttributeType.shortstayStaff
            )}
          </div>
        </div>
      </Mount>
      {/* ↑↑ 概要 */}

      {/* ↓↓ 詳細 */}
      <Mount if={detail}>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>男女別従業員数</th>
                    <td className="val">
                      <ul>
                        <li>
                          <div className="val-name">男性</div>
                          <div className="val-val">{staff.danseiNinzu}</div>
                        </li>
                        <li>
                          <div className="val-name">女性</div>
                          <div className="val-val">{staff.joseiNinzu}</div>
                        </li>
                        <li>
                          <div className="val-name">合計</div>
                          <div className="val-val">{staff.gokeiNinzu}</div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="p-office-body-sep__right">
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>年齢別従業員数</th>
                    <td className="val">
                      <ul>
                        <li>
                          <div className="val-name">20代</div>
                          <div className="val-val">
                            {staff.nenreibetsu20DaiNinzu}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">30代</div>
                          <div className="val-val">
                            {staff.nenreibetsu30DaiNinzu}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">40代</div>
                          <div className="val-val">
                            {staff.nenreibetsu40DaiNinzu}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">50代</div>
                          <div className="val-val">
                            {staff.nenreibetsu50DaiNinzu}
                          </div>
                        </li>
                        <li>
                          <div className="val-name">60代以上</div>
                          <div className="val-val">
                            {staff.nenreibetsu60DaiNinzu}
                          </div>
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div className="p-office-body-flex-wrap">
          <Mount
            if={[ServiceShubetsuType.短期入所生活介護]
              .map((it) => it.code)
              .includes(serviceShubetsuCode)}
          >
            <div className="p-office-body-sep__left">
              <div className="p-office-body-title">生活相談員</div>
              <StaffTableShokuin shokushu={staff.seikatsusodanin} />
            </div>
          </Mount>
          <div className="p-office-body-sep__left">
            <div className="p-office-body-title">医師</div>
            <StaffTableShokuin shokushu={staff.ishi} />
          </div>
          <div className="p-office-body-sep__left">
            <div className="p-office-body-title">看護職員</div>
            <StaffTableShokuin shokushu={staff.kangoshokuin} />
          </div>
          <Mount
            if={[
              ServiceShubetsuType.短期入所療養介護医療,
              ServiceShubetsuType.短期入所療養介護老健,
            ]
              .map((it) => it.code)
              .includes(serviceShubetsuCode)}
          >
            <div className="p-office-body-sep__left">
              <div className="p-office-body-title">薬剤師</div>
              <StaffTableShokuin shokushu={staff.yakuzaishi} />
            </div>
          </Mount>
          <div className="p-office-body-sep__left">
            <div className="p-office-body-title">介護職員</div>
            <StaffTableShokuin shokushu={staff.kaigoshokuin} />
          </div>
          <Mount
            if={[
              ServiceShubetsuType.短期入所療養介護医療,
              ServiceShubetsuType.短期入所療養介護老健,
            ]
              .map((it) => it.code)
              .includes(serviceShubetsuCode)}
          >
            <div className="p-office-body-sep__left">
              <div className="p-office-body-title">リハビリ職員</div>
              <StaffTableShokuin shokushu={staff.rehabilitation} />
            </div>
          </Mount>
          <Mount
            if={[ServiceShubetsuType.短期入所生活介護]
              .map((it) => it.code)
              .includes(serviceShubetsuCode)}
          >
            <div className="p-office-body-sep__left">
              <div className="p-office-body-title">機能訓練指導員</div>
              <StaffTableShokuin shokushu={staff.kinokunrenshidoin} />
            </div>
          </Mount>
        </div>
        <div className="p-office-body-title">資格保有者数</div>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__center">
            <div className="p-office-body-table">
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>医師</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin jokinHijokin={staff.ishiJokinHijokin} />
                  </span>
                </div>
                <div className="thead border-left">
                  <span>看護師及び准看護師</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin
                      jokinHijokin={staff.kangoshiOyobiJunkangoshi}
                    />
                    {staff.kangoshiOyobiJunkangoshiShikakushurui}
                  </span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>歯科衛生士</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin jokinHijokin={staff.shikaeiseishi} />
                  </span>
                </div>
                <div className="thead border-left">
                  <span>理学療法士</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin jokinHijokin={staff.rigakuryohoshi} />
                  </span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>管理栄養士</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin jokinHijokin={staff.kanrieiyoshi} />
                  </span>
                </div>
                <div className="thead border-left">
                  <span>栄養士</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin jokinHijokin={staff.eiyoshi} />
                  </span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>介護福祉士</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin jokinHijokin={staff.kaigofukushishi} />
                  </span>
                </div>
                <div className="thead border-left">
                  <span>作業療法士</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin jokinHijokin={staff.sagyoryohoshi} />
                  </span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>社会福祉士</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin jokinHijokin={staff.shakaifukushishi} />
                  </span>
                </div>
                <div className="thead border-left">
                  <span>言語聴覚士</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin jokinHijokin={staff.gengochokakushi} />
                  </span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>介護職員実務者研修</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin
                      jokinHijokin={staff.kaigoshokuinjitsumushakenshu}
                    />
                  </span>
                </div>
                <div className="thead border-left">
                  <span>介護プロフェッショナルキャリア段位</span>
                </div>
                <div className="tdata">
                  <span>{staff.kaigoProfessionalCarrierDani}</span>
                </div>
              </div>
              <div className="p-office-body-table-row">
                <div className="thead">
                  <span>介護職員初任者研修</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffJokinHijokin
                      jokinHijokin={staff.kaigoshokuinshoninshakenshu}
                    />
                  </span>
                </div>
                <div className="thead border-left">
                  <span>認定療法士</span>
                </div>
                <div className="tdata">
                  <span>
                    <StaffNinzuShikaku
                      ninzu={staff.ninteiryohoshi}
                      shikaku={staff.ninteiryohoshiShikakushurui}
                    />
                  </span>
                </div>
              </div>
              <Mount
                if={[
                  ServiceShubetsuType.短期入所療養介護医療,
                  ServiceShubetsuType.短期入所療養介護老健,
                ]
                  .map((it) => it.code)
                  .includes(serviceShubetsuCode)}
              >
                <div className="p-office-body-table-row">
                  <div className="thead">
                    <span>薬剤師</span>
                  </div>
                  <div className="tdata">
                    <span>
                      <StaffJokinHijokin
                        jokinHijokin={staff.yakuzaishiJokinHijokin}
                      />
                    </span>
                  </div>
                  <div className="thead border-left">
                    <span></span>
                  </div>
                </div>
              </Mount>
              <Mount
                if={[ServiceShubetsuType.短期入所生活介護]
                  .map((it) => it.code)
                  .includes(serviceShubetsuCode)}
              >
                <div className="p-office-body-table-row">
                  <div className="thead">
                    <span>あん摩マッサージ指圧師</span>
                  </div>
                  <div className="tdata">
                    <span>
                      <StaffJokinHijokin
                        jokinHijokin={staff.ammaMassageShiatsushi}
                      />
                    </span>
                  </div>
                  <div className="thead border-left">
                    <span>柔道整復士</span>
                  </div>
                  <div className="tdata">
                    <span>
                      <StaffJokinHijokin jokinHijokin={staff.judoseifukushi} />
                    </span>
                  </div>
                </div>
                <div className="p-office-body-table-row">
                  <div className="thead">
                    <span>マッサージ資格保有者</span>
                  </div>
                  <div className="tdata">
                    <span>{staff.massage}</span>
                  </div>
                  <div className="thead border-left">
                    <span></span>
                  </div>
                </div>
              </Mount>
            </div>
          </div>
        </div>
        <div className="p-office-body-sep">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>管理者保有資格</th>
                    <td>{staff.kanrishahoyushikaku}</td>
                  </tr>
                  <tr>
                    <th>
                      <span>
                        経験年数5年以上の
                        <br />
                        従業員の割合
                      </span>
                    </th>
                    <td>{staff.keikenNensu5NenijoWariai}</td>
                  </tr>
                  <tr>
                    <th>
                      <span>
                        看護・介護職員１人
                        <br />
                        あたりのご利用者数
                      </span>
                    </th>
                    <td>{staff.kangoKaigoshokuinWariai}</td>
                  </tr>
                  <tr>
                    <th>その他</th>
                    <td>{staff.sonota.join("、")}</td>
                  </tr>
                  <tr>
                    <th>備考</th>
                    <td>{staff.biko}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="p-office-body-sep__right">
            <div className="p-office-body-table2">
              <div className="p-office-body-table2__th">認知症介護</div>
              <div className="p-office-body-table2__td">
                <ul>
                  <li>
                    <div>基礎研修</div>
                    <div>{staff.ninchishokaigoKisokenshu}</div>
                  </li>
                  <li>
                    <div>実践者研修</div>
                    <div>{staff.ninchishokaigoJissenshakenshu}</div>
                  </li>
                  <li>
                    <div>実践リーダー研修</div>
                    <div>{staff.ninchishokaigoJissenLeaderKenshu}</div>
                  </li>
                  <li>
                    <div>指導者養成研修</div>
                    <div>{staff.ninchishokaigoShidoshayoseikenshu}</div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="p-office-body-characteristic">特 徴</div>
            <div className="p-office-body-description p-office-body-description--p">
              {staff.tokucho}
            </div>

            {goToJigyoshoAttributeFormButton(
              contents,
              JigyoshoAttributeType.shortstayStaff
            )}
          </div>
        </div>
      </Mount>
      {/* ↑↑ 詳細 */}
    </Fragment>
  );
};

const StaffTableShokuin = (shokushu) => {
  const {
    shokushu: {
      jokinHijokin,
      jokinkansan,
      keiken1miman,
      keiken1ijo3miman,
      keiken3ijo5miman,
      keiken5ijo10miman,
      keiken10ijo,
    },
  } = shokushu;
  return (
    <div className="p-office-body-table">
      <table>
        <tbody>
          <tr>
            <th>常勤・非常勤別</th>
            <td>
              <StaffJokinHijokin jokinHijokin={jokinHijokin} />
            </td>
          </tr>
          <tr>
            <th>常勤換算</th>
            <td>{jokinkansan}</td>
          </tr>
          <tr>
            <th>経験年数別従業員数</th>
            <td className="val">
              <ul>
                <li>
                  <div className="val-name">1年未満</div>
                  <div className="val-val">{keiken1miman}</div>
                </li>
                <li>
                  <div className="val-name">～3年未満</div>
                  <div className="val-val">{keiken1ijo3miman}</div>
                </li>
                <li>
                  <div className="val-name">～5年未満</div>
                  <div className="val-val">{keiken3ijo5miman}</div>
                </li>
                <li>
                  <div className="val-name">～10年未満</div>
                  <div className="val-val">{keiken5ijo10miman}</div>
                </li>
                <li>
                  <div className="val-name">～10年以上</div>
                  <div className="val-val">{keiken10ijo}</div>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const StaffJokinHijokin = (jokinHijokin) => {
  const { jokinHijokin: object } = jokinHijokin;
  return [
    object.jokin ? "常勤" + object.jokin : null,
    object.hijokin ? "非常勤" + object.hijokin : null,
    object.gokei ? "合計" + object.gokei : null,
  ]
    .filter((item) => {
      return item !== null;
    })
    .join("、");
};

const StaffNinzuShikaku = (ninzuShikaku) => {
  if (!ninzuShikaku.ninzu) {
    return ninzuShikaku.shikaku;
  }
  if (!ninzuShikaku.shikaku) {
    return ninzuShikaku.ninzu;
  }
  return `${ninzuShikaku.ninzu}（${ninzuShikaku.shikaku}）`;
};

StaffTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default StaffTable;
