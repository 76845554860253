import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputTypeTextWithClearButton from "../../../../app/ui/form/InputTypeTextWithClearButton";
import { enterKeyHandler } from "../../../../app/ui/form";
import * as actions from "../../../../state/open-data-list/actions";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import { conditionConverter } from "../../../../state/open-data-list/query-string";
import GridRow from "../../../../app/ui/grid/GridRow";
import GridCell from "../../../../app/ui/grid/GridCell";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import StandardSelect from "../../../../app/ui/form/StandardSelect";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_JIGYOSHO,
} from "../../../../state/master-data";
import { hokenTekiyoTypeValueOf } from "../../../../app/domain/jigyosho/HokenTekiyoType";
import { includeKyotakuKaigoShien } from "../../../../app/domain/jigyosho/ServiceShubetsuType";
import { packInHokengaiService } from "../add/JigyoshoAddPage";

class OpenDataJoinCondition extends React.Component {
  constructor(props) {
    super(props);
    this.handleSearchButtonClick = this.handleSearchButtonClick.bind(this);
    this.handleEnterKeyDown = this.handleEnterKeyDown.bind(this);
    this.handleKeywordClearButtonClick = this.handleKeywordClearButtonClick.bind(
      this
    );
    this.handleShozaichiClearButtonClick = this.handleShozaichiClearButtonClick.bind(
      this
    );
  }

  handleSearchButtonClick() {
    return this.handleEnterKeyDown();
  }

  handleEnterKeyDown() {
    const { actions, condition } = this.props;
    actions.reSearch(condition);
  }

  handleKeywordClearButtonClick() {
    const { actions } = this.props;
    actions.changeCondition("keyword", "", () => {
      actions.reSearch(this.props.condition);
    });
  }

  handleShozaichiClearButtonClick() {
    const { actions } = this.props;
    actions.changeCondition("shozaichi", "", () => {
      actions.reSearch(this.props.condition);
    });
  }

  createBindingProps() {
    const { actions, condition } = this.props;
    return {
      form: condition,
      onChange: actions.changeCondition,
    };
  }

  createServiceShubetsuSelectBindingProps(bindingProps) {
    const { actions } = this.props;
    return {
      ...bindingProps,
      blank: true,
      categorize: true,
      options: this.selectableServiceShubetsu(),
      onChange: (name, value) => {
        const callback = () => actions.reSearch(this.props.condition);
        bindingProps.onChange(name, value, callback);
      },
    };
  }

  selectableServiceShubetsu() {
    const { masterData } = this.props;
    return masterData.serviceShubetsu
      .map(packInHokengaiService)
      .filter((it) => includeOpenDataService(it));
  }

  render() {
    const bindingProps = this.createBindingProps();
    const serviceShubetsuSelectBindingProps = this.createServiceShubetsuSelectBindingProps(
      bindingProps
    );
    return (
      <div>
        <GridRow>
          <GridCell>
            <SpaceOut block>
              <InputTypeTextWithClearButton
                labelText="所在地"
                name="shozaichi"
                onKeyDown={enterKeyHandler(this.handleEnterKeyDown)}
                onClearButtonClick={this.handleShozaichiClearButtonClick}
                {...bindingProps}
              />
            </SpaceOut>
          </GridCell>
          <GridCell>
            <SpaceOut block>
              <StandardSelect
                labelText="サービス種別"
                name="serviceShubetsuCode"
                {...serviceShubetsuSelectBindingProps}
              />
            </SpaceOut>
          </GridCell>
          <GridCell>
            <SpaceOut block>
              <InputTypeTextWithClearButton
                labelText="事業所名"
                name="keyword"
                onKeyDown={enterKeyHandler(this.handleEnterKeyDown)}
                onClearButtonClick={this.handleKeywordClearButtonClick}
                {...bindingProps}
              />
            </SpaceOut>
          </GridCell>
          <GridCell className="search-area">
            <RaisedPrimaryButton
              disabled={this.props.listIsLoading}
              fullWidth
              onClick={this.handleSearchButtonClick}
            >
              オープンデータを探す
            </RaisedPrimaryButton>
          </GridCell>
        </GridRow>
      </div>
    );
  }
}

OpenDataJoinCondition.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  condition: PropTypes.object.isRequired,
  listIsLoading: PropTypes.bool.isRequired,
  masterData: PropTypes.object.isRequired,
};

export function includeOpenDataService(option) {
  if (
    includeKyotakuKaigoShien(option.code) ||
    (!option.shienCenter &&
      typeof option.category.hokenTekiyoType !== "undefined" &&
      hokenTekiyoTypeValueOf(option.category.hokenTekiyoType).isHokennai())
  )
    return true;
  return false;
}

export default withMasterData(MASTER_DATA_BUNDLE_FOR_JIGYOSHO)(
  connect(mapStateToProps, mapDispatchToProps)(OpenDataJoinCondition)
);

function mapStateToProps(state) {
  return {
    masterData: state.masterData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      changeCondition: (name, value, callback) =>
        dispatch(actions.changeCondition(name, value, callback)),
      reSearch: (condition) => {
        const search = conditionConverter(condition).toSearch();
        dispatch(actions.requestBySearch(search));
      },
    },
  };
}
