import React from "react";
import PropTypes from "prop-types";
import InputTypeText from "./InputTypeText";
import { InputAdornment, IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";

const InputTypeTextWithClearButton = (props) => {
  return <InputTypeText {...createProps(props)} />;
};

function createProps(props) {
  const blank = "";
  return {
    ...props,
    InputProps: {
      endAdornment: props.form.getByName(props.name) ? (
        <InputAdornment position="end">
          <IconButton
            onClick={(e) => {
              props.onChange(props.name, blank);
              props.onClearButtonClick(e);
            }}
          >
            <ClearIcon />
          </IconButton>
        </InputAdornment>
      ) : null,
    },
  };
}

InputTypeTextWithClearButton.propTypes = {
  form: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onClearButtonClick: PropTypes.func.isRequired,
};

export default InputTypeTextWithClearButton;
