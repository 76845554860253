import React from "react";
import PropTypes from "prop-types";

const ExternalLinkedUrl = (props) => {
  if (!props.url) {
    return null;
  }

  const body = props.children || props.url;
  return (
    <a href={props.url} target="_blank" rel="noopener noreferrer">
      {body}
    </a>
  );
};

ExternalLinkedUrl.propTypes = {
  url: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default ExternalLinkedUrl;
