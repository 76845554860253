import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { isArray } from "../../../../app/fundamental/data";

function hasContents(children) {
  if (!children) return false;
  if (isArray(children) && children.length === 0) return false;
  return true;
}

const CardFrameItem = (props) => {
  return hasContents(props.children) && !props.isEmpty ? (
    <Fragment>
      <dt>{props.title}</dt>
      <dd>{props.children}</dd>
    </Fragment>
  ) : null;
};

CardFrameItem.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  isEmpty: PropTypes.bool,
};

export default CardFrameItem;
