import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import { connect } from "react-redux";
import * as actions from "../../../../state/job-offer-reject/actions";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import { Card } from "@material-ui/core";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import Right from "../../../../app/ui/layout/Right";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import RaisedSecondaryButton from "../../../../app/ui/button/RaisedSecondaryButton";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import Textarea from "../../../../app/ui/form/Textarea";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";

class JobOfferRejectPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleRejectButtonClick = this.handleRejectButtonClick.bind(this);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  initializeFields(props) {
    const { params } = props.match;
    this.id = params.id;
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.requestBlankForm(this.id);
  }

  handleBackButtonClick() {
    const { actions } = this.props;
    actions.gotoJobOfferDetailPage(this.id);
  }

  handleRejectButtonClick() {
    const { actions, rejectForm } = this.props;
    const callback = () => actions.gotoJobOfferDetailPage(this.id);
    actions.submitToReject(this.id, rejectForm.form, callback);
  }

  gotoJobOfferDetailPage() {
    const { actions } = this.props;
    actions.gotoJobOfferDetailPage(this.id);
  }

  createBindingProps() {
    const { actions, rejectForm } = this.props;
    const { form } = rejectForm;
    const { changeForm: onChange } = actions;
    return { form, onChange };
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          <BackOfficeHead>求人情報の詳細</BackOfficeHead>
          <BackOfficeNavigation>
            <FlatPrimaryButton onClick={this.handleBackButtonClick}>
              <BackIcon /> 戻る
            </FlatPrimaryButton>
          </BackOfficeNavigation>
          {this.renderForm()}
        </SideMargin>
      </Boundary>
    );
  }

  renderForm() {
    const { rejectForm } = this.props;
    const bindingProps = this.createBindingProps();
    if (rejectForm.formIsLoading) return <CenteredCircularProgress />;

    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title="求人情報の非承認">
            <HorizontalLabelLayout labelText="非承認の理由">
              <Textarea
                name="feedback"
                characterLimit={500}
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          </FormGrouping>
          <Right>
            <SpaceOut>
              <FlatSecondaryButton onClick={this.handleBackButtonClick}>
                キャンセル
              </FlatSecondaryButton>
            </SpaceOut>
            <SpaceOut>
              <RaisedSecondaryButton
                disabled={rejectForm.formIsSubmitting}
                onClick={this.handleRejectButtonClick}
              >
                非承認
              </RaisedSecondaryButton>
            </SpaceOut>
          </Right>
        </SideMargin>
      </Card>
    );
  }
}

JobOfferRejectPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  match: PropTypes.object.isRequired,
  rejectForm: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
  return {
    rejectForm: state.rejectForm,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoJobOfferDetailPage: (id) => {
        dispatch(push(Pathname.backoffice_job_offer_id.replace(/:id/, id)));
      },
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobOfferRejectPage);
