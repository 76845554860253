import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";

const MoshikomiBlock = ({ moshikomijoho }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="申し込み情報" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold">
              <th style={{ width: "70px" }}>申し込み期間</th>
              <td>{moshikomijoho.moshikomikikan}</td>
            </tr>
            <tr className="tbody-th_bold">
              <th style={{ width: "70px" }}>緊急時受入</th>
              <td>{moshikomijoho.kinkyujiukeire}</td>
            </tr>
            <tr className="tbody-th_bold">
              <th style={{ width: "70px" }}>必要書類</th>
              <td style={{ textAlign: "left" }}>
                {moshikomijoho.hitsuyoshorui
                  .filter((_, index) => index < 4)
                  .map((it, index) => (
                    <div key={it}>
                      ・{it}{" "}
                      {index === 3 && moshikomijoho.hitsuyoshorui.length > 4
                        ? "など"
                        : ""}
                    </div>
                  ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

MoshikomiBlock.propTypes = {
  moshikomijoho: PropTypes.object.isRequired,
};

export default MoshikomiBlock;
