import React from "react";
import PropTypes from "prop-types";

const FormGrouping = (props) => {
  return (
    <div style={styles.container}>
      {props.title ? (
        <div style={styles.title}>
          {props.required ? <span className="mark--required"></span> : null}
          {props.recommended ? (
            <span className="mark--recommended"></span>
          ) : null}
          {props.title}
        </div>
      ) : null}
      {props.children}
    </div>
  );
};

const styles = {
  container: {
    marginBottom: 35,
    position: "relative",
  },
  title: {
    fontSize: "medium",
    fontWeight: "bold",
    marginTop: 15,
  },
};

FormGrouping.propTypes = {
  title: PropTypes.node,
  children: PropTypes.node,
  required: PropTypes.bool,
  recommended: PropTypes.bool,
};

export default FormGrouping;
