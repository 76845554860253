function ErrorsData() {}

ErrorsData.prototype.getByName = function (name) {
  const data = this;
  if (data[name]) return data[name];
  return Object.keys(data)
    .filter((key) => regularNameOf(key) === name)
    .map((key) => data[key])[0];
};

const regularNameOf = (name) =>
  name
    .replace(/\[([a-zA-Z0-9]+)\]/g, (match, p1) => `.${p1}`)
    .replace(/\.__[a-zA-Z0-9]+/g, "");

export function errorsDataOf(target) {
  Object.setPrototypeOf(target, ErrorsData.prototype);
  return target;
}
