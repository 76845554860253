import React from "react";
import PropTypes from "prop-types";
import TruncateText from "../../../../app/ui/text/TruncateText";

const HokennaiMapPrintCard = ({ service }) => {
  const hokennaiService = service.blocks.hokennaiService;

  const serviceTeikyochiiki = ((blocks) => {
    const {
      tsushokeiService,
      homonkeiService,
      kyotakukaigoshienjigyosho,
      shortstay,
    } = blocks;
    if (tsushokeiService) return tsushokeiService.serviceTeikyochiiki;
    if (homonkeiService) return homonkeiService.serviceTeikyochiiki;
    if (kyotakukaigoshienjigyosho)
      return kyotakukaigoshienjigyosho.serviceTeikyoChiiki;
    if (shortstay) return shortstay.serviceTeikyochiiki;
    return "";
  })(service.blocks);

  return (
    <div className="map-print-items-item">
      <div className="map-print-items-item__header">
        <div className="map-print-items-item__number">{service.number}</div>
        <div className="map-print-items-item__jigyoshomei">
          事業所名 {service.jigyoshomei}
        </div>
      </div>
      <dl className="map-print-items-item__attributes">
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">住所</dt>
          <dd className="map-print-items-item__attributes-item-value">
            {service.jusho}
          </dd>
        </div>
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">TEL</dt>
          <dd className="map-print-items-item__attributes-item-value">
            {hokennaiService.tel}
          </dd>
        </div>
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">
            営業日
          </dt>
          <dd className="map-print-items-item__attributes-item-value">
            {hokennaiService.eigyobi.join("、")}
          </dd>
        </div>
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">
            サービス提供地域
          </dt>
          <dd className="map-print-items-item__attributes-item-value">
            <TruncateText maxLength={50}>{serviceTeikyochiiki}</TruncateText>
          </dd>
        </div>
        <div className="map-print-items-item__attributes-item">
          <dt className="map-print-items-item__attributes-item-label">
            キャッチフレーズ
          </dt>
          <dd className="map-print-items-item__attributes-item-value">
            {hokennaiService.catchPhrase}
          </dd>
        </div>
      </dl>
    </div>
  );
};

HokennaiMapPrintCard.propTypes = {
  service: PropTypes.object.isRequired,
};

export default HokennaiMapPrintCard;
