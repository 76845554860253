import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import { formDataOf } from "../../../app/fundamental/data/FormData";

const initialState = {
  list: [],
  listIsLoading: false,
  listIsSubmitting: false,
  form: undefined,
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.REQUEST]: function (state) {
    return {
      ...state,
      listIsLoading: true,
    };
  },
  [ActionType.REQUEST_FINISHED]: function (state, action) {
    const form = formDataOf({});
    const list = action.payload.list.itemForms.sort(
      (a, b) => a.orderNo - b.orderNo
    );
    form["itemForms"] = [].concat(list);
    return {
      ...state,
      listIsLoading: false,
      list,
      form,
    };
  },
  [ActionType.CHANGE_SORT]: function (state, action) {
    const item = state.list.find((it) => it.id == action.payload.id);
    const list = state.list.filter((it) => it.id != action.payload.id);
    list.splice(action.payload.index, 0, item);
    list.forEach((it, index) => (it.orderNo = index + 1));

    const formItem = state.form.itemForms.find(
      (it) => it.id == action.payload.id
    );
    const formList = state.form.itemForms.filter(
      (it) => it.id != action.payload.id
    );
    formList.splice(action.payload.index, 0, formItem);
    formList.forEach((it, index) => (it.orderNo = index + 1));
    return {
      ...state,
      list,
      form: state.form.setByName("itemForms", formList),
    };
  },
  [ActionType.CHANGE_FORM]: function (state, action) {
    return {
      ...state,
      form: state.form.setByName(action.payload.name, action.payload.value),
    };
  },
  [ActionType.UPLOAD]: function (state, action) {
    const file = action.payload.file.contents[0];
    const topForm = state.form.itemForms.filter((it) => it.categoryCode != "")[0];
    const topOrderNo = topForm ? topForm.orderNo : 0;

    const list = (state.list.slice(0, topOrderNo))
    .concat([{
              id: file.id,
              description: "",
              categoryCode: "",
              orderNo: 0,
              originalFilename: file.originalFilename,
              contentType: file.contentType,
              filePath: file.filePath,
              createdAt: "",
            }])
    .concat(state.list.slice(topOrderNo));
    list.forEach((it, index) => (it.orderNo = index + 1));

    const formList = (state.form.itemForms.slice(0, topOrderNo))
    .concat([{
              id: file.id,
              categoryCode: "",
              description: "",
              orderNo: 0,
              originalFilename: file.originalFilename,
            }])
    .concat(state.form.itemForms.slice(topOrderNo));
    formList.forEach((it, index) => (it.orderNo = index + 1));
    return {
      ...state,
      list,
      form: state.form.setByName("itemForms", formList),
    };
  },
  [ActionType.REMOVE]: function (state, action) {
    const list = state.list.filter((it) => it.id != action.payload.fileId);
    list.forEach((it, index) => (it.orderNo = index + 1));
    const formList = state.form.itemForms.filter(
      (it) => it.id != action.payload.fileId
    );
    formList.forEach((it, index) => (it.orderNo = index + 1));
    return {
      ...state,
      list,
      form: state.form.setByName("itemForms", formList),
    };
  },
  [ActionType.SUBMIT]: function (state) {
    return {
      ...state,
      listIsSubmitting: true,
    };
  },
  [ActionType.SUBMIT_FINISHED]: function (state) {
    return {
      ...state,
      listIsSubmitting: false,
    };
  },
  [ActionType.SUBMIT_FAILED]: function (state) {
    return {
      ...state,
      listIsSubmitting: false,
    };
  },
});

export default reducers.createReducer(initialState);
