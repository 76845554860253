import React from "react";

class ScrollableAreaOnModalDialog extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  componentDidMount() {
    document
      .getElementById("app")
      .addEventListener("touchmove", preventDefault);
    this.ref.current.addEventListener("touchmove", stopPropagation);
    this.ref.current.scrollTop = 1;
  }

  componentWillUnmount() {
    document
      .getElementById("app")
      .removeEventListener("touchmove", preventDefault);
    this.ref.current.removeEventListener("touchmove", stopPropagation);
  }

  render() {
    const props = {
      ...this.props,
      onScroll: handleScroll,
    };
    return <div {...props} ref={this.ref} />;
  }
}

function handleScroll(e) {
  if (e.currentTarget.scrollTop === 0) {
    e.currentTarget.scrollTop = 1;
  } else if (
    e.currentTarget.scrollHeight ===
    e.currentTarget.scrollTop + e.currentTarget.offsetHeight
  ) {
    e.currentTarget.scrollTop -= 1;
  }
}

function preventDefault(e) {
  e.preventDefault();
}

function stopPropagation(e) {
  e.stopPropagation();
}

export default ScrollableAreaOnModalDialog;
