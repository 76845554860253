import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import {
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
  withMasterData,
  serviceShubetsuFilter,
} from "../../../../../state/master-data";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import { OPTIONS_AVAILABILITY } from "../../../../../app/ui/form";
import CategorizedOptions from "../../../../../app/util/CategorizedMasterData";
import NotCategorizedOptions from "../../../../../app/util/NotCategorizedMasterData";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import OptionsSelectDialog from "../../../../../app/ui/form/OptionsSelectDialog";
import RyokinRange from "../../../../../app/ui/form/RyokinRange";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { IconButton } from "../../../../../../node_modules/@material-ui/core";
import Textarea from "../../../../../app/ui/form/Textarea";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

class RyokinkasanjohoForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleRyokinAdded = this.handleRyokinAdded.bind(this);
    this.handleRyokinRemoved = this.handleRyokinRemoved.bind(this);
  }

  handleRyokinAdded(option) {
    this.appendTsudoHasseisuruRyokin({
      labelCode: option.code,
      jiyunyuryoku: "",
      minPrice: "0",
      maxPrice: "0",
      biko: "",
    });
  }

  handleRyokinRemoved(index) {
    this.removeTsudoHasseisuruRyokin(index);
  }

  getTsudoHasseisuruRyokins() {
    const { bindingProps } = this.props;
    return [].concat(bindingProps.form.getByName("tsudoHasseisuruRyokins"));
  }

  appendTsudoHasseisuruRyokin(form) {
    const { bindingProps } = this.props;
    const org = this.getTsudoHasseisuruRyokins();
    bindingProps.onChange("tsudoHasseisuruRyokins", org.concat([form]));
  }

  removeTsudoHasseisuruRyokin(index) {
    const { bindingProps } = this.props;
    const org = this.getTsudoHasseisuruRyokins();
    bindingProps.onChange(
      "tsudoHasseisuruRyokins",
      org.filter((_, i) => i != index)
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderKaigohoshukasan()}
        {this.renderRyokinkasanjoho()}
      </Fragment>
    );
  }

  renderKaigohoshukasan() {
    const {
      bindingProps,
      masterData,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode,
    } = this.props;
    const kasanCategories = new CategorizedOptions(
      masterData.kaigohoshukasan.filter(
        serviceShubetsuFilter(serviceShubetsuCategoryCode, serviceShubetsuCode)
      )
    ).getCategories();
    const kasanNotCategories = new NotCategorizedOptions(
      masterData.kaigohoshukasan.filter(
        serviceShubetsuFilter(serviceShubetsuCategoryCode, serviceShubetsuCode)
      )
    ).getCategories();
    return (
      <FormGrouping title="介護報酬加算" recommended>
        {[ServiceShubetsuType.療養通所介護]
          .map((it) => it.code)
          .includes(serviceShubetsuCode)
          ? kasanNotCategories.map((category) => (
              <HorizontalLabelLayout
                key={`category:${category.code}`}
                labelText={null}
              >
                <OptionsCheckbox
                  name="ryokinKasanJohosKaigohoshukasan"
                  split={2}
                  options={category.options}
                  {...bindingProps}
                />
              </HorizontalLabelLayout>
            ))
          : kasanCategories.map((category) => (
              <HorizontalLabelLayout
                key={`category:${category.code}`}
                labelText={category.label}
              >
                <OptionsCheckbox
                  name="ryokinKasanJohosKaigohoshukasan"
                  split={2}
                  options={category.options}
                  {...bindingProps}
                />
              </HorizontalLabelLayout>
            ))}
      </FormGrouping>
    );
  }

  renderRyokinkasanjoho() {
    const { bindingProps, masterData } = this.props;
    const TsudoHasseisuruRyokinLabelType = {
      キャンセル料: { code: "000" },
    };
    return (
      <Fragment>
        <FormGrouping title="利用料金の目安">
          <HorizontalLabelLayout labelText="自己負担額">
            <div>
              <RatioBlock divide={5}>
                <SpaceOut block>
                  <StaticLabel>要介護1</StaticLabel>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="youkaigo1jikohutangaku"
                      hintText="0"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </SpaceOut>
              </RatioBlock>
              <RatioBlock divide={5}>
                <SpaceOut block>
                  <StaticLabel>要介護2</StaticLabel>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="youkaigo2jikohutangaku"
                      hintText="0"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </SpaceOut>
              </RatioBlock>
              <RatioBlock divide={5}>
                <SpaceOut block>
                  <StaticLabel>要介護3</StaticLabel>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="youkaigo3jikohutangaku"
                      hintText="0"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </SpaceOut>
              </RatioBlock>
              <RatioBlock divide={5}>
                <SpaceOut block>
                  <StaticLabel>要介護4</StaticLabel>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="youkaigo4jikohutangaku"
                      hintText="0"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </SpaceOut>
              </RatioBlock>
              <RatioBlock divide={5}>
                <SpaceOut block>
                  <StaticLabel>要介護5</StaticLabel>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="youkaigo5jikohutangaku"
                      hintText="0"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </SpaceOut>
              </RatioBlock>
              <StaticLabel>
                ※ 自己負担額が1割の場合の目安料金をご記入ください
                <br />
                ※ 基本料金＋体制加算の料金をご記入ください
                <br />
              </StaticLabel>
            </div>
          </HorizontalLabelLayout>
        </FormGrouping>
        <FormGrouping title="都度発生する料金" recommended>
          <HorizontalLabelLayout labelText="">
            {this.getTsudoHasseisuruRyokins().length > 0 ? (
              <GridRow>
                <GridCell>
                  <StaticLabel>項目</StaticLabel>
                </GridCell>
                <GridCell grow={2}>
                  <StaticLabel>料金の範囲</StaticLabel>
                </GridCell>
                <GridCell>
                  <StaticLabel>備考</StaticLabel>
                </GridCell>
              </GridRow>
            ) : null}
            {this.getTsudoHasseisuruRyokins().map((ryokin, index) => {
              const name = `tsudoHasseisuruRyokins.${index}`;
              const label = masterData.tsudoHasseisuruRyokinLabel.find(
                (it) => it.code == ryokin.labelCode
              );
              return (
                <div key={label.code}>
                  <RatioBlock divide={4}>
                    <SpaceOut block>
                      {label.freeText ? (
                        <InputTypeText
                          name={`${name}.jiyunyuryoku`}
                          {...bindingProps}
                        />
                      ) : (
                        <StaticLabel>{label.label}</StaticLabel>
                      )}
                    </SpaceOut>
                  </RatioBlock>
                  <RatioBlock grow={2} divide={4}>
                    <RyokinRange name={name} separator="." {...bindingProps} />
                  </RatioBlock>
                  <RatioBlock divide={4}>
                    <RatioBlock grow={3} divide={4}>
                      <InputTypeText name={`${name}.biko`} {...bindingProps} />
                    </RatioBlock>
                    <RatioBlock grow={1} divide={4}>
                      <IconButton
                        onClick={() => this.handleRyokinRemoved(index)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </RatioBlock>
                  </RatioBlock>
                </div>
              );
            })}
            <OptionsSelectDialog
              title="追加する料金項目"
              onSelect={this.handleRyokinAdded}
              options={masterData.tsudoHasseisuruRyokinLabel.filter(
                (it) =>
                  it.code != TsudoHasseisuruRyokinLabelType.キャンセル料.code ||
                  this.getTsudoHasseisuruRyokins().filter(
                    (it) =>
                      it.labelCode ===
                      TsudoHasseisuruRyokinLabelType.キャンセル料.code
                  ).length === 0
              )}
            />
          </HorizontalLabelLayout>
        </FormGrouping>
        <FormGrouping title="その他">
          <HorizontalLabelLayout labelText="利用者負担軽減制度" required>
            <OptionsRadio
              name="riyoshaFutanKeigenSeido"
              split={6}
              options={OPTIONS_AVAILABILITY}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="支払い方法">
            <OptionsCheckbox
              name="ryokinKasanJohosShiharaiHoho"
              options={masterData.shiharaiHoho}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="備考">
            <Textarea name="biko" {...bindingProps} />
          </HorizontalLabelLayout>
        </FormGrouping>
      </Fragment>
    );
  }
}

RyokinkasanjohoForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(RyokinkasanjohoForm);
