import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const GaibuRenkeiTable = ({ contents }) => {
  const {
    blocks: { homonkeiServiceGaibuRenkei: gaibuRenkei },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>医療機関数</th>
              <td>{gaibuRenkei.iryokikansu}</td>
            </tr>
            <tr>
              <th>医療機関名</th>
              <td>{gaibuRenkei.iryokikanmei}</td>
            </tr>
            <tr>
              <th>指示書を受けている医師の人数</th>
              <td>{gaibuRenkei.shijishoIshi}</td>
            </tr>
          </tbody>
        </table>
      </div>
      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.homonkeiServiceGaibuRenkei
      )}
    </Fragment>
  );
};

GaibuRenkeiTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default GaibuRenkeiTable;
