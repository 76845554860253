import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { remove } from "../../../../state/comment/editForm";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import RaisedSecondaryButton from "../../../../app/ui/button/RaisedSecondaryButton";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";

class CommentRemoveButton extends React.Component {
  constructor(props) {
    super(props);
    this.handleRemoveButtonClick = this.handleRemoveButtonClick.bind(this);
    this.handleConfirmButtonClick = this.handleConfirmButtonClick.bind(this);
    this.handleCancelButtonClick = this.handleCancelButtonClick.bind(this);
  }

  handleRemoveButtonClick() {
    this.setState({ isConfirming: true, isDeleteButton: false });
  }

  handleConfirmButtonClick() {
    const { id, onClick, onSuccess } = this.props;
    this.setState({ isDeleteButton: true });
    const callback = () => {
      onSuccess(id);
    };
    onClick(id, callback.bind(this));
  }

  handleCancelButtonClick() {
    this.setState({ isConfirming: false });
  }

  state = {
    isConfirming: false,
    isDeleteButton: false,
  };

  render() {
    const { isConfirming } = this.state;
    return isConfirming
      ? this.renderRemoveConfirmation()
      : this.renderRemoveButton();
  }

  renderRemoveButton() {
    return (
      <FlatSecondaryButton onClick={this.handleRemoveButtonClick}>
        削除する
      </FlatSecondaryButton>
    );
  }

  renderRemoveConfirmation() {
    return (
      <span>
        <span className="confirmation-to-remove">
          削除します。よろしいですか？
        </span>
        <SpaceOut>
          <FlatPrimaryButton onClick={this.handleCancelButtonClick}>
            キャンセル
          </FlatPrimaryButton>
        </SpaceOut>
        <SpaceOut>
          <RaisedSecondaryButton
            onClick={this.handleConfirmButtonClick}
            disabled={this.state.isDeleteButton}
          >
            削除します
          </RaisedSecondaryButton>
        </SpaceOut>
      </span>
    );
  }
}

CommentRemoveButton.propTypes = {
  id: PropTypes.number.isRequired,
  // on-event handler
  onClick: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(CommentRemoveButton);

function mapDispatchToProps(dispatch) {
  return {
    onClick: (id, callback) => dispatch(remove(id, callback)),
  };
}
