import React, { Fragment } from "react";
import PropTypes from "prop-types";
import StaticLabel from "../../../../app/ui/form/StaticLabel";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import FormGrouping from "../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../app/ui/form/HorizontalLabelLayout";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import { connect } from "react-redux";
import { Card } from "@material-ui/core";
import Right from "../../../../app/ui/layout/Right";
import Parametername from "../../../../app/naming/Parametername";

class JobOfferJigyoshoBlock extends React.Component {
  constructor(props) {
    super(props);

    this.handleJobOfferJigyoshoEditButtonClick = this.handleJobOfferJigyoshoEditButtonClick.bind(
      this
    );
  }

  handleJobOfferJigyoshoEditButtonClick(jigyoshoId) {
    this.props.gotoJobOfferJigyoshoEditPage(jigyoshoId);
  }

  render() {
    const { detail } = this.props.jobOffer;

    const title = (
      <Fragment>
        事業所共通求人情報
        <Right>
          <FlatPrimaryButton
            onClick={() =>
              this.handleJobOfferJigyoshoEditButtonClick(detail.jigyoshoId)
            }
          >
            編集する
          </FlatPrimaryButton>
        </Right>
      </Fragment>
    );

    return (
      <Card>
        <SideMargin top bottom>
          <FormGrouping title="事業所情報">
            <HorizontalLabelLayout labelText="事業所名" required>
              <StaticLabel>{detail.jigyosho.jigyoshomei}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="サービス種別" required>
              <StaticLabel>{detail.jigyosho.serviceShubetsu}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="所在地" required>
              <StaticLabel>{detail.jigyosho.jusho}</StaticLabel>
            </HorizontalLabelLayout>
          </FormGrouping>

          <FormGrouping title={title}>
            <HorizontalLabelLayout labelText="キャッチフレーズ" required>
              <StaticLabel preLabel>{detail.jigyosho.catchPhrase}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="アイキャッチ画像" required>
              <img
                src={detail.jigyosho.uploadFileUrl}
                style={{ maxWidth: "90%" }}
              />
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="最寄り駅" required>
              <StaticLabel>{detail.jigyosho.station}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="担当者氏名" required>
              <StaticLabel>{detail.jigyosho.recruiterName}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="担当者メールアドレス" required>
              <StaticLabel>{detail.jigyosho.recruiterEmail}</StaticLabel>
            </HorizontalLabelLayout>
            <HorizontalLabelLayout labelText="担当者電話番号" required>
              <StaticLabel>{detail.jigyosho.recruiterTel}</StaticLabel>
            </HorizontalLabelLayout>
          </FormGrouping>
        </SideMargin>
      </Card>
    );
  }
}

JobOfferJigyoshoBlock.propTypes = {
  jobOffer: PropTypes.object.isRequired,
  gotoJobOfferJigyoshoEditPage: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    jobOffer: state.jobOffer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    gotoJobOfferJigyoshoEditPage: (jigyoshoId) => {
      const pathname = window.location.href.substr(
        window.location.protocol.length +
          "//".length +
          window.location.host.length
      );
      const searchParams = new URLSearchParams();
      searchParams.set(Parametername.pathname, pathname);
      dispatch(
        push({
          pathname: Pathname.backoffice_job_offer_jigyosho_id.replace(
            /:jigyoshoId/,
            jigyoshoId
          ),
          search: searchParams.toString(),
        })
      );
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobOfferJigyoshoBlock);
