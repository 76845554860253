import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";

const MimamorinaiyoBlock = ({ mimamorinaiyo }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="見守り内容" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>内容</th>
              <td>
                {mimamorinaiyo.teikyoServiceJokyoList &&
                  mimamorinaiyo.teikyoServiceJokyoList.map((it, index) => (
                    <div key={index}>・{it}</div>
                  ))}
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height40 tbody-td_textAlign_left">
              <th>詳細</th>
              <td>{mimamorinaiyo.teikyoServiceDetail}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

MimamorinaiyoBlock.propTypes = {
  mimamorinaiyo: PropTypes.object.isRequired,
};

export default MimamorinaiyoBlock;
