import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import Mount from "../../../../../app/ui/layout/Mount";

const CommonUneiJohoBlock = ({ uneiJoho, serviceShubetsuCode }) => {
  return (
    <Fragment>
      <div className="print-body__block-footer" style={{ width: "auto" }}>
        <div className="uneijoho">運営情報</div>
        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>運営法人</span> :{" "}
            {uneiJoho.uneihojin}
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>HPアドレス</span> :{" "}
            {uneiJoho.homepageAddress}
          </li>
          <Mount
            if={[ServiceShubetsuType.活動_通いの場]
              .map((it) => it.code)
              .includes(serviceShubetsuCode)}
          >
            <li>
              <span style={{ fontWeight: "bold" }}>団体種別</span> :{" "}
              {uneiJoho.dantaishubetsu}
            </li>
          </Mount>
        </ul>
      </div>
    </Fragment>
  );
};

CommonUneiJohoBlock.propTypes = {
  uneiJoho: PropTypes.object.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default CommonUneiJohoBlock;
