import React from "react";
import PropTypes from "prop-types";
import PrintTable from "../../../../app/ui/print/PrintTable";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const HaishokuServiceTable = (props) => {
  const { service } = props;
  const { hokengaiService, haishokuService } = service.blocks;

  return (
    <PrintTable {...props}>
      <PrintTableNode width="105px">
        {hokengaiService.jigyoshomei}
        <div className="c-table-frame__updated-at">
          ({service.userUpdatedAt})
        </div>
      </PrintTableNode>
      <PrintTableNode width="150px">{service.jusho}</PrintTableNode>
      <PrintTableNode width="112px">
        {hokengaiService.denwabango}
      </PrintTableNode>
      <PrintTableNode width="75px" center>
        {hokengaiService.teikyubi.join("・")}
      </PrintTableNode>
      <PrintTableNode width="120px" center>
        {hokengaiService.eigyojikan}
      </PrintTableNode>
      <PrintTableNode width="140px" center>
        {serviceTeikyochiiki(hokengaiService)}
      </PrintTableNode>
      <PrintTableNode minWidth="30px" center>
        {haishokuService.haishokuDekiruShokuji.filter((it) => it === "朝食")
          .length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode minWidth="30px" center>
        {haishokuService.haishokuDekiruShokuji.filter((it) => it === "昼食")
          .length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode minWidth="30px" center>
        {haishokuService.haishokuDekiruShokuji.filter((it) => it === "夕食")
          .length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode width="220px">
        {haishokuService.taioShitekureruService.map((it) => (
          <div key={it}>・{it}</div>
        ))}
      </PrintTableNode>
    </PrintTable>
  );
};

function serviceTeikyochiiki(hokengaiService) {
  const LABEL_LIMIT = 59;
  let serviceTeikyochiiki = hokengaiService.serviceTeikyochiiki
    .split("\n")
    .join(" ");
  serviceTeikyochiiki =
    serviceTeikyochiiki && serviceTeikyochiiki.length > LABEL_LIMIT
      ? serviceTeikyochiiki.substr(0, LABEL_LIMIT) + "..."
      : serviceTeikyochiiki;

  return serviceTeikyochiiki;
}

HaishokuServiceTable.propTypes = {
  onServiceButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default HaishokuServiceTable;
