import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Right from "../../../../app/ui/layout/Right";
import PrevNextPaginator from "../../../../app/ui/paginator/PrevNextPaginator";

class HojinListPaginator extends React.Component {
  constructor(props) {
    super(props);
    this.handlePaginatorButtonClick = this.handlePaginatorButtonClick.bind(
      this
    );
  }

  handlePaginatorButtonClick(search) {
    const { actions } = this.props;
    actions.requestBySearch(search);
  }

  render() {
    const { prevSearch, nextSearch, pagination } = this.props;
    return (
      <Right>
        <PrevNextPaginator
          onPrevButtonClick={() => this.handlePaginatorButtonClick(prevSearch)}
          onNextButtonClick={() => this.handlePaginatorButtonClick(nextSearch)}
          {...pagination}
        />
      </Right>
    );
  }
}

HojinListPaginator.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  prevSearch: PropTypes.string,
  nextSearch: PropTypes.string,
  pagination: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(HojinListPaginator);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      requestBySearch: (search) => dispatch(push({ search })),
    },
  };
}
