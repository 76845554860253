import { deepCopy } from ".";

function FormData() {}

FormData.prototype.getByName = function (name) {
  const data = this;
  return name.split(".").reduce((data, prop) => data[prop], data);
};

FormData.prototype.setByName = function (name, value) {
  const data = deepCopy(this);
  ((a) => {
    a.pop();
    return a;
  })(name.split(".")).reduce((data, prop) => data[prop], data)[
    ((a) => a.pop())(name.split("."))
  ] = value;
  return formDataOf(data);
};

FormData.prototype.setType = function (type) {
  this.__type = type;
  return this;
};

FormData.prototype.typeIs = function (type) {
  if (!this.__type) return false;
  return this.__type === type;
};

FormData.prototype.isNotEnough = function (names) {
  if (typeof names === "string") names = [names];
  if (!names) names = Object.keys(this);
  return (
    names
      .map((name) => this[name])
      .map((value) => (typeof value === "string" ? value.trim() : value))
      .filter((value) => value).length < names.length
  );
};

export function formDataOf(target) {
  Object.setPrototypeOf(target, FormData.prototype);
  return target;
}
