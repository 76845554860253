import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router";
import { Redirect } from "react-router-dom";
import Parametername from "../naming/Parametername";
import Pathname from "../naming/Pathname";
import * as actions from "../../state/authentication/actions";

class LoginRedirect extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.resetLoginForm();
  }

  render() {
    const { search } = this.props.location;
    const searchParams = new URLSearchParams(search);
    const from = searchParams.get(Parametername.from);

    return <Redirect to={from ? from : this.defaultLoginRoute()} />;
  }

  defaultLoginRoute = () => {
    const { user } = this.props;

    if (user.hasBackOfficeMenu() && user.shouldRedirectToBackOffice()) {
      return Pathname.backoffice;
    }

    return Pathname.webfront;
  };
}

LoginRedirect.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  location: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(LoginRedirect)
);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
