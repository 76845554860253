import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";

const RyokinBlock = ({ ryokin }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="料金について" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>送料</th>
              <td>{ryokin.soryo}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>送料備考</th>
              <td>{ryokin.soryobiko}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>注文に関する条件</th>
              <td>{ryokin.chumonjoken}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

RyokinBlock.propTypes = {
  ryokin: PropTypes.object.isRequired,
};

export default RyokinBlock;
