import React from "react";
import PropTypes from "prop-types";
import { mergeStyleToProps } from "../../fundamental/react/props";

const RatioBlock = (props) => {
  return <div {...mergeStyleToProps(styles(props).block, props)}></div>;
};

const styles = (props) => {
  const { divide, grow = 1 } = props;
  return {
    block: {
      display: "inline-block",
      width: `${(grow * 100) / divide}%`,
    },
  };
};

RatioBlock.propTypes = {
  divide: PropTypes.number.isRequired,
  grow: PropTypes.number,
};

export default RatioBlock;
