import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";

const initialState = {
  detail: null,
  isLoading: false,
  isDeleting: false,
  isApprovalRequesting: false,
  isApproved: false,
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.RESET]: function () {
    return initialState;
  },
  [ActionType.REQUEST]: function (state) {
    return {
      ...state,
      isLoading: true,
    };
  },
  [ActionType.REQUEST_FINISHED]: function (state, action) {
    return {
      ...state,
      isLoading: false,
      detail: action.payload,
    };
  },
  [ActionType.REMOVE_REQUEST]: function (state) {
    return {
      ...state,
      isDeleting: true,
    };
  },
  [ActionType.REMOVE_REQUEST_FINISHED]: function () {
    return initialState;
  },
  [ActionType.REMOVE_REQUEST_FAILED]: function (state) {
    return {
      ...state,
      isDeleting: false,
    };
  },
  [ActionType.APPROVAL_REQUEST_REQUEST]: function (state) {
    return {
      ...state,
      isApprovalRequesting: true,
      isLoading: true,
    };
  },
  [ActionType.APPROVAL_REQUEST_REQUEST_FINISHED]: function (state) {
    return {
      ...state,
      isApprovalRequesting: false,
      isLoading: false,
    };
  },
  [ActionType.APPROVAL_REQUEST_REQUEST_FAILED]: function (state) {
    return {
      ...state,
      isApprovalRequesting: false,
      isLoading: false,
    };
  },
  [ActionType.APPROVED_REQUEST]: function (state) {
    return {
      ...state,
      isApproved: true,
      isLoading: true,
    };
  },
  [ActionType.APPROVED_REQUEST_FINISHED]: function (state) {
    return {
      ...state,
      isApproved: false,
      isLoading: false,
    };
  },
  [ActionType.APPROVED_REQUEST_FAILED]: function (state) {
    return {
      ...state,
      isApproved: false,
      isLoading: false,
    };
  },
});

export default reducers.createReducer(initialState);
