import React from "react";
import PropTypes from "prop-types";
import GridRow from "../grid/GridRow";
import GridCell from "../grid/GridCell";

const ShortInputWidth = (props) => {
  return (
    <GridRow>
      <GridCell>{props.children}</GridCell>
      <GridCell grow={2}></GridCell>
    </GridRow>
  );
};

ShortInputWidth.propTypes = {
  children: PropTypes.node,
};

export default ShortInputWidth;
