const ActionType = {
  RESET: "INFORMATION_RESET",
  REQUEST_LIST: "INFORMATION_LIST_REQUEST",
  REQUEST_LIST_FINISHED: "INFORMATION_LIST_REQUEST_FINISHED",
  REQUEST_DETAILS: "INFORMATION_REQUEST_DETAILS",
  REQUEST_DETAILS_FINISHED: "INFORMATION_REQUEST_DETAILS_FINISHED",
  REQUEST_FORM: "INFORMATION_REQUEST_FORM",
  REQUEST_FORM_FINISHED: "INFORMATION_REQUEST_FORM_FINISHED",
  CHANGE_SORT: "INFORMATION_CHANGE_SORT",
  CHANGE_FORM: "INFORMATION_CHANGE_FORM",
  REMOVE: "INFORMATION_REMOVE",
  SUBMIT: "INFORMATION_SBMIT",
  SUBMIT_FINISHED: "INFORMATION_SBMIT_FINISHED",
  SUBMIT_FAILED: "INFORMATION_SBMIT_FAILED",
};

export default ActionType;
