const defaultCenter = { lat: 35.681167, lng: 139.767052 }; // 東京駅
const zoomIfOnlyOnePoint = 17;
export const MIN_ZOOM = 13;
export const DEFAULT_ZOOM = 14;

export function initialCenterAndZoom(services, center = null, zoom = null) {
  const latRange = latitudeRange(services);
  const lngRange = longitudeRange(services);

  if (center && zoom) {
    return { center, zoom };
  }

  if (!latRange || !lngRange) {
    return {
      center: defaultCenter,
      zoom: DEFAULT_ZOOM,
    };
  }

  if (latRange.min === latRange.max && lngRange.min === lngRange.max) {
    // 緯度経度情報が1つだけある場合、中心がその場所、ズームはデフォルト
    return {
      center: { lat: latRange.min, lng: lngRange.min },
      zoom: zoomIfOnlyOnePoint,
    };
  }
}

/**
 * 全ての位置が収まるようにフィットさせる。
 *
 * @param services
 * @param googleMap
 */
export function fitBounds(services, googleMap) {
  const latRange = latitudeRange(services);
  const lngRange = longitudeRange(services);

  googleMap.fitBounds({
    north: latRange.max,
    south: latRange.min,
    west: lngRange.min,
    east: lngRange.max,
  });
}

function latitudeRange(services) {
  const latitudes = services
    .map((item) => +item.latitude)
    .filter((item) => item);
  if (latitudes.length === 0) return null;
  return { min: Math.min(...latitudes), max: Math.max(...latitudes) };
}

function longitudeRange(services) {
  const longitudes = services
    .map((item) => +item.longitude)
    .filter((item) => item);
  if (longitudes.length === 0) return null;
  return { min: Math.min(...longitudes), max: Math.max(...longitudes) };
}
