import React from "react";
import PropTypes from "prop-types";
import {
  mergeStyleToProps,
  removePropertyFromProps,
} from "../../fundamental/react/props";

const Margin = (props) => {
  return <div {...mergeProps(props)}></div>;
};

function mergeProps(props) {
  if (props.bottom) props = mergeStyleToProps(styles.bottomMargin, props);
  if (props.left) props = mergeStyleToProps(styles.leftMargin, props);
  if (props.right) props = mergeStyleToProps(styles.rightMargin, props);
  if (props.top) props = mergeStyleToProps(styles.topMargin, props);
  props = removePropertyFromProps("bottom", props);
  props = removePropertyFromProps("left", props);
  props = removePropertyFromProps("right", props);
  props = removePropertyFromProps("top", props);
  return props;
}

const styles = {
  bottomMargin: {
    marginBottom: 15,
  },
  leftMargin: {
    marginLeft: 15,
  },
  rightMargin: {
    marginRight: 15,
  },
  topMargin: {
    marginTop: 15,
  },
};

Margin.propTypes = {
  bottom: PropTypes.bool,
  left: PropTypes.bool,
  right: PropTypes.bool,
  top: PropTypes.bool,
};

export default Margin;
