import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import { OPTIONS_AVAILABILITY } from "../../../../../app/ui/form";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
  withMasterData,
  serviceShubetsuFilter,
} from "../../../../../state/master-data";
import Mount from "../../../../../app/ui/layout/Mount";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

class RehabilitationForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderRihab()}
        {this.renderKobetsu()}
        {this.renderShudan()}
        {this.renderKankyo()}
        {this.renderBiko()}
        {this.renderKaigohoshu()}
      </Fragment>
    );
  }

  getLabel() {
    const { serviceShubetsuCode } = this.props;
    if (
      ServiceShubetsuType.通所リハビリテーション.code.includes(
        serviceShubetsuCode
      )
    ) {
      return "リハビリテーション";
    } else {
      return "機能訓練";
    }
  }

  renderRihab() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title={this.getLabel()}>
        <HorizontalLabelLayout labelText="特徴">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderKobetsu() {
    const { bindingProps, masterData } = this.props;
    const isActive = OptionsRadio.checked(
      bindingProps.form.getByName("kobetsutaio")
    );
    return (
      <FormGrouping title="個別機能訓練(個別対応)">
        <HorizontalLabelLayout labelText="個別対応" recommended>
          <OptionsRadio
            name="kobetsutaio"
            split={6}
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <Mount if={isActive}>
        <HorizontalLabelLayout labelText="担当制" recommended>
          <OptionsRadio
            name="kobetsutaioTantousei"
            split={6}
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="内容">
          <OptionsCheckbox
            name="rehabilitationKobetsuKinokunrennaiyo"
            options={masterData.kobetsuKinokunrennaiyo}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="時間(分)">
          <ShortInputWidth>
            <UnitLayout unitText="分">
              <InputTypeNumber name="kobetsutaioJikan" {...bindingProps} />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="療法">
          <OptionsCheckbox
            name="rehabilitationRiryoho"
            options={masterData.ryoho}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        </Mount>
      </FormGrouping>
    );
  }

  renderShudan() {
    const { bindingProps, masterData } = this.props;
    const isActive = OptionsRadio.checked(
      bindingProps.form.getByName("shudantaio")
    );
    return (
      <FormGrouping title="個別機能訓練(集団対応)">
        <HorizontalLabelLayout labelText="集団対応" recommended>
          <OptionsRadio
            name="shudantaio"
            split={6}
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <Mount if={isActive}>
        <HorizontalLabelLayout labelText="内容">
          <OptionsCheckbox
            name="rehabilitationShudanKinokunrennaiyo"
            options={masterData.shudanKinokunrennaiyo}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="人数">
          <ShortInputWidth>
            <UnitLayout unitText="人">
              <InputTypeNumber name="shudantaioNinzu" {...bindingProps} />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="時間(分)">
          <ShortInputWidth>
            <UnitLayout unitText="分">
              <InputTypeNumber name="shudantaioJikan" {...bindingProps} />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        </Mount>
      </FormGrouping>
    );
  }

  renderKankyo() {
    const {
      bindingProps,
      masterData,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode,
    } = this.props;
    return (
      <FormGrouping title="環境・設備">
        <HorizontalLabelLayout labelText="リハビリ機材" recommended>
          <OptionsCheckbox
            name="rehabilitationRehabilitationKizai"
            categorize
            options={masterData.rehabiliKizai}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="訓練場所">
          <OptionsCheckbox
            name="rehabilitationKunrembasho"
            options={masterData.kunrembasho.filter(
              serviceShubetsuFilter(
                serviceShubetsuCategoryCode,
                serviceShubetsuCode
              )
            )}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderBiko() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="備考">
        <HorizontalLabelLayout labelText="">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderKaigohoshu() {
    return (
      <FormGrouping title="介護報酬加算">
        <RatioBlock divide={18}></RatioBlock>
        <RatioBlock grow={17} divide={18}>
          <StaticLabel>
            ※介護報酬加算は、料金加算情報から入力してください。
            <br />
          </StaticLabel>
        </RatioBlock>
      </FormGrouping>
    );
  }
}

RehabilitationForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(RehabilitationForm);
