import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const TaiokanoShojoTable = ({ contents }) => {
  const {
    blocks: { homonkeiServiceTaiokanoShojo: taiokanoShojo },
  } = contents;

  const renderShojo = (shojo) => {
    return (
      <Fragment>
        <Mount if={shojo.taioka.length > 0}>
          <div className="p-office-body-table__doublecircle">
            {shojo.taioka.join("、")}
          </div>
        </Mount>
        <Mount if={shojo.ka.length > 0}>
          <div className="p-office-body-table__circle">
            {shojo.ka.join("、")}
          </div>
        </Mount>
        <Mount if={shojo.yousoudan.length > 0}>
          <div className="p-office-body-table__triangle">
            {shojo.yousoudan.join("、")}
          </div>
        </Mount>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>受入れ可能な症状</th>
              <td>{renderShojo(taiokanoShojo.shojo)}</td>
            </tr>
            <tr>
              <th>受入れ / 対応可能な 医療行為</th>
              <td>{renderShojo(taiokanoShojo.iryokoi)}</td>
            </tr>
            <tr>
              <th>受入れ可能な感染症</th>
              <td>{renderShojo(taiokanoShojo.kansensho)}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-note">
        <span className="p-office-body-legend__doublecircle">
          対応可能&nbsp;
        </span>
        <span className="p-office-body-legend__circle">受入れ可能&nbsp;</span>
        <span className="p-office-body-legend__triangle">要相談&nbsp;</span>
      </div>
      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.homonkeiServiceTaiokanoShojo
      )}
    </Fragment>
  );
};

TaiokanoShojoTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default TaiokanoShojoTable;
