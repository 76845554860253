import React from "react";
import PropTypes from "prop-types";
import {
  mergeStyleToProps,
  removePropertyFromProps,
} from "../../fundamental/react/props";

const StaticLabel = (props) => {
  return <div {...divProps(props)}></div>;
};

function divProps(props) {
  props = mergeStyleToProps(styles(props).label, props);
  if (props.preLabel) {
    props = mergeStyleToProps(styles(props).preLabel, props);
  }
  props = removePropertyFromProps("withFloatingText", props);
  props = removePropertyFromProps("preLabel", props);
  return props;
}

const styles = (props) => {
  return {
    label: {
      fontSize: "small",
      paddingBottom: 10,
      ...(props.withFloatingText
        ? {
            paddingTop: 38,
          }
        : {
            paddingTop: 22,
          }),
    },
    preLabel: {
      whiteSpace: "pre-line",
    },
  };
};

StaticLabel.propTypes = {
  withFloatingText: PropTypes.bool,
  preLabel: PropTypes.bool,
};

export default StaticLabel;
