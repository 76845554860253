import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import {
  OPTIONS_ALLOWABILITY,
  OPTIONS_ALLOWABILITY_FUZZY,
} from "../../../../../app/ui/form";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
} from "../../../../../state/master-data";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import { connect } from "react-redux";

class HaisetsuForm extends React.Component {
  render() {
    return <Fragment>{this.renderHaisetsu()}</Fragment>;
  }

  renderHaisetsu() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="排泄">
        <HorizontalLabelLayout labelText="特徴">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="車椅子対応" required>
          <OptionsRadio
            name="kurumaisutaio"
            split={6}
            options={OPTIONS_ALLOWABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="同性介助" required>
          <OptionsRadio
            name="doseikaijo"
            split={6}
            options={OPTIONS_ALLOWABILITY_FUZZY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="その他">
          <OptionsCheckbox
            name="haisetsuSonota"
            options={masterData.haisetsuSonota}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HaisetsuForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(connect(mapStateToProps, mapDispatchToProps)(HaisetsuForm));

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
