import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { bindActionCreators } from "redux";
import * as actions from "../../../state/service-search/actions";
import { push } from "react-router-redux";
import CategorizedOptions from "../../../app/util/CategorizedMasterData";
import { conditionConverter } from "../../../state/service-search/query-string";
import {
  serviceShubetsuCategoryTypeValueOfHokennai,
  serviceShubetsuCategoryTypeValueOfHokengai,
  serviceShubetsuCategoryTypeValueOfKyotakukaigoshienjigyosho,
} from "../../../app/domain/jigyosho/ServiceShubetsuCategoryType";

class SharedFolderMenu extends React.Component {
  constructor(props) {
    super(props);
    this.handleServiceShubetsuCategoryClick = this.handleServiceShubetsuCategoryClick.bind(
      this
    );
    this.handleServiceShubetsuMenuClick = this.handleServiceShubetsuMenuClick.bind(
      this
    );
  }

  handleServiceShubetsuCategoryClick(code) {
    const { actions, serviceShubetsuMenu } = this.props;
    const codes = serviceShubetsuMenu
      .filter((menu) => menu.category.code == code)
      .map((it) => it.code);
    actions.changeConditionFolders(codes, serviceShubetsuMenu, () => {
      actions.reSearch(this.props.condition);
    });
  }

  handleServiceShubetsuMenuClick(code) {
    const { actions, serviceShubetsuMenu } = this.props;
    actions.changeConditionFolders([code], serviceShubetsuMenu, () => {
      actions.reSearch(this.props.condition);
    });
  }

  render() {
    return <div>{this.renderServiceShubetsuMenu()}</div>;
  }

  renderServiceShubetsuMenu() {
    return (
      <div>
        <div>
          <div className="c-folder-nav">
            <div className="c-folder-nav__title">保険内</div>
            <ul className="c-folder-nav__item">
              {serviceShubetsuCategoryTypeValueOfHokennai().map((it) =>
                this.renderBook(it)
              )}
              {serviceShubetsuCategoryTypeValueOfKyotakukaigoshienjigyosho().map(
                (it) => this.renderBook(it)
              )}
            </ul>
          </div>
          <div className="c-folder-nav">
            <div className="c-folder-nav__title">保険外</div>
            <ul className="c-folder-nav__item">
              {serviceShubetsuCategoryTypeValueOfHokengai().map((it) =>
                this.renderBook(it)
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  renderBook({ code, shortLabel: label, imgSrc }) {
    const { condition, serviceShubetsuMenu, folders } = this.props;
    const category = new CategorizedOptions(serviceShubetsuMenu)
      .getCategories()
      .find((category) => category.code == code);
    const options = (category ? category.options : []).filter((it) =>
      folders.find((f) => f.serviceShubetsuCode == it.code)
    );
    const count = folders
      .filter((it) => it.serviceShubetsuCategoryCode == code)
      .reduce((count, it) => count + it.count, 0);
    const categoryIsSelected = condition.serviceShubetsuCategoryCode == code;
    const optionIsSelected = (option) =>
      condition.serviceShubetsuMenuCodes.filter((it) => it == option.code)
        .length
        ? true
        : false;
    return (
      <li
        key={`category:${code}`}
        className={categoryIsSelected ? "is-select" : null}
      >
        {count ? <i className="badge">{count}</i> : null}
        <div className="c-folder-nav__item-box">
          <a
            disabled={!count}
            onClick={() =>
              count && this.handleServiceShubetsuCategoryClick(code)
            }
          >
            <div className="c-folder-nav__item-box-img">
              <img className="unselect" src={imgSrc.inactive} />
              <img className="select" src={imgSrc.active} />
            </div>
            <div className="c-folder-nav__item-box-txt">{label}</div>
          </a>
        </div>
        {options.length > 1 ? (
          <ul className="c-folder-nav__item-box-small">
            {options.map((option) => (
              <li
                key={`option:${option.code}`}
                className={optionIsSelected(option) ? "selected" : null}
              >
                <a
                  onClick={() =>
                    this.handleServiceShubetsuMenuClick(option.code)
                  }
                >
                  {option.shortLabel}
                </a>
              </li>
            ))}
          </ul>
        ) : null}
      </li>
    );
  }
}

SharedFolderMenu.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  condition: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  serviceShubetsuMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
  folders: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(SharedFolderMenu)
);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      reSearch: (condition) => {
        const search = conditionConverter(condition).toSearch({ folder: true });
        dispatch(
          push({
            search,
          })
        );
      },
    },
  };
}
