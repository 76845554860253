import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TableHead,
  TableRow,
  TableBody,
  DialogActions,
} from "@material-ui/core";
import FixedTable from "../../../../app/ui/table/FixedTable";
import SmallPaddingTableCell from "../../../../app/ui/table/SmallPaddingTableCell";
import FlatSecondaryButton from "../../../../app/ui/button/FlatSecondaryButton";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";
import For from "../../../../app/ui/layout/For";

class AgentCollectionConfirmationDialog extends React.Component {
  render() {
    const { onClose = () => {}, open } = this.props;
    return (
      <Dialog onClose={onClose} open={open}>
        <Fragment>{this.renderMain()}</Fragment>
      </Dialog>
    );
  }

  renderMain() {
    return (
      <Fragment>
        {this.renderTitle()}
        {this.renderContent()}
        {this.renderActions()}
      </Fragment>
    );
  }

  renderTitle() {
    return <DialogTitle>事業所追加の確認</DialogTitle>;
  }

  renderContent() {
    const { list } = this.props;
    return (
      <DialogContent>
        <DialogContentText>次の事業所と重複していませんか？</DialogContentText>
        <FixedTable>
          <TableHead>
            <TableRow>
              <SmallPaddingTableCell> 事業所名 </SmallPaddingTableCell>
              <SmallPaddingTableCell> サービス種別 </SmallPaddingTableCell>
              <SmallPaddingTableCell> 市区町村 </SmallPaddingTableCell>
              <SmallPaddingTableCell> 電話番号 </SmallPaddingTableCell>
              <SmallPaddingTableCell>
                {" "}
                管理法人
                <br />
                または包括{" "}
              </SmallPaddingTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <For
              in={list}
              each={(it) => (
                <TableRow key={it.id}>
                  <SmallPaddingTableCell>
                    {" "}
                    {it.jigyoshomei}{" "}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    {" "}
                    {it.serviceShubetsu}{" "}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    {" "}
                    {it.shikuchoson}{" "}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    {" "}
                    {it.denwabango}{" "}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    {" "}
                    {it.kanriShutai}{" "}
                  </SmallPaddingTableCell>
                </TableRow>
              )}
            />
          </TableBody>
        </FixedTable>
      </DialogContent>
    );
  }

  renderActions() {
    return (
      <DialogActions>
        <FlatSecondaryButton onClick={this.props.onClose}>
          {" "}
          キャンセル{" "}
        </FlatSecondaryButton>
        <RaisedPrimaryButton onClick={this.props.onAddButtonClick}>
          {" "}
          追加する{" "}
        </RaisedPrimaryButton>
      </DialogActions>
    );
  }
}

AgentCollectionConfirmationDialog.propTypes = {
  // state
  list: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  // event handlers
  onAddButtonClick: PropTypes.func,
  onClose: PropTypes.func,
};

export default AgentCollectionConfirmationDialog;
