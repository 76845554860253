import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../state/notification-message/actions";
import Pathname from "../../naming/Pathname";

import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";

class NotificationMessage extends React.Component {
  render() {
    const { leadToLogin, message, open } = this.props.notificationMessage;

    const messageNode = (
      <span id="message-id">
        <span style={styles.body}>{message}</span>
        {leadToLogin ? (
          <span>
            （
            <a style={styles.link} href={Pathname.login}>
              ログインはこちら
            </a>
            ）
          </span>
        ) : null}
      </span>
    );

    return (
      <div className="notification-message">
        <Dialog
          open={open}
          onClose={this.props.actions.closeMessage}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {messageNode}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.props.actions.closeMessage}
              color="primary"
              autoFocus
            >
              閉じる
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const styles = {
  body: {
    whiteSpace: "pre-line",
  },
  link: {
    color: "white",
  },
};

const mapStateToProps = (state) => {
  return {
    notificationMessage: state.notificationMessage,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
};

NotificationMessage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  notificationMessage: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationMessage);
