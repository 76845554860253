import { StyleSheet } from "aphrodite";

const styles = StyleSheet.create({
  selectableRow: {},

  selectedRow: {},

  selectedCell: {},

  shortcutCell: {
    width: 160,
  },
});

export default styles;
