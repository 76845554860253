import React, { Fragment } from "react";
import PropTypes from "prop-types";
import RegistrationDataEmpty from "../RegistrationDataEmpty";
import { goToJigyoshoAttributeFormButton } from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";
import Thumbnail from "../../search/Thumbnail";

class HaishokuServiceDetail extends React.Component {
  render() {
    return (
      <div className="p-office-body">
        {this.menus().length || this.options().length ? (
          <Fragment>
            {this.renderMenus()}
            {this.renderOptions()}
          </Fragment>
        ) : (
          <RegistrationDataEmpty />
        )}
      </div>
    );
  }

  menus() {
    const { haishokuServiceMenus } = this.props.contents.blocks;
    return haishokuServiceMenus.menus;
  }

  options() {
    const { haishokuServiceOptions } = this.props.contents.blocks;
    return haishokuServiceOptions.options;
  }

  renderMenus() {
    const menus = this.menus();
    if (!menus.length) return null;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">メニュー</div>
        </div>

        <div className="p-office-body-menu">
          <ul>
            {menus.map((menu, i) => {
              return (
                <li key={i}>
                  <div className="p-office-body-menu__image">
                    <Thumbnail src={menu.imageUrl} />
                  </div>
                  <div className="p-office-body-menu__section">
                    <div className="p-office-body-menu__section-title">
                      {menu.menuMei}
                    </div>
                    <div>
                      {[menu.ondo, menu.kubun]
                        .filter((item) => item)
                        .join(" / ")}
                    </div>
                    <div>{menu.shubetsu}</div>
                  </div>
                  <div className="p-office-body-menu__section">
                    <div className="p-office-body-menu__section-detail">
                      {menu.menuShosai}
                    </div>
                  </div>
                  <div className="p-office-body-menu__last">
                    <dl>
                      <dt>通常価格（税込み）</dt>
                      <dd className="p-office-body-menu__last-b">
                        {menu.ryokin}
                      </dd>
                      <dt>おかずのみ価格（税込み）</dt>
                      <dd>{menu.ryokinOkazunomi}</dd>
                    </dl>
                  </div>
                </li>
              );
            })}
          </ul>

          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.deliveryMenu
          )}
        </div>
      </Fragment>
    );
  }

  renderOptions() {
    const options = this.options();
    if (!options.length) return null;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">オプション</div>
        </div>

        <div className="p-office-body-option">
          <ul>
            {options.map((option, i) => {
              return (
                <li key={i}>
                  <div className="p-office-body-menu__image">
                    <Thumbnail src={option.imageUrl} />
                  </div>
                  <div className="p-office-body-menu__section">
                    <div className="p-office-body-menu__section-title">
                      {option.optionMei}
                    </div>
                  </div>
                  <div className="p-office-body-menu__last">
                    <dl>
                      <dt>価格（税込み）</dt>
                      <dd className="p-office-body-menu__last-b">
                        {option.ryokin}
                      </dd>
                    </dl>
                  </div>
                </li>
              );
            })}
          </ul>

          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.deliveryOption
          )}
        </div>
      </Fragment>
    );
  }
}

HaishokuServiceDetail.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default HaishokuServiceDetail;
