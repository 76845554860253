import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ServiceKaishiBusinessProvideOther from "./ServiceKaishiBusinessProvideOther";
import ServiceYoyaku from "./ServiceYoyaku";
import ServiceGyoseiJoseiHojo from "./ServiceGyoseiJoseiHojo";

class BusinessProvideOtherForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderServiceKaishi()}
        {this.renderYoyaku()}
        {this.renderGyoseiJoseiHojo()}
      </Fragment>
    );
  }

  renderServiceKaishi() {
    const { bindingProps } = this.props;
    return <ServiceKaishiBusinessProvideOther bindingProps={bindingProps} />;
  }

  renderYoyaku() {
    const { bindingProps } = this.props;
    return <ServiceYoyaku bindingProps={bindingProps} />;
  }

  renderGyoseiJoseiHojo() {
    const { bindingProps } = this.props;
    return <ServiceGyoseiJoseiHojo bindingProps={bindingProps} />;
  }
}

BusinessProvideOtherForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BusinessProvideOtherForm);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
