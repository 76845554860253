import React, { Fragment } from "react";
import PropTypes from "prop-types";
import JigyoshoTable from "../content/shortstay/JigyoshoTable";
import EigyoJikanTable from "../content/shortstay/EigyoJikanTable";
import SogeiTable from "../content/shortstay/SogeiTable";
import RehabilitationTable from "../content/shortstay/RehabilitationTable";
import RecreationTable from "../content/shortstay/RecreationTable";
import ShokujiTable from "../content/shortstay/ShokujiTable";
import NyuyokuTable from "../content/shortstay/NyuyokuTable";
import HaisetsuTable from "../content/shortstay/HaisetsuTable";
import KyoshitsuTable from "../content/shortstay/KyoshitsuTable";
import SonotaServiceTable from "../content/shortstay/SonotaServiceTable";
import TaiokanoShojoTable from "../content/shortstay/TaiokanoShojoTable";
import RiyoshaTable from "../content/shortstay/RiyoshaTable";
import StaffTable from "../content/shortstay/StaffTable";
import RyokinkasanTable from "../content/shortstay/RyokinkasanTable";
import MoshikomiTable from "../content/shortstay/MoshikomiTable";
import TatemonoSetsubiBihinTable from "../content/shortstay/TatemonoSetsubiBihinTable";
import GaibuRenkeiTable from "../content/shortstay/GaibuRenkeiTable";
import { goToJigyoshoAttributeFormButton } from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";
import RyokinMeyasuTableSet from "../content/shortstay/RyokinMeyasuTableSet";

class ShortstayDetail extends React.Component {
  render() {
    return (
      <div>
        <div className="p-office-body">
          {this.renderEigyoJoho()}

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">
              {this.renderRehabilitation()}
            </div>

            <div className="p-office-body-sep__right">
              {this.renderRecreation()}
            </div>
          </div>

          <div className="p-office-body-sep">
            <div className="p-office-body-sep__left">
              {this.renderShokuji()}
              {this.renderHaisetsu()}
            </div>

            <div className="p-office-body-sep__right">
              {this.renderNyuyoku()}
            </div>
          </div>

          <div className="p-office-body-section">
            {this.renderKyoshitsu()}
            {this.renderMoshikomi()}
          </div>

          <div className="p-office-body-section">
            {this.renderTaiokanonaShojo()}
          </div>

          {this.renderRiyosha()}
          {this.renderStaff()}
          {this.renderRyokin()}
          {this.renderTatemonoSetsubiBihin()}
          {this.renderGaibuRenkei()}
          {this.renderSonotaService()}
          {this.renderJigyoshoJoho()}
        </div>
      </div>
    );
  }

  renderEigyoJoho() {
    const eigyoJikan = this.props.contents.blocks.shortstayEigyoJikan;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">営業情報</div>
        </div>
        <div className="p-office-body-sep">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-title">窓口対応時間</div>
            <EigyoJikanTable contents={this.props.contents} detail={true} />
            <div className="p-office-body-title">面会可能時間</div>
            <div className="p-office-body-table">
              <table>
                <tbody>
                  <tr>
                    <th>面会可能時間</th>
                    <td>{eigyoJikan.menkaiJikan.join(" 〜 ")}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            {goToJigyoshoAttributeFormButton(
              this.props.contents,
              JigyoshoAttributeType.shortstayEigyoJikan
            )}
          </div>

          <div className="p-office-body-sep__right">
            <SogeiTable contents={this.props.contents} detail={true} />
          </div>
        </div>
      </Fragment>
    );
  }

  renderRehabilitation() {
    const { serviceShubetsuCode } = this.props.contents;
    const title =
      ServiceShubetsuType.短期入所生活介護.code == serviceShubetsuCode
        ? "機能訓練"
        : "リハビリテーション";
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">{title}</div>
        </div>
        <RehabilitationTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderRecreation() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">レクリエーション・行事</div>
        </div>
        <RecreationTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderShokuji() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">食事</div>
        </div>
        <ShokujiTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderHaisetsu() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">排泄</div>
        </div>
        <HaisetsuTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderNyuyoku() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">入浴</div>
        </div>
        <NyuyokuTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderKyoshitsu() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">居室</div>
        </div>
        <KyoshitsuTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderMoshikomi() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">お申込みについて</div>
        </div>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <MoshikomiTable contents={this.props.contents} detail={true} />
          </div>
        </div>
      </Fragment>
    );
  }

  renderTaiokanonaShojo() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">
            受入れ / 対応可能な症状・医療行為
          </div>
        </div>
        <TaiokanoShojoTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderRiyosha() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">ご利用者</div>
        </div>
        <RiyoshaTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderStaff() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">スタッフ</div>
        </div>
        <StaffTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderRyokin() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">料金</div>
        </div>
        <RyokinkasanTable contents={this.props.contents} />
        <RyokinMeyasuTableSet contents={this.props.contents} />
        <div className="p-office-body-sep" />
      </Fragment>
    );
  }

  renderTatemonoSetsubiBihin() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">建物・設備・備品</div>
        </div>
        <TatemonoSetsubiBihinTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderGaibuRenkei() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">外部連携</div>
        </div>
        <GaibuRenkeiTable contents={this.props.contents} />
      </Fragment>
    );
  }

  renderSonotaService() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">その他サービス</div>
        </div>
        <SonotaServiceTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }

  renderJigyoshoJoho() {
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">事業所情報</div>
        </div>
        <JigyoshoTable contents={this.props.contents} detail={true} />
      </Fragment>
    );
  }
}

ShortstayDetail.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default ShortstayDetail;
