const ServiceSearchSortType = {
  更新日時順: { name: "u", label: "更新日時順", orderNo: 1 },
  印刷回数順: { name: "p", label: "印刷回数順", orderNo: 2 },
  事業所名順: { name: "n", label: "事業所名順", orderNo: 3 },
};

export function serviceSearchSortTypeValues() {
  return Object.keys(ServiceSearchSortType).map(
    (key) => ServiceSearchSortType[key]
  );
}

export function serviceSearchSortTypeValueOf(name) {
  return serviceSearchSortTypeValues().find((type) => type.name == name);
}

export function serviceSearchSortTypeValueOfOrDefault(name) {
  const type = serviceSearchSortTypeValueOf(name);
  return type ? type : ServiceSearchSortType.更新日時順;
}

export function serviceSearchSortOptions() {
  return serviceSearchSortTypeValues().map((type) => ({
    code: type.name,
    label: type.label,
    orderNo: type.orderNo,
  }));
}

export default ServiceSearchSortType;
