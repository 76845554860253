import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  OPTIONS_ALLOWABILITY,
  OPTIONS_AVAILABILITY,
} from "../../../../../app/ui/form";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import TimePeriod from "../../../../../app/ui/form/TimePeriod";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Textarea from "../../../../../app/ui/form/Textarea";

class KyotakukaigoshienjigyoshoServiceForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderServiceTeikyoChiiki()}
        {this.renderHomontaiokanobi()}
        {this.renderService()}
      </Fragment>
    );
  }

  renderServiceTeikyoChiiki() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="サービス提供地域">
        <HorizontalLabelLayout>
          <Textarea name="serviceTeikyoChiiki" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderService() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="電話対応">
        <HorizontalLabelLayout labelText="電話受付時間" recommended>
          <ShortInputWidth>
            <TimePeriod name="telUketsuke" separator="." {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="緊急時の電話対応" required>
          <OptionsRadio
            name="kinkyujinodenwataio"
            split={6}
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="緊急時の連絡先">
          <ShortInputWidth>
            <InputTypeText
              name="kinkyujinorenrakusaki"
              hintText="00-0000-0000"
              {...bindingProps}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderHomontaiokanobi() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="訪問対応可能日">
        <HorizontalLabelLayout labelText="平日" required>
          <OptionsRadio
            name="homontaiokanobiHeijitsu"
            split={6}
            options={OPTIONS_ALLOWABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="土曜" required>
          <OptionsRadio
            name="homontaiokanobiDoyo"
            split={6}
            options={OPTIONS_ALLOWABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="日曜" required>
          <OptionsRadio
            name="homontaiokanobiNichiyo"
            split={6}
            options={OPTIONS_ALLOWABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="祝日" required>
          <OptionsRadio
            name="homontaiokanobiShukujitsu"
            split={6}
            options={OPTIONS_ALLOWABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

KyotakukaigoshienjigyoshoServiceForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default KyotakukaigoshienjigyoshoServiceForm;
