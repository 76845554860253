import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";

const RyokinTeizui = ({ contents }) => {
  const { homonkeiServiceRyokin: ryokin } = contents.blocks;
  return (
    <Fragment>
      <div className="p-office-body-title">介護報酬加算</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>共通</th>
              <td>{ryokin.kaigohoshukasanKyotsu.join("、")}</td>
            </tr>
            <tr>
              <th>要介護のみ</th>
              <td>{ryokin.kaigohoshukasanYokaigo.join("、")}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="p-office-body-title">利用料金の目安</div>
      <div className="p-office-body-sep2">
        <Mount if={ryokin.ittaiGata}>
          <div className="p-office-body-sep__left">
            <div className="p-office-body-table2">
              <div className="p-office-body-table2__th">
                訪問介護費
                <br />
                (訪問看護あり)
              </div>
              <div className="p-office-body-table2__td">
                <ul>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      要介護度1
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {ryokin.homonkaigohiHomonkangoAriYokaigo1}
                    </div>
                  </li>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      要介護度2
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {ryokin.homonkaigohiHomonkangoAriYokaigo2}
                    </div>
                  </li>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      要介護度3
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {ryokin.homonkaigohiHomonkangoAriYokaigo3}
                    </div>
                  </li>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      要介護度4
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {ryokin.homonkaigohiHomonkangoAriYokaigo4}
                    </div>
                  </li>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      要介護度5
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {ryokin.homonkaigohiHomonkangoAriYokaigo5}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </Mount>
        <div className="p-office-body-sep__right">
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">
              訪問介護費
              <Mount if={ryokin.ittaiGata}>
                <br />
                (訪問看護なし)
              </Mount>
            </div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">要介護度1</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkaigohiYokaigo1}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">要介護度2</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkaigohiYokaigo2}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">要介護度3</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkaigohiYokaigo3}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">要介護度4</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkaigohiYokaigo4}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">要介護度5</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkaigohiYokaigo5}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

RyokinTeizui.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default RyokinTeizui;
