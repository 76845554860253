import React, { Fragment } from "react";
import PropTypes from "prop-types";
import RegistrationDataEmpty from "./RegistrationDataEmpty";
import { gotoPhotoFormButton } from "./GoToEditFormButton";

const CATEGORY_ALL = "すべて";

class ServiceContentTypePhoto extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeCategory: CATEGORY_ALL,
    };
  }

  handleCategoryClick(category) {
    this.activateCategory(category);
  }

  photosBlock() {
    return this.props.contents.blocks.photos;
  }

  getImagesOfActiveCategory() {
    return this.photosBlock().list.filter((image) => {
      return (
        this.isCategoryAllActive() || this.isCategoryActive(image.category)
      );
    });
  }

  numberOfImages(category) {
    return this.photosBlock().list.filter((image) => {
      return category === CATEGORY_ALL || image.category === category;
    }).length;
  }

  isCategoryActive(category) {
    return this.state.activeCategory === category;
  }

  isCategoryAllActive() {
    return this.isCategoryActive(CATEGORY_ALL);
  }

  activateCategory(category) {
    this.setState({ activeCategory: category });
  }

  render() {
    return (
      <div className="p-office-body">
        {this.renderMain()}

        {gotoPhotoFormButton(this.props.contents)}
      </div>
    );
  }

  renderMain() {
    if (this.photosBlock().list.length < 1) return <RegistrationDataEmpty />;
    return (
      <Fragment>
        {this.renderCategories()}
        {this.renderPhotos()}
      </Fragment>
    );
  }

  renderCategories() {
    if (this.photosBlock().categories.length === 0) {
      return null;
    }

    const categories = [CATEGORY_ALL].concat(this.photosBlock().categories);
    return (
      <div className="p-office-body-nav">
        <ul>
          {categories.map((category, index) => {
            const numberOfImages = this.numberOfImages(category);
            const categoryExp = `${category}(${numberOfImages})`;

            if (this.isCategoryActive(category)) {
              return (
                <li key={index} className="is-active">
                  {categoryExp}
                </li>
              );
            } else if (numberOfImages <= 0) {
              return <li key={index}>{categoryExp}</li>;
            } else {
              return (
                <li key={index}>
                  <a onClick={() => this.handleCategoryClick(category)}>
                    {categoryExp}
                  </a>
                </li>
              );
            }
          })}
        </ul>
      </div>
    );
  }

  renderPhotos() {
    return (
      <div className="p-office-body-picture">
        <ul>
          {this.getImagesOfActiveCategory().map((image, index) => {
            return (
              <li key={index}>
                <div>
                  <img src={image.imageUrl} />
                  {(() => {
                    if (image.description) {
                      return <p className="caption">{image.description}</p>;
                    }
                  })()}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }
}

ServiceContentTypePhoto.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default ServiceContentTypePhoto;
