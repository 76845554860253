import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ThWithBg from "../general/ThWithBg";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

const CareBlock = ({ careJoho, serviceShubetsuType }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="ケア情報" />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問介護}
        then={() => <CareBlock_homonkaigo careJoho={careJoho} />}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問看護}
        then={() => <CareBlock_homonkango careJoho={careJoho} />}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問リハビリテーション}
        then={() => <CareBlock_rehabilitation careJoho={careJoho} />}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問入浴介護}
        then={() => <CareBlock_nyuyoku careJoho={careJoho} />}
      />
      <Mount
        if={
          serviceShubetsuType ===
          ServiceShubetsuType.定期巡回_随時対応型訪問介護看護
        }
        then={() => <CareBlock_teikizuiji careJoho={careJoho} />}
      />
    </Fragment>
  );
};

const CareBlock_homonkaigo = (careJoho) => {
  const { careJoho: care } = careJoho;
  return (
    <Fragment>
      <div className="p-office-body-table" style={{ marginTop: "15pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>認定特定</div>
          <div>行為の実施</div>
        </div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="喀痰吸引" />
              <ThWithBg text="経管栄養" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.kakutankyuin}</td>
              <td>{care.keikaneiyo}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "15pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>疾患</div>
        </div>
        <table>
          <thead>
            <tr className="print-body_th_img_height20">
              <ThWithBg text="人工呼吸器" />
              <ThWithBg
                text={
                  <span>
                    進行性
                    <br />
                    神経筋疾患
                  </span>
                }
              />
              <ThWithBg text="重度認知症" />
              <ThWithBg text="悪性新生物" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.jinkokokyuki}</td>
              <td>{care.shinkoseikinshikkan}</td>
              <td>{care.jyudoninchisho}</td>
              <td>{care.akuseishinseibutsu}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "15pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>提供</div>
          <div>サービス</div>
        </div>
        <table>
          <thead>
            <tr className="print-body_th_img_height20">
              <ThWithBg text="緊急時訪問" />
              <ThWithBg text="通院等乗降介助" />
              <ThWithBg
                text={
                  <div>
                    <div>20分未満の</div>
                    <div>身体介助の実施</div>
                  </div>
                }
              />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.kinkyujihomon}</td>
              <td>{care.tsuinjokokaijo}</td>
              <td>{care.to20MinutesShintaikaijo}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "15pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          訪問手段
        </div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="軽自動車" />
              <ThWithBg text="原付" />
              <ThWithBg text="自転車" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.keijidousha}</td>
              <td>{care.gentsuki}</td>
              <td>{care.jitensha}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

const CareBlock_homonkango = (careJoho) => {
  const { careJoho: care } = careJoho;
  return (
    <Fragment>
      <div className="p-office-body-table" style={{ marginTop: "20pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>
            疾患
            <br />
            医療行為
          </div>
        </div>
        <table>
          <thead>
            <tr className="print-body_th_img_height20">
              <ThWithBg text="人工呼吸器" />
              <ThWithBg
                text={
                  <span>
                    進行性
                    <br />
                    神経筋疾患
                  </span>
                }
              />
              <ThWithBg text="精神疾患" />
              <ThWithBg text="麻薬（疼痛）" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.jinkokokyuki}</td>
              <td>{care.shinkoseikinshikkan}</td>
              <td>{care.seishinshikkan}</td>
              <td>{care.mayakutotsu}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "20pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>看護内容</div>
        </div>
        <table>
          <thead>
            <tr className="print-body_th_img_height20">
              <ThWithBg text="ターミナルケア" />
              <ThWithBg text="エンゼルケア" />
              <ThWithBg
                text={
                  <div>
                    <div>リハビリ専門職員</div>
                    <div>による訪問</div>
                  </div>
                }
              />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.terminalCare}</td>
              <td>{care.angelCare}</td>
              <td>{care.senmonshokuinhomon}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "20pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          訪問手段
        </div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="軽自動車" />
              <ThWithBg text="原付" />
              <ThWithBg text="自転車" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.keijidousha}</td>
              <td>{care.gentsuki}</td>
              <td>{care.jitensha}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

const CareBlock_rehabilitation = (careJoho) => {
  const { careJoho: care } = careJoho;
  return (
    <Fragment>
      <div className="p-office-body-table" style={{ marginTop: "15pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>担当制：</div>
        </div>
        <div>{care.tantosei}</div>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "15pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>疾患</div>
        </div>
        <table>
          <thead>
            <tr className="print-body_th_img_height20">
              <ThWithBg text="人工呼吸器" />
              <ThWithBg
                text={
                  <span>
                    進行性
                    <br />
                    神経筋疾患
                  </span>
                }
              />
              <ThWithBg text="重度認知症" />
              <ThWithBg text="悪性新生物" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.jinkokokyuki}</td>
              <td>{care.shinkoseikinshikkan}</td>
              <td>{care.jyudoninchisho}</td>
              <td>{care.akuseishinseibutsu}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "15pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>訓練内容</div>
        </div>
        <table>
          <tbody>
            <tr className="print-body_th_img_height14">
              <ThWithBg text="調理行為練習" />
              <td style={{ width: "25pt" }}>{care.chorikoi}</td>
              <ThWithBg text="摂食・嚥下機能訓練" />
              <td style={{ width: "25pt" }}>{care.sesshokuengekino}</td>
            </tr>
            <tr className="print-body_th_img_height14">
              <ThWithBg text="買い物訓練" />
              <td>{care.kaimono}</td>
              <ThWithBg text="聴覚機能訓練" />
              <td>{care.chokakukino}</td>
            </tr>
            <tr className="print-body_th_img_height14">
              <ThWithBg text="公共交通機関利用訓練" />
              <td>{care.kotsukikan}</td>
              <ThWithBg text="言語機能訓練" />
              <td>{care.gengokino}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "15pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          訪問手段
        </div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="軽自動車" />
              <ThWithBg text="原付" />
              <ThWithBg text="自転車" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.keijidousha}</td>
              <td>{care.gentsuki}</td>
              <td>{care.jitensha}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

const CareBlock_nyuyoku = (careJoho) => {
  const { careJoho: care } = careJoho;
  return (
    <Fragment>
      <div className="p-office-body-table" style={{ marginTop: "10pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>疾患</div>
        </div>
        <table>
          <thead>
            <tr className="print-body_th_img_height20">
              <ThWithBg text="人工呼吸器" />
              <ThWithBg
                text={
                  <span>
                    進行性
                    <br />
                    神経筋疾患
                  </span>
                }
              />
              <ThWithBg text="重度認知症" />
              <ThWithBg text="悪性新生物" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.jinkokokyuki}</td>
              <td>{care.shinkoseikinshikkan}</td>
              <td>{care.jyudoninchisho}</td>
              <td>{care.akuseishinseibutsu}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "10pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>
            サービス
            <br />
            内容
          </div>
        </div>
        <table>
          <tbody>
            <tr className="print-body_th_img_height14">
              <ThWithBg text="全身浴" />
              <td style={{ width: "25pt" }}>{care.zenshinyoku}</td>
              <ThWithBg text="爪切り" />
              <td style={{ width: "25pt" }}>{care.tsumekiri}</td>
            </tr>
            <tr className="print-body_th_img_height14">
              <ThWithBg text="部分浴" />
              <td>{care.bubunyoku}</td>
              <ThWithBg text="髭剃り" />
              <td>{care.higesori}</td>
            </tr>
            <tr className="print-body_th_img_height14">
              <ThWithBg text="清拭" />
              <td>{care.seishiki}</td>
              <ThWithBg text="薬剤の塗布" />
              <td>{care.yakuzaitofu}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "10pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>
            その他
            <br />
            サービス
          </div>
        </div>
        <table>
          <tbody>
            <tr className="print-body_th_img_height14">
              <ThWithBg text="洗濯機" />
              <td style={{ width: "25pt" }}>{care.sentakuki}</td>
              <ThWithBg text="タオル貸し出し" />
              <td style={{ width: "25pt" }}>{care.taorukashidashi}</td>
            </tr>
            <tr className="print-body_th_img_height14">
              <ThWithBg text="シーツ交換実施" />
              <td>{care.shitsukokan}</td>
              <ThWithBg text="入浴剤使用" />
              <td>{care.nyuyokuzaishiyo}</td>
            </tr>
            <tr className="print-body_th_img_height14">
              <ThWithBg text="天然温泉" />
              <td>{care.tennenonsen}</td>
              <ThWithBg text="高濃度炭酸泉入浴" />
              <td>{care.konodotansansen}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

const CareBlock_teikizuiji = (careJoho) => {
  const { careJoho: care } = careJoho;
  return (
    <Fragment>
      <div className="p-office-body-table" style={{ marginTop: "10pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>
            疾患
            <br />
            医療行為
          </div>
        </div>
        <table>
          <thead>
            <tr className="print-body_th_img_height20">
              <ThWithBg text="人工呼吸器" />
              <ThWithBg
                text={
                  <span>
                    進行性
                    <br />
                    神経筋疾患
                  </span>
                }
              />
              <ThWithBg text="精神疾患" />
              <ThWithBg text="麻薬（疼痛）" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.jinkokokyuki}</td>
              <td>{care.shinkoseikinshikkan}</td>
              <td>{care.seishinshikkan}</td>
              <td>{care.mayakutotsu}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "10pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>看護内容</div>
        </div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="ターミナルケア" />
              <ThWithBg text="エンゼルケア" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.terminalCare}</td>
              <td>{care.angelCare}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "10pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>認定特定</div>
          <div>行為の実施</div>
        </div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="喀痰吸引" />
              <ThWithBg text="経管栄養" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.kakutankyuin}</td>
              <td>{care.keikaneiyo}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table" style={{ marginTop: "10pt" }}>
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          訪問手段
        </div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="軽自動車" />
              <ThWithBg text="原付" />
              <ThWithBg text="自転車" />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{care.keijidousha}</td>
              <td>{care.gentsuki}</td>
              <td>{care.jitensha}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

CareBlock.propTypes = {
  careJoho: PropTypes.object.isRequired,
  serviceShubetsuType: PropTypes.object.isRequired,
};

export default CareBlock;
