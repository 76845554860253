import React, { Fragment } from "react";
import { Route, Switch } from "react-router";
import Pathname from "../../app/naming/Pathname";
import WebFrontDashboard from "./dashboard/WebFrontDashboard";
import WebFrontLayout from "./WebFrontLayout";
import SearchConditionPage from "./search/condition/SearchConditionPage";
import SearchPage from "./search/SearchPage";
import SharedFolderPage from "./folder/SharedFolderPage";
import ServicePage from "./service/ServicePage";
import { withSharedFolder } from "../../state/shared-folder";
import ServicePrintPage from "./service/ServicePrintPage";
import WebFrontPrintLayout from "./WebFrontPrintLayout";
import ServiceListPrintPage from "./search/ServiceListPrintPage";
import WebFrontPreviewLayout from "./WebFrontPreviewLayout";
import ServicePageType from "./service/ServicePageType";
import NotificationMessage from "../../app/ui/snackbar/NotificationMessage";
import InformationMessageList from "./information/InformationMessageList";
import InformationMessageDetail from "./information/InformationMessageDetail";
import ServiceMapPrintPage from "./search/ServiceMapPrintPage";
import ServiceDetailsPrintPage from "./search/ServiceDetailsPrintPage";

class WebFrontRouter extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    return (
      <Fragment>
        <Switch>
          <Route
            path={Pathname.webfront_service_id}
            component={() => (
              <WebFrontLayout>
                <Switch>
                  <Route
                    path={Pathname.webfront_service_id_type}
                    component={(props) => (
                      <ServicePage type={ServicePageType.基本} {...props} />
                    )}
                  />
                  <Route
                    path={Pathname.webfront_service_id}
                    component={(props) => (
                      <ServicePage type={ServicePageType.基本} {...props} />
                    )}
                  />
                </Switch>
              </WebFrontLayout>
            )}
          />
          <Route
            path={Pathname.webfront_preview_jigyosho_id}
            component={({ match }) => {
              const type = ServicePageType.プレビュー_事業所管理;
              return (
                <WebFrontPreviewLayout id={match.params.id} type={type}>
                  <Switch>
                    <Route
                      path={Pathname.webfront_preview_jigyosho_id_type}
                      render={(props) => (
                        <ServicePage preview={true} type={type} {...props} />
                      )}
                    />
                    <Route
                      path={Pathname.webfront_preview_jigyosho_id}
                      render={(props) => (
                        <ServicePage preview={true} type={type} {...props} />
                      )}
                    />
                  </Switch>
                </WebFrontPreviewLayout>
              );
            }}
          />
          <Route
            path={Pathname.webfront_preview_agent_collection_id}
            component={({ match }) => {
              const type = ServicePageType.プレビュー_代行入力;
              return (
                <WebFrontPreviewLayout id={match.params.id} type={type}>
                  <Switch>
                    <Route
                      path={Pathname.webfront_preview_agent_collection_id_type}
                      render={(props) => (
                        <ServicePage preview={true} type={type} {...props} />
                      )}
                    />
                    <Route
                      path={Pathname.webfront_preview_agent_collection_id}
                      render={(props) => (
                        <ServicePage preview={true} type={type} {...props} />
                      )}
                    />
                  </Switch>
                </WebFrontPreviewLayout>
              );
            }}
          />
          <Route
            path={Pathname.webfront_print}
            component={() => (
              <Switch>
                <Route
                  path={Pathname.webfront_print_service_list}
                  component={(props) => (
                    <WebFrontPrintLayout landscape printType="list">
                      <ServiceListPrintPage {...props} />
                    </WebFrontPrintLayout>
                  )}
                />
                <Route
                  path={Pathname.webfront_print_service_details}
                  component={(props) => (
                    <WebFrontPrintLayout printType="details" detailprint={true}>
                      <ServiceDetailsPrintPage {...props} />
                    </WebFrontPrintLayout>
                  )}
                />
                <Route
                  path={Pathname.webfront_print_service_map}
                  component={(props) => (
                    <WebFrontPrintLayout printType="map">
                      <ServiceMapPrintPage {...props} />
                    </WebFrontPrintLayout>
                  )}
                />
                <Route
                  path={Pathname.webfront_print_service_id}
                  component={(props) => (
                    <WebFrontPrintLayout detailprint={true}>
                      <ServicePrintPage {...props} />
                    </WebFrontPrintLayout>
                  )}
                />
              </Switch>
            )}
          />
          <Route
            path={Pathname.webfront_preview_print_service_id}
            component={() => (
              <WebFrontPrintLayout detailprint={true}>
                <Switch>
                  <Route
                    path={Pathname.webfront_preview_print_service_id}
                    render={(props) => (
                      <ServicePrintPage preview={true} {...props} />
                    )}
                  />
                </Switch>
              </WebFrontPrintLayout>
            )}
          />
          <Route
            path={Pathname.webfront_information}
            component={() => (
              <Switch>
                <Route
                  path={Pathname.webfront_information_id}
                  component={InformationMessageDetail}
                />
                <Route
                  path={Pathname.webfront_information}
                  component={InformationMessageList}
                />
              </Switch>
            )}
          />
          <Route
            path={Pathname.webfront}
            component={() => (
              <WebFrontLayout>
                <Switch>
                  <Route
                    path={Pathname.webfront_folder_widget}
                    component={WebFrontDashboard}
                  />
                  <Route
                    path={Pathname.webfront_folder}
                    component={SharedFolderPage}
                  />
                  <Route
                    path={Pathname.webfront_search_condition}
                    component={SearchConditionPage}
                  />
                  <Route
                    path={Pathname.webfront_search}
                    component={SearchPage}
                  />
                  <Route
                    path={Pathname.webfront}
                    component={SearchConditionPage}
                  />
                </Switch>
              </WebFrontLayout>
            )}
          />
        </Switch>
        <NotificationMessage />
      </Fragment>
    );
  }
}

export default withSharedFolder(WebFrontRouter);
