import React, { Component } from "react";
import { animateScroll as scroll } from "react-scroll";

class ScrollTopButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUrl: "/images/button_shadow.png",
      currentPosition: 0,
    };
  }

  componentDidMount() {
    window.addEventListener(
      "scroll",
      () => {
        return this.watchCurrentPosition();
      },
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "scroll",
      () => {
        return this.watchCurrentPosition();
      },
      false
    );
  }

  watchCurrentPosition() {
    const value = this.scrollTop();
    this.setState({ currentPosition: value });
  }

  scrollTop() {
    return Math.max(
      window.pageYOffset,
      document.documentElement.scrollTop,
      document.body.scrollTop
    );
  }

  scrollToTop = () => {
    scroll.scrollToTop();
  };

  render() {
    const position_number = this.state.currentPosition > 50;
    return (
      <div
        className="scroll scroll-up"
        onMouseEnter={() =>
          this.setState({ imageUrl: "/images/button_noshadow.png" })
        }
        onMouseLeave={() =>
          this.setState({ imageUrl: "/images/button_shadow.png" })
        }
      >
        <div
          className="arrowbounce"
          style={styles(position_number).arrowbounce}
        >
          <img src={this.state.imageUrl} onClick={this.scrollToTop} />
        </div>
      </div>
    );
  }
}

const styles = (position_number) => ({
  arrowbounce: {
    opacity: `${position_number ? 1 : 0}`,
    position: "fixed",
    right: "2%",
    zIndex: 1,
    bottom: "5%",
  },
});

export default ScrollTopButton;
