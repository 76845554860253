import React from "react";
import PropTypes from "prop-types";
import { Card } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import SideMargin from "../layout/SideMargin";
import BackOfficeHead from "../structure/BackOfficeHead";
import ScreenMask from "./ScreenMask";

class OperationDialog extends React.Component {
  constructor(props) {
    super(props);
    this.handleScreenMaskClick = this.handleScreenMaskClick.bind(this);
    this.handleDialogClick = this.handleDialogClick.bind(this);
  }

  handleScreenMaskClick(e) {
    this.props.onClose(e);
    e.stopPropagation();
  }

  handleDialogClick(e) {
    e.stopPropagation();
  }

  render() {
    if (!this.props.open) return null;
    return (
      <ScreenMask onClick={this.handleScreenMaskClick}>
        <Card style={styles.dialog} onClick={this.handleDialogClick}>
          <SideMargin bottom>
            <div style={styles.headContainer}>
              <div>
                <BackOfficeHead>{this.props.title}</BackOfficeHead>
              </div>
              <div>
                <IconButton
                  style={styles.clearButton}
                  onClick={this.props.onClose}
                >
                  <ClearIcon />
                </IconButton>
              </div>
            </div>
            {this.props.children}
          </SideMargin>
        </Card>
      </ScreenMask>
    );
  }
}

const styles = {
  dialog: {
    bottom: 0,
    position: "fixed",
    width: "100vw",
  },
  headContainer: {
    display: "flex",
    justifyContent: "space-between",
  },
  clearButton: {
    marginTop: 8,
  },
};

OperationDialog.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default OperationDialog;
