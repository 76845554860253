import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import { serviceShubetsuTypeValueOf } from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import Mount from "../../../../../app/ui/layout/Mount";
import StaffSummaryKaigo from "./StaffSummaryKaigo";
import StaffSummaryKango from "./StaffSummaryKango";
import StaffSummaryNyuyoku from "./StaffSummaryNyuyoku";
import StaffSummaryRehabilitation from "./StaffSummaryRehabilitation";
import StaffSummaryTeizui from "./StaffSummaryTeizui";

const StaffSummaryTable = (props) => {
  const serviceShubetsuType = serviceShubetsuTypeValueOf(
    props.contents.serviceShubetsuCode
  );

  return (
    <Fragment>
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問介護}
        then={() => <StaffSummaryKaigo {...props} />}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問看護}
        then={() => <StaffSummaryKango {...props} />}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問入浴介護}
        then={() => <StaffSummaryNyuyoku {...props} />}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問リハビリテーション}
        then={() => <StaffSummaryRehabilitation {...props} />}
      />
      <Mount
        if={
          serviceShubetsuType ===
          ServiceShubetsuType.定期巡回_随時対応型訪問介護看護
        }
        then={() => <StaffSummaryTeizui {...props} />}
      />

      {goToJigyoshoAttributeFormButton(
        props.contents,
        JigyoshoAttributeType.homonkeiServiceStaff
      )}
    </Fragment>
  );
};

StaffSummaryTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default StaffSummaryTable;
