import Pathname from "../../../app/naming/Pathname";

const ServicePageType = {
  基本: {
    name: "basic",
    pathname: Pathname.webfront_service_id_type,
    printPathname: Pathname.webfront_print_service_id,
  },
  プレビュー_事業所管理: {
    name: "preview_jigyosho",
    pathname: Pathname.webfront_preview_jigyosho_id_type,
    printPathname: Pathname.webfront_preview_print_service_id,
    returnPathname: Pathname.backoffice_jigyosho_id_status,
    photoEditFormPathname: Pathname.backoffice_jigyosho_id_photo,
    flierEditFormPathname: Pathname.backoffice_jigyosho_id_flier,
    attributeEditFormPathname: Pathname.backoffice_jigyosho_id_attributes_type,
  },
  プレビュー_代行入力: {
    name: "preview_agent_collection",
    pathname: Pathname.webfront_preview_agent_collection_id_type,
    printPathname: Pathname.webfront_preview_print_service_id,
    returnPathname: Pathname.backoffice_agent_collection_id_status,
    photoEditFormPathname: Pathname.backoffice_agent_collection_id_photo,
    flierEditFormPathname: Pathname.backoffice_agent_collection_id_flier,
    attributeEditFormPathname:
      Pathname.backoffice_agent_collection_id_attributes_type,
  },
};

export default ServicePageType;
