import React from "react";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import {
  removePropertyFromProps,
  mergeStyleToProps,
} from "../../fundamental/react/props";

const FormGroupingDeleteButton = (props) => {
  return (
    <IconButton
      {...mergeStyleToProps(
        styles.button,
        removePropertyFromProps("children", props)
      )}
    >
      <DeleteIcon />
    </IconButton>
  );
};

const styles = {
  button: {
    left: 0,
    position: "absolute",
    top: 30,
  },
};

export default FormGroupingDeleteButton;
