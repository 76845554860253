import React from "react";
import InputTypeText from "./InputTypeText";

const InputTypeNumber = (props) => {
  return <InputTypeText type="number" {...inputTypeTextProps(props)} />;
};

function inputTypeTextProps(props) {
  return {
    ...props,
    hintText: "0",
  };
}

export default InputTypeNumber;
