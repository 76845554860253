import { createMuiTheme } from "@material-ui/core";
import cyan from "@material-ui/core/colors/cyan";

const theme = createMuiTheme({
  palette: {
    primary: {
      ...cyan,
      contrastText: "white",
    },
  },
});

export default theme;
