import React from "react";
import PropTypes from "prop-types";
import { IconButton } from "@material-ui/core";
import NextIcon from "@material-ui/icons/NavigateNext";
import BeforeIcon from "@material-ui/icons/NavigateBefore";

const PrevNextPaginator = (props) => {
  const { onPrevButtonClick, onNextButtonClick } = props;
  return (
    <div>
      <span style={styles.text}>
        {textForTotal(props)}
        {textForPaged(props)}
      </span>
      <IconButton
        disabled={!paginatorHasPreviousPage(props)}
        onClick={onPrevButtonClick}
      >
        <BeforeIcon />
      </IconButton>
      <IconButton
        disabled={!paginatorHasNextPage(props)}
        onClick={onNextButtonClick}
      >
        <NextIcon />
      </IconButton>
    </div>
  );
};

function paginatorHasPreviousPage({ page }) {
  return page > 1;
}

function paginatorHasNextPage({ page, totalPages }) {
  return page < totalPages;
}

function textForTotal({ totalElements }) {
  return `全 ${totalElements} 件`;
}

function textForPaged({ page, size, totalElements }) {
  if (size >= totalElements) return null;
  const from = (page - 1) * size + 1;
  const limit = page * size;
  const to = limit > totalElements ? totalElements : limit;
  if (from == to) return `中 ${to} 件目`;
  return `中 ${from} 〜 ${to} 件目`;
}

const styles = {
  text: {
    fontSize: "small",
  },
};

PrevNextPaginator.propTypes = {
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  onPrevButtonClick: PropTypes.func.isRequired,
  onNextButtonClick: PropTypes.func.isRequired,
};

export default PrevNextPaginator;
