import React from "react";
import PropTypes from "prop-types";
import {
  serviceShubetsuCategoryTypeValueOfHokengai,
  serviceShubetsuCategoryTypeValueOf,
} from "../../../app/domain/jigyosho/ServiceShubetsuCategoryType";

class ServicePageReprintComment extends React.Component {
  render() {
    if (this.serviceIsHokengai()) return null;
    return <div className="p-office-reprint-comment">{this.message()}</div>;
  }

  serviceIsHokengai() {
    const { serviceShubetsuCategoryCode: code } = this.props.contents;
    return serviceShubetsuCategoryTypeValueOfHokengai().find(
      (it) => it === serviceShubetsuCategoryTypeValueOf(code)
    );
  }

  serviceHasBeenUpdated() {
    const { userUpdatedAt } = this.props.contents;
    return !userUpdatedAt.match(/--/);
  }

  message() {
    return this.serviceHasBeenUpdated()
      ? ""
      : "※ 上記の情報は「介護サービス情報公表システム」より転載しています。";
  }
}

ServicePageReprintComment.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default ServicePageReprintComment;
