import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { request as requestSharedFolder } from "./actions";

export function withSharedFolder(OriginalComponent) {
  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(
    class SharedFolderRequiredComponent extends React.Component {
      static propTypes = {
        request: PropTypes.func.isRequired,
      };

      componentDidMount() {
        this.props.request();
      }

      render() {
        const originalComponentProps = { ...this.props };
        delete originalComponentProps.request;
        return <OriginalComponent {...originalComponentProps} />;
      }
    }
  );
  function mapStateToProps() {
    return {};
  }
  function mapDispatchToProps(dispatch) {
    return {
      request: () => {
        dispatch(requestSharedFolder());
      },
    };
  }
}
