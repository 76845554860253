export default class SearchComparator {
  constructor(search1, search2) {
    this.search1 = search1;
    this.search2 = search2;
  }

  different(options = {}) {
    options = {
      ...{
        except: [],
      },
      ...options,
    };
    if (this.search1 === undefined) return true;
    if (this.search2 === undefined) return true;
    const searchParams1 = new URLSearchParams(this.search1);
    const searchParams2 = new URLSearchParams(this.search2);
    options.except.forEach((name) => searchParams1.delete(name));
    options.except.forEach((name) => searchParams2.delete(name));
    searchParams1.sort();
    searchParams2.sort();
    return searchParams1.toString() !== searchParams2.toString();
  }
}
