export function mergeStyleToProps(style, props) {
  return {
    ...props,
    style: {
      ...props.style,
      ...style,
    },
  };
}

export function removePropertyFromProps(property, props) {
  const ret = { ...props };
  delete ret[property];
  return ret;
}
