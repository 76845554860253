import ActionType from "./ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";

const initialState = {
  maintenance: false,
  message: null,
};

const reducers = reducersMapOf({
  [ActionType.SET_MAINTENANCE]: function (state, action) {
    return {
      ...state,
      maintenance: true,
      message: action.payload.message,
    };
  },
  [ActionType.CLEAR_MAINTENANCE]: function () {
    return initialState;
  },
});

export default reducers.createReducer(initialState);
