import React from "react";
import PrintTableHead from "../../../../app/ui/print/PrintTableHead";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const KatsudoKayoinobaTableHead = () => {
  return (
    <PrintTableHead height="80px">
      <PrintTableNode width="105px">
        店舗・団体名
        <div className="c-table-frame__updated-at">(最終更新日)</div>
      </PrintTableNode>
      <PrintTableNode width="150px">住所</PrintTableNode>
      <PrintTableNode width="112px">TEL</PrintTableNode>
      <PrintTableNode width="145px">活動内容種類</PrintTableNode>
      <PrintTableNode width="90px">営業・開催日</PrintTableNode>
      <PrintTableNode width="120px">営業・開催時間</PrintTableNode>
      <PrintTableNode width="125px">提供・対象地域</PrintTableNode>
      <PrintTableNode width="100px">料金</PrintTableNode>
    </PrintTableHead>
  );
};

export default KatsudoKayoinobaTableHead;
