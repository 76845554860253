import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import JigyoshoJohoForm from "./JigyoshoJohoForm";
import EigyoJikanForm from "./EigyoJikanForm";
import TsushokeiServiceJigyoshoJohoForm from "./TsushokeiServiceJigyoshoJohoForm";
import TsushokeiServiceJigyoshoEigyoJikanForm from "./TsushokeiServiceJigyoshoEigyoJikanForm";
import SogeiForm from "./SogeiForm";
import TsushokeiServiceJigyoshoAkiJoho from "./TsushokeiServiceJigyoshoAkiJoho";
import ShokujiForm from "./ShokujiForm";
import NyuyokuForm from "./NyuyokuForm";
import HaisetsuForm from "./HaisetsuForm";
import StaffForm from "./StaffForm";
import RiyoshaForm from "./RiyoshaForm";
import RehabilitationForm from "./RehabilitationForm";
import RyokinkasanjohoForm from "./RyokinkasanjohoForm";
import TaiokanoNaShojoForm from "./TaiokanoNaShojoForm";
import ServiceTeikyoJikanForm from "./ServiceTeikyoJikanForm";
import RecreationForm from "./RecreationForm";
import TsushokeiServiceJigyoshoSonotaForm from "./TsushokeiServiceJigyoshoSonotaForm";
import TatemonoSetsubiBihinForm from "./TatemonoSetsubiBihinForm";
import GaibuRenkeiForm from "./GaibuRenkeiForm";
import HouseworkForm from "./HouseworkForm";
import HouseworkMenuForm from "./HouseworkMenuForm";
import HomeProvideOtherForm from "./HomeProvideOtherForm";
import HomeProvideOtherMenuForm from "./HomeProvideOtherMenuForm";
import BusinessForm from "./BusinessForm";
import PromotionForm from "./PromotionForm";
import BusinessProvideOtherForm from "./BusinessProvideOtherForm";
import BusinessProvideOtherMenuForm from "./BusinessProvideOtherMenuForm";
import TaxiMenuForm from "./TaxiMenuForm";
import TaxiForm from "./TaxiForm";
import TaxiOptionForm from "./TaxiOptionForm";
import DeliveryForm from "./DeliveryForm";
import DeliveryMenuForm from "./DeliveryMenuForm";
import DeliveryOptionForm from "./DeliveryOptionForm";
import BarberForm from "./BarberForm";
import BarberMenuForm from "./BarberMenuForm";
import MementoForm from "./MementoForm";
import MementoMenuForm from "./MementoMenuForm";
import FuneralForm from "./FuneralForm";
import FuneralMenuForm from "./FuneralMenuForm";
import WatchForm from "./WatchForm";
import WatchMenuForm from "./WatchMenuForm";
import WatchOptionForm from "./WatchOptionForm";
import HomonkeiServiceJigyoshoForm from "./HomonkeiServiceJigyoshoForm";
import HomonkeiServiceEigyoJikanForm from "./HomonkeiServiceEigyoJikanForm";
import HomonkeiServiceAkijohoForm from "./HomonkeiServiceAkijohoForm";
import HomonkeiServiceServiceTeikyoForm from "./HomonkeiServiceServiceTeikyoForm";
import HomonkeiServiceKaigoForm from "./HomonkeiServiceKaigoForm";
import HomonkeiServiceKangoForm from "./HomonkeiServiceKangoForm";
import HomonkeiServiceNyuyokuForm from "./HomonkeiServiceNyuyokuForm";
import HomonkeiServiceRehabilitationForm from "./HomonkeiServiceRehabilitationForm";
import HomonkeiServiceTaiokanoShojoForm from "./HomonkeiServiceTaiokanoShojoForm";
import HomonkeiServiceRiyoshaForm from "./HomonkeiServiceRiyoshaForm";
import HomonkeiServiceStaffForm from "./HomonkeiServiceStaffForm";
import HomonkeiServiceBihinForm from "./HomonkeiServiceBihinForm";
import HomonkeiServiceGaibuRenkeiForm from "./HomonkeiServiceGaibuRenkeiForm";
import HomonkeiServiceRyokinkasanForm from "./HomonkeiServiceRyokinkasanForm";
import HomonkeiServiceHokengaiServiceForm from "./HomonkeiServiceHokengaiServiceForm";
import KyotakukaigoshienjigyoshoEigyoJikanForm from "./KyotakukaigoshienjigyoshoEigyoJikanForm";
import KyotakukaigoshienjigyoshoServiceForm from "./KyotakukaigoshienjigyoshoServiceForm";
import KyotakukaigoshienjigyoshoStaffForm from "./KyotakukaigoshienjigyoshoStaffForm";
import KyotakukaigoshienjigyoshoRyokinkasanForm from "./KyotakukaigoshienjigyoshoRyokinkasanForm";
import ShortstayJigyoshoForm from "./ShortstayJigyoshoForm";
import ShortstayEigyoJikanForm from "./ShortstayEigyoJikanForm";
import ShortstaySogeiForm from "./ShortstaySogeiForm";
import ShortstayRehabilitationForm from "./ShortstayRehabilitationForm";
import ShortstayRecreationForm from "./ShortstayRecreationForm";
import ShortstayShokujiForm from "./ShortstayShokujiForm";
import ShortstayNyuyokuForm from "./ShortstayNyuyokuForm";
import ShortstayHaisetsuForm from "./ShortstayHaisetsuForm";
import ShortstayKyoshitsuForm from "./ShortstayKyoshitsuForm";
import ShortstaySonotaServiceForm from "./ShortstaySonotaServiceForm";
import ShortstayTaiokanoShojoForm from "./ShortstayTaiokanoShojoForm";
import ShortstayRiyoshaForm from "./ShortstayRiyoshaForm";
import ShortstayStaffForm from "./ShortstayStaffForm";
import ShortstayRyokinkasanForm from "./ShortstayRyokinkasanForm";
import ShortstayRyokinmeyasuForm from "./ShortstayRyokinmeyasuForm";
import ShortstayMoshikomiForm from "./ShortstayMoshikomiForm";
import ShortstayTatemonoSetsubiBihinForm from "./ShortstayTatemonoSetsubiBihinForm";
import ShortstayGaibuRenkeiForm from "./ShortstayGaibuRenkeiForm";
import MimamoriSuishinJigyoForm from "./MimamoriSuishinJigyoForm";
import MimamoriSuishinJigyoDetailForm from "./MimamoriSuishinJigyoDetailForm";
import KyotakukaigoshienjigyoshoJohoForm from "./KyotakukaigoshienjigyoshoJohoForm";

const JigyoshoAttributeFormType = {
  // 保険内（標準）
  jigyoshoJoho: {
    target: JigyoshoAttributeType.jigyoshoJoho,
    component: JigyoshoJohoForm,
  },
  eigyoJikan: {
    target: JigyoshoAttributeType.eigyoJikan,
    component: EigyoJikanForm,
  },

  // 保険内（訪問系サービス）
  homonkeiServiceJigyosho: {
    target: JigyoshoAttributeType.homonkeiServiceJigyosho,
    component: HomonkeiServiceJigyoshoForm,
  },
  homonkeiServiceEigyoJikan: {
    target: JigyoshoAttributeType.homonkeiServiceEigyoJikan,
    component: HomonkeiServiceEigyoJikanForm,
  },
  homonkeiServiceAkijoho: {
    target: JigyoshoAttributeType.homonkeiServiceAkijoho,
    component: HomonkeiServiceAkijohoForm,
  },
  homonkeiServiceServiceTeikyo: {
    target: JigyoshoAttributeType.homonkeiServiceServiceTeikyo,
    component: HomonkeiServiceServiceTeikyoForm,
  },
  homonkeiServiceKaigo: {
    target: JigyoshoAttributeType.homonkeiServiceKaigo,
    component: HomonkeiServiceKaigoForm,
  },
  homonkeiServiceKango: {
    target: JigyoshoAttributeType.homonkeiServiceKango,
    component: HomonkeiServiceKangoForm,
  },
  homonkeiServiceNyuyoku: {
    target: JigyoshoAttributeType.homonkeiServiceNyuyoku,
    component: HomonkeiServiceNyuyokuForm,
  },
  homonkeiServiceRehabilitation: {
    target: JigyoshoAttributeType.homonkeiServiceRehabilitation,
    component: HomonkeiServiceRehabilitationForm,
  },
  homonkeiServiceHokengaiService: {
    target: JigyoshoAttributeType.homonkeiServiceHokengaiService,
    component: HomonkeiServiceHokengaiServiceForm,
  },
  homonkeiServiceTaiokanoShojo: {
    target: JigyoshoAttributeType.homonkeiServiceTaiokanoShojo,
    component: HomonkeiServiceTaiokanoShojoForm,
  },
  homonkeiServiceRiyosha: {
    target: JigyoshoAttributeType.homonkeiServiceRiyosha,
    component: HomonkeiServiceRiyoshaForm,
  },
  homonkeiServiceStaff: {
    target: JigyoshoAttributeType.homonkeiServiceStaff,
    component: HomonkeiServiceStaffForm,
  },
  homonkeiServiceRyokinkasan: {
    target: JigyoshoAttributeType.homonkeiServiceRyokinkasan,
    component: HomonkeiServiceRyokinkasanForm,
  },
  homonkeiServiceBihin: {
    target: JigyoshoAttributeType.homonkeiServiceBihin,
    component: HomonkeiServiceBihinForm,
  },
  homonkeiServiceGaibuRenkei: {
    target: JigyoshoAttributeType.homonkeiServiceGaibuRenkei,
    component: HomonkeiServiceGaibuRenkeiForm,
  },

  // 保険内（通所系サービス）
  tsushokeiServiceJigyoshoJoho: {
    target: JigyoshoAttributeType.tsushokeiServiceJigyoshoJoho,
    component: TsushokeiServiceJigyoshoJohoForm,
  },
  tsushokeiServiceJigyoshoEigyoJikan: {
    target: JigyoshoAttributeType.tsushokeiServiceJigyoshoEigyoJikan,
    component: TsushokeiServiceJigyoshoEigyoJikanForm,
  },
  tsushokeiServiceJigyoshoAkiJoho: {
    target: JigyoshoAttributeType.tsushokeiServiceJigyoshoAkiJoho,
    component: TsushokeiServiceJigyoshoAkiJoho,
  },
  serviceTeikyoJikan: {
    target: JigyoshoAttributeType.serviceTeikyoJikan,
    component: ServiceTeikyoJikanForm,
  },
  sogei: {
    target: JigyoshoAttributeType.sogei,
    component: SogeiForm,
  },
  rehabilitation: {
    target: JigyoshoAttributeType.rehabilitation,
    component: RehabilitationForm,
  },
  recreation: {
    target: JigyoshoAttributeType.recreation,
    component: RecreationForm,
  },
  shokuji: {
    target: JigyoshoAttributeType.shokuji,
    component: ShokujiForm,
  },
  nyuyoku: {
    target: JigyoshoAttributeType.nyuyoku,
    component: NyuyokuForm,
  },
  haisetsu: {
    target: JigyoshoAttributeType.haisetsu,
    component: HaisetsuForm,
  },
  taiokanoNaShojo: {
    target: JigyoshoAttributeType.taiokanoNaShojo,
    component: TaiokanoNaShojoForm,
  },
  riyosha: {
    target: JigyoshoAttributeType.riyosha,
    component: RiyoshaForm,
  },
  staff: {
    target: JigyoshoAttributeType.staff,
    component: StaffForm,
  },
  ryokinKasanJoho: {
    target: JigyoshoAttributeType.ryokinKasanJoho,
    component: RyokinkasanjohoForm,
  },
  tatemonoSetsubiBihin: {
    target: JigyoshoAttributeType.tatemonoSetsubiBihin,
    component: TatemonoSetsubiBihinForm,
  },
  gaibuRenkei: {
    target: JigyoshoAttributeType.gaibuRenkei,
    component: GaibuRenkeiForm,
  },
  tsushokeiServiceJigyoshoSonota: {
    target: JigyoshoAttributeType.tsushokeiServiceJigyoshoSonota,
    component: TsushokeiServiceJigyoshoSonotaForm,
  },

  // ショートステイ
  shortstayJigyosho: {
    target: JigyoshoAttributeType.shortstayjigyosho,
    component: ShortstayJigyoshoForm,
  },
  shortstayEigyoJikan: {
    target: JigyoshoAttributeType.shortstayEigyoJikan,
    component: ShortstayEigyoJikanForm,
  },
  shortstaySogei: {
    target: JigyoshoAttributeType.shortstaySogei,
    component: ShortstaySogeiForm,
  },
  shortstayRehabilitation: {
    target: JigyoshoAttributeType.shortstayRehabilitation,
    component: ShortstayRehabilitationForm,
  },
  shortstayRecreation: {
    target: JigyoshoAttributeType.shortstayRecreation,
    component: ShortstayRecreationForm,
  },
  shortstayShokuji: {
    target: JigyoshoAttributeType.shortstayShokuji,
    component: ShortstayShokujiForm,
  },
  shortstayNyuyoku: {
    target: JigyoshoAttributeType.shortstayNyuyoku,
    component: ShortstayNyuyokuForm,
  },
  shortstayHaisetsu: {
    target: JigyoshoAttributeType.shortstayHaisetsu,
    component: ShortstayHaisetsuForm,
  },
  shortstayKyoshitsu: {
    target: JigyoshoAttributeType.shortstayKyoshitsu,
    component: ShortstayKyoshitsuForm,
  },
  shortstayTaiokanoShojo: {
    target: JigyoshoAttributeType.shortstayTaiokanoShojo,
    component: ShortstayTaiokanoShojoForm,
  },
  shortstayRiyosha: {
    target: JigyoshoAttributeType.shortstayRiyosha,
    component: ShortstayRiyoshaForm,
  },
  shortstayStaff: {
    target: JigyoshoAttributeType.shortstayStaff,
    component: ShortstayStaffForm,
  },
  shortstayRyokinkasan: {
    target: JigyoshoAttributeType.shortstayRyokinkasan,
    component: ShortstayRyokinkasanForm,
  },
  shortstayRyokinmeyasu: {
    target: JigyoshoAttributeType.shortstayRyokinmeyasu,
    component: ShortstayRyokinmeyasuForm,
  },
  shortstayMoshikomi: {
    target: JigyoshoAttributeType.shortstayMoshikomi,
    component: ShortstayMoshikomiForm,
  },
  shortstayTatemonoSetsubiBihin: {
    target: JigyoshoAttributeType.shortstayTatemonoSetsubiBihin,
    component: ShortstayTatemonoSetsubiBihinForm,
  },
  shortstayGaibuRenkei: {
    target: JigyoshoAttributeType.shortstayGaibuRenkei,
    component: ShortstayGaibuRenkeiForm,
  },
  shortstaySonotaService: {
    target: JigyoshoAttributeType.shortstaySonotaService,
    component: ShortstaySonotaServiceForm,
  },

  // 居宅介護支援
  kyotakukaigoshienjigyoshoJoho: {
    target: JigyoshoAttributeType.kyotakukaigoshienjigyoshoJoho,
    component: KyotakukaigoshienjigyoshoJohoForm,
  },
  kyotakukaigoshienjigyoshoEigyoJikan: {
    target: JigyoshoAttributeType.kyotakukaigoshienjigyoshoEigyoJikan,
    component: KyotakukaigoshienjigyoshoEigyoJikanForm,
  },
  kyotakukaigoshienjigyoshoService: {
    target: JigyoshoAttributeType.kyotakukaigoshienjigyoshoService,
    component: KyotakukaigoshienjigyoshoServiceForm,
  },
  kyotakukaigoshienjigyoshoStaff: {
    target: JigyoshoAttributeType.kyotakukaigoshienjigyoshoStaff,
    component: KyotakukaigoshienjigyoshoStaffForm,
  },
  kyotakukaigoshienjigyoshoRyokinkasan: {
    target: JigyoshoAttributeType.kyotakukaigoshienjigyoshoRyokinkasan,
    component: KyotakukaigoshienjigyoshoRyokinkasanForm,
  },
  business: {
    target: JigyoshoAttributeType.business,
    component: BusinessForm,
  },
  promotion: {
    target: JigyoshoAttributeType.promotion,
    component: PromotionForm,
  },
  delivery: {
    target: JigyoshoAttributeType.delivery,
    component: DeliveryForm,
  },
  deliveryMenu: {
    target: JigyoshoAttributeType.deliveryMenu,
    component: DeliveryMenuForm,
  },
  deliveryOption: {
    target: JigyoshoAttributeType.deliveryOption,
    component: DeliveryOptionForm,
  },
  taxi: {
    target: JigyoshoAttributeType.taxi,
    component: TaxiForm,
  },
  taxiMenu: {
    target: JigyoshoAttributeType.taxiMenu,
    component: TaxiMenuForm,
  },
  taxiOption: {
    target: JigyoshoAttributeType.taxiOption,
    component: TaxiOptionForm,
  },
  housework: {
    target: JigyoshoAttributeType.housework,
    component: HouseworkForm,
  },
  houseworkMenu: {
    target: JigyoshoAttributeType.houseworkMenu,
    component: HouseworkMenuForm,
  },
  businessProvideOther: {
    target: JigyoshoAttributeType.businessProvideOther,
    component: BusinessProvideOtherForm,
  },
  businessProvideOtherMenu: {
    target: JigyoshoAttributeType.businessProvideOtherMenu,
    component: BusinessProvideOtherMenuForm,
  },
  homeProvideOther: {
    target: JigyoshoAttributeType.homeProvideOther,
    component: HomeProvideOtherForm,
  },
  homeProvideOtherMenu: {
    target: JigyoshoAttributeType.homeProvideOtherMenu,
    component: HomeProvideOtherMenuForm,
  },
  barber: {
    target: JigyoshoAttributeType.barber,
    component: BarberForm,
  },
  barberMenu: {
    target: JigyoshoAttributeType.barberMenu,
    component: BarberMenuForm,
  },
  memento: {
    target: JigyoshoAttributeType.memento,
    component: MementoForm,
  },
  mementoMenu: {
    target: JigyoshoAttributeType.mementoMenu,
    component: MementoMenuForm,
  },
  funeral: {
    target: JigyoshoAttributeType.funeral,
    component: FuneralForm,
  },
  funeralMenu: {
    target: JigyoshoAttributeType.funeralMenu,
    component: FuneralMenuForm,
  },
  Watch: {
    target: JigyoshoAttributeType.watch,
    component: WatchForm,
  },
  WatchMenu: {
    target: JigyoshoAttributeType.watchMenu,
    component: WatchMenuForm,
  },
  WatchOption: {
    target: JigyoshoAttributeType.watchOption,
    component: WatchOptionForm,
  },
  // 見守り推進事業
  mimamoriSuishinJigyo: {
    target: JigyoshoAttributeType.mimamoriSuishinJigyo,
    component: MimamoriSuishinJigyoForm,
  },
  mimamoriSuishinJigyoDetail: {
    target: JigyoshoAttributeType.mimamoriSuishinJigyoDetail,
    component: MimamoriSuishinJigyoDetailForm,
  },
};

export function jigyoshoAttributeFormTypeValueOf(name) {
  return Object.keys(JigyoshoAttributeFormType)
    .map((key) => JigyoshoAttributeFormType[key])
    .find((type) => type.target.name == name);
}

export default JigyoshoAttributeFormType;
