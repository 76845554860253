import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
} from "../../app/fundamental/request";
import { openMessage } from "../notification-message/actions";

function reset() {
  return {
    type: ActionType.RESET,
  };
}

export function requestDetail(id) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getDetail(dispatch, id);
  };
}

export function submitToRemove(
  id,
  callback = () => {},
  callbackOnFailure = () => {}
) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMOVE_REQUEST,
    });

    const url = ApiPath.api_job_offer_id.replace(/:id/, id);

    const onSuccess = (json) => {
      dispatch({
        type: ActionType.REMOVE_REQUEST_FINISHED,
        payload: json,
      });
      dispatch(openMessage("求人情報を削除しました。"));
      setTimeout(() => callback(json));
    };
    const responseHandler = defaultResponseHandler(dispatch, onSuccess);
    responseHandler.onFailure = () => {
      dispatch({
        type: ActionType.REMOVE_REQUEST_FAILED,
      });
      dispatch(openMessage("求人情報を削除できませんでした。"));
      callbackOnFailure();
    };
    ApiRequest.delete(url).request(
      responseHandler,
      defaultUnauthorizedHander(dispatch)
    );
  };
}

export function submitToApprovalRequest(
  id,
  callback = () => {},
  callbackOnFailure = () => {}
) {
  return (dispatch) => {
    dispatch({
      type: ActionType.APPROVAL_REQUEST_REQUEST,
    });

    const url = ApiPath.api_job_offer_id_approval_request.replace(/:id/, id);
    const onSuccess = (json) => {
      dispatch({
        type: ActionType.APPROVAL_REQUEST_REQUEST_FINISHED,
        payload: json,
      });
      dispatch(openMessage("求人情報の掲載を申請しました。"));
      setTimeout(() => callback(json));
    };
    const responseHandler = defaultResponseHandler(dispatch, onSuccess);
    responseHandler.onFailure = (json) => {
      dispatch({
        type: ActionType.APPROVAL_REQUEST_REQUEST_FAILED,
      });
      let message =
        json.requiredFields.length === 0
          ? "申請できませんでした。"
          : `求人情報が不完全なため申請できません。以下の内容を登録してください。\n${Object.values(
              json.requiredFields
            )
              .map((line) => `・${line}`)
              .join("\n")}`;
      dispatch(openMessage(message));
      callbackOnFailure();
    };
    ApiRequest.delete(url).request(
      responseHandler,
      defaultUnauthorizedHander(dispatch)
    );
  };
}

export function submitToClose(
  id,
  callback = () => {},
  callbackOnFailure = () => {}
) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CLOSE_REQUEST,
    });

    const url = ApiPath.api_job_offer_id_close.replace(/:id/, id);

    const onSuccess = (json) => {
      dispatch({
        type: ActionType.CLOSE_REQUEST_FINISHED,
        payload: json,
      });
      dispatch(openMessage("求人情報の掲載を終了しました。"));
      setTimeout(() => callback(json));
    };
    const responseHandler = defaultResponseHandler(dispatch, onSuccess);
    responseHandler.onFailure = () => {
      dispatch({
        type: ActionType.CLOSE_REQUEST_FAILED,
      });
      dispatch(openMessage("求人情報の掲載を終了できませんでした。"));
      callbackOnFailure();
    };
    ApiRequest.delete(url).request(
      responseHandler,
      defaultUnauthorizedHander(dispatch)
    );
  };
}

export function submitToApproved(
  id,
  callback = () => {},
  callbackOnFailure = () => {}
) {
  return (dispatch) => {
    dispatch({
      type: ActionType.APPROVED_REQUEST,
    });

    const url = ApiPath.api_job_offer_id_approved.replace(/:id/, id);
    const onSuccess = (json) => {
      dispatch({
        type: ActionType.APPROVED_REQUEST_FINISHED,
        payload: json,
      });
      dispatch(openMessage("求人情報の掲載を承認しました。"));
      setTimeout(() => callback(json));
    };
    const responseHandler = defaultResponseHandler(dispatch, onSuccess);
    responseHandler.onFailure = () => {
      dispatch({
        type: ActionType.APPROVED_REQUEST_FAILED,
      });
      dispatch(openMessage("求人情報の掲載を承認できませんでした。"));
      callbackOnFailure();
    };
    ApiRequest.delete(url).request(
      responseHandler,
      defaultUnauthorizedHander(dispatch)
    );
  };
}

function getDetail(dispatch, id) {
  const url = ApiPath.api_job_offer_id.replace(/:id/, id);

  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: json,
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}
