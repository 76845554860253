import React, { Fragment } from "react";
import PropTypes from "prop-types";

const RyokinKango = ({ contents }) => {
  const { homonkeiServiceRyokin: ryokin } = contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-title">介護報酬加算</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>共通</th>
              <td>{ryokin.kaigohoshukasanKyotsu.join("、")}</td>
            </tr>
            <tr>
              <th>要介護のみ</th>
              <td>{ryokin.kaigohoshukasanYokaigo.join("、")}</td>
            </tr>
            <tr>
              <th>予防のみ</th>
              <td>{ryokin.kaigohoshukasanYobo.join("、")}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="p-office-body-title">利用料金の目安</div>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-title">看護師の訪問</div>
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">要支援1〜2</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">20分未満</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI1Yoshien}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">30分未満</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI2Yoshien}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    30分〜60分
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI3Yoshien}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    60分〜90分
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI4Yoshien}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="p-office-body-title">リハビリ職の訪問</div>
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">要支援1〜2</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">20分</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI5Yoshien}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">60分</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI52koeruYoshien}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="p-office-body-sep__right">
          <div className="p-office-body-title">
            <span>
              <br />
            </span>
          </div>
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">要介護1〜5</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">20分未満</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI1Yokaigo}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">30分未満</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI2Yokaigo}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    30分〜60分
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI3Yokaigo}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    60分〜90分
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI4Yokaigo}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="p-office-body-title">
            <span>
              <br />
            </span>
          </div>
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">要介護1〜5</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">20分</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI5Yokaigo}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">60分</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.homonkangoI52koeruYokaigo}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

RyokinKango.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default RyokinKango;
