import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import Mount from "../../../../../app/ui/layout/Mount";

const ShokujiTable = ({ contents, detail }) => {
  const {
    blocks: { shortstayShokuji: shokuji },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <Mount if={!detail}>
              <tr>
                <th>調理場所</th>
                <td>{shokuji.chori}</td>
              </tr>
              <tr>
                <th>食事提供時間</th>
                <td>
                  {shokuji.teikyoJikan}
                  <br />
                </td>
              </tr>
              <tr>
                <th>備考（料金）</th>
                <td>{shokuji.biko}</td>
              </tr>
            </Mount>
            <Mount if={detail}>
              <tr>
                <th>朝食</th>
                <td>{shokuji.choshoku}</td>
              </tr>
              <tr>
                <th>昼食</th>
                <td>{shokuji.chushoku}</td>
              </tr>
              <tr>
                <th>おやつ</th>
                <td>{shokuji.oyatsu}</td>
              </tr>
              <tr>
                <th>夕食</th>
                <td>{shokuji.yushoku}</td>
              </tr>
              <tr>
                <th>備考（料金）</th>
                <td>{shokuji.biko}</td>
              </tr>
              <tr>
                <th>嚥下食対応</th>
                <td>{shokuji.engeshokutaio}</td>
              </tr>
              <tr>
                <th>制限食対応</th>
                <td>{shokuji.seigenshokutaio}</td>
              </tr>
              <tr>
                <th>栄養士</th>
                <td>{shokuji.eiyoshi}</td>
              </tr>
              <tr>
                <th>調理場所</th>
                <td>{shokuji.chori}</td>
              </tr>
              <tr>
                <th>その他</th>
                <td>{shokuji.sonota.join("、")}</td>
              </tr>
              <tr>
                <th>備考（オプション）</th>
                <td>{shokuji.optionBiko}</td>
              </tr>
            </Mount>
          </tbody>
        </table>

        {goToJigyoshoAttributeFormButton(
          contents,
          JigyoshoAttributeType.shortstayShokuji
        )}
      </div>
    </Fragment>
  );
};

ShokujiTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default ShokujiTable;
