import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import { OPTIONS_AVAILABILITY } from "../../../../../app/ui/form";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  MASTER_DATA_BUNDLE_FOR_SHORTSTAY,
  withMasterData,
} from "../../../../../state/master-data";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import HorizontalSubHeadLabelLayout from "../../../../../app/ui/form/HorizontalSubHeadLabelLayout";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";

class ShortstaySogeiForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderServiceTeikyoChiiki()}
        {this.renderSogei()}
      </Fragment>
    );
  }

  renderServiceTeikyoChiiki() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="サービス提供地域">
        <HorizontalLabelLayout labelText="サービス提供地域" required>
          <Textarea name="serviceTeikyoChiiki" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="serviceTeikyoChiikiBiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderSogei() {
    const { bindingProps, masterData } = this.props;
    const disabled = !OptionsRadio.checked(
      bindingProps.form.getByName("sogeiServiceUmu")
    );
    return (
      <FormGrouping title="送迎">
        <HorizontalLabelLayout labelText="送迎サービスの有無" required>
          <OptionsRadio
            name="sogeiServiceUmu"
            split={6}
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="車両台数">
          <ShortInputWidth>
            <HorizontalSubHeadLabelLayout labelText="一般車両">
              <UnitLayout unitText="台">
                <InputTypeNumber
                  name="ippansharyo"
                  disabled={disabled}
                  {...bindingProps}
                />
              </UnitLayout>
            </HorizontalSubHeadLabelLayout>
          </ShortInputWidth>
          <ShortInputWidth>
            <HorizontalSubHeadLabelLayout labelText="リフト車両">
              <UnitLayout unitText="台">
                <InputTypeNumber
                  name="liftSharyo"
                  disabled={disabled}
                  {...bindingProps}
                />
              </UnitLayout>
            </HorizontalSubHeadLabelLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="送迎時居宅内介護" required>
          <OptionsRadio
            name="sogeijiKyotakunaikaigo"
            split={6}
            options={OPTIONS_AVAILABILITY}
            disabled={disabled}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="送迎車ロゴ" required>
          <OptionsRadio
            name="sogeishaLogoCode"
            split={6}
            options={masterData.shortstay_SogeishaLogo}
            disabled={disabled}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="送迎時間個別対応" required>
          <OptionsRadio
            name="sogeijikanKobetsutaio"
            split={6}
            options={OPTIONS_AVAILABILITY}
            disabled={disabled}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="送迎時車椅子貸出" required>
          <OptionsRadio
            name="sogeijiKurumaisukashidashi"
            split={6}
            options={OPTIONS_AVAILABILITY}
            disabled={disabled}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="biko" disabled={disabled} {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

ShortstaySogeiForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SHORTSTAY)(
  ShortstaySogeiForm
);
