import React from "react";
import PropTypes from "prop-types";
import Boundary from "../../../../app/ui/layout/Boundary";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import BackIcon from "@material-ui/icons/KeyboardBackspace";
import connect from "react-redux/es/connect/connect";
import Pathname from "../../../../app/naming/Pathname";
import { push } from "react-router-redux";
import {
  changeForm,
  requestEditForm,
  submitToEdit,
} from "../../../../state/job-offer-jigyosho-form/actions";
import JobOfferJigyoshoForm from "./JobOfferJigyoshoForm";
import Parametername from "../../../../app/naming/Parametername";

class JobOfferJigyoshoEditPage extends React.Component {
  constructor(props) {
    super(props);

    this.jigyoshoId = props.match.params.jigyoshoId;

    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  componentDidMount() {
    this.props.loadForm(this.jigyoshoId);
  }

  handleBackButtonClick() {
    const searchParams = new URLSearchParams(window.location.search);
    const pathname =
      searchParams.get(Parametername.pathname) || Pathname.backoffice_job_offer;
    this.props.goto(pathname);
  }

  handleSaveButtonClick() {
    const { form } = this.props.jobOfferJigyoshoForm;
    const callback = () => this.handleBackButtonClick();
    this.props.submitForm(this.jigyoshoId, form, callback);
  }

  render() {
    return (
      <Boundary>
        <SideMargin>
          <BackOfficeHead>事業所共通情報の入力</BackOfficeHead>

          <BackOfficeNavigation>
            <FlatPrimaryButton onClick={this.handleBackButtonClick}>
              <BackIcon /> 戻る
            </FlatPrimaryButton>
          </BackOfficeNavigation>

          <JobOfferJigyoshoForm
            onCancel={this.handleBackButtonClick}
            onSubmit={this.handleSaveButtonClick}
            onChangeForm={this.props.changeForm}
          />
        </SideMargin>
      </Boundary>
    );
  }
}

JobOfferJigyoshoEditPage.propTypes = {
  match: PropTypes.object.isRequired,
  jobOfferJigyoshoForm: PropTypes.object.isRequired,
  loadForm: PropTypes.func.isRequired,
  changeForm: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  goto: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    jobOfferJigyoshoForm: state.jobOfferJigyoshoForm,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loadForm: (jigyoshoId) => {
      dispatch(requestEditForm(jigyoshoId));
    },
    changeForm: (name, value) => {
      dispatch(changeForm(name, value));
    },
    submitForm: (id, form, callback) => {
      dispatch(submitToEdit(id, form, callback));
    },
    goto: (pathname) => dispatch(push(pathname)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JobOfferJigyoshoEditPage);
