import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";

/**
 * 訪問系サービス提供地域・訪問手段フォーム.
 */
class HomonkeiServiceServiceTeikyoForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        {this.renderServiceTeikyoChiiki()}
        {this.renderHomonShudan()}
        {this.renderServiceTeikyoChiikigaiKotsuhi()}
        {this.renderBiko()}
      </Fragment>
    );
  }

  renderServiceTeikyoChiiki() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="サービス提供地域">
        <HorizontalLabelLayout labelText="サービス提供地域" required>
          <Textarea name="serviceTeikyoChiiki" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="サービス提供地域備考">
          <Textarea name="serviceTeikyoChiikiBiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderHomonShudan() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="訪問手段">
        <HorizontalLabelLayout labelText="訪問手段" recommended>
          <OptionsCheckbox
            name="homonShudan"
            options={masterData.homonkeiService_homonShudan}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderServiceTeikyoChiikigaiKotsuhi() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="サービス提供地域外交通費">
        <HorizontalLabelLayout labelText="値段">
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeText name="chiikigaiKotsuhiNedan" {...bindingProps} />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="説明">
          <InputTypeText name="chiikigaiKotsuhiSetsumei" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderBiko() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="備考">
        <HorizontalLabelLayout labelText="">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HomonkeiServiceServiceTeikyoForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceServiceTeikyoForm
);
