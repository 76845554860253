import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Boundary from "../../../app/ui/layout/Boundary";
import SideMargin from "../../../app/ui/layout/SideMargin";
import BackOfficeHead from "../../../app/ui/structure/BackOfficeHead";
import { Card } from "@material-ui/core";
import { connect } from "react-redux";
import { loadUser } from "../../../state/authentication/actions";

class JobOfferIntroductionPage extends React.Component {
  constructor(props) {
    super(props);

    this.handleApplyButtonClick = this.handleApplyButtonClick.bind(this);
  }

  componentDidMount() {
    this.props.loadUser();
  }

  handleApplyButtonClick() {
    // TODO
  }

  render() {
    return (
      <Fragment>
        <Boundary>
          <SideMargin>
            <BackOfficeHead>求人機能とは</BackOfficeHead>

            <Card>
              <SideMargin top bottom>
                <p>
                  「求人管理」とは、ミルモネットに登録されている事業所の方だけが利用出来る求人サービスです。
                  <br />
                  サービスのご利用開始には申し込みが必要となります。
                </p>
                <p>
                  申込みをされる方は
                  <a onClick={this.handleApplyButtonClick}>こちら</a>をクリック
                </p>
              </SideMargin>
            </Card>
          </SideMargin>
        </Boundary>
      </Fragment>
    );
  }
}

JobOfferIntroductionPage.propTypes = {
  loadUser: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(JobOfferIntroductionPage);

function mapDispatchToProps(dispatch) {
  return {
    loadUser: () => {
      dispatch(loadUser(null));
    },
  };
}
