import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";
import Parametername from "../../app/naming/Parametername";

function reset() {
  return {
    type: ActionType.RESET,
  };
}

// 一覧取得用
export function requestList() {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST_LIST,
    });
    getListForm(dispatch);
  };
}

// 入力フォーム取得用
export function requestForm(id) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST_FORM,
    });
    if (id > 0) {
      // 編集フォーム取得用
      getEditForm(dispatch, id);
    } else {
      // BlankForm取得用
      getBlankForm(dispatch);
    }
  };
}

// 詳細表示用
export function requestDetail(id) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST_DETAILS,
    });
    // 詳細表示用
    getDetailForm(dispatch, id);
  };
}

// 保存用
export function submit(form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    if (form.id > 0) {
      // 更新
      postToEditAttribute(dispatch, form, callback);
    } else {
      // 新規登録
      postToAdd(dispatch, form, callback);
    }
  };
}

// 削除用
export function submitToRemove(id, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMOVE,
      payload: {
        id,
      },
    });
    dispatch({
      type: ActionType.SUBMIT,
    });
    del(dispatch, id, callback);
  };
}

// ファイルアップロード用
export function uploadFiles(files, callback) {
  return (dispatch) => {
    const form = new FormData();
    files.forEach((file) => form.append(Parametername.files, file));
    postToUploadFiles(dispatch, form, callback);
  };
}

// 写真並べ替え
export function changeSort(name, id, index, callback = () => {}) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_SORT,
      payload: {
        name,
        id,
        index,
      },
    });
    setTimeout(callback);
  };
}

// form再設定
export function changeForm(name, value) {
  return {
    type: ActionType.CHANGE_FORM,
    payload: {
      name,
      value,
    },
  };
}

// 一覧取得用
function getListForm(dispatch) {
  const url = ApiPath.api_information;
  getList(dispatch, url);
}

// 編集フォーム取得用
function getEditForm(dispatch, id) {
  const url = ApiPath.api_information_id_editform.replace(/:id/, id);
  getForm(dispatch, url, "edit");
}

// BlankForm取得用
function getBlankForm(dispatch) {
  const url = ApiPath.api_information_blankform;
  getForm(dispatch, url, "blank");
}

// 詳細表示用
function getDetailForm(dispatch, id) {
  const url = ApiPath.api_information_id.replace(/:id/, id);
  get(dispatch, url);
}

function getList(dispatch, url) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_LIST_FINISHED,
      payload: {
        list: json.list,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function get(dispatch, url) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_DETAILS_FINISHED,
      payload: {
        details: json,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function getForm(dispatch, url, type) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FORM_FINISHED,
      payload: {
        form: json,
        type,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function postToAdd(dispatch, form, callback) {
  const url = ApiPath.api_information;
  post(dispatch, url, form, callback, "お知らせを新規に追加しました。");
}

function postToEditAttribute(dispatch, form, callback) {
  const url = ApiPath.api_information_id.replace(/:id/, form.id);
  post(dispatch, url, form, callback, "お知らせを更新しました。");
}

// ファイルアップロード
function postToUploadFiles(dispatch, form, callback) {
  const url = ApiPath.api_io_files;
  post(dispatch, url, form, callback, "アップロードしました。");
}

// ファイルソート

function post(dispatch, url, form, callback = () => {}, message) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(() => callback(json));
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}

function del(dispatch, id, callback = () => {}) {
  const url = ApiPath.api_information_id.replace(/:id/, id);
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(callback);
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.delete(url).request(
    withCompletionMessage(
      dispatch,
      "お知らせを削除しました。"
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}
