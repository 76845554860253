import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import TimePeriod from "../../../../../app/ui/form/TimePeriod";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Textarea from "../../../../../app/ui/form/Textarea";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import GridRow from "../../../../../app/ui/grid/GridRow";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import GridCell from "../../../../../app/ui/grid/GridCell";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";

class KyotakukaigoshienjigyoshoEigyoJikanForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderEigyoJikan()}
        {this.renderTeikyubi()}
      </Fragment>
    );
  }

  renderEigyoJikan() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="営業時間">
        <HorizontalLabelLayout labelText="平日" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="heijitsu"
                disabled={bindingProps.form.getByName("heijitsu_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="heijitsu_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="土曜" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="doyo"
                disabled={bindingProps.form.getByName("doyo_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="doyo_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="日曜" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="nichiyo"
                disabled={bindingProps.form.getByName("nichiyo_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="nichiyo_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="祝日" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="shukujitsu"
                disabled={bindingProps.form.getByName("shukujitsu_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="shukujitsu_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderTeikyubi() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="定休日等">
        <HorizontalLabelLayout labelText="365日対応">
          <InputTypeCheckbox
            name="allyearUkeire"
            labelText="はい"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="定休日" required>
          <InputTypeText name="teikyubi" {...bindingProps} />
          <StaticLabel>
            ※
            定休日にチェックを入れた曜日以外の定休日がない場合は、なしと入力してください
          </StaticLabel>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="留意事項">
          <Textarea name="ryuijiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

KyotakukaigoshienjigyoshoEigyoJikanForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default KyotakukaigoshienjigyoshoEigyoJikanForm;
