import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

const KaigoHosyuKasanBlock = ({ kaigohoshukasan, serviceShubetsuType }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="介護報酬加算" />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問介護}
        then={() => (
          <KaigoHosyuKasanBlock_homonkaigo kaigohoshukasan={kaigohoshukasan} />
        )}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問看護}
        then={() => (
          <KaigoHosyuKasanBlock_homonkango kaigohoshukasan={kaigohoshukasan} />
        )}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問リハビリテーション}
        then={() => (
          <KaigoHosyuKasanBlock_rehabilitation
            kaigohoshukasan={kaigohoshukasan}
          />
        )}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問入浴介護}
        then={() => (
          <KaigoHosyuKasanBlock_nyuyoku kaigohoshukasan={kaigohoshukasan} />
        )}
      />
      <Mount
        if={
          serviceShubetsuType ===
          ServiceShubetsuType.定期巡回_随時対応型訪問介護看護
        }
        then={() => (
          <KaigoHosyuKasanBlock_teikizuiji kaigohoshukasan={kaigohoshukasan} />
        )}
      />
    </Fragment>
  );
};

const KaigoHosyuKasanBlock_homonkaigo = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <Fragment>
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr>
              <th>サービス提供体制強化加算</th>
              <td>{kaigohoshu.serviceTeikyotaiseikyokakasan}</td>
              <th>緊急時訪問介護加算</th>
              <td>{kaigohoshu.kinkyujihomonkaigokasan}</td>
            </tr>
            <tr>
              <th>介護職員処遇改善加算</th>
              <td>{kaigohoshu.kaigoshokuinshogukaizenkasan}</td>
              <th>生活機能向上連携加算</th>
              <td>{kaigohoshu.seikatsukinokojorenkeikasan}</td>
            </tr>
            <tr>
              <th>特定事業所加算</th>
              <td>{kaigohoshu.tokuteijigyoshokasan}</td>
              <th>24時間通報対応加算</th>
              <td>{kaigohoshu.nijuyojikantsuhotaiokasan}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

const KaigoHosyuKasanBlock_homonkango = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <Fragment>
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr>
              <th>サービス提供体制強化加算</th>
              <td>{kaigohoshu.serviceTeikyotaiseikyokakasan}</td>
              <th>緊急時訪問看護加算</th>
              <td>{kaigohoshu.kinkyujihomonkangokasan}</td>
            </tr>
            <tr>
              <th>看護体制強化加算</th>
              <td>{kaigohoshu.kangotaiseikyokakasan}</td>
              <th>看護・介護職員連携強化加算</th>
              <td>{kaigohoshu.kangokaigoshokuinrenkeikyokakasan}</td>
            </tr>
            <tr>
              <th>ターミナルケア加算</th>
              <td>{kaigohoshu.terminalCareKasan}</td>
              <th></th>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

const KaigoHosyuKasanBlock_rehabilitation = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <Fragment>
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr>
              <th>サービス提供体制強化加算</th>
              <td>{kaigohoshu.serviceTeikyotaiseikyokakasan}</td>
              <th>短期集中リハビリ加算</th>
              <td>{kaigohoshu.tankishuchuRehabilitationKasan}</td>
            </tr>
            <tr>
              <th>リハビリマネジメント加算</th>
              <td>{kaigohoshu.rehabilitationManagementKasan}</td>
              <th>移行支援加算</th>
              <td>{kaigohoshu.ikoshienkasan}</td>
            </tr>
            <tr>
              <th>事業所評価加算</th>
              <td>{kaigohoshu.jigyoshohyokakasan}</td>
              <th></th>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

const KaigoHosyuKasanBlock_nyuyoku = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <Fragment>
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr>
              <th>サービス提供体制強化加算</th>
              <td>{kaigohoshu.serviceTeikyotaiseikyokakasan}</td>
              <th>介護職員処遇改善加算</th>
              <td>{kaigohoshu.kaigoshokuinshogukaizenkasan}</td>
            </tr>
            <tr>
              <th>認知症専門ケア加算</th>
              <td>{kaigohoshu.ninchishosemmonCareKasan}</td>
              <th></th>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

const KaigoHosyuKasanBlock_teikizuiji = (kaigohoshukasan) => {
  const { kaigohoshukasan: kaigohoshu } = kaigohoshukasan;
  return (
    <Fragment>
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr>
              <th>サービス提供体制強化加算</th>
              <td>{kaigohoshu.serviceTeikyotaiseikyokakasan}</td>
              <th>介護職員処遇改善加算</th>
              <td>{kaigohoshu.kaigoshokuinshogukaizenkasan}</td>
            </tr>
            <tr>
              <th>緊急時訪問看護加算</th>
              <td>{kaigohoshu.kinkyujihomonkangokasan}</td>
              <th>退院時共同指導加算</th>
              <td>{kaigohoshu.taiinjikyodoshidokasan}</td>
            </tr>
            <tr>
              <th>生活機能向上連携加算</th>
              <td>{kaigohoshu.seikatsukinokojorenkeikasan}</td>
              <th>ターミナルケア加算</th>
              <td>{kaigohoshu.terminalCareKasan}</td>
            </tr>
            <tr>
              <th>総合マネジメント体制強化加算</th>
              <td>{kaigohoshu.sogoManagementTaiseikyokakasan}</td>
              <th></th>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

KaigoHosyuKasanBlock.propTypes = {
  kaigohoshukasan: PropTypes.object.isRequired,
  serviceShubetsuType: PropTypes.object.isRequired,
};
export default KaigoHosyuKasanBlock;
