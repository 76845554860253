import React from "react";
import Center from "../layout/Center";
import { CircularProgress } from "@material-ui/core";

const CenteredCircularProgress = (props) => {
  return (
    <Center {...props}>
      <CircularProgress />
    </Center>
  );
};

export default CenteredCircularProgress;
