import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DropzoneLib from "react-dropzone";
import styles from "./Dropzone.css";
import { css } from "aphrodite";
import Mount from "../layout/Mount";
import DropzoneError from "./DropzoneError";

class Dropzone extends React.Component {
  constructor(props) {
    super(props);
    this.handleDrop = this.handleDrop.bind(this);
  }

  componentDidMount() {}

  handleDrop(acceptedFiles, rejectedFiles) {
    this.props.onDrop(acceptedFiles, rejectedFiles);
  }

  render() {
    const { explainMessage } = this.props;
    return (
      <Fragment>
        <DropzoneLib className={css(styles.dropzone)} onDrop={this.handleDrop}>
          ドラッグ＆ドロップして
          <br />
          ファイルをアップロード
          <Mount if={explainMessage}>
            <br/ >{explainMessage}
          </Mount>
        </DropzoneLib>
        <Mount if={this.props.errorMessage}>
          <DropzoneError />
        </Mount>
      </Fragment>
    );
  }
}

Dropzone.propTypes = {
  onDrop: PropTypes.func.isRequired,
  errorMessage: PropTypes.bool,
  explainMessage: PropTypes.string,
};

export default Dropzone;
