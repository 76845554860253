import PropTypes from "prop-types";

const ValueWithMenuUnit = ({ item, value }) => {
  if (!item) return "";
  switch (value) {
    case "title":
      return item.title;
    case "details":
      if (item.details == null) return "";
      if (item.details.length > 80) {
        return item.details.substring(0, 80) + "…";
      } else {
        return item.details;
      }
    case "ryokin":
      return item.ryokin;
    default:
      return "";
  }
};

ValueWithMenuUnit.propTypes = {
  item: PropTypes.object,
  value: PropTypes.string,
};

export default ValueWithMenuUnit;
