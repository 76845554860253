import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import {
  OPTIONS_AVAILABILITY,
  OPTIONS_ALLOWABILITY_FUZZY,
} from "../../../../../app/ui/form";
import Textarea from "../../../../../app/ui/form/Textarea";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
} from "../../../../../state/master-data";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import Mount from "../../../../../app/ui/layout/Mount";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import { connect } from "react-redux";
import HorizontalSubHeadLabelLayout from "../../../../../app/ui/form/HorizontalSubHeadLabelLayout";

class NyuyokuForm extends React.Component {
  render() {
    return <Fragment>{this.renderNyuyoku()}</Fragment>;
  }

  renderNyuyoku() {
    const { bindingProps, masterData } = this.props;
    const isActive = OptionsRadio.checked(
      bindingProps.form.getByName("nyuyokuServiceNoUmu")
    );
    return (
      <FormGrouping title="入浴">
        <HorizontalLabelLayout labelText="入浴サービスの有無" required>
          <OptionsRadio
            name="nyuyokuServiceNoUmu"
            split={6}
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <Mount if={isActive}>
          <HorizontalLabelLayout labelText="特徴">
            <Textarea name="tokucho" {...bindingProps} />
          </HorizontalLabelLayout>

          <HorizontalLabelLayout labelText="浴室の数" required>
            <ShortInputWidth>
              <HorizontalSubHeadLabelLayout>
                <UnitLayout unitText="箇所">
                  <InputTypeNumber name="yokushitsunokazu" {...bindingProps} />
                </UnitLayout>
              </HorizontalSubHeadLabelLayout>
            </ShortInputWidth>
          </HorizontalLabelLayout>

          <HorizontalLabelLayout labelText="浴槽タイプ" required>
            <ShortInputWidth>
              <HorizontalSubHeadLabelLayout labelText="個浴">
                <UnitLayout unitText="箇所">
                  <InputTypeNumber name="koyokunokazu" {...bindingProps} />
                </UnitLayout>
              </HorizontalSubHeadLabelLayout>
            </ShortInputWidth>
            <ShortInputWidth>
              <HorizontalSubHeadLabelLayout labelText="大浴槽">
                <UnitLayout unitText="箇所">
                  <InputTypeNumber name="daiyokusonokazu" {...bindingProps} />
                </UnitLayout>
              </HorizontalSubHeadLabelLayout>
            </ShortInputWidth>
          </HorizontalLabelLayout>

          <HorizontalLabelLayout labelText="機械浴" required>
            <GridRow>
              <GridCell>
                <HorizontalSubHeadLabelLayout labelText="特殊浴槽">
                  <UnitLayout unitText="箇所">
                    <InputTypeNumber
                      name="tokushuyokusonokazu"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </HorizontalSubHeadLabelLayout>
              </GridCell>
              <GridCell grow={2}>
                <GridRow>
                  <GridCell>
                    <InputTypeCheckbox
                      name="shininyuyoku"
                      labelText="寝位入浴"
                      {...bindingProps}
                    />
                  </GridCell>
                  <GridCell>
                    <InputTypeCheckbox
                      name="zainyuyoku"
                      labelText="座位入浴"
                      {...bindingProps}
                    />
                  </GridCell>
                  <GridCell grow={2}></GridCell>
                </GridRow>
              </GridCell>
            </GridRow>
            <ShortInputWidth>
              <HorizontalSubHeadLabelLayout labelText="リフト入浴">
                <UnitLayout unitText="箇所">
                  <InputTypeNumber name="liftyokunokazu" {...bindingProps} />
                </UnitLayout>
              </HorizontalSubHeadLabelLayout>
            </ShortInputWidth>
          </HorizontalLabelLayout>

          <HorizontalLabelLayout labelText="同性介助" required>
            <OptionsRadio
              name="doseikaijo"
              split={6}
              options={OPTIONS_ALLOWABILITY_FUZZY}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="その他">
            <OptionsCheckbox
              name="nyuyokuSonota"
              options={masterData.nyuyokuSonota}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
          <HorizontalLabelLayout labelText="その他設備等">
            <Textarea name="sonotasetsubito" {...bindingProps} />
          </HorizontalLabelLayout>
        </Mount>
      </FormGrouping>
    );
  }
}

NyuyokuForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(connect(mapStateToProps, mapDispatchToProps)(NyuyokuForm));

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
