import React from "react";
import PropTypes from "prop-types";

const BackOfficeSubHead = (props) => {
  return <h1 style={styles.head}>{props.children}</h1>;
};

const styles = {
  head: {
    fontSize: "medium",
    marginTop: 20,
  },
};

BackOfficeSubHead.propTypes = {
  children: PropTypes.node.isRequired,
};

export default BackOfficeSubHead;
