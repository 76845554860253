import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../app/naming/Pathname";
import Parametername from "../../app/naming/Parametername";
import { Button } from "@material-ui/core";

class WebFrontHeader extends React.Component {
  constructor(props) {
    super(props);
    this.handleLogoClick = this.handleLogoClick.bind(this);
    this.handleWebFrontButtonClick = this.handleWebFrontButtonClick.bind(this);
    this.handleBackOfficeButtonClick = this.handleBackOfficeButtonClick.bind(
      this
    );
    this.handleLogoutButtonClick = this.handleLogoutButtonClick.bind(this);
    this.handleGuideLinkClick = this.handleGuideLinkClick.bind(this);

    this.handleBackOfficeButtonMouseOver = this.handleBackOfficeButtonMouseOver.bind(
      this
    );
    this.handleBackOfficeButtonMouseOut = this.handleBackOfficeButtonMouseOut.bind(
      this
    );
  }

  state = {
    isMouseOver: false,
  };

  handleLogoClick() {
    const { actions } = this.props;
    actions.gotoDashboard();
  }

  handleWebFrontButtonClick() {
    const { actions } = this.props;
    actions.gotoWebFrontPage();
  }

  handleBackOfficeButtonClick() {
    const { actions } = this.props;
    actions.gotoBackOfficePage();
  }

  handleLogoutButtonClick() {
    const { actions } = this.props;
    actions.gotoLogoutPage();
  }

  handleGuideLinkClick() {
    const { actions } = this.props;
    actions.gotoGuidePage();
  }

  handleBackOfficeButtonMouseOver() {
    this.setState({
      isMouseOver: true,
    });
  }
  handleBackOfficeButtonMouseOut() {
    this.setState({
      isMouseOver: false,
    });
  }

  render() {
    const { user } = this.props.authentication;
    // 使い方表示中の場合（pathnameが二つ以上追加されないように）
    const guide = window.location.pathname == Pathname.guide;
    return (
      <Fragment>
        <div className="c-header">
          <div className="c-header-frame">
            <div style={{ display: "flex" }}>
              <div className="c-header-frame__logo">
                <a onClick={this.handleLogoClick}>
                  <img src="/images/logo.svg" />
                </a>
              </div>
              {user && user.hasBackOfficeMenu() ? (
                <div className="c-toggle">
                  <Button
                    onClick={this.handleWebFrontButtonClick}
                    className="c-toggleButton-on"
                  >
                    閲覧ページ
                  </Button>
                  <Button
                    onClick={this.handleBackOfficeButtonClick}
                    onMouseOver={this.handleBackOfficeButtonMouseOver}
                    onMouseOut={this.handleBackOfficeButtonMouseOut}
                    className={`c-toggleButton-off ${
                      this.state.isMouseOver ? "c-toggleButton-off-hover" : ""
                    }`}
                  >
                    管理ページ
                  </Button>
                </div>
              ) : null}
            </div>
            <div className="c-header-frame__nav">
              <ul>
                {user ? (
                  <Fragment>
                    <li>
                      <a onClick={() => !guide && this.handleGuideLinkClick()}>
                        使い方
                      </a>
                    </li>
                    <li>
                      <a onClick={this.handleLogoutButtonClick}>ログアウト</a>
                    </li>
                    <li className="c-header-frame__nav-login-user">
                      <a>
                        <div className="c-header-frame__nav-img">
                          <img src="/images/chara_hand-glass.png" />
                        </div>
                        <div className="c-header-frame__nav-text">
                          <div className="c-header-frame__nav-text-sub">
                            {user.group.name}
                          </div>
                          <div className="c-header-frame__nav-text-main">
                            {user.familyName} {user.givenName}さん
                          </div>
                        </div>
                      </a>
                    </li>
                  </Fragment>
                ) : null}
                {/*
                                <li><a>マイリスト</a></li>
                                <li><a>閲覧履歴</a></li>
                                */}
              </ul>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

WebFrontHeader.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  authentication: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebFrontHeader);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  const pathname = window.location.href.substr(
    window.location.protocol.length + "//".length + window.location.host.length
  );
  const searchParams = new URLSearchParams();
  searchParams.set(Parametername.pathname, pathname);
  return {
    actions: {
      gotoDashboard: () => dispatch(push(Pathname.webfront)),
      gotoBackOfficePage: () => dispatch(push(Pathname.backoffice)),
      gotoLogoutPage: () => dispatch(push(Pathname.logout)),
      gotoGuidePage: () =>
        dispatch(
          push({ pathname: Pathname.guide, search: searchParams.toString() })
        ),
    },
  };
}
