import React from "react";
import { mergeStyleToProps } from "../../fundamental/react/props";

const Center = (props) => {
  return <div {...mergeStyleToProps(styles.center, props)}></div>;
};

const styles = {
  center: {
    textAlign: "center",
  },
};

export default Center;
