import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import RegistrationDataEmpty from "./RegistrationDataEmpty";
import For from "../../../app/ui/layout/For";
import CommentBlankForm from "./comment/CommentBlankForm";
import Mount from "../../../app/ui/layout/Mount";
import {
  appendCommentToVisibleSurface,
  removeCommentFromVisibleSurface,
  modifyCommentOnVisibleSurface,
} from "../../../state/service-content/actions";
import CommentComponent from "./comment/CommentComponent";

class ServiceContentTypeComment extends React.Component {
  constructor(props) {
    super(props);
    this.handleEditButtonClick = this.handleEditButtonClick.bind(this);
  }

  handleEditButtonClick(id) {
    this.setState({ editTargetId: id });
  }

  state = {
    editTargetId: null,
  };

  render() {
    const { user } = this.props.authentication;
    return (
      <div className="p-office-body p-office-body--comment u-margin-b32">
        <Mount
          if={user.group.canComment}
          then={() => <Fragment>{this.renderBlankForm()}</Fragment>}
        />
        {this.renderCommentsHead()}
        {this.renderComments()}
      </div>
    );
  }

  renderCommentsHead() {
    const { authentication, contents } = this.props;
    const { canComment } = authentication.user.group;
    const { length } = contents.blocks.comments.list;
    const noComment = length === 0;
    if (noComment && !canComment) return null;
    return (
      <Fragment>
        <Mount if={!noComment}>
          <div className="comment-comment">
            下記のコメントは主観的なご意見、ご感想です。
          </div>
        </Mount>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">コメント</div>
        </div>
      </Fragment>
    );
  }

  renderComments() {
    const {
      contents,
      onEditFormSubmitSuccess: onFormSubmitSuccess,
      onRemoveSuccess,
    } = this.props;
    const { comments } = contents.blocks;
    if (!comments.list.length) return <RegistrationDataEmpty />;
    return (
      <For
        in={comments.list}
        each={(comment) => (
          <Fragment key={comment.id}>
            <CommentComponent
              comment={comment}
              editTargetId={this.state.editTargetId}
              onEditButtonClick={this.handleEditButtonClick}
              onFormSubmitSuccess={onFormSubmitSuccess}
              onRemoveSuccess={onRemoveSuccess}
            />
          </Fragment>
        )}
      />
    );
  }

  renderBlankForm() {
    const {
      contents,
      onBlankFormSubmitSuccess: onFormSubmitSuccess,
    } = this.props;
    const { id: serviceId } = contents;
    const props = {
      serviceId,
      onFormSubmitSuccess,
    };
    return <CommentBlankForm {...props} />;
  }
}

ServiceContentTypeComment.propTypes = {
  authentication: PropTypes.object.isRequired,
  contents: PropTypes.object.isRequired,
  // on-event handler
  onBlankFormSubmitSuccess: PropTypes.func.isRequired,
  onEditFormSubmitSuccess: PropTypes.func.isRequired,
  onRemoveSuccess: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ServiceContentTypeComment);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onBlankFormSubmitSuccess: (dummyComment) =>
      dispatch(appendCommentToVisibleSurface(dummyComment)),
    onEditFormSubmitSuccess: (dummyComment) =>
      dispatch(modifyCommentOnVisibleSurface(dummyComment)),
    onRemoveSuccess: (id) => dispatch(removeCommentFromVisibleSurface(id)),
  };
}
