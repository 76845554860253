import React, { Fragment } from "react";
import PropTypes from "prop-types";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import Mount from "../../../../../app/ui/layout/Mount";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";

class KyotakukaigoshienjigyoshoStaffForm extends React.Component {
  render() {
    return (
      <Fragment>
        <FormGrouping title="スタッフ数">
          <HorizontalLabelLayout labelText="男女別従業員数" required>
            {this.renderHeadCount({
              name: "seibetsuDansei",
              label: "男性従業員数",
            })}
            {this.renderHeadCount({
              name: "seibetsuJosei",
              label: "女性従業員数",
            })}
          </HorizontalLabelLayout>

          <HorizontalLabelLayout labelText="介護支援専門員" required>
            {this.renderHeadCount({ name: "kaigoshienSemmonin" })}
          </HorizontalLabelLayout>

          <HorizontalLabelLayout labelText="管理者保有資格" recommended>
            {this.renderText({
              name: "kanrishahoyushikaku",
              hintText: "例）看護師（排泄・褥瘡ケア専門看護師）",
            })}
          </HorizontalLabelLayout>

          <HorizontalLabelLayout
            labelText="経験年数5年以上のケアマネジャーの割合"
            recommended
          >
            {this.renderPercentage({ name: "keiken5nenWariai" })}
          </HorizontalLabelLayout>
        </FormGrouping>
      </Fragment>
    );
  }

  renderHeadCount({ name, label }) {
    const { bindingProps } = this.props;
    return (
      <RatioBlock divide={5} key={name}>
        <SpaceOut block>
          <Mount if={label}>
            <StaticLabel>{label}</StaticLabel>
          </Mount>
          <UnitLayout unitText="人">
            <InputTypeNumber name={name} {...bindingProps} />
          </UnitLayout>
        </SpaceOut>
      </RatioBlock>
    );
  }

  renderPercentage({ name, label }) {
    const { bindingProps } = this.props;
    return (
      <RatioBlock divide={5} key={name}>
        <SpaceOut block>
          <Mount if={label}>
            <StaticLabel>{label}</StaticLabel>
          </Mount>
          <UnitLayout unitText="％">
            <InputTypeNumber name={name} {...bindingProps} />
          </UnitLayout>
        </SpaceOut>
      </RatioBlock>
    );
  }

  renderText({ name, label, hintText = "" }) {
    const { bindingProps } = this.props;
    return (
      <SpaceOut block>
        <Mount if={label}>
          <StaticLabel>{label}</StaticLabel>
        </Mount>
        <InputTypeText name={name} hintText={hintText} {...bindingProps} />
      </SpaceOut>
    );
  }
}

KyotakukaigoshienjigyoshoStaffForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default KyotakukaigoshienjigyoshoStaffForm;
