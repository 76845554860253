import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Thumbnail from "../../search/Thumbnail";
import Mount from "../../../../app/ui/layout/Mount";
import TruncateText from "../../../../app/ui/text/TruncateText";
import YoyakuShiharaiBlock from "./hokengaiService/YoyakuShiharaiBlock";
import RiyokaishiBlock from "./hokengaiService/RiyokaishiBlock";
import CommonUneiJohoBlock from "./hokengaiService/CommonUneiJohoBlock";
import ServiceBlock from "./memento/ServiceBlock";
import GyoseiBlock from "./memento/GyoseiBlock";
import MenuPlanBlock from "./memento/MenuPlanBlock";
import ValueWithYubinMarkUnit from "../../../../app/ui/unit/ValueWithYubinMarkUnit";

class MementoPrint extends React.Component {
  render() {
    const { contents } = this.props;
    return (
      <Fragment>
        {this.renderHead(contents)}
        <div className="print-body__block print-body__block--two-columns print-body__block--hokengai-font">
          <div>{this.renderPhoto(contents)}</div>
          <div>{this.renderService(contents)}</div>
        </div>
        <div className="print-body__block print-body__block--two-columns print-body__block--hokengai-font">
          <div>{this.renderMenuPlan(contents)}</div>
          <div>{this.renderYoyakuShiharai(contents)}</div>
        </div>
        <div className="print-body__block print-body__block--two-columns print-body__block--hokengai-font">
          <div>{this.renderRiyokaishi(contents)}</div>
          <div>{this.renderGyosei(contents)}</div>
        </div>
        <hr />
        {this.renderUneiJoho(contents)}
      </Fragment>
    );
  }

  renderHead(contents) {
    return (
      <div className="print-body__head">
        {this.renderHeadLeft(contents)}
        {this.renderHeadRight(contents)}
        <img src="/images/print_bg_light_yellow.png" />
      </div>
    );
  }

  renderHeadLeft(contents) {
    const { kihonjoho: kihonjoho } = contents;
    const LABEL_LIMIT = 178;
    const tags = [];
    tags.push(kihonjoho.serviceShubetsu);

    return (
      <div>
        <div className="service-shubetsu">
          {tags.map((tag) => (
            <div key={tag}>
              {tag}
              <img
                style={{ borderRadius: "0 0 4pt 4pt" }}
                src="/images/print_bg_light_blue.png"
              />
            </div>
          ))}
        </div>
        <div className="eigyo-joho">
          <ul>
            {kihonjoho.doyobieigyoSign ? (
              <li className="open">
                土<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                土<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {kihonjoho.nichiyobieigyoSign ? (
              <li className="open">
                日<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                日<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
            {kihonjoho.shukujitsueigyoSign ? (
              <li className="open">
                祝<img src="/images/print_bg_yellow.png" />
              </li>
            ) : (
              <li>
                祝<img src="/images/print_bg_light_gray.png" />
              </li>
            )}
          </ul>
        </div>

        <div className="jigyoshomei">{kihonjoho.jigyoshomei}</div>
        <div className="tokuchoMessageTitle">
          {kihonjoho.tokuchoMessageTitle
            ? `・${kihonjoho.tokuchoMessageTitle}`
            : ""}
        </div>
        <div className="tokuchoMessageDetails">
          <TruncateText maxLength={LABEL_LIMIT}>
            {kihonjoho.tokuchoMessageDetails}
          </TruncateText>
        </div>
      </div>
    );
  }

  renderHeadRight(contents) {
    const { kihonjoho: kihonjoho } = contents;
    const LABEL_LIMIT = 46;
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>営</span>
                  <span>業</span>
                  <span>・</span>
                  <span>開</span>
                  <span>催</span>
                </span>
                <span>
                  <span>時</span>
                  <span></span>
                  <span></span>
                  <span></span>
                  <span>間</span>
                </span>
              </th>
              <td colSpan="3">{kihonjoho.eigyojikantai}</td>
            </tr>
            <tr>
              <th className="paddingTel">
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>T</span>
                  <span>E</span>
                  <span>L</span>
                </span>
              </th>
              <td className="fontTel">{kihonjoho.kokairenrakusaki.tel}</td>
              <Mount if={kihonjoho.kokairenrakusaki.fax}>
                <th className="paddingTel">
                  <img className="bgWhite" src="/images/print_bg_white.png" />
                  <span>
                    <span>F</span>
                    <span>A</span>
                    <span>X</span>
                  </span>
                </th>
                <td className="paddingTel">{kihonjoho.kokairenrakusaki.fax}</td>
              </Mount>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>住</span>
                  <span>所</span>
                </span>
              </th>
              <td colSpan="3">
                <div>
                  <ValueWithYubinMarkUnit
                    value={kihonjoho.kokaijusho.yubimbango}
                  />
                </div>
                <div>{kihonjoho.kokaijusho.jusho}</div>
              </td>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>サ</span>
                  <span>ー</span>
                  <span>ビ</span>
                  <span>ス</span>
                </span>
                <span>
                  <span>提</span>
                  <span>供</span>
                  <span>エ</span>
                  <span>リ</span>
                  <span>ア</span>
                </span>
              </th>
              <td colSpan="3">
                <TruncateText maxLength={LABEL_LIMIT}>
                  {kihonjoho.serviceTeikyoArea}
                </TruncateText>
              </td>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>ご</span>
                  <span>利</span>
                  <span>用</span>
                </span>
                <span>
                  <span>対</span>
                  <span>象</span>
                  <span>者</span>
                </span>
              </th>
              <td colSpan="3">
                <TruncateText maxLength={LABEL_LIMIT}>
                  {kihonjoho.riyotaishosha}
                </TruncateText>
              </td>
            </tr>
            <tr>
              <th>
                <img className="bgWhite" src="/images/print_bg_white.png" />
                <span>
                  <span>定</span>
                  <span>休</span>
                  <span>日</span>
                </span>
              </th>
              <td colSpan="3">{kihonjoho.teikyubi}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }

  renderPhoto(contents) {
    const photo1 = contents.photos[0] || { imageUrl: "", description: "" };

    return (
      <div style={{ marginTop: "10pt" }}>
        <div className="print-body__thumbnail_single">
          <Thumbnail src={photo1.imageUrl} description={photo1.description} />
        </div>
      </div>
    );
  }

  renderService(contents) {
    const { service: service } = contents;

    return <ServiceBlock service={service} />;
  }

  renderYoyakuShiharai(contents) {
    const { yoyakushiharai: yoyakushiharai } = contents;

    return <YoyakuShiharaiBlock yoyakuShiharai={yoyakushiharai} />;
  }

  renderMenuPlan(contents) {
    const { menuPlan: menuPlan } = contents;

    return <MenuPlanBlock menuplan={menuPlan} />;
  }

  renderRiyokaishi(contents) {
    const { riyokaishi: riyokaishi } = contents;

    return <RiyokaishiBlock riyokaishi={riyokaishi} />;
  }

  renderGyosei(contents) {
    const { gyoseijoseiseido: gyoseijoseiseido } = contents;

    return <GyoseiBlock gyosei={gyoseijoseiseido} />;
  }

  renderUneiJoho(contents) {
    const { uneijoho: uneijoho } = contents;

    return <CommonUneiJohoBlock uneiJoho={uneijoho} />;
  }
}

MementoPrint.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default MementoPrint;
