import React from "react";
import PropTypes from "prop-types";
import Thumbnail from "../Thumbnail";
import CardHead from "./CardHead";
import CardFoot from "./CardFoot";

const MimamoriSuishinJigyoCard = (props) => {
  const { service, shared, selected, onSelect } = props;
  const hokengaiService = service.blocks.hokengaiService;
  const mimamoriSuishinJigyo = service.blocks.mimamoriSuishinJigyo;
  return (
    <div
      className={`c-card-frame__item ${
        selected ? "c-card-frame__item--y" : ""
      }`}
    >
      <CardHead
        onSelect={onSelect}
        onServiceButtonClick={props.onServiceButtonClick}
        selected={selected}
        service={service}
      />
      <div className="c-card-frame__item-body">
        <div className="c-card-frame__item-body-left">
          <div className="c-card-frame-picture">
            <a onClick={() => props.onServiceButtonClick(service.id)}>
              <Thumbnail src={service.thumbnail} />
            </a>
          </div>
        </div>
        <div className="c-card-frame__item-body-right">
          <div className="c-dl">
            <dl>
              <dt>見守り内容</dt>
              <dd>
                {mimamoriSuishinJigyo.mimamoriNaiyo.map((it) => (
                  <div key={it}>{it}</div>
                ))}
              </dd>
              <dt>営業日</dt>
              <dd>{hokengaiService.eigyobi.join("、")}</dd>
              <dt>定休日</dt>
              <dd>{mimamoriSuishinJigyo.teikyubi}</dd>
              <dt>見守り提供地域</dt>
              <dd>{mimamoriSuishinJigyo.mimamoriTeikyoChiiki}</dd>
              <dt>TEL</dt>
              <dd>{hokengaiService.denwabango}</dd>
              <dt>担当包括</dt>
              <dd>{mimamoriSuishinJigyo.tantoChiikiHokatsuShienCenter}</dd>
              <dt>特記事項</dt>
              <dd>{hokengaiService.tokkijiko}</dd>
            </dl>
          </div>
          <CardFoot
            onPrintButtonClick={props.onPrintButtonClick}
            onServiceButtonClick={props.onServiceButtonClick}
            onShareButtonClick={props.onShareButtonClick}
            service={service}
            shared={shared}
          />
        </div>
      </div>
    </div>
  );
};

MimamoriSuishinJigyoCard.propTypes = {
  onPrintButtonClick: PropTypes.func.isRequired,
  onServiceButtonClick: PropTypes.func.isRequired,
  onShareButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  shared: PropTypes.bool.isRequired,
};

export default MimamoriSuishinJigyoCard;
