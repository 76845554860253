import React from "react";
import PropTypes from "prop-types";

const HeaderTextBlue = ({ text }) => {
  return (
    <div className="c-blue-heading">
      <div className="c-blue-heading__text">{text}</div>
      <img src="/images/print_bg_light_blue.png" />
    </div>
  );
};

HeaderTextBlue.propTypes = {
  text: PropTypes.string.isRequired,
};

export default HeaderTextBlue;
