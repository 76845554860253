import React from "react";
import PropTypes from "prop-types";
import Thumbnail from "../Thumbnail";
import CardHead from "./CardHead";
import CardFoot from "./CardFoot";
import CardFrameItem from "./CardFrameItem";

const KyotakukaigoshienjigyoshoCard = (props) => {
  const { service, selected, onSelect, shared } = props;
  const { hokennaiService, kyotakukaigoshienjigyosho } = service.blocks;
  return (
    <div
      className={`c-card-frame__item ${
        selected ? "c-card-frame__item--y" : ""
      }`}
    >
      <CardHead
        onSelect={onSelect}
        onServiceButtonClick={props.onServiceButtonClick}
        selected={selected}
        service={service}
      />
      <div className="c-card-frame__item-body">
        <div className="c-card-frame__item-body-left">
          <div className="c-card-frame-picture">
            <a onClick={() => props.onServiceButtonClick(service.id)}>
              <Thumbnail src={service.thumbnail} />
            </a>
          </div>
        </div>
        <div className="c-card-frame__item-body-right">
          <div className="c-dl">
            <dl>
              <CardFrameItem title="TEL">{hokennaiService.tel}</CardFrameItem>
              <CardFrameItem title="住所">{service.jusho}</CardFrameItem>
              <CardFrameItem title="営業日">
                {hokennaiService.eigyobi.join("、")}
              </CardFrameItem>
              <CardFrameItem title="サービス提供地域">
                {kyotakukaigoshienjigyosho.serviceTeikyoChiiki}
              </CardFrameItem>
              <CardFrameItem title="サービス提供対象">
                {kyotakukaigoshienjigyosho.serviceTeikyoTaisho.join("、")}
              </CardFrameItem>
              <CardFrameItem title="電話受付時間">
                {formatDenwaUketsukeJikan(
                  kyotakukaigoshienjigyosho.telUketsuke,
                  kyotakukaigoshienjigyosho.renrakutaiokanoJikantai
                )}
              </CardFrameItem>
              <CardFrameItem title="特徴">
                {kyotakukaigoshienjigyosho.tokucho.join("、")}
              </CardFrameItem>
              <CardFrameItem title="特記事項">
                {hokennaiService.tokkijiko}
              </CardFrameItem>
            </dl>
          </div>
          <CardFoot
            onPrintButtonClick={props.onPrintButtonClick}
            onServiceButtonClick={props.onServiceButtonClick}
            onShareButtonClick={props.onShareButtonClick}
            service={service}
            shared={shared}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * 電話受付時間のフォーマット
 *
 * @param telUketsuke
 * @param renrakutaiokanoJikantai
 * @returns 例「8:00〜23:00、緊急時電話対応可」「緊急時電話対応可」など、"〜"や"、"を出し分ける。
 */
export function formatDenwaUketsukeJikan(telUketsuke, renrakutaiokanoJikantai) {
  var result = "";

  if (telUketsuke.length > 0) {
    result = telUketsuke.join("〜");

    if (renrakutaiokanoJikantai.length > 0) {
      result += "（" + renrakutaiokanoJikantai + "）";
    }
  } else {
    result = renrakutaiokanoJikantai;
  }

  return result;
}

KyotakukaigoshienjigyoshoCard.propTypes = {
  onPrintButtonClick: PropTypes.func.isRequired,
  onServiceButtonClick: PropTypes.func.isRequired,
  onShareButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  shared: PropTypes.bool.isRequired,
};

export default KyotakukaigoshienjigyoshoCard;
