const ServiceShubetsuType = {
  // 通所系サービス
  通所介護: { code: "006", label: "通所介護", hissuJigyoshobango: true },
  認知症対応型通所介護: {
    code: "007",
    label: "認知症対応型通所介護",
    hissuJigyoshobango: true,
  },
  療養通所介護: {
    code: "008",
    label: "療養通所介護",
    hissuJigyoshobango: true,
  },
  通所リハビリテーション: {
    code: "009",
    label: "通所リハビリテーション",
    hissuJigyoshobango: true,
  },
  地域密着型通所介護: {
    code: "033",
    label: "地域密着型通所介護",
    hissuJigyoshobango: true,
  },

  // 訪問系サービス
  訪問介護: { code: "001", label: "訪問介護", hissuJigyoshobango: true },
  訪問入浴介護: {
    code: "003",
    label: "訪問入浴介護",
    hissuJigyoshobango: true,
  },
  訪問看護: { code: "004", label: "訪問看護", hissuJigyoshobango: true },
  訪問リハビリテーション: {
    code: "005",
    label: "訪問リハビリテーション",
    hissuJigyoshobango: true,
  },
  定期巡回_随時対応型訪問介護看護: {
    code: "031",
    label: "定期巡回・随時対応型訪問介護看護",
    hissuJigyoshobango: true,
  },

  // ショートステイ
  短期入所生活介護: {
    code: "025",
    label: "短期入所生活介護",
    hissuJigyoshobango: true,
  },
  短期入所療養介護医療: {
    code: "028",
    label: "短期入所療養介護",
    hissuJigyoshobango: true,
  },
  短期入所療養介護老健: {
    code: "030",
    label: "短期入所療養介護",
    hissuJigyoshobango: true,
  },

  // 保険外サービス
  活動_通いの場: {
    code: "550",
    label: "活動・通いの場",
    hissuJigyoshobango: true,
  },
  移送_介護タクシー: {
    code: "510",
    label: "移送・介護タクシー",
    hissuJigyoshobango: true,
  },
  生活支援_自費ヘルプ: {
    code: "520",
    label: "生活支援・自費ヘルプ",
    hissuJigyoshobango: true,
  },
  配食サービス: {
    code: "530",
    label: "配食サービス",
    hissuJigyoshobango: true,
  },
  訪問理美容: { code: "540", label: "訪問理美容", hissuJigyoshobango: true },
  遺品整理_生前整理: {
    code: "541",
    label: "遺品整理・生前整理",
    hissuJigyoshobango: true,
  },
  葬儀_相続: { code: "580", label: "葬儀・相続", hissuJigyoshobango: true },
  見守り_緊急通報: {
    code: "542",
    label: "見守り・緊急通報",
    hissuJigyoshobango: true,
  },
  その他便利サービス: {
    code: "560",
    label: "その他便利サービス",
    hissuJigyoshobango: true,
  },
  見守り推進事業: {
    code: "570",
    label: "見守り推進事業",
    hissuJigyoshobango: true,
  },

  // 居宅介護支援
  居宅介護支援: {
    code: "023",
    label: "居宅介護支援",
    hissuJigyoshobango: true,
  },

  // 支援組織・推進団体
  地域包括支援センター: {
    code: "900",
    label: "地域包括支援センター",
    hissuJigyoshobango: false,
  },
  社会福祉協議会: {
    code: "901",
    label: "社会福祉協議会",
    hissuJigyoshobango: false,
  },
  その他: { code: "999", label: "その他", hissuJigyoshobango: false },
};

export function serviceShubetsuTypeValueOf(code) {
  return Object.keys(ServiceShubetsuType)
    .map((key) => ServiceShubetsuType[key])
    .find((type) => type.code == code);
}

export function excludeMimamoriSuishinJigyo(code) {
  return code !== ServiceShubetsuType.見守り推進事業.code;
}

export function includeKyotakuKaigoShien(code) {
  return code == ServiceShubetsuType.居宅介護支援.code;
}

export default ServiceShubetsuType;
