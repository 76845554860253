import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ColorCatalog from "../color/ColorCatalog";
import StaticLabel from "./StaticLabel";
import Right from "../layout/Right";

const HorizontalSmallLabelLayout = (props) => {
  return (
    <div style={styles.container}>
      <div style={styles.label}>
        <Right>
          <StaticLabel withFloatingText={props.withFloatingText}>
            {props.required ? <span className="mark--required"></span> : null}
            {props.labelText ? <Fragment>{props.labelText}</Fragment> : null}
          </StaticLabel>
        </Right>
      </div>
      <div style={styles.input}>{props.children}</div>
    </div>
  );
};

const styles = {
  container: {
    width: "100%",
  },
  label: {
    boxSizing: "border-box",
    display: "inline-block",
    width: 40,
    color: ColorCatalog.gray,
    fontSize: "small",
    fontWeight: "bold",
    paddingRight: 1,
    textAlign: "right",
  },
  input: {
    boxSizing: "border-box",
    display: "inline-block",
    width: "calc(100% - 40px)",
  },
};

HorizontalSmallLabelLayout.propTypes = {
  children: PropTypes.node,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  withFloatingText: PropTypes.bool,
};

export default HorizontalSmallLabelLayout;
