import React from "react";
import PropTypes from "prop-types";
import {
  mergeStyleToProps,
  removePropertyFromProps,
} from "../../fundamental/react/props";

const Boundary = (props) => {
  return <div {...mergeProps(props)}></div>;
};

function mergeProps(props) {
  const { maxWidth = 1024, pushUp = true } = props;
  props = mergeStyleToProps(
    {
      margin: "0 auto",
      maxWidth,
    },
    props
  );
  if (pushUp)
    props = mergeStyleToProps(
      {
        paddingBottom: 15,
      },
      props
    );
  props = removePropertyFromProps("maxWidth", props);
  props = removePropertyFromProps("pushUp", props);
  return props;
}

Boundary.propTypes = {
  maxWidth: PropTypes.number,
  pushUp: PropTypes.bool,
};

export default Boundary;
