const SearchConditionFunnelType = {
  サービスの種類_介護タクシー: {
    name: "サービスの種類_介護タクシー",
    label: "サービスの種類",
    parameters: [
      {
        name: "ktss",
        type: "checkbox",
        options: [
          { code: "10", label: "介護タクシー", orderNo: 1 },
          { code: "20", label: "民間救急サービス", orderNo: 2 },
          { code: "30", label: "精神疾患患者等の移送サービス", orderNo: 3 },
        ],
      },
    ],
  },

  提供サービス_通所系サービス: {
    name: "提供サービス_通所系サービス",
    label: "提供サービス",
    parameters: [
      {
        name: "tsts",
        type: "checkbox",
        options: [
          { code: "10", label: "介護保険", orderNo: 1 },
          { code: "20", label: "予防給付相当", orderNo: 2 },
          { code: "30", label: "基準緩和型（A型）", orderNo: 3 },
          { code: "40", label: "短期集中型（C型）", orderNo: 4 },
          { code: "50", label: "自費利用", orderNo: 5 },
        ],
      },
    ],
  },

  提供サービス_介護タクシー: {
    name: "提供サービス_介護タクシー",
    label: "提供サービス",
    parameters: [
      {
        name: "ktts",
        type: "checkbox",
        options: [
          { code: "10", label: "車種の選択", orderNo: 1 },
          { code: "20", label: "車椅子利用", orderNo: 2 },
          { code: "30", label: "リクライニング車椅子利用", orderNo: 3 },
          { code: "40", label: "ストレッチャー利用", orderNo: 4 },
          { code: "50", label: "看護師の同乗", orderNo: 5 },
          { code: "60", label: "酸素吸入器", orderNo: 6 },
          { code: "70", label: "酸素マスク", orderNo: 7 },
          { code: "80", label: "カニューラ", orderNo: 8 },
          { code: "90", label: "たん吸引器", orderNo: 9 },
        ],
      },
    ],
  },

  提供サービス_生活支援: {
    name: "提供サービス_生活支援",
    label: "提供サービス",
    parameters: [
      {
        name: "ssts",
        type: "checkbox",
        options: [
          { code: "010", label: "日常家事代行", orderNo: 1 },
          { code: "020", label: "掃除代行", orderNo: 2 },
          { code: "030", label: "料理代行", orderNo: 3 },
          { code: "040", label: "身体介護", orderNo: 4 },
          { code: "050", label: "高齢者向けサービス", orderNo: 5 },
          { code: "060", label: "介護家族向けサービス", orderNo: 6 },
          { code: "070", label: "シッターサービス", orderNo: 7 },
          { code: "080", label: "害虫駆除", orderNo: 8 },
          { code: "090", label: "ゴミ屋敷の掃除", orderNo: 9 },
          { code: "100", label: "エアコン・換気扇クリーニング", orderNo: 10 },
          { code: "110", label: "家具の移動", orderNo: 11 },
          { code: "120", label: "雑草取り・庭の剪伐", orderNo: 12 },
        ],
      },
    ],
  },

  提供サービス_訪問理美容: {
    name: "提供サービス_訪問理美容",
    label: "提供サービス",
    parameters: [
      {
        name: "bbts",
        type: "checkbox",
        options: [
          { code: "10", label: "カット", orderNo: 1 },
          { code: "20", label: "パーマ", orderNo: 2 },
          { code: "30", label: "カラーリング", orderNo: 3 },
          { code: "40", label: "トリートメント", orderNo: 4 },
          { code: "50", label: "ヘアセット", orderNo: 5 },
          { code: "60", label: "シャンプー", orderNo: 6 },
          { code: "70", label: "ヘッドスパ", orderNo: 7 },
          { code: "80", label: "フェイスパック", orderNo: 8 },
          { code: "90", label: "髭剃り", orderNo: 9 },
          { code: "100", label: "眉毛カット", orderNo: 10 },
        ],
      },
    ],
  },

  提供サービス_遺品整理生前整理: {
    name: "提供サービス_遺品整理生前整理",
    label: "提供サービス",
    parameters: [
      {
        name: "mmts",
        type: "checkbox",
        options: [
          { code: "10", label: "家財整理", orderNo: 1 },
          { code: "20", label: "遺品整理", orderNo: 2 },
          { code: "30", label: "特殊清掃", orderNo: 3 },
          { code: "40", label: "ゴミ屋敷の掃除", orderNo: 4 },
          { code: "50", label: "害虫駆除", orderNo: 5 },
          { code: "60", label: "物品・書類の捜索", orderNo: 6 },
          { code: "70", label: "魂抜き・供養・お焚き上げ", orderNo: 7 },
          { code: "80", label: "不用品下取り", orderNo: 8 },
          { code: "90", label: "家電ゴミ回収", orderNo: 9 },
          { code: "100", label: "粗大ごみ回収", orderNo: 10 },
          { code: "110", label: "写真の整理", orderNo: 11 },
          { code: "120", label: "遺言書の整理", orderNo: 12 },
        ],
      },
    ],
  },

  提供サービス_葬儀相続: {
    name: "葬儀_相続",
    label: "葬儀・相続",
    parameters: [
      {
        name: "ffts",
        type: "checkbox",
        options: [
          { code: "10", label: "ご遺体搬送", orderNo: 1 },
          { code: "20", label: "ご遺体安置", orderNo: 2 },
          { code: "30", label: "ご遺体処置", orderNo: 3 },
          { code: "40", label: "ご遺族との打ち合わせ（葬儀プランニング）", orderNo: 4 },
          { code: "50", label: "会場設営・撤去", orderNo: 5 },
          { code: "60", label: "葬儀の司会・進行", orderNo: 6 },
          { code: "70", label: "位牌", orderNo: 7 },
          { code: "80", label: "仏壇", orderNo: 8 },
          { code: "90", label: "相続", orderNo: 9 },
          { code: "100", label: "後返し", orderNo: 10 },
          { code: "110", label: "各種法事", orderNo: 11 },
          { code: "120", label: "不要品下取り", orderNo: 12 },
        ],
      },
    ],
  },

  提供サービス_見守り緊急通報: {
    name: "提供サービス_見守り緊急通報",
    label: "提供サービス",
    parameters: [
      {
        name: "wtts",
        type: "checkbox",
        options: [
          { code: "10", label: "定期確認（訪問または電話）", orderNo: 1 },
          { code: "20", label: "緊急時駆けつけ", orderNo: 2 },
          { code: "30", label: "GPS", orderNo: 3 },
          { code: "40", label: "センサー", orderNo: 4 },
          { code: "50", label: "緊急時家族へ連絡", orderNo: 5 },
        ],
      },
    ],
  },

  活動内容種類_活動通いの場: {
    name: "活動内容種類_活動通いの場",
    label: "活動内容種類",
    parameters: [
      {
        label: "体操（運動）",
        name: "kbm1",
        type: "checkbox",
        options: [
          { code: "10", label: "体操", orderNo: 1 },
          { code: "20", label: "スポーツ", orderNo: 2 },
          { code: "30", label: "ウォーキング", orderNo: 3 },
          { code: "40", label: "ヨガ・ピラティス・太極拳", orderNo: 4 },
          { code: "50", label: "輪投げ", orderNo: 5 },
          { code: "60", label: "その他", orderNo: 6 },
        ],
      },
      {
        label: "趣味活動",
        name: "kbm2",
        type: "checkbox",
        options: [
          { code: "70", label: "料理", orderNo: 7 },
          { code: "80", label: "囲碁、将棋、麻雀", orderNo: 8 },
          { code: "90", label: "短歌、俳句、詩吟、朗読", orderNo: 9 },
          { code: "100", label: "絵画、水彩画", orderNo: 10 },
          { code: "240", label: "書道", orderNo: 11 },
          { code: "110", label: "茶道、華道", orderNo: 12 },
          {
            code: "120",
            label: "音楽系（歌・カラオケ・コーラス ・楽器演奏）",
            orderNo: 13,
          },
          { code: "130", label: "外国語（英会話・韓国語など）", orderNo: 14 },
          { code: "140", label: "折り紙、手芸、工芸", orderNo: 15 },
          { code: "150", label: "学習系", orderNo: 16 },
          { code: "160", label: "映画鑑賞", orderNo: 17 },
          { code: "170", label: "その他", orderNo: 18 },
        ],
      },
      {
        label: "認知症予防",
        name: "kbm3",
        type: "checkbox",
        options: [
          { code: "180", label: "認知症カフェ", orderNo: 19 },
          { code: "190", label: "脳トレ", orderNo: 20 },
          { code: "200", label: "その他", orderNo: 21 },
        ],
      },
      {
        label: "会食・茶話会・その他",
        name: "kbm4",
        type: "checkbox",
        view_small: true,
        options: [
          { code: "210", label: "会食", orderNo: 22 },
          { code: "220", label: "茶話会", orderNo: 23 },
          { code: "230", label: "その他", orderNo: 24 },
        ],
      },
    ],
  },

  団体種別_活動通いの場: {
    name: "団体種別_活動通いの場",
    label: "団体種別",
    parameters: [
      {
        name: "kds",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "自治体運営", orderNo: 1 },
          { code: "20", label: "町会・自治会", orderNo: 2 },
          { code: "30", label: "老人クラブ（シニアクラブ）", orderNo: 3 },
          { code: "40", label: "NPO法人", orderNo: 4 },
          { code: "50", label: "民間企業", orderNo: 5 },
          { code: "60", label: "任意団体", orderNo: 6 },
          { code: "70", label: "地域包括支援センター", orderNo: 6 },
          { code: "80", label: "その他", orderNo: 7 },
        ],
      },
    ],
  },

  営業日: {
    name: "営業日",
    label: "営業日",
    parameters: [
      {
        name: "eb",
        type: "checkbox",
        view_small: true,
        options: [
          { code: "10", label: "平日", orderNo: 1 },
          { code: "20", label: "土曜", orderNo: 2 },
          { code: "30", label: "日曜", orderNo: 3 },
          { code: "40", label: "祝日", orderNo: 4 },
          { code: "50", label: "365日対応", orderNo: 5 },
        ],
      },
    ],
  },

  営業日_通所系サービス: {
    name: "営業日_通所系サービス",
    label: "営業日時",
    parameters: [
      {
        name: "eb",
        label: "営業日",
        type: "checkbox",
        view_small: true,
        options: [
          { code: "10", label: "平日", orderNo: 1 },
          { code: "20", label: "土曜", orderNo: 2 },
          { code: "30", label: "日曜", orderNo: 3 },
          { code: "40", label: "祝日", orderNo: 4 },
          { code: "50", label: "365日対応", orderNo: 5 },
        ],
      },
    ],
  },

  営業日_訪問系サービス: {
    name: "営業日_訪問系サービス",
    label: "営業日時",
    parameters: [
      {
        name: "eb",
        label: "営業日",
        type: "checkbox",
        view_small: true,
        options: [
          { code: "10", label: "平日", orderNo: 1 },
          { code: "20", label: "土曜", orderNo: 2 },
          { code: "30", label: "日曜", orderNo: 3 },
          { code: "40", label: "祝日", orderNo: 4 },
          { code: "50", label: "365日対応", orderNo: 5 },
        ],
      },
    ],
  },

  サービス提供時間: {
    name: "サービス提供時間",
    hide_title: true,
    parameters: [
      {
        name: "stjt",
        label: "サービス提供時間",
        type: "select",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "1時間以上2時間未満", orderNo: 1 },
          { code: "20", label: "2時間以上3時間未満", orderNo: 2 },
          { code: "30", label: "3時間以上4時間未満", orderNo: 3 },
          { code: "40", label: "4時間以上5時間未満", orderNo: 4 },
          { code: "50", label: "5時間以上6時間未満", orderNo: 5 },
          { code: "60", label: "6時間以上7時間未満", orderNo: 6 },
          { code: "70", label: "7時間以上8時間未満", orderNo: 7 },
          { code: "80", label: "8時間以上9時間未満", orderNo: 8 },
        ],
      },
    ],
  },

  利用可能時間: {
    name: "利用可能時間",
    hide_title: true,
    parameters: [
      {
        name: "stjts1_2",
        label: "利用可能時間",
        type: "select_enclose",
        list: [
          {
            name: "stjts",
            type: "select",
            view_small: true,
            options: [
              { code: "", label: "下限なし", orderNo: 0 },
              { code: "0", label: "0", orderNo: 1 },
              { code: "1", label: "1", orderNo: 2 },
              { code: "2", label: "2", orderNo: 3 },
              { code: "3", label: "3", orderNo: 4 },
              { code: "4", label: "4", orderNo: 5 },
              { code: "5", label: "5", orderNo: 6 },
              { code: "6", label: "6", orderNo: 7 },
              { code: "7", label: "7", orderNo: 8 },
              { code: "8", label: "8", orderNo: 9 },
              { code: "9", label: "9", orderNo: 10 },
              { code: "10", label: "10", orderNo: 11 },
              { code: "11", label: "11", orderNo: 12 },
              { code: "12", label: "12", orderNo: 13 },
              { code: "13", label: "13", orderNo: 14 },
              { code: "14", label: "14", orderNo: 15 },
              { code: "15", label: "15", orderNo: 16 },
              { code: "16", label: "16", orderNo: 17 },
              { code: "17", label: "17", orderNo: 18 },
              { code: "18", label: "18", orderNo: 19 },
              { code: "19", label: "19", orderNo: 20 },
              { code: "20", label: "20", orderNo: 21 },
              { code: "21", label: "21", orderNo: 22 },
              { code: "22", label: "22", orderNo: 23 },
              { code: "23", label: "23", orderNo: 24 },
            ],
          },
          {
            type: "block",
            mark: "時",
          },
          {
            type: "block",
            mark: "〜",
            hide_side: true,
          },
          {
            name: "stjte",
            type: "select",
            view_small: true,
            options: [
              { code: "", label: "上限なし", orderNo: 0 },
              { code: "1", label: "1", orderNo: 1 },
              { code: "2", label: "2", orderNo: 2 },
              { code: "3", label: "3", orderNo: 3 },
              { code: "4", label: "4", orderNo: 4 },
              { code: "5", label: "5", orderNo: 5 },
              { code: "6", label: "6", orderNo: 6 },
              { code: "7", label: "7", orderNo: 7 },
              { code: "8", label: "8", orderNo: 8 },
              { code: "9", label: "9", orderNo: 9 },
              { code: "10", label: "10", orderNo: 10 },
              { code: "11", label: "11", orderNo: 11 },
              { code: "12", label: "12", orderNo: 12 },
              { code: "13", label: "13", orderNo: 13 },
              { code: "14", label: "14", orderNo: 14 },
              { code: "15", label: "15", orderNo: 15 },
              { code: "16", label: "16", orderNo: 16 },
              { code: "17", label: "17", orderNo: 17 },
              { code: "18", label: "18", orderNo: 18 },
              { code: "19", label: "19", orderNo: 19 },
              { code: "20", label: "20", orderNo: 20 },
              { code: "21", label: "21", orderNo: 21 },
              { code: "22", label: "22", orderNo: 22 },
              { code: "23", label: "23", orderNo: 23 },
              { code: "24", label: "24", orderNo: 24 },
            ],
          },
          {
            type: "block",
            mark: "時",
          },
        ],
      },
    ],
  },

  時間外対応: {
    name: "時間外対応",
    label: "時間外対応",
    hide_title: true,
    parameters: [
      {
        name: "tj",
        label: "時間外対応",
        type: "checkbox",
        view_small: true,
        options: [
          { code: "10", label: "早朝", orderNo: 1 },
          { code: "20", label: "夜間", orderNo: 2 },
          { code: "30", label: "宿泊", orderNo: 3 },
        ],
      },
    ],
  },

  時間外対応_24時間対応あり: {
    name: "時間外対応_24時間対応あり",
    label: "時間外対応",
    parameters: [
      {
        name: "tj",
        type: "checkbox",
        options: [
          { code: "10", label: "早朝", orderNo: 1 },
          { code: "20", label: "夜間", orderNo: 2 },
          { code: "30", label: "宿泊", orderNo: 3 },
          { code: "40", label: "24時間対応", orderNo: 4 },
        ],
      },
    ],
  },

  時間外対応_訪問系サービス: {
    name: "時間外対応_訪問系サービス",
    label: "時間外対応",
    hide_title: true,
    parameters: [
      {
        name: "hstj",
        label: "時間外対応",
        type: "checkbox",
        options: [
          { code: "10", label: "早朝", orderNo: 1 },
          { code: "20", label: "夜間", orderNo: 2 },
          { code: "40", label: "24時間対応", orderNo: 4 },
        ],
      },
    ],
  },

  サービス対象者_通所系サービス: {
    name: "サービス対象者_通所系サービス",
    label: "サービス対象者",
    parameters: [
      {
        name: "tsst",
        type: "checkbox",
        options: [
          { code: "10", label: "要介護", orderNo: 1 },
          { code: "20", label: "要支援", orderNo: 2 },
          { code: "30", label: "事業対象者", orderNo: 3 },
          { code: "40", label: "一般高齢者", orderNo: 4 },
        ],
      },
    ],
  },

  サービス対象者_訪問系サービス: {
    name: "サービス対象者_訪問系サービス",
    label: "サービス対象者",
    parameters: [
      {
        name: "hssts",
        type: "checkbox",
        options: [
          { code: "10", label: "要介護", orderNo: 1 },
          { code: "20", label: "要支援", orderNo: 2 },
          { code: "30", label: "事業対象者", orderNo: 3 },
          { code: "40", label: "一般高齢者", orderNo: 4 },
        ],
      },
    ],
  },

  実施事業者_訪問系サービス: {
    name: "実施事業者_訪問系サービス",
    label: "実施事業者",
    parameters: [
      {
        name: "hsjj",
        type: "checkbox",
        options: [
          { code: "10", label: "指定ステーション", orderNo: 1 },
          { code: "20", label: "病院", orderNo: 2 },
          { code: "30", label: "診療所", orderNo: 3 },
          { code: "40", label: "介護老人保健施設", orderNo: 4 },
          { code: "50", label: "介護医療院", orderNo: 5 },
        ],
      },
    ],
  },

  スタッフ_訪問系サービス: {
    name: "スタッフ_訪問系サービス",
    label: "スタッフ",
    parameters: [
      {
        name: "hsstf",
        type: "checkbox",
        options: [
          { code: "10", label: "医師", orderNo: 1 },
          { code: "20", label: "保健師", orderNo: 2 },
          { code: "30", label: "看護師", orderNo: 3 },
          { code: "40", label: "PT", orderNo: 4 },
          { code: "50", label: "OT", orderNo: 5 },
          { code: "60", label: "ST", orderNo: 6 },
          { code: "70", label: "社会福祉士", orderNo: 7 },
          { code: "80", label: "介護福祉士", orderNo: 8 },
          { code: "90", label: "男性スタッフ在籍", orderNo: 9 },
        ],
      },
    ],
  },

  定員: {
    name: "定員",
    label: "定員",
    parameters: [
      {
        name: "tn1_2",
        type: "select_enclose",
        list: [
          {
            name: "tn1",
            type: "select",
            options: [
              { code: "", label: "下限なし", orderNo: 0 },
              { code: "10", label: "10人以上", orderNo: 1 },
              { code: "18", label: "18人以上", orderNo: 2 },
              { code: "25", label: "25人以上", orderNo: 3 },
              { code: "31", label: "31人以上", orderNo: 4 },
            ],
          },
          {
            type: "block",
            mark: "〜",
            hide_side: true,
          },
          {
            name: "tn2",
            type: "select",
            options: [
              { code: "", label: "上限なし", orderNo: 0 },
              { code: "10", label: "10人以上", orderNo: 1 },
              { code: "18", label: "18人以上", orderNo: 2 },
              { code: "25", label: "25人以上", orderNo: 3 },
              { code: "31", label: "31人以上", orderNo: 4 },
            ],
          },
        ],
      },
    ],
  },

  介護予防支援_居宅介護支援: {
    name: "介護予防支援_居宅介護支援",
    label: "介護予防支援",
    parameters: [
      {
        name: "sets",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "あり", orderNo: 1 },
        ],
      },
    ],
  },

  連絡対応可能時間_居宅介護支援: {
    name: "連絡対応可能時間_居宅介護支援",
    label: "電話対応可能時間",
    parameters: [
      {
        name: "sertkj",
        type: "checkbox",
        options: [{ code: "10", label: "緊急時連絡対応可", orderNo: 1 }],
      },
    ],
  },

  訪問対応可能日_居宅介護支援: {
    name: "訪問対応可能日_居宅介護支援",
    label: "訪問対応可能日",
    parameters: [
      {
        name: "sehtkb",
        type: "checkbox",
        view_small: true,
        options: [
          { code: "10", label: "平日", orderNo: 1 },
          { code: "20", label: "土曜", orderNo: 2 },
          { code: "30", label: "日曜", orderNo: 3 },
          { code: "40", label: "祝日", orderNo: 4 },
        ],
      },
    ],
  },

  取得加算_居宅介護支援: {
    name: "取得加算_居宅介護支援",
    label: "取得加算",
    parameters: [
      {
        name: "sesk",
        type: "checkbox",
        options: [
          { code: "10", label: "退院・退所加算", orderNo: 1 },
          {
            code: "20",
            label: "小規模多機能型居宅介護事業所連携加算",
            orderNo: 2,
          },
          {
            code: "30",
            label: "看護小規模多機能型居宅介護事業所連携加算",
            orderNo: 3,
          },
          { code: "40", label: "緊急時等居宅カンファレンス加算", orderNo: 4 },
        ],
      },
    ],
  },

  スタッフ_居宅介護支援: {
    name: "スタッフ_居宅介護支援",
    label: "スタッフ",
    parameters: [
      {
        name: "sest",
        type: "checkbox",
        options: [{ code: "10", label: "男性スタッフ在籍", orderNo: 1 }],
      },
    ],
  },

  窓口営業日_短期入所系サービス: {
    name: "窓口営業日_短期入所系サービス業日",
    label: "窓口営業日",
    parameters: [
      {
        name: "eb",
        type: "checkbox",
        view_small: true,
        options: [
          { code: "10", label: "平日", orderNo: 1 },
          { code: "20", label: "土曜", orderNo: 2 },
          { code: "30", label: "日曜", orderNo: 3 },
          { code: "40", label: "祝日", orderNo: 4 },
          { code: "50", label: "365日対応", orderNo: 5 },
        ],
      },
    ],
  },

  サービス対象者_短期入所系サービス: {
    name: "サービス対象者_短期入所系サービス",
    label: "サービス対象者",
    parameters: [
      {
        name: "srtsts",
        type: "checkbox",
        options: [
          { code: "10", label: "要介護", orderNo: 1 },
          { code: "20", label: "要支援", orderNo: 2 },
          { code: "30", label: "事業対象者", orderNo: 3 },
          { code: "40", label: "一般高齢者", orderNo: 4 },
        ],
      },
    ],
  },

  実施事業者_短期入所系サービス: {
    name: "実施事業者_短期入所系サービス",
    label: "実施事業者",
    parameters: [
      {
        name: "srtjj",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "介護老人保健施設", orderNo: 1 },
          { code: "20", label: "特別養護老人ホーム", orderNo: 2 },
          { code: "30", label: "養護老人ホーム", orderNo: 3 },
          { code: "40", label: "老人短期入所施設", orderNo: 4 },
          { code: "50", label: "特定施設入居者生活介護", orderNo: 5 },
          { code: "60", label: "地域密着型特定施設入居者生活介護", orderNo: 6 },
          { code: "70", label: "病院", orderNo: 7 },
          { code: "80", label: "診療所", orderNo: 8 },
          { code: "90", label: "介護療養型医療施設", orderNo: 9 },
          { code: "100", label: "介護医療院", orderNo: 10 },
        ],
      },
    ],
  },

  居室_短期入所系サービス: {
    name: "居室_短期入所系サービス",
    label: "居室",
    parameters: [
      {
        label: "タイプ",
        name: "srtks1",
        type: "checkbox",
        options: [
          { code: "10", label: "従来型個室", orderNo: 1 },
          { code: "20", label: "多床室", orderNo: 2 },
          { code: "30", label: "ユニット型個室", orderNo: 3 },
          { code: "40", label: "ユニット型個室的多床室", orderNo: 4 },
          { code: "50", label: "在宅強化型", orderNo: 5 },
        ],
      },
      {
        label: "様式",
        name: "srtks2",
        type: "checkbox",
        view_small: true,
        options: [
          { code: "10", label: "和室", orderNo: 1 },
          { code: "20", label: "洋室", orderNo: 2 },
        ],
      },
    ],
  },

  写真登録: {
    name: "写真登録",
    label: "写真登録",
    parameters: [
      {
        name: "st",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "写真あり", orderNo: 1 },
        ],
      },
    ],
  },

  昼食料金: {
    name: "昼食料金",
    label: "昼食料金",
    parameters: [
      {
        name: "cr",
        type: "select",
        options: [
          { code: "", label: "上限なし", orderNo: 0 },
          { code: "400", label: "400円以下", orderNo: 1 },
          { code: "500", label: "500円以下", orderNo: 2 },
          { code: "600", label: "600円以下", orderNo: 3 },
          { code: "700", label: "700円以下", orderNo: 4 },
        ],
      },
    ],
  },

  スタッフ: {
    name: "スタッフ",
    label: "スタッフ",
    parameters: [
      {
        name: "sf",
        type: "checkbox",
        options: [
          { code: "10", label: "看護師常駐", orderNo: 1 },
          { code: "20", label: "リハ職勤務", orderNo: 2 },
        ],
      },
    ],
  },

  入浴: {
    name: "入浴",
    label: "入浴",
    parameters: [
      {
        label: "有無",
        name: "ny1",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "あり", orderNo: 1 },
          { code: "20", label: "なし", orderNo: 2 },
        ],
      },
      {
        label: "種類",
        name: "ny2",
        type: "checkbox",
        options: [
          { code: "10", label: "機械浴", orderNo: 1 },
          { code: "20", label: "個浴", orderNo: 2 },
        ],
      },
    ],
  },

  個別機能訓練: {
    name: "個別機能訓練",
    label: "個別機能訓練",
    parameters: [
      {
        label: "有無",
        name: "kk1",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "あり", orderNo: 1 },
          { code: "20", label: "なし", orderNo: 2 },
        ],
      },
      {
        label: "種類",
        name: "kk2",
        type: "checkbox",
        options: [
          { code: "10", label: "PT", orderNo: 1 },
          { code: "20", label: "OT", orderNo: 2 },
          { code: "30", label: "ST", orderNo: 3 },
        ],
      },
    ],
  },

  送迎: {
    name: "送迎",
    label: "送迎",
    parameters: [
      {
        name: "sg",
        type: "checkbox",
        options: [
          { code: "10", label: "リフト付き（車椅子）", orderNo: 1 },
          { code: "20", label: "個別送迎", orderNo: 2 },
          { code: "30", label: "自宅内介助可", orderNo: 3 },
        ],
      },
    ],
  },

  特化分野: {
    name: "特化分野",
    label: "特化分野",
    parameters: [
      {
        name: "tb",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "機能訓練特化型", orderNo: 1 },
          { code: "20", label: "入浴特化型", orderNo: 2 },
        ],
      },
    ],
  },

  配達ができる食事: {
    name: "配達ができる食事",
    label: "配達ができる食事",
    parameters: [
      {
        name: "hs",
        type: "checkbox",
        view_small: true,
        options: [
          { code: "10", label: "朝食", orderNo: 1 },
          { code: "20", label: "昼食", orderNo: 2 },
          { code: "30", label: "夕食", orderNo: 3 },
        ],
      },
    ],
  },

  メニュー区分: {
    name: "メニュー区分",
    label: "メニュー区分",
    parameters: [
      {
        name: "mk",
        type: "checkbox",
        options: [
          { code: "10", label: "普通食", orderNo: 1 },
          { code: "20", label: "エネルギー等調整食", orderNo: 2 },
          { code: "30", label: "やわらかさ調整食", orderNo: 3 },
        ],
      },
    ],
  },

  対応してくれるサービス_配食サービス: {
    name: "対応してくれるサービス_配食サービス",
    label: "対応してくれるサービス",
    parameters: [
      {
        name: "hsos",
        type: "checkbox",
        options: [
          { code: "10", label: "固さ調整要望対応", orderNo: 1 },
          { code: "20", label: "アレルギー対応", orderNo: 2 },
          { code: "30", label: "介護家族のお弁当も一緒に配達", orderNo: 3 },
          { code: "40", label: "安否確認サービス", orderNo: 4 },
          { code: "50", label: "無料試食", orderNo: 5 },
        ],
      },
    ],
  },

  対応してくれるサービス_生活支援: {
    name: "対応してくれるサービス_生活支援",
    label: "対応してくれるサービス",
    parameters: [
      {
        name: "ssos",
        type: "checkbox",
        options: [
          { code: "10", label: "介護保険サービスとの併用", orderNo: 1 },
          { code: "20", label: "1回からの利用", orderNo: 2 },
          { code: "30", label: "短時間利用（30分～）", orderNo: 3 },
          { code: "40", label: "長時間利用（2時間～）", orderNo: 4 },
          { code: "50", label: "早朝対応", orderNo: 5 },
          { code: "60", label: "夜間対応", orderNo: 6 },
          { code: "70", label: "宿泊対応", orderNo: 7 },
          { code: "80", label: "男性スタッフ", orderNo: 8 },
          { code: "90", label: "同一スタッフ指名", orderNo: 9 },
        ],
      },
    ],
  },

  対応状況_訪問理美容: {
    name: "対応状況_訪問理美容",
    label: "対応状況",
    parameters: [
      {
        name: "bbtj",
        type: "checkbox",
        options: [
          { code: "10", label: "認知症対応", orderNo: 1 },
          { code: "20", label: "麻痺対応", orderNo: 2 },
          { code: "30", label: "寝たきり対応", orderNo: 3 },
        ],
      },
    ],
  },

  オプション_遺品整理生前整理: {
    name: "オプション_遺品整理生前整理",
    label: "オプション",
    parameters: [
      {
        name: "mmop",
        type: "checkbox",
        options: [
          { code: "10", label: "見積もり無料", orderNo: 1 },
          { code: "20", label: "女性スタッフによる作業", orderNo: 2 },
        ],
      },
    ],
  },

  オプション_葬儀相続: {
    name: "オプション_葬儀相続",
    label: "オプション",
    parameters: [
      {
        name: "ffop",
        type: "checkbox",
        options: [
          { code: "10", label: "事前見積書作成無料", orderNo: 1 },
          { code: "20", label: "対面相談無料", orderNo: 2 },
          { code: "30", label: "専門スタッフが対応", orderNo: 3 },
        ],
      },
    ],
  },

  提供サービス_訪問系サービス: {
    name: "提供サービス_訪問系サービス",
    label: "提供サービス",
    parameters: [
      {
        name: "hsts",
        type: "checkbox",
        options: [
          { code: "10", label: "介護保険", orderNo: 1 },
          { code: "20", label: "予防給付相当", orderNo: 2 },
          { code: "30", label: "基準緩和型（A型）", orderNo: 3 },
          { code: "40", label: "短期集中型（C型）", orderNo: 4 },
          { code: "50", label: "自費利用", orderNo: 5 },
        ],
      },
    ],
  },

  特化分野_訪問系サービス: {
    name: "特化分野_訪問型サービス",
    label: "特化分野",
    parameters: [
      {
        name: "hstb",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "生活援助特化型（介護）", orderNo: 1 },
          { code: "20", label: "認知症ケア専門特化型（看護）", orderNo: 2 },
        ],
      },
    ],
  },

  提供保険外サービス_訪問系サービス: {
    name: "提供保険外サービス_訪問系サービス",
    label: "提供保険外サービス",
    parameters: [
      {
        name: "hshkg",
        type: "checkbox",
        options: [
          { code: "10", label: "通院同行", orderNo: 1 },
          { code: "20", label: "入退院時サポート", orderNo: 2 },
          { code: "30", label: "外出支援", orderNo: 3 },
          { code: "40", label: "家事・代行", orderNo: 4 },
          { code: "50", label: "日用品以外の買い物", orderNo: 5 },
          { code: "60", label: "ペットの世話", orderNo: 6 },
          { code: "70", label: "庭の掃除", orderNo: 7 },
          { code: "80", label: "移送サポート（看護師）", orderNo: 8 },
        ],
      },
    ],
  },

  取得加算_訪問系サービス: {
    name: "取得加算_訪問系サービス",
    label: "取得加算",
    parameters: [
      {
        name: "hssk",
        type: "checkbox",
        options: [
          { code: "10", label: "緊急時訪問看護加算", orderNo: 1 },
          { code: "20", label: "緊急時訪問介護加算", orderNo: 2 },
          { code: "30", label: "ターミナルケア体制加算", orderNo: 3 },
          { code: "40", label: "24時間通報対応加算", orderNo: 4 },
          { code: "50", label: "生活機能向上連携加算", orderNo: 5 },
          { code: "60", label: "移行支援加算", orderNo: 6 },
        ],
      },
    ],
  },

  対応可能医療行為_訪問系サービス: {
    name: "対応可能医療行為_訪問系サービス",
    label: "受入れ / 対応可能な医療行為",
    parameters: [
      {
        name: "hstki",
        type: "checkbox",
        options: [
          { code: "10", label: "腸ろう管理", orderNo: 1 },
          { code: "20", label: "膀胱ろう管理", orderNo: 2 },
          { code: "30", label: "人工肛門管理", orderNo: 3 },
          { code: "40", label: "人工膀胱管理", orderNo: 4 },
          { code: "50", label: "カテーテル管理", orderNo: 5 },
          { code: "60", label: "麻薬（疼痛コントロール）", orderNo: 6 },
          { code: "70", label: "気管カニューレ管理", orderNo: 7 },
          { code: "80", label: "人工呼吸器管理", orderNo: 8 },
          { code: "90", label: "ターミナルケア", orderNo: 9 },
          { code: "100", label: "エンゼルケア", orderNo: 10 },
          { code: "110", label: "腹膜透析", orderNo: 11 },
        ],
      },
    ],
  },

  対応可能疾患_訪問系サービス: {
    name: "対応可能疾患_訪問系サービス",
    label: "対応可能疾患",
    parameters: [
      {
        name: "hstks",
        type: "checkbox",
        options: [
          { code: "10", label: "精神疾患", orderNo: 1 },
          { code: "20", label: "聴覚障害", orderNo: 2 },
          { code: "30", label: "知的障害", orderNo: 3 },
          { code: "40", label: "視覚障害", orderNo: 4 },
          { code: "50", label: "悪性新生物", orderNo: 5 },
          { code: "60", label: "進行性神経筋疾患", orderNo: 6 },
        ],
      },
    ],
  },

  併設サービス_訪問系サービス: {
    name: "併設サービス_訪問系サービス",
    label: "併設サービス",
    parameters: [
      {
        name: "hshs",
        type: "checkbox",
        options: [
          { code: "10", label: "居宅介護支援", orderNo: 1 },
          { code: "20", label: "訪問介護", orderNo: 2 },
          { code: "30", label: "訪問入浴介護", orderNo: 3 },
          { code: "40", label: "訪問看護", orderNo: 4 },
          { code: "50", label: "訪問リハビリテーション", orderNo: 5 },
          { code: "60", label: "定期巡回・随時対応型訪問介護看護", orderNo: 6 },
          { code: "70", label: "通所介護", orderNo: 7 },
          { code: "80", label: "通所リハビリテーション", orderNo: 8 },
          { code: "90", label: "居宅療養管理指導", orderNo: 9 },
          { code: "100", label: "障害福祉サービス", orderNo: 10 },
        ],
      },
    ],
  },

  訪問手段_訪問系サービス: {
    name: "訪問手段_訪問系サービス",
    label: "訪問手段",
    parameters: [
      {
        name: "hshsd",
        type: "checkbox",
        options: [
          { code: "10", label: "軽自動車", orderNo: 1 },
          { code: "20", label: "原動機付自転車（原付）", orderNo: 2 },
          { code: "30", label: "自転車", orderNo: 3 },
        ],
      },
    ],
  },

  入浴サービス_訪問系サービス: {
    name: "入浴サービス_訪問系サービス",
    label: "入浴サービス",
    parameters: [
      {
        name: "hsns",
        type: "checkbox",
        options: [
          { code: "10", label: "タオル貸し出しあり", orderNo: 1 },
          { code: "20", label: "シーツ交換あり", orderNo: 2 },
          { code: "30", label: "洗髪機あり", orderNo: 3 },
          { code: "40", label: "入浴剤使用", orderNo: 4 },
          { code: "50", label: "天然温泉", orderNo: 5 },
          { code: "60", label: "爪切り", orderNo: 6 },
          { code: "70", label: "髭剃り", orderNo: 7 },
        ],
      },
    ],
  },

  一体型連携型_訪問系サービス: {
    name: "一体型連携型_訪問型サービス",
    label: "一体型・連携型",
    parameters: [
      {
        name: "hsir",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "一体型", orderNo: 1 },
          { code: "20", label: "連携型", orderNo: 2 },
        ],
      },
    ],
  },

  提供サービス_短期入所系サービス: {
    name: "提供サービス_短期入所系サービス",
    label: "提供サービス",
    parameters: [
      {
        name: "srtts",
        type: "checkbox",
        options: [
          { code: "10", label: "介護保険", orderNo: 1 },
          { code: "20", label: "予防給付相当", orderNo: 2 },
          { code: "30", label: "自費利用", orderNo: 3 },
        ],
      },
    ],
  },

  緊急時の受け入れ_短期入所系サービス: {
    name: "緊急時の受け入れ_短期入所系サービス",
    label: "緊急時の受け入れ",
    parameters: [
      {
        name: "srtky",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "あり", orderNo: 1 },
        ],
      },
    ],
  },

  スタッフ_短期入所系サービス: {
    name: "スタッフ_短期入所系サービス",
    label: "スタッフ",
    parameters: [
      {
        name: "srtst",
        type: "checkbox",
        options: [
          { code: "10", label: "医師", orderNo: 1 },
          { code: "20", label: "看護師", orderNo: 2 },
          { code: "30", label: "歯科衛生士", orderNo: 3 },
          { code: "40", label: "管理栄養士", orderNo: 4 },
          { code: "50", label: "PT", orderNo: 5 },
          { code: "60", label: "OT", orderNo: 6 },
          { code: "70", label: "ST", orderNo: 7 },
        ],
      },
    ],
  },

  取得加算_短期入所系サービス: {
    name: "取得加算_短期入所系サービス",
    label: "取得加算",
    parameters: [
      {
        name: "srtsk",
        type: "checkbox",
        options: [
          { code: "10", label: "機能訓練体制加算", orderNo: 1 },
          { code: "20", label: "生活機能向上連携加算", orderNo: 2 },
          { code: "30", label: "個別機能訓練加算", orderNo: 3 },
          { code: "40", label: "若年性認知症利用者受入加算", orderNo: 4 },
          { code: "50", label: "重度療養管理加算", orderNo: 5 },
          { code: "60", label: "個別リハビリテーション実施加算", orderNo: 6 },
        ],
      },
    ],
  },

  対応可能医療行為_短期入所系サービス: {
    name: "対応可能医療行為_短期入所系サービス",
    label: "受入れ / 対応可能な医療行為",
    parameters: [
      {
        name: "srttki",
        type: "checkbox",
        options: [
          { code: "10", label: "腸ろう管理", orderNo: 1 },
          { code: "20", label: "膀胱ろう管理", orderNo: 2 },
          { code: "30", label: "人工肛門管理", orderNo: 3 },
          { code: "40", label: "人工膀胱管理", orderNo: 4 },
          { code: "50", label: "カテーテル管理", orderNo: 5 },
          { code: "60", label: "麻薬（疼痛コントロール）", orderNo: 6 },
          { code: "70", label: "気管カニューレ管理", orderNo: 7 },
          { code: "80", label: "人工呼吸器管理", orderNo: 8 },
          { code: "90", label: "ターミナルケア", orderNo: 9 },
          { code: "100", label: "エンゼルケア", orderNo: 10 },
        ],
      },
    ],
  },

  対応可能疾患_短期入所系サービス: {
    name: "対応可能疾患_短期入所系サービス",
    label: "受入れ可能な疾患",
    parameters: [
      {
        name: "srttks",
        type: "checkbox",
        options: [
          { code: "10", label: "悪性新生物", orderNo: 1 },
          { code: "20", label: "進行性神経筋疾患", orderNo: 2 },
          { code: "30", label: "精神疾患", orderNo: 3 },
          { code: "40", label: "聴覚障害", orderNo: 4 },
          { code: "50", label: "知的障害", orderNo: 5 },
          { code: "60", label: "視覚障害", orderNo: 6 },
        ],
      },
    ],
  },

  入浴サービス_短期入所系サービス: {
    name: "入浴サービス_短期入所系サービス",
    label: "入浴サービス",
    parameters: [
      {
        label: "種類",
        name: "srtny1",
        type: "checkbox",
        options: [
          { code: "10", label: "機械浴", orderNo: 1 },
          { code: "20", label: "個浴", orderNo: 2 },
          { code: "30", label: "居室備え付け", orderNo: 2 },
        ],
      },
      {
        label: "入浴頻度",
        name: "srtny2",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "週１回以上", orderNo: 1 },
          { code: "20", label: "週２回以上", orderNo: 2 },
          { code: "30", label: "週３回以上", orderNo: 3 },
          { code: "40", label: "毎日", orderNo: 4 },
        ],
      },
    ],
  },

  個別機能訓練_短期入所系サービス: {
    name: "個別機能訓練_短期入所系サービス",
    label: "リハビリテーション・個別機能訓練",
    parameters: [
      {
        label: "有無",
        name: "srtkk1",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "あり", orderNo: 1 },
          { code: "20", label: "なし", orderNo: 2 },
        ],
      },
      {
        label: "種類",
        name: "srtkk2",
        type: "checkbox",
        options: [
          { code: "10", label: "PT", orderNo: 1 },
          { code: "20", label: "OT", orderNo: 2 },
          { code: "30", label: "ST", orderNo: 3 },
        ],
      },
    ],
  },

  送迎_短期入所系サービス: {
    name: "送迎_短期入所系サービス",
    label: "送迎サービス",
    parameters: [
      {
        name: "srtsg",
        type: "checkbox",
        options: [
          { code: "10", label: "リフト付き（車椅子）", orderNo: 1 },
          { code: "20", label: "個別送迎", orderNo: 2 },
          { code: "30", label: "自宅内介助可", orderNo: 3 },
          { code: "40", label: "車椅子貸出あり", orderNo: 4 },
        ],
      },
    ],
  },

  業種: {
    name: "業種",
    label: "業種",
    parameters: [
      {
        name: "mg",
        type: "radio",
        options: [
          { code: "", label: "指定なし", orderNo: 0 },
          { code: "10", label: "金融", orderNo: 1 },
          { code: "20", label: "交通", orderNo: 2 },
          { code: "30", label: "コンビニエンスストア", orderNo: 3 },
          { code: "40", label: "商店", orderNo: 4 },
          { code: "50", label: "スーパーマーケット", orderNo: 5 },
          { code: "60", label: "生協", orderNo: 6 },
          { code: "70", label: "農協", orderNo: 7 },
          { code: "80", label: "物流・配達", orderNo: 8 },
          { code: "90", label: "不動産", orderNo: 9 },
          { code: "100", label: "郵便", orderNo: 10 },
          { code: "110", label: "ライフライン(ガス・水道・電気)", orderNo: 11 },
          { code: "120", label: "その他", orderNo: 12 },
        ],
      },
    ],
  },

  見守り内容: {
    name: "見守り内容",
    label: "見守り内容",
    parameters: [
      {
        name: "mn",
        type: "checkbox",
        options: [
          { code: "10", label: "訪問時の異変察知・報告", orderNo: 1 },
          { code: "20", label: "電話時の異変察知・報告", orderNo: 2 },
          { code: "30", label: "来客・窓口業務時の異変察知・報告", orderNo: 3 },
          { code: "40", label: "店舗スペースの提供", orderNo: 4 },
          {
            code: "50",
            label: "福祉サービスの普及啓発（ポスター掲載等）",
            orderNo: 5,
          },
          { code: "60", label: "医療・福祉相談サービス", orderNo: 6 },
          { code: "70", label: "その他", orderNo: 7 },
        ],
      },
    ],
  },
};

export function searchConditionFunnelTypeValues() {
  return Object.keys(SearchConditionFunnelType).map(
    (key) => SearchConditionFunnelType[key]
  );
}

export default SearchConditionFunnelType;
