import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as fileUploadActions from "../../../../../state/file-upload/actions";
import * as jigyoshoFormActions from "../../../../../state/jigyosho-form/actions";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  MASTER_DATA_BUNDLE_FOR_HOKENGAI,
  withMasterData,
} from "../../../../../state/master-data";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import Dropzone from "../../../../../app/ui/dropzone/Dropzone";
import { IconButton } from "../../../../../../node_modules/@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import FlatPrimaryButton from "../../../../../app/ui/button/FlatPrimaryButton";
import FormGroupingDeleteButton from "../../../../../app/ui/button/FormGroupingDeleteButton";

class PromotionForm extends React.Component {
  constructor(props) {
    super(props);
    this.handlePromotionItemAdded = this.handlePromotionItemAdded.bind(this);
    this.handlePromotionItemRemoved = this.handlePromotionItemRemoved.bind(
      this
    );
    this.handleDrop = this.handleDrop.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
  }

  handlePromotionItemAdded() {
    this.appendPromotionItem({
      title: "",
      detail: "",
      uploadFileForm: null,
    });
  }

  handlePromotionItemRemoved(index) {
    this.removePromotionItem(index);
  }

  handleDrop(name, files) {
    const { actions, bindingProps } = this.props;
    actions.uploadFiles([files[0]], (data) => {
      const file = data.contents[0];
      bindingProps.onChange(name, file);
    });
  }

  handleRemove(name) {
    const { bindingProps } = this.props;
    bindingProps.onChange(name, undefined);
  }

  getPromotionItems() {
    const { bindingProps } = this.props;
    return [].concat(bindingProps.form.getByName("itemForms"));
  }

  appendPromotionItem(form) {
    const { bindingProps } = this.props;
    const org = this.getPromotionItems();
    bindingProps.onChange("itemForms", org.concat([form]));
  }

  removePromotionItem(index) {
    const { bindingProps } = this.props;
    const org = this.getPromotionItems();
    bindingProps.onChange(
      "itemForms",
      org.filter((_, i) => i != index)
    );
  }

  render() {
    return (
      <Fragment>
        {this.getPromotionItems().map((item, i) => {
          return this.renderPromotion(item, i);
        })}
        {this.renderItemAdd()}
      </Fragment>
    );
  }

  renderItemAdd() {
    return (
      <FlatPrimaryButton onClick={this.handlePromotionItemAdded}>
        項目を追加する
      </FlatPrimaryButton>
    );
  }

  renderPromotion(item, i) {
    const { bindingProps } = this.props;
    const item_form_name = `itemForms.${i}`;
    return (
      <FormGrouping
        key={i}
        title={
          <Fragment>
            項目{i + 1}
            <FormGroupingDeleteButton
              onClick={() => this.handlePromotionItemRemoved(i)}
            />
          </Fragment>
        }
      >
        <GridRow>
          <GridCell grow={3}>
            <SpaceOut block>
              <HorizontalLabelLayout
                labelText={
                  <span>
                    タイトル
                    <br />
                    （キャッチフレーズ）
                  </span>
                }
                required
              >
                <InputTypeText
                  name={`${item_form_name}.title`}
                  {...bindingProps}
                />
              </HorizontalLabelLayout>
              <HorizontalLabelLayout labelText="詳細" required>
                <Textarea name={`${item_form_name}.detail`} {...bindingProps} />
              </HorizontalLabelLayout>
            </SpaceOut>
          </GridCell>
          <GridCell>
            <SpaceOut block>
              {(function (onImage, onDropzone) {
                const image_name = `${item_form_name}.uploadFileForm`;
                const file = bindingProps.form.getByName(image_name);
                return file
                  ? onImage(image_name, file)
                  : onDropzone(image_name);
              })(
                (name, file) => (
                  <div style={{ position: "relative" }}>
                    <img
                      src={file.filePath}
                      alt={file.originalFilename}
                      title={file.originalFilename}
                      style={{ width: "100%" }}
                    />
                    <IconButton
                      onClick={() => this.handleRemove(name)}
                      style={{ position: "absolute", top: 5, right: 5 }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                ),
                (name) => (
                  <Dropzone onDrop={(files) => this.handleDrop(name, files)} />
                )
              )}
            </SpaceOut>
          </GridCell>
        </GridRow>
      </FormGrouping>
    );
  }
}

PromotionForm.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bindingProps: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOKENGAI)(
  connect(mapStateToProps, mapDispatchToProps)(PromotionForm)
);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(jigyoshoFormActions, dispatch),
      uploadFiles: (files, callback) =>
        dispatch(fileUploadActions.uploadFiles(files, callback)),
    },
  };
}
