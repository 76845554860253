import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const KyoshitsuTable = ({ contents, detail }) => {
  const {
    blocks: { shortstayKyoshitsu: kyoshitsu },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>居室タイプ</th>
              <td>{kyoshitsu.kyoshitsuTypeList.join("、")}</td>
            </tr>
            <tr>
              <th>居室様式</th>
              <td>{kyoshitsu.kyoshitsuYoshikiList.join("、")}</td>
            </tr>
            <Mount if={detail}>
              <tr>
                <th>備考</th>
                <td>{kyoshitsu.biko}</td>
              </tr>
            </Mount>
          </tbody>
        </table>
      </div>

      <Mount if={!detail}>{kyoshitsuTable(kyoshitsu)}</Mount>
      <Mount if={detail}>
        <div className="p-office-body-title">居室数・床面積</div>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            {kyoshitsuTable(kyoshitsu)}
          </div>
          <div className="p-office-body-sep__right">
            {yukamensekiTable(kyoshitsu)}
          </div>
        </div>
      </Mount>

      <Mount if={detail}>
        <div className="p-office-body-sep2">
          <div className="p-office-body-sep__left">
            <div className="p-office-body-title">室内設備</div>
            {shitsunaisetsubiTable(kyoshitsu)}
          </div>
          <div className="p-office-body-sep__right">
            <Mount
              if={taizaihiIsAvailable(kyoshitsu)}
              then={() => (
                <Fragment>
                  <div className="p-office-body-title">滞在費</div>
                  {taizaihiTable(kyoshitsu)}
                </Fragment>
              )}
            />
          </div>
        </div>
      </Mount>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.shortstayKyoshitsu
      )}
    </Fragment>
  );
};

function kyoshitsuTable(kyoshitsu) {
  return (
    <div className="p-office-body-table">
      <table>
        <tbody>
          <tr>
            <th>居室数</th>
            <td className="val">
              <ul>
                <li>
                  <div className="val-name">個室</div>
                  <div className="val-val">{kyoshitsu.koshitsuKazu1}</div>
                </li>
                <li>
                  <div className="val-name">２人部屋</div>
                  <div className="val-val">{kyoshitsu.koshitsuKazu2}</div>
                </li>
                <li>
                  <div className="val-name">３人部屋</div>
                  <div className="val-val">{kyoshitsu.koshitsuKazu3}</div>
                </li>
                <li>
                  <div className="val-name">４人部屋</div>
                  <div className="val-val">{kyoshitsu.koshitsuKazu4}</div>
                </li>
                <li>
                  <div className="val-name">５人部屋以上</div>
                  <div className="val-val">{kyoshitsu.koshitsuKazu5}</div>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function yukamensekiTable(kyoshitsu) {
  return (
    <div className="p-office-body-table">
      <table>
        <tbody>
          <tr>
            <th>床面積</th>
            <td className="val">
              <ul>
                <li>
                  <div className="val-name">個室</div>
                  <div className="val-val">{kyoshitsu.koshitsuMenseki1}</div>
                </li>
                <li>
                  <div className="val-name">２人部屋</div>
                  <div className="val-val">{kyoshitsu.koshitsuMenseki2}</div>
                </li>
                <li>
                  <div className="val-name">３人部屋</div>
                  <div className="val-val">{kyoshitsu.koshitsuMenseki3}</div>
                </li>
                <li>
                  <div className="val-name">４人部屋</div>
                  <div className="val-val">{kyoshitsu.koshitsuMenseki4}</div>
                </li>
                <li>
                  <div className="val-name">５人部屋以上</div>
                  <div className="val-val">{kyoshitsu.koshitsuMenseki5}</div>
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function shitsunaisetsubiTable(kyoshitsu) {
  return (
    <div className="p-office-body-table">
      <table>
        <tbody>
          <tr>
            <th>室内設備</th>
            <td>{kyoshitsu.shitsunaiSetsubiList.join("、")}</td>
          </tr>
          <tr>
            <th>その他設備等</th>
            <td>{kyoshitsu.shitsunaisetsubiSonota}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

function taizaihiIsAvailable(kyoshitsu) {
  return Object.keys(kyoshitsu.taizaihiMap).length > 0;
}

function taizaihiTable(kyoshitsu) {
  return Object.keys(kyoshitsu.taizaihiMap).map((key, keyIndex) => {
    return (
      <div className="p-office-body-table2" key={keyIndex}>
        <div className="p-office-body-table2__th" style={{ paddingRight: 15 }}>
          {key}
        </div>
        <div className="p-office-body-table2__td">
          {/* 負担段階 */}
          {Object.values(kyoshitsu.taizaihiMap)[keyIndex].map(
            (item, valueIndex) => {
              return (
                <ul key={valueIndex}>
                  <li>
                    <div>{item.futanDankai}</div>
                    <div>{item.taizaihi}</div>
                  </li>
                </ul>
              );
            }
          )}
        </div>
      </div>
    );
  });
}

KyoshitsuTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default KyoshitsuTable;
