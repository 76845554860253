import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { serviceShubetsuCategoryHeadViewTypeValueOf } from "./ServiceShubetsuCategoryHeadViewType";
import Sheet from "./print/Sheet";
import { withServiceSearchList } from "../../../state/service-search";

class ServiceListPrintPage extends React.Component {
  render() {
    return (
      <Fragment>
        <Sheet>
          {this.renderHeader()}
          {this.renderBody()}
        </Sheet>
      </Fragment>
    );
  }

  renderHeader() {
    const viewType = serviceShubetsuCategoryHeadViewTypeValueOf(
      this.props.serviceShubetsuCategoryCode
    );
    const Component = viewType.tableHead;
    return <Component />;
  }

  renderBody() {
    const { serviceSearch } = this.props;
    if (serviceSearch.list < 1) return null;
    const viewType = serviceShubetsuCategoryHeadViewTypeValueOf(
      this.props.serviceShubetsuCategoryCode
    );
    const Component = viewType.table;
    const props = {
      onServiceButtonClick: () => {},
      service: undefined,
      selected: false,
      onSelect: () => {},
    };
    return serviceSearch.list.map((service) => (
      <Component key={service.id} {...{ ...props, service }} />
    ));
  }
}

ServiceListPrintPage.propTypes = {
  serviceSearch: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
};

export default withServiceSearchList(
  connect(mapStateToProps)(ServiceListPrintPage)
);

function mapStateToProps(state) {
  return {
    serviceSearch: state.serviceSearch,
  };
}
