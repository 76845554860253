import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import BackOfficeHelp from "../../../../app/ui/structure/BackOfficeHelp";
import BackOfficeHelpItem from "../../../../app/ui/structure/BackOfficeHelpItem";

class JigyoshoListInformation extends React.Component {
  constructor(props) {
    super(props);
    this.handleShowAllButtonClick = this.handleShowAllButtonClick.bind(this);
  }

  handleShowAllButtonClick() {
    this.props.onShowAllButtonClick();
  }

  render() {
    return (
      <BackOfficeHelp>
        <BackOfficeHelpItem
          text={`「${this.props.group.name}」の事業所を一覧しています。`}
          action={
            <FlatPrimaryButton onClick={this.handleShowAllButtonClick}>
              すべての事業所を表示する
            </FlatPrimaryButton>
          }
        />
      </BackOfficeHelp>
    );
  }
}

JigyoshoListInformation.propTypes = {
  // state
  group: PropTypes.object.isRequired,
  // action
  onShowAllButtonClick: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JigyoshoListInformation);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onShowAllButtonClick: () => {
      dispatch(push(Pathname.backoffice_jigyosho));
    },
  };
}
