import React from "react";
import PropTypes from "prop-types";
import Margin from "./Margin";

const SideMargin = (props) => {
  return <Margin {...mergeProps(props)}></Margin>;
};

function mergeProps(props) {
  const newProps = { ...props };
  newProps.left = true;
  newProps.right = true;
  return newProps;
}

SideMargin.propTypes = {
  bottom: PropTypes.bool,
  top: PropTypes.bool,
};

export default SideMargin;
