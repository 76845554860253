import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../state/service-search/actions";
import { push } from "react-router-redux";
import { withRouter } from "react-router";
import ViewType from "./ViewType";
import Parametername from "../../../app/naming/Parametername";

class ViewTypeToggle extends React.Component {
  constructor(props) {
    super(props);
    this.handleViewButtonClick = this.handleViewButtonClick.bind(this);
  }

  handleViewButtonClick(view) {
    const { actions, search } = this.props;
    actions.changeCondition(Parametername.view, view);
    const searchParams = new URLSearchParams(search);
    const beforeViewTypeName = searchParams.get(Parametername.view);
    if (view === ViewType.mapView || beforeViewTypeName == "m") {
      actions.clearList();
    }
    searchParams.set(Parametername.view, view.name);
    searchParams.delete(Parametername.minLat);
    searchParams.delete(Parametername.maxLat);
    searchParams.delete(Parametername.minLng);
    searchParams.delete(Parametername.maxLng);
    if (view === ViewType.mapView) {
      searchParams.delete(Parametername.page);
      searchParams.delete(Parametername.sort);
    }
    actions.retouchLocation(searchParams.toString());
  }

  render() {
    const { condition } = this.props;
    return (
      <div className="c-middle-tab">
        <ul>
          <li
            className={
              condition.view.name == ViewType.tableView.name
                ? "is-active"
                : null
            }
            onClick={() => this.handleViewButtonClick(ViewType.tableView)}
          >
            <span>{ViewType.tableView.description}</span>
          </li>
          <li
            className={
              condition.view.name == ViewType.cardView.name ? "is-active" : null
            }
            onClick={() => this.handleViewButtonClick(ViewType.cardView)}
          >
            <span>{ViewType.cardView.description}</span>
          </li>
          <li
            className={
              condition.view.name == ViewType.mapView.name ? "is-active" : null
            }
            onClick={() => this.handleViewButtonClick(ViewType.mapView)}
          >
            <span>{ViewType.mapView.description}</span>
          </li>
        </ul>
      </div>
    );
  }
}

ViewTypeToggle.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  condition: PropTypes.object.isRequired,
  search: PropTypes.string,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ViewTypeToggle)
);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      retouchLocation: (search) => {
        dispatch(
          push({
            search,
          })
        );
      },
      clearList: () => {
        dispatch(actions.clearList());
      },
    },
  };
}
