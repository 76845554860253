export default class SplitArrayTable {
  constructor(split) {
    this.split = split;
  }

  make(array) {
    this.table = array.reduce(
      (table, item) => {
        const last = table[table.length - 1];
        if (last.length === this.split) {
          table.push([item]);
          return table;
        }
        last.push(item);
        return table;
      },
      [[]]
    );
    return this;
  }

  fill() {
    const last = this.table[this.table.length - 1];
    for (let i of Array(this.split).keys()) if (!last[i]) last[i] = null;
    return this;
  }

  getTable() {
    return this.table;
  }
}
