import React from "react";
import { mergeStyleToProps } from "../../fundamental/react/props";

const Right = (props) => {
  return <div {...mergeStyleToProps(styles.right, props)}></div>;
};

const styles = {
  right: {
    textAlign: "right",
  },
};

export default Right;
