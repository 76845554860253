import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";

const initialState = {
  apiKey: "",
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.API_KEY_REQUEST_FINISHED]: function (state, action) {
    return {
      ...state,
      apiKey: action.payload.data.apiKey,
    };
  },
});

export default reducers.createReducer(initialState);
