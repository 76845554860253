import ActionType from "./reducer/ActionType";

export function reset() {
  return {
    type: ActionType.RESET,
  };
}

export function raiseErrors(errors) {
  return {
    type: ActionType.RAISE_ERRORS,
    payload: {
      errors,
    },
  };
}
