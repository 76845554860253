import React from "react";
import PropTypes from "prop-types";
import Parametername from "../../naming/Parametername";
import Mount from "../layout/Mount";
import ViewType from "../../../page/webfront/search/ViewType";

class SortablePaginator extends React.Component {
  constructor(props) {
    super(props);
    this.handleSortChange = this.handleSortChange.bind(this);
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  handleSortChange(e) {
    const { search, onPaginationKick } = this.props;
    const searchParams = new URLSearchParams(search);
    searchParams.delete(Parametername.page);
    const folder = searchParams.get(Parametername.folder);
    if (folder) searchParams.set(Parametername.folder, folder);
    searchParams.set(Parametername.sort, e.target.value);
    const changedSearch = searchParams.toString();
    onPaginationKick(changedSearch);
  }

  handlePageClick(page) {
    const { search, onPaginationKick } = this.props;
    const searchParams = new URLSearchParams(search);
    searchParams.set(Parametername.page, page);
    const changedSearch = searchParams.toString();
    onPaginationKick(changedSearch);
  }

  render() {
    return (
      <div>
        <div className="c-pagenation">
          <div className="c-pagenation__num">{this.renderCount()}</div>
          <Mount if={this.props.view !== ViewType.mapView}>
            <div className="c-pagenation__control">
              <div className="c-sort">
                <div className="c-sort__text">並べ替え</div>
                <div className="c-sort-select">{this.renderSort()}</div>
              </div>
              <Mount
                if={!this.props.siteIsSharedFolder || this.props.totalPages > 1}
              >
                <div className="c-page-control">{this.renderButton()}</div>
              </Mount>
            </div>
          </Mount>
        </div>
      </div>
    );
  }

  renderCount() {
    if (this.props.loading) return null;
    if (this.props.view === ViewType.mapView) return this.renderMapCount();
    return (
      <div>
        <span className="c-pagenation__num-main">
          <i>{this.textForTotal()}</i>件
        </span>
        <span className="c-pagenation__num-between">{this.textForPaged()}</span>
      </div>
    );
  }

  renderMapCount() {
    return (
      <div>
        <span className="c-pagenation__num-main">
          <i>{this.textForTotal()}</i>件
        </span>
        <Mount if={this.props.totalPages > 1}>
          <span className="c-pagenation__num-too-much">
            条件に該当するサービスが多すぎるため{this.props.size}
            件のみ表示しています。
          </span>
        </Mount>
      </div>
    );
  }

  renderSort() {
    const { search, sortOptions } = this.props;
    const searchParams = new URLSearchParams(search);
    const value = searchParams.get(Parametername.sort)
      ? searchParams.get(Parametername.sort)
      : "";
    return (
      <select onChange={this.handleSortChange} value={value}>
        {sortOptions.map((option) => (
          <option key={option.code} value={option.code}>
            {option.label}
          </option>
        ))}
      </select>
    );
  }

  renderButton() {
    const { onPaginationKick, page } = this.props;
    const pages = this.previousOrNextPages();
    return (
      <div>
        <ul>
          {this.paginatorHasPreviousPage() ? (
            <li>
              <a onClick={() => onPaginationKick(this.prevSearch())}>&lt;</a>
            </li>
          ) : (
            <li>&lt;</li>
          )}
          {pages.map((p) =>
            p != page ? (
              <li key={`page:${p}`}>
                <a onClick={() => this.handlePageClick(p)}>{p}</a>
              </li>
            ) : (
              <li key={`page:${p}`}>{p}</li>
            )
          )}
          {this.paginatorHasNextPage() ? (
            <li>
              <a onClick={() => onPaginationKick(this.nextSearch())}>&gt;</a>
            </li>
          ) : (
            <li>&gt;</li>
          )}
        </ul>
      </div>
    );
  }

  previousOrNextPages() {
    const { page, totalPages } = this.props;
    const left = Math.max(page - 2, 1);
    const right = Math.min(left + 4, totalPages);
    const pages = [];
    for (let i = left; i <= right; i++) pages.push(i);
    return pages;
  }

  nextSearch() {
    const { search, page, totalPages } = this.props;
    const searchParams = new URLSearchParams(search);
    searchParams.set(Parametername.page, Math.min(page + 1, totalPages));
    return searchParams.toString();
  }

  prevSearch() {
    const { search, page } = this.props;
    const searchParams = new URLSearchParams(search);
    searchParams.set(Parametername.page, Math.max(page - 1, 1));
    return searchParams.toString();
  }

  paginatorHasPreviousPage() {
    const { page } = this.props;
    return page > 1;
  }

  paginatorHasNextPage() {
    const { page, totalPages } = this.props;
    return page < totalPages;
  }

  textForTotal() {
    const { totalElements } = this.props;
    return `全 ${this.toLocaleString(totalElements)}`;
  }

  textForPaged() {
    const { page, size, totalElements } = this.props;
    if (size >= totalElements) return null;
    const from = (page - 1) * size + 1;
    const limit = page * size;
    const to = limit > totalElements ? totalElements : limit;
    if (from == to) return `中 ${this.toLocaleString(to)} 件目`;
    return `中 ${this.toLocaleString(from)} 〜 ${this.toLocaleString(to)} 件目`;
  }

  toLocaleString(number) {
    return String(number).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
  }
}

SortablePaginator.propTypes = {
  search: PropTypes.string.isRequired,
  sortOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  onPaginationKick: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  size: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  totalElements: PropTypes.number.isRequired,
  siteIsSharedFolder: PropTypes.bool,
  view: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default SortablePaginator;
