import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import { withRouter } from "react-router";
import Pathname from "../../app/naming/Pathname";
import { searchFilter } from "../../state/service-search/query-string";

class WebFrontHeader extends React.Component {
  constructor(props) {
    super(props);
    this.handleSharedFolderTabClick = this.handleSharedFolderTabClick.bind(
      this
    );
    this.handleServiceSearchTabClick = this.handleServiceSearchTabClick.bind(
      this
    );
  }

  handleSharedFolderTabClick() {
    const { actions, location } = this.props;
    const search = searchFilter(location.search).forSharedFolder();
    actions.gotoSharedFolderPage(search);
  }

  handleServiceSearchTabClick() {
    const { actions, location } = this.props;
    const search = searchFilter(location.search).forServiceSearch();
    actions.gotoSearchConditionPage(search);
  }

  siteIsSharedFolder() {
    const { pathname } = this.props.location;
    return pathname.startsWith(Pathname.webfront_folder);
  }

  siteIsServiceSearch() {
    const { pathname } = this.props.location;
    return (
      pathname == Pathname.webfront ||
      pathname.startsWith(Pathname.webfront_search)
    );
  }

  render() {
    return (
      <Fragment>
        <div className="l-content">
          <div className="c-tab">
            <ul>
              <li
                onClick={this.handleServiceSearchTabClick}
                className={this.siteIsServiceSearch() ? "selected" : null}
              >
                <a>
                  <img src="/images/search_white.svg" />
                  すべての情報から探す
                </a>
              </li>
              <li
                onClick={this.handleSharedFolderTabClick}
                className={this.siteIsSharedFolder() ? "selected" : null}
              >
                <a>
                  <img src="/images/folder_white.svg" />
                  共有フォルダ
                </a>
              </li>
              {/*
                            <li><a><img src="/images/map_white.svg"/>マップから探す</a></li>
                            */}
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

WebFrontHeader.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(WebFrontHeader)
);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gotoSharedFolderPage: () =>
        dispatch(
          push({
            pathname: Pathname.webfront_folder_widget,
          })
        ),
      gotoSearchConditionPage: () =>
        dispatch(
          push({
            pathname: Pathname.webfront_search_condition,
          })
        ),
    },
  };
}
