const FileType = {
  PDF: {
    name: "PDF",
    extension: "pdf",
    label: "PDF",
    icon: "pdf",
    viewable: true,
    image: false,
  },
  PNG: {
    name: "PNG",
    extension: "png",
    label: "画像",
    icon: null,
    viewable: true,
    image: true,
  },
  JPG: {
    name: "JPG",
    extension: "jpg",
    label: "画像",
    icon: null,
    viewable: true,
    image: true,
  },
  JPEG: {
    name: "JPEG",
    extension: "jpeg",
    label: "画像",
    icon: null,
    viewable: true,
    image: true,
  },
  PPTX: {
    name: "PPTX",
    extension: "pptx",
    label: "パワーポイント",
    icon: "ppt",
    viewable: false,
  },
  PPTM: {
    name: "PPTM",
    extension: "pptm",
    label: "パワーポイント",
    icon: "ppt",
    viewable: false,
  },
  PPT: {
    name: "PPT",
    extension: "ppt",
    label: "パワーポイント",
    icon: "ppt",
    viewable: false,
  },
  DOC: {
    name: "DOC",
    extension: "doc",
    label: "ワード",
    icon: "doc",
    viewable: false,
  },
  DOCM: {
    name: "DOCM",
    extension: "docm",
    label: "ワード",
    icon: "doc",
    viewable: false,
  },
  DOCX: {
    name: "DOCX",
    extension: "docx",
    label: "ワード",
    icon: "doc",
    viewable: false,
  },
  XLSX: {
    name: "XLSX",
    extension: "xlsx",
    label: "エクセル",
    icon: "xls",
    viewable: false,
  },
  XLSM: {
    name: "XLSM",
    extension: "xlsm",
    label: "エクセル",
    icon: "xls",
    viewable: false,
  },
  XLS: {
    name: "XLS",
    extension: "xls",
    label: "エクセル",
    icon: "xls",
    viewable: false,
  },
  UNKNOWN: {
    name: "UNKNOWN",
    extension: "",
    label: "不明の",
    icon: "file",
    viewable: false,
  },
};

export function fileTypeValueOf(extension) {
  return Object.keys(FileType)
    .map((key) => FileType[key])
    .find((type) => type.extension === extension.toLowerCase());
}

export function fileTypeValueOfFilename(filename) {
  const extension = ((name) => {
    const index = name.lastIndexOf(".");
    return index === -1 ? "" : name.slice(index + 1);
  })(filename);
  const fileType = fileTypeValueOf(extension);
  return fileType ? fileType : FileType.UNKNOWN;
}

export default FileType;
