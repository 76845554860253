import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../state/authentication/actions";

class AuthenticationUserLoad extends React.Component {
  componentDidMount() {
    const { actions } = this.props;
    actions.loadUser();
  }

  render() {
    return (
      <div className="p-login-message__accept">
        ログイン情報を取得しています。
      </div>
    );
  }
}

AuthenticationUserLoad.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AuthenticationUserLoad);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
