import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import { OPTIONS_AVAILABILITY } from "../../../../../app/ui/form";

/**
 * 訪問系サービス空き情報フォーム.
 */
class HomonkeiServiceAkijohoForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Fragment>{this.renderHomonkeiServiceAkijoho()}</Fragment>;
  }

  renderHomonkeiServiceAkijoho() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="空き情報（曜日別）">
        {[
          { name: "getsuyo", label: "月曜" },
          { name: "kayo", label: "火曜" },
          { name: "suiyo", label: "水曜" },
          { name: "mokuyo", label: "木曜" },
          { name: "kinyo", label: "金曜" },
          { name: "doyo", label: "土曜" },
          { name: "nichiyo", label: "日曜" },
        ].map((yobi) => (
          <HorizontalLabelLayout
            key={yobi.name}
            labelText={yobi.label}
            required
          >
            <OptionsRadio
              split={6}
              name={yobi.name}
              options={OPTIONS_AVAILABILITY}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
        ))}
      </FormGrouping>
    );
  }
}

HomonkeiServiceAkijohoForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceAkijohoForm
);
