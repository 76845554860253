import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import * as actions from "../../../../state/service-content/actions";
import Pathname from "../../../../app/naming/Pathname";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_JIGYOSHO,
} from "../../../../state/master-data";
import JigyoshoStatusComponent from "./JigyoshoStatusComponent";
import Parametername from "../../../../app/naming/Parametername";

class JigyoshoStatusPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
  }

  initializeFields(props) {
    const { params } = props.match;
    this.id = +params.id;
  }

  render() {
    const props = {
      id: this.id,
      onBackButtonClick: () =>
        this.props.onBackButtonClick(this.props.jigyoshoList.search),
      onPreviewButtonClick: this.props.onPreviewButtonClick,
      onPhotoButtonClick: this.props.onPhotoButtonClick,
      onFlierButtonClick: this.props.onFlierButtonClick,
      onAttributeButtonClick: this.props.onAttributeButtonClick,
    };
    return <JigyoshoStatusComponent {...props} />;
  }
}

JigyoshoStatusPage.propTypes = {
  // state
  jigyoshoList: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  // action
  onBackButtonClick: PropTypes.func.isRequired,
  onPreviewButtonClick: PropTypes.func.isRequired,
  onPhotoButtonClick: PropTypes.func.isRequired,
  onFlierButtonClick: PropTypes.func.isRequired,
  onAttributeButtonClick: PropTypes.func.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_JIGYOSHO)(
  connect(mapStateToProps, mapDispatchToProps)(JigyoshoStatusPage)
);

function mapStateToProps(state) {
  return {
    jigyoshoList: state.jigyoshoList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    onBackButtonClick: (search = "") => {
      const searchParams = new URLSearchParams(search);
      searchParams.set("timestamp", new Date().getTime());
      dispatch(
        push({
          pathname: Pathname.backoffice_jigyosho,
          search: searchParams.toString(),
        })
      );
    },
    onPreviewButtonClick: (id) => {
      dispatch(actions.reset());
      dispatch(
        push({
          pathname: Pathname.webfront_preview_jigyosho_id.replace(/:id/, id),
        })
      );
    },
    onPhotoButtonClick: (
      id,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode
    ) => {
      const searchParams = new URLSearchParams();
      searchParams.set(
        Parametername.serviceShubetsuCategoryCode,
        serviceShubetsuCategoryCode
      );
      searchParams.set(Parametername.serviceShubetsuCode, serviceShubetsuCode);
      dispatch(
        push({
          pathname: Pathname.backoffice_jigyosho_id_photo.replace(/:id/, id),
          search: searchParams.toString(),
        })
      );
    },
    onFlierButtonClick: (
      id,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode
    ) => {
      const searchParams = new URLSearchParams();
      searchParams.set(
        Parametername.serviceShubetsuCategoryCode,
        serviceShubetsuCategoryCode
      );
      searchParams.set(Parametername.serviceShubetsuCode, serviceShubetsuCode);
      dispatch(
        push({
          pathname: Pathname.backoffice_jigyosho_id_flier.replace(/:id/, id),
          search: searchParams.toString(),
        })
      );
    },
    onAttributeButtonClick: (
      id,
      type,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode
    ) => {
      const searchParams = new URLSearchParams();
      searchParams.set(
        Parametername.serviceShubetsuCategoryCode,
        serviceShubetsuCategoryCode
      );
      searchParams.set(Parametername.serviceShubetsuCode, serviceShubetsuCode);
      dispatch(
        push({
          pathname: Pathname.backoffice_jigyosho_id_attributes_type
            .replace(/:id/, id)
            .replace(/:type/, type),
          search: searchParams.toString(),
        })
      );
    },
  };
}
