import React, { Fragment } from "react";
import PropTypes from "prop-types";
import DanjohiBarChart from "../../../../../app/ui/chart/DanjohiBarChart";
import AgeBarChart from "../../../../../app/ui/chart/AgeBarChart";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ThWithBg from "../general/ThWithBg";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import ValueWithHeadcountUnit from "../../../../../app/ui/unit/ValueWithHeadcountUnit";
import ValueWithPercentageUnit from "../../../../../app/ui/unit/ValueWithPercentageUnit";

const ShokuinBlock = ({ shokuintaisei, serviceShubetsuType }) => {
  const ageninzu = {
    nenrei20DaiWariai: shokuintaisei.sedai20SJugyoinhiritsu,
    nenrei30DaiWariai: shokuintaisei.sedai30SJugyoinhiritsu,
    nenrei40DaiWariai: shokuintaisei.sedai40SJugyoinhiritsu,
    nenrei50DaiWariai: shokuintaisei.sedai50SJugyoinhiritsu,
    nenrei60DaiWariai: shokuintaisei.sedai60SJugyoinhiritsu,
  };
  return (
    <Fragment>
      <HeaderTextBlue text="職員体制" />
      <DanjohiBarChart danjohi={shokuintaisei} />
      <AgeBarChart ageninzu={ageninzu} />

      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問介護}
        then={() => <ShokuinBlock_homonkaigo shokuintaisei={shokuintaisei} />}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問看護}
        then={() => <ShokuinBlock_homonkango shokuintaisei={shokuintaisei} />}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問リハビリテーション}
        then={() => (
          <ShokuinBlock_rehabilitation shokuintaisei={shokuintaisei} />
        )}
      />
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問入浴介護}
        then={() => <ShokuinBlock_nyuyoku shokuintaisei={shokuintaisei} />}
      />
      <Mount
        if={
          serviceShubetsuType ===
          ServiceShubetsuType.定期巡回_随時対応型訪問介護看護
        }
        then={() => <ShokuinBlock_teikizuiji shokuintaisei={shokuintaisei} />}
      />
    </Fragment>
  );
};

const ShokuinBlock_homonkaigo = (shokuintaisei) => {
  const { shokuintaisei: shokuin } = shokuintaisei;
  return (
    <Fragment>
      <div className="print-body-text_area">
        <div>
          訪問介護員：
          <ValueWithHeadcountUnit value={shokuin.homonkaigoingokeininzu} />
        </div>
        <div>
          経験年数5年以上の従業員の割合：
          <ValueWithPercentageUnit value={shokuin.keikennensu5Nenijyowariai} />
        </div>
      </div>
      <div
        className="p-office-body-table-dotted"
        style={{ marginBottom: "5pt" }}
      >
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20">
              <th>管理者保有資格</th>
              <td style={{ textAlign: "left" }}>
                {shokuin.kanrishahoyushikaku}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div className="p-office-body-table-dotted">
          <table>
            <thead>
              <tr>
                <ThWithBg text="職員保有資格者" />
                <ThWithBg text="常勤" />
                <ThWithBg text="非常勤" />
              </tr>
            </thead>
            <tbody>
              <tr className="tbody-td_width45">
                <th>介護福祉士</th>
                <td>{shokuin.kaigofukushishininzu.jokin}</td>
                <td>{shokuin.kaigofukushishininzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width45">
                <th>実務者研修修了者</th>
                <td>{shokuin.jitsumushakenshuninzu.jokin}</td>
                <td>{shokuin.jitsumushakenshuninzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width45">
                <th>初任者研修修了者</th>
                <td>{shokuin.shoninshakenshuninzu.jokin}</td>
                <td>{shokuin.shoninshakenshuninzu.hijokin}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

const ShokuinBlock_homonkango = (shokuintaisei) => {
  const { shokuintaisei: shokuin } = shokuintaisei;
  return (
    <Fragment>
      <div className="print-body-text_area">
        <div>
          看護職員：
          <ValueWithHeadcountUnit value={shokuin.kangoshokuingokeininzu} />
        </div>
        <div>
          リハビリ職員：
          <ValueWithHeadcountUnit
            value={shokuin.rehabilitationShokuingokeininzu}
          />
        </div>
        <div>
          経験年数5年以上の従業員の割合：
          <ValueWithPercentageUnit value={shokuin.keikennensu5Nenijyowariai} />
        </div>
      </div>
      <div
        className="p-office-body-table-dotted"
        style={{ marginBottom: "5pt" }}
      >
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80">
              <th>管理者保有資格</th>
              <td style={{ textAlign: "left" }}>
                {shokuin.kanrishahoyushikaku}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div className="p-office-body-table-dotted">
          <table>
            <thead>
              <tr>
                <ThWithBg text="看護系資格" />
                <ThWithBg text="常勤" />
                <ThWithBg text="非常勤" />
                <ThWithBg text="介護系資格" />
                <ThWithBg text="常勤" />
                <ThWithBg text="非常勤" />
              </tr>
            </thead>
            <tbody>
              <tr className="tbody-td_width21">
                <th>看護師</th>
                <td>{shokuin.kangoshininzu.jokin}</td>
                <td>{shokuin.kangoshininzu.hijokin}</td>
                <th>理学療法士</th>
                <td>{shokuin.rigakuryohoshininzu.jokin}</td>
                <td>{shokuin.rigakuryohoshininzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width21">
                <th>准看護師</th>
                <td>{shokuin.junkangoshininzu.jokin}</td>
                <td>{shokuin.junkangoshininzu.hijokin}</td>
                <th>作業療法士</th>
                <td>{shokuin.sagyoryohoshininzu.jokin}</td>
                <td>{shokuin.sagyoryohoshininzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width21">
                <th>保健師</th>
                <td>{shokuin.hokenshininzu.jokin}</td>
                <td>{shokuin.hokenshininzu.hijokin}</td>
                <th>言語聴覚士</th>
                <td>{shokuin.gengochokakushininzu.jokin}</td>
                <td>{shokuin.gengochokakushininzu.hijokin}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

const ShokuinBlock_rehabilitation = (shokuintaisei) => {
  const { shokuintaisei: shokuin } = shokuintaisei;
  return (
    <Fragment>
      <div className="print-body-text_area">
        <div>
          リハビリ職員：
          <ValueWithHeadcountUnit
            value={shokuin.rehabilitationShokuingokeininzu}
          />
        </div>
        <div>
          経験年数5年以上の従業員の割合：
          <ValueWithPercentageUnit value={shokuin.keikennensu5Nenijyowariai} />
        </div>
      </div>
      <div
        className="p-office-body-table-dotted"
        style={{ marginBottom: "5pt" }}
      >
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80">
              <th>管理者保有資格</th>
              <td style={{ textAlign: "left" }}>
                {shokuin.kanrishahoyushikaku}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div className="p-office-body-table-dotted">
          <table>
            <thead>
              <tr>
                <ThWithBg text="職員保有資格" />
                <ThWithBg text="常勤" />
                <ThWithBg text="非常勤" />
              </tr>
            </thead>
            <tbody>
              <tr className="tbody-td_width45">
                <th>理学療法士</th>
                <td>{shokuin.rigakuryohoshininzu.jokin}</td>
                <td>{shokuin.rigakuryohoshininzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width45">
                <th>作業療法士</th>
                <td>{shokuin.sagyoryohoshininzu.jokin}</td>
                <td>{shokuin.sagyoryohoshininzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width45">
                <th>言語聴覚士</th>
                <td>{shokuin.gengochokakushininzu.jokin}</td>
                <td>{shokuin.gengochokakushininzu.hijokin}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

const ShokuinBlock_nyuyoku = (shokuintaisei) => {
  const { shokuintaisei: shokuin } = shokuintaisei;
  return (
    <Fragment>
      <div className="print-body-text_area">
        <div>
          看護職員：
          <ValueWithHeadcountUnit value={shokuin.kangoshokuingokeininzu} />
        </div>
        <div>
          訪問介護員：
          <ValueWithHeadcountUnit value={shokuin.homonkaigoingokeininzu} />
        </div>
        <div>
          経験年数5年以上の従業員の割合：
          <ValueWithPercentageUnit value={shokuin.keikennensu5Nenijyowariai} />
        </div>
      </div>
      <div
        className="p-office-body-table-dotted"
        style={{ marginBottom: "5pt" }}
      >
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80">
              <th>管理者保有資格</th>
              <td style={{ textAlign: "left" }}>
                {shokuin.kanrishahoyushikaku}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div className="p-office-body-table-dotted">
          <table>
            <thead>
              <tr>
                <ThWithBg text="看護系資格" />
                <ThWithBg text="常勤" />
                <ThWithBg text="非常勤" />
                <ThWithBg text="介護系資格" />
                <ThWithBg text="常勤" />
                <ThWithBg text="非常勤" />
              </tr>
            </thead>
            <tbody>
              <tr className="tbody-td_width21">
                <th>看護師</th>
                <td>{shokuin.kangoshininzu.jokin}</td>
                <td>{shokuin.kangoshininzu.hijokin}</td>
                <th>介護福祉士</th>
                <td>{shokuin.kaigofukushishininzu.jokin}</td>
                <td>{shokuin.kaigofukushishininzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width21">
                <th>准看護師</th>
                <td>{shokuin.junkangoshininzu.jokin}</td>
                <td>{shokuin.junkangoshininzu.hijokin}</td>
                <th>実務者研修修了者</th>
                <td>{shokuin.jitsumushakenshuninzu.jokin}</td>
                <td>{shokuin.jitsumushakenshuninzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width21">
                <th></th>
                <td></td>
                <td></td>
                <th>初任者研修修了者</th>
                <td>{shokuin.shoninshakenshuninzu.jokin}</td>
                <td>{shokuin.shoninshakenshuninzu.hijokin}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

const ShokuinBlock_teikizuiji = (shokuintaisei) => {
  const { shokuintaisei: shokuin } = shokuintaisei;
  return (
    <Fragment>
      <div className="print-body-text_area">
        <div>
          訪問介護員：
          <ValueWithHeadcountUnit value={shokuin.homonkaigoingokeininzu} />
        </div>
        <div>
          看護職員：
          <ValueWithHeadcountUnit value={shokuin.kangoshokuingokeininzu} />
        </div>
        <div>
          経験年数5年以上の従業員の割合：
          <ValueWithPercentageUnit value={shokuin.keikennensu5Nenijyowariai} />
        </div>
      </div>
      <div
        className="p-office-body-table-dotted"
        style={{ marginBottom: "5pt" }}
      >
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80">
              <th>管理者保有資格</th>
              <td style={{ textAlign: "left" }}>
                {shokuin.kanrishahoyushikaku}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div>
        <div className="p-office-body-table-dotted">
          <table>
            <thead>
              <tr>
                <ThWithBg text="看護系資格" />
                <ThWithBg text="常勤" />
                <ThWithBg text="非常勤" />
                <ThWithBg text="介護系資格" />
                <ThWithBg text="常勤" />
                <ThWithBg text="非常勤" />
              </tr>
            </thead>
            <tbody>
              <tr className="tbody-td_width21">
                <th>看護師</th>
                <td>{shokuin.kangoshininzu.jokin}</td>
                <td>{shokuin.kangoshininzu.hijokin}</td>
                <th>介護福祉士</th>
                <td>{shokuin.kaigofukushishininzu.jokin}</td>
                <td>{shokuin.kaigofukushishininzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width21">
                <th>准看護師</th>
                <td>{shokuin.junkangoshininzu.jokin}</td>
                <td>{shokuin.junkangoshininzu.hijokin}</td>
                <th>実務者研修修了者</th>
                <td>{shokuin.jitsumushakenshuninzu.jokin}</td>
                <td>{shokuin.jitsumushakenshuninzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width21">
                <th>保健師</th>
                <td>{shokuin.hokenshininzu.jokin}</td>
                <td>{shokuin.hokenshininzu.hijokin}</td>
                <th>初任者研修修了者</th>
                <td>{shokuin.shoninshakenshuninzu.jokin}</td>
                <td>{shokuin.shoninshakenshuninzu.hijokin}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

ShokuinBlock.propTypes = {
  shokuintaisei: PropTypes.object.isRequired,
  serviceShubetsuType: PropTypes.object.isRequired,
};

export default ShokuinBlock;
