import React from "react";
import { Table } from "@material-ui/core";
import { mergeStyleToProps } from "../../fundamental/react/props";

const FixedTable = (props) => {
  return <Table {...mergeStyleToProps(styles.table, props)}></Table>;
};

const styles = {
  table: {
    tableLayout: "fixed",
  },
};

export default FixedTable;
