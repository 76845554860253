import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const RecreationTable = ({ contents, detail }) => {
  const {
    blocks: { shortstayRecreation: recreation },
  } = contents;
  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <Mount if={detail}>
              <tr>
                <th>個別レク</th>
                <td>{recreation.kobetsuRecreation}</td>
              </tr>
              <tr>
                <th>集団レク</th>
                <td>{recreation.shudanRecreation}</td>
              </tr>
              <tr>
                <th>外出レク</th>
                <td>{recreation.gaishutsuRecreation}</td>
              </tr>
            </Mount>
            <tr>
              <th>アピールしたいレク</th>
              <td>{recreation.appealRecreation.join("、")}</td>
            </tr>
            <tr>
              <th>外部講師によるレク</th>
              <td>{recreation.gaibukoshiniyoruRecreation.join("、")}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Mount if={detail}>
        <div className="p-office-body-characteristic">特 徴</div>
        <div className="p-office-body-description p-office-body-description--p">
          {recreation.tokucho}
        </div>
      </Mount>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.shortstayRecreation
      )}
    </Fragment>
  );
};

RecreationTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default RecreationTable;
