import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import { formDataOf } from "../../../app/fundamental/data/FormData";
import { searchConverter, conditionConverter } from "../query-string";
import CategorizedOptions from "../../../app/util/CategorizedMasterData";
import Parametername from "../../../app/naming/Parametername";

const initialState = {
  condition: formDataOf(searchConverter().toCondition()),
  conditionBuffer: formDataOf({
    [Parametername.prefArea]: {},
    [Parametername.cityArea]: {},
    [Parametername.townArea]: {},
  }),
  list: [],
  listIsLoading: false,
  pagination: {
    page: 1,
    size: 10,
    totalPages: 1,
    totalElements: 0,
  },
  search: undefined,
  prevSearch: undefined,
  nextSearch: undefined,
  selectedIds: [],
  browseAreaCities: undefined, // 地域選択市区町村一覧
  isBrowseAreaLoading: false,
  selectedBrowseAreaLabel: undefined,
  // 詳細データ用
  detailsIsLoading: false,
  details: {},
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.CLEAR_LIST]: function (state) {
    return {
      ...state,
      list: [],
      pagination: {
        ...state.pagination,
        totalPages: 1,
        totalElements: 0,
      },
      selectedIds: [],
      listDetails: [],
    };
  },
  [ActionType.MAKE_CONDITION]: function (state, action) {
    const { search, serviceShubetsuMenu } = action.payload;
    return {
      ...state,
      condition: formDataOf(
        searchConverter(search, serviceShubetsuMenu).toCondition()
      ),
    };
  },
  [ActionType.SET_SEARCH]: function (state, action) {
    return {
      ...state,
      search: action.payload.search,
    };
  },
  [ActionType.FETCH_BROWSE_AREA_CITIES]: function (state) {
    return {
      ...state,
      isBrowseAreaLoading: true,
    };
  },
  [ActionType.FETCH_BROWSE_AREA_CITIES_FINISHED]: function (state, action) {
    return {
      ...state,
      browseAreaCities: action.payload.areaCities,
      isBrowseAreaLoading: false,
    };
  },
  [ActionType.FETCH_BROWSE_AREA_TOWNS]: function (state) {
    return {
      ...state,
      isBrowseAreaLoading: true,
    };
  },
  [ActionType.FETCH_BROWSE_AREA_TOWNS_FINISHED]: function (state, action) {
    return {
      ...state,
      browseAreaTowns: action.payload.areaTowns,
      isBrowseAreaLoading: false,
    };
  },
  [ActionType.FETCH_BROWSE_AREA_LABEL_FINISHED]: function (state, action) {
    return {
      ...state,
      selectedBrowseAreaLabel: action.payload.areaLabel,
    };
  },
  [ActionType.SELECT]: function (state, action) {
    const { id } = action.payload;
    return {
      ...state,
      selectedIds: state.selectedIds.includes(id)
        ? state.selectedIds.filter((it) => it != id)
        : [id].concat(state.selectedIds),
    };
  },
  [ActionType.CHANGE_CONDITION_SERVICE_SHUBETSU_CATEGORY]: function (
    state,
    action
  ) {
    const { code, serviceShubetsuMenu } = action.payload;
    const menuCodes = new CategorizedOptions(serviceShubetsuMenu)
      .getCategories()
      .find((category) => category.code === code)
      .options.map((option) => option.code);
    const on =
      menuCodes.filter((it) =>
        state.condition.serviceShubetsuMenuCodes.includes(it)
      ).length !== menuCodes.length;
    return {
      ...state,
      condition: formDataOf({
        ...searchConverter().toCondition(),
        serviceShubetsuCategoryCode: on ? code : undefined,
        serviceShubetsuMenuCodes: on
          ? new CategorizedOptions(serviceShubetsuMenu)
              .getCategories()
              .find((category) => category.code === code)
              .options.map((option) => option.code)
          : [],
      }),
    };
  },
  [ActionType.CHANGE_CONDITION_SERVICE_SHUBETSU_MENU]: function (
    state,
    action
  ) {
    const { code, checked, serviceShubetsuMenu } = action.payload;
    const menusCodes = checked
      ? [] // save processing
      : state.condition.serviceShubetsuMenuCodes.filter(
          (menuCode) => menuCode !== code
        );
    const categoryCode = checked
      ? serviceShubetsuMenu.find((menu) => menu.code === code).category.code
      : menusCodes.length > 0
      ? state.condition.serviceShubetsuCategoryCode
      : undefined;
    return {
      ...state,
      condition: formDataOf({
        ...state.condition,
        serviceShubetsuCategoryCode: categoryCode,
        serviceShubetsuMenuCodes: checked
          ? new CategorizedOptions(serviceShubetsuMenu)
              .getCategories()
              .find((category) => category.code === categoryCode)
              .options.map((option) => option.code)
              .filter((menuCode) =>
                state.condition.serviceShubetsuMenuCodes.includes(menuCode)
              )
              .concat([code])
          : menusCodes,
      }),
    };
  },
  [ActionType.CHANGE_CONDITION_FOLDERS]: function (state, action) {
    const searchParams = new URLSearchParams(
      conditionConverter(state.condition).toSearch()
    );
    searchParams.delete(Parametername.serviceShubetsuMenuCode);
    action.payload.folders.forEach((code) =>
      searchParams.append(Parametername.serviceShubetsuMenuCode, code)
    );
    const condition = searchConverter(
      searchParams.toString(),
      action.payload.serviceShubetsuMenu
    ).toCondition();
    return {
      ...state,
      condition: formDataOf(condition),
    };
  },
  [ActionType.CHANGE_CONDITION]: function (state, action) {
    return {
      ...state,
      condition: state.condition.setByName(
        action.payload.name,
        action.payload.value
      ),
    };
  },
  [ActionType.SAVE_CONDITION_BUFFER]: function (state, action) {
    return {
      ...state,
      conditionBuffer: state.conditionBuffer.setByName(
        action.payload.name,
        action.payload.value
      ),
    };
  },
  [ActionType.REQUEST]: function (state, action) {
    return {
      ...state,
      listIsLoading: true,
      search: action.payload.search,
    };
  },
  [ActionType.REQUEST_FINISHED]: function (state, action) {
    return {
      ...state,
      condition: formDataOf(
        searchConverter(
          action.payload.search,
          action.payload.serviceShubetsuMenu
        ).toCondition()
      ),
      list: action.payload.list,
      listIsLoading: false,
      pagination: action.payload.pagination,
      search: action.payload.search,
      prevSearch: action.payload.prevSearch,
      nextSearch: action.payload.nextSearch,
      selectedIds: state.selectedIds,
    };
  },
  // 詳細リスト取得用のアクション
  [ActionType.FETCH_DETAIL_ITEM]: function (state) {
    return {
      ...state,
      detailsIsLoading: true,
      details: {},
    };
  },
  [ActionType.FETCH_DETAIL_ITEM_FINISHED]: function (state, action) {
    const { payload } = action;
    const details = {};
    payload.details.map(
      (detail) =>
        (details[detail.id] = {
          contents: {
            [payload.serviceContentType.name]: detail,
          },
        })
    );
    return {
      ...state,
      detailsIsLoading: false,
      details: details,
    };
  },
});

export default reducers.createReducer(initialState);
