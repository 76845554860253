import React from "react";
import PropTypes from "prop-types";
import { Route, Switch } from "react-router";
import Pathname from "../../../app/naming/Pathname";
import JobOfferListPage from "./list/JobOfferListPage";
import JobOfferJigyoshoEditPage from "./jigyosho/JobOfferJigyoshoEditPage";
import JobOfferAddPage from "./add/JobOfferAddPage";
import JobOfferDetailPage from "./detail/JobOfferDetailPage";
import JobOfferEditPage from "./edit/JobOfferEditPage";
import { connect } from "react-redux";
import JobOfferFeatureIntroductionPage from "./JobOfferIntroductionPage";
import JobOfferJigyoshoPhotoEditPage from "./jigyosho/JobOfferJigyoshoPhotoEditPage";
import JobOfferRejectPage from "./reject/JobOfferRejectPage";

const JobOfferRouter = ({ authentication }) => {
  if (!authentication.user.jobOfferEnabled) {
    return <JobOfferFeatureIntroductionPage />;
  }

  return (
    <Switch>
      <Route
        path={Pathname.backoffice_job_offer_jigyosho_id_add}
        component={JobOfferAddPage}
      />
      <Route
        path={Pathname.backoffice_job_offer_jigyosho_id_photos}
        component={JobOfferJigyoshoPhotoEditPage}
      />
      <Route
        path={Pathname.backoffice_job_offer_jigyosho_id}
        component={JobOfferJigyoshoEditPage}
      />
      <Route
        path={Pathname.backoffice_job_offer_id_edit}
        component={JobOfferEditPage}
      />
      <Route
        path={Pathname.backoffice_job_offer_id_reject}
        component={JobOfferRejectPage}
      />
      <Route
        path={Pathname.backoffice_job_offer_id}
        component={JobOfferDetailPage}
      />
      <Route
        path={Pathname.backoffice_job_offer}
        component={JobOfferListPage}
      />
    </Switch>
  );
};

JobOfferRouter.propTypes = {
  authentication: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    authentication: state.authentication,
  };
};

export default connect(mapStateToProps, null)(JobOfferRouter);
