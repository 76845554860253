import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";

const RyokinKaigo = ({ contents }) => {
  const { homonkeiServiceRyokin: ryokin } = contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-title">介護報酬加算</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>共通</th>
              <td>{ryokin.kaigohoshukasanKyotsu.join("、")}</td>
            </tr>
            <tr>
              <th>要介護のみ</th>
              <td>{ryokin.kaigohoshukasanYokaigo.join("、")}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="p-office-body-title">利用料金の目安</div>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-title">身体介護</div>
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">要支援1〜2</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">
                    週１回程度
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.shintaikaigoYoshienShu1kai}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    週２回程度
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.shintaikaigoYoshienShu2kai}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    週２回を超える場合
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.shintaikaigoYoshienMoreThanShu2kai}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name"></div>
                  <div className="p-office-body-table2__td-val">
                    <span>
                      <br />
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="p-office-body-title">
            <span>
              <br />
              <br />
              生活援助
            </span>
          </div>
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">要支援1〜2</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">
                    20分〜45分
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.seikatsuenjoYoshienWithin45min}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">45分以上</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.seikatsuenjoYoshienMoreThan45min}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="p-office-body-title">通院等乗降介助</div>
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">要介護1〜5</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name"></div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.tsuinJokokaijoYokaigo}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <Mount if={ryokin.yakantaiogataService}>
            <div className="p-office-body-title">夜間対応型訪問介護</div>
            <div className="p-office-body-table2">
              <div className="p-office-body-table2__th">要介護1〜5</div>
              <div className="p-office-body-table2__td">
                <ul>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      基本夜間対応型訪問介護費
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {ryokin.yakantaioKihonServiceYokaigo}
                    </div>
                  </li>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      定期巡回サービス費
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {ryokin.yakantaioTeikijunkaiYokaigo}
                    </div>
                  </li>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      随時訪問サービス費（I）
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {ryokin.yakantaioZuijihomon1Yokaigo}
                    </div>
                  </li>
                  <li>
                    <div className="p-office-body-table2__td-name">
                      随時訪問サービス費（Ⅱ）
                    </div>
                    <div className="p-office-body-table2__td-val">
                      {ryokin.yakantaioZuijihomon2Yokaigo}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </Mount>
        </div>

        <div className="p-office-body-sep__right">
          <div className="p-office-body-title">
            <span>
              <br />
            </span>
          </div>
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">要介護1〜5</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">〜20分</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.shintaikaigoYokaigoWithin20min}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    20分〜30分
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.shintaikaigoYokaigoWithin30min}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    30分〜60分
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.shintaikaigoYokaigoWithin60min}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    60分〜90分
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.shintaikaigoYokaigoWithin90min}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">
                    以降、30分増すごとに
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.shintaikaigoYokaigoBy30min}
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div className="p-office-body-title">
            <span>
              <br />
            </span>
          </div>
          <div className="p-office-body-table2">
            <div className="p-office-body-table2__th">要介護1〜5</div>
            <div className="p-office-body-table2__td">
              <ul>
                <li>
                  <div className="p-office-body-table2__td-name">
                    20分〜45分
                  </div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.seikatsuenjoYokaigoWithin45min}
                  </div>
                </li>
                <li>
                  <div className="p-office-body-table2__td-name">45分以上</div>
                  <div className="p-office-body-table2__td-val">
                    {ryokin.seikatsuenjoYokaigoMoreThan45min}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

RyokinKaigo.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default RyokinKaigo;
