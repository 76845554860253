import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import {
  Paper,
  TableBody,
  TableHead,
  TableRow,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import * as actions from "../../../state/information/actions";
import Pathname from "../../../app/naming/Pathname";
import Boundary from "../../../app/ui/layout/Boundary";
import SideMargin from "../../../app/ui/layout/SideMargin";
import FlatPrimaryButton from "../../../app/ui/button/FlatPrimaryButton";
import AddIcon from "@material-ui/icons/Add";
import { IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import FixedTable from "../../../app/ui/table/FixedTable";
import BackOfficeHead from "../../../app/ui/structure/BackOfficeHead";
import SmallPaddingTableCell from "../../../app/ui/table/SmallPaddingTableCell";
import Right from "../../../app/ui/layout/Right";
import RaisedSecondaryButton from "../../../app/ui/button/RaisedSecondaryButton";
import Mount from "../../../app/ui/layout/Mount";
import For from "../../../app/ui/layout/For";

class NotificationMessageInputList extends React.Component {
  constructor(props) {
    super(props);
    // 「お知らせ追加」クリックイベント
    this.handleNotificationMessageAddDetailClick = this.handleNotificationMessageAddDetailClick.bind(
      this
    );
    // 「タイトル」クリックイベント
    this.handleNotificationMessageTitlelClick = this.handleNotificationMessageTitlelClick.bind(
      this
    );
    // 「削除」クリックイベント
    this.handleDeleteButtonClick = this.handleDeleteButtonClick.bind(this);
    this.handleRemoveButtonClick = this.handleRemoveButtonClick.bind(this);
    this.handleRemoveDialogCloseButtonClick = this.handleRemoveDialogCloseButtonClick.bind(
      this
    );
    this.handleRemoveDialogRevemoButtonClick = this.handleRemoveDialogRevemoButtonClick.bind(
      this
    );
  }

  state = {
    removeDialogIsOpen: false,
    removeTargetId: null,
    isDeleteButton: false,
  };

  componentDidMount() {
    const { actions } = this.props;
    // お知らせ一覧の取得
    actions.requestList();
  }

  // 「お知らせ追加」クリックイベント
  handleNotificationMessageAddDetailClick() {
    const { actions } = this.props;
    actions.gotoNotificationMessageAddDetailPage();
  }

  // 「タイトル」クリックイベント
  handleNotificationMessageTitlelClick(id) {
    const { actions } = this.props;
    actions.gotoNotificationMessageEditDetailPage(id);
  }

  // 「削除」クリックイベント
  handleDeleteButtonClick(id) {
    const { actions } = this.props;
    actions.submitToRemove(id);
  }

  handleRemoveButtonClick(e, id) {
    e.stopPropagation();
    this.setState({
      removeDialogIsOpen: true,
      removeTargetId: id,
      isDeleteButton: false,
    });
  }

  handleRemoveDialogCloseButtonClick(e) {
    e.stopPropagation();
    this.setState({
      removeDialogIsOpen: false,
      removeTargetId: null,
    });
  }

  handleRemoveDialogRevemoButtonClick(e) {
    e.stopPropagation();
    this.setState({ isDeleteButton: true });
    this.handleDeleteButtonClick(this.state.removeTargetId);
    this.setState({
      removeDialogIsOpen: false,
      removeTargetId: null,
    });
  }

  render() {
    const { user } = this.props.authentication;
    return (
      <Boundary>
        <SideMargin>
          <BackOfficeHead>お知らせ一覧</BackOfficeHead>
          <Mount if={user.canAddInformation()}>
            <FlatPrimaryButton
              onClick={() => this.handleNotificationMessageAddDetailClick()}
            >
              <AddIcon />
              お知らせを追加
            </FlatPrimaryButton>
          </Mount>
          <Fragment>
            <Paper>
              <FixedTable>
                <TableHead>
                  <TableRow>
                    <SmallPaddingTableCell style={{ width: 70 }}>
                      公開日
                    </SmallPaddingTableCell>
                    <SmallPaddingTableCell>見出し</SmallPaddingTableCell>
                    <SmallPaddingTableCell style={{ width: 100 }} />
                  </TableRow>
                </TableHead>
                {this.renderInformationList()}
              </FixedTable>
            </Paper>
          </Fragment>
          {this.renderRemoveDialog()}
        </SideMargin>
      </Boundary>
    );
  }

  renderInformationList() {
    const { user } = this.props.authentication;
    const { informationForm } = this.props;
    const { list, listIsLoading } = informationForm;
    if (listIsLoading) return null;
    if (!list.length) return null;
    return (
      <TableBody>
        <For
          in={list}
          each={(it) => (
            <TableRow key={it.id}>
              <SmallPaddingTableCell>
                {it.registDate} {this.renderNewFor(it)}
              </SmallPaddingTableCell>
              <SmallPaddingTableCell>{it.title}</SmallPaddingTableCell>
              <SmallPaddingTableCell>
                <Right>
                  <Mount if={user.canEditInformation()}>
                    <IconButton
                      onClick={() =>
                        this.handleNotificationMessageTitlelClick(it.id)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                  </Mount>
                  <Mount if={user.canRemoveInformation()}>
                    <IconButton
                      onClick={(e) => this.handleRemoveButtonClick(e, it.id)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Mount>
                </Right>
              </SmallPaddingTableCell>
            </TableRow>
          )}
        />
      </TableBody>
    );
  }

  renderNewFor(it) {
    if (!it.newFlag) return null;
    return <span style={styles.new}>NEW</span>;
  }

  renderRemoveDialog() {
    return (
      <Dialog
        onClose={this.handleRemoveDialogCloseButtonClick}
        open={this.state.removeDialogIsOpen}
      >
        <DialogTitle>お知らせ削除の確認</DialogTitle>
        <DialogContent>
          <DialogContentText>
            お知らせを削除します、よろしいですか？
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <FlatPrimaryButton onClick={this.handleRemoveDialogCloseButtonClick}>
            キャンセル
          </FlatPrimaryButton>
          <RaisedSecondaryButton
            onClick={this.handleRemoveDialogRevemoButtonClick}
            disabled={this.state.isDeleteButton}
          >
            削除する
          </RaisedSecondaryButton>
        </DialogActions>
      </Dialog>
    );
  }
}

NotificationMessageInputList.propTypes = {
  authentication: PropTypes.object.isRequired,
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  informationForm: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationMessageInputList);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    informationForm: state.informationForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoNotificationMessageAddDetailPage: () =>
        dispatch(
          push({
            pathname: Pathname.backoffice_notification_id.replace(/:id/, "add"),
          })
        ),
      gotoNotificationMessageEditDetailPage: (id) =>
        dispatch(
          push({
            pathname: Pathname.backoffice_notification_id.replace(/:id/, id),
          })
        ),
      gotoReDisplay: () =>
        dispatch(
          push({
            pathname: Pathname.backoffice_notification,
          })
        ),
    },
  };
}

const styles = {
  new: {
    color: "red",
  },
};
