import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { checkMaintenanceStatus } from "../../state/maintenance/actions";
import { push } from "react-router-redux";
import Pathname from "../naming/Pathname";

const CHECK_INTERVAL_MINUTES = 5;

class MaintenancePage extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    this.pollingProcess = setInterval(
      this.props.checkStatus,
      CHECK_INTERVAL_MINUTES * 60 * 1000
    );
  }

  componentWillUnmount() {
    clearInterval(this.pollingProcess);
  }

  render() {
    return (
      <div className="o-sorry">
        <div className="o-sorry-flame">
          <div>
            <div className="o-sorry__title">メンテナンス中</div>
            <div className="o-sorry__text">
              ミルモネットは、ただいまメンテナンス中です。
              <br />
              ご不便をおかけいたしまして、申し訳ございません。
              <br />
              {this.props.maintenance.message}
            </div>
            <div className="o-sorry__img">
              <img src="/images/chara_jump.png" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MaintenancePage.propTypes = {
  maintenance: PropTypes.object.isRequired,
  checkStatus: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(MaintenancePage);

function mapStateToProps(state) {
  return {
    maintenance: state.maintenance,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    checkStatus: () => {
      dispatch(
        checkMaintenanceStatus(() => {
          dispatch(push(Pathname.__root));
        })
      );
    },
  };
}
