import React from "react";
import PropTypes from "prop-types";
import InputTypeText from "./InputTypeText";
import ColorCatalog from "../color/ColorCatalog";

const DEFAULT_LIMIT = 400;

const Textarea = (props) => {
  const { characterLimit = DEFAULT_LIMIT } = props;
  return (
    <div>
      <InputTypeText multiline rows={3} {...props} />
      <div
        style={{
          ...styles.count,
          ...(countIsOver(props) ? styles.over : {}),
        }}
      >
        文字数: {count(props)} / {characterLimit}
      </div>
    </div>
  );
};

function count(props) {
  const text = props.form.getByName(props.name);
  return text ? text.length : 0;
}

function countIsOver(props) {
  const { characterLimit = DEFAULT_LIMIT } = props;
  return count(props) > characterLimit;
}

const styles = {
  count: {
    fontSize: "x-small",
    color: ColorCatalog.gray,
  },
  over: {
    color: ColorCatalog.error,
  },
};

Textarea.propTypes = {
  characterLimit: PropTypes.number,
};

export default Textarea;
