import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import Mount from "../../../../../app/ui/layout/Mount";

const ServiceNaiyoNyuyoku = ({ contents, detail }) => {
  const {
    homonkeiServiceServiceNaiyoNyuyokuService: nyuyokuService,
  } = contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>内容</th>
              <td>
                <Mount
                  if={nyuyokuService.naiyo.ka.length > 0}
                  then={() => (
                    <div className="p-office-body-table__circle">
                      {nyuyokuService.naiyo.ka.join("、")}
                    </div>
                  )}
                />
                <Mount
                  if={nyuyokuService.naiyo.yosodan.length > 0}
                  then={() => (
                    <div className="p-office-body-table__triangle">
                      {nyuyokuService.naiyo.yosodan.join("、")}
                    </div>
                  )}
                />
              </td>
            </tr>
            <tr>
              <th>浴槽種類</th>
              <td>{nyuyokuService.yokusoShurui.join("、")}</td>
            </tr>
            <tr>
              <th>その他サービス</th>
              <td>{nyuyokuService.sonotaService.join("、")}</td>
            </tr>
            <Mount if={detail}>
              <tr>
                <th>浴槽大きさ</th>
                <td>{nyuyokuService.yokusoOkisa}</td>
              </tr>
              <tr>
                <th>入浴車両の台数</th>
                <td>{nyuyokuService.nyuyokusharyonoDaisu}</td>
              </tr>
            </Mount>
            <tr>
              <th>備考</th>
              <td>{nyuyokuService.biko}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Mount if={detail}>
        <div className="p-office-body-characteristic">特 徴</div>
        <div className="p-office-body-description p-office-body-description--p">
          {nyuyokuService.tokucho}
        </div>
      </Mount>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.homonkeiServiceNyuyoku
      )}
    </Fragment>
  );
};

ServiceNaiyoNyuyoku.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default ServiceNaiyoNyuyoku;
