import { stringIsNullOrEmpty } from "../../app/fundamental/data";

export function searchConverter(target = "") {
  return {
    toCondition: () => {
      const searchParams = new URLSearchParams(target);
      return {
        keyword: !stringIsNullOrEmpty(searchParams.get("keyword"))
          ? searchParams.get("keyword")
          : "",
      };
    },
  };
}

export function conditionConverter(condition) {
  return {
    toSearch: () => {
      const searchParams = new URLSearchParams();
      if (!stringIsNullOrEmpty(condition.keyword))
        searchParams.set("keyword", condition.keyword);
      return searchParams.toString();
    },
  };
}
