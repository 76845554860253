import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  NativeSelect,
} from "@material-ui/core";
import CategorizedOptions from "../../util/CategorizedMasterData";

const UNSPECIFIED = {
  code: "",
  label: "（未選択）",
};

class StandardSelect extends React.Component {
  formControlProps() {
    const { disabled, name } = this.props;
    const helperText = this.helperText();
    return {
      disabled,
      error: helperText ? true : false,
      margin: "normal",
      name,
      style: styles.default,
    };
  }

  selectProps() {
    const { form, name, onChange: change } = this.props;
    return {
      onChange: (e) => change(name, e.target.value),
      value: form.getByName(name),
    };
  }

  helperText() {
    const { name } = this.props;
    return this.props.inputFeedback.errors.getByName(name);
  }

  options() {
    const { blank, blankLabel, categorize, labelText, options } = this.props;
    const optionsNode = categorize
      ? this.makeCategorizedOptions(options)
      : this.makeUncategorizedOptions(options);
    if (blank)
      optionsNode.unshift(
        <option key={UNSPECIFIED.code} value={UNSPECIFIED.code}>
          {labelText ? "" : blankLabel ? blankLabel : UNSPECIFIED.label}
        </option>
      );
    return optionsNode;
  }

  makeCategorizedOptions(options) {
    return new CategorizedOptions(options).getCategories().map((category) => (
      <optgroup key={category.code} label={category.label}>
        {this.makeUncategorizedOptions(category.options)}
      </optgroup>
    ));
  }

  makeUncategorizedOptions(options) {
    return options
      .sort((a, b) => a.orderNo - b.orderNo)
      .map((option) => (
        <option key={option.code} value={option.code}>
          {option.label}
        </option>
      ));
  }

  render() {
    const { labelText } = this.props;
    const helperText = this.helperText();
    return (
      <FormControl {...this.formControlProps()}>
        {labelText ? <InputLabel>{labelText}</InputLabel> : null}
        <NativeSelect {...this.selectProps()}>{this.options()}</NativeSelect>
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }
}

const styles = {
  default: {
    width: "100%",
  },
};

StandardSelect.propTypes = {
  blank: PropTypes.bool,
  blankLabel: PropTypes.string,
  categorize: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  inputFeedback: PropTypes.object.isRequired,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(StandardSelect);

function mapStateToProps(state) {
  return {
    inputFeedback: state.inputFeedback,
  };
}

function mapDispatchToProps() {
  return {};
}
