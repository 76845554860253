import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import WebFrontHeader from "./WebFrontHeader";
import WebFrontFooter from "./WebFrontFooter";
import WebFrontInformation from "./WebFrontInformation";
import WebFrontNavigation from "./WebFrontNavigation";
import Pathname from "../../app/naming/Pathname";
import Mount from "../../app/ui/layout/Mount";
import ScrollTopButton from "../../app/ui/button/ScrollTopButton";
import { Banner } from "../tools/Banner";

class WebFrontLayout extends React.Component {
  render() {
    return (
      <div className="webfront c-header-page">
        <WebFrontHeader />
        <div className="l-content">
          <Mount if={this.pageIsTop()}>
            <WebFrontInformation />
          </Mount>
          <WebFrontNavigation />
          <Banner />
          {this.props.children}
          <WebFrontFooter />
        </div>
        <ScrollTopButton />
      </div>
    );
  }

  pageIsTop() {
    const pathname = window.location.href.substr(
      window.location.protocol.length +
        "//".length +
        window.location.host.length
    );
    return (
      pathname === Pathname.webfront ||
      pathname === Pathname.webfront_folder_widget ||
      pathname.startsWith(Pathname.webfront_search_condition)
    );
  }
}

WebFrontLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(WebFrontLayout);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
