import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";

const TaiokanoShojoTable = ({ contents }) => {
  const {
    blocks: { shortstayTaiokanoShojo: taiokanoShojo },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            {taiokanoShojo.list.map((item, i) => {
              return (
                <tr key={i}>
                  <th>
                    {(() => {
                      if (item.label == "受入れ / 対応可能な医療行為") {
                        return "受入れ / 対応可能な 医療行為";
                      } else {
                        return item.label;
                      }
                    })()}
                  </th>
                  <td>
                    {(() => {
                      if (item.taioka.length > 0) {
                        return (
                          <div className="p-office-body-table__doublecircle">
                            {item.taioka.join("、")}
                          </div>
                        );
                      }
                    })()}
                    {(() => {
                      if (item.ka.length > 0) {
                        return (
                          <div className="p-office-body-table__circle">
                            {item.ka.join("、")}
                          </div>
                        );
                      }
                    })()}
                    {(() => {
                      if (item.yousoudan.length > 0) {
                        return (
                          <div className="p-office-body-table__triangle">
                            {item.yousoudan.join("、")}
                          </div>
                        );
                      }
                    })()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      <div className="p-office-body-note">
        <span className="p-office-body-legend__doublecircle">
          対応可能&nbsp;
        </span>
        <span className="p-office-body-legend__circle">受入れ可能&nbsp;</span>
        <span className="p-office-body-legend__triangle">要相談&nbsp;</span>
      </div>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.shortstayTaiokanoShojo
      )}
    </Fragment>
  );
};

TaiokanoShojoTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default TaiokanoShojoTable;
