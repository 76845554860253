import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import { serviceShubetsuTypeValueOf } from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import Mount from "../../../../../app/ui/layout/Mount";
import StaffShikakuKaigo from "./StaffShikakuKaigo";
import StaffShikakuKango from "./StaffShikakuKango";
import StaffShikakuNyuyoku from "./StaffShikakuNyuyoku";
import StaffShikakuRehabilitation from "./StaffShikakuRehabilitation";
import StaffShikakuTeizui from "./StaffShikakuTeizui";

const StaffTable = (props) => {
  const contents = props.contents;
  const {
    serviceShubetsuCode,
    blocks: { homonkeiServiceStaff: staff },
  } = contents;
  const serviceShubetsuType = serviceShubetsuTypeValueOf(serviceShubetsuCode);

  return (
    <Fragment>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>男女別従業員数</th>
                  <td className="val">
                    <ul>
                      <li>
                        <div className="val-name">男性</div>
                        <div className="val-val">{staff.danseiNinzu}</div>
                      </li>
                      <li>
                        <div className="val-name">女性</div>
                        <div className="val-val">{staff.joseiNinzu}</div>
                      </li>
                      <li>
                        <div className="val-name">合計</div>
                        <div className="val-val">{staff.gokeiNinzu}</div>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="p-office-body-sep__right">
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>年齢別従業員数</th>
                  <td className="val">
                    <ul>
                      <li>
                        <div className="val-name">20代</div>
                        <div className="val-val">
                          {staff.nenreibetsu20DaiNinzu}
                        </div>
                      </li>
                      <li>
                        <div className="val-name">30代</div>
                        <div className="val-val">
                          {staff.nenreibetsu30DaiNinzu}
                        </div>
                      </li>
                      <li>
                        <div className="val-name">40代</div>
                        <div className="val-val">
                          {staff.nenreibetsu40DaiNinzu}
                        </div>
                      </li>
                      <li>
                        <div className="val-name">50代</div>
                        <div className="val-val">
                          {staff.nenreibetsu50DaiNinzu}
                        </div>
                      </li>
                      <li>
                        <div className="val-name">60代以上</div>
                        <div className="val-val">
                          {staff.nenreibetsu60DaiNinzu}
                        </div>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Mount if={serviceShubetsuType === ServiceShubetsuType.訪問介護}>
        <StaffShikakuKaigo {...props} />
      </Mount>
      <Mount if={serviceShubetsuType === ServiceShubetsuType.訪問看護}>
        <StaffShikakuKango {...props} />
      </Mount>
      <Mount if={serviceShubetsuType === ServiceShubetsuType.訪問入浴介護}>
        <StaffShikakuNyuyoku {...props} />
      </Mount>
      <Mount
        if={serviceShubetsuType === ServiceShubetsuType.訪問リハビリテーション}
      >
        <StaffShikakuRehabilitation {...props} />
      </Mount>
      <Mount
        if={
          serviceShubetsuType ===
          ServiceShubetsuType.定期巡回_随時対応型訪問介護看護
        }
      >
        <StaffShikakuTeizui {...props} />
      </Mount>

      <div className="p-office-body-sep">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>管理者保有資格</th>
                  <td>{staff.kanrishaHoyushikaku}</td>
                </tr>
                <Mount
                  if={[
                    ServiceShubetsuType.訪問介護,
                    ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
                  ]
                    .map((it) => it.code)
                    .includes(serviceShubetsuCode)}
                >
                  <tr>
                    <th>
                      サービス提供責任者
                      <br />
                      保有資格
                    </th>
                    <td>{staff.serviceTeikyosekininshaHoyushikaku}</td>
                  </tr>
                </Mount>
                <tr>
                  <th>経験年数5年以上の従業員の割合</th>
                  <td>{staff.keiken5nenWariai}</td>
                </tr>
                <tr>
                  <th>その他</th>
                  <td>{staff.sonota.join("、")}</td>
                </tr>
                <tr>
                  <th>備考</th>
                  <td>{staff.biko}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="p-office-body-sep__right">
          <div className="p-office-body-characteristic">特 徴</div>
          <div className="p-office-body-description p-office-body-description--p">
            {staff.tokucho}
          </div>

          {goToJigyoshoAttributeFormButton(
            contents,
            JigyoshoAttributeType.homonkeiServiceStaff
          )}
        </div>
      </div>
    </Fragment>
  );
};

StaffTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default StaffTable;

export function renderStaffJokinHijokin(jokinHijokin) {
  return [
    jokinHijokin.jokin ? "常勤" + jokinHijokin.jokin : null,
    jokinHijokin.hijokin ? "非常勤" + jokinHijokin.hijokin : null,
    jokinHijokin.gokei ? "合計" + jokinHijokin.gokei : null,
  ]
    .filter((item) => {
      return item !== null;
    })
    .join("、");
}

export function renderNinzuShikaku(ninzu, shikaku) {
  if (!ninzu) {
    return shikaku;
  }
  if (!shikaku) {
    return ninzu;
  }
  return `${ninzu}（${shikaku}）`;
}
