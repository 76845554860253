import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import Mount from "../../../../../app/ui/layout/Mount";

const RiyoshaTable = ({ contents, detail }) => {
  const {
    blocks: { homonkeiServiceRiyosha: riyosha },
  } = contents;

  return (
    <Fragment>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>男女別ご利用者数</th>
                  <td className="val">
                    <ul>
                      <li>
                        <div className="val-name">男性</div>
                        <div className="val-val">{riyosha.danseiNinzu}</div>
                      </li>
                      <li>
                        <div className="val-name">女性</div>
                        <div className="val-val">{riyosha.joseiNinzu}</div>
                      </li>
                      <li>
                        <div className="val-name">合計</div>
                        <div className="val-val">{riyosha.gokeiNinzu}</div>
                      </li>
                    </ul>
                  </td>
                </tr>
                <tr>
                  <th>年齢別ご利用者数</th>
                  <td className="val">
                    <ul>
                      <li>
                        <div className="val-name">65歳未満</div>
                        <div className="val-val">
                          {riyosha.nenreibetsu65miman}
                        </div>
                      </li>
                      <li>
                        <div className="val-name">65歳〜75歳未満</div>
                        <div className="val-val">
                          {riyosha.nenreibetsu65ijo75miman}
                        </div>
                      </li>
                      <li>
                        <div className="val-name">75歳〜85歳未満</div>
                        <div className="val-val">
                          {riyosha.nenreibetsu75ijo85miman}
                        </div>
                      </li>
                      <li>
                        <div className="val-name">85歳〜95歳未満</div>
                        <div className="val-val">
                          {riyosha.nenreibetsu85ijo95miman}
                        </div>
                      </li>
                      <li>
                        <div className="val-name">95歳以上</div>
                        <div className="val-val">
                          {riyosha.nenreibetsu95ijo}
                        </div>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="p-office-body-sep__right">
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>要介護度別ご利用者数</th>
                  <td className="val">
                    <ul>
                      <li>
                        <div className="val-name">要支援1</div>
                        <div className="val-val">{riyosha.yoshien1}</div>
                      </li>
                      <li>
                        <div className="val-name">要支援2</div>
                        <div className="val-val">{riyosha.yoshien2}</div>
                      </li>
                      <li>
                        <div className="val-name">要介護1</div>
                        <div className="val-val">{riyosha.yokaigo1}</div>
                      </li>
                      <li>
                        <div className="val-name">要介護2</div>
                        <div className="val-val">{riyosha.yokaigo2}</div>
                      </li>
                      <li>
                        <div className="val-name">要介護3</div>
                        <div className="val-val">{riyosha.yokaigo3}</div>
                      </li>
                      <li>
                        <div className="val-name">要介護4</div>
                        <div className="val-val">{riyosha.yokaigo4}</div>
                      </li>
                      <li>
                        <div className="val-name">要介護5</div>
                        <div className="val-val">{riyosha.yokaigo5}</div>
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <Mount if={detail}>
        <div className="p-office-body-characteristic">特 徴</div>
        <div className="p-office-body-description p-office-body-description--p">
          {riyosha.tokucho}
        </div>
      </Mount>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.homonkeiServiceRiyosha
      )}
    </Fragment>
  );
};

RiyoshaTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default RiyoshaTable;
