import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import For from "../../../../../app/ui/layout/For";
import TimePeriod from "../../../../../app/ui/form/TimePeriod";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Textarea from "../../../../../app/ui/form/Textarea";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import { OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE } from "../../../../../app/ui/form";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import {
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
  withMasterData,
} from "../../../../../state/master-data";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import GridRow from "../../../../../app/ui/grid/GridRow";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import GridCell from "../../../../../app/ui/grid/GridCell";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";

class TsushokeiServiceJigyoshoEigyoJikanForm extends React.Component {
  constructor(props) {
    super(props);
  }

  shouldComponentUpdate(nextProps) {
    const nextBindingProps = nextProps.bindingProps;
    const preBindingProps = this.props.bindingProps;
    const nextTeikyobusu = nextBindingProps.form.getByName("teikyobusuCode");
    const preTeikyobusu = preBindingProps.form.getByName("teikyobusuCode");
    const preHeijitsuStart = preBindingProps.form.getByName("heijitsu").start;
    const preHeijitsuEnd = preBindingProps.form.getByName("heijitsu").end;
    const nextHeijitsuStart = nextBindingProps.form.getByName("heijitsu").start;
    const nextHeijitsuEnd = nextBindingProps.form.getByName("heijitsu").end;
    const dataHasBeenUpdated = preBindingProps.form.getByName(
      "dataHasBeenUpdated"
    );
    const teikyobusuChangedTo1 =
      nextTeikyobusu === "1" && preTeikyobusu !== "1";
    const teikyobusuChangedFrom1 =
      nextTeikyobusu !== "1" && preTeikyobusu === "1";
    const heijitsuStartChanged =
      nextTeikyobusu === "1" && preHeijitsuStart !== nextHeijitsuStart;
    const heijitsuEndChanged =
      nextTeikyobusu === "1" && preHeijitsuEnd !== nextHeijitsuEnd;

    if (
      (teikyobusuChangedTo1 || heijitsuStartChanged || heijitsuEndChanged) &&
      !dataHasBeenUpdated
    ) {
      nextProps.bindingProps.onChange("section1.start", nextHeijitsuStart);
      nextProps.bindingProps.onChange("section1.end", nextHeijitsuEnd);
    }
    if (teikyobusuChangedFrom1 && !dataHasBeenUpdated) {
      nextProps.bindingProps.onChange("section1.end", "");
    }
    return true;
  }

  componentWillMount() {
    const { bindingProps } = this.props;
    const heijitsuStart = bindingProps.form.getByName("heijitsu").start;
    const heijitsuEnd = bindingProps.form.getByName("heijitsu").end;
    const section1Start = bindingProps.form.getByName("section1").start;
    const section1End = bindingProps.form.getByName("section1").end;
    const teikyobusu = bindingProps.form.getByName("teikyobusuCode");
    const heijitsuStartEntered = heijitsuStart !== "" && section1Start === "";
    const heijitsuEndEntered = heijitsuEnd !== "" && section1End === "";
    if (heijitsuStartEntered) {
      bindingProps.onChange("section1.start", heijitsuStart);
    }
    if (heijitsuEndEntered && teikyobusu === "1") {
      bindingProps.onChange("section1.end", heijitsuEnd);
    }
  }

  render() {
    return (
      <Fragment>
        {this.renderEigyoJikan()}
        {this.renderTeikyobusu()}
        {this.renderJikangai()}
        {this.renderTeikyubi()}
      </Fragment>
    );
  }

  renderEigyoJikan() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="営業時間">
        <HorizontalLabelLayout labelText="平日" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="heijitsu"
                disabled={bindingProps.form.getByName("heijitsu_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="heijitsu_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="土曜" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="doyo"
                disabled={bindingProps.form.getByName("doyo_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="doyo_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="日曜" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="nichiyo"
                disabled={bindingProps.form.getByName("nichiyo_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="nichiyo_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="祝日" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="shukujitsu"
                disabled={bindingProps.form.getByName("shukujitsu_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="shukujitsu_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderTeikyobusu() {
    const { bindingProps, masterData, serviceShubetsuCode } = this.props;
    const teikyobusu = Number(bindingProps.form.getByName("teikyobusuCode"));
    const teikyobusu_eigyojikan = [
      "section1",
      "section2",
      "section3",
      "section4",
      "section5",
    ];
    const tsushokeiServiceTeikyobusu = [
      ServiceShubetsuType.通所リハビリテーション,
    ]
      .map((it) => it.code)
      .includes(serviceShubetsuCode)
      ? masterData.tsushokeiService_teikyobusu
      : masterData.tsushokeiService_teikyobusu.filter((i) => i.code < 4);
    return (
      <FormGrouping title="提供部数">
        <HorizontalLabelLayout labelText="部数" required>
          <OptionsRadio
            name="teikyobusuCode"
            split={6}
            options={tsushokeiServiceTeikyobusu}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <For
          in={[...Array(teikyobusu).keys()]}
          each={(it) => (
            <Fragment key={it}>
              <HorizontalLabelLayout labelText={`${it + 1}部`} required>
                <ShortInputWidth>
                  <TimePeriod
                    name={teikyobusu_eigyojikan[it]}
                    separator="."
                    {...bindingProps}
                  />
                </ShortInputWidth>
              </HorizontalLabelLayout>
            </Fragment>
          )}
        />
      </FormGrouping>
    );
  }

  renderJikangai() {
    const { bindingProps, serviceShubetsuCode } = this.props;
    return (
      <FormGrouping title="時間外対応">
        <HorizontalLabelLayout labelText="早朝対応" required>
          <OptionsRadio
            name="sochoUkeireType"
            split={6}
            options={OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="夜間対応" required>
          <OptionsRadio
            name="yakanUkeireType"
            split={6}
            options={OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        {
          /* 通所リハビリテーションなら宿泊対応は非表示 */
          ![ServiceShubetsuType.通所リハビリテーション]
            .map((it) => it.code)
            .includes(serviceShubetsuCode) ? (
            <HorizontalLabelLayout labelText="宿泊対応" required>
              <OptionsRadio
                name="shukuhakuUkeireType"
                split={6}
                options={OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE}
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          ) : null
        }
      </FormGrouping>
    );
  }

  renderTeikyubi() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="定休日等">
        <HorizontalLabelLayout labelText="365日対応">
          <InputTypeCheckbox
            name="allyearUkeire"
            labelText="はい"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="定休日" required>
          <InputTypeText name="teikyubi" {...bindingProps} />
          <StaticLabel>
            ※
            定休日にチェックを入れた曜日以外の定休日がない場合は、なしと入力してください
          </StaticLabel>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="留意事項">
          <Textarea name="ryuijiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

TsushokeiServiceJigyoshoEigyoJikanForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(TsushokeiServiceJigyoshoEigyoJikanForm);
