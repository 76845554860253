import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ServiceContentType from "./ServiceContentType";
import Parametername from "../../../app/naming/Parametername";

class ServiceContentTab extends React.Component {
  constructor(props) {
    super(props);
    this.handleServiceContentButtonClick = this.handleServiceContentButtonClick.bind(
      this
    );
  }

  handleServiceContentButtonClick(serviceContentType) {
    this.props.onServiceContentButtonClick(serviceContentType);
  }

  render() {
    const { contents } = this.props;
    return (
      <div className="p-office-head-tab">
        <ul>
          <li
            className={this.tabClassName(ServiceContentType.abstract)}
            onClick={() =>
              this.handleServiceContentButtonClick(ServiceContentType.abstract)
            }
          >
            <a>{ServiceContentType.abstract.label}</a>
          </li>
          <li
            className={this.tabClassName(ServiceContentType.detail)}
            onClick={() =>
              this.handleServiceContentButtonClick(ServiceContentType.detail)
            }
          >
            <a>
              {contents
                ? ServiceContentType.detail.dynamicLabel(
                    contents.serviceShubetsuCategoryCode
                  )
                : ServiceContentType.detail.label}
            </a>
          </li>
          <li
            className={this.tabClassName(ServiceContentType.photo)}
            onClick={() =>
              this.handleServiceContentButtonClick(ServiceContentType.photo)
            }
          >
            <a>{ServiceContentType.photo.label}</a>
          </li>
          <li
            className={this.tabClassName(ServiceContentType.flier)}
            onClick={() =>
              this.handleServiceContentButtonClick(ServiceContentType.flier)
            }
          >
            <a>{ServiceContentType.flier.label}</a>
          </li>
          {this.renderTailTab(contents)}
        </ul>
      </div>
    );
  }

  renderTailTab(contents) {
    return contents
      ? contents.canShowComment
        ? this.renderCommentTab(contents)
        : null
      : this.renderDummyTab();
  }

  renderCommentTab(contents) {
    return (
      <li
        className={this.tabClassName(ServiceContentType.comment)}
        onClick={() =>
          this.handleServiceContentButtonClick(ServiceContentType.comment)
        }
      >
        <a>
          {ServiceContentType.comment.label} ({this.commentCount(contents)})
        </a>
      </li>
    );
  }

  renderDummyTab() {
    return <li>...</li>;
  }

  tabClassName(serviceContentType) {
    return this.props.serviceContentType.name == serviceContentType.name
      ? "is-active"
      : null;
  }

  commentCount(contents) {
    if (!contents) return "-";
    return contents.commentCount;
  }
}

ServiceContentTab.propTypes = {
  contents: PropTypes.object,
  id: PropTypes.number.isRequired,
  serviceContentType: PropTypes.object.isRequired,
  servicePageType: PropTypes.object.isRequired,
  // on-event handler
  onServiceContentButtonClick: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(ServiceContentTab);

function mapDispatchToProps(dispatch, props) {
  const searchParams = new URLSearchParams();
  if (props.serviceSubId)
    searchParams.set(Parametername.serviceSubId, props.serviceSubId);
  return {
    onServiceContentButtonClick: (serviceContentType) => {
      props.history.push({
        pathname: `${props.servicePageType.pathname
          .replace(/:id/, props.id)
          .replace(/:type/, serviceContentType.name)}`,
        search: `${searchParams.toString()}`,
        state: { url: props.url },
      });
    },
  };
}
