import React from "react";
import InputTypeText from "./InputTypeText";

const InputTypeDate = (props) => {
  return <InputTypeText type="date" {...inputTypeTextProps(props)} />;
};

function inputTypeTextProps(props) {
  return {
    ...props,
    hintText: "2000/01/01",
  };
}

export default InputTypeDate;
