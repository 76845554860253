import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import {
  OPTIONS_ALLOWABILITY,
  OPTIONS_ALLOWABILITY_FUZZY,
} from "../../../../../app/ui/form";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  MASTER_DATA_BUNDLE_FOR_SHORTSTAY,
  withMasterData,
} from "../../../../../state/master-data";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import HorizontalSubHeadLabelLayout from "../../../../../app/ui/form/HorizontalSubHeadLabelLayout";

class ShortstayHaisetsuForm extends React.Component {
  render() {
    return <Fragment>{this.renderHaisetsu()}</Fragment>;
  }

  renderHaisetsu() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="排泄">
        <HorizontalLabelLayout labelText="特徴">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>

        <HorizontalLabelLayout labelText="トイレの設置数">
          <ShortInputWidth>
            <HorizontalSubHeadLabelLayout labelText="共同">
              <UnitLayout unitText="箇所">
                <InputTypeNumber name="kyodoToilet" {...bindingProps} />
              </UnitLayout>
            </HorizontalSubHeadLabelLayout>
          </ShortInputWidth>
          <ShortInputWidth>
            <HorizontalSubHeadLabelLayout labelText="個室（居室）">
              <UnitLayout unitText="箇所">
                <InputTypeNumber name="koshitsuToilet" {...bindingProps} />
              </UnitLayout>
            </HorizontalSubHeadLabelLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="車椅子対応" required>
          <OptionsRadio
            name="kurumaisutaio"
            split={6}
            options={OPTIONS_ALLOWABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="同性介助" required>
          <OptionsRadio
            name="doseikaijo"
            split={6}
            options={OPTIONS_ALLOWABILITY_FUZZY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="その他">
          <OptionsCheckbox
            name="haisetsuSonota"
            options={masterData.shortstay_HaisetsuSonota}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

ShortstayHaisetsuForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SHORTSTAY)(
  ShortstayHaisetsuForm
);
