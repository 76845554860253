import React from "react";
import PropTypes from "prop-types";
import {
  mergeStyleToProps,
  removePropertyFromProps,
} from "../../fundamental/react/props";
import cssStyles from "./SpaceOut.css";
import { css } from "aphrodite";

const SpaceOut = (props) => {
  const className =
    props.block && props.comma ? css(cssStyles.container) : null;
  return props.block ? (
    <div className={className} {...mergeProps(props)}></div>
  ) : (
    <span className={className} {...mergeProps(props)}></span>
  );
};

function mergeProps(props) {
  props = mergeStyleToProps(
    props.block ? styles.sidePadding.div : styles.sidePadding.span,
    props
  );
  if (props.bottom) props = mergeStyleToProps(styles.bottomPadding, props);
  if (props.top) props = mergeStyleToProps(styles.topPadding, props);
  props = removePropertyFromProps("bottom", props);
  props = removePropertyFromProps("top", props);
  props = removePropertyFromProps("block", props);
  props = removePropertyFromProps("comma", props);
  return props;
}

const styles = {
  sidePadding: {
    span: {
      padding: "0 3px",
    },
    div: {
      padding: "0 6px",
    },
  },
  bottomPadding: {
    paddingBottom: 3,
  },
  topPadding: {
    paddingTop: 3,
  },
};

SpaceOut.propTypes = {
  block: PropTypes.bool,
  bottom: PropTypes.bool,
  comma: PropTypes.bool,
  top: PropTypes.bool,
};

export default SpaceOut;
