import React from "react";
import PropTypes from "prop-types";
import { GoogleMap, Marker } from "react-google-maps";
import { withGoogleMap } from "../../../../state/google-map";
import {
  fitBounds,
  initialCenterAndZoom,
} from "../../../../app/fundamental/map/bounds";

const MAX_MARKER_NUMBER = 100; // カスタムマーカーアイコンは100個用意してある

class Map extends React.Component {
  constructor(props) {
    super(props);

    this.handleMapMounted = this.handleMapMounted.bind(this);

    this.state = {
      centerAndZoom: { center: null, zoom: null },
      mouseOverId: null,
    };
  }

  componentDidMount() {
    this.setInitialMapBounds();
  }

  handleMapMounted(googleMap) {
    this.googleMap = googleMap;
    if (this.props.onGoogleMapMounted) {
      this.props.onGoogleMapMounted(googleMap);
    }
  }

  setInitialMapBounds() {
    const centerAndZoom = initialCenterAndZoom(this.props.serviceGroups);
    if (centerAndZoom) {
      this.setState({
        centerAndZoom: centerAndZoom,
      });
    } else {
      fitBounds(this.props.serviceGroups, this.googleMap);
    }
  }

  render() {
    const { serviceGroups } = this.props;
    const { centerAndZoom } = this.state;

    const googleMapOptions = {
      clickableIcons: false,
      fullscreenControl: false,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
    };

    return (
      <GoogleMap
        center={centerAndZoom.center}
        zoom={centerAndZoom.zoom}
        options={googleMapOptions}
        onBoundsChanged={this.handleBoundsChanged}
        ref={this.handleMapMounted}
      >
        {serviceGroups.map((serviceGroup, i) =>
          this.renderMarker(serviceGroup, i)
        )}
      </GoogleMap>
    );
  }

  renderMarker(serviceGroup, i) {
    if (!serviceGroup.latitude || !serviceGroup.longitude) return null;
    if (serviceGroup.number <= MAX_MARKER_NUMBER) {
      return (
        <Marker
          key={i}
          position={{
            lat: +serviceGroup.latitude,
            lng: +serviceGroup.longitude,
          }}
          icon={{
            url: `/images/map-marker/marker-with-number-${serviceGroup.number}.svg`,
            scaledSize: { width: 27, height: 43 },
          }}
        ></Marker>
      );
    } else {
      return (
        <Marker
          key={i}
          position={{
            lat: +serviceGroup.latitude,
            lng: +serviceGroup.longitude,
          }}
          label={{
            color: "#ffffff",
            text: String(serviceGroup.number),
            fontSize: "17px",
          }}
        ></Marker>
      );
    }
  }
}

Map.propTypes = {
  serviceGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGoogleMapMounted: PropTypes.func,
};

export default withGoogleMap(Map);
