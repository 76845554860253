import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import { OPTIONS_AVAILABILITY } from "../../../../../app/ui/form";
import {
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO,
  withMasterData,
} from "../../../../../state/master-data";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import Textarea from "../../../../../app/ui/form/Textarea";

class TatemonoSetsubiBihinForm extends React.Component {
  render() {
    return <Fragment>{this.renderTatemonoSetsubiBihin()}</Fragment>;
  }

  renderTatemonoSetsubiBihin() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="建物・設備・備品">
        <HorizontalLabelLayout labelText="事業所設置階" required>
          <div>
            <RatioBlock divide={4}>
              <SpaceOut block>
                <UnitLayout unitText="階">
                  <InputTypeNumber name="jigyoshosetchikai" {...bindingProps} />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
          </div>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="建物面積">
          <div>
            <RatioBlock divide={4}>
              <SpaceOut block>
                <StaticLabel>食堂面積</StaticLabel>
                <UnitLayout unitText="㎡">
                  <InputTypeText
                    name="shokudomenseki"
                    hintText="0.0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={4}>
              <SpaceOut block>
                <StaticLabel>機能訓練室面積</StaticLabel>
                <UnitLayout unitText="㎡">
                  <InputTypeText
                    name="kinokunrenshitsumenseki"
                    hintText="0.0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={4}>
              <SpaceOut block>
                <StaticLabel>静養室面積</StaticLabel>
                <UnitLayout unitText="㎡">
                  <InputTypeText
                    name="seiyoshitsumenseki"
                    hintText="0.0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
            <RatioBlock divide={4}>
              <SpaceOut block>
                <StaticLabel>相談室面積</StaticLabel>
                <UnitLayout unitText="㎡">
                  <InputTypeText
                    name="sodanshitsumenseki"
                    hintText="0.0"
                    {...bindingProps}
                  />
                </UnitLayout>
              </SpaceOut>
            </RatioBlock>
          </div>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="構造" required>
          <OptionsRadio
            name="kozoCode"
            options={masterData.tatemonoKozo}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="エレベーター" required>
          <OptionsRadio
            name="elevator"
            options={OPTIONS_AVAILABILITY}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="デザイン" required>
          <OptionsRadio
            name="designCode"
            options={masterData.tatemonoDesign}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="建物改築区分" required>
          <OptionsRadio
            name="tatemonokaichikukubunCode"
            options={masterData.tatemonoKaichikuKubun}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="ナースコール">
          <OptionsCheckbox
            name="tatemonoSetsubiBihinNurseCall"
            options={masterData.nurseCall}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="設備">
          <OptionsCheckbox
            name="tatemonoSetsubiBihinTatemonoSetsubi"
            options={masterData.tatemonoSetsubi}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備品">
          <OptionsCheckbox
            name="tatemonoSetsubiBihinTatemonoBihin"
            options={masterData.tatemonoBihin}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

TatemonoSetsubiBihinForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(
  MASTER_DATA_BUNDLE_FOR_TSUSHOKEI_SERVICE_JIGYOSHO
)(TatemonoSetsubiBihinForm);
