import React from "react";
import PropTypes from "prop-types";
import PrintTable from "../../../../app/ui/print/PrintTable";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const HokengaiTable = (props) => {
  const { service } = props;
  const { hokengaiService, mimamoriSuishinJigyo } = service.blocks;

  return (
    <PrintTable {...props}>
      <PrintTableNode width="105px">
        {hokengaiService.jigyoshomei}
        <div className="c-table-frame__updated-at">
          ({service.userUpdatedAt})
        </div>
      </PrintTableNode>
      <PrintTableNode width="90px" center>
        {mimamoriSuishinJigyo.gyoshu}
      </PrintTableNode>
      <PrintTableNode width="150px">{service.jusho}</PrintTableNode>
      <PrintTableNode width="112px">
        {hokengaiService.denwabango}
      </PrintTableNode>
      <PrintTableNode width="75px" center>
        {hokengaiService.teikyubi.join("・")}
      </PrintTableNode>
      <PrintTableNode width="120px" center>
        {hokengaiService.eigyojikan}
      </PrintTableNode>
      <PrintTableNode width="105px" center>
        {mimamoriTeikyoChiiki(mimamoriSuishinJigyo)}
      </PrintTableNode>
      <PrintTableNode width="160px">
        {mimamoriSuishinJigyo.mimamoriNaiyo.map((it) => (
          <div key={it}>・{it}</div>
        ))}
      </PrintTableNode>
      <PrintTableNode width="115px" center>
        {mimamoriSuishinJigyo.tantoChiikiHokatsuShienCenter}
      </PrintTableNode>
    </PrintTable>
  );
};

function mimamoriTeikyoChiiki(mimamoriSuishinJigyo) {
  const LABEL_LIMIT = 69;
  let mimamoriTeikyoChiiki = mimamoriSuishinJigyo.mimamoriTeikyoChiiki;
  mimamoriTeikyoChiiki =
    mimamoriTeikyoChiiki && mimamoriTeikyoChiiki.length > LABEL_LIMIT
      ? mimamoriTeikyoChiiki.substr(0, LABEL_LIMIT) + "..."
      : mimamoriTeikyoChiiki;

  return mimamoriTeikyoChiiki;
}

HokengaiTable.propTypes = {
  onServiceButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default HokengaiTable;
