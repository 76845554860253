import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";

const initialState = {
  leadToLogin: false,
  message: undefined,
  open: false,
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.LEAD_TO_LOGIN]: function (state, action) {
    return {
      ...state,
      leadToLogin: true,
      message: action.payload.message,
      open: true,
    };
  },
  [ActionType.OPEN_MESSAGE]: function (state, action) {
    return {
      ...state,
      leadToLogin: false,
      message: action.payload.message,
      open: true,
    };
  },
  [ActionType.CLOSE_MESSAGE]: function (state) {
    return {
      ...state,
      open: false,
    };
  },
});

export default reducers.createReducer(initialState);
