import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import TruncateText from "../../../../../app/ui/text/TruncateText";

const HokengaiBlock = ({ hokengaiService, serviceShubetsuType }) => {
  if (
    serviceShubetsuType === ServiceShubetsuType.訪問入浴介護 ||
    serviceShubetsuType === ServiceShubetsuType.定期巡回_随時対応型訪問介護看護
  )
    return null;
  const LABEL_LIMIT = 230;
  return (
    <Fragment>
      <HeaderTextBlue text="保険外サービス" />
      <div className="print-body-text_area">
        <TruncateText maxLength={LABEL_LIMIT}>
          {hokengaiService.hokengaiService}
        </TruncateText>
      </div>
    </Fragment>
  );
};

HokengaiBlock.propTypes = {
  hokengaiService: PropTypes.object.isRequired,
  serviceShubetsuType: PropTypes.object.isRequired,
};

export default HokengaiBlock;
