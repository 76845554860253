import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";

const KaigoHosyuKasanBlock = ({ kaigohoshu }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="介護報酬加算" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr>
              <th>特定事業所加算</th>
              <td>{kaigohoshu.tokuteijigyoshokasan}</td>
              <th>入院時情報連携加算</th>
              <td>{kaigohoshu.nyuinjijohorenkeikasan}</td>
            </tr>
            <tr>
              <th>
                小規模多機能型居宅
                <br />
                介護事業所連携加算
              </th>
              <td>{kaigohoshu.shotakigatakyotakukaigojigyoshorenkeikasan}</td>
              <th>
                看護多機能型居宅
                <br />
                介護事業所連携加算
              </th>
              <td>{kaigohoshu.kantakigatakyotakukaigojigyoshorenkeikasan}</td>
            </tr>
            <tr>
              <th>
                緊急時等居宅
                <br />
                カンファレンス加算
              </th>
              <td>{kaigohoshu.kinkyujitokyotakuConferenceKasan}</td>
              <th>
                ターミナルケア
                <br />
                マネジメント加算
              </th>
              <td>{kaigohoshu.terminalCareManagementKasan}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

KaigoHosyuKasanBlock.propTypes = {
  kaigohoshu: PropTypes.object.isRequired,
};

export default KaigoHosyuKasanBlock;
