import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import {
  OPTIONS_AVAILABILITY,
  OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE,
  OPTIONS_TAIO_JOKYO_POSITIVE_DEFAULT,
} from "../../../../../app/ui/form";
import For from "../../../../../app/ui/layout/For";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

/**
 * 訪問系サービス看護フォーム.
 */
class HomonkeiServiceKangoForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { serviceShubetsuCode } = this.props;
    return (
      <Fragment>
        {this.renderTokucho()}
        {this.renderNaiyo()}
        {this.renderTerminalCare()}
        {this.renderEngelCare()}
        <Mount
          if={[ServiceShubetsuType.訪問看護]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => this.renderSemmonshokuinHomon()}
        />
        {this.renderBiko()}
      </Fragment>
    );
  }

  renderTokucho() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="サービスの特徴・強み">
        <HorizontalLabelLayout labelText="">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderNaiyo() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="看護内容" required>
        <For
          in={masterData.homonkeiService_kango}
          each={(option) => (
            <HorizontalLabelLayout
              key={`naiyo.${option.code}`}
              labelText={option.label}
            >
              <OptionsRadio
                name={`naiyo.${option.code}`}
                split={6}
                options={OPTIONS_TAIO_JOKYO_POSITIVE_DEFAULT}
                {...bindingProps}
              />
            </HorizontalLabelLayout>
          )}
        />
      </FormGrouping>
    );
  }

  renderRadio(item) {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title={item.label} required>
        <HorizontalLabelLayout labelText="">
          <OptionsRadio
            name={item.name}
            split={6}
            options={item.options}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderTerminalCare() {
    return this.renderRadio({
      name: "terminalCare",
      label: "ターミナルケア",
      options: OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE,
    });
  }

  renderEngelCare() {
    return this.renderRadio({
      name: "engelCare",
      label: "エンゼルケア",
      options: OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE,
    });
  }

  renderSemmonshokuinHomon() {
    return this.renderRadio({
      name: "semmonshokuinHomon",
      label: "リハビリ専門職員による訪問",
      options: OPTIONS_AVAILABILITY,
    });
  }

  renderBiko() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="備考">
        <HorizontalLabelLayout labelText="">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HomonkeiServiceKangoForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceKangoForm
);
