import React from "react";
import PropTypes from "prop-types";
import Thumbnail from "../Thumbnail";
import CardHead from "./CardHead";
import CardFoot from "./CardFoot";
import CardFrameItem from "./CardFrameItem";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";

const TsushokeiServiceCard = (props) => {
  const { service, selected, onSelect, shared } = props;
  const hokennaiService = service.blocks.hokennaiService;
  const tsushokeiService = service.blocks.tsushokeiService;

  // 通所リハなら時間外対応の宿泊を非表示
  if (
    [ServiceShubetsuType.通所リハビリテーション]
      .map((it) => it.code)
      .includes(service.serviceShubetsuCode) &&
    tsushokeiService.jikangaitaio.length >= 3
  ) {
    tsushokeiService.jikangaitaio.pop();
  }
  return (
    <div
      className={`c-card-frame__item ${
        selected ? "c-card-frame__item--y" : ""
      }`}
    >
      <CardHead
        onSelect={onSelect}
        onServiceButtonClick={props.onServiceButtonClick}
        selected={selected}
        service={service}
      />
      <div className="c-card-frame__item-body">
        <div className="c-card-frame__item-body-left">
          <div className="c-card-frame-picture">
            <a onClick={() => props.onServiceButtonClick(service.id)}>
              <Thumbnail src={service.thumbnail} />
            </a>
          </div>
          <div className="c-weekcalendar">
            <table>
              <caption>空き状況</caption>
              <thead>
                <tr>
                  {tsushokeiService.akijokyo.map((youbi, i) => {
                    return <th key={i}>{youbi[0]}</th>;
                  })}
                </tr>
              </thead>
              <tbody>
                <tr>
                  {tsushokeiService.akijokyo.map((youbi, i) => {
                    return <td key={i}>{youbi[1]}</td>;
                  })}
                </tr>
              </tbody>
            </table>
            <div className="c-card-frame__item-head-date">
              {tsushokeiService.akijokyoUserUpdatedAt}時点
            </div>
          </div>
        </div>
        <div className="c-card-frame__item-body-right">
          <div className="c-dl">
            <dl>
              <CardFrameItem title="TEL">{hokennaiService.tel}</CardFrameItem>
              <CardFrameItem title="住所">{service.jusho}</CardFrameItem>
              <CardFrameItem title="営業日">
                {hokennaiService.eigyobi.join("、")}
              </CardFrameItem>
              <CardFrameItem title="サービス提供地域">
                {tsushokeiService.serviceTeikyochiiki}
              </CardFrameItem>
              <CardFrameItem title="時間外対応">
                {tsushokeiService.jikangaitaio
                  .map((tj) => tj.join(" "))
                  .join("、")}
              </CardFrameItem>
              <CardFrameItem title="提供サービス">
                {tsushokeiService.teikyoService.join("、")}
              </CardFrameItem>
              <CardFrameItem title="昼食提供">
                {tsushokeiService.chushokuteikyo}
              </CardFrameItem>
              <CardFrameItem title="特徴">
                {tsushokeiService.tokucho.join("、")}
              </CardFrameItem>
              <CardFrameItem title="特記事項">
                {hokennaiService.tokkijiko}
              </CardFrameItem>
            </dl>
          </div>
          <CardFoot
            onPrintButtonClick={props.onPrintButtonClick}
            onServiceButtonClick={props.onServiceButtonClick}
            onShareButtonClick={props.onShareButtonClick}
            service={service}
            shared={shared}
          />
        </div>
      </div>
    </div>
  );
};

TsushokeiServiceCard.propTypes = {
  onPrintButtonClick: PropTypes.func.isRequired,
  onServiceButtonClick: PropTypes.func.isRequired,
  onShareButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  shared: PropTypes.bool.isRequired,
};

export default TsushokeiServiceCard;
