import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import BackOfficeHelp from "../../../../app/ui/structure/BackOfficeHelp";
import BackOfficeHelpItem from "../../../../app/ui/structure/BackOfficeHelpItem";

class OpenDataInformation extends React.Component {
  constructor(props) {
    super(props);
    this.handleAllButtonClick = this.handleAllButtonClick.bind(this);
  }

  handleAllButtonClick() {
    const { actions, search } = this.props;
    actions.gotoAddPage(search);
  }

  render() {
    return (
      <BackOfficeHelp>
        <BackOfficeHelpItem
          text="オープンデータが見つかりませんか？"
          action={
            <FlatPrimaryButton onClick={this.handleAllButtonClick}>
              新規に事業所を追加する
            </FlatPrimaryButton>
          }
        />
      </BackOfficeHelp>
    );
  }
}

OpenDataInformation.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  search: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OpenDataInformation);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gotoAddPage: (search = "") =>
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho_add,
            search,
          })
        ),
    },
  };
}
