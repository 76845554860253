import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import ServiceKaishi from "./ServiceKaishi";
import ServiceYoyaku from "./ServiceYoyaku";
import ServiceGyoseiJoseiHojo from "./ServiceGyoseiJoseiHojo";

class FuneralForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderTeikyoService()}
        {this.renderOption()}
        {this.renderShikakuTou()}
        {this.renderServiceKaishi()}
        {this.renderYoyaku()}
        {this.renderGyoseiJoseiHojo()}
      </Fragment>
    );
  }

  renderTeikyoService() {
    const { bindingProps } = this.props;
    const services = [
      { code: "transportFlg", label: "ご遺体搬送" },
      { code: "enshrinementFlg", label: "ご遺体安置" },
      { code: "bodyCareFlg", label: "ご遺体処置" },
      { code: "meetingFlg", label: "ご遺族との打ち合わせ（葬儀プランニング）" },
      { code: "venueFlg", label: "会場設営・撤去" },
      { code: "hostingFlg", label: "葬儀の司会・進行" },
      { code: "ihaiFlg", label: "位牌" },
      { code: "butsudanFlg", label: "仏壇" },
      { code: "inheritanceFlg", label: "相続" },
      { code: "giftFlg", label: "後返し" },
      { code: "memorialServicesFlg", label: "各種法事" },
      { code: "tradeInFlg", label: "不要品下取り" },
    ];
    return (
      <FormGrouping title="提供サービス" required>
        <HorizontalLabelLayout>
          <OptionsCheckbox
            name="teikyoService"
            options={services}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderOption() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="オプション" recommended>
        <HorizontalLabelLayout>
          <InputTypeCheckbox
            name="estimateFreeFlg"
            labelText="事前見積書作成無料"
            {...bindingProps}
          />
          <InputTypeCheckbox
            name="taimenSodanFreeFlg"
            labelText="対面相談無料"
            {...bindingProps}
          />
          <InputTypeCheckbox
              name="professionalFlg"
              labelText="専門スタッフが対応"
              {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderShikakuTou() {
    const { bindingProps } = this.props;
    const services = [
      { code: "funeralDirectorFlg", label: "厚生労働大臣認定 葬祭ディレクター" },
      { code: "otherLicenseFlg", label: "その他関連資格" },
    ];
    return (
      <FormGrouping title="資格等" recommended>
        <HorizontalLabelLayout>
          <OptionsCheckbox
            name="shikakuTou"
            options={services}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderServiceKaishi() {
    const { bindingProps } = this.props;
    return <ServiceKaishi bindingProps={bindingProps} />;
  }

  renderYoyaku() {
    const { bindingProps } = this.props;
    return <ServiceYoyaku bindingProps={bindingProps} />;
  }

  renderGyoseiJoseiHojo() {
    const { bindingProps } = this.props;
    return <ServiceGyoseiJoseiHojo bindingProps={bindingProps} />;
  }
}

FuneralForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(FuneralForm);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}