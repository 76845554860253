import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as actions from "../../../../state/hojin-list/actions";
import BackOfficeHead from "../../../../app/ui/structure/BackOfficeHead";
import SideMargin from "../../../../app/ui/layout/SideMargin";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import AddIcon from "@material-ui/icons/Add";
import { Paper, TableBody, TableHead, TableRow } from "@material-ui/core";
import { css } from "aphrodite";
import styles from "./HojinListPage.css";
import SmallPaddingTableCell from "../../../../app/ui/table/SmallPaddingTableCell";
import Right from "../../../../app/ui/layout/Right";
import { IconButton } from "@material-ui/core";
import RightIcon from "@material-ui/icons/KeyboardArrowRight";
import Boundary from "../../../../app/ui/layout/Boundary";
import HojinListOperationDialog from "./HojinListOperationDialog";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import HojinListCondition from "./HojinListCondition";
import HojinListPaginator from "./HojinListPaginator";
import FixedTable from "../../../../app/ui/table/FixedTable";
import { withInputFeedback } from "../../../../app/ui/form";
import SearchComparator from "../../../../app/util/SearchComparator";
import CenteredCircularProgress from "../../../../app/ui/progress/CenteredCircularProgress";
import Mount from "../../../../app/ui/layout/Mount";

class HojinListPage extends React.Component {
  constructor(props) {
    super(props);
    this.resetSelection = this.resetSelection.bind(this);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.handleJigyoshoButtonClick = this.handleJigyoshoButtonClick.bind(this);
  }

  componentDidMount() {
    const { actions, location } = this.props;
    if (this.searchWasUpdated()) {
      actions.requestBySearch(new URLSearchParams(location.search).toString());
    }
    this.resetSelection();
  }

  searchWasUpdated() {
    const { hojinList, location } = this.props;
    return new SearchComparator(location.search, hojinList.search).different();
  }

  resetSelection() {
    const { actions } = this.props;
    actions.select(undefined);
  }

  handleAddButtonClick() {
    const { actions } = this.props;
    actions.gotoAddPage();
  }

  handleJigyoshoButtonClick(e, groupId) {
    e.stopPropagation();
    const { actions } = this.props;
    const searchParams = new URLSearchParams();
    searchParams.set("groupId", groupId);
    actions.gotoJigyoshoListPage(searchParams.toString());
  }

  listIsLoading() {
    return this.props.hojinList.listIsLoading;
  }

  render() {
    return (
      <Fragment>
        <Boundary>
          <SideMargin>
            {this.renderHead()}
            {this.renderNavigation()}
            {this.renderCondition()}
            {this.listIsLoading() ? (
              <CenteredCircularProgress />
            ) : (
              <Fragment>
                {this.renderTable()}
                {this.renderPaginator()}
              </Fragment>
            )}
          </SideMargin>
        </Boundary>
        {this.renderOperationDialog()}
      </Fragment>
    );
  }

  renderHead() {
    return <BackOfficeHead>法人一覧</BackOfficeHead>;
  }

  renderNavigation() {
    const { user } = this.props.authentication;
    return (
      <BackOfficeNavigation>
        <Mount if={user.canAddHojin()}>
          <FlatPrimaryButton onClick={this.handleAddButtonClick}>
            <AddIcon />
            法人を追加する
          </FlatPrimaryButton>
        </Mount>
      </BackOfficeNavigation>
    );
  }

  renderCondition() {
    const { hojinList } = this.props;
    return <HojinListCondition condition={hojinList.condition} />;
  }

  renderTable() {
    const { actions, hojinList } = this.props;
    const { list, selectedId } = hojinList;
    const hojinIsSelected = (hojin) => hojin.id === selectedId;
    return (
      <Paper>
        <FixedTable>
          <TableHead>
            <TableRow>
              <SmallPaddingTableCell>
                法人名
                <br />
                ログインID
              </SmallPaddingTableCell>
              <SmallPaddingTableCell
                numeric
                className={css(styles.jigyoshosuCell)}
              >
                事業所数
              </SmallPaddingTableCell>
              <SmallPaddingTableCell>担当者氏名</SmallPaddingTableCell>
              <SmallPaddingTableCell>事業内容</SmallPaddingTableCell>
              <SmallPaddingTableCell
                className={css(styles.shortcutCell)}
              ></SmallPaddingTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {list.map((hojin) => {
              const onRowClick = (e) => {
                e.stopPropagation();
                actions.select(hojin.id);
              };
              const rowClass = hojinIsSelected(hojin)
                ? css(styles.selectableRow, styles.selectedRow)
                : css(styles.selectableRow);
              const cellClass = hojinIsSelected(hojin)
                ? css(styles.selectedCell)
                : "";
              return (
                <TableRow
                  className={rowClass}
                  key={hojin.id}
                  onClick={onRowClick}
                >
                  <SmallPaddingTableCell className={cellClass}>
                    {hojin.hojimmei}
                    <br />
                    {hojin.username}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell className={cellClass} numeric>
                    {hojin.jigyoshosu}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell className={cellClass}>
                    {hojin.tantoshashimei}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell className={cellClass}>
                    {hojin.setsumei}
                  </SmallPaddingTableCell>
                  <SmallPaddingTableCell>
                    <Right>
                      <IconButton
                        onClick={(e) =>
                          this.handleJigyoshoButtonClick(e, hojin.groupId)
                        }
                      >
                        <RightIcon />
                      </IconButton>
                    </Right>
                  </SmallPaddingTableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </FixedTable>
      </Paper>
    );
  }

  renderPaginator() {
    const { hojinList } = this.props;
    const paginatorProps = {
      prevSearch: hojinList.prevSearch,
      nextSearch: hojinList.nextSearch,
      pagination: hojinList.pagination,
    };
    return <HojinListPaginator {...paginatorProps} />;
  }

  renderOperationDialog() {
    const { authentication, hojinList } = this.props;
    const search = hojinList.search;
    const target = hojinList.list.find(
      (hojin) => hojin.id === hojinList.selectedId
    );
    return (
      <HojinListOperationDialog
        onClose={this.resetSelection}
        search={search}
        target={target}
        user={authentication.user}
      />
    );
  }
}

HojinListPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  authentication: PropTypes.object.isRequired,
  hojinList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withInputFeedback(
  connect(mapStateToProps, mapDispatchToProps)(HojinListPage)
);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    hojinList: state.hojinList,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      ...bindActionCreators(actions, dispatch),
      gotoAddPage: () => dispatch(push(Pathname.backoffice_hojin_add)),
      gotoJigyoshoListPage: (search) =>
        dispatch(
          push({
            pathname: Pathname.backoffice_jigyosho,
            search,
          })
        ),
    },
  };
}
