import React, { Fragment } from "react";
import PropTypes from "prop-types";
import {
  MASTER_DATA_BUNDLE_FOR_KYOTAKUKAIGOSHIENJIGYOSHO,
  withMasterData,
} from "../../../../../state/master-data";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";

class KyotakukaigoshienjigyoshoRyokinkasanForm extends React.Component {
  render() {
    return <Fragment>{this.renderKaigohoshukasan()}</Fragment>;
  }

  renderKaigohoshukasan() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="介護報酬加算" recommended>
        <HorizontalLabelLayout>
          <OptionsCheckbox
            name="kaigohoshukasan"
            split={2}
            options={masterData.kyotakukaigoshienjigyosho_kaigohoshukasan}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

KyotakukaigoshienjigyoshoRyokinkasanForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_KYOTAKUKAIGOSHIENJIGYOSHO)(
  KyotakukaigoshienjigyoshoRyokinkasanForm
);
