import React, { Fragment } from "react";
import PropTypes from "prop-types";
import RegistrationDataEmpty from "../RegistrationDataEmpty";
import { goToJigyoshoAttributeFormButton } from "../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../app/domain/jigyosho/JigyoshoAttributeType";

class IsoKaigoTaxiDetail extends React.Component {
  render() {
    return (
      <div className="p-office-body">
        {this.menus().length || this.options().length ? (
          <Fragment>
            {this.renderPlans()}
            {this.renderOptions()}
          </Fragment>
        ) : (
          <RegistrationDataEmpty />
        )}
      </div>
    );
  }

  menus() {
    const { isoKaigoTaxiMenus } = this.props.contents.blocks;
    return isoKaigoTaxiMenus.menus;
  }

  options() {
    const { isoKaigoTaxiOptions } = this.props.contents.blocks;
    return isoKaigoTaxiOptions.options;
  }

  renderPlans() {
    const menus = this.menus();
    if (!menus.length) return null;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">プラン</div>
        </div>

        <div className="p-office-body-table-v">
          <table>
            <thead>
              <tr>
                <th>プラン</th>
                <th>課金方式</th>
                <th>初乗り単位</th>
                <th>プラン詳細</th>
                <th>料金（税込み）</th>
                <th>加算料金（税込み）</th>
              </tr>
            </thead>
            <tbody>
              {menus.map((menu, i) => {
                return (
                  <tr key={i}>
                    <td>{menu.plan}</td>
                    <td>{menu.kakinhoshiki}</td>
                    <td>{menu.hatsunoritani}</td>
                    <td>{menu.planShosai}</td>
                    <td>{menu.ryokin}</td>
                    <td>{menu.kasanryokin}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.taxiMenu
          )}
        </div>
      </Fragment>
    );
  }

  renderOptions() {
    const options = this.options();
    if (!options.length) return null;
    return (
      <Fragment>
        <div className="c-blue-heading">
          <div className="c-blue-heading__text">オプション</div>
        </div>

        <div className="p-office-body-table-v">
          <table>
            <thead>
              <tr>
                <th>オプション名</th>
                <th>料金区分</th>
                <th>単位</th>
                <th>詳細</th>
                <th>料金（税込み）</th>
              </tr>
            </thead>
            <tbody>
              {options.map((option, i) => {
                return (
                  <tr key={i}>
                    <td>{option.optionMei}</td>
                    <td>{option.ryokinKubun}</td>
                    <td>{option.tani}</td>
                    <td>{option.shosai}</td>
                    <td>{option.ryokin}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>

          {goToJigyoshoAttributeFormButton(
            this.props.contents,
            JigyoshoAttributeType.taxiOption
          )}
        </div>
      </Fragment>
    );
  }
}

IsoKaigoTaxiDetail.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default IsoKaigoTaxiDetail;
