import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { renderNinzuShikaku, renderStaffJokinHijokin } from "./StaffTable";
import StaffShokushuTable from "./StaffShokushuTable";

const StaffShikakuRehabilitation = (props) => {
  const { homonkeiServiceStaff: staff } = props.contents.blocks;

  return (
    <Fragment>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">
          <div className="p-office-body-title">リハビリ職員</div>
          <StaffShokushuTable shokushu={staff.rehabilitationshokuin} />
        </div>

        <div className="p-office-body-sep__right">
          <div className="p-office-body-title">資格保有者数</div>
          <div className="p-office-body-table">
            <table>
              <tbody>
                <tr>
                  <th>理学療法士</th>
                  <td>{renderStaffJokinHijokin(staff.rigakuryohoshi)}</td>
                </tr>
                <tr>
                  <th>作業療法士</th>
                  <td>{renderStaffJokinHijokin(staff.sagyoryohoshi)}</td>
                </tr>
                <tr>
                  <th>言語聴覚士</th>
                  <td>{renderStaffJokinHijokin(staff.gengochokakushi)}</td>
                </tr>
                <tr>
                  <th>専門・認定療法士</th>
                  <td>
                    {renderNinzuShikaku(
                      staff.semmonNinteiryohoshi,
                      staff.semmonNinteiryohoshiShikaku
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

StaffShikakuRehabilitation.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default StaffShikakuRehabilitation;
