import React from "react";
import PropTypes from "prop-types";
import PrintHeader from "./PrintHeader";
import PrintFooter from "./PrintFooter";

const Sheet = ({ children, afterFooter }) => {
  return (
    <div className="sheet">
      <PrintHeader />

      <div className="print-body">{children}</div>

      <PrintFooter />

      {afterFooter}
    </div>
  );
};

Sheet.propTypes = {
  children: PropTypes.any,
  afterFooter: PropTypes.node,
};

export default Sheet;
