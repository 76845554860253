import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import InputTypeTextWithClearButton from "../../../../app/ui/form/InputTypeTextWithClearButton";
import { enterKeyHandler } from "../../../../app/ui/form";
import { push } from "react-router-redux";
import * as actions from "../../../../state/jigyosho-list/actions";
import GridRow from "../../../../app/ui/grid/GridRow";
import GridCell from "../../../../app/ui/grid/GridCell";
import StaticLabel from "../../../../app/ui/form/StaticLabel";
import SpaceOut from "../../../../app/ui/layout/SpaceOut";
import StandardSelect from "../../../../app/ui/form/StandardSelect";
import { conditionConverter } from "../../../../state/jigyosho-list/query-string";
import { packInHokengaiService } from "../add/JigyoshoAddPage";
import RaisedPrimaryButton from "../../../../app/ui/button/RaisedPrimaryButton";

export const JigyoshoListConditionType = {
  管理対象: {
    keyword: {
      labelOf: (user) =>
        (user.hasBackOfficeHojinMenu() ? "法人名・" : "") +
        "事業所名・管理者名",
    },
  },
  代行入力: {
    keyword: {
      labelOf: (user) =>
        (!user.canCollectJigyosho() ? "代行入力・" : "") + "事業所名・管理者名",
    },
  },
};

class JigyoshoListCondition extends React.Component {
  constructor(props) {
    super(props);
    this.handleEnterKeyDown = this.handleEnterKeyDown.bind(this);
    this.handleShozaichiClearButtonClick = this.handleShozaichiClearButtonClick.bind(
      this
    );
    this.handleKeywordClearButtonClick = this.handleKeywordClearButtonClick.bind(
      this
    );
  }

  handleEnterKeyDown() {
    this.props.onConditionChangeDone(this.props.condition);
  }

  handleShozaichiClearButtonClick() {
    const callback = () =>
      this.props.onConditionChangeDone(this.props.condition);
    this.props.onConditionChange("shozaichi", "", callback);
  }

  handleKeywordClearButtonClick() {
    const callback = () =>
      this.props.onConditionChangeDone(this.props.condition);
    this.props.onConditionChange("keyword", "", callback);
  }

  componentType() {
    const { componentType = JigyoshoListConditionType.管理対象 } = this.props;
    return componentType;
  }

  render() {
    const bindingProps = this.createBindingProps();
    const serviceShubetsuSelectBindingProps = this.createServiceShubetsuSelectBindingProps(
      bindingProps
    );
    return (
      <div>
        <GridRow>
          <GridCell>
            <SpaceOut block>
              <InputTypeTextWithClearButton
                labelText="所在地"
                name="shozaichi"
                onKeyDown={enterKeyHandler(this.handleEnterKeyDown)}
                onClearButtonClick={this.handleShozaichiClearButtonClick}
                {...bindingProps}
              />
            </SpaceOut>
          </GridCell>
          <GridCell>
            <SpaceOut block>
              <StandardSelect
                labelText="サービス種別"
                name="serviceShubetsuCode"
                {...serviceShubetsuSelectBindingProps}
              />
            </SpaceOut>
          </GridCell>
          <GridCell>
            <SpaceOut block>
              <InputTypeTextWithClearButton
                labelText={this.componentType().keyword.labelOf(
                  this.props.user
                )}
                name="keyword"
                onKeyDown={enterKeyHandler(this.handleEnterKeyDown)}
                onClearButtonClick={this.handleKeywordClearButtonClick}
                {...bindingProps}
              />
            </SpaceOut>
          </GridCell>
          <GridCell className="search-area">
            <RaisedPrimaryButton onClick={this.handleEnterKeyDown}>検索</RaisedPrimaryButton>
          </GridCell>
        </GridRow>
      </div>
    );
  }

  createBindingProps() {
    return {
      form: this.props.condition,
      onChange: this.props.onConditionChange,
    };
  }

  createServiceShubetsuSelectBindingProps(bindingProps) {
    const { serviceShubetsuOptions: options } = this.props;
    return {
      ...bindingProps,
      blank: true,
      categorize: true,
      options: options.map(packInHokengaiService),
      onChange: (name, value) => {
        const callback = () =>
          this.props.onConditionChangeDone(this.props.condition);
        bindingProps.onChange(name, value, callback);
      },
    };
  }
}

JigyoshoListCondition.propTypes = {
  condition: PropTypes.object.isRequired,
  componentType: PropTypes.object,
  serviceShubetsuOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  user: PropTypes.object.isRequired,
  // on-event handlers
  onConditionChange: PropTypes.func.isRequired,
  onConditionChangeDone: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(JigyoshoListCondition);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    onConditionChange: (name, value, callback) => {
      dispatch(actions.changeCondition(name, value, callback));
    },
    onConditionChangeDone: (condition) => {
      const search = conditionConverter(condition).toSearch();
      dispatch(push({ search }));
    },
  };
}
