import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import TruncateText from "../../../../../app/ui/text/TruncateText";

const MenuBlock = ({ mainItem }) => {
  const LABEL_LIMIT = 80;
  return (
    <Fragment>
      <HeaderTextBlue text="メニュー・活動内容" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>
                <div>メニュー・活動名</div>
              </th>
              <td>{mainItem.menuContent.title}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height40 tbody-td_textAlign_left">
              <th>内容詳細</th>
              <td>
                <TruncateText maxLength={LABEL_LIMIT}>
                  {mainItem.menuContent.details}
                </TruncateText>
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>活動内容種類</th>
              <td>
                <TruncateText maxLength={LABEL_LIMIT}>
                  {mainItem.katsudonaiyoshuruiList
                    ? mainItem.katsudonaiyoshuruiList.join()
                    : null}
                </TruncateText>
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height40 tbody-td_textAlign_left">
              <th>会場住所</th>
              <td>
                <TruncateText maxLength={LABEL_LIMIT}>
                  {mainItem.kaijo.kaijojusho}
                </TruncateText>
              </td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height40 tbody-td_textAlign_left">
              <th>会場までの行き方</th>
              <td>
                <TruncateText maxLength={LABEL_LIMIT}>
                  {mainItem.kaijo.ikikata}
                </TruncateText>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

MenuBlock.propTypes = {
  mainItem: PropTypes.object.isRequired,
};

export default MenuBlock;
