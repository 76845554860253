import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import {
  MASTER_DATA_BUNDLE_FOR_HOKENGAI,
  withMasterData,
} from "../../../../../state/master-data";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import FlatPrimaryButton from "../../../../../app/ui/button/FlatPrimaryButton";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import GridRow from "../../../../../app/ui/grid/GridRow";
import GridCell from "../../../../../app/ui/grid/GridCell";
import { enterKeyHandler } from "../../../../../app/ui/form";
import DummyLabel from "../../../../../app/ui/form/DummyLabel";
import { IconButton } from "@material-ui/core";
import FindIcon from "@material-ui/icons/FindReplace";
import { zipcodeToAddress } from "../../../../../state/zipcode-search/actions";
import Textarea from "../../../../../app/ui/form/Textarea";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import TimePeriod from "../../../../../app/ui/form/TimePeriod";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import FormGroupingDeleteButton from "../../../../../app/ui/button/FormGroupingDeleteButton";
import Mount from "../../../../../app/ui/layout/Mount";
import Right from "../../../../../app/ui/layout/Right";

const itemsName = "itemForms";

class BusinessProvideOtherMenuForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleItemAddClick = this.handleItemAddClick.bind(this);
    this.handleItemRemoveClick = this.handleItemRemoveClick.bind(this);
    this.handleEnterKeyDownOnYubimbango = this.handleEnterKeyDownOnYubimbango.bind(
      this
    );
  }

  getItems() {
    const { bindingProps } = this.props;
    return bindingProps.form.getByName(itemsName);
  }

  appendItem(item) {
    const { bindingProps } = this.props;
    const org = this.getItems();
    bindingProps.onChange(itemsName, org.concat([item]));
  }

  removeItem(index) {
    const { bindingProps } = this.props;
    const org = this.getItems();
    bindingProps.onChange(
      itemsName,
      org.filter((_, i) => i !== index)
    );
  }

  handleItemAddClick() {
    this.appendItem({
      serviceName: "",
      menuComment: "",
      serviceClassification: "",
      serviceType: {},
      zipCode: "",
      prefName: "",
      cityName: "",
      address: "",
      building: "",
      placeComment: "",
      access: "",
      apply: "",
      tantoshaName: "",
      tantoshaNameHikokai: false,
      tel: "",
      telToiawasesaki: "",
      fax: "",
      daihyoshaBangoFlg: false,
      telFaxHikokai: false,
      email: "",
      emailHikokai: false,
      businessAt: {
        start: "",
        end: "",
        complete: false,
      },
      allYearFlg: false,
      allDayFlg: false,
      notSureBusinessHourFlg: false,
      eigyobi: {
        getsuyo: false,
        kayo: false,
        suiyo: false,
        mokuyo: false,
        kinyo: false,
        doyo: false,
        nichiyo: false,
        shukujitsu: false,
        notSure: false,
      },
      irregularEigyoFlg: false,
      closeBusinessComment: "",
      participant: "",
      costFree: false,
      costEachTimeFee: "",
      costInitialFee: "",
      costAnnualFee: "",
      costMonthlyFee: "",
      costCancel: "",
      costTraffic: "",
      costComment: "",
      from65ToUnder75Male: "",
      from65ToUnder75Female: "",
      over75Male: "",
      over75Female: "",
      heldFrequency: "",
      careLevel: "",
    });
  }

  handleItemRemoveClick(index) {
    this.removeItem(index);
  }

  handleEnterKeyDownOnYubimbango(index) {
    const { actions, bindingProps } = this.props;
    const { form, onChange: changeForm } = bindingProps;
    const zipcode = form.itemForms[index].zipCode;

    actions.zipcodeToAddress(zipcode, (address) => {
      changeForm(`${itemsName}.${index}.prefName`, address.pref);
      changeForm(`${itemsName}.${index}.cityName`, address.address);
    });
  }

  render() {
    return (
      <Fragment>
        {this.getItems().map((item, i) => {
          return this.renderBusinessProvideOtherMenu(item, i);
        })}
        {this.renderItemAdd()}
      </Fragment>
    );
  }

  renderBusinessProvideOtherMenu(item, i) {
    const namePrefix = `${itemsName}.${i}`;
    return (
      <Fragment>
        <FormGrouping
          key={i}
          title={
            <Fragment>
              メニュー{i + 1}
              <HorizontalLabelLayout>
                <FormGroupingDeleteButton
                  onClick={() => this.handleItemRemoveClick(i)}
                />
              </HorizontalLabelLayout>
            </Fragment>
          }
        >
          {this.renderMenu(namePrefix, i)}
          {this.renderPlace(namePrefix, i)}
          {this.renderToiawase(namePrefix, i)}
          {this.renderRenrakusaki(namePrefix, i)}
          {this.renderEigyojikan(namePrefix, i)}
          {this.renderRyokin(namePrefix, i)}
          {this.renderPeople(namePrefix, i)}
        </FormGrouping>
      </Fragment>
    );
  }

  renderItemAdd() {
    const { bindingProps } = this.props;
    return (
      <FlatPrimaryButton onClick={this.handleItemAddClick} {...bindingProps}>
        項目を追加する
      </FlatPrimaryButton>
    );
  }

  renderMenu(namePrefix, i) {
    const { bindingProps, masterData } = this.props;
    const serviceClassification =
      bindingProps.form.itemForms[i].serviceClassification;
    return (
      <FormGrouping title="メニュー・活動内容">
        <HorizontalLabelLayout labelText="メニュー・活動名" required>
          <InputTypeText name={`${namePrefix}.serviceName`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="メニュー・活動内容詳細" recommended>
          <Textarea name={`${namePrefix}.menuComment`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="活動内容分類" required>
          <OptionsRadio
            name={`${namePrefix}.serviceClassification`}
            split={3}
            options={masterData.businessProvideCategoryCode}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <Mount
          if={
            masterData.businessProvideCode.filter(
              (value) => value.category.code === serviceClassification
            ).length > 0
          }
        >
          <HorizontalLabelLayout labelText="活動内容種類" required>
            <OptionsCheckbox
              name={`${namePrefix}.serviceType`}
              split={3}
              options={masterData.businessProvideCode.filter(
                (value) => value.category.code === serviceClassification
              )}
              {...bindingProps}
            />
          </HorizontalLabelLayout>
        </Mount>
      </FormGrouping>
    );
  }

  renderPlace(namePrefix, i) {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="活動場所">
        <HorizontalLabelLayout labelText="〒 郵便番号" recommended>
          <GridRow>
            <GridCell>
              <InputTypeText
                name={`${namePrefix}.zipCode`}
                hintText="000-0000"
                {...bindingProps}
                onKeyDown={enterKeyHandler(() =>
                  this.handleEnterKeyDownOnYubimbango(i)
                )}
              />
            </GridCell>
            <GridCell grow={2}>
              <IconButton
                onClick={() => this.handleEnterKeyDownOnYubimbango(i)}
              >
                <FindIcon />
              </IconButton>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="都道府県" recommended>
          <DummyLabel name={`${namePrefix}.prefName`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="市区町村" recommended>
          <DummyLabel name={`${namePrefix}.cityName`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="番地" recommended>
          <ShortInputWidth>
            <InputTypeText
              name={`${namePrefix}.address`}
              hintText="0番地"
              {...bindingProps}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="建物以下">
          <InputTypeText name={`${namePrefix}.building`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="会場備考" recommended>
          <Textarea
            name={`${namePrefix}.placeComment`}
            hintText="例）○○会館2階、開催場所は毎回異なります"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="会場までの行き方" recommended>
          <Textarea
            name={`${namePrefix}.access`}
            hintText="例）○○駅から徒歩5分"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderToiawase(namePrefix) {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="問い合わせについて">
        <HorizontalLabelLayout labelText="問い合わせ方法" required>
          <OptionsRadio
            name={`${namePrefix}.apply`}
            split={3}
            options={masterData.applyCode}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="担当者" recommended>
          <InputTypeText
            name={`${namePrefix}.tantoshaName`}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="非公開設定">
          <InputTypeCheckbox
            name={`${namePrefix}.tantoshaNameHikokai`}
            labelText="担当者を公開しない"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderRenrakusaki(namePrefix, i) {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="連絡先">
        <HorizontalLabelLayout labelText="代表者番号と同じ">
          <InputTypeCheckbox
            name={`${namePrefix}.daihyoshaBangoFlg`}
            labelText="はい"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="☎ 電話番号" required>
          <GridRow>
            <GridCell>
              <InputTypeText
                name={`${namePrefix}.tel`}
                hintText="00-0000-0000"
                {...bindingProps}
                disabled={bindingProps.form.itemForms[i].daihyoshaBangoFlg}
              />
            </GridCell>
            <GridCell grow={2}>
              <div>
                <RatioBlock divide={12}>
                  <Right>（ </Right>
                </RatioBlock>
                <RatioBlock grow={7} divide={12}>
                  <InputTypeText
                    name={`${namePrefix}.telToiawasesaki`}
                    hintText="例）○○地域包括支援センター"
                    {...bindingProps}
                  />
                </RatioBlock>
                <RatioBlock divide={12}>）</RatioBlock>
              </div>
            </GridCell>
          </GridRow>
          <StaticLabel>
            ※
            お問合せ先が店舗・事業所・団体と異なる場合お問合せ先をご記入ください
            <br />
          </StaticLabel>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="FAX" recommended>
          <ShortInputWidth>
            <InputTypeText
              name={`${namePrefix}.fax`}
              hintText="00-0000-0000"
              {...bindingProps}
              disabled={bindingProps.form.itemForms[i].daihyoshaBangoFlg}
            />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="非公開設定">
          <InputTypeCheckbox
            name={`${namePrefix}.telFaxHikokai`}
            labelText="電話番号・FAXを公開しない"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="✉ E-mail" recommended>
          <InputTypeText
            name={`${namePrefix}.email`}
            hintText="xxxx@xxxx.xxxx"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="非公開設定">
          <InputTypeCheckbox
            name={`${namePrefix}.emailHikokai`}
            labelText="E-mailを公開しない"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderEigyojikan(namePrefix, i) {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="営業時間">
        <HorizontalLabelLayout labelText="営業・開催時間" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name={`${namePrefix}.businessAt`}
                separator="."
                {...bindingProps}
                disabled={
                  bindingProps.form.itemForms[i].allDayFlg ||
                  bindingProps.form.itemForms[i].notSureBusinessHourFlg
                }
              />
            </GridCell>
            <GridCell grow={2}>
              <div>
                <RatioBlock divide={12}>
                  <Right></Right>
                </RatioBlock>
                <RatioBlock grow={7} divide={12}>
                  <InputTypeCheckbox
                    name={`${namePrefix}.notSureBusinessHourFlg`}
                    labelText="把握していない"
                    disabled={bindingProps.form.itemForms[i].allDayFlg}
                    {...bindingProps}
                  />
                </RatioBlock>
              </div>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="24時間対応">
          <InputTypeCheckbox
            name={`${namePrefix}.allDayFlg`}
            labelText="はい"
            disabled={bindingProps.form.itemForms[i].notSureBusinessHourFlg}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="営業・開催日" required>
          <OptionsCheckbox
            name={`${namePrefix}.eigyobi`}
            split={6}
            options={masterData.otherMenuItemEigyobi}
            disabled={
              bindingProps.form.itemForms[i].irregularEigyoFlg ||
              bindingProps.form.itemForms[i].allYearFlg
            }
            {...bindingProps}
          />
          <StaticLabel>
            ※ 隔週の場合も曜日にチェックを入れください
            <br />
          </StaticLabel>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="不定期開催">
          <InputTypeCheckbox
            name={`${namePrefix}.irregularEigyoFlg`}
            labelText="はい"
            disabled={bindingProps.form.itemForms[i].allYearFlg}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="年中無休">
          <InputTypeCheckbox
            name={`${namePrefix}.allYearFlg`}
            labelText="はい"
            disabled={bindingProps.form.itemForms[i].irregularEigyoFlg}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="営業・開催備考" recommended>
          <InputTypeText
            name={`${namePrefix}.closeBusinessComment`}
            hintText="例）8月・12月の開催はありません"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="開催頻度" required>
          <OptionsRadio
            name={`${namePrefix}.heldFrequency`}
            split={3}
            options={masterData.heldFrequencyCode}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderRyokin(namePrefix, i) {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="料金について">
        <HorizontalLabelLayout labelText="無料" recommended>
          <InputTypeCheckbox
            name={`${namePrefix}.costFree`}
            labelText="はい"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout
          labelText="１回あたりの費用（税込み）"
          recommended
        >
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name={`${namePrefix}.costEachTimeFee`}
                {...bindingProps}
                disabled={bindingProps.form.itemForms[i].costFree}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="初期費用（税込み）" recommended>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name={`${namePrefix}.costInitialFee`}
                {...bindingProps}
                disabled={bindingProps.form.itemForms[i].costFree}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="年会費（税込み）" recommended>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name={`${namePrefix}.costAnnualFee`}
                {...bindingProps}
                disabled={bindingProps.form.itemForms[i].costFree}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="月会費（税込み）" recommended>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name={`${namePrefix}.costMonthlyFee`}
                {...bindingProps}
                disabled={bindingProps.form.itemForms[i].costFree}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout
          labelText="キャンセル時の扱いについて"
          recommended
        >
          <InputTypeText name={`${namePrefix}.costCancel`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="交通費について" recommended>
          <Textarea name={`${namePrefix}.costTraffic`} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考" recommended>
          <Textarea name={`${namePrefix}.costComment`} {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderPeople(namePrefix) {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="参加者">
        <HorizontalLabelLayout labelText="1回あたりの参加者人数" recommended>
          <RatioBlock divide={5}>
            <UnitLayout unitText="人">
              <InputTypeNumber
                name={`${namePrefix}.participant`}
                hintText="0"
                {...bindingProps}
              />
            </UnitLayout>
          </RatioBlock>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="年齢別" recommended>
          <GridRow>
            <RatioBlock divide={1}>
              <SpaceOut block>
                <StaticLabel></StaticLabel>
              </SpaceOut>
            </RatioBlock>
          </GridRow>
          <GridRow>
            <RatioBlock divide={3}>
              <SpaceOut block>
                <StaticLabel style={{ fontWeight: "bold" }}>
                  65歳以上75歳未満
                </StaticLabel>
              </SpaceOut>
            </RatioBlock>
          </GridRow>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>男性</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name={`${namePrefix}.from65ToUnder75Male`}
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>女性</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name={`${namePrefix}.from65ToUnder75Female`}
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <GridRow>
            <RatioBlock divide={3}>
              <SpaceOut block>
                <StaticLabel style={{ fontWeight: "bold" }}>
                  75歳以上
                </StaticLabel>
              </SpaceOut>
            </RatioBlock>
          </GridRow>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>男性</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name={`${namePrefix}.over75Male`}
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>女性</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name={`${namePrefix}.over75Female`}
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="最も介護度の高い参加者" required>
          <OptionsRadio
            name={`${namePrefix}.careLevel`}
            split={4}
            options={masterData.careLevelCode}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

BusinessProvideOtherMenuForm.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOKENGAI)(
  connect(mapStateToProps, mapDispatchToProps)(BusinessProvideOtherMenuForm)
);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      zipcodeToAddress: (zipcode, callback) =>
        dispatch(zipcodeToAddress(zipcode, callback)),
    },
  };
}
