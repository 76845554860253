import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import {
  MASTER_DATA_BUNDLE_FOR_HOKENGAI,
  withMasterData,
} from "../../../../../state/master-data";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import ServiceYoyaku from "./ServiceYoyaku";
import ServiceGyoseiJoseiHojo from "./ServiceGyoseiJoseiHojo";
import ServiceKaishi from "./ServiceKaishi";

class DeliveryForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderService()}
        {this.renderServiceKaishi()}
        {this.renderYoyaku()}
        {this.renderGyoseiJoseiHojo()}
        {this.renderRyokin()}
      </Fragment>
    );
  }

  renderService() {
    const { bindingProps } = this.props;
    const services = [
      { code: "breakfastFlg", label: "朝食", orderNo: 1 },
      { code: "lunchFlg", label: "昼食", orderNo: 2 },
      { code: "dinnerFlg", label: "夕食", orderNo: 3 },
    ];
    const dishes = [
      { code: "dishRepeatFlg", label: "繰り返し使える", orderNo: 1 },
      { code: "dishOnlyFlg", label: "使い捨て容器", orderNo: 2 },
    ];
    const options = [
      { code: "optionGruelFlg", label: "固さ調整要望対応", orderNo: 1 },
      { code: "optionAllergyFlg", label: "アレルギー対応", orderNo: 2 },
      {
        code: "optionFamilyFlg",
        label: "介護家族のお弁当も一緒に宅配",
        orderNo: 3,
      },
      { code: "optionVisitFlg", label: "安否確認サービス", orderNo: 4 },
      { code: "optionSampleFlg", label: "無料試食", orderNo: 5 },
    ];
    return (
      <FormGrouping title="サービスについて">
        <HorizontalLabelLayout labelText="配達が出来る食事" required>
          <OptionsCheckbox
            name="teikyoService"
            options={services}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="容器の種類" required>
          <OptionsCheckbox name="yoki" options={dishes} {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="対応してくれるサービス" required>
          <OptionsCheckbox
            name="taioService"
            options={options}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="serviceBiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderServiceKaishi() {
    const { bindingProps } = this.props;
    const deliveries = [
      { code: "deliveryHandoverFlg", label: "常に手渡し", orderNo: 1 },
      {
        code: "deliveryCustodyFlg",
        label: "不在時留置(保冷ボックス貸与など)",
        orderNo: 2,
      },
      { code: "deliveryScheduleFlg", label: "宅配時間指定可", orderNo: 3 },
      { code: "orderOneFlg", label: "1食から注文可", orderNo: 4 },
      { code: "orderTomorrowFlg", label: "翌日対応可", orderNo: 5 },
    ];
    return (
      <ServiceKaishi bindingProps={bindingProps}>
        <HorizontalLabelLayout
          labelText="商品の配送・受け取りについて"
          recommended
        >
          <OptionsCheckbox
            name="haisoUketori"
            options={deliveries}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </ServiceKaishi>
    );
  }

  renderYoyaku() {
    const { bindingProps } = this.props;
    return <ServiceYoyaku bindingProps={bindingProps} />;
  }

  renderGyoseiJoseiHojo() {
    const { bindingProps } = this.props;
    return <ServiceGyoseiJoseiHojo bindingProps={bindingProps} />;
  }

  renderRyokin() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="料金について">
        <HorizontalLabelLayout
          labelText="キャンセル時の扱いについて"
          recommended
        >
          <InputTypeText name="costCancel" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="注文に関する条件">
          <InputTypeText name="orderUnitAbout" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="送料（税込み）" recommended>
          <ShortInputWidth>
            <UnitLayout unitText="円">
              <InputTypeNumber
                name="deliveryFee"
                hintText="0"
                {...bindingProps}
              />
            </UnitLayout>
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="送料無料フラグ ">
          <InputTypeCheckbox
            name="deliveryFreeFlg"
            labelText="はい"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="送料について備考" recommended>
          <InputTypeText name="deliveryComment" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="備考">
          <Textarea name="costComment" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

DeliveryForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOKENGAI)(DeliveryForm);
