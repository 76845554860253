import React, { Fragment } from "react";
import PropTypes from "prop-types";

const For = (props) => {
  if (props.in && props.each)
    return (
      <Fragment>{props.in.map((it, index) => props.each(it, index))}</Fragment>
    );
  if (props.in)
    return <Fragment>{props.in.map(() => props.children)}</Fragment>;
  return null;
};

For.propTypes = {
  children: PropTypes.node,
  each: PropTypes.func,
  in: PropTypes.array,
};

export default For;
