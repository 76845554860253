import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
} from "../../app/fundamental/request";

export function requestData(name) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REQUEST,
    });
    getData(dispatch, name);
  };
}

function getData(dispatch, name) {
  const url = ApiPath.api_masterdata_name.replace(/:name/, name);
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        data: json,
        name,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}
