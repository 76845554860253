import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import GridRow from "../grid/GridRow";
import GridCell from "../grid/GridCell";
import ColorCatalog from "../color/ColorCatalog";
import InputTypeCheckbox from "./InputTypeCheckbox";
import SplitArrayTable from "../../util/SplitArrayTable";
import CategorizedOptions from "../../util/CategorizedMasterData";

class OptionsRadio extends React.Component {
  formControlProps() {
    const helperText = this.helperText();
    return {
      error: helperText ? true : false,
      margin: "normal",
      style: styles.formControl,
    };
  }

  helperText() {
    const { name } = this.props;
    return this.props.inputFeedback.errors.getByName(name);
  }

  options() {
    const { options } = this.props;
    return this.optionsOf(options);
  }

  categorizedOptions() {
    const { options } = this.props;
    return new CategorizedOptions(options).getCategories().map((category) => ({
      label: category.label,
      options: this.optionsOf(category.options),
    }));
  }

  optionsOf(options) {
    const { disabled, form, name, onChange: change, onKeyDown } = this.props;
    return options.map((option) => {
      const nameOfOption = `${name}.${option.code}`;
      const formControlLabelProps = {
        disabled,
        label: option.label,
        margin: "normal",
        name: nameOfOption,
        onKeyDown,
        style: styles.formControlLabel,
      };
      const checkboxProps = {
        checked: InputTypeCheckbox.checked(
          form.getByName(nameOfOption) ? true : false
        ),
        color: "primary",
        onChange: (e, checked) => change(e.target.name, checked),
      };
      return (
        <FormControlLabel
          key={`${name}-${option.code}`}
          control={<Checkbox {...checkboxProps} />}
          {...formControlLabelProps}
        />
      );
    });
  }

  split(options) {
    const { split = 3 } = this.props;
    return new SplitArrayTable(split).make(options).fill().getTable();
  }

  render() {
    const { categorize, labelText } = this.props;
    const helperText = this.helperText();
    return (
      <FormControl {...this.formControlProps()}>
        {labelText ? <FormLabel>{labelText}</FormLabel> : null}
        {categorize ? this.renderCategorizedOptions() : this.renderOptions()}
        {helperText ? <FormHelperText>{helperText}</FormHelperText> : null}
      </FormControl>
    );
  }

  renderOptions() {
    return this.renderOptionsOf(this.options());
  }

  renderCategorizedOptions() {
    return this.categorizedOptions().map((category, categoryIndex) => (
      <Fragment key={categoryIndex}>
        <div style={styles.category}>▼ {category.label}</div>
        {this.renderOptionsOf(category.options)}
      </Fragment>
    ));
  }

  renderOptionsOf(options) {
    return this.split(options).map((options, optionsIndex) => (
      <GridRow key={optionsIndex}>
        {options.map((option, optionIndex) => (
          <GridCell key={optionIndex}>{option}</GridCell>
        ))}
      </GridRow>
    ));
  }
}

const styles = {
  formControl: {
    width: "100%",
  },
  formControlLabel: {
    width: "100%",
    fontSize: "small",
    marginTop: -8,
  },
  category: {
    color: ColorCatalog.gray,
    fontSize: "small",
    marginBottom: 6,
    marginTop: 6,
  },
};

OptionsRadio.propTypes = {
  categorize: PropTypes.bool,
  disabled: PropTypes.bool,
  form: PropTypes.object.isRequired,
  inputFeedback: PropTypes.object.isRequired,
  labelText: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  split: PropTypes.number,
  onKeyDown: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(OptionsRadio);

function mapStateToProps(state) {
  return {
    inputFeedback: state.inputFeedback,
  };
}

function mapDispatchToProps() {
  return {};
}
