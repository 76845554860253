import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";
import Parametername from "../../app/naming/Parametername";

export function request(id) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REQUEST,
    });
    getFliers(dispatch, id);
  };
}

export function uploadFiles(files, callback) {
  return (dispatch) => {
    const form = new FormData();
    files.forEach((file) => form.append(Parametername.files, file));
    postToUploadFiles(dispatch, form, callback);
  };
}

export function changeSort(id, index, callback = () => {}) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_SORT,
      payload: {
        id,
        index,
      },
    });
    setTimeout(callback);
  };
}

export function submitToEditFlierCollective(id, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToEditFlierCollective(dispatch, id, form, callback);
  };
}

export function removeFlier(serviceId, fileId) {
  return (dispatch) => {
    dispatch({
      type: ActionType.REMOVE,
      payload: {
        fileId,
      },
    });
  };
}

function getFliers(dispatch, id) {
  const url = ApiPath.api_jigyosho_id_fliers_collective.replace(/:id/, id);
  get(dispatch, url);
}

function get(dispatch, url) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        list: json,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function postToUploadFiles(dispatch, form, callback) {
  const url = ApiPath.api_io_files;
  postFile(dispatch, url, form, callback, "チラシをアップロードしました。");
}

function postFile(dispatch, url, form, callback = () => {}, message) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.UPLOAD,
      payload: {
        file: json,
      },
    });
    setTimeout(() => callback(json));
  };
  const onFailure = () => {};
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}

function postToEditFlierCollective(dispatch, id, form, callback) {
  const url = ApiPath.api_jigyosho_id_fliers_collective.replace(/:id/, id);
  post(dispatch, url, form, callback, null, "チラシを更新しました。");
}

function post(
  dispatch,
  url,
  form,
  callbackOnSuccess,
  callbackOnFailure,
  message
) {
  const noop = () => {};
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(callbackOnSuccess || noop);
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
    setTimeout(callbackOnFailure || noop);
  };
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}
