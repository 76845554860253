import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import OptionsCheckbox from "../../../../../app/ui/form/OptionsCheckbox";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import ServiceKaishi from "./ServiceKaishi";
import ServiceYoyaku from "./ServiceYoyaku";
import ServiceGyoseiJoseiHojo from "./ServiceGyoseiJoseiHojo";

class BarberForm extends React.Component {
  render() {
    return (
      <Fragment>
        {this.renderTeikyoService()}
        {this.renderOption()}
        {this.renderTaioJokyo()}
        {this.renderSetsubi()}
        {this.renderServiceKaishi()}
        {this.renderYoyaku()}
        {this.renderGyoseiJoseiHojo()}
      </Fragment>
    );
  }

  renderTeikyoService() {
    const { bindingProps } = this.props;
    const services = [
      { code: "cutFlg", label: "カット" },
      { code: "permFlg", label: "パーマ" },
      { code: "colorFlg", label: "カラーリング" },
      { code: "treatmentFlg", label: "トリートメント" },
      { code: "hairSetFlg", label: "ヘアセット" },
      { code: "shampooFlg", label: "シャンプー" },
      { code: "headSpaFlg", label: "ヘッドスパ" },
      { code: "facePackFlg", label: "フェイスパック" },
      { code: "shaveFlg", label: "顔そり" },
      { code: "eyebrowFlg", label: "眉毛カット" },
    ];
    return (
      <FormGrouping title="提供サービス" required>
        <HorizontalLabelLayout>
          <OptionsCheckbox
            name="teikyoService"
            options={services}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderOption() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="オプション">
        <HorizontalLabelLayout>
          <InputTypeCheckbox
            name="designationFlg"
            labelText="スタッフの指名"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderTaioJokyo() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="対応状況" recommended>
        <HorizontalLabelLayout>
          <InputTypeCheckbox
            name="dementiaFlg"
            labelText="認知症対応"
            {...bindingProps}
          />
          <InputTypeCheckbox
            name="paralysisFlg"
            labelText="麻痺対応"
            {...bindingProps}
          />
          <InputTypeCheckbox
            name="lyingFlg"
            labelText="寝たきり対応"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderSetsubi() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="設備" recommended>
        <HorizontalLabelLayout>
          <InputTypeCheckbox
            name="frontUnitFlg"
            labelText="シャンプー台・フロント"
            {...bindingProps}
          />
          <InputTypeCheckbox
            name="backUnitFlg"
            labelText="シャンプー台・バック"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderServiceKaishi() {
    const { bindingProps } = this.props;
    return <ServiceKaishi bindingProps={bindingProps} />;
  }

  renderYoyaku() {
    const { bindingProps } = this.props;
    return <ServiceYoyaku bindingProps={bindingProps} />;
  }

  renderGyoseiJoseiHojo() {
    const { bindingProps } = this.props;
    return <ServiceGyoseiJoseiHojo bindingProps={bindingProps} />;
  }
}

BarberForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(BarberForm);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
