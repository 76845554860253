import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ThWithBg from "../general/ThWithBg";

const HomonDenwaBlock = ({ homondenwa }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="訪問・電話対応" />
      <div className="p-office-body-table">
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>訪問対応</div>
          <div>可能日</div>
        </div>
        <table>
          <thead>
            <tr>
              <ThWithBg text="平日" />
              <ThWithBg text="土曜日" />
              <ThWithBg text="日曜日" />
              <ThWithBg text="祝日" />
            </tr>
          </thead>
          <tbody>
            <tr style={{ height: "17pt" }}>
              <td>{homondenwa.heijitsuhomontaio}</td>
              <td>{homondenwa.doyohomontaio}</td>
              <td>{homondenwa.nichiyohomontaio}</td>
              <td>{homondenwa.shukujitsuhomontaio}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-table">
        <div
          className="p-office-body-title"
          style={{ width: "6em", textAlign: "center" }}
        >
          <div>緊急時の</div>
          <div>電話対応</div>
        </div>
        <table style={{ minHeight: "30pt" }}>
          <tbody>
            <tr>
              <td>{homondenwa.kinkyujirenrakutaio}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

HomonDenwaBlock.propTypes = {
  homondenwa: PropTypes.object.isRequired,
};

export default HomonDenwaBlock;
