import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceShubetsuCategoryType from "../../../../../app/domain/jigyosho/ServiceShubetsuCategoryType";

// 保険内 運営情報
const CommonUneiJohoBlock = ({ uneiJoho, serviceShubetsuCategoryCode }) => {
  const yakanjigyoshobango = uneiJoho.yakanjigyoshobango
    ? "（夜間）" + uneiJoho.yakanjigyoshobango
    : "";
  return (
    <Fragment>
      <div className="print-body__block-footer">
        <div className="uneijoho">運営情報</div>
        <ul>
          <li>
            <span style={{ fontWeight: "bold" }}>事業所番号</span> :
            {uneiJoho.jigyoshobango}
            {yakanjigyoshobango}
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>運営法人</span> :{" "}
            {uneiJoho.uneihojin}
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>事業開始日</span> :{" "}
            {uneiJoho.jigyokaishibi}
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>管理者名</span> :{" "}
            {uneiJoho.kanrishamei}
          </li>
          <li>
            <span style={{ fontWeight: "bold" }}>HPアドレス </span>:{" "}
            {uneiJoho.homepageAddress}
          </li>
          <Mount
            if={[ServiceShubetsuCategoryType.通所系サービス]
              .map((it) => it.code)
              .includes(serviceShubetsuCategoryCode)}
          >
            <li>
              <span style={{ fontWeight: "bold" }}>協力病院</span>:{" "}
              {uneiJoho.kyoryokurenkeiiryokikan}
            </li>
          </Mount>
        </ul>
      </div>
    </Fragment>
  );
};

CommonUneiJohoBlock.propTypes = {
  uneiJoho: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
};

export default CommonUneiJohoBlock;
