import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";

function reset() {
  return {
    type: ActionType.RESET,
  };
}

export function requestBlankForm(jigyoshoId) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getBlankForm(dispatch, jigyoshoId);
  };
}

export function requestEditForm(id) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getEditForm(dispatch, id);
  };
}

export function changeForm(name, value, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.CHANGE_FORM,
      payload: {
        name,
        value,
      },
    });
    setTimeout(callback);
  };
}

export function submitToAdd(jigyoshoId, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToAdd(dispatch, jigyoshoId, form, callback);
  };
}

export function submitToEdit(id, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToEdit(dispatch, id, form, callback);
  };
}

function getBlankForm(dispatch, jigyoshoId) {
  const url = ApiPath.api_job_offer_jigyosho_id_add.replace(
    /:jigyoshoId/,
    jigyoshoId
  );
  getForm(dispatch, url, "blank");
}

function getEditForm(dispatch, id) {
  const url = ApiPath.api_job_offer_id_edit.replace(/:id/, id);
  getForm(dispatch, url, "edit");
}

function getForm(dispatch, url, type) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        form: json,
        type,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function postToAdd(dispatch, jigyoshoId, form, callback) {
  const url = ApiPath.api_job_offer_jigyosho_id_add.replace(
    /:jigyoshoId/,
    jigyoshoId
  );
  post(dispatch, url, form, callback, "求人情報を新規に追加しました。");
}

function postToEdit(dispatch, id, form, callback) {
  const url = ApiPath.api_job_offer_id_edit.replace(/:id/, id);
  post(dispatch, url, form, callback, "求人情報を編集しました。");
}

function post(dispatch, url, form, callback = () => {}, message) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(() => callback(json));
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}
