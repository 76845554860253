import React from "react";
import { TableCell } from "@material-ui/core";
import { mergeStyleToProps } from "../../fundamental/react/props";

const SmallPaddingTableCell = (props) => {
  return <TableCell {...mergeStyleToProps(styles.cell, props)}></TableCell>;
};

const styles = {
  cell: {
    paddingLeft: 15,
    paddingRight: 15,
  },
};

export default SmallPaddingTableCell;
