import React, { Fragment } from "react";
import PropTypes from "prop-types";

const HokennaiServiceTable = ({ hokennaiService, afterTable }) => {
  return (
    <Fragment>
      <div className="c-blue-heading">
        <div className="c-blue-heading__text">事業所情報</div>
      </div>
      <div className="p-office-body-table u-margin-b32">
        <table>
          <tbody>
            <tr>
              <th>TEL</th>
              <td>{hokennaiService.tel}</td>
            </tr>
            <tr>
              <th>FAX</th>
              <td>{hokennaiService.fax}</td>
            </tr>
            <tr>
              <th>事業所番号</th>
              <td>{hokennaiService.jigyoshobango}</td>
            </tr>
            <tr>
              <th>事業開始日</th>
              <td>{hokennaiService.jigyokaishibi}</td>
            </tr>
            <tr>
              <th>住所</th>
              <td>{hokennaiService.jusho}</td>
            </tr>
            <tr>
              <th>交通手段</th>
              <td>{hokennaiService.kotsushudan}</td>
            </tr>
            <tr>
              <th>運営法人</th>
              <td>{hokennaiService.uneihojin}</td>
            </tr>
            <tr>
              <th>管理者名</th>
              <td>{hokennaiService.kanrishamei}</td>
            </tr>
          </tbody>
        </table>

        {afterTable}
      </div>
    </Fragment>
  );
};

HokennaiServiceTable.propTypes = {
  hokennaiService: PropTypes.object.isRequired,
  afterTable: PropTypes.node,
};

export default HokennaiServiceTable;
