import React, { Fragment } from "react";
import PropTypes from "prop-types";

const ExternalLink = (props) => {
  if (!props.url) return <Fragment>{props.children}</Fragment>;

  return (
    <a href={props.url} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );
};

ExternalLink.propTypes = {
  url: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};

export default ExternalLink;
