import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../../../state/service-search/actions";
import {
  serviceShubetsuCategoryTypeValueOfHokennai,
  serviceShubetsuCategoryTypeValueOfHokengai,
  serviceShubetsuCategoryTypeValueOfKyotakukaigoshienjigyosho,
} from "../../../../app/domain/jigyosho/ServiceShubetsuCategoryType";
import Parametername from "../../../../app/naming/Parametername";
import { excludeMimamoriSuishinJigyo } from "../../../../app/domain/jigyosho/ServiceShubetsuType";

class SearchConditionServiceShubetsuMenu extends React.Component {
  constructor(props) {
    super(props);
    this.handleServiceShubetsuCategoryClick = this.handleServiceShubetsuCategoryClick.bind(
      this
    );
  }

  handleServiceShubetsuCategoryClick(e) {
    const { checked, value: code } = e.target;
    const { actions, serviceShubetsuMenu, conditionBuffer } = this.props;
    actions.changeConditionServiceShubetsuCategory(
      code,
      checked,
      serviceShubetsuMenu
    );

    // 地域選択保存状態を再設定
    actions.changeCondition(
      Parametername.cityArea,
      conditionBuffer[Parametername.cityArea]
    );
    actions.changeCondition(
      Parametername.townArea,
      conditionBuffer[Parametername.townArea]
    );
  }

  render() {
    return (
      <div>
        <div className="c-blue-frame-sharp u-margin-b32">
          <div className="c-search-head">保険内サービス</div>
          <div className="c-search-main">
            {serviceShubetsuCategoryTypeValueOfHokennai()
              .filter((it) => it.activated)
              .map((it) => this.renderCategory(it))}
            {serviceShubetsuCategoryTypeValueOfKyotakukaigoshienjigyosho()
              .filter((it) => it.activated)
              .map((it) => this.renderCategory(it))}
          </div>

          <div className="c-search-head">保険外サービス</div>
          <div className="c-search-main">
            {this.serviceShubetsuCategoryOfHokengai()}
          </div>
        </div>
      </div>
    );
  }

  serviceShubetsuCategoryOfHokengai() {
    const { user } = this.props.authentication;
    const serviceShubetsuCategory = user.canHandleMimamoriSuishinJigyo()
      ? serviceShubetsuCategoryTypeValueOfHokengai()
      : serviceShubetsuCategoryTypeValueOfHokengai().filter((it) =>
          excludeMimamoriSuishinJigyo(it.code)
        );
    return serviceShubetsuCategory
      .filter((it) => it.activated)
      .map((it) => this.renderCategory(it));
  }

  renderCategory(it) {
    return (
      <label key={`category:${it.label}`} className="c-search-main-radio">
        <input
          type="checkbox"
          value={it.code}
          checked={this.props.serviceShubetsuCategoryCode === it.code}
          onChange={this.handleServiceShubetsuCategoryClick}
        />
        <div className="c-search-main-radio__descri">
          <div className="c-search-main-radio__descri-img">
            <img className="unselect" src={it.imgSrc.inactive} />
            <img className="select" src={it.imgSrc.active} />
          </div>
          <div className="c-search-main-radio__descri-txt">{it.label}</div>
        </div>
      </label>
    );
  }
}

SearchConditionServiceShubetsuMenu.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  serviceShubetsuCategoryCode: PropTypes.string,
  serviceShubetsuMenu: PropTypes.arrayOf(PropTypes.object).isRequired,
  serviceShubetsuMenuCodes: PropTypes.arrayOf(PropTypes.string).isRequired,
  conditionBuffer: PropTypes.object.isRequired,
  authentication: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchConditionServiceShubetsuMenu);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch),
  };
}
