import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "../../../../state/jigyosho-list/actions";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";
import { withInputFeedback } from "../../../../app/ui/form";
import SearchComparator from "../../../../app/util/SearchComparator";
import JigyoshoListComponent from "./JigyoshoListComponent";
import JigyoshoListInformation from "./JigyoshoListInformation";
import { stringIsNullOrEmpty } from "../../../../app/fundamental/data";
import BackOfficeNavigation from "../../../../app/ui/structure/BackOfficeNavigation";
import Mount from "../../../../app/ui/layout/Mount";
import FlatPrimaryButton from "../../../../app/ui/button/FlatPrimaryButton";
import AddIcon from "@material-ui/icons/Add";

class JigyoshoListPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleAddButtonClick = this.handleAddButtonClick.bind(this);
    this.renderInformation = this.renderInformation.bind(this);
    this.renderNavigation = this.renderNavigation.bind(this);
  }

  render() {
    const props = {
      authentication: this.props.authentication,
      history: this.props.history,
      jigyoshoList: this.props.jigyoshoList,
      location: this.props.location,
      omitCondition: !this.props.authentication.user.canAddJigyosho(),
      // on-event handlers
      onAddButtonClick: this.props.onAddButtonClick,
      onDidMount: this.props.onDidMount,
      onEditButtonClick: this.props.onEditButtonClick,
      onItemSelect: this.props.onItemSelect,
      onItemUnselect: this.props.onItemUnselect,
      // renderers
      informationRenderer: this.renderInformation,
      navigationRenderer: this.renderNavigation,
    };
    return <JigyoshoListComponent {...props} />;
  }

  handleAddButtonClick() {
    this.props.onAddButtonClick();
  }

  renderInformation() {
    const { authentication, jigyoshoList } = this.props;
    const authGroupId = authentication.user.group.id;
    const listGroupId = jigyoshoList.group.id;
    if (!authGroupId) return null;
    if (!listGroupId) return null;
    if (authGroupId == listGroupId) return null;
    return <JigyoshoListInformation group={jigyoshoList.group} />;
  }

  renderNavigation() {
    const { user } = this.props.authentication;
    const searchParams = new URLSearchParams(this.props.location.search);
    const groupId = searchParams.get("groupId");

    const userCanAddHojin = user.canAddHojin();
    const groupIdIsUnspecified = stringIsNullOrEmpty(groupId);
    const groupIdIsWelmo = groupId == user.group.id;
    const userCannotAddJigyosho =
      userCanAddHojin && (groupIdIsUnspecified || groupIdIsWelmo);

    const targetIsUnderUsersGroup = userCanAddHojin && !userCannotAddJigyosho;
    const group = this.props.jigyoshoList.group;

    return (
      <BackOfficeNavigation>
        <Mount if={user.canAddJigyosho()}>
          <FlatPrimaryButton
            onClick={this.handleAddButtonClick}
            disabled={userCannotAddJigyosho}
          >
            <AddIcon />
            {targetIsUnderUsersGroup && group.name
              ? `「${group.name}」に`
              : null}
            事業所を追加する
          </FlatPrimaryButton>
        </Mount>
        <Mount if={userCannotAddJigyosho}>
          <span style={styles.buttonMessage}>
            ※
            事業所を追加するには「法人管理」メニューにて法人を選択してください。
          </span>
        </Mount>
      </BackOfficeNavigation>
    );
  }
}

const styles = {
  buttonMessage: {
    fontSize: "small",
  },
};

JigyoshoListPage.propTypes = {
  // state
  authentication: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  jigyoshoList: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  // action
  onAddButtonClick: PropTypes.func.isRequired,
  onDidMount: PropTypes.func.isRequired,
  onEditButtonClick: PropTypes.func.isRequired,
  onItemSelect: PropTypes.func.isRequired,
  onItemUnselect: PropTypes.func.isRequired,
};

export default withInputFeedback(
  connect(mapStateToProps, mapDispatchToProps)(JigyoshoListPage)
);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
    jigyoshoList: state.jigyoshoList,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    onDidMount: (search) => {
      const toUpdate = new SearchComparator(
        props.location.search,
        search
      ).different();
      if (toUpdate)
        dispatch(
          actions.requestJigyoshoListBySearch(
            new URLSearchParams(props.location.search).toString()
          )
        );
      dispatch(actions.select(undefined));
    },
    onItemSelect: (id) => {
      dispatch(actions.select(id));
    },
    onItemUnselect: () => {
      dispatch(actions.select(undefined));
    },
    onAddButtonClick: () => {
      dispatch(
        push({
          pathname: Pathname.backoffice_jigyosho_join,
          search: props.location.search,
        })
      );
    },
    onEditButtonClick: (id) => {
      dispatch(push(Pathname.backoffice_jigyosho_id_status.replace(/:id/, id)));
    },
  };
}
