import React from "react";
import PropTypes from "prop-types";
import ServiceShubetsuType from "../../../../app/domain/jigyosho/ServiceShubetsuType";

const Table = (props) => {
  const {
    onClick,
    onServiceButtonClick,
    service,
    selected,
    onSelect,
    active,
  } = props;
  const id = [ServiceShubetsuType.活動_通いの場]
    .map((it) => it.code)
    .includes(service.serviceShubetsuCode)
    ? service.serviceId
    : service.id;
  return (
    <div className="c-table-frame__flex">
      <div className="c-table-frame__flex-check">
        <label className="c-check">
          <input
            type="checkbox"
            checked={selected}
            onChange={() => onSelect(service.id)}
          />
          <span className="c-check__box"></span>
        </label>
      </div>
      <div className="c-table-frame__flex-link">
        <a
          onClick={() => onClick(service.id)}
          className={active ? "active" : ""}
        >
          <div className="c-table-frame__flex-link-item">
            <div className="c-table-frame__jigyoshomei">
              <div className="c-table-frame__jigyoshomei-left">
                {service.jigyoshomei}
                {(() => {
                  return !service.jusho ? (
                    <div className="c-table-frame__address-private">
                      （住所非公開）
                    </div>
                  ) : null;
                })()}
              </div>
              <div className="c-table-frame__jigyoshomei-right">
                <p
                  className="c-table-frame__detail-link"
                  onClick={() => onServiceButtonClick(id, service.serviceSubId)}
                >
                  詳細
                </p>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  );
};

Table.propTypes = {
  onClick: PropTypes.func.isRequired,
  onServiceButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default Table;
