import React, { Fragment } from "react";
import PropTypes from "prop-types";
import PhotosBlock from "../general/PhotosBlock";
import Mount from "../../../../../app/ui/layout/Mount";
import {
  goToJigyoshoAttributeFormButton,
  gotoPhotoFormButton,
} from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import Thumbnail from "../../../search/Thumbnail";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

const CommonHeadContents = ({ contents }) => {
  const { serviceShubetsuCode } = contents;
  const {
    hokengaiService,
    hokengaiPromotions: promotions,
    photos,
  } = contents.blocks;
  return (
    <Fragment>
      <div className="c-dl u-border-bottom-b u-margin-b32">
        <dl className="u-margin-b8">
          <dt>提供・対象地域</dt>
          <dd>{hokengaiService.serviceTeikyochiiki}</dd>
          <dt>ご利用可能対象者</dt>
          <dd>{hokengaiService.serviceRiyoKanoTaishosha}</dd>
          <Mount
            if={
              ![ServiceShubetsuType.活動_通いの場]
                .map((it) => it.code)
                .includes(serviceShubetsuCode)
            }
          >
            <dt>営業時間</dt>
            <dd>{hokengaiService.eigyojikan}</dd>
            <dt>営業日</dt>
            <dd>{hokengaiService.eigyobi.join("、")}</dd>
            <dt>営業日備考</dt>
            <dd>{hokengaiService.eigyobiBiko}</dd>
            <dt>住所</dt>
            <dd>{hokengaiService.jusho}</dd>
            <dt>電話番号</dt>
            <dd>{hokengaiService.denwabango}</dd>
          </Mount>
          <dt>特記事項</dt>
          <dd>{hokengaiService.tokkijiko}</dd>
        </dl>
        <div className="p-office-body-partition__right-text">
          最新更新日：{contents.userUpdatedAt}
          <br />
          印刷回数：{contents.printCount}
          <br />
        </div>
      </div>
      <div className="p-office-body-sep">
        <div className="p-office-body-sep__left">
          <PhotosBlock photos={photos.list} />
          {gotoPhotoFormButton(contents)}
        </div>
        <div className="p-office-body-sep__right">
          <div className="c-blue-heading">
            <div className="c-blue-heading__text">PR</div>
          </div>
          {promotions.list.map((item, i) => {
            return (
              <div className="p-office-body-pr" key={i}>
                <div className="p-office-body-pr__left">
                  <Thumbnail src={item.imageUrl} />
                </div>
                <div className="p-office-body-pr__right">
                  <div className="p-office-body-pr__title">{item.title}</div>
                  <div className="p-office-body-pr__body">{item.shosai}</div>
                </div>
              </div>
            );
          })}

          {goToJigyoshoAttributeFormButton(
            contents,
            JigyoshoAttributeType.promotion
          )}
        </div>
      </div>
    </Fragment>
  );
};

CommonHeadContents.propTypes = {
  contents: PropTypes.object.isRequired,
};

export default CommonHeadContents;
