import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PrintTypeToggle from "./search/print/PrintTypeToggle";

class WebFrontPrintLayout extends React.Component {
  constructor(props) {
    super(props);
    this.handlePrintPage = this.handlePrintPage.bind(this);
    this.handleBackPage = this.handleBackPage.bind(this);
  }

  handleBackPage() {
    history.back();
  }

  handlePrintPage() {
    window.print();
  }

  render() {
    const { landscape = false, printType = "", detailprint } = this.props;
    const detailClassName = detailprint ? "webfront--print-detail-page" : "";
    return (
      <Fragment>
        {this.page(landscape)}
        <div
          className={`webfront webfront--print-page ${
            landscape ? "webfront--print-page--landscape" : ""
          } ${detailClassName}`}
        >
          {printType ? <PrintTypeToggle type={printType} /> : ""}
          <div className="print-flow">
            <button
              className="print-flow__print-button"
              onClick={this.handleBackPage}
            >
              戻る
            </button>
            <button
              className="print-flow__print-button"
              onClick={this.handlePrintPage}
            >
              この表示で印刷する
            </button>
          </div>

          {this.props.children}
        </div>
      </Fragment>
    );
  }

  page(landscape) {
    return (
      <style type="text/css" media="print">
        {landscape ? "@page { size: landscape; }" : "@page { size: portrait; }"}
      </style>
    );
  }
}

WebFrontPrintLayout.propTypes = {
  children: PropTypes.node.isRequired,
  landscape: PropTypes.bool,
  detailprint: PropTypes.bool,
  printType: PropTypes.string,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebFrontPrintLayout);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps() {
  return {};
}
