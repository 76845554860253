import {
  leadToLogin,
  openMessage,
} from "../../../state/notification-message/actions";
import { raiseErrors, reset } from "../../../state/input-feedback/actions";
import { setMaintenance } from "../../../state/maintenance/actions";
import { responseHandlersTypeOf } from "./ResponseHandlersType";
import Pathname from "../../naming/Pathname";
import { push } from "react-router-redux";

const WAIT = 250;

export function withCompletionMessage(dispatch, message) {
  return (responseHandler) => {
    const type = responseHandlersTypeOf(responseHandler);
    const normalizedReponseHandler = type.normalize(responseHandler);
    return {
      ...normalizedReponseHandler,
      onSuccess: (json) => {
        if (message) {
          setTimeout(
            () => setTimeout(() => dispatch(openMessage(message))),
            WAIT
          );
        }
        normalizedReponseHandler.onSuccess(json);
      },
    };
  };
}

export function defaultResponseHandler(
  dispatch,
  onSuccess,
  onFailure = () => {}
) {
  return {
    // for HTTP status code 200
    onSuccess: (json) => {
      dispatch(reset());
      onSuccess(json);
    },
    // for HTTP status code 4xx
    onFailure: (json) => {
      dispatch(openMessage("入力に誤りがあります。ご確認ください。"));
      dispatch(raiseErrors(json));
      onFailure(json);
    },
    // for HTTP status code 503
    onMaintenance: (json) => {
      dispatch(setMaintenance(json.message));
      dispatch(push(Pathname.maintenance));
    },
    // for HTTP status code 5xx excludes 503
    onError: () => {
      dispatch(
        openMessage(
          "システムにエラーがありました。恐れ入りますがシステム管理者までお問い合わせください。"
        )
      );
    },
  };
}

export function defaultUnauthorizedHander(dispatch) {
  return () =>
    dispatch(
      leadToLogin(
        "申し訳ございません。ログインして最初からやり直してください。"
      )
    );
}
