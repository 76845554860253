import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";

/**
 * 訪問系サービス利用者フォーム.
 */
class HomonkeiServiceRiyoshaForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        {this.renderTokucho()}
        <FormGrouping title="利用者数">
          {this.renderSeibetsuGoriyoushasu()}
          {this.renderNenreibetsuGoriyoushasu()}
          {this.renderYokaigodobetsuGoriyoushasu()}
        </FormGrouping>
      </Fragment>
    );
  }

  renderTokucho() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="利用者">
        <HorizontalLabelLayout labelText="特徴">
          <Textarea name="tokucho" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderSeibetsuGoriyoushasu() {
    const { bindingProps } = this.props;
    return (
      <HorizontalLabelLayout labelText="性別" required>
        <div>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>男性</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="seibetsuDansei"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
              <InputTypeCheckbox
                name="danseiUkeireFuka"
                labelText="男性受け入れ不可"
                {...bindingProps}
              />
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>女性</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="seibetsuJosei"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
              <InputTypeCheckbox
                name="joseiUkeireFuka"
                labelText="女性受け入れ不可"
                {...bindingProps}
              />
            </SpaceOut>
          </RatioBlock>
        </div>
      </HorizontalLabelLayout>
    );
  }

  renderNenreibetsuGoriyoushasu() {
    const { bindingProps } = this.props;
    return (
      <HorizontalLabelLayout labelText="年齢別">
        <div>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>65歳未満</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="nenreibetsu65miman"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>65歳〜75歳未満</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="nenreibetsu65ijo75miman"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>75歳〜85歳未満</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="nenreibetsu75ijo85miman"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>85歳〜95歳未満</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="nenreibetsu85ijo95miman"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>95歳以上</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="nenreibetsu95ijo"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
        </div>
      </HorizontalLabelLayout>
    );
  }

  renderYokaigodobetsuGoriyoushasu() {
    const { bindingProps } = this.props;
    return (
      <HorizontalLabelLayout labelText="要介護度別" recommended>
        <div>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>要支援1</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="yoshien1"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>要支援2</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="yoshien2"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
        </div>
        <div>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>要介護1</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="yokaigo1"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>要介護2</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="yokaigo2"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>要介護3</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="yokaigo3"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>要介護4</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="yokaigo4"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>要介護5</StaticLabel>
              <UnitLayout unitText="人">
                <InputTypeNumber
                  name="yokaigo5"
                  hintText="0"
                  {...bindingProps}
                />
              </UnitLayout>
            </SpaceOut>
          </RatioBlock>
        </div>
      </HorizontalLabelLayout>
    );
  }
}

HomonkeiServiceRiyoshaForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceRiyoshaForm
);
