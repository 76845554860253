import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware, compose } from "redux";
import createHistory from "history/createBrowserHistory";
import { ConnectedRouter, routerMiddleware } from "react-router-redux";
import thunk from "redux-thunk";
import HomeRouter from "./app/router/HomeRouter";
import reducer from "./state";

const history = createHistory();
const router = routerMiddleware(history);

const createStoreWithMiddleware = compose(applyMiddleware(thunk, router))(
  createStore
);
const store = createStoreWithMiddleware(
  reducer,
  process.env.NODE_ENV === "development" &&
    typeof window === "object" &&
    window.__REDUX_DEVTOOLS_EXTENSION__
    ? window.__REDUX_DEVTOOLS_EXTENSION__()
    : (noop) => noop
);

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <HomeRouter />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("app")
);
