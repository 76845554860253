import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withServiceSearchList } from "../../../state/service-search";
import {
  flatten,
  groupServicesByLatLng,
} from "../../../app/fundamental/map/grouping";
import Map from "./print/Map";
import MapPrintCards from "./print/MapPrintCards";
import Sheet from "./print/Sheet";
import { serviceShubetsuCategoryTypeValueOf } from "../../../app/domain/jigyosho/ServiceShubetsuCategoryType";

const ITEMS_IN_LINE = 2;

const ServiceMapPrintPage = ({
  serviceSearch,
  serviceShubetsuCategoryCode,
}) => {
  const serviceGroups = groupServicesByLatLng(serviceSearch.list);
  const servicesFlatten = flatten(serviceGroups);
  const serviceShubetsuCategory = serviceShubetsuCategoryTypeValueOf(
    serviceShubetsuCategoryCode
  );
  return (
    <Fragment>
      <Sheet afterFooter={renderNote()}>
        <div className="map-print">
          <div className="map-print__service-shubetsu">
            {serviceShubetsuCategory.label}
          </div>

          <Map
            serviceGroups={serviceGroups}
            containerElement={<div className="map-print__map" />}
            mapElement={<div style={{ height: "100%" }} />}
          />

          {renderList(servicesFlatten)}
        </div>
      </Sheet>
    </Fragment>
  );

  function renderList(services) {
    const maxLines = Math.ceil(services.length / ITEMS_IN_LINE);
    return [...Array(maxLines).keys()].map((i) => (
      <div key={i} className="map-print__list">
        <MapPrintCards
          services={services.slice(ITEMS_IN_LINE * i, ITEMS_IN_LINE * (i + 1))}
          serviceShubetsuCategoryCode={serviceShubetsuCategoryCode}
        />
      </div>
    ));
  }

  function renderNote() {
    return (
      <div
        className="sheet-note"
        style={{ position: "absolute", top: "150px", right: "-250px" }}
      >
        <div className="sheet-note__body">
          地図の表示領域や縮尺を適宜変更して印刷してください。
        </div>
      </div>
    );
  }
};

ServiceMapPrintPage.propTypes = {
  serviceSearch: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
};

export default withServiceSearchList(
  connect(mapStateToProps)(ServiceMapPrintPage)
);

function mapStateToProps(state) {
  return {
    serviceSearch: state.serviceSearch,
  };
}
