import React, { Fragment } from "react";
import PropTypes from "prop-types";

const RehabilitationTable = ({ rehabilitation, afterTable }) => {
  return (
    <Fragment>
      <div className="c-blue-heading">
        <div className="c-blue-heading__text">リハビリ・機能訓練</div>
      </div>
      <div className="p-office-body-title">個別機能訓練(個別対応)</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>個別対応</th>
              <td>{rehabilitation.kobetsutaio}</td>
            </tr>
            <tr>
              <th>内容</th>
              <td>
                {rehabilitation.rehabilitationKobetsuKinokunrennaiyo.join("、")}
              </td>
            </tr>
            <tr>
              <th>時間(分)</th>
              <td>{rehabilitation.kobetsutaioJikan}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-title">個別機能訓練(集団対応)</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>集団対応</th>
              <td>{rehabilitation.shudantaio}</td>
            </tr>
            <tr>
              <th>内容</th>
              <td>
                {rehabilitation.rehabilitationShudanKinokunrennaiyo.join("、")}
              </td>
            </tr>
            <tr>
              <th>時間(分)</th>
              <td>{rehabilitation.shudantaioJikan}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-title">環境・設備</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>リハビリ機材</th>
              <td>
                {rehabilitation.rehabiliKizai
                  .map((item) => item.label)
                  .join("、")}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-title">介護報酬加算</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>介護報酬加算</th>
              <td>{rehabilitation.kaigohoshukasan.join("、")}</td>
            </tr>
          </tbody>
        </table>
        {afterTable}
      </div>
    </Fragment>
  );
};

RehabilitationTable.propTypes = {
  rehabilitation: PropTypes.object.isRequired,
  afterTable: PropTypes.node,
};

export default RehabilitationTable;
