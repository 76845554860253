import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import TimePeriod from "../../../../../app/ui/form/TimePeriod";
import ShortInputWidth from "../../../../../app/ui/form/ShortInputWidth";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Textarea from "../../../../../app/ui/form/Textarea";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import GridRow from "../../../../../app/ui/grid/GridRow";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import GridCell from "../../../../../app/ui/grid/GridCell";

class ShortstayEigyoJikanForm extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Fragment>
        {this.renderToiawaseJikan()}
        {this.renderMenkaiMadoguchi()}
      </Fragment>
    );
  }

  renderToiawaseJikan() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="窓口対応時間">
        <HorizontalLabelLayout labelText="平日" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="heijitsu"
                disabled={bindingProps.form.getByName("heijitsu_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="heijitsu_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="土曜" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="doyo"
                disabled={bindingProps.form.getByName("doyo_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="doyo_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="日曜" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="nichiyo"
                disabled={bindingProps.form.getByName("nichiyo_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="nichiyo_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="祝日" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="shukujitsu"
                disabled={bindingProps.form.getByName("shukujitsu_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="shukujitsu_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderMenkaiMadoguchi() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="面会時間・窓口">
        <HorizontalLabelLayout labelText="面会可能時間">
          <ShortInputWidth>
            <TimePeriod name="menkai" separator="." {...bindingProps} />
          </ShortInputWidth>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="窓口365日対応">
          <InputTypeCheckbox
            name="allyearUkeire"
            labelText="はい"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="窓口定休日" recommended>
          <InputTypeText name="teikyubi" {...bindingProps} />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="留意事項">
          <Textarea name="ryuijiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

ShortstayEigyoJikanForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
};

export default ShortstayEigyoJikanForm;
