import React from "react";
import PrintTableHead from "../../../../app/ui/print/PrintTableHead";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const HomonkeiServiceTableHead = () => {
  return (
    <PrintTableHead height="80px">
      <PrintTableNode width="105px">
        店舗・団体名
        <div className="c-table-frame__updated-at">(最終更新日)</div>
      </PrintTableNode>
      <PrintTableNode width="100px">サービス種別</PrintTableNode>
      <PrintTableNode width="150px">住所</PrintTableNode>
      <PrintTableNode width="112px">TEL</PrintTableNode>
      <PrintTableNode width="72px" flexible>
        <div className="row" style={{ height: "50%" }}>
          <div className="cell">営業日</div>
        </div>
        <div className="row" style={{ height: "50%" }}>
          <div className="cell" style={{ width: "15px" }}>
            土
          </div>
          <div className="cell" style={{ width: "15px" }}>
            日
          </div>
          <div className="cell" style={{ width: "15px" }}>
            祝
          </div>
        </div>
      </PrintTableNode>
      <PrintTableNode width="15px">早朝</PrintTableNode>
      <PrintTableNode width="15px">夜間</PrintTableNode>
      <PrintTableNode width="155px">サービス提供地域</PrintTableNode>
      <PrintTableNode width="180px">受入れ / 対応可能な症状等</PrintTableNode>
      <PrintTableNode width="135px">特徴</PrintTableNode>
    </PrintTableHead>
  );
};

export default HomonkeiServiceTableHead;
