import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../app/naming/Pathname";
import AccountEditComponent from "../../backoffice/account/edit/AccountEditComponent";

class SystemOperatorUserEditPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields(props);
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  initializeFields(props) {
    const { params } = props.match;
    this.id = +params.id;
  }

  groupId() {
    return this.props.accountForm.form.groupId;
  }

  handleBackButtonClick() {
    const { actions } = this.props;
    actions.gotoListPage(this.groupId());
  }

  handleSaveButtonClick() {
    this.handleBackButtonClick();
  }

  render() {
    const pageProps = {
      id: this.id,
      onGoBack: this.handleBackButtonClick,
      onGoAhead: this.handleSaveButtonClick,
    };
    return <AccountEditComponent {...pageProps} />;
  }
}

SystemOperatorUserEditPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  accountForm: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemOperatorUserEditPage);

function mapStateToProps(state) {
  return {
    accountForm: state.accountForm,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gotoListPage: (groupId) => {
        const searchParams = new URLSearchParams();
        searchParams.set("groupId", groupId);
        const search = searchParams.toString();
        dispatch(
          push({
            pathname: Pathname.tools_sysop,
            search,
          })
        );
      },
    },
  };
}
