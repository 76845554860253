import AuthorizationRequiredRequest from "./AuthorizationRequiredRequest";
import AuthorizationRequiredRequestFile from "./AuthorizationRequiredRequestFile";
import { responseHandlersTypeOf } from "./ResponseHandlersType";

class ApiRequest {
  constructor(authorizationRequiredRequest) {
    this.authorizationRequiredRequest = authorizationRequiredRequest;
  }

  request(
    responseHandler = (/* json */) => {
      /* received a response */
    },
    unauthorizedHandler = () => {
      /* unauthorized */
    }
  ) {
    this.makeErrorHandler(responseHandler);
    this.authorizationRequiredRequest
      .setErrorHandler(this.errorHandler)
      .request(responseHandler, unauthorizedHandler);
  }

  makeErrorHandler(responseHandler) {
    this.errorHandler = responseHandlersTypeOf(responseHandler).normalize(
      responseHandler
    ).onError;
  }

  static get(url) {
    return new ApiRequest(AuthorizationRequiredRequest.get(url));
  }

  static post(url, body) {
    return new ApiRequest(AuthorizationRequiredRequest.post(url, body));
  }

  static delete(url) {
    return new ApiRequest(AuthorizationRequiredRequest.delete(url));
  }

  static download(url, body) {
    return new ApiRequest(AuthorizationRequiredRequestFile.post(url, body));
  }
}

export default ApiRequest;
