import Parametername from "../../app/naming/Parametername";
import { viewTypeValueOfOrDefault } from "../../page/webfront/search/ViewType";
import { serviceSearchSortTypeValueOfOrDefault } from "../../app/domain/service/ServiceSearchSortType";
import { searchConditionFunnelTypeValues } from "../../page/webfront/search/SearchConditionFunnelType";
import { searchConditionFunnelTypeValuesOfMenus } from "../../page/webfront/search/SearchConditionFunnelTypeConfiguration";

export function searchConverter(target = "", serviceShubetsuMenu = []) {
  return {
    toCondition: () => {
      const searchParams = new URLSearchParams(target);
      const defaultMenuCodes = searchParams.getAll(
        Parametername.serviceShubetsuMenuCode
      );
      const category = serviceShubetsuMenu
        .filter((menu) => defaultMenuCodes.includes(menu.code))
        .map((menu) => menu.category)
        .pop();
      const condition = {
        serviceShubetsuCategoryCode: category ? category.code : undefined,
        serviceShubetsuMenuCodes: defaultMenuCodes,
        view: viewTypeValueOfOrDefault(searchParams.get(Parametername.view)),
        sort: serviceSearchSortTypeValueOfOrDefault(
          searchParams.get(Parametername.sort)
        ),
        [Parametername.keyword]: searchParams.get(Parametername.keyword)
          ? searchParams.get(Parametername.keyword)
          : "",
        [Parametername.minLat]: searchParams.get(Parametername.minLat)
          ? searchParams.get(Parametername.minLat)
          : "",
        [Parametername.maxLat]: searchParams.get(Parametername.maxLat)
          ? searchParams.get(Parametername.maxLat)
          : "",
        [Parametername.minLng]: searchParams.get(Parametername.minLng)
          ? searchParams.get(Parametername.minLng)
          : "",
        [Parametername.maxLng]: searchParams.get(Parametername.maxLng)
          ? searchParams.get(Parametername.maxLng)
          : "",
      };
      condition[Parametername.cityArea] = {};
      searchParams
        .getAll(Parametername.cityArea)
        .forEach((val) => (condition[Parametername.cityArea][val] = true)),
        (condition[Parametername.townArea] = {});
      searchParams
        .getAll(Parametername.townArea)
        .forEach((val) => (condition[Parametername.townArea][val] = true)),
        searchConditionFunnelTypeValues().forEach((type) =>
          type.parameters.forEach((param) => {
            if (param.type == "checkbox") {
              condition[param.name] = {};
              searchParams
                .getAll(param.name)
                .forEach((value) => (condition[param.name][value] = true));
            }
            if (param.type == "radio") {
              condition[param.name] = searchParams.get(param.name)
                ? searchParams.get(param.name)
                : "";
            }
            if (param.type == "select") {
              condition[param.name] = searchParams.get(param.name)
                ? searchParams.get(param.name)
                : "";
            }
            if (param.type == "select_enclose") {
              param.list.forEach((select_enclose) => {
                if (select_enclose.type == "select") {
                  condition[select_enclose.name] = searchParams.get(
                    select_enclose.name
                  )
                    ? searchParams.get(select_enclose.name)
                    : "";
                }
              });
            }
          })
        );

      return condition;
    },
  };
}

export function searchFilter(search) {
  return {
    forSharedFolder: () => {
      const after = new URLSearchParams();
      const before = new URLSearchParams(search);
      before
        .getAll(Parametername.serviceShubetsuMenuCode)
        .forEach((code) =>
          after.append(Parametername.serviceShubetsuMenuCode, code)
        );
      after.set(Parametername.folder, "yes");
      after.set(
        Parametername.view,
        viewTypeValueOfOrDefault(before.get(Parametername.view)).name
      );
      after.set(
        Parametername.sort,
        serviceSearchSortTypeValueOfOrDefault(before.get(Parametername.sort))
          .name
      );
      return after.toString();
    },
    forServiceSearch: () => {
      const searchParams = new URLSearchParams(search);
      searchParams.delete(Parametername.folder);
      return searchParams.toString();
    },
  };
}

export function conditionConverter(condition) {
  return {
    toSearch: (options = {}) => {
      options = {
        ...{
          folder: false,
          page: 0,
          view: undefined,
        },
        ...options,
      };

      const searchParams = new URLSearchParams();
      condition.serviceShubetsuMenuCodes.forEach((code) =>
        searchParams.append(Parametername.serviceShubetsuMenuCode, code)
      );
      searchParams.set(Parametername.view, condition.view.name);
      searchParams.set(Parametername.sort, condition.sort.name);
      if (condition[Parametername.cityArea])
        Object.keys(condition[Parametername.cityArea])
          .filter((key) => condition[Parametername.cityArea][key])
          .forEach((key) => searchParams.append(Parametername.cityArea, key));
      if (
        condition[Parametername.townArea] &&
        Object.keys(condition[Parametername.cityArea]).length < 2
      )
        Object.keys(condition[Parametername.townArea])
          .filter((key) => condition[Parametername.townArea][key])
          .forEach((key) => searchParams.append(Parametername.townArea, key));
      if (condition[Parametername.keyword])
        searchParams.set(
          Parametername.keyword,
          condition[Parametername.keyword]
        );
      if (condition[Parametername.minLat])
        searchParams.set(Parametername.minLat, condition[Parametername.minLat]);
      if (condition[Parametername.maxLat])
        searchParams.set(Parametername.maxLat, condition[Parametername.maxLat]);
      if (condition[Parametername.minLng])
        searchParams.set(Parametername.minLng, condition[Parametername.minLng]);
      if (condition[Parametername.maxLng])
        searchParams.set(Parametername.maxLng, condition[Parametername.maxLng]);
      if (options.folder) searchParams.set(Parametername.folder, "yes");
      if (options.page) searchParams.set(Parametername.page, options.page);
      if (options.view) searchParams.set(Parametername.view, options.view);
      searchConditionFunnelTypeValuesOfMenus(
        condition.serviceShubetsuCategoryCode,
        condition.serviceShubetsuMenuCodes,
        true
      ).forEach((type) =>
        type.parameters.forEach((param) => {
          if (param.type == "checkbox") {
            Object.keys(condition[param.name])
              .filter((key) => condition[param.name][key])
              .forEach((key) => searchParams.append(param.name, key));
          }
          if (param.type == "radio") {
            const value = condition[param.name];
            if (value) searchParams.set(param.name, value);
          }
          if (param.type == "select") {
            const value = condition[param.name];
            if (value) searchParams.set(param.name, value);
          }
          if (param.type == "select_enclose") {
            param.list.forEach((select_enclose) => {
              if (select_enclose.type == "select") {
                const value = condition[select_enclose.name];
                if (value) searchParams.set(select_enclose.name, value);
              }
            });
          }
        })
      );
      return searchParams.toString();
    },
  };
}

export function foldersConverter(folders, condition) {
  return {
    toSearch: () => {
      const before = new URLSearchParams(
        conditionConverter(condition).toSearch()
      );
      const after = new URLSearchParams();
      folders.forEach((code) =>
        after.append(Parametername.serviceShubetsuMenuCode, code)
      );
      after.set(Parametername.folder, "yes");
      after.set(Parametername.view, before.get(Parametername.view));
      after.set(Parametername.sort, before.get(Parametername.sort));
      return after.toString();
    },
  };
}
