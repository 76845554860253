import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../app/naming/Pathname";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_SERVICE,
} from "../../../state/master-data";
import { share, unshare } from "../../../state/shared-folder/actions";
import SearchResultComponent from "../search/SearchResultComponent";
import { select } from "../../../state/service-search/actions";
import Parametername from "../../../app/naming/Parametername";

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SERVICE)(
  connect(mapStateToProps, mapDispatchToProps)(SearchResultComponent)
);

function mapStateToProps(state) {
  return {
    serviceSearch: state.serviceSearch,
    sharedFolder: state.sharedFolder,
    siteIsSharedFolder: true,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      share: (id, serviceSubId) => dispatch(share(id, serviceSubId)),
      unshare: (id, serviceSubId) => dispatch(unshare(id, serviceSubId)),
      select: (id) => dispatch(select(id)),
      page: (search) => {
        dispatch(
          push({
            search,
          })
        );
      },
      gotoServicePage: (id, serviceSubId, history) => {
        const searchParams = new URLSearchParams();
        if (serviceSubId)
          searchParams.set(Parametername.serviceSubId, serviceSubId);
        // window.location.href = `${Pathname.webfront_service_id.replace(/:id/, id)}?${searchParams.toString()}`;
        history.push({
          pathname: `${Pathname.webfront_service_id.replace(/:id/, id)}`,
          search: `${searchParams.toString()}`,
          state: { url: `${location.pathname}${location.search}` },
        });
      },
      openPrintPage: (id, serviceSubId) => {
        const searchParams = new URLSearchParams();
        if (serviceSubId)
          searchParams.set(Parametername.serviceSubId, serviceSubId);
        window.location.href = `${Pathname.webfront_print_service_id.replace(
          /:id/,
          id
        )}?${searchParams.toString()}`;
      },
      openListPrintPage: (
        ids,
        serviceShubetsuCategoryCode,
        sort,
        isMapView = false,
        isCardView = false
      ) => {
        const searchParams = new URLSearchParams();
        ids.forEach((id) => searchParams.append(Parametername.id, id));
        searchParams.set(
          Parametername.serviceShubetsuCategoryCode,
          serviceShubetsuCategoryCode
        );
        searchParams.set(Parametername.sort, sort.name);
        const pathname = () => {
          if (isMapView) return Pathname.webfront_print_service_map;
          if (isCardView) return Pathname.webfront_print_service_details;
          return Pathname.webfront_print_service_list;
        };
        window.location.href = `${pathname()}?${searchParams.toString()}`;
      },
    },
  };
}
