import React from "react";
import { Route, Switch } from "react-router";
import Pathname from "../../../app/naming/Pathname";
import HojinListPage from "./list/HojinListPage";
import HojinEditPage from "./edit/HojinEditPage";
import HojinPasswordPage from "./password/HojinPasswordPage";
import HojinAddPage from "./add/HojinAddPage";
import HojinKanriAccountEditPage from "./kanriaccount/HojinKanriAccountEditPage";
import HojinFeaturePage from "./feature/HojinFeaturePage";

const HojinRouter = () => {
  return (
    <Switch>
      <Route path={Pathname.backoffice_hojin_add} component={HojinAddPage} />
      <Route
        path={Pathname.backoffice_hojin_id_edit}
        component={HojinEditPage}
      />
      <Route
        path={Pathname.backoffice_hojin_id_features}
        component={HojinFeaturePage}
      />
      <Route
        path={Pathname.backoffice_hojin_accounts_id_edit}
        component={HojinKanriAccountEditPage}
      />
      <Route
        path={Pathname.backoffice_hojin_accounts_id_password}
        component={HojinPasswordPage}
      />
      <Route path={Pathname.backoffice_hojin} component={HojinListPage} />
    </Switch>
  );
};

export default HojinRouter;
