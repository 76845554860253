const ActionType = {
  REQUEST: "SHARED_FOLDER_REQUEST",
  REQUEST_FINISHED: "SHARED_FOLDER_REQUEST_FINISHED",
  SHARE: "SHARED_FOLDER_SHARE",
  SHARE_FINISHED: "SHARED_FOLDER_SHARE_FINISHED",
  UNSHARE: "SHARED_FOLDER_UNSHARE",
  UNSHARE_FINISHED: "SHARED_FOLDER_UNSHARE_FINISHED",
};

export default ActionType;
