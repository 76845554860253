import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../../app/naming/Pathname";

class PrintTypeToggle extends React.Component {
  constructor(props) {
    super(props);

    this.handleToggleToListButtonClick = this.handleToggleToListButtonClick.bind(
      this
    );
    this.handleToggleToDetailsButtonClick = this.handleToggleToDetailsButtonClick.bind(
      this
    );
    this.handleToggleToMapButtonClick = this.handleToggleToMapButtonClick.bind(
      this
    );
  }

  handleToggleToListButtonClick() {
    this.props.gotoPrintPage(Pathname.webfront_print_service_list);
  }

  handleToggleToDetailsButtonClick() {
    this.props.gotoPrintPage(Pathname.webfront_print_service_details);
  }

  handleToggleToMapButtonClick() {
    this.props.gotoPrintPage(Pathname.webfront_print_service_map);
  }

  render() {
    const { type } = this.props;

    return (
      <div className="print-type-toggle">
        <button
          className={`print-type-toggle__toggle-button ${
            type === "details" ? "print-type-toggle__toggle-button--active" : ""
          }`}
          onClick={this.handleToggleToDetailsButtonClick}
        >
          事業所詳細で印刷
        </button>
        <button
          className={`print-type-toggle__toggle-button ${
            type === "list" ? "print-type-toggle__toggle-button--active" : ""
          }`}
          onClick={this.handleToggleToListButtonClick}
        >
          リストで印刷
        </button>
        <button
          className={`print-type-toggle__toggle-button ${
            type === "map" ? "print-type-toggle__toggle-button--active" : ""
          }`}
          onClick={this.handleToggleToMapButtonClick}
        >
          マップで印刷
        </button>
      </div>
    );
  }
}

PrintTypeToggle.propTypes = {
  type: PropTypes.oneOf(["list", "map", "details"]).isRequired,
  gotoPrintPage: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(PrintTypeToggle);

function mapDispatchToProps(dispatch) {
  return {
    gotoPrintPage: (pathname) => {
      const urlAndSearch = window.location.href.split("?");
      const search = urlAndSearch.length >= 2 ? urlAndSearch[1] : "";
      dispatch(push({ pathname, search }));
    },
  };
}
