import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import WebFrontFooter from "./WebFrontFooter";
import { push } from "react-router-redux";
import RaisedPrimaryButton from "../../app/ui/button/RaisedPrimaryButton";

class WebFrontPreviewLayout extends React.Component {
  constructor(props) {
    super(props);

    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
  }

  handleBackButtonClick() {
    this.props.onBackButtonClick();
  }

  render() {
    const { user } = this.props.authentication;
    return (
      <div className="webfront c-header-page">
        <div className="c-header">
          <div className="c-header-frame">
            <div className="c-header-frame__logo">
              <img src="/images/logo.svg" />
            </div>
            <div className="c-header-frame__nav">
              <ul>
                <li>
                  <RaisedPrimaryButton onClick={this.handleBackButtonClick}>
                    情報入力ページに戻る
                  </RaisedPrimaryButton>
                </li>
                <li className="c-header-frame__nav-login-user">
                  <a>
                    <div className="c-header-frame__nav-img">
                      <img src="/images/chara_hand-glass.png" />
                    </div>
                    <div className="c-header-frame__nav-text">
                      <div className="c-header-frame__nav-text-sub">
                        {user.group.name}
                      </div>
                      <div className="c-header-frame__nav-text-main">
                        {user.familyName} {user.givenName}さん
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="l-content">
          {this.props.children}
          <WebFrontFooter preview />
        </div>
      </div>
    );
  }
}

WebFrontPreviewLayout.propTypes = {
  // state
  authentication: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.object.isRequired,
  // action
  onBackButtonClick: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(WebFrontPreviewLayout);

function mapStateToProps(state) {
  return {
    authentication: state.authentication,
  };
}

function mapDispatchToProps(dispatch, props) {
  return {
    onBackButtonClick: () => {
      dispatch(
        push({
          pathname: props.type.returnPathname.replace(":id", props.id),
        })
      );
    },
  };
}
