import React from "react";
import PropTypes from "prop-types";

const PrintTableHead = (props) => {
  return (
    <div className="c-table-frame__flex u-textalign-center">
      <img src="/images/print_bg_light_blue.png" />
      <div className="c-table-frame__flex-check u-bg-skyblue2"></div>
      <div
        className="c-table-frame__flex-link u-bg-skyblue2"
        style={{ height: props.height }}
      >
        {props.children}
      </div>
    </div>
  );
};

PrintTableHead.propTypes = {
  height: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default PrintTableHead;
