import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { push } from "react-router-redux";
import Pathname from "../../../app/naming/Pathname";
import AccountAddComponent from "../../backoffice/account/add/AccountAddComponent";
import RoleType from "../../../app/domain/account/RoleType";

class SystemOperatorUserAddPage extends React.Component {
  constructor(props) {
    super(props);
    this.initializeFields();
    this.handleBackButtonClick = this.handleBackButtonClick.bind(this);
    this.handleSaveButtonClick = this.handleSaveButtonClick.bind(this);
  }

  initializeFields() {
    // システム管理者、システムオペレータのグループIDをセット
    this.groupId = 1;
  }

  handleBackButtonClick() {
    const { actions } = this.props;
    actions.gotoListPage(this.groupId);
  }

  handleSaveButtonClick() {
    const { actions } = this.props;
    actions.reloadListPage(this.groupId);
  }

  render() {
    const pageProps = {
      groupId: this.groupId,
      onGoBack: this.handleBackButtonClick,
      onGoAhead: this.handleSaveButtonClick,
      role: RoleType.ROLE_SYS_OPERATOR.name,
    };
    return <AccountAddComponent {...pageProps} />;
  }
}

SystemOperatorUserAddPage.propTypes = {
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  location: PropTypes.object.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SystemOperatorUserAddPage);

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      gotoListPage: (groupId) => {
        const searchParams = new URLSearchParams();
        searchParams.set("groupId", groupId);
        const search = searchParams.toString();
        dispatch(
          push({
            pathname: Pathname.tools_sysop,
            search,
          })
        );
      },
      reloadListPage: (groupId) => {
        const searchParams = new URLSearchParams();
        searchParams.set("groupId", groupId);
        searchParams.set("timestamp", new Date().getTime());
        const search = searchParams.toString();
        dispatch(
          push({
            pathname: Pathname.tools_sysop,
            search,
          })
        );
      },
    },
  };
}
