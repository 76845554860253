import React from "react";
import PropTypes from "prop-types";
import PrintTable from "../../../../app/ui/print/PrintTable";
import PrintTableNode from "../../../../app/ui/print/PrintTableNode";

const KyotakukaigoshienjigyoshoTable = (props) => {
  const { service } = props;
  const { hokennaiService, kyotakukaigoshienjigyosho } = service.blocks;

  return (
    <PrintTable {...props}>
      <PrintTableNode width="105px">
        {hokennaiService.jigyoshomei}
        <div className="c-table-frame__updated-at">
          ({service.userUpdatedAt})
        </div>
      </PrintTableNode>
      <PrintTableNode width="150px">{service.jusho}</PrintTableNode>
      <PrintTableNode width="112px">{hokennaiService.tel}</PrintTableNode>
      <PrintTableNode width="15px" center>
        {kyotakukaigoshienjigyosho.serviceTeikyoTaisho.filter(
          (it) => it === "要支援"
        ).length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode minWidth="15px" center>
        {hokennaiService.eigyobi.filter((it) => it === "土曜").length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode minWidth="15px" center>
        {hokennaiService.eigyobi.filter((it) => it === "日曜").length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode minWidth="15px" center>
        {hokennaiService.eigyobi.filter((it) => it === "祝日").length > 0
          ? "〇"
          : ""}
      </PrintTableNode>
      <PrintTableNode width="140px" center>
        {kyotakukaigoshienjigyosho.kinkyujinodenwataio}
        <div>
          {kyotakukaigoshienjigyosho.kinkyujinodenwataio &&
          kyotakukaigoshienjigyosho.kinkyujinorenrakusaki
            ? `(${kyotakukaigoshienjigyosho.kinkyujinorenrakusaki})`
            : ""}
        </div>
      </PrintTableNode>
      <PrintTableNode width="145px" center>
        {kyotakukaigoshienjigyosho.kanrishahoyushikaku}
      </PrintTableNode>
      <PrintTableNode width="175px" center>
        {serviceTeikyoChiiki(kyotakukaigoshienjigyosho)}
      </PrintTableNode>
      <PrintTableNode width="135px">
        {kyotakukaigoshienjigyosho.tokucho.map((it) => (
          <div key={it}>・{it}</div>
        ))}
      </PrintTableNode>
    </PrintTable>
  );
};

function serviceTeikyoChiiki(kyotakukaigoshienjigyosho) {
  const LABEL_LIMIT = 77;
  let serviceTeikyoChiiki = kyotakukaigoshienjigyosho.serviceTeikyoChiiki
    .split("\n")
    .join(" ");
  serviceTeikyoChiiki =
    serviceTeikyoChiiki && serviceTeikyoChiiki.length > LABEL_LIMIT
      ? serviceTeikyoChiiki.substr(0, LABEL_LIMIT) + "..."
      : serviceTeikyoChiiki;

  return serviceTeikyoChiiki;
}

KyotakukaigoshienjigyoshoTable.propTypes = {
  onServiceButtonClick: PropTypes.func.isRequired,
  service: PropTypes.object.isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default KyotakukaigoshienjigyoshoTable;
