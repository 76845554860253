const Parametername = {
  // login
  from: "from",
  // search
  serviceShubetsuMenuCode: "s",
  folder: "folder",
  view: "view",
  sort: "sort",
  page: "page",
  prefArea: "x",
  cityArea: "y",
  townArea: "z",
  keyword: "kw",
  // print
  id: "id",
  print: "print",
  // form
  serviceSubId: "ssi",
  files: "files",
  serviceShubetsuCategoryCode: "c",
  serviceShubetsuCode: "s",
  preview: "preview",
  pathname: "pathname",
  minLat: "minLat",
  maxLat: "maxLat",
  minLng: "minLng",
  maxLng: "maxLng",
};

export default Parametername;
