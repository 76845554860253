import React, { Fragment } from "react";
import PropTypes from "prop-types";
import HeaderTextBlue from "../general/HeaderTextBlue";

const YoyakuShiharaiBlock = ({ yoyakuShiharai }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="予約・お支払い" />
      <div className="p-office-body-table-dotted">
        <table>
          <tbody>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>事前予約の有無</th>
              <td>{yoyakuShiharai.jizenyoyaku}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>予約方法</th>
              <td>{yoyakuShiharai.yoyakuhoho}</td>
            </tr>
            <tr className="tbody-th_bold tbody-th_width80 tbody-th_height20 tbody-td_textAlign_left">
              <th>お支払い方法</th>
              <td>{yoyakuShiharai.shiharaihoho}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

YoyakuShiharaiBlock.propTypes = {
  yoyakuShiharai: PropTypes.object.isRequired,
};

export default YoyakuShiharaiBlock;
