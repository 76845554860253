import React, { Fragment } from "react";
import PropTypes from "prop-types";
import ThWithBg from "../general/ThWithBg";
import HeaderTextBlue from "../general/HeaderTextBlue";
import ValueWithHeadcountUnit from "../../../../../app/ui/unit/ValueWithHeadcountUnit";

const ShokuinBlock = ({ shokuin }) => {
  return (
    <Fragment>
      <HeaderTextBlue text="職員体制" />
      <div style={{ marginBottom: "2pt" }}>
        <div>
          介護職員数：
          <ValueWithHeadcountUnit value={shokuin.kaigoshokuingokeininzu} />
        </div>
      </div>
      <div>
        <div className="p-office-body-table-dotted">
          <table>
            <thead>
              <tr>
                <ThWithBg text="看護・介護系資格" />
                <ThWithBg text="常勤" />
                <ThWithBg text="非常勤" />
                <ThWithBg text="リハビリ系資格" />
                <ThWithBg text="常勤" />
                <ThWithBg text="非常勤" />
              </tr>
            </thead>
            <tbody>
              <tr className="tbody-td_width21">
                <th>看護師・准看護師</th>
                <td>{shokuin.kangoshijunkangoshininzu.jokin}</td>
                <td>{shokuin.kangoshijunkangoshininzu.hijokin}</td>
                <th>理学療法士</th>
                <td>{shokuin.rigakuryohoshininzu.jokin}</td>
                <td>{shokuin.rigakuryohoshininzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width21">
                <th>介護福祉士</th>
                <td>{shokuin.kaigofukushishininzu.jokin}</td>
                <td>{shokuin.kaigofukushishininzu.hijokin}</td>
                <th>作業療法士</th>
                <td>{shokuin.sagyoryohoshininzu.jokin}</td>
                <td>{shokuin.sagyoryohoshininzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width21">
                <th>社会福祉士</th>
                <td>{shokuin.shakaifukushishininzu.jokin}</td>
                <td>{shokuin.shakaifukushishininzu.hijokin}</td>
                <th>言語聴覚士</th>
                <td>{shokuin.gengochokakushininzu.jokin}</td>
                <td>{shokuin.gengochokakushininzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width21">
                <th>実務者研修</th>
                <td>{shokuin.jitsumushakenshuninzu.jokin}</td>
                <td>{shokuin.jitsumushakenshuninzu.hijokin}</td>
                <th>柔道整復士</th>
                <td>{shokuin.judoseifukushininzu.jokin}</td>
                <td>{shokuin.judoseifukushininzu.hijokin}</td>
              </tr>
              <tr className="tbody-td_width21">
                <th>初任者研修</th>
                <td>{shokuin.shoninshakenshuninzu.jokin}</td>
                <td>{shokuin.shoninshakenshuninzu.hijokin}</td>
                <th>あん摩マッサージ師</th>
                <td>{shokuin.ammaMassagistNinzu.jokin}</td>
                <td>{shokuin.ammaMassagistNinzu.hijokin}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </Fragment>
  );
};

ShokuinBlock.propTypes = {
  shokuin: PropTypes.object.isRequired,
};

export default ShokuinBlock;
