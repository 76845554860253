import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import {
  MASTER_DATA_BUNDLE_FOR_SHORTSTAY,
  withMasterData,
  serviceShubetsuFilter,
} from "../../../../../state/master-data";
import InputTypeNumber from "../../../../../app/ui/form/InputTypeNumber";
import UnitLayout from "../../../../../app/ui/form/UnitLayout";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";
import OptionsSelectDialog from "../../../../../app/ui/form/OptionsSelectDialog";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import { IconButton } from "../../../../../../node_modules/@material-ui/core";
import GridRow from "../../../../../app/ui/grid/GridRow";

class ShortstayRyokinmeyasuForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleTaizaihiAdded = this.handleTaizaihiAdded.bind(this);
  }

  // 入力項目を追加する。
  handleTaizaihiAdded(option) {
    this.appendTaizaihi({
      kyoshitsuTypeCode: option.code,
      kihonServiceYoshien1: "0",
      kihonServiceYoshien2: "0",
      kihonServiceYokaigo1: "0",
      kihonServiceYokaigo2: "0",
      kihonServiceYokaigo3: "0",
      kihonServiceYokaigo4: "0",
      kihonServiceYokaigo5: "0",
      taizaihiDai1dankai: "0",
      taizaihiDai2dankai: "0",
      taizaihiDai3dankai: "0",
      taizaihiDai4dankai: "0",
    });
  }

  // 入力項目を削除する
  handleTaizaihiRemoved(index) {
    this.removeTaizaihi(index);
  }

  // Form取得
  getTaizaihi() {
    const { bindingProps } = this.props;
    return [].concat(bindingProps.form.getByName("taizaihi"));
  }

  // Form追加
  appendTaizaihi(form) {
    const { bindingProps } = this.props;
    const org = this.getTaizaihi();
    bindingProps.onChange("taizaihi", org.concat([form]));
  }

  // Form削除
  removeTaizaihi(index) {
    const { bindingProps } = this.props;
    const org = this.getTaizaihi();
    bindingProps.onChange(
      "taizaihi",
      org.filter((_, i) => i != index)
    );
  }

  kyoshituType() {
    const {
      masterData,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode,
    } = this.props;
    const taizaihi = this.getTaizaihi().map((it) => it.kyoshitsuTypeCode);
    return (
      masterData.shortstay_KyoshitsuType
        // サービス種別でフィルタ
        .filter(
          serviceShubetsuFilter(
            serviceShubetsuCategoryCode,
            serviceShubetsuCode
          )
        )
        // formに無い要素をフィルタ
        .filter((it) => !taizaihi.includes(it.code))
    );
  }

  render() {
    return (
      <Fragment>
        {this.renderShokuhi()}
        {this.renderTaizaihi()}
      </Fragment>
    );
  }

  renderShokuhi() {
    const { bindingProps } = this.props;
    return (
      <Fragment>
        <FormGrouping title="食費">
          <HorizontalLabelLayout labelText="食費（利用料負担段階別）" required>
            <div>
              <RatioBlock divide={5}>
                <SpaceOut block>
                  <StaticLabel>第1段階</StaticLabel>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shokuhiDai1dankai"
                      hintText="0"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </SpaceOut>
              </RatioBlock>
              <RatioBlock divide={5}>
                <SpaceOut block>
                  <StaticLabel>第2段階</StaticLabel>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shokuhiDai2dankai"
                      hintText="0"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </SpaceOut>
              </RatioBlock>
              <RatioBlock divide={5}>
                <SpaceOut block>
                  <StaticLabel>第3段階</StaticLabel>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shokuhiDai3dankai"
                      hintText="0"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </SpaceOut>
              </RatioBlock>
              <RatioBlock divide={5}>
                <SpaceOut block>
                  <StaticLabel>第4段階</StaticLabel>
                  <UnitLayout unitText="円">
                    <InputTypeNumber
                      name="shokuhiDai4dankai"
                      hintText="0"
                      {...bindingProps}
                    />
                  </UnitLayout>
                </SpaceOut>
              </RatioBlock>
            </div>
          </HorizontalLabelLayout>
        </FormGrouping>
      </Fragment>
    );
  }

  renderTaizaihi() {
    const { bindingProps, masterData } = this.props;
    return (
      <FormGrouping title="基本サービス費・滞在費">
        {this.getTaizaihi().map((taizaihi, index) => {
          const name = `taizaihi.${index}`;
          const kyoshitsuType = masterData.shortstay_KyoshitsuType.find(
            (it) => it.code == taizaihi.kyoshitsuTypeCode
          );
          return (
            <HorizontalLabelLayout
              key={index}
              labelText={kyoshitsuType.label}
              required
            >
              <GridRow>
                <RatioBlock divide={1}>
                  <SpaceOut block>
                    <StaticLabel></StaticLabel>
                  </SpaceOut>
                </RatioBlock>
              </GridRow>
              <GridRow>
                <RatioBlock divide={3}>
                  <SpaceOut block>
                    <StaticLabel style={styles.label}>
                      基本サービス費
                    </StaticLabel>
                  </SpaceOut>
                </RatioBlock>
                <IconButton
                  style={styles.deleteButton}
                  onClick={() => this.handleTaizaihiRemoved(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </GridRow>
              <GridRow>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <StaticLabel>要支援1</StaticLabel>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.kihonServiceYoshien1`}
                        hintText="0"
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <StaticLabel>要支援2</StaticLabel>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.kihonServiceYoshien2`}
                        hintText="0"
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </RatioBlock>
              </GridRow>
              <GridRow>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <StaticLabel>要介護1</StaticLabel>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.kihonServiceYokaigo1`}
                        hintText="0"
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <StaticLabel>要介護2</StaticLabel>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.kihonServiceYokaigo2`}
                        hintText="0"
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <StaticLabel>要介護3</StaticLabel>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.kihonServiceYokaigo3`}
                        hintText="0"
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <StaticLabel>要介護4</StaticLabel>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.kihonServiceYokaigo4`}
                        hintText="0"
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <StaticLabel>要介護5</StaticLabel>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.kihonServiceYokaigo5`}
                        hintText="0"
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </RatioBlock>
              </GridRow>
              <GridRow>
                <RatioBlock divide={3}>
                  <SpaceOut block>
                    <StaticLabel style={styles.label}>
                      滞在費（利用料負担段階別）
                    </StaticLabel>
                  </SpaceOut>
                </RatioBlock>
              </GridRow>
              <GridRow>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <StaticLabel>第1段階</StaticLabel>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.taizaihiDai1dankai`}
                        hintText="0"
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <StaticLabel>第2段階</StaticLabel>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.taizaihiDai2dankai`}
                        hintText="0"
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <StaticLabel>第3段階</StaticLabel>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.taizaihiDai3dankai`}
                        hintText="0"
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <StaticLabel>第4段階</StaticLabel>
                    <UnitLayout unitText="円">
                      <InputTypeNumber
                        name={`${name}.taizaihiDai4dankai`}
                        hintText="0"
                        {...bindingProps}
                      />
                    </UnitLayout>
                  </SpaceOut>
                </RatioBlock>
              </GridRow>
            </HorizontalLabelLayout>
          );
        })}
        {this.renderOptionsSelectDialog()}
      </FormGrouping>
    );
  }

  renderOptionsSelectDialog() {
    const shortstayKyoshituTypeSelect = this.kyoshituType();
    if (shortstayKyoshituTypeSelect.length < 1) return null;
    const split =
      shortstayKyoshituTypeSelect.length > 3
        ? 3
        : shortstayKyoshituTypeSelect.length;
    return (
      <HorizontalLabelLayout>
        <OptionsSelectDialog
          split={split}
          title="タイプを選択する"
          onSelect={this.handleTaizaihiAdded}
          options={shortstayKyoshituTypeSelect}
        />
      </HorizontalLabelLayout>
    );
  }
}

const styles = {
  label: {
    fontWeight: "bold",
  },
  deleteButton: {
    position: "relative",
    top: -25,
    left: -465,
  },
  taizaihiBlock: {
    marginLeft: -155,
  },
  taizaihiLabel: {
    marginLeft: -10,
    paddingRight: 10,
    textAlign: "right",
  },
};

ShortstayRyokinmeyasuForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_SHORTSTAY)(
  ShortstayRyokinmeyasuForm
);
