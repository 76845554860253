import ActionType from "./ActionType";
import AuthenticationActionType from "../../authentication/reducer/ActionType";
import { reducersMapOf } from "../../../app/fundamental/reducer/ReducersMap";
import { formDataOf } from "../../../app/fundamental/data/FormData";

const initialState = {
  list: [],
  listIsLoading: false,
  details: null,
  detailsIsLoading: false,
  form: formDataOf({}),
  formIsLoading: false,
  formIsSubmitting: false,
  pickupList: [],
};

const reducers = reducersMapOf({
  [AuthenticationActionType.LOGOUT]: function () {
    return initialState;
  },
  [ActionType.RESET]: function () {
    return initialState;
  },
  [ActionType.REQUEST_LIST]: function (state) {
    return {
      ...state,
      listIsLoading: true,
    };
  },
  [ActionType.REQUEST_LIST_FINISHED]: function (state, action) {
    return {
      ...state,
      listIsLoading: false,
      list: action.payload.list,
    };
  },
  [ActionType.REQUEST_DETAILS]: function (state) {
    return {
      ...state,
      detailsIsLoading: true,
    };
  },
  [ActionType.REQUEST_DETAILS_FINISHED]: function (state, action) {
    return {
      ...state,
      detailsIsLoading: false,
      details: action.payload.details,
    };
  },
  [ActionType.REQUEST_FORM]: function (state) {
    return {
      ...state,
      formIsLoading: true,
    };
  },
  [ActionType.REQUEST_FORM_FINISHED]: function (state, action) {
    return {
      ...state,
      formIsLoading: false,
      form: formDataOf(action.payload.form).setType(action.payload.type),
    };
  },
  [ActionType.CHANGE_SORT]: function (state, action) {
    // 並べ替え対象
    const item = state.form.items.find(
      (it) => it.uploadFile.id == action.payload.id
    );
    // 並べ替え対象以外
    const items = state.form.items.filter(
      (it) => it.uploadFile.id != action.payload.id
    );
    // 並べ替え実施
    items.splice(action.payload.index, 0, item);
    return {
      ...state,
      form: state.form.setByName(action.payload.name, items),
    };
  },
  [ActionType.CHANGE_FORM]: function (state, action) {
    return {
      ...state,
      form: state.form.setByName(action.payload.name, action.payload.value),
    };
  },
  [ActionType.REMOVE]: function (state, action) {
    return {
      ...state,
      list: state.list.filter((it) => it.id != action.payload.id),
    };
  },
  [ActionType.SUBMIT]: function (state) {
    return {
      ...state,
      formIsSubmitting: true,
    };
  },
  [ActionType.SUBMIT_FINISHED]: function (state) {
    return {
      ...state,
      formIsSubmitting: false,
    };
  },
  [ActionType.SUBMIT_FAILED]: function (state) {
    return {
      ...state,
      formIsSubmitting: false,
    };
  },
  [ActionType.PICKUP_REQUEST_LIST]: function (state) {
    return {
      ...state,
      listIsLoading: true,
    };
  },
  [ActionType.PICKUP_REQUEST_LIST_FINISHED]: function (state, action) {
    return {
      ...state,
      listIsLoading: false,
      pickupList: action.payload.list,
    };
  },
  [ActionType.PICKUP_REQUEST_FORM]: function (state) {
    return {
      ...state,
      formIsLoading: true,
    };
  },
  [ActionType.PICKUP_REQUEST_FORM_FINISHED]: function (state, action) {
    return {
      ...state,
      formIsLoading: false,
      form: formDataOf(action.payload.form).setType(action.payload.type),
    };
  },
  [ActionType.PICKUP_REMOVE]: function (state, action) {
    return {
      ...state,
      pickupList: state.pickupList.filter((it) => it.id != action.payload.id),
    };
  },
  [ActionType.PICKUP_SUBMIT]: function (state) {
    return {
      ...state,
      formIsSubmitting: true,
    };
  },
  [ActionType.PICKUP_SUBMIT_FINISHED]: function (state) {
    return {
      ...state,
      formIsSubmitting: false,
    };
  },
  [ActionType.PICKUP_SUBMIT_FAILED]: function (state) {
    return {
      ...state,
      formIsSubmitting: false,
    };
  },
});

export default reducers.createReducer(initialState);
