import ActionType from "./reducer/ActionType";
import ApiPath from "../../app/fundamental/request/ApiPath";
import ApiRequest from "../../app/fundamental/request/ApiRequest";
import {
  defaultResponseHandler,
  defaultUnauthorizedHander,
  withCompletionMessage,
} from "../../app/fundamental/request";

function reset() {
  return {
    type: ActionType.RESET,
  };
}

export function requestBlankForm(randomPw) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getBlankForm(dispatch, randomPw);
  };
}

export function requestEditForm(id) {
  return (dispatch) => {
    dispatch(reset());
    dispatch({
      type: ActionType.REQUEST,
    });
    getEditForm(dispatch, id);
  };
}

export function changeForm(name, value) {
  return {
    type: ActionType.CHANGE_FORM,
    payload: {
      name,
      value,
    },
  };
}

export function submitToAdd(form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToAdd(dispatch, form, callback);
  };
}

export function submitToEdit(id, form, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    postToEdit(dispatch, id, form, callback);
  };
}

export function submitToRemove(id, callback) {
  return (dispatch) => {
    dispatch({
      type: ActionType.SUBMIT,
    });
    del(dispatch, id, callback);
  };
}

function getBlankForm(dispatch, randomPw) {
  const url = ApiPath.api_hojin_blankform;
  getForm(dispatch, url, "blank", randomPw);
}

function getEditForm(dispatch, id) {
  const url = ApiPath.api_hojin_id_editform.replace(/:id/, id);
  getForm(dispatch, url, "edit");
}

function getForm(dispatch, url, type, randomPw) {
  const onSuccess = (json) => {
    dispatch({
      type: ActionType.REQUEST_FINISHED,
      payload: {
        form: json,
        type,
        randomPw,
      },
    });
  };
  ApiRequest.get(url).request(
    defaultResponseHandler(dispatch, onSuccess),
    defaultUnauthorizedHander(dispatch)
  );
}

function postToAdd(dispatch, form, callback) {
  const url = ApiPath.api_hojin;
  post(dispatch, url, form, callback, "法人情報を追加しました。");
}

function postToEdit(dispatch, id, form, callback) {
  const url = ApiPath.api_hojin_id.replace(/:id/, id);
  post(dispatch, url, form, callback, "法人情報を更新しました。");
}

function post(dispatch, url, form, callback = () => {}, message) {
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(callback);
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.post(url, form).request(
    withCompletionMessage(
      dispatch,
      message
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}

function del(dispatch, id, callback = () => {}) {
  const url = ApiPath.api_hojin_id.replace(/:id/, id);
  const onSuccess = () => {
    dispatch({
      type: ActionType.SUBMIT_FINISHED,
    });
    setTimeout(callback);
  };
  const onFailure = () => {
    dispatch({
      type: ActionType.SUBMIT_FAILED,
    });
  };
  ApiRequest.delete(url).request(
    withCompletionMessage(
      dispatch,
      "法人情報を削除しました。"
    )(defaultResponseHandler(dispatch, onSuccess, onFailure)),
    defaultUnauthorizedHander(dispatch)
  );
}
