import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
  serviceShubetsuFilter,
} from "../../../../../state/master-data";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";
import Mount from "../../../../../app/ui/layout/Mount";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import { IconButton } from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/DeleteForever";
import OptionsSelectDialog from "../../../../../app/ui/form/OptionsSelectDialog";
import ColorCatalog from "../../../../../app/ui/color/ColorCatalog";
import StandardSelect from "../../../../../app/ui/form/StandardSelect";
import RatioBlock from "../../../../../app/ui/layout/RatioBlock";

/**
 * 訪問系サービス保険外サービスフォーム.
 */
class HomonkeiServiceHokengaiServiceForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleHokengaiServiceAdded = this.handleHokengaiServiceAdded.bind(
      this
    );
  }

  handleHokengaiServiceAdded(option) {
    const duplicated = this.hokengaiService().find(
      (it) => it.code == option.code
    );
    const freeText = option.freeText;
    if (duplicated && !freeText) return;
    this.hokengaiService(
      this.hokengaiService().concat([
        {
          code: option.code,
          sonota: "",
        },
      ])
    );
  }

  handleHokengaiServiceRemoved(index) {
    this.hokengaiService(this.hokengaiService().filter((_, i) => i != index));
  }

  hokengaiService(hokengaiService) {
    const { bindingProps } = this.props;
    if (!hokengaiService) return bindingProps.form.getByName("items");
    bindingProps.onChange("items", hokengaiService);
  }

  render() {
    return (
      <Fragment>
        {this.renderHokengaiService()}
        {this.renderBiko()}
      </Fragment>
    );
  }

  renderHokengaiService() {
    const {
      bindingProps,
      masterData,
      serviceShubetsuCategoryCode,
      serviceShubetsuCode,
    } = this.props;
    return (
      <FormGrouping title="保険外サービス" recommended>
        <HorizontalLabelLayout labelText="">
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>サービスの種類</StaticLabel>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={6}>
            <SpaceOut block>
              <StaticLabel>単位</StaticLabel>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={5}>
            <SpaceOut block>
              <StaticLabel>料金</StaticLabel>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={3}>
            <SpaceOut block>
              <StaticLabel>URL</StaticLabel>
            </SpaceOut>
          </RatioBlock>
          <RatioBlock divide={0}></RatioBlock>

          {this.hokengaiService().map((hokengaiService, index) => {
            const name = `items.${index}`;
            const label = masterData.homonkeiService_hokengaiService.find(
              (it) => it.code == hokengaiService.code
            );
            return (
              <div key={`${index}:${label.code}`}>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <Mount
                      if={label.freeText}
                      then={() => (
                        <InputTypeText
                          name={`${name}.sonota`}
                          hintText="自由入力"
                          {...bindingProps}
                        />
                      )}
                      else={() => <StaticLabel>{label.label}</StaticLabel>}
                    />
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={6}>
                  <SpaceOut block>
                    <StandardSelect
                      name={`${name}.unitCode`}
                      options={masterData.homonkeiService_hokengaiUnit}
                      {...bindingProps}
                    />
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={5}>
                  <SpaceOut block>
                    <div style={styles.container}>
                      <div style={styles.input}>
                        <InputTypeText
                          name={`${name}.ryokin`}
                          hintText="0"
                          {...bindingProps}
                        />
                      </div>
                      <div style={styles.unit}>
                        <StaticLabel>円</StaticLabel>
                      </div>
                    </div>
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={3}>
                  <SpaceOut block>
                    <InputTypeText
                      name={`${name}.url`}
                      hintText="http://"
                      {...bindingProps}
                    />
                  </SpaceOut>
                </RatioBlock>
                <RatioBlock divide={0}>
                  <IconButton
                    onClick={() => this.handleHokengaiServiceRemoved(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </RatioBlock>
              </div>
            );
          })}
          <OptionsSelectDialog
            title="保険外サービスを追加する"
            onSelect={this.handleHokengaiServiceAdded}
            options={masterData.homonkeiService_hokengaiService.filter(
              serviceShubetsuFilter(
                serviceShubetsuCategoryCode,
                serviceShubetsuCode
              )
            )}
          />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderBiko() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="備考">
        <HorizontalLabelLayout labelText="">
          <Textarea name="biko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HomonkeiServiceHokengaiServiceForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceHokengaiServiceForm
);

const styles = {
  container: {
    width: "100%",
  },
  input: {
    boxSizing: "border-box",
    display: "inline-block",
    width: "calc(100% - 20px)",
  },
  unit: {
    boxSizing: "border-box",
    display: "inline-block",
    width: 20,
    paddingLeft: 5,
    color: ColorCatalog.gray,
  },
};
