import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";
import { goToJigyoshoAttributeFormButton } from "../../GoToEditFormButton";
import JigyoshoAttributeType from "../../../../../app/domain/jigyosho/JigyoshoAttributeType";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";

const RehabilitationTable = ({ contents, detail }) => {
  const {
    serviceShubetsuCode,
    blocks: { shortstayRehabilitation: rehabilitation },
  } = contents;
  const title =
    ServiceShubetsuType.短期入所生活介護.code == serviceShubetsuCode
      ? "機能訓練"
      : "リハビリテーション";
  return (
    <Fragment>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>{title}の有無</th>
              <td>{rehabilitation.rehabilitationUmu}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="p-office-body-title">個別機能訓練(個別対応)</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>個別対応</th>
              <td>{rehabilitation.kobetsutaio}</td>
            </tr>
            <Mount if={detail}>
              <tr>
                <th>担当制</th>
                <td>{rehabilitation.kobetsutaioTantousei}</td>
              </tr>
            </Mount>
            <tr>
              <th>内容</th>
              <td>
                {rehabilitation.rehabilitationKobetsuKinokunrennaiyo.join("、")}
              </td>
            </tr>
            <tr>
              <th>時間(分)</th>
              <td>{rehabilitation.kobetsutaioJikan}</td>
            </tr>
            <Mount if={detail}>
              <tr>
                <th>療法</th>
                <td>{rehabilitation.ryoho.join("、")}</td>
              </tr>
            </Mount>
          </tbody>
        </table>
      </div>

      <div className="p-office-body-title">個別機能訓練(集団対応)</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>集団対応</th>
              <td>{rehabilitation.shudantaio}</td>
            </tr>
            <tr>
              <th>内容</th>
              <td>
                {rehabilitation.rehabilitationShudanKinokunrennaiyo.join("、")}
              </td>
            </tr>
            <Mount if={detail}>
              <tr>
                <th>人数</th>
                <td>{rehabilitation.shudantaioNinzu}</td>
              </tr>
            </Mount>
            <tr>
              <th>時間(分)</th>
              <td>{rehabilitation.shudantaioJikan}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="p-office-body-title">環境・設備</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>リハビリ機材</th>
              <td className="val">
                <Mount if={!detail}>
                  {rehabilitation.rehabiliKizaiLabel.join("、")}
                </Mount>
                <Mount if={detail}>
                  <ul>
                    {rehabilitation.rehabiliKizai.map((item, i) => {
                      return (
                        <li key={i}>
                          <div key={i}>
                            <div className="c-small-subtitle">
                              ▼{item.label}
                            </div>
                            <div>{item.list.join("、")}</div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </Mount>
              </td>
            </tr>
            <tr>
              <th>訓練場所</th>
              <td>{rehabilitation.rehabilitationKunrembasho.join("、")}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="p-office-body-title">介護報酬加算</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>介護報酬加算</th>
              <td>{rehabilitation.kaigohoshukasan.join("、")}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="p-office-body-title">備考</div>
      <div className="p-office-body-table">
        <table>
          <tbody>
            <tr>
              <th>備考</th>
              <td>{rehabilitation.biko}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <Mount if={detail}>
        <div className="p-office-body-characteristic">特 徴</div>
        <div className="p-office-body-description p-office-body-description--p">
          {rehabilitation.tokucho}
        </div>
      </Mount>

      {goToJigyoshoAttributeFormButton(
        contents,
        JigyoshoAttributeType.shortstayRehabilitation
      )}
    </Fragment>
  );
};

RehabilitationTable.propTypes = {
  contents: PropTypes.object.isRequired,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default RehabilitationTable;
