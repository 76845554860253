import JwtToken from "./JwtToken";

const TOKEN_NAME = "jwt-token";

class JwtTokenRepository {
  store(loginToken) {
    localStorage.setItem(TOKEN_NAME, loginToken.value);
  }

  restore() {
    const token = localStorage.getItem(TOKEN_NAME);
    if (!token) return null;
    return new JwtToken(token);
  }

  remove() {
    localStorage.removeItem(TOKEN_NAME);
  }
}

export default JwtTokenRepository;
