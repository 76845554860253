import React, { Fragment } from "react";
import PropTypes from "prop-types";
import FormGrouping from "../../../../../app/ui/form/FormGrouping";
import HorizontalLabelLayout from "../../../../../app/ui/form/HorizontalLabelLayout";
import Textarea from "../../../../../app/ui/form/Textarea";
import {
  withMasterData,
  MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE,
} from "../../../../../state/master-data";
import TimePeriod from "../../../../../app/ui/form/TimePeriod";
import OptionsRadio from "../../../../../app/ui/form/OptionsRadio";
import {
  OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE,
  OPTIONS_TAIO_JOKYO,
} from "../../../../../app/ui/form";
import InputTypeCheckbox from "../../../../../app/ui/form/InputTypeCheckbox";
import InputTypeText from "../../../../../app/ui/form/InputTypeText";
import Mount from "../../../../../app/ui/layout/Mount";
import ServiceShubetsuType from "../../../../../app/domain/jigyosho/ServiceShubetsuType";
import GridRow from "../../../../../app/ui/grid/GridRow";
import SpaceOut from "../../../../../app/ui/layout/SpaceOut";
import GridCell from "../../../../../app/ui/grid/GridCell";
import StaticLabel from "../../../../../app/ui/form/StaticLabel";

/**
 * 訪問系サービス営業時間フォーム.
 */
class HomonkeiServiceEigyoJikanForm extends React.Component {
  constructor(props) {
    super(props);
    this.yakanUkeireDisabled = this.yakanUkeireDisabled.bind(this);
    this.handleAlldayUkeireChanged = this.handleAlldayUkeireChanged.bind(this);
    this.handleAlldayUkeireChangedOn = this.handleAlldayUkeireChangedOn.bind(
      this
    );
    this.handleAlldayUkeireChangedOff = this.handleAlldayUkeireChangedOff.bind(
      this
    );
    this.state = {
      previousValues: {
        yakanUkeire: "",
      },
    };
  }

  yakanUkeireDisabled() {
    const {
      bindingProps: { form },
    } = this.props;
    return form.getByName("alldayUkeire");
  }

  handleAlldayUkeireChanged(_, value) {
    value
      ? this.handleAlldayUkeireChangedOn()
      : this.handleAlldayUkeireChangedOff();
  }

  handleAlldayUkeireChangedOn() {
    const yakanUkeire = "yakanUkeire";
    const previousValues = "previousValues";
    const {
      bindingProps: { form, onChange: changeForm },
    } = this.props;
    this.setState({
      [previousValues]: {
        ...this.state[previousValues],
        [yakanUkeire]: form.getByName(yakanUkeire),
      },
    });
    changeForm(yakanUkeire, OPTIONS_TAIO_JOKYO[0].code);
  }

  handleAlldayUkeireChangedOff() {
    const yakanUkeire = "yakanUkeire";
    const {
      bindingProps: { onChange: changeForm },
    } = this.props;
    changeForm(yakanUkeire, this.state.previousValues[yakanUkeire]);
  }

  render() {
    return (
      <Fragment>
        {this.renderEigyoJikan()}
        {this.renderTeikyoJikan()}
        {this.renderJikangai()}
        {this.renderTeikyubi()}
      </Fragment>
    );
  }

  renderEigyoJikan() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="営業時間">
        <HorizontalLabelLayout labelText="平日" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="heijitsu"
                disabled={bindingProps.form.getByName("heijitsu_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="heijitsu_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="土曜" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="doyo"
                disabled={bindingProps.form.getByName("doyo_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="doyo_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="日曜" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="nichiyo"
                disabled={bindingProps.form.getByName("nichiyo_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="nichiyo_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="祝日" required>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="shukujitsu"
                disabled={bindingProps.form.getByName("shukujitsu_teikyubi")}
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}>
              <SpaceOut block>
                <InputTypeCheckbox
                  name="shukujitsu_teikyubi"
                  labelText="定休日"
                  {...bindingProps}
                />
              </SpaceOut>
            </GridCell>
          </GridRow>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderTeikyoJikan() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="サービス提供時間">
        <p style={{ marginLeft: 115, fontSize: "x-small", color: "gray" }}>
          24時間対応の場合は「00:00～23:59」と入力ください。
        </p>
        <HorizontalLabelLayout labelText="平日" recommended>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="heijitsu_teikyojikan"
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}></GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="土曜" recommended>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="doyo_teikyojikan"
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}></GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="日曜" recommended>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="nichiyo_teikyojikan"
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}></GridCell>
          </GridRow>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="祝日" recommended>
          <GridRow>
            <GridCell>
              <TimePeriod
                name="shukujitsu_teikyojikan"
                separator="."
                {...bindingProps}
              />
            </GridCell>
            <GridCell grow={2}></GridCell>
          </GridRow>
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }

  renderJikangai() {
    const { bindingProps, serviceShubetsuCode } = this.props;
    return (
      <FormGrouping title="時間外対応">
        <HorizontalLabelLayout labelText="早朝対応" required>
          <OptionsRadio
            name="sochoUkeire"
            split={6}
            options={OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="夜間対応" required>
          <OptionsRadio
            name="yakanUkeire"
            split={6}
            disabled={this.yakanUkeireDisabled()}
            options={OPTIONS_ALLOWABILITY_WITH_NEGOTIABLE}
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <Mount
          if={[
            ServiceShubetsuType.訪問介護,
            ServiceShubetsuType.訪問看護,
            ServiceShubetsuType.定期巡回_随時対応型訪問介護看護,
          ]
            .map((it) => it.code)
            .includes(serviceShubetsuCode)}
          then={() => (
            <HorizontalLabelLayout labelText="24時間対応">
              <InputTypeCheckbox
                name="alldayUkeire"
                labelText="はい"
                {...{
                  ...bindingProps,
                  onChange: (...args) => {
                    bindingProps.onChange(...args);
                    this.handleAlldayUkeireChanged(...args);
                  },
                }}
              />
            </HorizontalLabelLayout>
          )}
        />
      </FormGrouping>
    );
  }

  renderTeikyubi() {
    const { bindingProps } = this.props;
    return (
      <FormGrouping title="定休日等">
        <HorizontalLabelLayout labelText="365日対応">
          <InputTypeCheckbox
            name="allyearUkeire"
            labelText="はい"
            {...bindingProps}
          />
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="定休日" required>
          <InputTypeText name="teikyubi" {...bindingProps} />
          <StaticLabel>
            ※
            定休日にチェックを入れた曜日以外の定休日がない場合は、なしと入力してください
          </StaticLabel>
        </HorizontalLabelLayout>
        <HorizontalLabelLayout labelText="留意事項">
          <Textarea name="ryuijiko" {...bindingProps} />
        </HorizontalLabelLayout>
      </FormGrouping>
    );
  }
}

HomonkeiServiceEigyoJikanForm.propTypes = {
  bindingProps: PropTypes.object.isRequired,
  masterData: PropTypes.object.isRequired,
  serviceShubetsuCategoryCode: PropTypes.string.isRequired,
  serviceShubetsuCode: PropTypes.string.isRequired,
};

export default withMasterData(MASTER_DATA_BUNDLE_FOR_HOMONKEI_SERVICE)(
  HomonkeiServiceEigyoJikanForm
);
