import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Mount from "../../../../../app/ui/layout/Mount";

const RyokinMeyasuTable = ({ contents, alone }) => {
  return alone ? (
    <Fragment>
      <div className="p-office-body-title">{contents.kyoshitsuType}</div>
      <div className="p-office-body-sep2">
        <div className="p-office-body-sep__left">{yoshienTable(contents)}</div>
        <div className="p-office-body-sep__right">{yokaigoTable(contents)}</div>
      </div>
    </Fragment>
  ) : (
    <Fragment>
      <div className="p-office-body-title">{contents.kyoshitsuType}</div>
      {yoshienTable(contents)}
      {yokaigoTable(contents)}
    </Fragment>
  );
};

function yoshienTable(contents) {
  return (
    <div className="p-office-body-table-ryokinmeyasu">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>負担段階</th>
            <th>基本料金</th>
            <th>滞在費</th>
            <th>食費</th>
            <th>合計(日額)</th>
          </tr>
        </thead>
        {/* 要支援度 */}
        {Object.keys(contents.yoshienMap).map((key, keyIndex) => {
          return (
            <tbody key={keyIndex}>
              {/* 負担段階 */}
              {Object.values(contents.yoshienMap)[keyIndex].map(
                (item, valueIndex) => {
                  return (
                    <tr key={valueIndex}>
                      <Mount if={valueIndex < 1}>
                        <th rowSpan={5}>{key}</th>
                      </Mount>
                      <th>{item.futanDankai}</th>
                      <Mount if={valueIndex < 1}>
                        <td rowSpan={4}>{item.kihonService}</td>
                      </Mount>
                      <Mount if={valueIndex == 4}>
                        <td>{item.kihonService}</td>
                      </Mount>
                      <td>{item.taizaihi}</td>
                      <td>{item.shokuhi}</td>
                      <td>{item.nichigaku}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          );
        })}
      </table>
    </div>
  );
}

function yokaigoTable(contents) {
  return (
    <div className="p-office-body-table-ryokinmeyasu">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>負担段階</th>
            <th>基本料金</th>
            <th>滞在費</th>
            <th>食費</th>
            <th>合計(日額)</th>
          </tr>
        </thead>
        {/* 要介護度 */}
        {Object.keys(contents.yokaigoMap).map((key, keyIndex) => {
          return (
            <tbody key={keyIndex}>
              {/* 負担段階 */}
              {Object.values(contents.yokaigoMap)[keyIndex].map(
                (item, valueIndex) => {
                  return (
                    <tr key={valueIndex}>
                      <Mount if={valueIndex < 1}>
                        <th rowSpan={5}>{key}</th>
                      </Mount>
                      <th>{item.futanDankai}</th>
                      <Mount if={valueIndex < 1}>
                        <td rowSpan={4}>{item.kihonService}</td>
                      </Mount>
                      <Mount if={valueIndex == 4}>
                        <td>{item.kihonService}</td>
                      </Mount>
                      <td>{item.taizaihi}</td>
                      <td>{item.shokuhi}</td>
                      <td>{item.nichigaku}</td>
                    </tr>
                  );
                }
              )}
            </tbody>
          );
        })}
      </table>
    </div>
  );
}

RyokinMeyasuTable.propTypes = {
  contents: PropTypes.object.isRequired,
  alone: PropTypes.bool,
  detail: PropTypes.bool,
  print: PropTypes.bool,
};

export default RyokinMeyasuTable;
