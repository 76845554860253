const agent = getAgent();

class Agent {
  // IE
  static isIE() {
    return agent.indexOf("msie") !== -1 || agent.indexOf("trident/7") !== -1;
  }

  // edge
  static isEdge() {
    return agent.indexOf("edge") !== -1;
  }

  // chrome
  static isChrome() {
    return agent.indexOf("chrome") !== -1;
  }
}

function getAgent() {
  return window.navigator.userAgent.toLowerCase();
}

export default Agent;
